import styled from 'styled-components';
import { Link as _link } from 'react-router-dom';

export const Wrapper = styled(_link)`
  border-radius: 20px;
  align-items: center;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 40px;
  justify-content: flex-start;
  padding: 0 10px;
  max-width: 50px;
  transition: all 250ms ease-in;
  will-change: all;
  overflow: hidden;
  svg {
    min-width: 26px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      display: inline-block;
      margin-left: 15px;
      user-select: none;
      color: white;
    }
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
  }
  &.active {
    max-width: 400px;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    padding: 0 15px;
    margin: 0 5px;
    .content > span {
      display: inline-block;
      margin-left: 0px;
      padding-left: 10px;
    }
  }
`;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import {
  selectPatientLoading,
  selectAnamnesisPreview,
  selectAnamnesisFile,
} from '../selectors';
import SelfAnamnesisPreview from './SelfAnamnesisPreview';

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  anamnesisPreview: selectAnamnesisPreview(),
  anamnesisFile: selectAnamnesisFile(),
  isLoading: selectPatientLoading(),
});

export default compose(
  injectIntl,
  connect(mapStateToProps),
)(SelfAnamnesisPreview);

import { css } from 'styled-components';
import draftBgLight from '../images/draft-background-light.png';

export const arrayCSSProperty = [
  'margin',
  'marginLeft',
  'marginTop',
  'marginRight',
  'marginBottom',
  'padding',
  'paddingLeft',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'justifyContent',
  'flex',
  'flexDirection',
  'display',
  'alignItems',
  'borderTop',
  'borderBottom',
  'borderLeft',
  'borderRight',
  'textAlign',
  'position',
  'width',
  'fullBackground',
  'striped',
  'maxWidth',
  'backgroundColor',
  'height',
  'maxWidth',
  'widthFlex',
];

export const addCSSProperty = css`
  ${({ display, flex }) =>
    (display || flex) &&
    css`
      display: ${(flex && 'flex') || display};
    `};
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `};
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `};
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `};
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};
  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft}px;
    `};
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop}px;
    `};
  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight}px;
    `};
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom}px;
    `};
  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid ${({ theme }) => theme.gray200};
    `};
  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${({ theme }) => theme.gray200};
    `};
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid ${({ theme }) => theme.gray200};
    `};
  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.gray200};
    `};
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width} !important;
    `};
  ${({ fullBackground }) =>
    fullBackground &&
    css`
      background-color: ${({ theme }) => theme.gray100};
    `};
  ${({ striped }) =>
    striped &&
    css`
      background: url(${draftBgLight}) repeat, rgba(255, 255, 255, 0.8);
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ widthFlex }) =>
    !!widthFlex &&
    css`
      flex: 0 0 ${widthFlex};
      max-width: ${widthFlex};
    `};
`;

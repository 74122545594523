import styled from 'styled-components';
import omit from 'utils/omitProps';
import { ArrowLeft as _al, ArrowRight as _ar } from 'utils/icons';

export const Wrapper = styled.div`
  & .slick-arrow.slick-disabled {
    cursor: default;
    visibility: hidden;
  }
`;

export const ArrowLeft = styled(omit(_al, ['currentSlide', 'slideCount']))`
  color: ${({ theme }) => theme.black} !important;
  cursor: pointer;
`;

export const ArrowRight = styled(omit(_ar, ['currentSlide', 'slideCount']))`
  color: ${({ theme }) => theme.black} !important;
  cursor: pointer;
`;

import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { LOCATION_CHANGE, push } from 'connected-react-router/immutable';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { selectIsAuthenticated } from './selectors';
import { checkOneSignalRedirectAction } from './actions';

function* checkOneSignalRedirect() {
  const urlParams = yield select((state) => state.getIn(['router', 'location', 'search']));
  const values = queryString.parse(urlParams);
  const { resourceType, resourceId, persistedId } = values;
  if (resourceType || resourceId || persistedId) {
    yield call(makeRedirectUrl, {
      resourceType,
      resourceId,
      persistedId,
    });
  }
}

function* makeRedirectUrl(data) {
  try {
    const isAuthenticated = yield select(selectIsAuthenticated());
    if (!isAuthenticated) return;
    const { resourceType, resourceId } = data;
    let destination = null;
    switch (true) {
      case resourceType === 'booking' && !!resourceId: {
        const url = `partners/me/bookings/${resourceId}`;
        const { status, data: res } = yield call(fetchWrapper, {
          url,
        });
        if (status === 'success') {
          const { id } = (res || {}).patient || {};
          if (id) destination = `patient/${id}/details/${resourceId}`;
        }
        break;
      }
      case resourceType === 'bid' && !!resourceId:
        destination = `requests/${resourceId}`;
        break;
      default:
        destination = 'communications';
    }
    if (destination !== null) {
      yield call(makeRedirect, destination);
    }
  } catch (err) {
    console.log(err);
  }
}

function* makeRedirect(destination) {
  const isAuthenticated = yield select(selectIsAuthenticated());
  if (isAuthenticated) {
    yield put(push(destination));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(checkOneSignalRedirectAction, checkOneSignalRedirect),
    takeEvery(LOCATION_CHANGE, checkOneSignalRedirect),
  ]);
}

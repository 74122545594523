import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { setActiveFormAction } from 'containers/HomePage/LandingPage/actions';

export function* getForgotUsername({ data }) {
  const url = `auth/username`;
  try {
    yield put(showLoading());
    const { status } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: { ...data, role: 'partner' },
    });
    if (status === 'success') {
      yield put(setActiveFormAction({ activeForm: 'forgotUsernameSuccess' }));
      yield put(
        pushError({ text: 'forgotUsernameSuccess', typeError: 'success' }),
      );
    } else {
      yield put(pushError({ text: 'forgotUsernameError' }));
    }
  } catch (e) {
    const { packet, response } = e; // eslint-disable-line no-unused-vars
    if (response.status === 422) {
      yield put(pushError({ text: 'forgotUsernameDataError' }));
    } else {
      yield put(pushError({ text: 'forgotUsernameError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

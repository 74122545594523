import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from '../messageHandler/actions';
import {
  getAllDoctorsForSingleLocationFailed,
  getAllDoctorsForSingleLocationRequest,
  getAllDoctorsForSingleLocationSuccess,
  getDoctorDetailsFailed,
  getDoctorDetailsRequest,
  getDoctorDetailsSuccess,
  getAllDoctorsFailed,
  getAllDoctorsRequest,
  getAllDoctorsSuccess,
  createConventionalLocationRequest,
  createConventionalLocationSuccess,
  createConventionalLocationError,
  getDoctorsSkillsRequest,
  getDoctorsSkillsSuccess,
  getDoctorsSkillsFailed,
} from './actions';
import * as DoctorsAPI from './apiCall';
import {
  GetAllDoctorsForSingleLocationRequestPayload,
  GetDoctorDetailsRequestPayload,
  GetAllDoctorsRequestPayload,
  IDoctorLocations,
  CreateDoctorConventionalLocationPayload,
} from './types';

function* getAllDoctorsForSingleLocationSaga({ payload }: PayloadAction<GetAllDoctorsForSingleLocationRequestPayload>) {
  try {
    const response = yield call(DoctorsAPI.getAllDoctorsForSingleLocation, { ...payload });
    const { data } = response;

    yield put(getAllDoctorsForSingleLocationSuccess(data));
  } catch (error: any) {
    yield put(getAllDoctorsForSingleLocationFailed());
  }
}

function* getDoctorDetailsSaga({ payload }: PayloadAction<GetDoctorDetailsRequestPayload>) {
  try {
    const response = yield call(DoctorsAPI.getDoctorDetails, { ...payload });
    const { data } = response;

    const groupedLocations = data?.doctorLocations.reduce((acc, doctorLocation: IDoctorLocations) => {
      if (
        acc.filter(
          (l: IDoctorLocations) => l.locationId === doctorLocation.locationId && l.skillId === doctorLocation.skillId,
        ).length === 0
      ) {
        acc.push(doctorLocation);
      }
      return acc;
    }, []);

    const cleanedData = {
      ...data,
      doctorLocations: groupedLocations,
    };

    yield put(getDoctorDetailsSuccess(cleanedData));
  } catch (error: any) {
    yield put(getDoctorDetailsFailed());
  }
}

function* getDoctorsSkillsSaga() {
  try {
    const response = yield call(DoctorsAPI.getDoctorsSkills);
    const { data } = response;

    yield put(getDoctorsSkillsSuccess(data));
  } catch (error: any) {
    yield put(getDoctorsSkillsFailed());
  }
}

function* getAllDoctorsSaga({ payload }: PayloadAction<GetAllDoctorsRequestPayload>) {
  try {
    const response = yield call(DoctorsAPI.getAllDoctors, { ...payload });
    const { data } = response;

    yield put(getAllDoctorsSuccess(data));
  } catch (error: any) {
    yield put(getAllDoctorsFailed());
  }
}
function* createDoctorsConventionLocationSaga({ payload }: PayloadAction<CreateDoctorConventionalLocationPayload>) {
  try {
    const response = yield call(DoctorsAPI.createDoctorConventionalLocation, { ...payload });
    const { data } = response;

    yield put(createConventionalLocationSuccess(data));
  } catch (error: any) {
    yield put(createConventionalLocationError());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* doctorsSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getAllDoctorsForSingleLocationRequest.type, getAllDoctorsForSingleLocationSaga);
  yield takeLatest(getDoctorDetailsRequest.type, getDoctorDetailsSaga);
  yield takeLatest(getAllDoctorsRequest.type, getAllDoctorsSaga);
  yield takeLatest(createConventionalLocationRequest.type, createDoctorsConventionLocationSaga);
  yield takeLatest(getDoctorsSkillsRequest.type, getDoctorsSkillsSaga);
}

export default doctorsSaga;

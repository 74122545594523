import { defineMessages } from 'react-intl';

export default defineMessages({
  timeExpiredModalTitle: {
    id: 'web.partner.videomeeting.modal.timeExpiredModalTitle',
    defaultMessage: 'Time expired',
  },
  timeExpiredModalMessage: {
    id: 'web.partner.videomeeting.modal.timeExpiredModalMessage',
    defaultMessage:
      'Time for videomeeting expired, if you leave the room you will no longer be able to enter again',
  },
  ok: {
    id: 'web.partner.videomeeting.modal.ok',
    defaultMessage: 'Ok',
  },
});

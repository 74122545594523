import styled from 'styled-components';

export { default as Label } from './Label';
export { default as SelectStudio } from './SelectStudio';
export { default as GMap } from './GMap';
export { default as Alert } from './Alert';
export { default as CallToActionAlert } from './CallToActionAlert';
export { default as AvatarInput } from './AvatarInput';
export { default as AnimatedBackground } from './AnimatedBackground';
export {
  default as AnimatedBackgroundBottom,
} from './AnimatedBackgroundBottom';
export { default as MyStepBox } from './Box';
export { default as Spinner } from './Spinner';
export { default as TimePicker } from './Timepicker';
export { default as FilePreview } from './FilePreview';
export { default as ErrorModal } from './ErrorModal';
export { default as UpdateTermsModal } from './UpdateTermsModal';
export { default as FlagGenerator } from './FlagGenerator';
export {
  default as DestructiveOperationConfirmModal,
} from './DestructiveOperationConfirmModal';
export { default as SpinnerTable } from './SpinnerTable';
export { default as StarRating } from './StarRating';
// export { default as Tables } from './Tables';
export { default as Avatar } from './Avatar';
export { default as CueBall } from './CueBall';
export { default as StatusDot } from './StatusDot';
export { default as Carousel } from './Carousel';
export { default as SelectHours } from './SelectHours';
export { default as LabelBadge } from './LabelBadge';
export { default as ModalConfirmation } from './ModalConfirmation';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 40px 25px 20px;
`;

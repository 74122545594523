import styled, { css } from 'styled-components';
import _link from '../Link';

export const TextWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const BackLink = css`
  background-color: #acacac;
  border-radius: 20px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.3rem;
  line-height: 24px;
  padding: 0px 15px;
  margin-bottom: 30px;
  text-decoration: none;

  &:active {
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

export const Link = styled(_link)`
  ${BackLink};
`;

export const Button = styled.button`
  ${BackLink};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fontFamilySerif};
  font-size: ${({ hasBackPath }) => (hasBackPath ? '3.2rem' : '4.4rem')};
  text-align: left;
  ${({ titleFontSize }) =>
    titleFontSize &&
    css`
      font-size: ${titleFontSize};
    `};
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.secondary};
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.6rem;
`;

import { createReducer } from '@reduxjs/toolkit';
import {
  getAllPracticesAttachmentsFailed,
  getAllPracticesAttachmentsRequest,
  getAllPracticesAttachmentsSuccess,
  getAllPracticesInvoicesFailed,
  getAllPracticesInvoicesRequest,
  getAllPracticesInvoicesSuccess,
  resetAllPracticesAttachments,
  resetAllPracticesInvoices,
} from './actions';
import { ResultsAttachmentsEntity, ResultsInvoicesEntity } from './types';

export interface allPracticesAttachments {
  loading: boolean;
  attachments?: ResultsAttachmentsEntity[] | [];
  totalCount: number;
  currentPage: number;
  prevPage?: null;
  nextPage?: null;
}

export interface allPracticesInvoices {
  loading: boolean;
  invoices?: ResultsInvoicesEntity[] | [];
  totalCount: number;
  currentPage: number;
  prevPage?: null;
  nextPage?: null;
}

const initialStatePA: allPracticesAttachments = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  attachments: [],
};

const initialStatePI: allPracticesInvoices = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  invoices: [],
};

export const practicesAttachmentsReducers = createReducer(initialStatePA, {
  [getAllPracticesAttachmentsRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllPracticesAttachmentsSuccess.type]: (state, action) => {
    state.loading = false;
    state.attachments = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllPracticesAttachmentsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetAllPracticesAttachments.type]: (state) => {
    state.attachments = [];
  },
});

export const practicesInvoicesReducers = createReducer(initialStatePI, {
  [getAllPracticesInvoicesRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllPracticesInvoicesSuccess.type]: (state, action) => {
    state.loading = false;
    state.invoices = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllPracticesInvoicesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetAllPracticesInvoices.type]: (state) => {
    state.invoices = [];
  },
});

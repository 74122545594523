import { compose, withStateHandlers } from 'recompose';
import AvatarInput from './AvatarInput';

export default compose(
  withStateHandlers(
    { image: '' },
    {
      handleDrop: () => dropped => ({ image: dropped[0] }),
    },
  ),
)(AvatarInput);

export RemoteAvatarInput from './RemoteAvatarInput';

import styled, { css } from 'styled-components';
import { Input as _input } from 'reactstrap';
import omit from '../../utils/omitProps';
import { Close } from '../../icons';
import { getPaddingOption as getPaddingInput } from '../FormStyles';

export const Input = styled(
  omit(_input, [
    'errorBorder',
    'minHeight',
    'titlelabel',
    'label',
    'dirty',
    'success',
    'transparent',
    'maxWidth',
    'paddingLeft',
    'inputRadioField',
    'noDirtyBorder',
  ]),
)`
  font-size: 1.6rem;
  caret-color: ${({ theme }) => theme.primary};
  margin-top: 0 !important;
  color: ${({ theme }) => theme.black};
  width: 100%;
  padding: ${({ bsSize }) => getPaddingInput(bsSize)} !important;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
  background-color: ${({ inputRadioField, theme }) =>
    inputRadioField && theme.primary};
  border-color: ${({ inputRadioField, theme }) =>
    inputRadioField && theme.primary};

  ${({ type }) =>
    type === 'textarea' &&
    css`
      min-height: ${({ minHeight }) => minHeight || '160'}px !important;
      border-radius: 25px;
      color: ${({ theme }) => theme.black} !important;
      background-color: ${({ theme }) => theme.gray100};
      border: 2px solid ${({ theme }) => theme.gray200};
      outline: none !important;
      box-shadow: none !important;
      &.form-control,
      &.form-control:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    `};

  ${({ dirty, theme, noDirtyBorder }) =>
    !noDirtyBorder &&
    dirty &&
    css`
      border-color: ${theme.warning};
    `};

  ${({ errorBorder, theme }) =>
    errorBorder &&
    css`
      border-color: ${theme.danger} !important;
      margin-bottom: 0;
    `};

  ${({ success, validate, theme }) =>
    success &&
    validate &&
    css`
      color: ${theme.success} !important;
      border-color: ${theme.success} !important;
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.gray100} !important;
      border-color: ${theme.gray200} !important;
      color: #d8d8d8 !important;
    `};

  ${({ readOnly, theme }) =>
    readOnly &&
    css`
      background-color: ${theme.white} !important;
      border-color: ${theme.gray300} !important;
      color: #d8d8d8 !important;
    `};

  ${({ transparent, theme }) =>
    transparent &&
    css`
      padding-left: 0.6em !important;
      background-color: ${theme.white} !important;
      border-color: ${theme.white} !important;
      color: ${theme.black} !important;
      font-weight: 600;
    `};

  ${({ removeBorderRight }) =>
    removeBorderRight &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    `};

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft}px !important;
    `};
`;

export const Currency = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
`;
export const InputWrapper = styled.div`
  position: relative;
`;
export const CleanButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
export const CleanIcon = styled(Close)`
  width: 15px;
  height: 15px;
`;

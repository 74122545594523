/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

function ZipCodeInput({ input, ...props }) {
  return (
    <Input
      {...props}
      input={input}
      onChange={e => {
        const val = e.target.value;
        const max = 99999;
        const maxLength = 5;
        const newVal =
          // eslint-disable-next-line radix
          val < max ? val : parseInt(val.toString().substring(0, maxLength));
        input.onChange(newVal);
      }}
    />
  );
}

ZipCodeInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

ZipCodeInput.defaultProps = {};

export default ZipCodeInput;

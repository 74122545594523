import { messageHandlerSet } from '@apps/pu/redux/messageHandler/actions';
import { PayloadAction } from '@reduxjs/toolkit';
// import omit from 'lodash/omit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import {
  addNewVenueFailed,
  addNewVenueRequest,
  addNewVenueSuccess,
  getAllVenuesFailed,
  getAllVenuesRequest,
  getAllVenuesSuccess,
  getInvoiceAddressesSingleVenueFailed,
  getInvoiceAddressesSingleVenueRequest,
  getInvoiceAddressesSingleVenueSuccess,
  getVenueDetailsFailed,
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getApprovedVenueDetailsRequest,
  getApprovedVenueDetailsSuccess,
  getApprovedVenueDetailsFailed,
  getVenueDetailsInvoiceAddressesRequest,
  getVenueDetailsInvoiceAddressesSuccess,
  getVenueDetailsInvoiceAddressesFailed,
  putVenueDetailsRequest,
  putVenueDetailsSuccess,
  putVenueDetailsFailed,
  getVenueAffiliationsRequest,
  getVenueAffiliationsSuccess,
  getVenueAffiliationsFailed,
  getVenueTypesRequest,
  getVenueTypesSuccess,
  getVenueTypesFailed,
  getVenueAdditionalServicesSuccess,
  getVenueAdditionalServicesFailed,
  getVenueAdditionalServicesRequest,
  getCurrentVenuePraticeDetailsSuccess,
  getCurrentVenuePraticeDetailsFailed,
  getCurrentVenuePraticeDetailsRequest,
  getAllApprovedVenuesFailed,
  getAllApprovedVenuesRequest,
  getAllApprovedVenuesSuccess,
  getAllVenuePartnersSuccess,
  getAllVenuePartnersFailed,
  getAllVenuePartnersRequest,
} from './actions';
import * as VenuesAPI from './apiCall';
import {
  AddNewVenueRequestPayload,
  AddNewVenueRequestPayloadToBE,
  GetAllVenuesRequestPayload,
  GetVenueDetailsRequestPayload,
  GetApprovedVenueDetailsRequestPayload,
  PutVenueRequestPayload,
  GetAllApprovedVenuesRequestPayload,
} from './types';

function* getAllVenuesSaga({ payload }: PayloadAction<GetAllVenuesRequestPayload>) {
  try {
    const response = yield call(VenuesAPI.getAllVenues, { ...payload });
    const { data } = response;

    yield put(getAllVenuesSuccess(data));
  } catch (error: any) {
    yield put(getAllVenuesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getAllApprovedVenuesSaga({ payload }: PayloadAction<GetAllApprovedVenuesRequestPayload>) {
  try {
    const response = yield call(VenuesAPI.getAllApprovedVenues, { ...payload });
    const { data } = response;

    yield put(getAllApprovedVenuesSuccess(data));
  } catch (error: any) {
    yield put(getAllApprovedVenuesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenueDetailsSaga({ payload }: PayloadAction<GetVenueDetailsRequestPayload>) {
  try {
    const response = yield call(VenuesAPI.getSingleVenueDetails, { ...payload });
    const { data } = response;

    yield put(getVenueDetailsSuccess(data));
  } catch (error: any) {
    yield put(getVenueDetailsFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getApprovedVenueDetailsSaga({ payload }: PayloadAction<GetApprovedVenueDetailsRequestPayload>) {
  try {
    const response = yield call(VenuesAPI.getSingleApprovedVenueDetails, { ...payload });
    const { data } = response;

    yield put(getApprovedVenueDetailsSuccess(data));
  } catch (error: any) {
    yield put(getApprovedVenueDetailsFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getInvoiceAddresseSingleVenueSaga() {
  try {
    const response = yield call(VenuesAPI.getInvoiceAddressesSingleVenue);
    const { data } = response;

    yield put(getInvoiceAddressesSingleVenueSuccess(data));
  } catch (error: any) {
    yield put(getInvoiceAddressesSingleVenueFailed());
  }
}

function* addNewVenueSaga({ payload }: PayloadAction<AddNewVenueRequestPayload>) {
  const newConventions = payload?.convenzioni?.map((c) => c.name);
  const newCompanyType = payload?.tipo_azienda?.map((ct) => ct.label);
  const newCity: string | undefined = payload?.city?.label;
  const newProvince: string | undefined = payload?.province?.label;

  const newPayload = {
    ...payload,
    convenzioni: newConventions,
    tipo_azienda: newCompanyType,
    administrativeArea: payload?.administrativeArea?.label,
    regionId: payload?.administrativeArea?.value,
    city: newCity,
    province: newProvince,
    cityId: payload?.city?.value,
    provinceId: payload?.province?.value,
    invoiceAddress: {
      ...payload?.invoiceAddress,
      city: payload?.invoiceAddress?.city?.label,
      cityId: payload?.invoiceAddress?.city?.value,
      bill_region: payload?.invoiceAddress?.bill_region?.label,
      bill_state: payload?.invoiceAddress?.bill_state?.label,
      regionId: payload?.invoiceAddress?.bill_region?.value,
      provinceId: payload?.invoiceAddress?.bill_state?.value,
      // province
      // region
    },
    position: {
      lat: payload?.position?.lat,
      lng: payload?.position?.lng,
    },
    // @TODO: Mocked due BE by now
    vtigerTechnicalData: {
      headquarter: 0,
    },
  };

  try {
    const response = yield call(VenuesAPI.addNewVenue, {
      ...(newPayload as AddNewVenueRequestPayloadToBE),
    });

    if (response.status === 'success') {
      const newResponse = yield call(VenuesAPI.submitPracticeVenue);

      if (newResponse.status === 'success') {
        yield put(addNewVenueSuccess());
      } else {
        yield put(messageHandlerSet({ message: 'web.pu.Message.SubmitVenueError', type: 'error' }));
        yield put(addNewVenueFailed());
      }
    }
  } catch (error: any) {
    yield put(addNewVenueFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenueDetailsInvoiceAddressesSaga() {
  try {
    const response = yield call(VenuesAPI.getSingleVenueDetailsInvoiceAddresses);
    const { data } = response;

    yield put(getVenueDetailsInvoiceAddressesSuccess(data));
  } catch (error: any) {
    yield put(getVenueDetailsInvoiceAddressesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* putVenueSaga({ payload }: PayloadAction<PutVenueRequestPayload>) {
  const { id: locationId } = payload;
  try {
    const response = yield call(VenuesAPI.putSingleVenueDetails, { ...payload });

    if (response.status === 'success') {
      const newResponse = yield call(VenuesAPI.submitPracticeVenue);

      if (newResponse.status === 'success') {
        yield put(putVenueDetailsSuccess(response));
      } else {
        yield put(putVenueDetailsFailed({ status: 'failure' }));
        yield put(messageHandlerSet({ message: 'web.pu.Message.SubmitVenueError', type: 'error' }));
      }
    }
  } catch (error: any) {
    yield put(putVenueDetailsFailed({ status: 'failure' }));
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
    yield put(getVenueDetailsRequest({ locationId }));
  }
}

function* getVenueLocationAffiliationsSaga() {
  try {
    const response = yield call(VenuesAPI.getVenueLocationAffiliations);
    const { data } = response;

    yield put(getVenueAffiliationsSuccess(data));
  } catch (error: any) {
    yield put(getVenueAffiliationsFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenueLocationTypesSaga() {
  try {
    const response = yield call(VenuesAPI.getVenueLocationTypes);
    const { data } = response;

    yield put(getVenueTypesSuccess(data));
  } catch (error: any) {
    yield put(getVenueTypesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenueLocationAdditionalServicesSaga() {
  try {
    const response = yield call(VenuesAPI.getVenueAdditionalServicesTypes);
    const { data } = response;

    yield put(getVenueAdditionalServicesSuccess(data));
  } catch (error: any) {
    yield put(getVenueAdditionalServicesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenueCurrentPracticeSaga() {
  try {
    const response = yield call(VenuesAPI.getCurrentVenuePraticeDetails);
    const { data } = response;

    yield put(getCurrentVenuePraticeDetailsSuccess(data));
  } catch (error: any) {
    yield put(getCurrentVenuePraticeDetailsFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getAllVenuePartnersSaga() {
  try {
    const response = yield call(VenuesAPI.getAllVenuesPartner);
    const { results } = response;

    yield put(getAllVenuePartnersSuccess(results));
  } catch (error: any) {
    yield put(getAllVenuePartnersFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* venuesSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getAllVenuesRequest.type, getAllVenuesSaga);
  yield takeLatest(getAllApprovedVenuesRequest.type, getAllApprovedVenuesSaga);
  yield takeLatest(getVenueDetailsRequest.type, getVenueDetailsSaga);
  yield takeLatest(getApprovedVenueDetailsRequest.type, getApprovedVenueDetailsSaga);
  yield takeLatest(getInvoiceAddressesSingleVenueRequest.type, getInvoiceAddresseSingleVenueSaga);
  yield takeLatest(addNewVenueRequest.type, addNewVenueSaga);
  yield takeLatest(getVenueDetailsInvoiceAddressesRequest.type, getVenueDetailsInvoiceAddressesSaga);
  yield takeLatest(putVenueDetailsRequest.type, putVenueSaga);
  yield takeLatest(getVenueAffiliationsRequest.type, getVenueLocationAffiliationsSaga);
  yield takeLatest(getVenueTypesRequest.type, getVenueLocationTypesSaga);
  yield takeLatest(getVenueAdditionalServicesRequest.type, getVenueLocationAdditionalServicesSaga);
  yield takeLatest(getCurrentVenuePraticeDetailsRequest.type, getVenueCurrentPracticeSaga);
  yield takeLatest(getAllVenuePartnersRequest.type, getAllVenuePartnersSaga);
}

export default venuesSaga;

import { call, put, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import omit from 'lodash/omit';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { uploadAttachment } from 'utils/Api/attachments';
import { pushError } from 'containers/Alerts/actions';
import { setActiveFormAction } from 'containers/HomePage/LandingPage/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { USER_ROLE } from '../constants';

export function* getRegister({ data }) {
  const url = `auth/register`;
  const language = yield select(makeSelectLocale());
  try {
    yield put(showLoading());

    let profilePictureId;
    if (data.file && data.file[0]) {
      const { id } = yield call(uploadAttachment, data.file[0]);
      profilePictureId = id;
    }

    const params = omit(data, ['file']);
    const { status } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        ...params,
        profilePictureId,
        role: USER_ROLE,
        language,
      },
    });
    if (status === 'success') {
      yield put(pushError({ text: 'registerSuccess', typeError: 'success' }));
      yield put(setActiveFormAction({ activeForm: 'registerSuccess' }));
    }
  } catch (e) {
    // error 422
    const { packet, response } = e; // eslint-disable-line no-unused-vars
    if (response.status === 422) {
      yield put(pushError({ text: 'registerDataError' }));
    } else {
      yield put(pushError({ text: 'registerError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

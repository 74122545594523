import { createReducer } from '@reduxjs/toolkit';
import {
  getFAQListFailed,
  getFAQListRequest,
  getFAQListSuccess,
  getTicketsListFailed,
  getTicketsListRequest,
  getTicketsListSuccess,
  resetSearchFAQListFilters,
  resetSearchTicketsListFilters,
  setSearchFAQListFilters,
  setSearchTicketsListFilters,
  getAllPracticeTicketsRequest,
  getAllPracticeTicketsSuccess,
  getAllPracticeTicketsFailed,
} from './actions';

export interface InitialStateSupportProps {
  faq: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage: number | null;
    nextPage: number | null;
    faqList: [];
    faqListFilter: {
      textFilter: string;
    };
  };
  tickets: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage: number | null;
    nextPage: number | null;
    ticketsList: [];
    ticketsListFilter: {
      textFilter: string;
    };
  };
}

const initialState: InitialStateSupportProps = {
  faq: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    faqList: [],
    faqListFilter: {
      textFilter: '',
    },
  },
  tickets: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    ticketsList: [],
    ticketsListFilter: {
      textFilter: '',
    },
  },
};

export const supportReducers = createReducer(initialState, {
  [getFAQListRequest.type]: (state) => {
    state.faq.loading = true;
  },
  [getFAQListSuccess.type]: (state, action) => {
    state.faq.loading = false;
    state.faq.faqList = action.payload.results;
    state.faq.totalCount = action.payload.totalCount;
    state.faq.currentPage = action.payload.currentPage;
    state.faq.prevPage = action.payload.prevPage;
    state.faq.nextPage = action.payload.nextPage;
  },
  [getFAQListFailed.type]: (state) => {
    state.faq.loading = false;
  },
  [setSearchFAQListFilters.type]: (state, action) => {
    state.faq.faqListFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchFAQListFilters.type]: (state) => {
    state.faq.faqListFilter.textFilter = '';
  },
  [getTicketsListRequest.type]: (state) => {
    state.tickets.loading = true;
  },
  [getTicketsListSuccess.type]: (state, action) => {
    state.tickets.loading = false;
    state.tickets.ticketsList = action.payload.results;
    state.tickets.totalCount = action.payload.totalCount;
    state.tickets.currentPage = action.payload.currentPage;
    state.tickets.prevPage = action.payload.prevPage;
    state.tickets.nextPage = action.payload.nextPage;
  },
  [getTicketsListFailed.type]: (state) => {
    state.tickets.loading = false;
  },
  [getAllPracticeTicketsRequest.type]: (state) => {
    state.tickets.loading = true;
  },
  [getAllPracticeTicketsSuccess.type]: (state, action) => {
    state.tickets.loading = false;
    state.tickets.ticketsList = action.payload.results;
    state.tickets.totalCount = action.payload.totalCount;
    state.tickets.currentPage = action.payload.currentPage;
    state.tickets.prevPage = action.payload.prevPage;
    state.tickets.nextPage = action.payload.nextPage;
  },
  [getAllPracticeTicketsFailed.type]: (state) => {
    state.tickets.loading = false;
  },
  [setSearchTicketsListFilters.type]: (state, action) => {
    state.tickets.ticketsListFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchTicketsListFilters.type]: (state) => {
    state.tickets.ticketsListFilter.textFilter = '';
  },
});

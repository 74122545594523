import { defineMessages } from 'react-intl';

export default defineMessages({
  impersonateMessage: {
    id: 'web.partner.components.ImpersonateBar.impersonateMessage',
    defaultMessage:
      'You are logged in as {firstName} {lastName}, to return to your workspace click',
  },
  stopImpersonate: {
    id: 'web.partner.components.ImpersonateBar.stopImpersonate',
    defaultMessage: 'here.',
  },
});

import moment from 'moment';
import reduce from 'lodash/reduce';

const DAY_FORMAT = 'DD';
const DAY_LONG_FORMAT = 'dddd';
const MONTH_LONG_FORMAT = 'MMMM';
const YEAR_FORMAT = 'YYYY';
const FULL_DATE_FORMAT = 'DD/MM/YYYY';
const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm aa';

const TIME_FORMAT = 'HH:mm';
const TIME_EN_FORMAT = 'h:mm a';
const TIME_LONG_FORMAT = 'HH:mm:ss';

const format = (value, FORMAT) => moment(value).format(FORMAT);

export const dateFormatter = date =>
  moment(date).isValid() ? moment(date).format(FULL_DATE_FORMAT) : '--/--/----';

export const hoursFormatter = date =>
  moment(date).isValid() ? moment(date).format(TIME_FORMAT) : '--:--';

export const showTimeShort = data => format(data, TIME_FORMAT);
export const showTimeLong = data => format(data, TIME_LONG_FORMAT);
export const showTimeEn = data =>
  moment(data, TIME_FORMAT).format(TIME_EN_FORMAT);

export const showDate = data => format(data, FULL_DATE_FORMAT);
export const showDateTimeEn = data =>
  moment(data, FULL_DATE_TIME_FORMAT).toISOString();

export const showDateTimeLongEn = data => `
  ${format(data, DAY_LONG_FORMAT)}, ${format(data, MONTH_LONG_FORMAT)}
  ${format(data, DAY_FORMAT)} ${format(data, YEAR_FORMAT)}
`;

export const formatDateRange = dateRange => {
  const arrayString = dateRange.map(({ from, to }) => {
    const toMoment = moment(to);

    if (toMoment.isSame(from)) return showDate(to);

    if (!toMoment.isSame(from, 'year'))
      return `${showDate(from)} - ${showDate(to)}`;

    if (!toMoment.isSame(from, 'month'))
      return `${format(from, 'DD/MM')} - ${showDate(to)}`;

    return `${format(from, DAY_FORMAT)}-${showDate(to)}`;
  });
  return reduce(arrayString, (sum, n) => `${sum}, ${n}`);
};

export const formatPrice = (priceCent = 0, toFixed) =>
  Number(priceCent / 100.0).toFixed(typeof toFixed === 'number' ? toFixed : 2);
/*
  convert date format "Thu Mar 07 2019 00:00:00 GMT+0100" to "2019-03-07T00:00:00.000Z"
  excluding the time zone from the conversion
*/
export const convertDateStringToUtcString = dateString =>
  moment
    .utc({
      year: dateString.getFullYear(),
      month: dateString.getMonth(),
      day: dateString.getDate(),
    })
    .toISOString();

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const showTimeFromSec = timeInSeconds =>
  moment.utc(timeInSeconds * 1000).format('HH:mm');

export const showDateRange = dateRange => {
  if (dateRange.length === 0) return '';
  const dateStart = dateRange[0].from;
  const dateEnd = dateRange[0].to;

  if (moment(dateStart).year() !== moment(dateEnd).year())
    return `${moment(dateStart).format('DD/MM/YYYY')}-${moment(dateEnd).format(
      'DD/MM/YYYY',
    )}`;
  else if (moment(dateStart).month() !== moment(dateEnd).month())
    return `${moment(dateStart).format('DD/MM')}-${moment(dateEnd).format(
      'DD/MM/YYYY',
    )}`;
  else if (moment(dateStart).day() !== moment(dateEnd).day())
    return `${moment(dateStart).format('DD')}-${moment(dateEnd).format(
      'DD/MM/YYYY',
    )}`;
  return `${moment(dateStart).format('DD/MM/YYYY')}`;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { intlShape } from 'react-intl';
import TermsModal from 'containers/HomePage/LandingPage/FormRegister/TermsModal';
import messages from './messages';

function UpdateTermsModal({
  intl,
  form,
  handleSubmit,
  isTosModalVisible,
  termsOfUse,
  isPrivacyPolicyModalVisible,
  isImpersonated,
  privacyPolicy,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <TermsModal
        form={form}
        content={termsOfUse}
        extraText={intl.formatMessage(messages.updateRequired, {
          subject: intl.formatMessage(messages.tos),
        })}
        isOpen={
          !isImpersonated && isTosModalVisible && !isPrivacyPolicyModalVisible
        }
        onAccepted={handleSubmit}
      />

      <TermsModal
        form={form}
        content={privacyPolicy}
        extraText={intl.formatMessage(messages.updateRequired, {
          subject: intl.formatMessage(messages.privacy),
        })}
        isOpen={!isImpersonated && isPrivacyPolicyModalVisible}
        onAccepted={handleSubmit}
      />
    </Form>
  );
}

UpdateTermsModal.propTypes = {
  intl: intlShape.isRequired,
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isTosModalVisible: PropTypes.bool,
  isPrivacyPolicyModalVisible: PropTypes.bool,
  termsOfUse: PropTypes.shape({}),
  privacyPolicy: PropTypes.shape({}),
  isImpersonated: PropTypes.bool.isRequired,
};

UpdateTermsModal.defaultProps = {
  isTosModalVisible: false,
  isPrivacyPolicyModalVisible: false,
};

export default UpdateTermsModal;

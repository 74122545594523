import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Dropdown } from 'reactstrap';
/* import { platformReady } from 'utils/env'; */
import ProfileIcon from 'images/icons/profile/profile';
import SettingsIcon from 'images/icons/profile/settings';
import InvoiceIcon from 'images/icons/profile/invoice';
import LogoutIcon from 'images/icons/profile/logout';
import Avatar from 'components/shared/Avatar';
import {
  MenuIconsPatients,
  MenuIconsSpecialties,
  MenuIconsAgenda,
  MenuIconsRequests,
  MenuIconsCommunications,
} from '@shared/icons';
import MenuItem from './MenuItem';
import {
  Wrapper,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  Hello,
  Name,
  TextWrapper,
  RemoteFilePreviewWrapper,
  MenuWrapper,
} from './styles';
import messages from './messages';

function HeaderProfileSwitcher({
  onLogout,
  intl,
  isOpen,
  toggle,
  goToStep,
  userProfileData,
  partnerPracticeStatus,
  unreadCommunicationsCount,
}) {
  const showLinks = true;
  /* partnerPracticeStatus === 'approved' && platformReady === 'true'; */

  return (
    <Wrapper>
      {showLinks && (
        <MenuWrapper>
          <MenuItem
            Icon={() => <MenuIconsPatients width={28} height={28} />}
            Label={() => <FormattedMessage {...messages.patients} />}
            Tooltip={intl.formatMessage(messages.patients)}
            to="/patients"
          />
          <MenuItem
            Icon={() => <MenuIconsSpecialties width={28} height={28} />}
            Label={() => <FormattedMessage {...messages.partnerServices} />}
            Tooltip={intl.formatMessage(messages.partnerServices)}
            to="/partner-services"
          />
          <MenuItem
            Icon={() => <InvoiceIcon width={28} height={28} />}
            Label={() => <FormattedMessage {...messages.invoices} />}
            Tooltip={intl.formatMessage(messages.invoices)}
            to="/invoices"
          />
          <MenuItem
            Icon={() => <MenuIconsAgenda width={28} height={28} />}
            Label={() => <FormattedMessage {...messages.agenda} />}
            Tooltip={intl.formatMessage(messages.agenda)}
            to="/agenda"
          />
          <MenuItem
            Icon={() => (
              <MenuIconsCommunications
                width={25}
                height={30}
                showAlarm={unreadCommunicationsCount > 0}
              />
            )}
            Label={() => <FormattedMessage {...messages.communications} />}
            Tooltip={intl.formatMessage(messages.communications)}
            to="/communications"
          />
        </MenuWrapper>
      )}
      {partnerPracticeStatus !== 'approved' && (
        <MenuWrapper>
          <MenuItem
            Icon={() => <MenuIconsRequests width={28} height={28} />}
            Label={() => <FormattedMessage {...messages.partnerPractice} />}
            Tooltip={intl.formatMessage(messages.partnerPractice)}
            to="/partner-registration"
            onClick={() =>
              goToStep({
                step: 0,
                wizardIsOpen: false,
              })
            }
          />
          <MenuItem
            Icon={() => (
              <MenuIconsCommunications
                width={25}
                height={30}
                showAlarm={unreadCommunicationsCount > 0}
              />
            )}
            Label={() => <FormattedMessage {...messages.communications} />}
            Tooltip={intl.formatMessage(messages.communications)}
            to="/communications"
          />
        </MenuWrapper>
      )}
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle caret color="link">
          <RemoteFilePreviewWrapper>
            <Avatar
              attachmentId={userProfileData && userProfileData.profilePictureId}
              firstName={(userProfileData && userProfileData.firstName) || ''}
              lastName={(userProfileData && userProfileData.lastName) || ''}
              alt="photo profile"
            />
          </RemoteFilePreviewWrapper>

          <TextWrapper>
            <Hello>{intl.formatMessage(messages.hello)}</Hello>
            <Name>
              {userProfileData &&
                `${userProfileData.firstName} ${userProfileData.lastName}`}
            </Name>
          </TextWrapper>
        </DropdownToggle>

        <DropdownMenu right>
          {partnerPracticeStatus === 'approved' ? (
            <Fragment>
              <NavLink
                tag={RRNavLink}
                exact
                to="/edit-profile/main"
                active={window.location.pathname.startsWith('/edit-profile')}
                onClick={toggle}
              >
                <ProfileIcon />
                {intl.formatMessage(messages.myProfile)}
              </NavLink>
              <NavLink
                tag={RRNavLink}
                exact
                to="/settings"
                active={window.location.pathname.startsWith('/settings')}
                onClick={toggle}
              >
                <SettingsIcon />
                {intl.formatMessage(messages.settings)}
              </NavLink>
              {
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/invoices"
                  active={window.location.pathname.startsWith('/invoices')}
                  onClick={toggle}
                >
                  <InvoiceIcon />
                  {intl.formatMessage(messages.invoices)}
                </NavLink>
              }
            </Fragment>
          ) : (
            <Fragment>
              <NavLink
                tag={RRNavLink}
                exact
                to="/edit-profile/change-email-password"
                active={window.location.pathname.startsWith('/edit-profile')}
                onClick={toggle}
              >
                <ProfileIcon />
                {intl.formatMessage(messages.myProfile)}
              </NavLink>
            </Fragment>
          )}
          <NavLink tag={RRNavLink} exact to="/#logout" onClick={onLogout}>
            <LogoutIcon />
            {intl.formatMessage(messages.logout)}
          </NavLink>
        </DropdownMenu>
      </Dropdown>
    </Wrapper>
  );
}

HeaderProfileSwitcher.propTypes = {
  onLogout: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  userProfileData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  partnerPracticeStatus: PropTypes.string,
  unreadCommunicationsCount: PropTypes.number,
};

HeaderProfileSwitcher.defaultProps = {
  isOpen: false,
  partnerPracticeStatus: null,
  userProfileData: null,
  unreadCommunicationsCount: 0,
};

export default HeaderProfileSwitcher;

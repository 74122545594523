import { defineMessages } from 'react-intl';

export default defineMessages({
  noOption: {
    id: 'web.shared.components.InputAutosuggest.noOption',
    defaultMessage: 'Type something to see some results',
  },
  placeholder: {
    id: 'web.shared.components.InputAutosuggest.placeholder',
    defaultMessage: 'Select...',
  },
});

/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { LOCATION_CHANGE, connectRouter } from 'connected-react-router/immutable';
import profile from 'containers/EditProfile/reducers';
// import { connectRouter } from 'connected-react-router/immutable';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory as createHistory } from 'history';
import { fromJS } from 'immutable';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as reduxFormReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { basename } from 'utils/env';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

export const history = createHistory({
  basename,
});

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    // route: routeReducer,
    router: connectRouter(history), // to fix CombineState and type incompatibility
    language: languageProviderReducer,
    form: reduxFormReducer,
    loadingBar: loadingBarReducer,
    profile,
    ...injectedReducers,
  });
}

import { createAction } from '@reduxjs/toolkit';
import {
  GetAllPracticesAttachmentsRequestPayload,
  GetAllPracticesAttachmentsSuccessPayload,
  GetAllPracticesInvoicesRequestPayload,
  GetAllPracticesInvoicesSuccessPayload,
} from './types';

export const getAllPracticesAttachmentsRequest = createAction<GetAllPracticesAttachmentsRequestPayload>(
  'ACTION/GET_ALL_PRACTICES_ATTACHMENTS_REQUEST',
);
export const getAllPracticesAttachmentsSuccess = createAction<GetAllPracticesAttachmentsSuccessPayload>(
  'ACTION/GET_ALL_PRACTICES_ATTACHMENTS_SUCCESS',
);
export const getAllPracticesAttachmentsFailed = createAction('ACTION/GET_ALL_PRACTICES_ATTACHMENTS_FAILED');
export const resetAllPracticesAttachments = createAction('ACTION/RESET_ALL_PRACTICES_ATTACHMENTS_LIST');

export const getAllPracticesInvoicesRequest = createAction<GetAllPracticesInvoicesRequestPayload>(
  'ACTION/GET_ALL_PRACTICES_INVOICES_REQUEST',
);
export const getAllPracticesInvoicesSuccess = createAction<GetAllPracticesInvoicesSuccessPayload>(
  'ACTION/GET_ALL_PRACTICES_INVOICES_SUCCESS',
);
export const getAllPracticesInvoicesFailed = createAction('ACTION/GET_ALL_PRACTICES_INVOICES_FAILED');
export const resetAllPracticesInvoices = createAction('ACTION/RESET_ALL_PRACTICES_INVOICES_LIST');

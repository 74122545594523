import styled from 'styled-components';
import {
  DropdownToggle as _dropdownToggle,
  DropdownMenu as _dropdownMenu,
} from 'reactstrap';
import {
  ArrowDown as _ad,
  Agenda as _agenda,
  ArrowLeft as _al,
  ArrowRight as _ar,
} from '../../../icons';

export const HeaderWrapper = styled.div`
  padding: 0 0.833em 1em 0.833em;
  display flex;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
`;

export const DateTitle = styled.h3`
  font-size: 2.5rem;
  font-family: ${({ theme }) => theme.fontFamilySerif};
  text-transform: capitalize;
`;

export const DropdownToggle = styled(_dropdownToggle)`
  background-color: ${({ theme }) => theme.white};
  padding: 0;
  border: none;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.white} !important;
    box-shadow: none !important;
  }

  svg {
    color: ${({ theme }) => theme.black};
  }
`;

export const DropdownMenu = styled(_dropdownMenu)`
  width: 250px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.35);
`;

export const Agenda = styled(_agenda)`
  font-size: 3rem;
`;

export const ArrowDown = styled(_ad)`
  margin-left: 5px;
  font-size: 1rem;
`;

export const ArrowLeft = styled(_al)`
  cursor: pointer;
  margin: 5px;
`;

export const ArrowRight = styled(_ar)`
  cursor: pointer;
  margin: 5px;
`;
export const PreviousMonth = styled.div`
  font-family: 'Eksell medium', 'Times new roman', 'Arial', 'Noto Sans', serif;
  font-size: 2.4rem;
  color: #e2e2e2;
  text-transform: capitalize;
  cursor: pointer;
  margin: 5px;
  overflow: hidden;
  & span {
    position: relative;
    left: -50%;
  }
`;
export const NextMonth = styled.div`
  font-family: 'Eksell medium', 'Times new roman', 'Arial', 'Noto Sans', serif;
  font-size: 2.4rem;
  color: #e2e2e2;
  text-transform: capitalize;
  cursor: pointer;
  margin: 5px;
  overflow: hidden;
  & span {
    position: relative;
    left: 50%;
  }
`;

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts

export const AccordionStyles = {
  // The parts of the component
  parts: ['button', 'item', 'panel'],
  // The styles all button have in common
  baseStyle: {
    button: {
      color: 'black',
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'xl',
      fontWeight: 700,
      paddingBottom: '10px',
      paddingLeft: '0px',
      paddingRight: '0px',

      _hover: {
        backgroundColor: 'white',
      },
    },
    item: {},
    panel: {
      paddingBottom: '24px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '24px',
    },
  },
  sizes: {},
  // Two variants: primary and outline
  variants: {},
  // The default size and variant values
  defaultProps: {},
};

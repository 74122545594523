// eslint-disable-next-line import/no-anonymous-default-export
export default {
  goToPrivacyPolicy: {
    category: 'WEB_PARTNERS/Footer/provacyPolicy',
    action: 'Click on Privacy Policy link',
  },
  goToTermsOfUse: {
    category: 'WEB_PARTNERS/Footer/termsOfUse',
    action: 'Click on Terms of Use link',
  },
  goToCopyright: {
    category: 'WEB_PARTNERS/Footer/copyright',
    action: 'Click on Copyright link',
  },
};

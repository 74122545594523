import { call, select, put, race, take } from 'redux-saga/effects';
import { hostname, hostnameHealthBox } from 'utils/env';
import { stopSubmit } from 'redux-form/immutable';
import { selectIsImpersonated, selectRefreshToken, selectGeolocation } from 'containers/Auth/selectors';
import { push } from 'connected-react-router/immutable';
import {
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFail,
  getGeoLocationRequest,
  logoutRequest,
} from 'containers/Auth/actions';
import { makeSelectActiveFormName } from 'components/shared/Hocs/asyncReduxForm/selectors';
import { pushError } from 'containers/Alerts/actions';
import { getHeaders } from './getHeaders';
import { getFetchHeaders, remodelServerErrors } from './utils';

const validateResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response;
};

const readResponseAsJSON = (response) => {
  const json = response.json();
  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(json);
  }
  return Promise.reject(response);
};

const handleResponseError = (response) =>
  new Promise((resolve, reject) => {
    response
      .text() // response.json() doesn't appear to support sensible error handling of non-JSON
      .then((text) => {
        let jsonData = {};
        try {
          jsonData = JSON.parse(text); // try to do our own parsing of the json here
        } catch (err) {
          // no-op
        }
        return jsonData;
      })
      .then((packet) => {
        if (packet.error) {
          return reject(packet.error);
        }
        // if (response.status === 404) {
        //   return reject(new Error('404: not found')); // we treat this as a special code in a couple of places
        // }

        const error = { packet, response };
        return reject(error);
      });
  });

export const fetchWrapper = (url, options = {}) =>
  fetch(url.includes('http:') ? url : `${options.healthBox ? hostnameHealthBox : hostname}/${url}`, {
    ...options,
    headers: getFetchHeaders(url, options.headers),
  })
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(handleResponseError);

function* ApiWrapper({ url, method = 'GET', params, noCheckJWT, healthBox = false }) {
  const headers = yield call(getHeaders, noCheckJWT);
  const activeFormName = yield select(makeSelectActiveFormName());

  // METRICS
  const { latitude, longitude } = yield select(selectGeolocation());
  const metricsHeaders = {
    ...headers,
    metricsHeaders: {
      ...(latitude && longitude ? { 'metrics-geolocation': `${latitude}|${longitude}` } : {}),
      'metrics-touchpoint': 'WEB_PARTNER',
    },
  };

  const options = {
    method,
    healthBox,
    // headers,
    headers: metricsHeaders,
    body: params ? JSON.stringify({ ...params }) : undefined,
  };

  try {
    return yield call(fetchWrapper, url, options);
  } catch ({ packet, response }) {
    const isImpersonated = yield select(selectIsImpersonated());

    if (response.status === 403 && headers.jwtToken) {
      const refreshToken = yield select(selectRefreshToken());
      yield put(refreshTokenRequest(refreshToken));

      // race ---> JWTSuccess/JWTFail
      const { fail } = yield race({
        success: take(refreshTokenSuccess),
        fail: take(refreshTokenFail),
      });

      // Success call ApiWrapper
      if (fail) {
        yield call(logoutRequest());
        yield put(push('/login'));
      } else {
        // REFRESH GEOLOCATION
        yield put(getGeoLocationRequest());
        return yield call(ApiWrapper, { url, method, params });
      }
    }
    if (response.status === 401 && isImpersonated) {
      yield put(pushError({ messageId: 'notHavePermission' }));
    }
    if (response.status === 401 && !isImpersonated) {
      console.log('response.status === 401 && !isImpersonated', response);
      yield call(logoutRequest());
      // yield put(push('/'));
    }
    // packet.data.errors
    if (response.status === 422) {
      if (activeFormName) {
        // IF activeFormName EXIST we want to send errors to the form
        const { data } = packet;
        const { errors } = data || {};
        if (!!errors && Object.keys(errors).length) {
          yield put(stopSubmit(activeFormName, remodelServerErrors(errors)));
        }
      } else {
        // OTHERWISE we show an alert message on the top of page
        const { message } = packet || {};
        const text = message || null;
        yield put(pushError({ text: text || 'serverError' }));
      }
    }

    // eslint-disable-next-line
    throw { packet, response };
  }
}

export default ApiWrapper;

import styled from 'styled-components';
import { Container as _container } from 'reactstrap';

export const Description = styled.p`
  font-size: 1.6rem;
`;

export const DescriptionHeader = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
`;

export const DangerText = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.danger};
`;

export const HeaderTitle = styled.h1`
  padding: 80px 0 20px;
  color: ${p => p.theme.primary};
  font-weight: 700;
  font-size: 3.6rem;
  text-align: center;
`;

export const Container = styled(_container)`
  padding-bottom: 120px;
`;

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0 20px;
`;

export const Title = styled.h1`
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  max-width: 500px;
  margin: 0 0 20px;
  font-family: ${({ theme }) => theme.fontFamilySerif};
`;

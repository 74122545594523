import { palette } from '@apps/pu/theme/theme';
import { Spinner as CSpinner } from '@chakra-ui/react';
import { FC } from 'react';
import { Oval, ThreeDots } from 'react-loader-spinner';
import Spinner from 'react-spinkit';

interface IPULoader {
  color?: string;
  type?: 'old' | 'new' | 'horizontal' | 'oval';
  secondaryColor?: string;
  height?: number;
  strokeWidth?: number;
  width?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  radius?: number;
}

const PULoader: FC<IPULoader> = ({
  type = 'old',
  color = palette.YELLOW,
  secondaryColor = palette.YELLOWBG,
  height = 80,
  width = 80,
  strokeWidth = 5,
  radius = 5,
  size = 'md',
}) => {
  switch (type) {
    case 'new':
      return <CSpinner color={color} size={size} />;
    case 'oval':
      return (
        <Oval
          ariaLabel="loading-indicator"
          height={height}
          width={width}
          strokeWidth={strokeWidth}
          color={color}
          secondaryColor={secondaryColor}
        />
      );
    case 'horizontal':
      return <ThreeDots ariaLabel="loading-indicator" height={height} width={width} radius={radius} color={color} />;
    default:
      return <Spinner name="ball-spin-fade-loader" color={color} />;
  }
};

export default PULoader;

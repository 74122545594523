import styled from 'styled-components';

// export { default as Badge } from './Badge';
// export { default as SpinnerTable } from './SpinnerTable';
// export { default as DeleteButton } from './DeleteButton';

// Table Cell
// export { default as LinkCell } from './CellComponents/LinkCell';
// export { default as ChevronCell } from './CellComponents/ChevronCell';

// NEW COMPONENTS ***************************
export { default as Avatar } from './Avatar';
export { default as Button } from './Button';
export { default as Link } from './Link';
export { default as NavLink } from './NavLink';
export { default as Tab } from './Tab';
export { default as Row } from './Row';
export { default as Col } from './Col';
export { default as Label } from './Label';
export { default as GroupView } from './GroupView';
export { default as FilePreview } from './FilePreview/MyFilePreview';
export { default as AccordionSwitch } from './CollapsableRow'; // TODO: verificare corretto funzionamento ed eliminare il vecchio
export { default as Collapse } from './Collapse'; // TODO: verificare corretto funzionamento ed eliminare il vecchio
export { default as Spinner } from './Spinner';

export { default as Table } from './Table';
export { default as FilterHeader } from './FilterHeader';
export { default as Maps } from './Maps';
export { default as GMap } from './GMap';
export { default as SearchCard } from './SearchCard';
export { default as StarRating } from './StarRating';

// Form Components
export { default as Switch } from './Switch';
export { default as Input } from './Input';
export { default as InputPassword } from './InputPassword';
export { default as Select } from './Select';
export { default as CheckBox } from './Checkbox';
export { default as Radio } from './Radio';
export { default as InputAutosuggest } from './InputAutosuggest';
export { default as InputLocalAutosuggest } from './InputLocalAutosuggest';
export { default as InputGeosuggest } from './InputGeosuggest';
export { default as InputGeosuggest2 } from './InputGeosuggest2';
export { default as InputPhone } from './InputPhone';
export { default as DatePicker } from './Datepicker';
export { default as DateRange } from './DateRange';
export { default as BackHeader } from './BackHeader';
export { default as FileDropInput } from './FileDrop';
export { default as StatelessFileDropInput } from './StatelessFileDropInput';
export { default as LoadingSpinner } from './LoadingSpinner';
export { default as Slider } from './Slider';
export { default as TimePicker } from './TimePicker';
export { default as TimeRange } from './TimeRange';
export { default as ZipCodeInput } from './ZipCodeInput';
export * from './modalStyles';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '130px'};
`;

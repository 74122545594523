import React from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import { Wrapper, InputFake } from './styles';

function GroupView({
  label,
  children,
  titleLabel,
  bigMargin,
  noInput,
  ...props
}) {
  return (
    <Wrapper
      label={!!label}
      titleLabel={!!titleLabel}
      hasChildren={!!children}
      bigMargin={bigMargin}
      noInput={noInput}
      {...props}
    >
      <Label label={label} titleLabel={titleLabel} />
      {!noInput && <InputFake>{children || '-'}</InputFake>}
    </Wrapper>
  );
}

GroupView.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  bigMargin: PropTypes.bool,
  noInput: PropTypes.bool,
};

GroupView.defaultProps = {
  label: null,
  titleLabel: null,
  children: null,
  bigMargin: null,
  noInput: null,
};

export default GroupView;

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'web.partner.PatientBookingDetails.selfAnamnesis.title',
    defaultMessage: 'self anamnesis questionnaire',
  },
  subtitle: {
    id: 'web.partner.PatientBookingDetails.selfAnamnesis.subtitle',
    defaultMessage: 'Compiled self anamnesis questionnaire',
  },
  questionnaireFileName: {
    id: 'web.partner.PatientBookingDetails.selfAnamnesis.questionnaireFileName',
    defaultMessage: 'Questionnaire.pdf',
  },
  questionnaireDownload: {
    id: 'web.partner.PatientBookingDetails.selfAnamnesis.questionnaireDownload',
    defaultMessage: 'Download',
  },
});

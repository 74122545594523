import React from 'react';
import PropTypes from 'prop-types';
import { NavLinkRoute, ActiveLine, NavLinkStrap } from './styles';

function NavLink({
  children,
  to,
  handleClick,
  isOpen,
  disabled,
  activeLine,
  active,
  big,
  dispatch,
  ...props
}) {
  if (to)
    return (
      <NavLinkRoute
        isOpen={isOpen}
        to={to}
        activeClassName="active"
        disabled={disabled}
        active={active}
        big={big}
        {...props}
        onClick={handleClick}
      >
        {children}
        {activeLine && <ActiveLine disabled={disabled} />}
      </NavLinkRoute>
    );
  return (
    <NavLinkStrap
      isOpen={isOpen}
      disabled={disabled}
      onClick={handleClick}
      active={active}
      big={big}
      {...props}
    >
      {children}
      {!big && active && <ActiveLine disabled={disabled} />}
    </NavLinkStrap>
  );
}

NavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  activeLine: PropTypes.bool,
  active: PropTypes.bool,
  big: PropTypes.bool,
};

NavLink.defaultProps = {
  children: null,
  to: null,
  isOpen: false,
};

export default NavLink;

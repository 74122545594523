import { fromRemToPixel } from '../../utils/ie11Compatibility';
import omit from '../../utils/omitProps';
import styled, { css } from 'styled-components';

const sizing = css`
  box-sizing: border-box;
  height: ${({ theme, size }) => {
    if (size === 'sm') return theme.inputHeightSm;
    if (size === 'lg') return theme.inputHeightLg;
    return theme.inputHeight;
  }};
  border-radius: ${({ theme, size }) => {
    if (size === 'sm') return `${fromRemToPixel(theme.inputHeightSm) / 2}px`;
    if (size === 'lg') return `${fromRemToPixel(theme.inputHeightLg) / 2}px`;
    return `${fromRemToPixel(theme.inputHeight) / 2}px`;
  }};
  font-size: ${({ size, labelFontSize }) => {
    if (labelFontSize !== undefined) return labelFontSize;
    if (size === 'lg') return '1.6rem';
    if (size === 'sm') return '1.2rem';
    return '1.4rem';
  }};
`;

const primaryStyles = css`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
  &:active {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const primaryDarkStyles = css`
  background-color: ${({ theme }) => theme.primaryDark};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
  &:active {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const secondaryStyles = css`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.gray500};
  border-color: ${({ theme }) => theme.gray400};
  /* border: 1px solid; */
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.gray100};
  }
  &:active {
    background-color: ${({ outline, theme }) =>
      outline ? theme.primary : theme.primaryHover};
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.primaryHover};
  }
`;

const textLinkButtonStyles = css`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.gray500};
  border: none;
  cursor: pointer;
  text-decoration: underline;
  &:hover,
  &.active {
    text-decoration: none;
    color: ${({ theme }) => theme.white};
    span {
      color: currentColor;
      text-decoration: inherit;
    }
    background: ${({ theme }) => theme.primary};
  }
  &:active {
    background: ${({ theme }) => theme.primaryHover};
    text-decoration: underline;
  }
`;

const disabledStyles = css`
  &,
  &:active,
  &:hover,
  &:focus {
    background-color: ${({ outline, theme }) =>
      outline ? 'transparent' : theme.gray300} !important;
    border-color: ${({ outline, theme }) =>
      outline ? theme.gray300 : theme.primaryHover} !important;
    color: ${({ outline, theme }) =>
      outline ? theme.gray300 : theme.white} !important;
  }
  cursor: default !important;
`;

export const Button = styled(
  omit('button', [
    'icon',
    'iconColor',
    'isIconButton',
    'withArrow',
    'goTo',
    'textLinkButton',
    'outline',
  ]),
)`
  ${sizing};
  width: 100%;
  margin: 0;
  padding: ${({ withArrow }) => (withArrow ? '0 15px 0 25px' : '0 25px')};
  border: 0;
  outline: none !important;
  transition: background-color 200ms ease, border-color 300ms ease,
    color 200ms ease;
  cursor: pointer;
  ${({ color }) => color === 'primary' && primaryStyles};
  ${({ color }) => color === 'primaryDark' && primaryDarkStyles};
  ${({ color }) => color === 'secondary' && secondaryStyles};
  ${({ disabled }) => disabled && disabledStyles};
  ${({ width }) => width && `width: ${width} !important;`};
  ${({ margin }) => margin && `margin: ${margin} !important;`};
  ${({ isIconButton, size, theme }) => {
    if (isIconButton) {
      let width = theme.inputHeight;
      if (size === 'sm') width = theme.inputHeightSm;
      if (size === 'lg') width = theme.inputHeightLg;
      return css`
        width: ${width} !important;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      `;
    }
    return '';
  }};
  ${({ iconColor, theme }) => {
    if (iconColor === 'light')
      return css`
        border-color: ${theme.gray300};
      `;
    if (iconColor === 'secondary')
      return css`
        border: none;
        background-color: ${theme.yellowLight};
        &:hover {
          background-color: ${theme.primaryHover};
          color: ${theme.white};
        }
      `;
    return '';
  }};
  ${({ textLinkButton }) => textLinkButton && textLinkButtonStyles};
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  white-space: nowrap;
  & > span {
    color: inherit;
    font-size: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ withArrow, withIcon }) =>
    (withArrow || withIcon) && `justify-content: space-between;`};
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  padding-right: ${({ isIconButton }) => (isIconButton ? 0 : '15px')};
`;

import apiWrapper, { fetchWrapper } from 'utils/Api/fetchWrapper';

export function* uploadAttachment(file) {
  const url = `attachments`;
  const params = {
    filename: file.name,
  };

  const { data } = yield apiWrapper({
    url,
    method: 'POST',
    params,
  });

  const {
    postPolicy: { formData, postURL: postUrl },
    postPolicy,
    attachment: { id: attachmentId },
  } = data;

  const attachmentData = new FormData();
  attachmentData.append('file', file);
  Object.keys(formData).forEach(key => {
    attachmentData.append(key, formData[key]);
  });

  // TODO: Handle 413 error
  yield fetch(postUrl, {
    method: 'POST',
    body: attachmentData,
  })
    .then(res => console.log('===>> ', res))
    .catch(error => {
      console.log('err ==>> ', error);
      throw error;
    });

  return {
    id: attachmentId,
    postPolicy,
  };
}

export const loadRemoteAttachmentById = (attachmentId, headers) =>
  fetchWrapper(`attachments/${attachmentId}`, {
    method: 'GET',
    headers,
  }).then(({ data }) => ({
    filename: data.fileName,
    url: data.url,
  }));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalHeader, ModalBody, ModalFooter } from '@components';
import walkingManPc from '@shared/images/person-image/walking-man.png';
import { FormattedMessage } from 'react-intl';
import {
  HelpVideomeetingMsgTitleWrapper,
  HelpVideomeetingMsgMessageWrapper,
  HelpVideomeetingImgWrapper,
} from './styles';
import messages from './messages';

function HelpVideomeetingContent({
  currentPage,
  totalPages,
  changePage,
  skip,
  exit,
}) {
  return (
    <Fragment>
      <ModalHeader shortPadding>
        <HelpVideomeetingMsgTitleWrapper>
          <FormattedMessage {...messages.beforeStarting} />
        </HelpVideomeetingMsgTitleWrapper>
      </ModalHeader>
      <ModalBody>
        <HelpVideomeetingImgWrapper>
          <img src={walkingManPc} alt="" height="250" hspace="50" />
        </HelpVideomeetingImgWrapper>
        <HelpVideomeetingMsgMessageWrapper>
          <FormattedMessage {...messages[`msgPage${currentPage}`]} />
        </HelpVideomeetingMsgMessageWrapper>
      </ModalBody>
      <ModalFooter>
        {currentPage < totalPages ? (
          <Fragment>
            <Button color="primary" size="lg" onClick={changePage}>
              <FormattedMessage {...messages.continue} />
            </Button>
            <Button color="link" size="lg" onClick={skip}>
              <FormattedMessage {...messages.skip} />
            </Button>
          </Fragment>
        ) : (
          <Button color="primary" size="lg" onClick={exit}>
            <FormattedMessage {...messages.ok} />
          </Button>
        )}
      </ModalFooter>
    </Fragment>
  );
}

HelpVideomeetingContent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
};

export default HelpVideomeetingContent;

import React from 'react';

const DocIMG = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(77, 226, 94)"
    />
    <path
      fill-rule="evenodd"
      d="M 5.222 12.89 L 3.322 12.89 L 3.322 12.07 L 3.572 12.07 L 3.572 9.82 L 3.322 9.82 L 3.322 9 L 5.222 9 L 5.222 9.82 L 4.972 9.82 L 4.972 12.07 L 5.222 12.07 L 5.222 12.89 ZM 10.942 9 L 10.942 9.82 L 10.692 9.82 L 10.692 12.07 L 10.942 12.07 L 10.942 12.89 L 9.052 12.89 L 9.052 12.07 L 9.292 12.07 L 9.292 10.32 L 8.442 12.89 L 7.702 12.89 L 6.822 10.35 L 6.822 12.07 L 7.062 12.07 L 7.062 12.89 L 5.422 12.89 L 5.422 12.07 L 5.672 12.07 L 5.672 9.82 L 5.422 9.82 L 5.422 9 L 7.712 9 L 8.232 10.56 L 8.732 9 L 10.942 9 ZM 14.907 10.89 L 14.907 11.64 L 14.657 11.64 L 14.657 12.64 C 14.51 12.72 14.297 12.793 14.017 12.86 C 13.737 12.927 13.43 12.96 13.097 12.96 C 12.477 12.96 11.99 12.797 11.637 12.47 C 11.284 12.137 11.107 11.627 11.107 10.94 C 11.107 10.24 11.284 9.73 11.637 9.41 C 11.99 9.09 12.49 8.93 13.137 8.93 C 13.457 8.93 13.757 8.963 14.037 9.03 C 14.31 9.097 14.517 9.17 14.657 9.25 L 14.657 10.45 L 13.407 10.45 L 13.407 10.21 C 13.407 10.07 13.377 9.97 13.317 9.91 C 13.257 9.85 13.164 9.82 13.037 9.82 C 12.91 9.82 12.82 9.85 12.767 9.91 C 12.707 9.97 12.677 10.07 12.677 10.21 L 12.677 11.67 C 12.677 11.81 12.707 11.913 12.767 11.98 C 12.82 12.04 12.91 12.07 13.037 12.07 C 13.164 12.07 13.257 12.04 13.317 11.98 C 13.377 11.913 13.407 11.81 13.407 11.67 L 13.407 11.64 L 13.137 11.64 L 13.137 10.89 L 14.907 10.89 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocIMG;

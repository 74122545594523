import React from 'react';

const Search = props => (
  <svg
    viewBox="0 0 22 22"
    {...props}
    width="28"
    height="24"
    style={{ marginTop: '2px' }}
  >
    <path
      d="M15.018 16.232a9.083 9.083 0 11.942-.942c.11.035.219.1.32.202l2.5 2.5c-.707-.707-1.767.353-1.06 1.06l2.5 2.5c.707.708 1.767-.353 1.06-1.06l-2.5-2.5-3.762-1.76zm2.702 2.82l1.06-1.06-1.06 1.06zM9.083 16.94a7.583 7.583 0 100-15.167 7.583 7.583 0 000 15.167z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default Search;

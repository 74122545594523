import styled from 'styled-components';
import { FormGroup as _formGroup } from 'reactstrap';
import ReactSelect from 'react-select';
import omit from '../../utils/omitProps';

export const getBorderColor = (theme, dirty, error, isDisabled) => {
  if (isDisabled) return theme.gray200;
  if (error) return theme.danger;
  if (dirty) return '#ffcc00';
  return theme.gray300;
};

export const getBorderRadius = (size, theme) => {
  if (size === 'sm') return theme.inputBorderRadiusSm;
  if (size === 'lg') return theme.inputBorderRadiusLg;
  return theme.inputBorderRadius;
};

export const getHeight = (size, theme) => {
  if (size === 'sm') return theme.inputHeightSm;
  if (size === 'lg') return theme.inputHeightLg;
  return theme.inputHeight;
};

export const getDropdownIndicatorSize = size => {
  if (size === 'sm') return '1rem';
  if (size === 'lg') return '2.2rem';
  return '1.6rem';
};

export const getDropdownIndicatorPadding = size => {
  if (size === 'sm') return '0 10px 0 0';
  if (size === 'lg') return '0 10px 0 0';
  return '0 5px 0 0';
};

export const getFontSize = size => {
  if (size === 'sm') return '1rem';
  if (size === 'lg') return '1.8rem';
  return '1.6rem';
};

export const getInputSpacer = (size, transparent) => {
  if (transparent) return '0';
  if (size === 'sm') return '0';
  if (size === 'lg') return '0 15px';
  return '0 10px';
};

export const getTopMenuSpacer = size => {
  if (size === 'sm') return '-10px';
  if (size === 'lg') return '-25px';
  return '-20px';
};

export const getMenuBorderRadius = size => {
  if (size === 'sm') return '0 0 10px 10px';
  if (size === 'lg') return '0 0 25px 25px';
  return '0 0 20px 20px';
};

export const getPaddingOption = size => {
  if (size === 'sm') return '10px 15px';
  if (size === 'lg') return '18px 30px';
  return '13px 25px';
};

export const getInputHeight = size => {
  if (size === 'sm') return '15px';
  if (size === 'lg') return '40px';
  return '30px';
};

export const FormGroup = styled(
  omit(_formGroup, ['haslabel', 'marginBottom', 'margin', 'maxWidth']),
)`
  margin: 0;
  width: 100%;
  margin-bottom: ${({ haslabel }) => (haslabel ? '30px' : '0')};
  height: auto;
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}` : ''};
  ${({ margin }) => (margin ? `margin: ${margin}` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};

  .form-control:focus {
    border-color: ${({ theme }) => theme.gray300};
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
  & span {
    color: ${({ theme }) => theme.red} !important;
    font-size: 1rem;
    text-align: left;
  }
`;

export const Select = styled(
  omit(ReactSelect, ['callbackOnChange', 'dirty']),
).attrs({
  styles: ({
    theme,
    dirty,
    error,
    isDisabled,
    size,
    hideArrow,
    removeBorderRight,
    transparent,
    active,
  }) => {
    const borderColor = getBorderColor(theme, dirty, error, isDisabled);
    return {
      dropdownIndicator: (styles, state) => ({
        ...styles,
        color: `${state.isDisabled ? `#d8d8d8` : theme.black}`,
        padding: getDropdownIndicatorPadding(size),
        fontSize: getDropdownIndicatorSize(size),
        transformOrigin: '30% 50%',
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        zIndex: state.selectProps.menuIsOpen && 3,
        display: (hideArrow || transparent) && 'none',
        ...(!active &&
          dirty && {
            borderColor: theme.warning,
          }),
      }),
      indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
      }),
      control: (styles, state) => ({
        ...styles,
        borderRadius: getBorderRadius(size, theme),
        border: transparent ? 'none' : `1px solid ${borderColor}`,
        minHeight: '20px',
        height: getHeight(size, theme),
        fontSize: getFontSize(size),
        padding: getInputSpacer(size, transparent),
        boxShadow: state.selectProps.menuIsOpen && '0 1px 8px #ABABAB',
        background: `${
          state.isDisabled && !transparent ? theme.gray100 : theme.white
        }`,
        overflow: 'hidden',
        ...(removeBorderRight && {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0',
          borderRight: 'none',
        }),
      }),
      input: styles => ({
        ...styles,
        margin: 0,
        height: getInputHeight(size),
        borderColor: 'theme.gray400',
      }),
      option: styles => ({
        ...styles,
        ':hover': {
          background: '#FFF0CC',
        },
        padding: getPaddingOption(size),
        color: theme.black,
      }),
      valueContainer: (styles, state) => ({
        ...styles,
        paddingLeft: '12px',
        zIndex: state.selectProps.menuIsOpen && 102,
      }),
      multiValue: styles => ({
        ...styles,
        backgroundColor: theme.primary,
        borderRadius: '3px',
      }),
      multiValueRemove: styles => ({
        ...styles,
      }),
      clearIndicator: (styles, state) => ({
        ...styles,
        zIndex: state.selectProps.menuIsOpen && 3,
      }),
      menu: styles => ({
        ...styles,
        maxHeight: '250px',
        margin: `${getTopMenuSpacer(size)} 1px 0`,
        border: 'none',
        zIndex: 101,
        paddingTop: '20',
        boxShadow: '0 8px 8px #ABABAB',
        width: 'calc(100% - 2px)',
        borderRadius: getMenuBorderRadius(size),
        overflow: 'hidden',
      }),
      menuList: styles => ({
        ...styles,
        paddingBottom: '20',
        maxHeight: '250px',
        fontSize: '1.4rem',
      }),
      singleValue: (styles, state) => ({
        ...styles,
        color:
          state.isDisabled && !transparent
            ? '#d8d8d8'
            : transparent && theme.black,
        marginLeft: '-2px',
        fontWeight: 600,
      }),
    };
  },
  theme: () => themeSelect => ({
    ...themeSelect,
    borderRadius: 0,
    colors: {
      ...themeSelect.colors,
      primary25: '#FFF0CC',
      primary: '#FFF0CC',
    },
  }),
})``;

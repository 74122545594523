import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from '@components';
import { CueBall } from 'components/shared';
import messages from './messages';
import { HeaderGroup, Title, HeaderTitle, Container } from './styles';

function PlatformOffline({ userProfileData }) {
  return (
    <Container>
      <HeaderGroup>
        <HeaderTitle>
          <FormattedMessage
            {...messages.dr}
            values={{
              name: `${userProfileData.firstName} ${userProfileData.lastName}`,
            }}
          />
        </HeaderTitle>
        <Title>
          <FormattedHTMLMessage {...messages.message} />
        </Title>
        <Row>
          <Col sm="12">
            <CueBall status="edit" />
          </Col>
        </Row>
      </HeaderGroup>
    </Container>
  );
}

PlatformOffline.propTypes = {
  userProfileData: PropTypes.shape({}).isRequired,
};

PlatformOffline.defaultProps = {};

export default PlatformOffline;

import React from 'react';

const EmptyStar = props => (
  <svg height="24px" viewBox="0 0 1024 1024" {...props}>
    <title>star-empty</title>
    <path
      fill="currentColor"
      d="M1024 397.05l-353.78-51.408L512 25.06 353.784 345.642 0 397.05l256 249.538-60.432 352.352L512 832.582 828.432 998.94l-60.434-352.352L1024 397.05zM512 753.498l-223.462 117.48 42.676-248.83-180.786-176.222 249.84-36.304L512 183.226l111.736 226.396 249.836 36.304-180.788 176.222 42.678 248.83L512 753.498z"
    />
  </svg>
);

export default EmptyStar;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../../../Button';
import { Wrapper, CancelWrap, CancelButton } from './styles';
import messages from './messages';

function BottomButtons({
  removeSingleFilter,
  removeFilter,
  apply,
  valid,
  isApplied,
  filtersApplied,
}) {
  return (
    <Wrapper>
      <CancelWrap>
        {isApplied && (
          <Fragment>
            <CancelButton onClick={removeSingleFilter}>
              <FormattedMessage {...messages.cancelThis} />
            </CancelButton>
            <span> | </span>
          </Fragment>
        )}
        {filtersApplied && (
          <CancelButton onClick={removeFilter}>
            <FormattedMessage {...messages.cancelAll} />
          </CancelButton>
        )}
      </CancelWrap>
      <Button color="secondary" width="130px" onClick={apply} disabled={!valid}>
        <FormattedMessage {...messages.apply} />
      </Button>
    </Wrapper>
  );
}

BottomButtons.propTypes = {
  removeSingleFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  isApplied: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
};

export default BottomButtons;

import React from 'react';
import PropTypes from 'prop-types';
import RemoteFilePreview from '../FilePreview/RemoteFilePreview';
import MyFilePreview from '../FilePreview/MyFilePreview';

function FileDecoratedPreview({
  input,
  file,
  disableClick,
  getData,
  contract,
  onDroppedFile,
  forcePracticeIcon,
}) {
  if (contract)
    return (
      <MyFilePreview
        filename={
          file ||
          ((input.value || {}).toJS
            ? input.value.get('name')
            : (input.value || {}).name)
        }
      />
    );

  const { postURL, formData } = getData || {};

  if (onDroppedFile)
    return (
      <RemoteFilePreview
        filename={file}
        forcePracticeIcon={forcePracticeIcon}
      />
    );

  return input && input.value && input.value.toJS ? (
    <RemoteFilePreview
      filename={
        getData
          ? `${postURL}/${formData.key}`
          : input.value && input.value.get('url')
      }
      url={
        getData
          ? `${postURL}/${formData.key}`
          : input.value && input.value.get('url')
      }
      disableClick={disableClick}
    />
  ) : (
    <RemoteFilePreview
      attachmentId={input.value && input.value.id}
      filename={file}
      url={getData ? `${postURL}/${formData.key}` : file}
    />
  );
}

FileDecoratedPreview.propTypes = {
  input: PropTypes.shape({}),
  file: PropTypes.any,
  disableClick: PropTypes.bool,
  getData: PropTypes.string.isRequired,
  contract: PropTypes.bool,
  onDroppedFile: PropTypes.func,
};

FileDecoratedPreview.defaultProps = {
  input: null,
  file: null,
  disableClick: null,
  contract: null,
  onDroppedFile: null,
};

export default FileDecoratedPreview;

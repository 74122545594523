import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import { change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import qs from 'qs';
// TODO: importate da partners
import { makeSelectLocale } from '../../../../app/containers/LanguageProvider/selectors';
import { selectFetchHeaders } from '../../../../app/components/shared/selectors';
import { fetchWrapper } from '../../../../app/utils/Api/fetchWrapper';
// TODO: *********************

import InputGeosuggest from './InputGeosuggest';

const getSuggestionsFetcher = (
  url,
  headers,
  queryParams,
  method,
  callbackAfterFetch,
) => (filter, filterBy, filterType) => {
  if (method === 'POST') {
    return fetchWrapper(url, {
      method,
      body: JSON.stringify({ filter }),
      headers,
    }).then(res => {
      if (callbackAfterFetch) return callbackAfterFetch(res);
      return res;
    });
  }
  const paramString = qs.stringify({ filter, ...queryParams });
  if (filterType && filterBy) {
    return fetchWrapper(
      filterType === 'querystring'
        ? `${url}?${filterBy}=${filter[0].value}`
        : `${url}/${filter[0].value}`,
      {
        method: 'GET',
        headers,
      },
    ).then(
      ({ data }) => (filterType === 'querystring' ? data.results : data) || [],
    );
  }
  return fetchWrapper(`public/${url}?${decodeURI(paramString)}`, {
    method: 'GET',
    headers,
  }).then(({ data }) => data.results || []);
};

const mapStateToProps = null;

const mapDispatchToProps = {
  onChangeValue: change,
  fetchHeaders: selectFetchHeaders,
  languageActive: makeSelectLocale(),
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(
    ({
      fetchHeaders,
      fillCityAutosuggest,
      queryParams,
      method = 'GET',
      callbackAfterFetch,
    }) => ({
      fetchSuggestions: getSuggestionsFetcher(
        (fillCityAutosuggest || {}).url || '',
        fetchHeaders,
        queryParams,
        method,
        callbackAfterFetch,
      ),
    }),
  ),
  withHandlers({
    onMapCreated: () => map => {
      map.setOptions({
        disableDefaultUI: true,
      });
    },
    onSuggestionsFetchRequested: ({
      fetchSuggestions,
      defaultFilters = [],
    }) => async (value, filterBy, filterType, callback) => {
      if (!value) {
        callback(null);
        return;
      }
      const filterToUse = [
        {
          field: filterBy,
          operator: 'contains',
          value,
        },
      ];
      try {
        const results = await fetchSuggestions(
          filterToUse.concat(defaultFilters),
          filterBy,
          filterType,
        );
        callback(results[0]);
      } catch (error) {
        callback(null);
      }
    },
    onAddressChange: ({ onChangeValue, fillCityAutosuggest }) => () => {
      onChangeValue(
        fillCityAutosuggest.formName,
        fillCityAutosuggest.fieldCity,
        null,
      );
      onChangeValue(
        fillCityAutosuggest.formName,
        fillCityAutosuggest.fieldCityId,
        null,
      );
      onChangeValue(
        fillCityAutosuggest.formName,
        fillCityAutosuggest.fieldZipCode,
        null,
      );
    },
  }),
  withStateHandlers(
    {
      selectedLocation: null,
    },
    {
      // eslint-disable-next-line
      onSuggestSelect: (
        _,
        {
          input,
          meta,
          onChangeValue,
          fillCityAutosuggest,
          onSuggestionsFetchRequested,
        },
      ) => locationData => {
        if (!isEmpty(locationData)) {
          const {
            location,
            description,
            gmaps: { address_components: addressComponents },
          } = locationData;
          input.onChange(description);

          const administrativeArea = addressComponents.find(_a => {
            const { types } = _a;
            return types.some(
              rowType => rowType === 'administrative_area_level_1',
            );
          });

          const country = addressComponents.find(_a => {
            const { types } = _a;
            return types.some(rowType => rowType === 'country');
          });

          const zipCode = addressComponents.find(_a => {
            const { types } = _a;
            return types.some(rowType => rowType === 'postal_code');
          });

          const city =
            ((country || {}).short_name || 'IT') !== 'IT'
              ? { long_name: (country || {}).long_name }
              : addressComponents.find(_a => {
                  const { types } = _a;
                  return types.some(rowType =>
                    [
                      'administrative_area_level_3',
                      'administrative_area_level_1',
                    ].includes(rowType),
                  );
                });

          const province = addressComponents.find(_a => {
            const { types } = _a;
            return types.some(
              rowType => rowType === 'administrative_area_level_2',
            );
          });

          const shortAddress =
            description &&
            description
              .split(',')
              .filter(
                el =>
                  el.toLowerCase().trim() !==
                    ((city || {}).long_name || '').toLowerCase().trim() &&
                  el.toLowerCase().trim() !==
                    ((province || {}).short_name || '').toLowerCase().trim() &&
                  el.toLowerCase().trim() !==
                    ((country || {}).long_name || '').toLowerCase().trim(),
              )
              .join(',');

          if (fillCityAutosuggest)
            onSuggestionsFetchRequested(
              (city && city.long_name) || '',
              fillCityAutosuggest.filterBy,
              fillCityAutosuggest.filterType,
              res => {
                onChangeValue(
                  fillCityAutosuggest.formName,
                  fillCityAutosuggest.fieldAddress,
                  fillCityAutosuggest.shortAddress ? shortAddress : description,
                );
                onChangeValue(
                  fillCityAutosuggest.formName,
                  fillCityAutosuggest.fieldCity,
                  res,
                );
                onChangeValue(
                  fillCityAutosuggest.formName,
                  fillCityAutosuggest.fieldCityId,
                  (res && res.id) || null,
                );

                onChangeValue(
                  fillCityAutosuggest.formName,
                  fillCityAutosuggest.fieldZipCode,
                  zipCode || res ? zipCode.long_name : null,
                );
              },
            );
          else {
            onChangeValue(
              meta.form,
              `${input.name.split('.')[0]}.city`,
              ((country || {}).short_name || 'IT') !== 'IT'
                ? (country || {}).long_name
                : city && city.long_name,
            );

            onChangeValue(
              meta.form,
              `${input.name.split('.')[0]}.administrativeArea`,
              ((country || {}).short_name || 'IT') !== 'IT'
                ? (country || {}).long_name
                : administrativeArea && administrativeArea.long_name,
            );

            onChangeValue(
              meta.form,
              `${input.name.split('.')[0]}.position`,
              locationData.location,
            );

            onChangeValue(
              meta.form,
              `${input.name.split('.')[0]}.zipCode`,
              zipCode && zipCode.long_name,
            );
          }
          return {
            selectedLocation: location,
          };
        }
        return {
          selectedLocation: null,
        };
      },
    },
  ),
)(InputGeosuggest);

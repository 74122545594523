import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = props => (
  <svg width={20} height={17} {...props}>
    <title>CTA/edit</title>
    <g fill={props.color} fillRule="evenodd">
      <path d="M6.768 9.07l2.927 2.732 7.827-7.827a.5.5 0 0 0 0-.707L15.4 1.147a.502.502 0 0 0-.708 0L6.768 9.07zm-.477.924l-.238 2.62 2.778-.251-2.54-2.37zM5.5 13.668a.501.501 0 0 1-.498-.545l.354-3.89a.496.496 0 0 1 .144-.308L13.986.44a1.501 1.501 0 0 1 2.122-.001l2.12 2.122a1.501 1.501 0 0 1 0 2.12l-8.484 8.487a.501.501 0 0 1-.31.144l-3.89.354a.648.648 0 0 1-.044.002zM19.5 17H.5a.5.5 0 0 1 0-1h19a.5.5 0 0 1 0 1" />
    </g>
  </svg>
);

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#888',
};

export default SvgComponent;

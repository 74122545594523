import { compose, withHandlers } from 'recompose';
import { event } from 'react-ga';
// import withGoogleAnalyticsInfo from '../../Hocs/withGoogleAnalyticsInfo';
import Link from './Link';

export default compose(
  // withGoogleAnalyticsInfo,
  withHandlers({
    handleClick: ({
      analytics,
      googleAnalyticsInitialized,
      cookiePolicyConsent,
      onClick,
    }) => () => {
      if (
        analytics &&
        googleAnalyticsInitialized &&
        cookiePolicyConsent === 'true'
      )
        event(analytics);
      if (onClick) onClick();
    },
  }),
)(Link);

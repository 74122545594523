import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';

export function* setNewPassword({ data: { oldPassword, newPassword } }) {
  const url = `auth/pass`;
  const params = { oldPassword, newPassword, role: 'partner' };

  try {
    yield put(showLoading());
    yield call(fetchWrapper, { url, method: 'PATCH', params });

    yield put(
      pushError({ text: 'changePasswordSuccess', typeError: 'success' }),
    );
  } catch ({ packet, response }) {
    if (response.status === 422) {
      if (packet.message && packet.message !== '')
        yield put(pushError({ text: packet.message }));
      else yield put(pushError({ text: 'changePasswordDataError' }));
    } else {
      yield put(pushError({ text: 'changePasswordError' }));
    }
  } finally {
    yield put(hideLoading());
  }
}

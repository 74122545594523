import React from 'react';
import PropTypes from 'prop-types';

const MyChangeRequest = ({ colored, ...props }) => (
  <svg viewBox="0 0 172.3 172.3" width="1em" height="1em" {...props}>
    <title>MENU-Details Requests</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          d="M125.28 172.949H0.879883V0.649414H173.18V125.049C173.154 137.745 168.098 149.913 159.121 158.891C150.144 167.868 137.976 172.923 125.28 172.949V172.949ZM125.28 168.949C133.963 168.949 142.45 166.375 149.669 161.551C156.889 156.727 162.515 149.871 165.838 141.849C169.161 133.828 170.03 125.001 168.336 116.485C166.643 107.969 162.462 100.147 156.322 94.0074C150.182 87.8679 142.36 83.6868 133.844 81.9929C125.329 80.299 116.502 81.1684 108.48 84.4911C100.459 87.8138 93.6021 93.4406 88.7783 100.66C83.9545 107.879 81.3799 116.367 81.3799 125.049C81.3931 136.688 86.0227 147.847 94.2527 156.077C102.483 164.307 113.641 168.936 125.28 168.949V168.949ZM58.4299 168.949H106.12C97.8964 165.348 90.8477 159.513 85.7744 152.106C80.7012 144.699 77.8068 136.019 77.4199 127.049H58.4199L58.4299 168.949ZM4.87988 168.949H54.4299V127.049H4.87988V168.949ZM58.4299 123.049H77.4299C77.8168 114.08 80.7112 105.399 85.7844 97.9928C90.8577 90.5862 97.9064 84.7508 106.13 81.1494H58.4399L58.4299 123.049ZM4.87988 123.049H54.4299V81.1494H4.87988V123.049ZM125.28 77.1494C134.597 77.1623 143.709 79.8866 151.505 84.99C159.3 90.0933 165.441 97.3551 169.18 105.889V35.2494H119.63V77.1494H125.28ZM58.4299 77.1494H115.63V35.2494H58.4299V77.1494ZM4.87988 77.1494H54.4299V35.2494H4.87988V77.1494ZM4.87988 31.2494H169.18V4.64943H4.87988V31.2494Z"
          fill={colored ? '#e11c23' : 'currentColor'}
          // strokeMiterlimit="10"
          // strokeWidth="4px"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.55 134.45L131.26 117.74L133.13 119.62L116.43 136.33L114.55 134.45ZM151.71 112.65L119.94 144.48L101.06 149.87L106.45 130.98L138.27 99.1601C138.516 98.9137 138.808 98.7182 139.13 98.5848C139.452 98.4515 139.797 98.3828 140.145 98.3828C140.493 98.3828 140.838 98.4515 141.16 98.5848C141.482 98.7182 141.774 98.9137 142.02 99.1601L151.77 108.91C152.015 109.158 152.208 109.452 152.337 109.775C152.467 110.099 152.53 110.444 152.525 110.793C152.519 111.141 152.444 111.485 152.304 111.803C152.164 112.122 151.962 112.41 151.71 112.65V112.65ZM133.85 107.29L143.58 117.03L149.83 110.78L140.1 101.04L133.85 107.29L143.58 117.03L133.85 107.29ZM104.85 146L107.61 145.22L105.61 143.22L104.85 146ZM106.48 140.33L110.53 144.38L118.48 142.11L141.69 118.91L131.95 109.17L108.75 132.38L106.48 140.33Z"
          fill={colored ? '#e11c23' : 'currentColor'}
          // strokeMiterlimit="10"
          // strokeWidth="4px"
          // fill="none"
        />
      </g>
    </g>
  </svg>
);

MyChangeRequest.propTypes = {
  colored: PropTypes.bool,
};

MyChangeRequest.defaultProps = {
  colored: null,
};

export default MyChangeRequest;

import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { hideFooterHeader, contactUsMail } from 'utils/env';
import {
  BadgeAppStore,
  BadgeGooglePlay,
  LogoLinkedin,
  LogoFacebook,
  LogoInstagram,
} from '@shared/icons';
import {
  FooterWrapper,
  InfoBox,
  GoodmeLogo,
  Copyright,
  BrandWrapper,
  LegalsWrapper,
  LinkWrapper,
  Content,
  CtaWrapper,
  Legals,
  Link,
  MailLink,
  AonData,
  AonDataHighlighted,
} from './styles';
import messages from './messages';
import analytics from './analytics';

function Footer({ showCta }) {
  return (
    <FooterWrapper>
      <GoodmeLogo />
      <Content>
        {!hideFooterHeader &&
          showCta && (
            <CtaWrapper>
              <div id="linksWrapper">
                <div className="links">
                  <h3>
                    <FormattedMessage {...messages.goodMe} />
                  </h3>
                  <Link to="#aboutUs">
                    <FormattedMessage {...messages.aboutUs} />
                  </Link>
                  <br />
                  <Link to="#workWithUs">
                    <FormattedMessage {...messages.workWithUs} />
                  </Link>
                  <br />
                  <Link to="#mediaRoom">
                    <FormattedMessage {...messages.mediaRoom} />
                  </Link>
                  <br />
                  <Link to="#news">
                    <FormattedMessage {...messages.news} />
                  </Link>
                </div>
                <div className="links">
                  <h3>
                    <FormattedMessage {...messages.community} />
                  </h3>
                  <Link to="#doctors">
                    <FormattedMessage {...messages.doctors} />
                  </Link>
                  <br />
                  <Link to="#structures">
                    <FormattedMessage {...messages.structures} />
                  </Link>
                  <br />
                  <Link to="#partners">
                    <FormattedMessage {...messages.partners} />
                  </Link>
                </div>
                <div className="links">
                  <h3>
                    <FormattedMessage {...messages.needHelp} />
                  </h3>
                  <Link to="#faq">
                    <FormattedMessage {...messages.faq} />
                  </Link>
                  <br />
                  <Link to="#tutorial">
                    <FormattedMessage {...messages.tutorial} />
                  </Link>
                  <br />
                  <Link to="#contactUs">
                    <FormattedMessage {...messages.contactUs} />
                  </Link>
                </div>
              </div>
              <div id="socialWrapper">
                <div>
                  <h3>
                    <FormattedMessage {...messages.downloadApp} />
                  </h3>
                  <Link to="#AppStore">
                    <BadgeAppStore
                      width="140"
                      height="40"
                      className="badgeAppStore"
                    />
                  </Link>
                  <br />
                  <Link to="#GooglePlay">
                    <BadgeGooglePlay width="140" height="40" />
                  </Link>
                </div>
                <div>
                  <h3>
                    <FormattedMessage {...messages.followUs} />
                  </h3>
                  <Link to="#Linkedin">
                    <LogoLinkedin width="30" height="30" />
                  </Link>
                  <Link to="#Facebook">
                    <LogoFacebook
                      width="30"
                      height="30"
                      className="logoFacebook"
                    />
                  </Link>
                  <Link to="#Instagram">
                    <LogoInstagram width="30" height="30" />
                  </Link>
                </div>
              </div>
            </CtaWrapper>
          )}
        <LegalsWrapper>
          <BrandWrapper>
            <InfoBox>
              <AonDataHighlighted>
                <FormattedMessage {...messages.row1} />
              </AonDataHighlighted>
              <AonData>
                <FormattedMessage {...messages.row2} />
              </AonData>
              <AonData>
                <FormattedHTMLMessage {...messages.row3} />
              </AonData>
              <AonData>
                <FormattedMessage {...messages.row4} />
              </AonData>
              <AonData>
                <FormattedMessage {...messages.row5} />
              </AonData>
            </InfoBox>
          </BrandWrapper>
          <Legals>
            <InfoBox alignRight>
              <LinkWrapper>
                <Link
                  to="/privacy-policy"
                  analytics={analytics.goToPrivacyPolicy}
                >
                  <FormattedMessage {...messages.privacyPolicy} />
                </Link>
                <Link to="/term-of-use" analytics={analytics.goToTermsOfUse}>
                  <FormattedMessage {...messages.termOfUse} />
                </Link>
              </LinkWrapper>
              <LinkWrapper>
                <MailLink href={`mailto:${contactUsMail}`}>
                  <FormattedMessage {...messages.contact} />
                </MailLink>
                <Link to="/copyright" analytics={analytics.goToCopyright}>
                  <Copyright>
                    <FormattedMessage {...messages.copyright} />
                  </Copyright>
                </Link>
              </LinkWrapper>
            </InfoBox>
          </Legals>
        </LegalsWrapper>
      </Content>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  showCta: PropTypes.bool.isRequired,
};

export default Footer;

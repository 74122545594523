import React from 'react';

const Career = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>PROFILE-Career</title>
    <path
      d="M26.7 7.3h-5.63V5.57A2.17 2.17 0 0 0 18.9 3.4h-7.8a2.17 2.17 0 0 0-2.17 2.17V7.3H3.3A1.3 1.3 0 0 0 2 8.6v9.65a1.32 1.32 0 0 0 .86 1.23v6a1.3 1.3 0 0 0 1.3 1.3h21.68a1.3 1.3 0 0 0 1.3-1.3v-6a1.32 1.32 0 0 0 .86-1.23V8.6a1.3 1.3 0 0 0-1.3-1.3zM9.8 5.57a1.3 1.3 0 0 1 1.3-1.3h7.8a1.3 1.3 0 0 1 1.3 1.3V7.3h-.87V5.57a.43.43 0 0 0-.43-.43h-7.8a.43.43 0 0 0-.43.43V7.3H9.8zm8.67 1.73h-6.94V6h6.94zm7.8 18.21a.43.43 0 0 1-.43.43H4.16a.43.43 0 0 1-.43-.43v-5.89l9.54 1.32V22a1.3 1.3 0 0 0 1.3 1.3h.86a1.3 1.3 0 0 0 1.3-1.3v-1.1l9.54-1.32zM15.87 22a.44.44 0 0 1-.44.43h-.86a.44.44 0 0 1-.44-.43v-2.6a.44.44 0 0 1 .44-.43h.86a.44.44 0 0 1 .44.43zm11.27-3.79a.43.43 0 0 1-.38.43h-.12L16.73 20v-.62a1.3 1.3 0 0 0-1.3-1.3h-.86a1.3 1.3 0 0 0-1.3 1.3V20l-10-1.38a.43.43 0 0 1-.38-.43V8.6a.44.44 0 0 1 .44-.43H26.7a.44.44 0 0 1 .44.43z"
      fill="currentColor"
    />
  </svg>
);

export default Career;

/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import { SpinnerComponent as Spinner } from 'components/shared/Spinner';
import fromPairs from 'lodash/fromPairs';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  pure,
  withHandlers,
  withStateHandlers,
  setDisplayName,
  branch,
  renderComponent,
} from 'recompose';
import { createSelector } from 'reselect';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { masterLanguage } from 'utils/env';
import { appLocales } from '../../i18n';
import { makeSelectLocale } from './selectors';
import { PageWrapper } from './styles';

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export default compose(
  setDisplayName('LanguageProvider'),
  pure,
  connect(mapStateToProps),
  withStateHandlers(
    {
      isLoading: true,
      remoteMessages: {},
    },
    {
      setLoading: () => (loading) => ({
        isLoading: loading,
      }),
      setMessages:
        ({ remoteMessages: prevMessages }) =>
        (messages) => ({
          remoteMessages: {
            ...prevMessages,
            ...messages,
          },
        }),
    },
  ),
  withHandlers({
    fetchTranslations:
      ({ setLoading, setMessages }) =>
      async () => {
        try {
          // const { data: remoteLocales } = await fetchWrapper('locales');
          // Bypassed locales
          const remoteLocales = { it_IT: 'Italiano' };
          const supportedLocales = Object.keys(remoteLocales || {}).filter((remoteLocale) =>
            appLocales.includes(remoteLocale.replace('_', '-')),
          );

          const allLocaleMessages = await Promise.all(
            supportedLocales.map(async (locale) => {
              const { data: messages } = await fetchWrapper(`translations/${locale.replace('-', '_')}`);
              return [locale.replace('_', '-'), messages];
            }),
          );

          setMessages(fromPairs(allLocaleMessages));
        } catch (e) {
          // Failed to load remote locales
        } finally {
          setLoading(false);
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchTranslations();
    },
  }),
  branch(
    ({ isLoading }) => isLoading,
    renderComponent(() => (
      <PageWrapper>
        <Spinner isLoading fadeIn="quarter" />
      </PageWrapper>
    )),
  ),
  mapProps(({ children, locale, messages, remoteMessages }) => {
    const saneLocale = locale.replace('_', '-');
    return {
      children,
      defaultLocale: masterLanguage,
      key: saneLocale,
      locale: saneLocale,
      messages: {
        ...messages[saneLocale],
        ...remoteMessages[saneLocale],
      },
    };
  }),
)(IntlProvider);

import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { setActiveFormAction } from 'containers/HomePage/LandingPage/actions';

export function* resetPassword({ email }) {
  if (!email) return;
  const url = `auth/pass/reset`;
  try {
    const { status } = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: { email },
    });
    if (status === 'success') {
      yield put(setActiveFormAction({ activeForm: 'registerSuccess' }));
    }
  } catch (e) {
    yield put(pushError({ text: e.packet.message }));
  }
}

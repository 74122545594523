import { defineMessages } from 'react-intl';

export default defineMessages({
  areYouSureYouWantToProceedWithTheCancellation: {
    id:
      'web.partner.components.DestructiveOperationConfirmModal.areYouSureYouWantToProceedWithTheCancellation',
    defaultMessage: 'Are you sure you want to proceed with the cancellation?',
  },
  thisActionIsFinalAndCannotBeUndone: {
    id:
      'web.partner.components.DestructiveOperationConfirmModal.thisActionIsFinalAndCannotBeUndone',
    defaultMessage: "This action is final and you can't undo it.",
  },
  confirmDestructiveOperation: {
    id:
      'web.partner.components.DestructiveOperationConfirmModal.confirmDestructiveOperation',
    defaultMessage: 'Yes, proceed',
  },
  noWait: {
    id: 'web.partner.components.DestructiveOperationConfirmModal.noWait',
    defaultMessage: 'No, wait a moment',
  },
});

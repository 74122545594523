/*
 *
 * LanguageProvider actions
 *
 */
import { createAction } from 'redux-actions-helpers';

export const changeLocale = createAction(
  'APP/LANGUAGE/CHANGE_LOCALE',
  languageLocale => ({
    languageLocale,
  }),
);

export const localesRequest = createAction('APP/LANGUAGE/LOCALES_REQUEST');

export const localesResponse = createAction(
  'APP/LANGUAGE/SET_LOCALES',
  ({ remoteLocales }) => ({
    remoteLocales,
  }),
);

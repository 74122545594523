import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import { FormGroup, ErrorMessage } from '../FormStyles';
import Label from '../Label';
import GMap from '../GMap';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import { InputGeosuggest } from './styles';
import messages from './messages';

function MyInputGeosuggest({
  intl,
  input,
  meta,
  label,
  titleLabel,
  className,
  onSuggestSelect,
  onAddressChange,
  selectedLocation,
  onMapCreated,
  position,
  requiredfield,
  marginBottom,
  customPlaceholder,
  onlyItaly,
  hideMap,
  ...props
}) {
  const { name } = input;
  const { google } = window;
  const { error, warning, visited, active, dirty } = meta;

  // TODO: indagare perchè non scatena meta.touched per sostituire meta.visited
  const hasErrors = visited && !!error;
  const viewMap = !error && selectedLocation;
  const haslabel = !!titleLabel || !!label;
  const placeholder =
    customPlaceholder || intl.formatMessage(messages.placeholder);

  return (
    <FormGroup
      className={className}
      haslabel={haslabel}
      marginBottom={marginBottom}
    >
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          name={name}
          requiredfield={!!requiredfield}
        />
      )}
      <InputGeosuggest
        {...input}
        initialValue={input?.value}
        id={name}
        {...props}
        active={active}
        dirty={dirty}
        onChange={onAddressChange}
        location={google?.maps.LatLng(53.558572, 9.9278215)} // new google.map Error
        radius="20"
        onSuggestSelect={onSuggestSelect}
        error={hasErrors}
        placeholder={placeholder}
        country={onlyItaly ? 'it' : null}
      />
      {hasErrors &&
        ((error && (
          <ErrorMessage>
            <FormattedMessage
              values={typeof error !== 'object' ? {} : error}
              {...errorMessages[isString(error)]}
            />
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}

      {(viewMap || position) && !(hideMap || false) && (
        <GMap
          lat={(viewMap && selectedLocation.lat) || position.lat}
          lng={(viewMap && selectedLocation.lng) || position.lng}
        />
      )}
    </FormGroup>
  );
}

MyInputGeosuggest.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSuggestSelect: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  selectedLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  onMapCreated: PropTypes.func.isRequired,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  requiredfield: PropTypes.bool,
  placeholder: PropTypes.string,
  marginBottom: PropTypes.string,
  hideMap: PropTypes.bool,
  customPlaceholder: PropTypes.string,
  onlyItaly: PropTypes.string,
};

MyInputGeosuggest.defaultProps = {
  type: null,
  className: null,
  input: null,
  meta: null,
  label: null,
  titleLabel: null,
  selectedLocation: null,
  requiredfield: false,
  placeholder: null,
  marginBottom: null,
  hideMap: false,
  customPlaceholder: null,
  onlyItaly: null,
};

export default MyInputGeosuggest;

import styled, { css } from 'styled-components';
import { FormGroup as _formGroup } from 'reactstrap';
import Geosuggest from 'react-geosuggest';
import { Gmaps as _gmaps } from 'react-gmaps';
import omit from '../../utils/omitProps';
import {
  getBorderRadius,
  getHeight,
  getFontSize,
  getTopMenuSpacer,
  getMenuBorderRadius,
  getPaddingOption,
} from '../FormStyles';

export const InputGeosuggest = styled(Geosuggest).attrs({
  style: ({ theme, size }) => ({
    input: {
      position: 'relative',
      outline: 0,
      display: 'block',
      width: '100%',
      height: getHeight(size, theme),
      padding: getPaddingOption(size),
      lineHeight: 1.5,
      fontSize: getFontSize(size, theme),
      color: theme.black,
      backgroundColor: 'transparent',
      borderRadius: getBorderRadius(size, theme),
      border: 'none',
      zIndex: 102,
    },
    suggests: {
      zIndex: 101,
      backgroundColor: theme.white,
      position: 'absolute',
      border: `1px solid ${theme.gray400}`,
      borderTop: 'none',
      marginTop: getTopMenuSpacer(size),
      paddingTop: '30px',
      left: '-1px',
      right: '-1px',
      paddingLeft: 0,
      borderRadius: getMenuBorderRadius(size),
      overflow: 'scroll',
      maxHeight: '250px',
    },
    suggestItem: {
      listStyle: 'none',
      fontSize: '1.6rem',
      padding: getPaddingOption(size),
    },
  }),
})`
  border: 1px solid ${({ theme }) => theme.gray400};
  border-color: ${({ theme, error }) => (error ? theme.danger : '')} !important;
  border-radius: ${({ theme, size }) => getBorderRadius(size, theme)};
  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out';
  z-index: 100;
  position: relative;
  ${({ active, dirty, theme }) =>
    !active &&
    dirty &&
    css`
      border-color: ${theme.warning};
    `};

  .geosuggest__input {
    background-color: transparent;
  }

  .geosuggest__suggests--hidden {
    max-height: 0 !important;
    overflow: hidden !important;
    border-width: 0 !important;
    padding: 0 !important;
  }
`;

export const FormGroup = styled(omit(_formGroup, ['hasLabel']))`
  width: 100%;
  margin: 0;
  margin-bottom: ${({ haslabel }) => (haslabel ? '22px' : '0')};
  min-height: 50px;
`;

export const Gmaps = styled(_gmaps)`
  margin-top: 20px;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
`;

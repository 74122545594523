import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { pushError } from './actions';

const initialState = fromJS({
  text: null,
  typeError: null,
});

export default handleActions(
  {
    [pushError]: (state, { text, typeError }) =>
      state.set('text', text).set('typeError', typeError || 'danger'),
  },
  { initialState },
);

import styled from 'styled-components';
import {
  Modal as _modal,
  ModalHeader as _modalHeader,
  ModalBody as _modalBody,
  ModalFooter as _modalFooter,
} from 'reactstrap';
import _button from './Button';
import { addCSSProperty, arrayCSSProperty } from '../utils/addCSSProperty';
import omit from '../utils/omitProps';

// // Modal base style
// export const Modal = styled(_modal).attrs({
//   centered: true,
// })`
//   .modal-content {
//     border-radius: 30px;
//     padding: 0 10px;
//   }
// `;

// // NEW COMPONENTS ***************************

export const Modal = styled(_modal).attrs({
  centered: true,
})`
  padding: 0;
  .modal-content {
    min-height: 250px;
    padding: 20px 20px;
    border-radius: 30px;
  }
  .modal-body {
    color: ${({ theme }) => theme.black};
    padding: 0;
  }
  .modal-footer {
    display: block;
    padding: 0px;
    border: none;
  }
`;

export const ModalHeader = styled(
  omit(_modalHeader, ['shortPadding', 'noPadding', ...arrayCSSProperty]),
)`
  padding: 0;
  border: none;

  .modal-title {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    padding: 10px 25px 25px;
    margin: 0;
    width: 100%;
  }

  ${({ shortPadding }) => shortPadding && 'padding-top: 33px;'};
  ${({ noPadding }) => noPadding && 'padding: 0;'};
  ${addCSSProperty};
`;

export const Submessage = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 300;
  color: ${p => p.theme.gray300};
`;

export const ModalBody = styled(_modalBody)`
  text-align: center;
  justify-content: center;
  border: none;
  padding: 5px;
`;

export const ModalFooter = styled(_modalFooter)`
  border: none;
  display: block;
  flex-direction: column;
  text-align: center;
  padding: 20px 100px;
  button + button {
    margin-top: 20px;
  }
`;

export const Round = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 30px;
  background-color: ${({ success, theme }) => success && theme.success};
  background-color: ${({ rejected, theme: { danger } }) => rejected && danger};
  margin-left: calc(50% - 12px);
`;

export const CloseButton = styled(_button)`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 4rem;
  width: auto;
  padding: 0;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 220px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

export const BackgroundWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 0;
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
`;

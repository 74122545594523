import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import { event } from 'react-ga';
import Button from './Button';

const mapDispatchToProps = {
  goTo: push,
};

export default compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    handleClick:
      ({ analytics, onClick, to, goTo }) =>
      () => {
        if (analytics) event(analytics);
        if (onClick) onClick();
        if (to) goTo(to);
      },
  }),
)(Button);

import styled, { css } from 'styled-components';

export const InputPhoneContainer = styled.div`
  .react-tel-input {
    #phone-form-control {
      font-size: 1.6rem;
      border-radius: 50px;
      height: 50px;
      padding: 3px 12px 3px 50px;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      font-family: 'Nunito Sans';
      ${({ active, dirty, theme }) =>
        !active &&
        dirty &&
        css`
          border-color: ${theme.warning};
        `};
    }
    .flag-dropdown {
      z-index: auto;
      /* border-radius: 50px 0 0 50px; */
      background-color: transparent;
      /* border-right: 0; */
      border: 0;
      height: calc(100% - 10px);
      margin-top: 5px;
      &.open-dropdown {
        border-radius: 50px 0 0 50px;
        background-color: transparent;
        border-right: 0;
      }
      .selected-flag {
        padding-left: 15px;
      }
      &:hover .selected-flag,
      &:focus .selected-flag,
      &:active .selected-flag {
        background-color: transparent;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, BasicLabel, TitleLabel } from './styles';

function Label({ label, titleLabel, name, requiredfield }) {
  const asteriskForRequiredField = requiredfield ? '*' : '';

  return (
    <Wrapper>
      {titleLabel && <TitleLabel for={name}>{titleLabel}</TitleLabel>}
      {label && (
        <BasicLabel
          for={name}
        >{`${label}${asteriskForRequiredField}`}</BasicLabel>
      )}
    </Wrapper>
  );
}

Label.propTypes = {
  label: PropTypes.string,
  titleLabel: PropTypes.string,
  name: PropTypes.string,
  requiredfield: PropTypes.bool,
};

Label.defaultProps = {
  label: null,
  titleLabel: null,
  name: null,
  requiredfield: false,
};

export default Label;

import { createAction } from 'redux-actions-helpers';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';

export const profileRequest = createAction('APP/AUTH/PROFILE/REQUEST');
export const profileResponse = createAction('APP/AUTH/PROFILE/RESPONSE', ({ data }) => ({
  data,
}));

export const partnerProfileResponse = createAction('APP/AUTH/PROFILE/PARTNER_RESPONSE', ({ partnerData }) => ({
  partnerData,
}));

export const editProfileRequest = createAction(
  'APP/AUTH/PROFILE/EDIT_PROFILE_REQUEST',
  ({ data, confirm, partnerRegistration, analytics }) => ({
    data,
    confirm,
    partnerRegistration,
    analytics,
  }),
);

export const editPartnerProfileRequest = createAction(
  'APP/AUTH/PROFILE/EDIT_PARTNER_PROFILE_REQUEST',
  ({ data, confirm, partnerRegistration }) => ({
    data,
    confirm,
    partnerRegistration,
  }),
);

export const setNewPasswordResponse = createAction('APP/AUTH/PROFILE/SET_NEW_PASSWORD', (data) => data);

export const setNewEmailResponse = createAction('APP/AUTH/PROFILE/SET_NEW_EMAIL', (data) => data);

export const cleanProfileDataOnLogout = createAction('APP/AUTH/PROFILE/LOGOUT');

export const editProfile = createAction('APP/AUTH/PROFILE/SET_EDIT_PROFILE', (data) => ({ data }));

export const profileSummaryRequest = createAction('APP/AUTH/PROFILE/SUMMARY/REQUEST');

export const profileSummaryResponse = createAction('APP/AUTH/PROFILE/SUMMARY/RESPONSE', (data) => ({
  data,
}));

export const locationChange = createAction(LOCATION_CHANGE);

export const deleteAccount = createAction('APP/AUTH/PROFILE/DELETE', ({ intl }) => ({ intl }));

export const portAccountData = createAction('APP/AUTH/PROFILE/PORTABILITY', ({ callback, intl, userNotes }) => ({
  callback,
  intl,
  userNotes,
}));

export const amendAccountData = createAction('APP/AUTH/PROFILE/AMEND', ({ callback, intl, userNotes }) => ({
  callback,
  intl,
  userNotes,
}));

export const getIbanDataRequest = createAction('APP/AUTH/PROFILE/GET_IBAN_DATA_REQUEST', ({ iban, formData }) => ({
  iban,
  formData,
}));

export const getIbanDataResponse = createAction('APP/AUTH/PROFILE/GET_IBAN_DATA_RESPONSE');

import styled from 'styled-components';
import { FormGroup as _formGroup } from 'reactstrap';
import omit from '../../utils/omitProps';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${({ fromFormGroup }) => (fromFormGroup ? '1em' : '0')};
  ${({ fromFormGroup }) => fromFormGroup && 'line-height: 1'};
  width: ${({ checkboxPosition }) =>
    checkboxPosition === 'right' ? 0 : '25px'};
  width: ${({ checkboxPosition }) =>
    checkboxPosition === 'right' ? '100%' : 'auto'};
  div > input {
    cursor: pointer;
    position: absolute;
    height: 20px;
    width: 100%;
    top: -3px;
    opacity: 0;
    z-index: 100;
  }

  div > input + label {
    color: ${({ theme }) => theme.gray600};
    cursor: pointer;
    display: inline;
    font-size: 1.6rem;
    min-height: 17px;
    padding-left: ${({ checkboxPosition }) =>
      checkboxPosition === 'right' ? 0 : '25px'};
    display: ${({ checkboxPosition }) =>
      checkboxPosition === 'right' && 'flex'};
    position: relative;
    vertical-align: middle;
    &:before {
      content: '';
      position: absolute;
      left: ${({ checkboxPosition }) =>
        checkboxPosition === 'right' ? null : '0'};
      right: ${({ checkboxPosition }) => checkboxPosition === 'right' && '0'};
      top: 0px;
      width: 20px;
      height: 20px;
      border: 1px solid ${({ theme }) => theme.gray600};
      background-color: ${({ theme }) => theme.white};
      border-radius: 50%;
      transition: all 300ms;
    }
    &:after {
      content: '';
      border: solid ${({ theme }) => theme.primary};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      width: 5px;
      height: 10px;
      position: absolute;
      top: 4px;
      left: ${({ checkboxPosition }) =>
        checkboxPosition === 'right' ? null : '8px'};
      right: ${({ checkboxPosition }) => checkboxPosition === 'right' && '7px'};
      transition: all 200ms;
    }
  }
  div > input:checked + label {
    &:before {
      border-color: ${({ theme }) => theme.primary};
    }
  }
  div > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  div > input:disabled + label {
    &:before {
      border-color: ${({ theme }) => theme.gray200};
      background-color: ${({ theme }) => theme.gray200};
    }
    &:after {
      border-color: ${({ theme }) => theme.white};
    }
  }
`;

export const FormGroup = styled(omit(_formGroup, ['marginBottom']))`
  width: 100%;
  margin: 0;
  height: ${({ height }) => height || '35px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '13px'};
  display: flex;
  align-items: center;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
`;

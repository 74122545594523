import { extendTheme, theme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { StepsStyleConfig as Steps } from '@componentsPU/StepIndicator/theme';
import { AccordionStyles as Accordion } from './components/accordionStyles';
import { ButtonStyles as Button } from './components/buttonStyles';
import { CheckboxStyles as Checkbox } from './components/checkboxStyles';
import { InputStyles as Input } from './components/inputStyles';
import { ModalStyles as Modal } from './components/modalStyles';
import { RadioStyles as Radio } from './components/radioStyles';
import { TableStyles as Table } from './components/tableStyles';
import { TabsStyles as Tabs } from './components/tabsStyles';

// Use https://openchakra.app/ to make experiments with ChakraUI components and props

const breakpoints = createBreakpoints({
  base: '320px',
  xs: '360px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1366px',
});

export const palette = {
  BLACK: '#000',
  WHITE: '#fff',
  YELLOW: '#FCB731',
  YELLOWBG: '#FCF1D9',
  LIGHT_ORANGE: '#FF7761',
  LIGHT_ORANGEBG: '#FFEBE7',
  LIGHT_GREEN: '#79DDB1',
  LIGHT_GREENBG: '#EEFBF5',
  LIGHT_BLUE: '#87E0E4',
  LIGHT_BLUEBG: '#ECF9FA',
  GREY1: '#6f6f6f',
  GREY2: '#ACACAC',
  GREY3: '#E2E2E2',
  GREY4: '#F2F2F2',
  GREY6: '#f6f6f6',
  GREY7: '#F4F4F4',
  LIGHTEST_GREY: '#FBFBFB',
  SHADOW_GREY: 'rgba(255,255,255, 0.4)',
  GREEN: '#70CD47',
  RED: '#C20D00',
};

// Let's say you want to add custom colors
const customTheme = extendTheme({
  ...theme,
  colors: {
    ...theme.colors,
    ...palette,
    yellow: {
      50: '#fff5db',
      100: '#ffe5ae',
      200: '#fed37e',
      300: '#fcc24d',
      400: '#fcb01d',
      500: '#FCB731',
      600: '#b07500',
      700: '#7f5400',
      800: '#4c3200',
      900: '#1d1000',
    },
    primary: palette.YELLOW,
    primaryBG: palette.YELLOWBG,
    secondary: palette.LIGHT_ORANGE,
    secondaryBG: palette.LIGHT_ORANGEBG,
    accentGreen: palette.LIGHT_GREEN,
    accentGreenBG: palette.LIGHT_GREENBG,
    accentBlue: palette.LIGHT_BLUE,
    accentBlueBG: palette.LIGHT_BLUEBG,
    accentRed: palette.RED,
    accentRedBG: theme.colors.red[100],
    accentYellow: palette.YELLOW,
    accentYellowBG: palette.YELLOWBG,
    success: palette.GREEN,
    error: palette.RED,
    white: palette.WHITE,
    black: palette.BLACK,
    grey2: palette.GREY2,
    grey3: palette.GREY3,
    grey4: palette.GREY4,
    grey6: palette.GREY6,
    grey7: palette.GREY7,
    selectedBackgroundMenu: palette.LIGHTEST_GREY,
    shadowGreyCard4: palette.SHADOW_GREY,
  },
  breakpoints,
  fonts: {
    heading: "'Eksell medium', sans-serif",
    body: "'Nunito Sans', sans-serif",
    mono: 'Menlo, monospace',
    content: 'Source Serif Pro, sans-serif',
  },
  fontWeights: {
    ...theme.fontWeights,
    thin: 200,
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xxs: '0.625rem', // 10px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
  },
  textStyles: {
    body: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
    },
    link: {
      textDecoration: 'underline',
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 700,
    },
    cta: {
      fontFamily: "'Nunito Sans', sans-serif",
    },
    h1: {
      color: palette.BLACK,
      fontFamily: "'Eksell medium', sans-serif",
      fontSize: '48px',
    },
    h2: {
      color: palette.BLACK,
      fontFamily: "'Eksell medium', sans-serif",
      fontSize: '32px',
    },
    h3: {
      color: palette.BLACK,
      fontFamily: "'Eksell medium', sans-serif",
      fontSize: '24px',
    },
    h4: {
      color: palette.BLACK,
      fontFamily: "'Eksell medium', sans-serif",
      fontSize: '20px',
    },
    h5: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: '18px',
      fontWeight: 700,
    },
    h6: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: '16px',
      fontWeight: 600,
    },
    normalBlack: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
    },
    normalGrey2: {
      color: palette.GREY2,
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
    },
    normalGrey3: {
      color: palette.GREY3,
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
    },
    pageTitle: {
      //  h2
      color: palette.BLACK,
      fontFamily: "'Eksell medium', sans-serif",
      fontSize: '32px',
    },
    pageSubtitle: {
      //  h2
      color: palette.GREY1,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
    },
    filterBy: {
      color: palette.GREY2,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'lg',
      fontWeight: 700,
    },
    filtersText: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 600,
    },
    cardDetailsSectionTitle: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'xl',
      fontWeight: 700,
      padding: '10px 0 20px 0',
    },
    cardDetailsWarning: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 700,
      padding: '10px 0 20px 0',
    },
    cardDetailsInnerSectionTitle: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'xl',
      fontWeight: 700,
      padding: '0 0 20px 0',
    },
    cardDetailsTitle: {
      color: palette.GREY2,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
      fontWeight: 500,
    },
    cardDetailsDynamicContent: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
      fontWeight: 500,
    },
    cardDetailsBlackTitle: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
      fontWeight: 500,
    },
    tableHalfDayTitle: {
      color: palette.GREY2,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 600,
      paddingTop: '20px',
      textAlign: 'center',
    },
    tableDayHours: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 600,
      paddingBottom: '20px',
      textAlign: 'center',
    },
    cardDetailsInputLabel: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 500,
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    availableServicesTitleLabel: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 500,
    },
    tableScheduleDays: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
      fontWeight: 600,
      textAlign: 'center',
    },
    errorMessage: {
      color: palette.RED,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 600,
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    errorMessageNoPadX: {
      color: palette.RED,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 600,
      paddingTop: '10px',
    },
    asterisk: {
      color: palette.LIGHT_ORANGE,
    },
    stepActive: {
      color: 'primary',
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
    },
    stepNotActive: {
      color: 'grey2',
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
    },
    stepperWizardText: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'md',
    },
    tableTH: {
      color: palette.BLACK,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
      fontWeight: 'semibold',
      textTransform: 'initial',
    },
    modalSubtitle: {
      color: palette.GREY2,
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'sm',
    },
    footerLabel: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: 'xs',
    },
    tabsLabel: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: 'lg',
      fontWeight: 400,
    },
  },
  shadows: {
    base: `0px 0px 10px 3px ${palette.GREY3}`,
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    s: '0px 1px 8px #f2f2f2',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    // outline: '0px 1px 0px #F2F2F2',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    bottomInner: '0px 15px 10px -15px rgba(0, 0, 0, 0.1)',
    none: 'none',
    'dark-lg': 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px',
  },
  components: {
    Accordion,
    Button,
    Checkbox,
    Input,
    Modal,
    Radio,
    Steps,
    Tabs,
    Table,
  },
});

export default customTheme;

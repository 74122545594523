import React from 'react';

const ArrowLeftNavigation = props => (
  <svg viewBox="0 0 60 100" width="1em" height="1em" {...props}>
    <path
      d="M6.2 53.5l42.6 40.7c1.9 1.8 4.9 1.8 6.8 0 1.9-1.8 1.9-4.7 0-6.5L16.4 50.2l39.2-37.5c1.9-1.8 1.9-4.7 0-6.5-1.9-1.8-4.9-1.8-6.8 0L6.2 47c-1.8 1.8-1.8 4.7 0 6.5z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeftNavigation;

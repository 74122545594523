import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { ModalTitle, ModalMessage, Button } from './styles';

const ErrorModal = props => {
  const {
    isOpen,
    onClose,
    title,
    message,
    buttonLabel,
    buttonAnalytics,
  } = props;

  return (
    <Modal isOpen={isOpen} size="sm" centered>
      <ModalBody>
        <ModalTitle>{title}</ModalTitle>

        <ModalMessage>{message}</ModalMessage>

        <Button
          color="primary"
          size="lg"
          onClick={onClose}
          analytics={buttonAnalytics}
          autoFocus
        >
          {buttonLabel}
        </Button>
      </ModalBody>
    </Modal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  buttonAnalytics: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }).isRequired,
};

ErrorModal.defaultProps = {
  isOpen: false,
};

export default ErrorModal;

import React from 'react';

const RadioActive = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="white"
      stroke="#FF7761"
      strokeWidth="4"
    />
    <circle cx="12" cy="12" r="4" fill="#FF7761" />
  </svg>
);

export default RadioActive;

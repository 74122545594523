import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isString } from '../../utils/validation';
import {
  Wrapper,
  CustomDropzone,
  FileFormats,
  CustomIcon,
  ErrorMessage,
  Button,
  FileDropInputWrapper,
} from './styles';
import messages from './messages';
import typesMapper from './fileTypesMapper';
import errorMessages from '../errorMessages';
import MyDummyPreview from './MyDummyPreview';

const FileErrorFormattedMessage = ({
  fileSizeError,
  fileGenericError,
  fileTypeError,
}) => {
  if (fileTypeError) {
    return <FormattedMessage {...messages.fileTypeError} />;
  } else if (fileSizeError) {
    return <FormattedMessage {...messages.fileSizeError} />;
  }
  return fileGenericError;
};

function FileDropInput({
  handleDropAccepted,
  handleDropRejected,
  input,
  meta,
  file,
  clearInput,
  getData,
  loading,
  hideDelete,
  fileSizeError,
  fileTypeError,
  fileGenericError,
  maxFileSizeMB,
  fileTypes,
  customMessage,
  contract,
  descriptionLabel,
  disableDnD,
  onDroppedFile,
  forcePracticeIcon,
}) {
  const fileUrlExists = !!(input && input.value && input.value.size > 0);

  let fileTypesArray = [];
  let typeLabel = null;
  let typeFilter = null;
  if (fileTypes !== '*') {
    fileTypesArray = typesMapper.filter(
      ({ key }) => (fileTypes || 'PDF,JPG,PNG,GIF').indexOf(key) >= 0,
    );
    typeLabel = fileTypesArray.map(({ label }) => label).toString();
    typeFilter = fileTypesArray.map(({ type }) => type).toString();
  }

  const showDropzone =
    (!input || !input.value) && !file ? (
      <Button size="lg" color="link">
        <CustomIcon>+</CustomIcon>
        <FormattedMessage {...messages[customMessage || 'selectFile']} />
        {descriptionLabel ? (
          <FileFormats>{descriptionLabel}</FileFormats>
        ) : (
          <FileFormats>{`${
            typeLabel ? `${typeLabel} - ` : ''
          }${maxFileSizeMB}MB`}</FileFormats>
        )}
      </Button>
    ) : (
      <MyDummyPreview
        input={input}
        file={file}
        clearInput={clearInput}
        fileUrlExists={fileUrlExists}
        getData={getData}
        loading={loading}
        hideDelete={hideDelete}
        fileSizeError={fileSizeError}
        fileTypeError={fileTypeError}
        fileGenericError={fileGenericError}
        customMessage={customMessage}
        typeLabel={typeLabel}
        maxFileSizeMB={maxFileSizeMB}
        contract={contract}
        onDroppedFile={onDroppedFile}
        forcePracticeIcon={forcePracticeIcon}
      />
    );
  return (
    <Fragment>
      <FileDropInputWrapper isEmpty={!input.value}>
        <CustomDropzone
          {...input}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
          onBlur={() => null}
          onChange={() => null}
          accept={typeFilter}
          maxSize={maxFileSizeMB * 1024 * 1024}
          multiple={false}
          disabled={disableDnD}
          disableClick={
            !fileSizeError &&
            !fileTypeError &&
            !fileGenericError &&
            fileUrlExists
          }
        >
          <Wrapper>
            {fileSizeError || fileTypeError || fileGenericError ? (
              <Button size="lg" color="link">
                <CustomIcon>+</CustomIcon>
                <FormattedMessage
                  {...messages[customMessage || 'selectFile']}
                />
                <FileFormats>{`${typeLabel} - ${maxFileSizeMB}MB`}</FileFormats>
              </Button>
            ) : (
              showDropzone
            )}
          </Wrapper>
        </CustomDropzone>
      </FileDropInputWrapper>
      {(fileTypeError || fileSizeError || fileGenericError) && (
        <ErrorMessage>
          <FileErrorFormattedMessage
            fileSizeError={fileSizeError}
            fileTypeError={fileTypeError}
            fileGenericError={fileGenericError}
          />
        </ErrorMessage>
      )}
      {meta &&
        meta.touched &&
        ((meta.error && (
          <ErrorMessage>
            <FormattedMessage
              values={typeof error !== 'object' ? {} : meta.error}
              {...errorMessages[isString(meta.error)]}
            />
          </ErrorMessage>
        )) ||
          (meta.warning && <span>{meta.warning}</span>))}
    </Fragment>
  );
}

FileDropInput.propTypes = {
  handleDropAccepted: PropTypes.func.isRequired,
  handleDropRejected: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
  file: PropTypes.any,
  getData: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hideDelete: PropTypes.bool,
  fileSizeError: PropTypes.bool,
  fileTypeError: PropTypes.bool,
  fileGenericError: PropTypes.string,
  maxFileSizeMB: PropTypes.number,
  fileTypes: PropTypes.string,
  customMessage: PropTypes.string,
  contract: PropTypes.bool,
  descriptionLabel: PropTypes.node,
  disableDnD: PropTypes.bool,
  onDroppedFile: PropTypes.func,
  forcePracticeIcon: PropTypes.bool,
};

FileDropInput.defaultProps = {
  input: null,
  meta: null,
  file: null,
  loading: false,
  hideDelete: false,
  fileSizeError: false,
  fileTypeError: false,
  fileGenericError: undefined,
  maxFileSizeMB: 1,
  fileTypes: 'PDF,JPG,PNG,GIF',
  customMessage: null,
  contract: null,
  descriptionLabel: null,
  disableDnD: false,
  onDroppedFile: null,
  forcePracticeIcon: false,
};

export default FileDropInput;

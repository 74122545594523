import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form/immutable';

export const selectDataTableDomain = () => state => state.get('dataTable');

export const selectTableFilterFormValues = () => state => {
  const formValues = getFormValues('table-filter')(state);
  return !formValues ? {} : formValues.toJS();
};

export const selectDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('results'),
  );

export const selectLoadingDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('loading'),
  );

export const selectTotalPagesDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('totalPages'),
  );

export const selectTotalCountDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('totalCount'),
  );

export const selectCurrentPageDataTable = () =>
  createSelector(
    selectDataTableDomain(),
    dataTable => dataTable.get('currentPage') || 1,
  );

export const selectItemsPerPageDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('itemsPerPage'),
  );

export const selectNextPageDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('nextPage'),
  );

export const selectPrevPageDataTable = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('prevPage'),
  );

export const makeSelectExpandedRows = () =>
  createSelector(
    selectDataTableDomain(),
    dataTable => (dataTable ? dataTable.get('expandedRows').toJS() : {}),
  );

export const selectAutoSuggestsLabels = () =>
  createSelector(selectDataTableDomain(), dataTable => {
    const autoSuggestsLabels = dataTable
      ? dataTable.get('autoSuggestsLabels')
      : null;
    return !autoSuggestsLabels ? {} : autoSuggestsLabels.toJS();
  });

export const selectOrderBy = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('orderBy'),
  );

export const selectFilters = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('filters'),
  );

export const selectDataUrl = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('dataUrl'),
  );

export const selectTypeOfCall = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('typeOfCall'),
  );

export const selectV2Params = () =>
  createSelector(selectDataTableDomain(), dataTable => dataTable.get('v2'));

export const selectHidePaginationParams = () =>
  createSelector(selectDataTableDomain(), dataTable =>
    dataTable.get('hidePaginationParams'),
  );

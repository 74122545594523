export const TableStyles = {
  parts: ['tbody'],
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    striped: {
      tbody: {
        tr: {
          _even: {
            backgroundColor: 'gray.50',
          },
          _odd: {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'striped',
  },
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  cookieBannerText: {
    id: 'web.partner.cookieBanner.Text',
    defaultMessage:
      'Questo sito fa uso di cookie per migliorare la navigazione utente.',
  },
  cookieBannerAcceptLinkText: {
    id: 'web.partner.cookieBanner.AcceptLinkText',
    defaultMessage: 'Clicca qui',
  },
  cookieBannerForConsent: {
    id: 'web.partner.cookieBanner.ForConsent',
    defaultMessage: 'per accettare.',
  },
  cookieBannerReadPolicyLinkText: {
    id: 'web.partner.cookieBanner.cookieBannerReadPolicyLinkText',
    defaultMessage: 'Leggi la cookie policy.',
  },
});

import React from 'react';

const DocAUD = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(0, 0, 0)"
    />
    <path
      fill-rule="evenodd"
      d="M 5.512 9.24 L 6.352 12.31 L 6.552 12.31 L 6.552 13.13 L 4.592 13.13 L 4.592 12.31 L 4.892 12.31 L 4.812 11.91 L 4.132 11.91 L 4.052 12.31 L 4.352 12.31 L 4.352 13.13 L 2.642 13.13 L 2.642 12.31 L 2.842 12.31 L 3.682 9.24 L 5.512 9.24 Z M 4.442 10.38 L 4.242 11.38 L 4.702 11.38 L 4.502 10.38 L 4.442 10.38 ZM 8.378 13.2 C 7.858 13.2 7.455 13.083 7.168 12.85 C 6.881 12.623 6.738 12.25 6.738 11.73 L 6.738 10.06 L 6.488 10.06 L 6.488 9.24 L 8.338 9.24 L 8.338 10.06 L 8.138 10.06 L 8.138 11.99 C 8.138 12.103 8.161 12.183 8.208 12.23 C 8.261 12.283 8.335 12.31 8.428 12.31 C 8.528 12.31 8.601 12.283 8.648 12.23 C 8.695 12.183 8.718 12.103 8.718 11.99 L 8.718 10.06 L 8.518 10.06 L 8.518 9.24 L 10.238 9.24 L 10.238 10.06 L 9.988 10.06 L 9.988 11.73 C 9.988 12.25 9.848 12.623 9.568 12.85 C 9.295 13.083 8.898 13.2 8.378 13.2 ZM 10.409 13.13 L 10.409 12.31 L 10.659 12.31 L 10.659 10.07 L 10.409 10.07 L 10.409 9.24 L 12.439 9.24 C 12.992 9.24 13.436 9.393 13.769 9.7 C 14.096 10 14.259 10.493 14.259 11.18 C 14.259 11.867 14.096 12.363 13.769 12.67 C 13.436 12.977 12.992 13.13 12.439 13.13 L 10.409 13.13 Z M 12.059 12.31 L 12.349 12.31 C 12.462 12.31 12.546 12.28 12.599 12.22 C 12.659 12.16 12.689 12.067 12.689 11.94 L 12.689 10.43 C 12.689 10.183 12.576 10.06 12.349 10.06 L 12.059 10.06 L 12.059 12.31 Z"
      transform="matrix(0.8579750061035156, 0, 0, 0.9071421027183533, 1.885807991027832, 1.1355159282684326)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocAUD;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar';
import { FormattedMessage } from 'react-intl';
import OneSignal from 'components/shared/OneSignal';
import ZendeskChat from 'components/ZendeskChatCustom';
import HomePage from 'containers/HomePage/Loadable';
import Agenda from 'containers/Agenda/Loadable';
import PublicRoute from 'containers/Auth/PublicRoute';
import PrivateRoute from 'containers/Auth/PrivateRoute';
import EditProfile from 'containers/EditProfile/Loadable';
import Settings from 'containers/Settings/Loadable';
import Invoices from 'containers/Invoices/Loadable';
import Patients from 'containers/Patients/Loadable';
import Documents from 'containers/Documents/Loadable';
import Requests from 'containers/Requests/Loadable';
import RequestDetails from 'containers/RequestDetails/Loadable';
import PatientDetails from 'containers/PatientDetails/Loadable';
import Videomeeting from 'containers/PatientDetails/PatientBookingDetails/Videomeeting';
import VideomeetingMobile from 'containers/PatientDetails/PatientBookingDetails/Videomeeting/Mobile';
import SelfAnamnesis from 'containers/PatientDetails/SelfAnamnesis';
import PartnersRegistration from 'containers/PartnersRegistration/Loadable';
import PartnerServices from 'containers/PartnersServices/Loadable';
import PrivacyPolicy from 'containers/PrivacyPolicy/Loadable';
import Copyright from 'containers/Copyright/Loadable';
import TermsOfUse from 'containers/TermsOfUse/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Communications from 'containers/Communications/Loadable';
import PlatformOffline from 'containers/PlatformOffline';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Alert, UpdateTermsModal } from 'components/shared';
import CallToActionAlert from 'components/shared/CallToActionAlert/CallToActionAlert';
import ImpersonateBar from 'containers/Auth/ImpersonateBar';
import messages from './messages';
import { AppWrapper, ScrollContent, HeaderWrapper, ContentWrapper, FooterWrapper, CookiePolicyBanner } from './styles';
import cookiePolicyDocument from '../../documents/cookiePolicy.pdf';

const Protected = () => <h3>Protected</h3>;

// eslint-disable-next-line react/prefer-stateless-function
function App({
  location,
  textError,
  typeError,
  logPageView,
  partnerPracticeStatus,
  goToStep,
  callToActionProps,
  hideCallToAction,
  isImpersonate,
  partnerProfileData,
  cookiePolicyConsent,
  setCookiePolicyConsent,
  partnerIsAuthenticated,
  dataLostErrorText,
  dataLostErrorType,
  mobileVideomeeting,
  fromPU,
  singlePage,
}) {
  if (partnerIsAuthenticated && partnerProfileData) {
    OneSignal.push(() => {
      OneSignal.provideUserConsent(true);
      OneSignal.registerForPushNotifications();
      OneSignal.sendTag('user', partnerProfileData.id);
      OneSignal.setEmail(partnerProfileData.email);
      OneSignal.setSubscription(true);
    });
  }

  return (
    <AppWrapper isMobile={mobileVideomeeting}>
      <ZendeskChat isImpersonate={isImpersonate} isMobile={mobileVideomeeting} />
      <ScrollContent id="scroll-content" isMobile={mobileVideomeeting}>
        {!fromPU && !singlePage && (
          <HeaderWrapper isMobile={mobileVideomeeting}>
            <LoadingBar />
            <Header pathname={location.pathname} partnerPracticeStatus={partnerPracticeStatus} goToStep={goToStep} />
            <ImpersonateBar profileData={partnerProfileData} isImpersonate={isImpersonate} />
            <Alert
              text={textError || dataLostErrorText}
              type={typeError || dataLostErrorType}
              isOpen={!!textError || !!dataLostErrorText}
            />
            <CallToActionAlert {...callToActionProps} hideCallToAction={hideCallToAction} />
          </HeaderWrapper>
        )}

        <ContentWrapper id="main-content-wrapper" isImpersonate={isImpersonate}>
          <UpdateTermsModal />
          <Route path="/" component={() => logPageView(location.pathname)} />
          <Switch>
            <PublicRoute exact path="/" component={HomePage} />
            <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
            <PublicRoute path="/copyright" component={Copyright} />
            <PublicRoute path="/term-of-use" component={TermsOfUse} />
            <PublicRoute
              path="/patient/:id/details/:bookingId/videomeeting/:token/:refreshToken"
              component={VideomeetingMobile}
            />

            <PrivateRoute path="/protected" component={Protected} />
            <PrivateRoute path="/edit-profile" component={EditProfile} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/invoices" component={Invoices} />
            <PrivateRoute exact path="/patient/:id/details/:bookingId/videomeeting_mobile" component={Videomeeting} />
            <PrivateRoute exact path="/patient/:id/details/:bookingId/videomeeting" component={Videomeeting} />
            <PrivateRoute exact path="/patient/:id/details/selfanamnesis" component={SelfAnamnesis} />
            <PrivateRoute path="/patient/:id/details/:bookingId?" component={PatientDetails} />
            <PrivateRoute path="/requests/:id" component={RequestDetails} />
            <PrivateRoute path="/partner-services" component={PartnerServices} />
            <PrivateRoute path="/patients" component={Patients} />
            <PrivateRoute path="/documents/:patientId/:bookingId" component={Documents} />
            <PrivateRoute path="/documents/:patientId" component={Documents} />
            <PrivateRoute exact path="/partner-registration" component={PartnersRegistration} />
            <PrivateRoute path="/requests" component={Requests} />
            <PrivateRoute exact path="/agenda" component={Agenda} />
            <PrivateRoute exact path="/agenda/cronofy/success" component={Agenda} />
            <PrivateRoute exact path="/communications" component={Communications} />
            <PrivateRoute exact path="/platform-offline" component={PlatformOffline} />
            <Route component={NotFoundPage} />
          </Switch>
        </ContentWrapper>
        {!fromPU && !singlePage && (
          <FooterWrapper isMobile={mobileVideomeeting}>
            <Footer showCta={!partnerIsAuthenticated} />
          </FooterWrapper>
        )}
      </ScrollContent>
      {cookiePolicyConsent !== 'true' && (
        <CookiePolicyBanner>
          <FormattedMessage {...messages.cookieBannerText} />
          &nbsp;
          <button onClick={() => setCookiePolicyConsent({ consent: 'true' })}>
            <FormattedMessage {...messages.cookieBannerAcceptLinkText} />
          </button>
          &nbsp;
          <FormattedMessage {...messages.cookieBannerForConsent} />
          &nbsp;
          <a href={cookiePolicyDocument} target="_blank" rel="noreferrer">
            <FormattedMessage {...messages.cookieBannerReadPolicyLinkText} />
          </a>
        </CookiePolicyBanner>
      )}
    </AppWrapper>
  );
}

App.propTypes = {
  location: PropTypes.shape({}).isRequired,
  textError: PropTypes.string,
  typeError: PropTypes.string,
  dataLostErrorText: PropTypes.string,
  dataLostErrorType: PropTypes.string,
  logPageView: PropTypes.func.isRequired,
  partnerPracticeStatus: PropTypes.string,
  goToStep: PropTypes.func.isRequired,
  callToActionProps: PropTypes.shape({
    isOpen: PropTypes.bool,
    descriptor: PropTypes.string,
    values: PropTypes.shape({}),
  }),
  hideCallToAction: PropTypes.func.isRequired,
  isImpersonate: PropTypes.bool.isRequired,
  partnerProfileData: PropTypes.shape({}),
  partnerIsAuthenticated: PropTypes.bool,
  partnerUserId: PropTypes.number,
  cookiePolicyConsent: PropTypes.oneOf(['true', 'false']).isRequired,
  setCookiePolicyConsent: PropTypes.func.isRequired,
  mobileVideomeeting: PropTypes.bool.isRequired,
};

App.defaultProps = {
  textError: null,
  typeError: null,
  dataLostErrorText: null,
  dataLostErrorType: 'danger',
  partnerPracticeStatus: null,
  partnerIsAuthenticated: false,
  partnerUserId: undefined,
};

export default App;

import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import injectReducer from 'utils/injectReducer';
import { hideCallToActionAction } from './actions';
import callToActionReducer from './reducers';
import { selectCallToActionDomain } from './selectors';

const mapStateToProps = createStructuredSelector({
  callToActionProps: selectCallToActionDomain(),
});

const mapDispatchToProps = {
  hideCallToAction: hideCallToActionAction,
};

export default compose(
  injectReducer({ key: 'callToAction', reducer: callToActionReducer }),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

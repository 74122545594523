import React from 'react';
import PropTypes from 'prop-types';

const MyEvaluationRequest = ({ colored, ...props }) => (
  <svg viewBox="0 0 172.98 173.85" width="1em" height="1em" {...props}>
    <title>MENU-Evaluation Requests</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <polygon
          className="cls-1"
          stroke={colored ? '#e11c23' : 'currentColor'}
          strokeMiterlimit="10"
          strokeWidth="4px"
          fill="none"
          points="120.35 137.18 87.54 170.98 54.74 137.18 2 137.18 2 2 170.98 2 170.98 137.18 120.35 137.18"
        />
        <path
          className="cls-1"
          stroke={colored ? '#e11c23' : 'currentColor'}
          strokeMiterlimit="10"
          strokeWidth="4px"
          fill="none"
          d="M88.41,34.28,97.65,53a2.41,2.41,0,0,0,1.83,1.33l20.67,3a2.43,2.43,0,0,1,1.35,4.15l-15,14.58a2.47,2.47,0,0,0-.7,2.16l3.53,20.58a2.43,2.43,0,0,1-3.53,2.57L87.35,91.66a2.44,2.44,0,0,0-2.26,0L66.6,101.38a2.44,2.44,0,0,1-3.53-2.57L66.6,78.23a2.47,2.47,0,0,0-.7-2.16l-15-14.58a2.43,2.43,0,0,1,1.35-4.15l20.67-3A2.41,2.41,0,0,0,74.79,53L84,34.28A2.43,2.43,0,0,1,88.41,34.28Z"
        />
      </g>
    </g>
  </svg>
);

MyEvaluationRequest.propTypes = {
  colored: PropTypes.bool,
};

MyEvaluationRequest.defaultProps = {
  colored: null,
};

export default MyEvaluationRequest;

import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import { showCallToActionAction, hideCallToActionAction } from './actions';

const initialState = fromJS({
  descriptor: null,
  values: null,
  isOpen: false,
});

export default handleActions(
  {
    [showCallToActionAction]: (state, { descriptor, values }) =>
      state.set('isOpen', true).set('descriptor', descriptor).set('values', values),
    [LOCATION_CHANGE]: () => initialState,
    [hideCallToActionAction]: () => initialState,
  },
  { initialState },
);

import { compose } from 'recompose';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { RESTART_ON_REMOUNT } from 'utils/constants';
import profileSaga from 'containers/EditProfile/sagas';

import reducer from './reducers';
import saga from './sagas';

const withSaga = injectSaga({
  key: 'auth',
  saga,
  mode: RESTART_ON_REMOUNT,
});

const withSagaProfile = injectSaga({
  key: 'profile',
  saga: profileSaga,
  mode: RESTART_ON_REMOUNT,
});

const withReducer = injectReducer({ key: 'auth', reducer });

export default compose(withReducer, withSaga, withSagaProfile);

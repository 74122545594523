import styled from 'styled-components';
import {
  Modal as _modal,
  ModalHeader as _modalHeader,
  ModalBody as _modalBody,
  ModalFooter as _modalFooter,
} from 'reactstrap';
import { Button as _button } from '@components';
import omit from 'utils/omitProps';

export const Modal = styled(_modal).attrs({
  centered: true,
})`
  .modal-content {
    min-height: 250px;
    padding: 30px;
    border-radius: 30px;
  }
  .modal-title {
    font-weight: 600;
  }
  .modal-body {
    font-size: 1.8rem;
    font-weight: 700;
    color: ${({ theme }) => theme.black};
    padding: 0;
  }
  .modal-footer {
    display: block;
    padding: 0px;
    border: none;
  }
`;

export const ModalHeader = styled(
  omit(_modalHeader, ['shortPadding', 'noPadding']),
)`
  text-align: center;
  justify-content: center;
  border: none;
  padding: 60px 30px 30px;

  .modal-title {
    font-weight: 600;
  }
  ${({ shortPadding }) => shortPadding && 'padding-top: 33px;'};
  ${({ noPadding }) => noPadding && 'padding: 0;'};
`;

export const Submessage = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 300;
  color: ${p => p.theme.gray300};
`;

export const ModalBody = styled(_modalBody)`
  text-align: center;
  justify-content: center;
  border: none;
  padding: 5px;
`;

export const ModalFooter = styled(_modalFooter)`
  border: none;
  display: block;
  flex-direction: column;
  text-align: center;
  padding: 20px 100px;
  button + button {
    margin-top: 20px;
  }
`;

export const Button = styled(_button)`
  font-size: 1.6rem;
  margin: 0 10px;
  padding: 0 40px;
`;

export const Round = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 30px;
  background-color: ${({ success, theme }) => success && theme.success};
  background-color: ${({ rejected, theme: { danger } }) => rejected && danger};
  margin-left: calc(50% - 12px);
`;

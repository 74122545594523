import { compose, withProps } from 'recompose';
import get from 'lodash/get';
import TimeRange from './TimeRange';

export default compose(
  withProps(({ names, ...props }) => {
    const timeStart = names[0];
    const timeEnd = names[1];
    const timeStartDataForm = get(props, timeStart);
    const timeEndDataForm = get(props, timeEnd);

    return {
      timeStartDataForm,
      timeEndDataForm,
    };
  }),
)(TimeRange);

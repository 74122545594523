import { compose, withStateHandlers, lifecycle } from 'recompose';

import Select from './Select';

export default compose(
  withStateHandlers(
    {
      touched: false,
    },
    {
      // on this component meta.touched (redux-form) seems not arrive
      // this handler is called onFocus
      activateTouched: () => () => ({
        touched: true,
      }),
    },
  ),
  lifecycle({
    componentDidMount() {
      const {
        value,
        options,
        input: { onChange },
      } = this.props;
      if (!value && options && options.length === 1) onChange(options[0].value);
    },
  }),
)(Select);

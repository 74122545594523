import * as React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { FormGroup, Wrapper, ErrorMessage } from './styles';

export const CheckBoxComponent = ({
  fromFormGroup,
  requiredfield,
  input,
  label,
  meta,
  disabled,
  checkboxPosition,
}) => {
  const { error, touched, warning } = meta;
  const requiredAsterisk = requiredfield || error ? '*' : '';
  return (
    <Wrapper fromFormGroup={fromFormGroup} checkboxPosition={checkboxPosition}>
      <div checked={input.checked}>
        <input type="checkbox" {...input} disabled={disabled} />
        <Label disabled={disabled}>
          {label}
          {requiredAsterisk}
        </Label>
      </div>
      {touched &&
        ((error && <ErrorMessage>{error}</ErrorMessage>) ||
          (warning && <span>{warning}</span>))}
    </Wrapper>
  );
};

function CheckBox({
  input,
  label,
  meta,
  height,
  onClick,
  marginBottom,
  requiredfield,
  disabled,
  checkboxPosition,
}) {
  return (
    <FormGroup
      height={height}
      onClick={disabled ? () => null : onClick}
      marginBottom={marginBottom}
    >
      <CheckBoxComponent
        fromFormGroup
        input={input}
        label={label}
        requiredfield={requiredfield}
        meta={meta}
        disabled={disabled}
        checkboxPosition={checkboxPosition}
      />
    </FormGroup>
  );
}

CheckBox.propTypes = {
  input: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.string,
  meta: PropTypes.any,
  onClick: PropTypes.func,
  marginBottom: PropTypes.string,
  requiredfield: PropTypes.bool,
  disabled: PropTypes.bool,
  checkboxPosition: PropTypes.string,
};

CheckBox.defaultProps = {
  meta: null,
  height: null,
  onClick: null,
  marginBottom: '0',
  requiredfield: false,
  checkboxPosition: 'left',
};

CheckBoxComponent.propTypes = {
  input: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.any,
  requiredfield: PropTypes.bool,
  fromFormGroup: PropTypes.bool,
  disabled: PropTypes.bool,
  checkboxPosition: PropTypes.string,
};

CheckBoxComponent.defaultProps = {
  checkboxPosition: 'left',
};

export default CheckBox;

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { intlShape, FormattedMessage } from 'react-intl';
import SelectArrow from '../Select/SelectArrow';
import { FormGroup, Select } from '../FormStyles';
import Label from '../Label';
import messages from './messages';

export const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SelectArrow />
  </components.DropdownIndicator>
);

function InputLocaleAutosuggest({
  input,
  titleLabel,
  label,
  handleInputChange,
  suggestions,
  handleChange,
  isMulti,
  selectedOption,
  intl,
  value,
  requiredfield,
  size,
  placeholder,
  marginBottom,
  meta,
}) {
  const options = (suggestions || []).map(({ name, id }) => ({
    value: id,
    label: name,
  }));
  const { active, dirty } = meta;

  return (
    <FormGroup haslabel={!!titleLabel || !!label} marginBottom={marginBottom}>
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={requiredfield}
        />
      )}
      <Select
        name={input.name}
        value={selectedOption || value}
        components={{ DropdownIndicator }}
        onChange={handleChange}
        options={options}
        active={active}
        dirty={dirty}
        onInputChange={handleInputChange}
        isMulti={isMulti}
        noOptionsMessage={() => intl.formatMessage(messages.noOption)}
        size={size}
        placeholder={
          placeholder || <FormattedMessage {...messages.placeholder} />
        }
      />
    </FormGroup>
  );
}

InputLocaleAutosuggest.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleInputChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  selectedOption: PropTypes.any,
  intl: intlShape.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  requiredfield: PropTypes.bool,
  size: PropTypes.string,
  marginBottom: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.any,
};

InputLocaleAutosuggest.defaultProps = {
  titleLabel: null,
  label: null,
  suggestions: [],
  isMulti: false,
  selectedOption: null,
  value: null,
  requiredfield: false,
  size: 'md',
  placeholder: null,
  marginBottom: null,
  meta: null,
};

export default InputLocaleAutosuggest;

import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import {
  getFAQListFailed,
  getFAQListRequest,
  getFAQListSuccess,
  getTicketsListFailed,
  getTicketsListRequest,
  getTicketsListSuccess,
  getAllPracticeTicketsRequest,
  getAllPracticeTicketsSuccess,
  getAllPracticeTicketsFailed,
} from './actions';
import * as SupportAPI from './apiCall';
import { GetFAQListRequestPayload, GetTicketsListRequestPayload, GetPracticeTicketsListRequestPayload } from './types';

function* getFAQListSaga({ payload }: PayloadAction<GetFAQListRequestPayload>) {
  try {
    const response = yield call(SupportAPI.getFAQList, { ...payload });
    const { data } = response;

    yield put(getFAQListSuccess(data));
  } catch (error: any) {
    yield put(getFAQListFailed());
  }
}

function* getTicketsListSaga({ payload }: PayloadAction<GetTicketsListRequestPayload>) {
  try {
    const response = yield call(SupportAPI.getTicketsList, { ...payload });
    const { data } = response;

    yield put(getTicketsListSuccess(data));
  } catch (error: any) {
    yield put(getTicketsListFailed());
  }
}

function* getAllPracticeTicketsSaga({ payload }: PayloadAction<GetPracticeTicketsListRequestPayload>) {
  try {
    const response = yield call(SupportAPI.getAllPracticeTicketsList, { ...payload });
    const { data } = response;

    yield put(getAllPracticeTicketsSuccess(data));
  } catch (error: any) {
    yield put(getAllPracticeTicketsFailed());
  }
}

function* supportSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getFAQListRequest.type, getFAQListSaga);
  yield takeLatest(getTicketsListRequest.type, getTicketsListSaga);
  yield takeLatest(getAllPracticeTicketsRequest.type, getAllPracticeTicketsSaga);
}

export default supportSaga;

export const LOGIN_REQUEST = 'APP/AUTH/LOGIN/REQUEST';
export const LOGIN_RESPONSE = 'APP/AUTH/LOGIN/RESPONSE';
export const LOGOUT_REQUEST = 'APP/AUTH/LOGOUT/REQUEST';
export const LOGOUT_RESPONSE = 'APP/AUTH/LOGOUT/RESPONSE';
export const REGISTER_REQUEST = 'APP/AUTH/REGISTER/REQUEST';
export const PRIVACY_POLICY_LOADING = 'APP/AUTH/REGISTER/PRIVACY/LOADING';
export const PRIVACY_POLICY_LOADED = 'APP/AUTH/REGISTER/PRIVACY/LOADED';
export const TOS_LOADING = 'APP/AUTH/REGISTER/TOS/LOADING';
export const TOS_LOADED = 'APP/AUTH/REGISTER/TOS/LOADED';
export const FACEBOOK_LOGIN_REQUEST = 'APP/AUTH/LOGIN/FACEBOOK_REQUEST';
export const GOOGLE_LOGIN_REQUEST = 'APP/AUTH/LOGIN/GOOGLE_LOGIN_REQUEST';
export const CHANGE_PASSWORD_REQUEST = 'APP/AUTH/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RESPONSE = 'APP/AUTH/CHANGE_PASSWORD_RESPONSE';
export const FORGOT_USERNAME_REQUEST = 'APP/AUTH/FORGOT_USERNAME_REQUEST';
export const FORGOT_PASSWORD_REQUEST = 'APP/AUTH/FORGOT_PASSWORD_REQUEST';
export const REFRESH_TOKEN_REQUEST = 'APP/AUTH/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'APP/AUTH/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'APP/AUTH/REFRESH_TOKEN_FAIL';
export const SET_TOKEN_LOCAL_STORAGE = 'APP/AUTH/SET_TOKEN_LOCAL_STORAGE';
export const GET_TOKEN_LOCAL_STORAGE = 'APP/AUTH/GET_TOKEN_LOCAL_STORAGE';
export const GET_PARTNER_STATUS = 'APP/AUTH/GET_PARTNER_STATUS';
export const SET_PARTNER_STATUS = 'APP/AUTH/SET_PARTNER_STATUS';
export const SET_FIRST_APPROVAL_REVIEWED =
  'APP/AUTH/SET_FIRST_APPROVAL_REVIEWED';
export const UPDATE_TERMS_REQUEST = 'APP/AUTH/TERMS/UPDATE_REQUEST';
export const UPDATE_TERMS_RESPONSE = 'APP/AUTH/TERMS/UPDATE_RESPONSE';
export const RESET_PASSWORD = 'APP/AUTH/RESET_PASSWORD';
export const REMIND_USERNAME = 'APP/AUTH/REMIND_USERNAME';
export const SET_LEGAL_DOCUMENTS = 'APP/AUTH/SET_LEGAL_DOCUMENTS';
export const REQUEST_LEGAL_DOCUMENTS = 'APP/AUTH/REQUEST_LEGAL_DOCUMENTS';
export const SET_REDIRECT_URL_POST_LOGIN =
  'APP/AUTH/SET_REDIRECT_URL_POST_LOGIN';
export const GET_GEO_LOCATION_REQUEST = 'APP/AUTH/GET_GEO_LOCATION_REQUEST';
export const GET_GEO_LOCATION_RESPONSE = 'APP/AUTH/GET_GEO_LOCATION_RESPONSE';

export const USER_ROLE = 'partner';

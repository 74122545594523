import { createSelector } from 'reselect';

const selectAppDomain = (state) => state.get('App');
const selectRouteDomain = (state) => state.get('router');

export const makeSelectLocation = () => createSelector(selectRouteDomain, (route) => route.get('location').toJS());

export const makeSelectCookiePolicyConsent = () =>
  createSelector(selectAppDomain, (app) => app.get('cookiePolicyConsent'));

export const makeSelectGoogleAnalyticsInitialized = () =>
  createSelector(selectAppDomain, (app) => app.get('googleAnalyticsInitialized'));

export const selectUnreadCommunicationsCount = () =>
  createSelector(selectAppDomain, (app) => app.get('unreadCommunicationsCount'));

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { BackHeader, Row, Col } from '@components';
import get from 'lodash/get';
import {
  PageWrapper,
  Container,
  HeaderButtonContainer,
  Answer,
  WizardTitle,
  Category,
  Question,
  DownloadButton,
  AnamnesisWrapper,
} from './styles';
import messages from './messages';

const decodeHtml = html => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function SelfAnamnesisPreview({
  intl,
  anamnesisFile,
  anamnesisPreview,
  patientSelectedBooking,
  toggleAnamnesisPreview,
}) {
  const preview = patientSelectedBooking
    ? get(patientSelectedBooking, 'anamnesisPreview', null)
    : anamnesisPreview;
  const file = patientSelectedBooking
    ? get(patientSelectedBooking, 'anamnesisFile', null)
    : anamnesisFile;
  const title = get(preview, 'title[0]', '');
  const questions = get(preview, 'questions', []);
  return (
    <PageWrapper>
      <Container>
        <BackHeader
          title={messages.title}
          subtitle={messages.subtitle}
          onClick={toggleAnamnesisPreview}
          RightComponent={() => (
            <HeaderButtonContainer>
              {file && (
                <a
                  href={`data:application/pdf;base64,${file}`}
                  download={intl.formatMessage(messages.questionnaireFileName)}
                >
                  <DownloadButton>
                    <FormattedMessage {...messages.questionnaireDownload} />
                  </DownloadButton>
                </a>
              )}
            </HeaderButtonContainer>
          )}
        />
        <AnamnesisWrapper>
          <Row marginTop="50" marginBottom="20">
            <Col md={{ size: 9, offset: 1 }} padding="0" textAlign="left">
              <WizardTitle>{title}</WizardTitle>
            </Col>
          </Row>
          {questions.map(({ query, answers }, index) => {
            let category;
            let question;
            const q = (query || [])[0] || null;
            if (q) {
              category = decodeHtml((q || '').split(':')[0]);
              question = decodeHtml((q || '').split(':')[1]);
            }
            const answerSingle =
              (answers || []).length === 1 ? answers[0].answer : null;
            const answerMultiple =
              (answers || []).length > 1
                ? answers
                    .reduce((acc, a) => {
                      acc.push(a[0].answer);
                      return acc;
                    }, [])
                    .join(', ')
                : null;
            const isLast = index === questions.length - 1;
            return (
              <Row
                key={index.toString()}
                style={{ marginBottom: isLast ? '60px' : '0' }}
              >
                <Col md={{ size: 10, offset: 1 }} padding="0" textAlign="left">
                  <Category>{category}:</Category>
                  <Question>{question}</Question>
                  <Answer>{answerSingle || answerMultiple}</Answer>
                </Col>
              </Row>
            );
          })}
        </AnamnesisWrapper>
      </Container>
    </PageWrapper>
  );
}

SelfAnamnesisPreview.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.shape({}).isRequired,
  anamnesisFile: PropTypes.string,
  anamnesisPreview: PropTypes.shape({}),
  patientSelectedBooking: PropTypes.shape({}),
  toggleAnamnesisPreview: PropTypes.func.isRequired,
};

SelfAnamnesisPreview.defaultValues = {
  anamnesisFile: null,
  anamnesisPreview: null,
};

export default SelfAnamnesisPreview;

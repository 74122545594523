import { compose, lifecycle, setDisplayName, renameProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form/immutable';
import {
  loadPrivacyPolicy,
  loadTermsOfUse,
  updateTermsRequest,
} from 'containers/Auth/actions';
import {
  selectPrivacyPolicy,
  selectTermsOfUse,
  selectPrivacyPolicyNeedUpdate,
  selectTermsOfUseNeedUpdate,
  selectIsImpersonated,
} from 'containers/Auth/selectors';
import UpdateTermsModal from './UpdateTermsModal';
import { handleAcceptancesFor } from './utils';

const mapStateToProps = createStructuredSelector({
  privacyNeedUpdate: selectPrivacyPolicyNeedUpdate(),
  privacyPolicy: selectPrivacyPolicy(),
  termsNeedUpdate: selectTermsOfUseNeedUpdate(),
  termsOfUse: selectTermsOfUse(),
  isImpersonated: selectIsImpersonated(),
});

const mapDispatchToProps = {
  loadPrivacyPolicy,
  loadTermsOfUse,
};

const onSubmit = (
  values,
  dispatch,
  { privacyPolicy, privacyNeedUpdate, termsOfUse, termsNeedUpdate },
) => {
  const val = values.toJS();

  let terms;
  let termsType;

  if (privacyNeedUpdate) {
    // Handle Privacy acceptances
    termsType = 'privacy';
    terms = handleAcceptancesFor(privacyPolicy)(val);
  } else if (termsNeedUpdate && !privacyNeedUpdate) {
    // Handle TOS acceptances
    termsType = 'tos';
    terms = handleAcceptancesFor(termsOfUse)(val);
  }

  // Submit
  if (termsType && terms) {
    dispatch(updateTermsRequest(termsType, terms));
  }
};

export default compose(
  setDisplayName('UpdateTermsModalContainer'),
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'update-terms',
    onSubmit,
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadPrivacyPolicy();
      this.props.loadTermsOfUse();
    },
  }),
  renameProps({
    privacyNeedUpdate: 'isPrivacyPolicyModalVisible',
    termsNeedUpdate: 'isTosModalVisible',
  }),
)(UpdateTermsModal);

import { defineMessages } from 'react-intl';

export default defineMessages({
  distance: {
    id: 'web.shared.components.searchResults.distance',
    defaultMessage: 'Distance:',
  },
  dr: {
    id: 'web.shared.components.searchResults.doctor',
    defaultMessage: 'Dr.',
  },
  bestMatch: {
    id: 'web.shared.components.searchResults.bestMatch',
    defaultMessage: 'BEST MATCH',
  },
});

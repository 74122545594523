import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { pushError } from 'containers/Alerts/actions';
import { setDataLostErrorAction } from 'containers/PartnersRegistration/actions';
import { selectDataLostError } from 'containers/PartnersRegistration/selectors';
import { selectCallToActionDomain } from '../CallToActionAlert/selectors';
import Alert from './Alert';

const mapStateToProps = createStructuredSelector({
  callToAction: selectCallToActionDomain(),
  dataLostError: selectDataLostError(),
});

const mapDispatchToProps = {
  dispatchPushError: pushError,
  dispatchCleanDataLostError: setDataLostErrorAction,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    // shouldComponentUpdate({ isOpen, dispatchPushError }) {
    //   if (isOpen) {
    //     setTimeout(() => {
    //       dispatchPushError({ text: '' });
    //     }, 5000);
    //   }
    //   return true;
    // },
    componentDidUpdate({ location: { pathname: prevPathname } }) {
      const {
        location: { pathname },
        dispatchPushError,
        dataLostError,
      } = this.props;

      if (pathname !== prevPathname && !dataLostError)
        dispatchPushError({ text: '' });
    },
  }),
)(Alert);

import styled, { css } from 'styled-components';
import { Col, Row as _row, Form as _form } from 'reactstrap';
import _button from '../Button';

export const SearchWrapper = styled(Col)`
  display: flex;
  align-items: center;
  max-width: 210px;
  padding: 0;
`;

export const FilterWrapper = styled.div`
  height: 90px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-size: 1.4rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > span {
    font-weight: 700;
  }
`;

export const Row = styled(_row)`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
      padding-top: ${height / 2 - 15}px;
    `};
`;

export const Form = styled(_form)`
  border-bottom: 1px solid ${({ theme }) => theme.gray200};
  position: relative;
`;

export const SearchContainerTitle = styled.div`
  color: ${({ theme }) => theme.black};
  display: inline-block;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 0 0 10px;
  vertical-align: bottom;
  line-height: 2.2;
`;

export const SearchButton = styled(_button)`
  background: ${({ theme }) => theme.primary} !important;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  cursor: pointer;
  width: 50px;
  border-radius: 0px 50px 50px 0px;
  font-size: 2.2rem;
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100vh;
  z-index: 2;
`;

export const FilterBy = styled.div`
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 600;
`;

import styled, { css } from 'styled-components';
import _profileIcon from 'images/icons/profile/profile';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.gray200};
  border-radius: 50%;
  color: #fff;
  height: ${({ size }) => size}px !important;
  text-align: center;
  width: ${({ size }) => size}px !important;
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background: url(${backgroundImage}) no-repeat;
      background-size: cover;
      background-position: center;
      &:after {
        content: '\u00A0'; /* necessary for right alignment */
      }
    `};
`;

export const Initials = styled.div`
  font-size: ${({ size }) => size / 2}px;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ProfileIcon = styled(_profileIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

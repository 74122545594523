import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Alert } from './styles';
import messages from './messages';

function MyAlert({
  isOpen,
  text,
  intl,
  type,
  callToAction,
  dispatchPushError,
  dispatchCleanDataLostError,
}) {
  return (
    isOpen && (
      <Alert
        color={type || 'danger'}
        isOpen={isOpen}
        ctaopen={callToAction.isOpen}
        toggle={() => {
          dispatchPushError({ text: '' });
          dispatchCleanDataLostError(null);
        }}
      >
        {messages[text] ? intl.formatMessage(messages[text]) : text}
      </Alert>
    )
  );
}

MyAlert.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  dispatchPushError: PropTypes.func.isRequired,
  dispatchCleanDataLostError: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

MyAlert.defaultProps = {
  isOpen: false,
  text: '',
};

export default MyAlert;

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts

export const CheckboxStyles = {
  // The parts of the component
  parts: ['control', 'icon', 'container', 'label'],
  // The styles all button have in common
  baseStyle: {
    control: {
      borderRadius: '5px',
      /* display: 'none !important', */
      _checked: {
        borderColor: 'secondary',
        bg: 'secondary',
        _hover: {
          borderColor: 'secondary',
          bg: 'secondary',
        },
      },
      _hover: {
        borderColor: 'secondaryBG',
        bg: 'secondaryBG',
      },
      _disabled: {
        _checked: {
          borderColor: 'secondary',
          bg: 'secondary',
          opacity: 0.5,
        },
      },
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  sizes: {},
  // Two variants: primary and outline
  variants: {
    grayColor: {
      color: 'grey4',
      backgroundColor: 'red',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'lg',
  },
};

import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  basename: '/pu',
  getUserConfirmation: () => {
    /* Empty callback to block the default browser prompt */
  },
});

export { history };

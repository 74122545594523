import { defineMessages } from 'react-intl';

export default defineMessages({
  leaveMessage: {
    id: 'web.shared.components.modal.dirty.leaveMessage',
    defaultMessage: 'Are you sure you want to leave page without saving?',
  },
  title: {
    id: 'web.shared.components.modal.dirty.title',
    defaultMessage: 'Modal title',
  },
  yes: {
    id: 'web.shared.components.modal.dirty.yes',
    defaultMessage: 'Yes, proceed',
  },
  no: {
    id: 'web.shared.components.modal.dirty.no',
    defaultMessage: 'No, wait a moment',
  },
});

import styled from 'styled-components';

export const circleColorsMapper = {
  approved: 'success',
  confirmed: 'success',
  submitted: 'yellowWarm',
  edit: 'warning',
  working: 'warning',
  pending: 'orangeLight',
  changeRequested: 'danger',
  rejected: 'danger',
  cancelled: 'danger',
  completed: 'dark',
  noshow: 'dark',
  expired: 'dark',
};

export const BigCircusStatus = styled.div`
  background-color: ${({ theme, status }) =>
    theme[circleColorsMapper[status || 'confirmed']]};
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  border-radius: 50%;
  ${({ noMargin }) => !noMargin && 'margin: 70px auto'};
  ${({ inline }) => inline && 'display: inline-block'};
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const StatusIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

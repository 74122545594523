import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { messageHandlerSet } from '../messageHandler/actions';
import { getApprovedVenueDetailsRequest } from '../venues/actions';
import {
  getPracticesRequest,
  getPracticesSuccess,
  getPracticesFailed,
  getPracticesAppointmentsSuccess,
  getPracticesAppointmentsFailed,
  getPracticesToManageSuccess,
  getPracticesToManageFailed,
  getPracticeDetailsRequest,
  getPracticeDetailsSuccess,
  getPracticeDetailsFailed,
  getPracticesCategoriesRequest,
  getPracticesCategoriesSuccess,
  getPracticesCategoriesFailed,
  getPracticesStatusesRequest,
  getPracticesStatusesSuccess,
  getPracticesStatusesFailed,
  getPracticeMedicalServicesRequest,
  getPracticeMedicalServicesSuccess,
  getPracticeMedicalServicesFailed,
  getPracticeMedicalServicesDentalRequest,
  getPracticeMedicalServicesDentalSuccess,
  getPracticeMedicalServicesDentalFailed,
  getPracticeDoctorsRequest,
  getPracticeDoctorsSuccess,
  getPracticeDoctorsFailed,
  putPracticesRequest,
  putPracticesSuccess,
  putPracticesFailed,
  putProntoCarePracticesRequest,
  putProntoCarePracticesSuccess,
  putProntoCarePracticesFailed,
  deletePracticeServicesRequest,
  deletePracticeServicesSuccess,
  deletePracticeServicesFailed,
  payPracticesCouponRequest,
  payPracticesCouponSuccess,
  payPracticesCouponFailed,
  closePracticeRequest,
  closePracticeSuccess,
  closePracticeFailed,
  addInvoiceRequest,
  addInvoiceSuccess,
  addInvoiceErrors,
  addInvoiceFailed,
  getFamilyListRequest,
  getFamilyListSuccess,
  getFamilyListFailed,
  getVenuesListRequest,
  getVenuesListSuccess,
  getVenuesListFailed,
  createPracticeCouponRequest,
  createPracticeCouponSuccess,
  createPracticeCouponFailed,
} from './actions';
import * as PracticesAPI from './apiCall';
import {
  GetPracticesListRequestPayload,
  GetPracticeDetailsRequestPayload,
  GetPracticeMedicalServicesRequestPayload,
  GetPracticeDoctorsRequestPayload,
  PutPracticeRequestPayload,
  DeletePracticeServicesRequestPayload,
  PayPracticeCouponRequestPayload,
  ClosePracticeRequestPayload,
  AddInvoiceRequestPayload,
  GetFamilyListRequestPayload,
  GetVenuesListRequestPayload,
  CreateCouponPracticeRequestPayload,
  PutProntoCarePracticeRequestPayload,
} from './types';

function* getPracticesListSaga({ payload }: PayloadAction<GetPracticesListRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getPracticesList, { ...payload });
    const { data } = response;

    if (payload.homeTab === 0) {
      yield put(getPracticesAppointmentsSuccess(data));
    } else if (payload.homeTab === 1) {
      yield put(getPracticesToManageSuccess(data));
    } else {
      yield put(getPracticesSuccess(data));
    }
  } catch (error: any) {
    if (payload.homeTab === 0) {
      yield put(getPracticesAppointmentsFailed());
    } else if (payload.homeTab === 1) {
      yield put(getPracticesToManageFailed());
    } else {
      yield put(getPracticesFailed());
    }
    // yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* practiceDetailsSaga({ payload }: PayloadAction<GetPracticeDetailsRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getPracticeDetails, { ...payload });
    const { data } = response;

    // Get venue data
    const locationId = data?.providerDetail?.providerUUID;
    if (locationId) {
      yield put(getApprovedVenueDetailsRequest({ locationId }));
    }

    yield put(getPracticeDetailsSuccess(data));
  } catch (error: any) {
    yield put(getPracticeDetailsFailed());
  }
}

function* getPracticesCategoriesSaga() {
  try {
    const response = yield call(PracticesAPI.getPracticesCategories);
    const { data } = response;

    yield put(getPracticesCategoriesSuccess(data));
  } catch (error: any) {
    yield put(getPracticesCategoriesFailed());
  }
}

function* getPracticesStatusesSaga() {
  try {
    const response = yield call(PracticesAPI.getPracticesStatuses);
    const { data } = response;

    yield put(getPracticesStatusesSuccess(data));
  } catch (error: any) {
    yield put(getPracticesStatusesFailed());
  }
}

function* getPracticeMedicalServicesSaga({ payload }: PayloadAction<GetPracticeMedicalServicesRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getPracticeMedicalServices, { ...payload });
    const { data } = response;

    yield put(getPracticeMedicalServicesSuccess(data));
  } catch (error: any) {
    yield put(getPracticeMedicalServicesFailed());
  }
}

function* getPracticeMedicalServicesDentalSaga() {
  try {
    const response = yield call(PracticesAPI.getPracticeMedicalServicesDental);
    const { data } = response;

    yield put(getPracticeMedicalServicesDentalSuccess(data));
  } catch (error: any) {
    yield put(getPracticeMedicalServicesDentalFailed());
  }
}

function* getPracticeDoctorsSaga({ payload }: PayloadAction<GetPracticeDoctorsRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getPracticeDoctors, { ...payload });
    const { data } = response;

    yield put(getPracticeDoctorsSuccess(data));
  } catch (error: any) {
    yield put(getPracticeDoctorsFailed());
  }
}

function* putPracticeSaga({ payload }: PayloadAction<PutPracticeRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.putPractice, { ...payload });
    const { data } = response;

    yield put(putPracticesSuccess(data));
  } catch (error: any) {
    yield put(putPracticesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* putProntoCarePracticeSaga({ payload }: PayloadAction<PutProntoCarePracticeRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.putProntoCarePractice, { ...payload });
    const { data } = response;

    yield put(putProntoCarePracticesSuccess(data));
  } catch (error: any) {
    yield put(putProntoCarePracticesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* deletePracticeServicesSaga({ payload }: PayloadAction<DeletePracticeServicesRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.deletePracticeServices, { ...payload });
    const { data } = response;

    yield put(deletePracticeServicesSuccess(data));
  } catch (error: any) {
    yield put(deletePracticeServicesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* payPracticeCouponSaga({ payload }: PayloadAction<PayPracticeCouponRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.payPracticesCoupon, { ...payload });
    const { data } = response;
    yield put(payPracticesCouponSuccess(data));
  } catch (error: any) {
    yield put(payPracticesCouponFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* closePracticeSaga({ payload }: PayloadAction<ClosePracticeRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.closePractice, { ...payload });
    const { data } = response;

    yield put(closePracticeSuccess(data));
  } catch (error: any) {
    yield put(closePracticeFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* addInvoiceSaga({ payload }: PayloadAction<AddInvoiceRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.addInvoice, { ...payload });
    const { status, data } = response;

    if (status === 200) {
      yield put(addInvoiceSuccess(data));
    } else if (status === 202) {
      yield put(addInvoiceErrors(data));
    } else {
      yield put(addInvoiceFailed());
    }
  } catch (error: any) {
    yield put(addInvoiceFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getFamilyListSaga({ payload }: PayloadAction<GetFamilyListRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getFamilyList, { ...payload });
    const { data } = response;

    yield put(getFamilyListSuccess(data));
  } catch (error: any) {
    yield put(getFamilyListFailed());
    // yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenuesListSaga({ payload }: PayloadAction<GetVenuesListRequestPayload>) {
  try {
    const response = yield call(PracticesAPI.getVenuesList, { ...payload });
    const { data } = response;

    yield put(getVenuesListSuccess(data));
  } catch (error: any) {
    yield put(getVenuesListFailed());
    // yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* createCouponPracticeSaga({ payload }: PayloadAction<CreateCouponPracticeRequestPayload>) {
  try {
    yield call(PracticesAPI.createPracticesCoupon, { ...payload });

    yield put(createPracticeCouponSuccess());
  } catch (error: any) {
    yield put(createPracticeCouponFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* practicesSaga(): Generator<ForkEffect<never>, void> {
  yield takeEvery(getPracticesRequest.type, getPracticesListSaga);
  yield takeLatest(getPracticeDetailsRequest.type, practiceDetailsSaga);
  yield takeLatest(getPracticesCategoriesRequest.type, getPracticesCategoriesSaga);
  yield takeLatest(getPracticesStatusesRequest.type, getPracticesStatusesSaga);
  yield takeLatest(getPracticeMedicalServicesRequest.type, getPracticeMedicalServicesSaga);
  yield takeLatest(getPracticeMedicalServicesDentalRequest.type, getPracticeMedicalServicesDentalSaga);
  yield takeLatest(getPracticeDoctorsRequest.type, getPracticeDoctorsSaga);
  yield takeLatest(putPracticesRequest.type, putPracticeSaga);
  yield takeLatest(putProntoCarePracticesRequest.type, putProntoCarePracticeSaga);
  yield takeLatest(deletePracticeServicesRequest.type, deletePracticeServicesSaga);
  yield takeLatest(payPracticesCouponRequest.type, payPracticeCouponSaga);
  yield takeLatest(closePracticeRequest.type, closePracticeSaga);
  yield takeLatest(addInvoiceRequest.type, addInvoiceSaga);
  yield takeLatest(getFamilyListRequest.type, getFamilyListSaga);
  yield takeLatest(getVenuesListRequest.type, getVenuesListSaga);
  yield takeLatest(createPracticeCouponRequest.type, createCouponPracticeSaga);
}

export default practicesSaga;

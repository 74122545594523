import Spinkit from 'react-spinkit';
import styled from 'styled-components';

export const FileName = styled.div`
  color: ${({ theme }) => theme.gray400};
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
`;

export const FilePreviewUrl = styled.div`
  background-color: ${({ theme }) => theme.gray100};
  ${({ theme, onlyview }) => onlyview && `border: 2px dashed ${theme.gray200}`};
  ${({ disableClick }) => disableClick && `pointer-events: none`};
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 150px;
  max-width: 260px;
  min-height: 150px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  word-break: break-all;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    min-height: ${({ onlyview }) => (onlyview ? '170px' : 'auto')};
  }

  @supports (-ms-ime-align: auto) {
    /* Edge */
    min-height: ${({ onlyview }) => (onlyview ? '170px' : 'auto')};
  }

  a {
    text-decoration: none;
    display: contents !important;
  }
`;

export const ImagePreview = styled.div`
  background-image: url(${({ filename }) => filename});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 150px;
  height: 100%;
  width: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    min-height: 150px;
    min-width: 220px;
    border-radius: 5px;
  }

  @supports (-ms-ime-align: auto) {
    /* Edge */
    min-height: 150px;
    min-width: 220px;
    border-radius: 5px;
  }
`;

export const Spinner = styled(Spinkit).attrs({
  name: 'ball-spin-fade-loader',
  color: `${({ theme }) => theme.yellow}`,
})`
  transform: scale(0.5);
`;

export const SpinnerWrapper = styled.div`
  flex: 1;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

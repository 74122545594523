import React from 'react';

const CFArrowLeft = props => (
  <svg width="1em" height="1em" viewBox="0 0 12 22" {...props}>
    <title>arrow-right</title>
    <path
      d="M16 8C16 7.44772 15.5523 7 15 7H4.31284C4.13466 7 4.04543 6.78457 4.17142 6.65858L8.71039 2.11961C9.10189 1.72811 9.10037 1.09262 8.70749 0.702509C8.31657 0.314339 7.68494 0.315064 7.29539 0.704613L0.707106 7.29289C0.316582 7.68342 0.316583 8.31658 0.707107 8.70711L7.29468 15.2947C7.68422 15.6842 8.31578 15.6842 8.70532 15.2947C9.0946 14.9054 9.09489 14.2743 8.70595 13.8847L4.17068 9.34129C4.04486 9.21525 4.13414 9 4.31223 9H15C15.5523 9 16 8.55229 16 8Z"
      fill="black"
    />
  </svg>
);

export default CFArrowLeft;

import React from 'react';
import PropTypes from 'prop-types';
/* import { Dropdown } from 'reactstrap'; */
import {
  HeaderWrapper,
  /*   TooltipContainer, */
  DateTitle,
  /*   DropdownToggle,
  DropdownMenu,
  ArrowDown,
  Agenda, */
  // ArrowLeft,
  // ArrowRight,
  PreviousMonth,
  NextMonth,
} from './styles';

function DatePickerHeader({
  focusedDate,
  changeShownDate,
  showMonthArrow,
  locale,
  minDate,
  maxDate,
  isOpen,
  toggle,
}) {
  /*   const upperYearLimit = maxDate.getFullYear();
  const lowerYearLimit = minDate.getFullYear(); */
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onMouseUp={(e) => e.stopPropagation()}>
      <HeaderWrapper>
        {showMonthArrow ? (
          <PreviousMonth onClick={() => changeShownDate(-1, 'monthOffset')}>
            <span>{locale.localize.months()[focusedDate.getMonth() - 1]}</span>
          </PreviousMonth>
        ) : /*  <ArrowLeft onClick={() => changeShownDate(-1, 'monthOffset')}/> */
        null}
        <div>
          <DateTitle>
            {locale.localize.months()[focusedDate.getMonth()]}{' '}
            {focusedDate.getFullYear()}
          </DateTitle>
          {/* <select
                  value={focusedDate.getMonth()}
                  onChange={e => changeShownDate(e.target.value, 'setMonth')}
                >
                  {locale.localize.months().map((month, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={i} value={i}>
                      {month}
                    </option>
                  ))}
                </select> */}

          {/*     <select
                  value={focusedDate.getFullYear()}
                  onChange={e => changeShownDate(e.target.value, 'setYear')}
                >
                  {new Array(upperYearLimit - lowerYearLimit + 1)
                    .fill(upperYearLimit)
                    .map((val, i) => {
                      const year = val - i;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                </select> */}
        </div>

        {showMonthArrow ? (
          <NextMonth onClick={() => changeShownDate(+1, 'monthOffset')}>
            <span>{locale.localize.months()[focusedDate.getMonth() + 1]}</span>
          </NextMonth>
        ) : /*  <ArrowRight onClick={() => changeShownDate(+1, 'monthOffset')} /> */
        null}

        {/* <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle>
            <Agenda />
            <ArrowDown />
          </DropdownToggle>
          <DropdownMenu right>
            <TooltipContainer>
              {showMonthArrow ? (
                <ArrowLeft onClick={() => changeShownDate(-1, 'monthOffset')} />
              ) : null}
              <div>
                <select
                  value={focusedDate.getMonth()}
                  onChange={e => changeShownDate(e.target.value, 'setMonth')}
                >
                  {locale.localize.months().map((month, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={i} value={i}>
                      {month}
                    </option>
                  ))}
                </select>

                <select
                  value={focusedDate.getFullYear()}
                  onChange={e => changeShownDate(e.target.value, 'setYear')}
                >
                  {new Array(upperYearLimit - lowerYearLimit + 1)
                    .fill(upperYearLimit)
                    .map((val, i) => {
                      const year = val - i;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                </select>
              </div>

              {showMonthArrow ? (
                <ArrowRight
                  onClick={() => changeShownDate(+1, 'monthOffset')}
                />
              ) : null}
            </TooltipContainer>
          </DropdownMenu>
        </Dropdown> */}
      </HeaderWrapper>
    </div>
  );
}

DatePickerHeader.propTypes = {
  focusedDate: PropTypes.string,
  changeShownDate: PropTypes.func.isRequired,
  showMonthArrow: PropTypes.bool,
  locale: PropTypes.shape({}),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default DatePickerHeader;

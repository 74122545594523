import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';

export function* getForgotPassword({ data }) {
  const url = `auth/pass/reset`;
  try {
    yield put(showLoading());
    yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: { ...data, role: 'partner' },
    });
    yield put(
      pushError({ text: 'forgotPasswordSuccess', typeError: 'success' }),
    );
  } catch (e) {
    const { packet, response } = e; // eslint-disable-line no-unused-vars
    if (response.status === 422) {
      yield put(pushError({ text: 'forgotPasswordDataError' }));
    } else {
      yield put(pushError({ text: 'forgotPasswordError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

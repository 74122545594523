import styled from 'styled-components';
import { Button as _button } from '@components';

export const ModalTitle = styled.h3`
  color: ${({ theme }) => theme.gray400};
  font-size: ${({ theme }) => theme.fontSizeLg};
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
`;

export const Button = styled(_button)`
  width: 100%;
  max-width: 310px;
  margin-top: 20px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: ${({ theme }) => theme.fontSizeBase};
  text-align: center;
`;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { Field } from 'redux-form/immutable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as rdrLocales from 'react-date-range/dist/locale';
// import Row from '../Row';
// import Col from '../Col';
import DatePickerHeader from './Header/index';
import Day from './Day/index';
import { Wrapper, DateRange /*Input*/ } from './styles';

function MyDateRange({
  onChange,
  startDateDataForm,
  endDateDataForm,
  months,
  direction,
  maxWidth,
  isOpen,
  toggle,
  getAvailabilities,
  resetAvailabilities,
  service,
  availabilitiesAccessor,
  availabilitiesLoadingAccessor,
  availabilities,
  availabilitiesLoading,
  locale,
}) {
  const selectionRange = {
    startDate:
      startDateDataForm.input.value &&
      moment(startDateDataForm.input.value).isValid()
        ? moment(startDateDataForm.input.value).toDate()
        : new Date(),
    endDate:
      endDateDataForm.input.value &&
      moment(endDateDataForm.input.value).isValid()
        ? moment(endDateDataForm.input.value).toDate()
        : new Date(),
    key: 'selection',
  };

  return (
    <Wrapper maxWidth={maxWidth}>
      <DateRange
        ranges={[selectionRange]}
        onChange={onChange}
        showDateDisplay={false}
        minDate={new Date()}
        months={months}
        direction={direction}
        isOpen={isOpen}
        toggle={toggle}
        locale={rdrLocales[locale]}
        navigatorRenderer={DatePickerHeader}
        dayRenderer={Day}
        getAvailabilities={getAvailabilities}
        service={service}
        resetAvailabilities={resetAvailabilities}
        availabilitiesAccessor={availabilitiesAccessor}
        availabilitiesLoadingAccessor={availabilitiesLoadingAccessor}
        availabilities={availabilities}
        availabilitiesLoading={availabilitiesLoading}
      />
      {/* <Row>
        <Col paddingRight="0">
          <Field
            component={Input}
            type="start"
            {...startDateDataForm.input}
            normalize={value =>
              value && moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD')
            }
            format={value =>
              value && moment(value, 'YYYY/MM/DD').format('DD/MM/YYYY')
            }
            disabled
            size="lg"
          />
        </Col>
        <Col paddingLeft="0">
          <Field
            component={Input}
            type="end"
            {...endDateDataForm.input}
            normalize={value =>
              value && moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD')
            }
            format={value =>
              value && moment(value, 'YYYY/MM/DD').format('DD/MM/YYYY')
            }
            disabled
            size="lg"
          />
        </Col>
      </Row> */}
    </Wrapper>
  );
}

MyDateRange.propTypes = {
  maxWidth: PropTypes.string,
  months: PropTypes.number,
  direction: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  startDateDataForm: PropTypes.shape({}).isRequired,
  endDateDataForm: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  getAvailabilities: PropTypes.func.isRequired,
  resetAvailabilities: PropTypes.func.isRequired,
  service: PropTypes.number,
  availabilities: PropTypes.shape({}),
  availabilitiesLoading: PropTypes.bool,
  availabilitiesAccessor: PropTypes.string.isRequired,
  availabilitiesLoadingAccessor: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

MyDateRange.defaultProps = {};

export default MyDateRange;

import React from 'react';

const Clock = props => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
    <title>Clock</title>
    <path
      d="M36 18c0 9.942-8.058 18-18 18S0 27.942 0 18 8.058 0 18 0s18 8.058 18 18zm-1.895 0c0-8.896-7.21-16.105-16.105-16.105C9.104 1.895 1.895 9.105 1.895 18c0 8.896 7.21 16.105 16.105 16.105 8.896 0 16.105-7.21 16.105-16.105zM18.947 8.526v9.082l5.407 5.406a.947.947 0 01-1.34 1.34L17.33 18.67a.947.947 0 01-.277-.67V8.526a.947.947 0 011.894 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Clock;

/* istanbul ignore file */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  refreshAuthenticationInterceptor,
  requestSuccessInterceptor,
  requestErrorInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptor,
} from './interceptors';

// AXIOS
export const APIClient = axios.create({
  baseURL: window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// AXIOS PU
export const APIClientPU = axios.create({
  baseURL: window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Instantiate the auth refresh interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(APIClient, refreshAuthenticationInterceptor, {
  statusCodes: [401, 403], // default: [ 401 ]
  retryInstance: APIClient, // try to refresh on 403 when sometimes doesn't do that
  // pauseInstanceWhileRefreshing: true,
});

createAuthRefreshInterceptor(APIClientPU, refreshAuthenticationInterceptor, {
  statusCodes: [401, 403], // default: [ 401 ]
  retryInstance: APIClientPU, // try to refresh on 403 when sometimes doesn't do that
  // pauseInstanceWhileRefreshing: true,
});

// Set request interceptor to add Auth token
APIClient.interceptors.request.use(requestSuccessInterceptor, requestErrorInterceptor);

APIClientPU.interceptors.request.use(requestSuccessInterceptor, requestErrorInterceptor);

APIClient.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

APIClientPU.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

import React from 'react';

const DocARC = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(212, 181, 24)"
    />
    <path
      fill-rule="evenodd"
      d="M 4.962 12.31 L 4.962 11.9 L 5.962 11.9 L 5.962 13.13 L 2.652 13.13 L 2.652 12.41 L 4.192 10.06 L 3.672 10.06 L 3.672 10.47 L 2.682 10.47 L 2.682 9.24 L 5.872 9.24 L 5.872 9.96 L 4.342 12.31 L 4.962 12.31 ZM 8.023 13.13 L 6.123 13.13 L 6.123 12.31 L 6.373 12.31 L 6.373 10.06 L 6.123 10.06 L 6.123 9.24 L 8.023 9.24 L 8.023 10.06 L 7.773 10.06 L 7.773 12.31 L 8.023 12.31 L 8.023 13.13 ZM 8.221 13.13 L 8.221 12.31 L 8.471 12.31 L 8.471 10.06 L 8.221 10.06 L 8.221 9.24 L 10.411 9.24 C 10.845 9.24 11.175 9.333 11.401 9.52 C 11.628 9.707 11.741 10.007 11.741 10.42 C 11.741 10.827 11.628 11.123 11.401 11.31 C 11.175 11.497 10.845 11.59 10.411 11.59 L 9.871 11.59 L 9.871 12.31 L 10.351 12.31 L 10.351 13.13 L 8.221 13.13 Z M 9.871 10.88 L 10.041 10.88 C 10.235 10.88 10.331 10.773 10.331 10.56 L 10.331 10.38 C 10.331 10.267 10.308 10.187 10.261 10.14 C 10.208 10.087 10.135 10.06 10.041 10.06 L 9.871 10.06 L 9.871 10.88 Z"
      transform="matrix(0.8579750061035156, 0, 0, 0.9071421027183533, 3.0855319499969482, 0.9755520820617676)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocARC;

import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import {
  getAllPracticesAttachmentsFailed,
  getAllPracticesAttachmentsRequest,
  getAllPracticesAttachmentsSuccess,
  getAllPracticesInvoicesFailed,
  getAllPracticesInvoicesRequest,
  getAllPracticesInvoicesSuccess,
} from './actions';
import * as DocumentsApi from './apiCall';
import { GetAllPracticesAttachmentsRequestPayload, GetAllPracticesInvoicesRequestPayload } from './types';

function* getAllPracticesAttachmentsSaga({ payload }: PayloadAction<GetAllPracticesAttachmentsRequestPayload>) {
  try {
    const response = yield call(DocumentsApi.getAllPracticesAttachments, { ...payload });
    const { data } = response;

    yield put(getAllPracticesAttachmentsSuccess(data));
  } catch (error: any) {
    yield put(getAllPracticesAttachmentsFailed());
  }
}

function* getAllPracticesInvoicesSaga({ payload }: PayloadAction<GetAllPracticesInvoicesRequestPayload>) {
  try {
    const response = yield call(DocumentsApi.getAllPracticesInvoices, { ...payload });
    const { data } = response;

    yield put(getAllPracticesInvoicesSuccess(data));
  } catch (error: any) {
    yield put(getAllPracticesInvoicesFailed());
  }
}

function* documentsSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getAllPracticesAttachmentsRequest.type, getAllPracticesAttachmentsSaga);
  yield takeLatest(getAllPracticesInvoicesRequest.type, getAllPracticesInvoicesSaga);
}

export default documentsSaga;

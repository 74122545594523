import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { statusVisibility } from 'containers/PartnersRegistration/shared';
import { hideFooterHeader } from 'utils/env';
import HeaderProfileSwitcher from './HeaderProfileSwitcher';
import { Wrapper, Logo, Link } from './styles';
import messages from './messages';

function Header({ pathname, partnerPracticeStatus, goToStep }) {
  return (
    <Wrapper>
      <div>
        {!statusVisibility(partnerPracticeStatus) ? (
          <Link to="/">
            <Logo />
          </Link>
        ) : (
          <Link to="/partner-registration" onClick={() => goToStep({ step: 0, wizardIsOpen: false })}>
            <Logo />
          </Link>
        )}
        {!hideFooterHeader && (
          <Fragment>
            <Link to="/#" className="helpers">
              <FormattedMessage {...messages.community} />
            </Link>
            <Link to="/#" className="helpers">
              <FormattedMessage {...messages.needHelp} />
            </Link>
          </Fragment>
        )}
      </div>
      {pathname !== '/login' && <HeaderProfileSwitcher />}
    </Wrapper>
  );
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  partnerPracticeStatus: PropTypes.string,
  goToStep: PropTypes.func.isRequired,
};

Header.defaultProps = {
  partnerPracticeStatus: null,
};

export default Header;

/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { routerMiddleware } from 'connected-react-router/immutable';
import { captureMessage } from '@sentry/browser';
// import RavenMiddleware from 'redux-raven-middleware';
import createSagaMiddleware from 'redux-saga';
import { env } from 'utils/env';
import { changeLocale } from 'containers/LanguageProvider/actions';
import createReducer, { history } from './reducers';
// import { routerMiddleware } from 'react-router-redux';

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    captureMessage(error);
  },
});

export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    // RavenMiddleware(sentryDns),
    loadingBarMiddleware(),
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    env !== 'production' && // disabled for a test in uat
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          name: 'Goodme',
        })
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  const currentLanguage = localStorage.getItem('i18nextLng');
  if (currentLanguage) {
    store.dispatch(changeLocale(currentLanguage));
  }

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}

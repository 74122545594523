import i18n from '@apps/pu/i18n';
import { GetSingleFAQSuccessPayload } from '@apps/pu/pages/Support/ListPage/Tabs/FAQ/types';
import {
  AnswerToSupportTicketRequestPayload,
  AnswerToSupportTicketSuccessPayload,
  GetSingleTicketSuccessPayload,
} from '@apps/pu/pages/Support/ListPage/Tabs/Tickets/types';
import { APIClient, APIClientPU } from '@common/common/utils';
import { isEmpty, map, without } from 'lodash';
import {
  CreateSupportTicketRequestPayload,
  CreateSupportTicketSuccessPayload,
  GetFAQListRequestPayload,
  GetSingleFAQRequestPayload,
  GetSingleTicketRequestPayload,
  GetTicketsListRequestPayload,
  GetPracticeTicketsListRequestPayload,
  SelectAutocompleteOption,
  GetTicketFieldsSuccessPayload,
} from './types';

export async function getFAQList({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
}: GetFAQListRequestPayload): Promise<Response> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'fullText',
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/articles/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getFAQList - Error: ', error);
    throw error;
  }
}

export async function getSingleFAQ({ id }: GetSingleFAQRequestPayload): Promise<GetSingleFAQSuccessPayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/articles/${id}`,
    );

    return response.data;
  } catch (error) {
    console.error('getSingleFAQ - Error: ', error);
    throw error;
  }
}

export async function getTicketsList({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetTicketsListRequestPayload): Promise<Response> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'fullText',
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const venuesFilter = !isEmpty(popoverFilters?.venues)
    ? {
        field: 'locationId',
        operator: 'in',
        value: popoverFilters?.venues,
      }
    : null;

  const dateFromFilter =
    popoverFilters?.dateFrom !== undefined
      ? popoverFilters?.dateFrom && {
          field: 'dateFrom',
          operator: 'eq',
          value: popoverFilters?.dateFrom,
        }
      : null;

  const dateToFilter =
    popoverFilters?.dateTo !== undefined
      ? popoverFilters?.dateTo && {
          field: 'dateTo',
          operator: 'eq',
          value: popoverFilters?.dateTo,
        }
      : null;

  const updateFromFilter =
    popoverFilters?.updateFrom !== undefined
      ? popoverFilters?.updateFrom && {
          field: 'updateFrom',
          operator: 'eq',
          value: popoverFilters?.updateFrom,
        }
      : null;

  const updateToFilter =
    popoverFilters?.updateTo !== undefined
      ? popoverFilters?.updateTo && {
          field: 'updateTo',
          operator: 'eq',
          value: popoverFilters?.updateTo,
        }
      : null;

  const statusFilter = !isEmpty(popoverFilters?.status)
    ? {
        field: 'status',
        operator: 'in',
        value: popoverFilters?.status,
      }
    : null;

  const filtersBy = !isEmpty(popoverFilters)
    ? [venuesFilter, dateFromFilter, dateToFilter, updateFromFilter, updateToFilter, statusFilter]
    : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(searchTextFilter) || !isEmpty(filtersBy)
      ? {
          filter: [...searchTextFilter, ...without(filtersBy, undefined, null)],
        }
      : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/tickets/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getTicketsList - Error: ', error);
    throw error;
  }
}

export async function getAllPracticeTicketsList({ id }: GetPracticeTicketsListRequestPayload): Promise<Response> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}/assistance/filter`,
    );

    return response.data;
  } catch (error) {
    console.error('getPracticeTicketsList - Error: ', error);
    throw error;
  }
}

export async function getSingleTicket({ id }: GetSingleTicketRequestPayload): Promise<GetSingleTicketSuccessPayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/tickets/${id}`,
    );

    return response.data;
  } catch (error) {
    console.error('getSingleTicket - Error: ', error);
    throw error;
  }
}

// { label: 'Nessuna sede in particolare', value: null }
export const loadOpAssignedVenues = async (
  inputValue?: string,
  callback?: (options: SelectAutocompleteOption[]) => void,
) => {
  const baseUrl = window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL;
  const urlWithParams = `${baseUrl}/partners/pu/me/locations?page=1&itemsPerPage=999`;

  const searchTextFilter = inputValue
    ? [
        {
          field: 'name',
          operator: 'contains',
          value: inputValue,
        },
      ]
    : [];

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  const location = await APIClient.post(urlWithParams, {
    ...allFilters,
  })
    .then((elements) => {
      if (!isEmpty(elements)) {
        const optionsList = map(elements?.data?.data?.results, (location) => {
          return {
            value: location.additionalData.accountid,
            label: location.name,
          };
        });
        callback && callback(optionsList);

        return [{ label: i18n.t('web.pu.Support.TicketModal.NoParticularVenueSelected'), value: -1 }].concat(
          optionsList,
        );
      }
    })
    .catch((error) => console.log('load location error: ', error));

  return location;
};

export async function getTicketFields(): Promise<GetTicketFieldsSuccessPayload> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/tickets/types/filter`,
    );

    return response.data;
  } catch (error) {
    console.error('getTicketFields - Error: ', error);
    throw error;
  }
}

export async function createSupportTicket({
  payload,
}: CreateSupportTicketRequestPayload): Promise<CreateSupportTicketSuccessPayload> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/tickets`,
      {
        ...payload,
      },
    );

    return response.data;
  } catch (error) {
    console.error('createSupportTicket - Error: ', error);
    throw error;
  }
}

export async function answerToSupportTicket({
  id,
  body,
}: AnswerToSupportTicketRequestPayload): Promise<AnswerToSupportTicketSuccessPayload> {
  try {
    const response = await APIClientPU.put(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/assistance/tickets/${id}`,
      {
        body,
      },
    );

    return response.data;
  } catch (error) {
    console.error('answerToSupportTicket - Error: ', error);
    throw error;
  }
}

/**
 * web.partner.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Needed for redux-saga es6 generator support
// import 'babel-polyfill';
// import { ConnectedRouter } from 'react-router-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
// import ReactGA from 'react-ga';
// import 'sanitize.css/sanitize.css';
// import '@bootstrap/index.scss';
// Import basename
// Import root app
import App from 'containers/App';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
// Import all the third party stuff
import React from 'react';
// import 'react-app-polyfill/ie11';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
// Load the favicon and the .htaccess file

/* eslint-disable import/no-unresolved, import/extensions */
// import '!file-loader?name=[name].[ext]!images/favicon.ico';
// import 'file-loader?name=[name].[ext]!.htaccess';

/* eslint-enable import/no-unresolved, import/extensions */
import configureStore from './configureStore';
import Auth from './containers/Auth';
// Import i18n messages
// import { translationMessages } from './i18n';
// Import CSS reset and Global Styles
import './global-styles';
import { history } from './reducers';

export const theme = require('../shared/src/bootstrap/variables.scss');

// Create redux store with history
const initialState = {};

export const store = configureStore(initialState, history);
// const MOUNT_NODE = document.getElementById('app');

const goodMeApp = (messages) => {
  return (
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Auth>
              <App />
            </Auth>
          </ConnectedRouter>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  );
};

export default goodMeApp;

// const render = messages => {
//   ReactDOM.render(
//     <Provider store={store}>
//       <LanguageProvider messages={messages}>
//         <ThemeProvider theme={theme}>
//           <ConnectedRouter history={history}>
//             <App />
//           </ConnectedRouter>
//         </ThemeProvider>
//       </LanguageProvider>
//     </Provider>,
//     MOUNT_NODE,
//   );
// };

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render(translationMessages);
//   });
// }

// // Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'));
//   })
//     .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {
//   render(translationMessages);
// }

import { useContext } from 'react';

import type { AuthContext } from '../types/authContext';
import { authContext } from '../utils/authContext';

export function useAuth(): AuthContext {
  const ctx = useContext(authContext);

  if (!ctx) {
    throw new Error('useAuth hook must be used inside AuthProvider context');
  }

  return ctx;
}

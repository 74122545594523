// import { getAccessToken, getRefreshToken } from '@common/auth/utils/token';
import { getAccessToken, getRefreshToken } from '@common/auth/utils/token';
import { createSelector } from 'reselect';

const selectAuthDomain = () => (state) => state.get('auth');
const selectRouteDomain = () => (state) => state.get('router');

export const selectFromPathname = () =>
  createSelector(selectRouteDomain(), (state) => state.getIn(['location', 'state', 'from', 'pathname']));

export const selectIsAuthenticated = () => createSelector(selectAuthDomain(), (auth) => auth.get('isAuthenticated'));

export const selectUserId = () => createSelector(selectAuthDomain(), (auth) => auth.get('userId'));

export const selectPartnerStatus = () =>
  createSelector(selectAuthDomain(), (auth) => localStorage.getItem('partner_status') || auth.get('partnerStatus'));

export const selectPartnerFirstApprovalReviewed = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('partnerFirstApprovalReviewed'));

export const selectSendPasswordEmail = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('sendPasswordEmailModal'));

export const selectPasswordToken = () => createSelector(selectAuthDomain(), (auth) => auth.get('changePassToken'));

// export const selectAuthToken = () => createSelector(selectAuthDomain(), (auth) => auth.get('jwt'));

// export const selectAuthToken = () => createSelector(() => localStorage.getItem('jwt_my_care'));

export const selectAuthToken = () => createSelector(() => getAccessToken());

// export const selectRefreshToken = () => createSelector(selectAuthDomain(), (auth) => auth.get('refreshToken'));

// export const selectRefreshToken = () => createSelector(() => localStorage.getItem('refresh_token_my_care'));

export const selectRefreshToken = () => createSelector(() => getRefreshToken());

export const selectPrivacyPolicy = () =>
  createSelector(selectAuthDomain(), (auth) => {
    const privacyPolicy = auth.get('privacy');
    return !privacyPolicy ? {} : privacyPolicy.toJS();
  });

export const selectTermsOfUse = () =>
  createSelector(selectAuthDomain(), (auth) => {
    const termsOfUse = auth.get('tos');
    return !termsOfUse ? {} : termsOfUse.toJS();
  });

export const selectPrivacyPolicyNeedUpdate = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('privacyNeedUpdate', false));

export const selectTermsOfUseNeedUpdate = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('tosNeedUpdate', false));

export const selectIsImpersonated = () => createSelector(selectAuthDomain(), (auth) => auth.get('isImpersonated'));

export const selectImpersonatedBy = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('impersonatedBy') && auth.get('impersonatedBy').toJS());

export const selectGeolocation = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('geolocation') && auth.get('geolocation').toJS());

export const selectFromRedirectUrlPostLogin = () =>
  createSelector(selectAuthDomain(), (auth) => auth.get('redirectUrlPostLogin'));

import { managedResponsesErrorUrls, excludedResponsesErrorUrls } from '@apps/pu/constants/errors';
import { AppRoutes } from '@apps/pu/routes/routesList';
import { history } from '@utilsRoot/history';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import includes from 'lodash/includes';
import some from 'lodash/some';
import { getAccessToken, refreshAuthTokens } from '../../auth/utils/token';

// AUTH

export async function refreshAuthenticationInterceptor(failedRequest: any) {
  // Function that will be called to refresh authorization
  // const { accessToken: token } = await refreshAuthTokens();

  return (
    refreshAuthTokens()
      // @TODO: FIX post upgrade
      .then(({ data }: any) => {
        // Set updated token inside provided request
        failedRequest.response.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
      })
      .catch((e) => {
        console.log('refreshAuthenticationInterceptor>> ', e);
      })
  );

  // return new Promise((resolve) => {
  //   setTimeout(resolve, 1000);
  // });
}

// REQUEST

export async function requestSuccessInterceptor(axiosRequestCfg: AxiosRequestConfig) {
  try {
    // Check and acquire a token before the request is sent
    const token = await getAccessToken();

    // Set token inside provided request
    if (axiosRequestCfg?.headers) axiosRequestCfg.headers.Authorization = `Bearer ${token}`;
  } catch (err) {
    // Do something with error of acquiring the token
    console.error('getAuthToken:', err);
  }

  return axiosRequestCfg;
}

export function requestErrorInterceptor(error: any) {
  // Do nothing in case of request error
  return Promise.reject(error);
}

// RESPONSE

export function responseSuccessInterceptor(axiosReponse: AxiosResponse) {
  // Do nothing with response
  return axiosReponse;
}

export function responseErrorInterceptor({ request, response, message }: any) {
  let status = -1;
  let errMsg = '';
  // const regex = /User has not access/g;

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    status = response?.status ?? 500;
    errMsg = response?.statusText ?? 'Unknow server error';
    const requestURL = request?.responseURL ?? '';
    if (
      [404, 500].includes(status) &&
      some(managedResponsesErrorUrls, (url) => includes(requestURL, url)) &&
      !some(excludedResponsesErrorUrls, (url) => includes(requestURL, url))
    ) {
      history.replace(`${AppRoutes.ErrorPage}`);
    } else if (response?.data?.error instanceof Object) {
      errMsg = response?.data?.error?.message ?? errMsg;
    } else if (response?.data?.error) {
      errMsg = response?.data?.error;
    } else if (response?.data?.message) {
      // errMsg = response?.data?.message; // original: return directly the message
      errMsg = response?.data; // return entire BE error
    }
  } else if (request) {
    status = request?.status ?? 400;
    errMsg = 'Unknow client error';
  } else if (!!message) {
    // Something happened in setting up the request that triggered an Error
    errMsg = message;
  }

  // if (status === 401 && response.data.message.search(regex)) {
  //   localStorage.setItem(
  //     'interceptorError',
  //     JSON.stringify({ message: response?.data?.message ?? 'Generic', type: 'error' }),
  //   );
  // }

  return Promise.reject({
    status,
    message: errMsg,
  });
}

import i18n from '@apps/pu/i18n';
import { saveAuthUserData } from '@apps/pu/redux/auth/actions';
import { store } from '@apps/pu/redux/store';
import { clearAuthTokens, getAccessToken, storeAuthTokens } from '@common/auth/utils/token';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import * as AuthAPI from '../api';
import type { AuthContext } from '../types/authContext';
import { MaintenanceDetail } from '../types/maintenanceDetail';

// Provider hook that creates auth object and handles state
export function useProvideAuthContext(): AuthContext {
  // const [user, setUser] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [isChangePasswordRequired, setIsChangePasswordRequired] = useState<boolean>(false);
  const [changePasswordToken, setChangePasswordToken] = useState<string>('');
  const [changePwdSuccessfull, setChangePwdSuccessfull] = useState<boolean>(false);
  const [activeMaintenance, setActiveMaintenance] = useState<MaintenanceDetail | null>(null);

  const login = useCallback(
    async (username: string, password: string, role: string, nonce: string, rememberMe: boolean) => {
      // Call login API
      await AuthAPI.login({
        username,
        password,
        role,
        nonce,
      })
        .then((res) => {
          const { data } = res;

          // accessToken: """
          // firstApprovalReviewed: false
          // partnerStatus: ""
          // refreshToken: ""
          // userLegalFlags: {
          // privacy_endusers: ""
          // privacy_partners: ""
          // tos_endusers: ""
          // tos_partners: ""
          // }
          // userStatus: ""

          if (!!data?.accessToken) {
            const decodeAccessToken: { userId?: number } = jwtDecode(data.accessToken);

            storeAuthTokens(data.accessToken, data.refreshToken, rememberMe);
            setIsAuthenticated(true);

            if (data?.partnerStatus) {
              localStorage.setItem('partner_status', data.partnerStatus);
            }

            if (data?.userStatus) {
              localStorage.setItem('user_status', data.userStatus);
            }

            // if (data?.firstApprovalReviewed) {
            //   localStorage.setItem('firstApprovalReviewed', data.firstApprovalReviewed.toString());
            // }

            // if (data?.userLegalFlags) {
            //   map(data?.userLegalFlags, async (value, key) => {
            //     await localStorage.setItem(`userLegalFlags.${value}`, key);
            //   });
            // }

            const newData = {
              ...data,
              userId: decodeAccessToken?.userId,
            };

            store.dispatch(saveAuthUserData(newData));

            window.location.replace('/pu/home');
          } else if (data?.result === 'changepass') {
            if (data?.token) {
              setChangePasswordToken(data.token);
              setIsChangePasswordRequired(true);
            } else {
              throw i18n.t('web.pu.common.GenericLoginError');
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message === 'aon.pu.web.ongoing_maintenance') {
            window.location.replace('/maintenance');
          } else {
            throw error.response.data.message || i18n.t('web.pu.common.GenericLoginError');
          }
        });
    },
    [],
  );

  const changePassword = useCallback(
    async (username: string, oldPassword: string, newPassword: string, token: string, role: string) => {
      // Call login API
      await AuthAPI.changePassword({
        username,
        oldPassword,
        newPassword,
        token,
        role,
      })
        .then(async (res) => {
          const { status } = res;
          if (status === 'success') {
            await setChangePwdSuccessfull(true);
            setChangePasswordToken('');
            setIsChangePasswordRequired(false);
          }
        })
        .catch((error) => {
          console.log('ERROR');

          throw error.response.data.message || i18n.t('GenericChangePasswordError');
        });
    },
    [],
  );

  const logout = useCallback(async () => {
    const accessToken = getAccessToken();
    const status = await AuthAPI.logout({ accessToken })
      .then((res) => {
        const { status } = res;
        return status;
      })
      .catch((error) => {
        throw error.response.data.message || i18n.t('web.pu.common.GenericLoginError');
      });

    if (status === 200) {
      // Clear stored tokens
      clearAuthTokens();
      // setUser({});
      setIsAuthenticated(false);
      return true;
    }
    return false;
  }, []);

  const checkMaintenance = useCallback(async () => {
    await AuthAPI.checkMaintenance()
      .then(async (res) => {
        const { status, data } = res;
        if (status === 'success') {
          await setActiveMaintenance(data.currentMaintenance);
        } else {
          await setActiveMaintenance(null);
        }
      })
      .catch(async (error) => {
        await setActiveMaintenance(null);
        throw error.response.data.message || i18n.t('web.pu.common.GenericLoginError');
      });
  }, []);

  // Load user token from SessionStorage if available
  useEffect(() => {
    const retrieveToken = async () => {
      const existingUserToken = getAccessToken();

      if (!!existingUserToken) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setLoading(false);
    };

    retrieveToken();
  }, []);

  // Return an auth context
  return {
    // user,
    changePwdSuccessfull,
    isChangePasswordRequired,
    changePasswordToken,
    activeMaintenance,
    isAuthenticated,
    isLoading,
    login,
    logout,
    changePassword,
    checkMaintenance,
  };
}

import { compose, branch, renderNothing, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import OneSignal from 'components/shared/OneSignal';
import { logoutRequest } from 'containers/Auth/actions';
import ImpersonateBar from './ImpersonateBar';

const mapStateToProps = null;

const mapDispatchToProps = {
  logout: logoutRequest,
};
export default compose(
  branch(
    ({ isImpersonate, profileData }) => !profileData || !isImpersonate,
    renderNothing,
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    onLogout: ({ logout }) => () => {
      OneSignal.push(() => {
        OneSignal.setSubscription(false);
        OneSignal.deleteTag('user');
        OneSignal.logoutEmail();
      });
      logout();
    },
  }),
)(ImpersonateBar);

import {
  compose,
  withProps,
  withStateHandlers,
  pure,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import flatten from 'lodash/flatten';
import { injectIntl } from 'react-intl';
import { reduxForm, change } from 'redux-form/immutable';
import { selectFilters } from '../../Hocs/Pagination/selectors';
// TODO: importate da partners
import {
  applyFilterAction,
  removeFilterAction,
} from '../../../../app/components/shared/Hocs/Pagination/actions';
// TODO: *********************
import Header from './Header';

const mapStateToProps = createStructuredSelector({
  filterValues: selectFilters(),
});

const mapDispatchToProps = {
  setFormValue: change,
  applyFilter: applyFilterAction,
  removeSingleFilter: removeFilterAction,
};

const validate = val => {
  const errors = {};

  const values = val.toJS();

  const filtersArrayErrors = [];
  if (values.filter) {
    values.filter.forEach((filter, filterIndex) => {
      const filterErrors = {};
      if (!filter || !filter.field) {
        filterErrors.field = 'Required';
        filtersArrayErrors[filterIndex] = filterErrors;
      }
      if (!filter || !filter.operator) {
        filterErrors.operator = 'Required';
        filtersArrayErrors[filterIndex] = filterErrors;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!filter || !filter.hasOwnProperty('value')) {
        filterErrors.value = 'Required';
        filtersArrayErrors[filterIndex] = filterErrors;
      }
    });
    if (filtersArrayErrors.length) {
      errors.filter = filtersArrayErrors;
    }
  }
  return errors;
};

export default compose(
  injectIntl,
  pure,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(({ filters, headerInitialValues: { search } = {} }) => {
    let i = 0;
    const newFilters = filters.map(({ inputs, ...rest }) => ({
      inputs: inputs.map(input => {
        const index = i;
        i += 1;
        return { index, ...input };
      }),
      ...rest,
    }));
    const filterInitialValues = flatten(
      newFilters.map(({ inputs }) =>
        inputs.map(({ formValues, index }) => ({ index, ...formValues })),
      ),
    );

    return {
      initialValues: {
        filter: filterInitialValues,
        search,
      },
      filters: newFilters,
    };
  }),
  reduxForm({
    form: 'table-filter',
    validate,
    onSubmit: (v, dispatch, { setFilters }) => {
      const values = v.toJS();
      let filters = [];
      const { search, filter } = values;
      if (search.value) filters.push(search);
      if (filter || [].length)
        filters = [...filters, ...filter.filter(({ value }) => !!value)];
      dispatch(setFilters({ filters }));
    },
  }),
  withStateHandlers(
    {
      selectedField: null,
    },
    {
      selectField: (_, { filters }) => index => ({
        selectedField: filters[index] || null,
      }),
    },
  ),
  withHandlers({
    onDismiss: ({
      filterValues,
      formValues,
      selectedField,
      selectField,
      setFormValue,
    }) => () => {
      (selectedField.inputs || []).forEach(input => {
        const currentFormValue = formValues.filter[input.index].value;
        const originalFilterValue = filterValues.find(
          el =>
            el.get('field') === input.formValues.field &&
            el.get('operator') === input.formValues.operator,
        );
        const originalValue = originalFilterValue
          ? originalFilterValue.get('value')
          : null;

        if (currentFormValue !== originalValue) {
          setFormValue(
            'table-filter',
            `filter[${input.index}].value`,
            originalValue,
          );
        }
      });

      selectField();
    },
  }),
)(Header);

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@components';
import { ColoredSelect, SelectedValue } from './styles';

// eslint-disable-next-line react/prop-types
function CustomOption({ data: { label, color }, innerProps }) {
  return (
    <div {...innerProps}>
      <ColoredSelect color={color}>{label}</ColoredSelect>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function SingleValue({ data: { label, color }, innerProps }) {
  return (
    <div {...innerProps}>
      <SelectedValue color={color}>{label}</SelectedValue>
    </div>
  );
}

function SelectStudio({ locations, ...props }) {
  const options = (locations || []).map(
    ({ id, name, address, color, isVideomeeting }) => ({
      label: isVideomeeting ? name.trim() : `${name} ${address}`.trim(),
      value: id,
      color,
    }),
  );
  return (
    <Select
      {...props}
      options={options}
      singleValue={SingleValue}
      customOption={CustomOption}
    />
  );
}

SelectStudio.propTypes = {
  locations: PropTypes.shape({}),
};

export default SelectStudio;

export const AppRoutes = {
  AddVenue: '/venue/add',
  DoctorDetails: '/doctor',
  EditVenue: '/venue',
  GoodmeAgenda: '/goodme/agenda',
  GoodmeNotifications: '/notifications',
  GoodmePatients: '/goodme/patients',
  Profile: '/profile',
  Home: '/home',
  Invoices: '/invoices',
  InvoicesDetails: '/invoices',
  ManageAccesses: '/accesses/manage',
  ManageLists: '/lists/manage',
  ManagePricelists: '/pricelists/manage',
  ManageStructures: '/structures/manage',
  ManageVenues: '/venues/manage',
  Notifications: '/notifications',
  Practices: '/practices',
  PracticeDetails: '/practice',
  AddPractice: '/practice/add',
  EditPractice: '/practice',
  PricelistDetails: '/approved/pricelist',
  PricelistSandboxDetails: '/pricelist/sandbox',
  Settings: '/settings',
  Support: '/support',
  VenueDetails: '/venue',
  DoctorDetailsVenue: '/venue/doctor',
  DoctorDetailsPricelist: '/pricelists/doctor',
  TicketDetails: '/ticket',
  AccessesDetails: '/accesses',
  EditAccessesDetails: '/accesses',
  CreateAccessPage: '/accesses/add',
  AddDoctorPage: '/doctor/add',
  AddPricelistPage: '/pricelist/add',
  EditPricelistPage: '/edit/pricelist',
  EditApprovedPricelist: '/approved/pricelist',
  ErrorPage: '/error',
  Maintenance: '/maintenance',
  GenericErrorPage: '/generic-error',
};

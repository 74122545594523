import React from 'react';
import PropTypes from 'prop-types';

const MyPendingRequest = ({ colored, ...props }) => (
  <svg viewBox="0 0 172.3 172.3" width="1em" height="1em" {...props}>
    <title>MENU-Pending Requests</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          strokeMiterlimit="10"
          strokeWidth="4px"
          fill="none"
          d="M136.33,101.45v45.9m-21.93-45.9v45.9m10,23a45.9,45.9,0,1,0-45.9-45.9A45.9,45.9,0,0,0,124.4,170.3Zm0,0H2V2H170.3V124.4M2,124.4H78.5M2,32.6H170.3M2,78.5H124.4m-7.65-45.9V78.5M55.55,32.6V170.3"
        />
      </g>
    </g>
  </svg>
);

MyPendingRequest.propTypes = {
  colored: PropTypes.bool,
};

MyPendingRequest.defaultProps = {
  colored: null,
};

export default MyPendingRequest;

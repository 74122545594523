import React from 'react';

const LogoInstagram = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 24" {...props}>
    <path d="M11.481 0C8.364 0 7.972.014 6.747.07 5.525.124 4.69.32 3.961.605a5.617 5.617 0 00-2.034 1.327A5.663 5.663 0 00.603 3.976C.32 4.708.126 5.546.07 6.773.014 8.003 0 8.393 0 11.524c0 3.13.014 3.522.07 4.752.056 1.227.25 2.065.533 2.797a5.663 5.663 0 001.324 2.042 5.616 5.616 0 002.034 1.328c.729.285 1.564.479 2.786.536 1.225.055 1.617.07 4.734.07 3.119 0 3.509-.015 4.734-.07 1.222-.057 2.057-.251 2.786-.536a5.634 5.634 0 002.035-1.328 5.659 5.659 0 001.323-2.042c.284-.732.479-1.57.534-2.797.056-1.23.07-1.622.07-4.752s-.014-3.522-.07-4.751c-.055-1.227-.25-2.065-.534-2.797a5.659 5.659 0 00-1.323-2.043A5.634 5.634 0 0019.001.606C18.272.32 17.437.125 16.215.069 14.99.014 14.6 0 11.48 0m0 2.076c3.066 0 3.43.013 4.64.069 1.118.05 1.727.238 2.132.395.535.209.918.458 1.32.861.402.406.65.789.858 1.326.157.406.344 1.017.395 2.14.055 1.215.067 1.58.067 4.657 0 3.078-.012 3.443-.067 4.657-.051 1.123-.238 1.734-.395 2.14-.207.537-.456.92-.857 1.326a3.568 3.568 0 01-1.322.861c-.404.157-1.013.345-2.132.396-1.21.055-1.573.068-4.639.068-3.066 0-3.43-.013-4.639-.068-1.12-.051-1.728-.239-2.132-.396a3.562 3.562 0 01-1.32-.861 3.58 3.58 0 01-.86-1.326c-.156-.406-.343-1.017-.393-2.14-.056-1.215-.068-1.58-.068-4.657 0-3.078.012-3.442.068-4.656.05-1.124.237-1.735.394-2.14A3.58 3.58 0 013.39 3.4a3.561 3.561 0 011.32-.86c.404-.158 1.013-.345 2.132-.396 1.21-.056 1.574-.069 4.64-.069" />
    <path d="M11.481 15.365a3.833 3.833 0 01-3.826-3.84 3.833 3.833 0 013.826-3.842 3.833 3.833 0 013.827 3.841 3.833 3.833 0 01-3.827 3.841m0-9.758c-3.256 0-5.895 2.648-5.895 5.917 0 3.27 2.64 5.917 5.895 5.917 3.257 0 5.895-2.648 5.895-5.917 0-3.27-2.638-5.917-5.895-5.917M18.988 5.373c0 .763-.618 1.383-1.38 1.383a1.38 1.38 0 01-1.376-1.383 1.38 1.38 0 011.377-1.384c.761 0 1.379.62 1.379 1.384" />
  </svg>
);

export default LogoInstagram;

import ExtApp from '@ExtApp';
// @ts-ignore
import { theme } from '@apps/goodme/app/app.js';
import PULoader from '@apps/pu/components/PULoader';
import i18n from '@apps/pu/i18n';
import customTheme from '@apps/pu/theme/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from '@common/auth/components/AuthProvider';
import { Global, css } from '@emotion/react';
import 'focus-visible/dist/focus-visible';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

// @TODO: if in the cookie we have impoersonate_token set it to setTokens()

ReactDOM.render(
  // <React.StrictMode>
  <ChakraProvider theme={customTheme}>
    <Suspense fallback={<PULoader />}>
      <I18nextProvider i18n={i18n}>
        <Global styles={GlobalStyles} />
        <ThemeProvider theme={theme}>
          <div style={{ height: '100%', width: '100%' }}>
            <AuthProvider>
              <ExtApp />
            </AuthProvider>
          </div>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  </ChakraProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import PropTypes from 'prop-types';

const Communications = ({ showAlarm, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 -2 23 23" {...props}>
    <path d="M22.2 17.67h-2.35V8.45a8.45 8.45 0 00-16.9 0v9.22H.6a.6.6 0 000 1.2h7.25v.38a3.55 3.55 0 107.1 0v-.38h7.25a.6.6 0 100-1.2zm-8.45 1.58a2.35 2.35 0 01-4.7 0v-.38h4.7zm4.9-1.58H4.15V8.45a7.25 7.25 0 0114.5 0z" />
    {showAlarm && (
      <circle
        cx={18.31}
        cy={18.47}
        r={3.75}
        fill="#FFB301"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    )}
  </svg>
);

Communications.propTypes = {
  showAlarm: PropTypes.bool,
};

Communications.defaultProps = {
  showAlarm: false,
};

export default Communications;

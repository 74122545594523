import { createReducer } from '@reduxjs/toolkit';
import {
  getInvoicesFailed,
  getInvoicesRequest,
  getInvoicesSuccess,
  checkInvoicesRequest,
  checkInvoicesSuccess,
  checkInvoicesFailed,
  resetSearchInvoicesListFilters,
  setInvoicesListInitialPage,
  setSearchInvoicesListFilters,
} from './actions';
import { ResultsEntity, CheckInvoicesSuccessPayload } from './types';

export type InvoicesListIS = {
  loading: boolean;
  invoicesList: ResultsEntity[] | [];
  totalCount: number;
  currentPage: number;
  prevPage?: null;
  nextPage?: null;
  invoicesListFilter: {
    textFilter: string;
  };
  invoicesErrors: CheckInvoicesSuccessPayload | null;
};

const initialState: InvoicesListIS = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  invoicesList: [],
  invoicesListFilter: {
    textFilter: '',
  },
  invoicesErrors: null,
};

export const invoicesReducers = createReducer(initialState, {
  [getInvoicesRequest.type]: (state) => {
    state.loading = true;
  },
  [getInvoicesSuccess.type]: (state, action) => {
    state.loading = false;
    state.invoicesList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getInvoicesFailed.type]: (state) => {
    state.loading = false;
  },
  [checkInvoicesRequest.type]: (state) => {
    state.loading = true;
  },
  [checkInvoicesSuccess.type]: (state, action) => {
    state.loading = false;
    state.invoicesErrors = action.payload;
  },
  [checkInvoicesFailed.type]: (state) => {
    state.loading = false;
  },
  [setSearchInvoicesListFilters.type]: (state, action) => {
    state.invoicesListFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchInvoicesListFilters.type]: (state) => {
    state.invoicesListFilter.textFilter = '';
  },
  [setInvoicesListInitialPage.type]: (state) => {
    state.currentPage = 1;
  },
});

import React from 'react';

const CloseCircle = props => (
  <svg viewBox="3 3 24 24" {...props} width="1em" height="1em">
    <g fill="#333">
      <path d="M19.91 10.09a.51.51 0 0 0-.71 0l-4.2 4.2-4.2-4.2a.5.5 0 0 0-.71.71l4.2 4.2-4.2 4.2a.5.5 0 0 0 .71.71l4.2-4.2 4.2 4.2a.5.5 0 0 0 .71-.71l-4.2-4.2 4.2-4.2a.51.51 0 0 0 0-.71z" />
      <path d="M15 3.5A11.5 11.5 0 1 0 26.5 15 11.5 11.5 0 0 0 15 3.5zm7.42 18.92A10.49 10.49 0 1 1 7.58 7.58a10.49 10.49 0 1 1 14.84 14.84z" />
    </g>
  </svg>
);

export default CloseCircle;

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import PracticeIcon from '../../icons/practice';
import LoadingSpinner from './LoadingSpinner';
import { FileName, FilePreviewUrl, ImagePreview } from './styles';
import { isImage } from './utils';

function RenderPreview({
  alt,
  url,
  type,
  filename,
  firstName,
  lastName,
  size,
  forcePracticeIcon,
}) {
  if ((firstName || lastName) && !forcePracticeIcon) {
    return (
      <Avatar
        firstName={firstName}
        lastName={lastName}
        size={size}
        url={filename}
        type={type}
      />
    );
  }

  if (!filename && !url && !forcePracticeIcon) {
    return <LoadingSpinner />;
  }

  if (isImage(filename) && !forcePracticeIcon) {
    return <ImagePreview alt={alt} filename={url || filename} />;
  }

  return (
    <div style={{ padding: '30px 0' }}>
      <PracticeIcon width={40} height={40} color="#fcb731" />
      <FileName>
        {filename && filename.slice(filename.lastIndexOf('/') + 1)}
      </FileName>
    </div>
  );
}

RenderPreview.propTypes = {
  filename: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  forcePracticeIcon: PropTypes.bool,
};

RenderPreview.defaultTypes = {
  filename: null,
  firstName: null,
  lastName: null,
  type: null,
  forcePracticeIcon: false,
};

function MyFilePreview({
  alt,
  filename,
  firstName,
  lastName,
  size,
  url,
  type,
  onlyview,
  disableClick,
  forcePracticeIcon,
}) {
  if (!url) {
    return (
      <RenderPreview
        alt={alt}
        filename={filename}
        firstName={firstName}
        lastName={lastName}
        size={size}
        type={type}
        forcePracticeIcon={forcePracticeIcon}
      />
    );
  }

  return (
    <FilePreviewUrl onlyview={onlyview} disableClick={disableClick}>
      <a href={url} target="_blank" download rel="noreferrer">
        <RenderPreview alt={alt} url={url} filename={filename} />
      </a>
    </FilePreviewUrl>
  );
}

MyFilePreview.propTypes = {
  filename: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  alt: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  onlyview: PropTypes.bool,
  disableClick: PropTypes.bool,
  forcePracticeIcon: PropTypes.bool,
};

MyFilePreview.defaultTypes = {
  filename: null,
  firstName: null,
  lastName: null,
  size: null,
  alt: '',
  url: '',
  onlyview: undefined,
  disableClick: undefined,
  type: null,
  forcePracticeIcon: false,
};

export default MyFilePreview;

import { createAction } from '@reduxjs/toolkit';
import { UpdateManageStructureRequestPayload, GetManageStructureSuccessPayload } from './types';

export const getManageStructureRequest = createAction('ACTION/GET_MANAGE_STRUCTURE_REQUEST');
export const getManageStructureSuccess = createAction<GetManageStructureSuccessPayload>(
  'ACTION/GET_MANAGE_STRUCTURE_SUCCESS',
);
export const getManageStructureFailed = createAction('ACTION/GET_MANAGE_STRUCTURE_FAILED');

export const updateManageStructureRequest = createAction<UpdateManageStructureRequestPayload>(
  'ACTION/UPDATE_MANAGE_STRUCTURE_REQUEST',
);
export const updateManageStructureSuccess = createAction('ACTION/UPDATE_MANAGE_STRUCTURE_SUCCESS');
export const updateManageStructureFailed = createAction('ACTION/UPDATE_MANAGE_STRUCTURE_FAILED');

import styled, { css } from 'styled-components';
import _button from '../../../Button';

export const Button = styled(_button)`
  width: auto;
  font-weight: 600;
  svg {
    font-size: 2.5rem;
  }
  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      color: ${theme.primary};
    `};
  ${({ isApplied, theme }) =>
    isApplied &&
    css`
      border: 1px solid ${theme.primary};
    `};
  &:disabled {
    background-color: white !important;
    color: #333 !important;
    opacity: 0.5;
  }
`;

import moment from 'moment';
import reduce from 'lodash/reduce';

const DAY_FORMAT = 'DD';
const DAY_LONG_FORMAT = 'dddd';
const MONTH_LONG_FORMAT = 'MMMM';
const YEAR_FORMAT = 'YYYY';
const FULL_DATE_FORMAT = 'DD/MM/YYYY';
const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const TIME_FORMAT = 'HH:mm';
const TIME_EN_FORMAT = 'h:mm a';
const TIME_LONG_FORMAT = 'HH:mm:ss';

const format = (value, FORMAT) => moment(value).format(FORMAT);

export const showTimeShort = data => format(data, TIME_FORMAT);
export const showTimeLong = data => format(data, TIME_LONG_FORMAT);
export const showTimeEn = data =>
  moment(data, TIME_FORMAT).format(TIME_EN_FORMAT);

export const showDate = data => format(data, FULL_DATE_FORMAT);
export const showDateTimeEn = data =>
  moment(data, FULL_DATE_TIME_FORMAT).toISOString();

export const showDateTimeLongEn = data => `
  ${format(data, DAY_LONG_FORMAT)}, ${format(data, MONTH_LONG_FORMAT)}
  ${format(data, DAY_FORMAT)} ${format(data, YEAR_FORMAT)}
`;

export const formatDateRange = dateRange => {
  const arrayString = dateRange.map(({ from, to }) => {
    const toMoment = moment(to);

    if (toMoment.isSame(from)) return showDate(to);

    if (!toMoment.isSame(from, 'year'))
      return `${showDate(from)} - ${showDate(to)}`;

    if (!toMoment.isSame(from, 'month'))
      return `${format(from, 'DD/MM')} - ${showDate(to)}`;

    return `${format(from, DAY_FORMAT)}-${showDate(to)}`;
  });
  return reduce(arrayString, (sum, n) => `${sum}, ${n}`);
};

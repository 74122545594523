import React from 'react';

const EyeOutline = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22 13" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      d="M0.0814514 6.15645C0.254705 5.90435 4.41828 0 11 0C16.6477 0 21.6803 5.87045 21.8921 6.1207C22.036 6.2912 22.036 6.54146 21.8921 6.71286C21.6803 6.96312 16.6477 12.8336 11 12.8336C5.35235 12.8336 0.319805 6.96312 0.108051 6.71286C-0.0248966 6.55521 -0.0368004 6.32695 0.0814514 6.15645ZM11 11.9169C15.5275 11.9169 19.8323 7.59932 20.9259 6.4168C19.8341 5.23338 15.5339 0.916711 11 0.916711C5.70255 0.916711 1.96339 5.22788 1.05037 6.39205C2.10458 7.53697 6.43406 11.9169 11 11.9169Z"
      fill="#494949"
    />
    <path
      d="M11.0003 2.75C13.0225 2.75 14.667 4.39453 14.667 6.41671C14.667 8.4389 13.0225 10.0834 11.0003 10.0834C8.97809 10.0834 7.33356 8.4389 7.33356 6.41671C7.33356 4.39453 8.97809 2.75 11.0003 2.75ZM11.0003 9.1668C12.5165 9.1668 13.7503 7.93294 13.7503 6.41676C13.7503 4.90058 12.5165 3.66671 11.0003 3.66671C9.4841 3.66671 8.25023 4.90058 8.25023 6.41676C8.25023 7.93294 9.4841 9.1668 11.0003 9.1668Z"
      fill="#494949"
    />
  </svg>
);

export default EyeOutline;

import React from 'react';
import PropTypes from 'prop-types';

const Agenda = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
    <title>Agenda</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.933 2.168h6.134V.324c0-.175.148-.318.331-.318.183 0 .331.143.331.318v1.844h1.935c.182 0 .33.143.33.318v9.19a.324.324 0 01-.331.318H.337a.325.325 0 01-.331-.318v-9.19c0-.175.148-.318.331-.318h1.934V.324c0-.176.148-.318.331-.318.183 0 .331.142.331.318v1.844zm8.4.637H.667v2.066h10.666V2.805zm0 2.703H.667v5.849h10.666V5.508z"
        fill="currentColor"
      />
    </g>
  </svg>
);

Agenda.propTypes = {
  active: PropTypes.bool,
};

export default Agenda;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrapper } from './styles';
import messages from './messages';

function LabelBadge({ margin }) {
  return (
    <Wrapper margin={margin}>
      <FormattedMessage {...messages.label} />
    </Wrapper>
  );
}

LabelBadge.propTypes = {
  margin: PropTypes.string,
};

LabelBadge.defaultProps = {
  margin: null,
};

export default LabelBadge;

import styled, { css } from 'styled-components';
import _slider, { Range as _range } from 'rc-slider';

const handleStyle = {
  height: '26px',
  width: '26px',
  top: 0,
  left: 0,
  borderWidth: '4px',
};

const fixedDot = ({ theme }) => ({
  /*  height: '26px',
  width: '26px', */
  /* top: '-10px', */
  /* borderWidth: '5px', */
  backgroundColor: "transparent",
  borderColor: "transparent",
});

export const Slider = styled(_slider).attrs({
  handleStyle: ({ theme }) => ({
    ...handleStyle,
    borderColor: theme.cfSecondary,
  }),
   dotStyle: fixedDot, 
  trackStyle: ({ theme }) => ({
    height: '5px',
    backgroundColor: theme.cfSecondary,
  }),
  railStyle: ({ theme }) => ({
    height: '5px',
    backgroundColor: theme.gray400,
  }),
})`
  .rc-slider-dot {
    /* left: -9px !important; */
    &:not(:first-child) {
      display: none;
    }
  }
`;

export const Range = styled(_range).attrs({
  handleStyle: ({ theme }) => [
    { ...handleStyle, borderColor: theme.cfSecondary },
    { ...handleStyle, borderColor: theme.cfSecondary },
  ],
  dotStyle: {
    display: 'none',
  },
  trackStyle: ({ theme }) => [
    {
      height: '5px',
      backgroundColor: theme.cfSecondary,
    },
  ],
  railStyle: ({ theme }) => ({
    height: '5px',
    backgroundColor: theme.gray400,
  }),
})``;

export const Wrapper = styled.div`
  max-width: ${({ maxWidth }) =>
    maxWidth ? `calc(${maxWidth} - 26px)` : `calc(100% - 26px)`};
  margin: 0 auto;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `};
  /* resets */
  .rc-slider-handle:focus,
  .rc-slider-handle:active {
    box-shadow: none;
  }
  .rc-slider-mark-text,
  .rc-slider-mark-text-active {
    top: 15px;
  }
  .rc-slider-mark-text,
  .rc-slider-mark-text-active {
    white-space: nowrap;
  }
`;

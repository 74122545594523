import React from 'react';

const Success = props => (
  <svg width="1rem" height="1rem" viewBox="0 0 50 50" {...props}>
    <path
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M38 15L22 33l-10-8"
    />
  </svg>
);

export default Success;

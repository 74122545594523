import { createAction } from 'redux-actions-helpers';

export const getVideomeetingBookingRequest = createAction(
  'APP/VIDEOMEETING/BOOKING_REQUEST',
  id => ({ id }),
);

export const getVideomeetingBookingResponse = createAction(
  'APP/VIDEOMEETING/BOOKING_RESPONSE',
  data => ({ data }),
);

export const resetVideomeetingData = createAction(
  'APP/VIDEOMEETING/RESET_DATA',
);

export const saveVideomeetingNotesRequest = createAction(
  'APP/VIDEOMEETING/SAVE_NOTES',
  ({ id, notes }) => ({ id, notes }),
);

export const getVideomeetingRequest = createAction(
  'APP/VIDEOMEETING/GET_VIDEOMEETING_REQUEST',
  id => ({ id }),
);

export const getVideomeetingRsponse = createAction(
  'APP/VIDEOMEETING/GET_VIDEOMEETING_RESPONSE',
  data => data,
);

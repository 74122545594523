import React from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import Modal from './Modal';

const Prompt = ({ when, message }) => (
  <NavigationPrompt when={when}>
    {({ onCancel, onConfirm }) => (
      <Modal
        isOpen={when}
        message={message}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )}
  </NavigationPrompt>
);

Prompt.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string,
};

Prompt.defaultProps = {
  when: false,
  message: null,
};

export default Prompt;

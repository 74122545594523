const {
  API_BASE_PATH: hostname,
  API_HEALTHBOX_BASE_PATH: hostnameHealthBox,
  FE_PARTNERS_BASE_PATH: basename,
  FACEBOOK_APP_ID_ENV: facebookClientId,
  GOOGLE_APP_ID_ENV: googleClientId,
  ANALYTICS_ID_ENV: googleAnalyticsId,
  SENTRY_DNS: sentryDns,
  GMAPS_API: gmapsId,
  ONE_SIGNAL_PUSH_DOMAIN: oneSignalPushDomain,
  ONE_SIGNAL_APP_ID: oneSignalAppId,
  ONE_SIGNAL_SAFARI_APP_ID: oneSignalSafariAppId,
  DEPLOYED_VERSION: deployedVersion,
  AGENDA_MINUTES_TIMESLOT: agendaMinutesTimeslot,
  env,
  NODE_ENV: keychainKey,
  ZENDESK_ORGANIZATION_NAME: zendeskOrgName,
  MASTER_LANGUAGE: masterLanguage,
  HOTJAR_ID: hotjarId,
  ZENDESK_USER_ROLE_FIELD_ID: zendeskUserRoleFieldId,
  PLATFORM_READY: platformReady,
  HIDE_FOOTER_HEADER: hideFooterHeaderString,
  CONTACT_US_MAIL: contactUsMail,
  TEMPORARILY_DISABLED: temporarilyDisabled,
  REACT_APP_ANALYTICS: reactAppAnalytics,
} = window.MyCareEnvironment;

const hideFooterHeader = Boolean(hideFooterHeaderString);

export {
  env,
  hostname,
  hostnameHealthBox,
  basename,
  facebookClientId,
  googleClientId,
  googleAnalyticsId,
  sentryDns,
  gmapsId,
  oneSignalPushDomain,
  oneSignalAppId,
  oneSignalSafariAppId,
  deployedVersion,
  agendaMinutesTimeslot,
  keychainKey,
  zendeskOrgName,
  masterLanguage,
  hotjarId,
  zendeskUserRoleFieldId,
  platformReady,
  hideFooterHeader,
  contactUsMail,
  temporarilyDisabled,
  reactAppAnalytics,
};

import React from 'react';
import PropTypes from 'prop-types';

const Agenda = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 40 39" {...props}>
    <title />
    <g fill="none" fillRule="evenodd">
      <path
        d="M8.71 6.643h16.58V1.661c0-.475.4-.861.894-.861.493 0 .893.386.893.861v4.982h5.23c.493 0 .893.386.893.861V32.34c0 .475-.4.861-.894.861H1.694C1.2 33.2.8 32.814.8 32.339V7.504c0-.475.4-.86.894-.86h5.229V1.66c0-.475.4-.861.893-.861.494 0 .894.386.894.861v4.982zm22.703 1.722H2.587v5.583h28.826V8.365zm0 7.305H2.587v15.808h28.826V15.67z"
        fill="currentColor"
      />
      {active && (
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#FF4040"
          cx={30.923}
          cy={8.923}
          r={9.923}
        />
      )}
    </g>
  </svg>
);

Agenda.propTypes = {
  active: PropTypes.bool,
};

export default Agenda;

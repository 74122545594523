import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectStepDomain = () => state => state.get('steps');

export const selectInvoiceCities = () =>
  createSelector(selectStepDomain(), selectStep =>
    selectStep.get('invoiceCities'),
  );

export const selectLoadingSteps = () =>
  createSelector(selectStepDomain(), selectStep => selectStep.get('loading'));

export const selectCurrentStep = () =>
  createSelector(selectStepDomain(), selectStep =>
    selectStep.get('currentStep'),
  );

export const selectCompletedStep = () =>
  createSelector(selectStepDomain(), selectStep =>
    selectStep.get('completedStep'),
  );

export const selectWizardIsOpen = () =>
  createSelector(selectStepDomain(), selectStep =>
    selectStep.get('wizardIsOpen'),
  );

export const selectPraticeData = () =>
  createSelector(selectStepDomain(), selectStep => selectStep.get('data'));

export const selectChangedSections = () =>
  createSelector(
    selectStepDomain(),
    selectStep =>
      selectStep.get('changedSections') &&
      selectStep.get('changedSections').toJS(),
  );

export const selectUsedInvoiceAddresses = () =>
  createSelector(selectPraticeData(), data =>
    get(data, 'locations.locations', []).map(
      location => location.invoiceAddressId,
    ),
  );

export const selectPraticeStatus = () =>
  createSelector(selectStepDomain(), currentPracticeData =>
    currentPracticeData.get('status'),
  );

export const selectPracticeReason = () =>
  createSelector(selectStepDomain(), currentPracticeData =>
    currentPracticeData.get('reason'),
  );
export const selectContractNeededDuringFill = () =>
  createSelector(selectStepDomain(), currentPracticeData =>
    currentPracticeData.get('contractNeededDuringFill'),
  );
export const selectDataLostError = () =>
  createSelector(
    selectStepDomain(),
    selectStep => selectStep && selectStep.get('dataLostError'),
  );

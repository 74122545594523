import { Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { history } from '../../../utils/history';
import { opDetailsAccessesReducers, opsAccessesReducers } from './accesses/reducers';
import authReducer from './auth/reducers';
import { doctorsReducers } from './doctors/reducers';
import { practicesAttachmentsReducers, practicesInvoicesReducers } from './documents/reducers';
import { invoicesReducers } from './invoices/reducers';
import messageHandler from './messageHandler/reducers';
import { createPraticeReducers, openPraticeReducers, practicesReducers } from './practices/reducers';
import {
  pricelistDetailsReducer,
  pricelistDetailsSpecialtyListReducer,
  pricelistReducers,
  pricelistSandboxDetailsReducer,
  pricelistSandboxReducer,
} from './pricelists/reducers';
import { allLocationSpecialties } from './specialties/reducers';
import { manageStructureReducer } from './structure/reducers';
import { supportReducers } from './support/reducers';
import {
  addNewVenueReducer,
  allVenuesReducer,
  invoiceAddressesSingleVenueReducer,
  venueAdditionalServicesReducer,
  venueAffiliationsReducer,
  venueCurrentPracticeReducer,
  venueDetailsReducer,
  venuePartnerLocationReducer,
  venueTypesReducer,
} from './venues/reducers';

const reducers = {
  auth: authReducer,
  doctors: doctorsReducers,
  invoiceAddresses: invoiceAddressesSingleVenueReducer,
  invoices: invoicesReducers,
  locationSpecialties: allLocationSpecialties,
  // :TODO: TO BE REVIEWED AND REFACTORED ASAP!!!
  manageStructure: manageStructureReducer,
  messages: messageHandler,
  newVenue: addNewVenueReducer,
  practiceAttachments: practicesAttachmentsReducers,
  practiceInvoices: practicesInvoicesReducers,
  practices: practicesReducers,
  openPractice: openPraticeReducers,
  createPractice: createPraticeReducers,
  pricelistDetails: pricelistDetailsReducer,
  pricelistDetailsSpecialty: pricelistDetailsSpecialtyListReducer,
  pricelists: pricelistReducers,
  venueAdditionalServices: venueAdditionalServicesReducer,
  venueAffiliations: venueAffiliationsReducer,
  venueCurrentPractice: venueCurrentPracticeReducer,
  venueDetails: venueDetailsReducer,
  venueTypes: venueTypesReducer,
  venues: allVenuesReducer,
  support: supportReducers,
  accesses: opsAccessesReducers,
  opDetailsAccesses: opDetailsAccessesReducers,
  pricelistSandbox: pricelistSandboxReducer,
  pricelistSandboxDetails: pricelistSandboxDetailsReducer,
  venuesLocation: venuePartnerLocationReducer,
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  blacklist: ['router'],
};

// Reducers
const allReducers = {
  ...reducers,
  router: connectRouter(history) as Reducer, // to fix CombineState and type incompatibility
};

const persistedRootReducer = persistCombineReducers(persistConfig, allReducers);

export type RootState = ReturnType<typeof persistedRootReducer>;

export default persistedRootReducer;

import styled from 'styled-components';
import {
  Modal as _modal,
  ModalHeader as _modalHeader,
  ModalFooter as _modalFooter,
} from 'reactstrap';

export const ModalContainer = styled(_modal).attrs({
  centered: true,
})`
  border-radius: 5px;

  .modal-content {
    border-radius: 5px;
  }
`;

export const ModalHeader = styled(_modalHeader)`
  justify-content: center;
  border: none;
  padding: 60px 30px 30px;

  .modal-title {
    font-weight: 600;
  }
`;

export const ModalFooter = styled(_modalFooter)`
  border: none;
  display: block;
  flex-direction: column;
  padding: 20px 100px;

  button + button {
    margin-top: 20px;
  }
`;

export const InputStyles = {
  parts: ['addon', 'field', 'element'],
  baseStyle: {
    field: {
      // border: '1px solid #E2E2E2',
      borderRadius: '99px',
      fontSize: 'md',
      fontWeight: 400,
      /* height: '54px', */
      _focus: {
        borderColor: '#cbd5e0',
        boxShadow: '0 0 2px 1px rgba(203, 213, 224, 0.5)',
      },
      _invalid: {
        border: '1px solid #C20D00',
        boxShadow: '0 0 2px 1px rgba(194, 13, 0, 0.5)',
      },
    },
  },
  sizes: {
    lg: {
      height: 54,
    },
    md: {
      height: 40,
    },
  },
  variants: {
    rounded: {
      field: {
        border: '1px solid #E2E2E2',
        borderRadius: '99px',
        color: '#000',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        _hover: {
          border: '1px solid #cbd5e0',
        },
        _focus: {
          border: '1px solid #cbd5e0',
          boxShadow: '0 0 2px 1px rgba(203, 213, 224, 0.5)',
        },
        _placeholder: {
          color: '#6f6f6f',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'rounded',
  },
};

export const externalInputStyle = {
  generalStyle: {
    border: '1px solid #E2E2E2',
    borderRadius: '99px',
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '1rem',
    height: '40px',
    padding: '0 3.2rem',
    width: 'inherit',
  },
  generalStyleWithErrors: {
    border: '1px solid #C20D00',
    borderRadius: '99px',
    boxShadow: '0 0 2px 1px rgba(194, 13, 0, 0.5)',
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '1rem',
    height: '40px',
    padding: '0 3.2rem',
    width: 'inherit',
  },
};

export const selectStyles = (hasErrors) => ({
  input: (provided) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    height: '40px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'none',
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: '40px',
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    border: hasErrors ? '1px solid #C20D00' : '1px solid #E2E2E2',
    borderRadius: 99,
    boxShadow: hasErrors ? '0 0 2px 1px rgba(194, 13, 0, 0.5)' : 'none',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'white',
    paddingLeft: '1.5rem',
    '&:hover': {
      border: '1px solid #cbd5e0',
      boxShadow: 'none',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#FCB731',
    color: '#fff',
    flex: '0 0 auto',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
    fontWeight: 600,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#FFFFFF',
    '&:hover': {
      color: '#000',
      backgroundColor: '#fed37e',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#FCF1D9' : '#FFFFFF',
    color: '#000',
    '&:hover': {
      backgroundColor: '#fed37e',
    },
    opacity: state.isDisabled ? 0.3 : 1,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: '#000',
    fontSize: 'md',
    fontWeight: 600,
    paddingLeft: 0,
    textTransform: 'none',
  }),
  group: (provided) => ({
    ...provided,
    paddingLeft: '15px',
  }),
});

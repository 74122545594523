import React from 'react';

const Info = props => (
  <svg viewBox="0 0 100 100" width="1.5em" height="1.5em" {...props}>
    <path
      d="M50 7C26.3 7 7 26.3 7 50s19.3 43 43 43 43-19.3 43-43S73.7 7 50 7zm0 80c-20.4 0-37-16.6-37-37s16.6-37 37-37 37 16.6 37 37-16.6 37-37 37z"
      fill="currentColor"
    />
    <path
      d="M50 41c-1.7 0-3 1.3-3 3v23c0 1.7 1.3 3 3 3s3-1.3 3-3V44c0-1.7-1.3-3-3-3zM50 30c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"
      fill="currentColor"
    />
  </svg>
);

export default Info;

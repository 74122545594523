import { APIClientPU } from '@common/common/utils';
import { GetPartnerWarningsResponse } from './types';

export async function getPartnerWarnings(): Promise<GetPartnerWarningsResponse[]> {
  try {
    const { data } = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/warnings`,
    );

    return data.data;
  } catch (error) {
    console.error('getPartnerWarnings - Error: ', error);
    throw error;
  }
}

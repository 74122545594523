import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { selectFilters } from '../../../../Hocs/Pagination/selectors';
// TODO: importate da partners
import {
  applyFilterAction,
  removeFilterAction,
  resetFiltersAction,
} from '../../../../../../app/components/shared/Hocs/Pagination/actions';
// TODO: *********************
import ButtonTooltip from './ButtonTooltip';

const mapStateToProps = createStructuredSelector({
  applyFilters: selectFilters(),
});

const mapDispatchToProps = {
  applyFilter: applyFilterAction,
  removeSingleFilter: removeFilterAction,
  removeAllFilters: resetFiltersAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ applyFilters, ignoredFilters, removeAllFilters }) => ({
    // ignore determinate filters from the count, useful for show Cancel all
    // (Es. we have external filters for table, see Requests container on DidMount)
    applyFilters: ignoredFilters
      ? applyFilters.filter((f) => !ignoredFilters.includes(f.get('field')))
      : applyFilters,
    removeAllFilters: () => {
      removeAllFilters({ ignoredFilters });
    },
  })),
  withHandlers({
    doApplyFilter:
      ({ applyFilter, formValues, inputs }) =>
      () => {
        const { filter } = formValues || {};
        const filtersToApply = (filter || []).map((el) => {
          if (el.forceStartOfDay && moment(el.value).isValid())
            return {
              ...el,
              value: moment(el.value).startOf('day').toISOString(),
            };
          else if (el.forceEndOfDay && moment(el.value).isValid())
            return {
              ...el,
              value: moment(el.value).endOf('day').toISOString(),
            };
          else if (
            (el.operator === 'gteq' || el.operator === 'gt') &&
            moment(el.value).isValid() &&
            el.type !== 'number'
          ) {
            return {
              ...el,
              value: moment(el.value).startOf('day').toISOString(),
            };
          } else if (
            (el.operator === 'lteq' || el.operator === 'lt') &&
            moment(el.value).isValid() &&
            el.type !== 'number'
          ) {
            return {
              ...el,
              value: moment(el.value).endOf('day').toISOString(),
            };
          }
          return { ...el };
        });
        const cleanedFiltersToApply = filtersToApply.map((el) => {
          const cleanedEl = { ...el };
          delete cleanedEl.forceStartOfDay;
          delete cleanedEl.forceEndOfDay;
          delete cleanedEl.type;
          return { ...cleanedEl };
        });
        inputs.map(({ index: indexField }) =>
          applyFilter({ filter: cleanedFiltersToApply[indexField] }),
        );
      },
  }),
)(ButtonTooltip);

import React from 'react';
import PropTypes from 'prop-types';

const MyBooking = ({ colored, ...props }) => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>MENU-Booking</title>
    <g fill="currentColor">
      <path d="M24.85 6.15A.47.47 0 0 0 24.5 6h-2a.5.5 0 0 0 0 1H24v3H6V7h1.5a.5.5 0 0 0 0-1h-2a.51.51 0 0 0-.5.5v14.68A3.82 3.82 0 0 0 8.82 25h12.36A3.82 3.82 0 0 0 25 21.18V6.5a.47.47 0 0 0-.15-.35zM21.18 24H8.82A2.82 2.82 0 0 1 6 21.18V11h18v10.18A2.82 2.82 0 0 1 21.18 24z" />
      <path d="M10 8c.55 0 1-.6 1-1.33V5.33C11 4.6 10.55 4 10 4s-1 .6-1 1.33v1.34C9 7.4 9.45 8 10 8zM20 8c.55 0 1-.6 1-1.33V5.33C21 4.6 20.55 4 20 4s-1 .6-1 1.33v1.34C19 7.4 19.45 8 20 8zM12.5 7h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0 0 1z" />
    </g>
    <path
      fill={colored ? '#e11c23' : 'currentColor'}
      d="M13 13h4v3h3v4h-3v3h-4v-3h-3v-4h3v-3z"
    />
  </svg>
);

MyBooking.propTypes = {
  colored: PropTypes.bool,
};

MyBooking.defaultValues = {
  colored: false,
};

export default MyBooking;

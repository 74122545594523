import React from 'react';

const DocDOC = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(77, 169, 226)"
    />
    <path
      fill-rule="evenodd"
      d="M 3.642 13.21 L 3.642 12.39 L 3.892 12.39 L 3.892 10.15 L 3.642 10.15 L 3.642 9.32 L 5.672 9.32 C 6.225 9.32 6.669 9.473 7.002 9.78 C 7.329 10.08 7.492 10.573 7.492 11.26 C 7.492 11.947 7.329 12.443 7.002 12.75 C 6.669 13.057 6.225 13.21 5.672 13.21 L 3.642 13.21 Z M 5.292 12.39 L 5.582 12.39 C 5.695 12.39 5.779 12.36 5.832 12.3 C 5.892 12.24 5.922 12.147 5.922 12.02 L 5.922 10.51 C 5.922 10.263 5.809 10.14 5.582 10.14 L 5.292 10.14 L 5.292 12.39 ZM 9.542 13.28 C 8.929 13.28 8.459 13.12 8.132 12.8 C 7.799 12.48 7.632 11.967 7.632 11.26 C 7.632 10.56 7.799 10.05 8.132 9.73 C 8.459 9.41 8.929 9.25 9.542 9.25 C 10.155 9.25 10.625 9.41 10.952 9.73 C 11.285 10.05 11.452 10.56 11.452 11.26 C 11.452 11.967 11.285 12.48 10.952 12.8 C 10.625 13.12 10.155 13.28 9.542 13.28 Z M 9.542 12.39 C 9.655 12.39 9.739 12.36 9.792 12.3 C 9.852 12.24 9.882 12.147 9.882 12.02 L 9.882 10.51 C 9.882 10.383 9.852 10.29 9.792 10.23 C 9.739 10.17 9.655 10.14 9.542 10.14 C 9.429 10.14 9.345 10.17 9.292 10.23 C 9.232 10.29 9.202 10.383 9.202 10.51 L 9.202 12.02 C 9.202 12.153 9.232 12.247 9.292 12.3 C 9.345 12.36 9.429 12.39 9.542 12.39 ZM 13.562 13.28 C 12.929 13.28 12.442 13.12 12.102 12.8 C 11.762 12.48 11.592 11.967 11.592 11.26 C 11.592 9.92 12.245 9.25 13.552 9.25 C 13.865 9.25 14.159 9.287 14.432 9.36 C 14.705 9.44 14.909 9.527 15.042 9.62 L 15.042 10.87 L 13.842 10.87 L 13.842 10.51 C 13.842 10.383 13.812 10.29 13.752 10.23 C 13.699 10.17 13.615 10.14 13.502 10.14 C 13.389 10.14 13.305 10.17 13.252 10.23 C 13.192 10.29 13.162 10.383 13.162 10.51 L 13.162 12.02 C 13.162 12.147 13.192 12.24 13.252 12.3 C 13.305 12.36 13.389 12.39 13.502 12.39 C 13.615 12.39 13.699 12.36 13.752 12.3 C 13.812 12.24 13.842 12.147 13.842 12.02 L 13.842 11.62 L 15.042 11.62 L 15.042 12.91 C 14.902 13.003 14.699 13.09 14.432 13.17 C 14.165 13.243 13.875 13.28 13.562 13.28 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocDOC;

import styled from 'styled-components';

export const FlagWrapper = styled.div`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}px` : '30px')} !important;
  height: ${({ height }) => (height ? `${height}px` : '22px')} !important;
  margin-right: 8px;
  & > .flag-icon {
    width: ${({ width }) => (width ? `${width}px` : '30px')} !important;
    height: ${({ height }) => (height ? `${height}px` : '22px')} !important;
    background-size: cover;
    border-radius: ${({ width }) => `calc(${width || 30}px / 10)`};
  }
`;

import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import injectSaga from 'utils/injectSaga';
import { withRouter } from 'react-router-dom';
import saga from './sagas';
import { checkOneSignalRedirectAction } from './actions';

export default compose(
  withRouter,
  injectSaga({ key: 'OneSignal', saga }),
  connect(
    null,
    { checkOneSignalRedirectAction },
  ),
  lifecycle({
    componentDidMount() {
      this.props.checkOneSignalRedirectAction();
    },
  }),
);

import { APIClient } from '@common/common/utils';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import {
  AddNewVenueRequestPayloadToBE,
  AddNewVenueSuccessPayload,
  GetAllVenuesRequestPayload,
  GetAllVenuesSuccessPayload,
  GetVenueDetailsRequestPayload,
  GetApprovedVenueDetailsRequestPayload,
  GetApprovedVenueDetailsSuccessPayload,
  PutVenueRequestPayload,
  PutVenueSuccessPayload,
  GetAllApprovedVenuesRequestPayload,
  GetAllApprovedVenuesSuccessPayload,
} from './types';

export async function getAllVenues({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetAllVenuesRequestPayload): Promise<GetAllVenuesSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: {
            $or: ['locationName', 'agencyTypes', 'address'],
          },
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const agencyTypesFilter =
    popoverFilters?.agencyTypes !== undefined
      ? popoverFilters?.agencyTypes.length > 0 && {
          field: 'agencyTypes',
          operator: 'in',
          value: popoverFilters?.agencyTypes,
        }
      : null;

  const statusFilter =
    popoverFilters?.status !== undefined
      ? popoverFilters?.status.length > 0 && {
          field: 'status',
          operator: 'in',
          value: popoverFilters?.status,
        }
      : null;

  const filtersBy = !isEmpty(popoverFilters) ? [agencyTypesFilter, statusFilter] : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(searchTextFilter) || !isEmpty(filtersBy)
      ? {
          filter: [...searchTextFilter, ...without(filtersBy, undefined, null, false)],
        }
      : {};

  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/locations/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data as GetAllVenuesSuccessPayload;
  } catch (error) {
    console.error('getAllVenues - Error: ', error);
    throw error;
  }
}

export async function getAllVenuesPartner(): Promise<Response> {
  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations?page=1&itemsPerPage=999`,
      {},
    );
    return response.data.data;
  } catch (error) {
    console.error('getSingleVenueDetails - Error: ', error);
    throw error;
  }
}

export async function getAllApprovedVenues({
  page = 1,
  itemsPerPage = 10,
  sortBy = [],
}: GetAllApprovedVenuesRequestPayload): Promise<GetAllApprovedVenuesSuccessPayload> {
  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations?page=${page}&itemsPerPage=${itemsPerPage}`,
      { ...orderBy },
    );

    return response.data as GetAllApprovedVenuesSuccessPayload;
  } catch (error) {
    console.error('getAllApprovedVenues - Error: ', error);
    throw error;
  }
}

export async function getSingleVenueDetails({ locationId }: GetVenueDetailsRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/locations/${locationId}`,
    );

    return response.data;
  } catch (error) {
    console.error('getSingleVenueDetails - Error: ', error);
    throw error;
  }
}

export async function getSingleApprovedVenueDetails({
  locationId,
}: GetApprovedVenueDetailsRequestPayload): Promise<GetApprovedVenueDetailsSuccessPayload> {
  try {
    const response = await APIClient.get<GetApprovedVenueDetailsSuccessPayload>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations/${locationId}/details`,
    );

    return response.data;
  } catch (error) {
    console.error('getSingleApprovedVenueDetails - Error: ', error);
    throw error;
  }
}

export async function getSingleVenueDetailsInvoiceAddresses(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/invoice_addresses`,
    );

    return response.data;
  } catch (error) {
    console.error('getSingleVenueDetailsInvoiceAddresses - Error: ', error);
    throw error;
  }
}

export async function putSingleVenueDetails(payload: PutVenueRequestPayload): Promise<PutVenueSuccessPayload> {
  const { data, id } = payload;

  try {
    const response = await APIClient.put(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/locations/${id}`,
      data,
    );

    return response.data;
  } catch (error) {
    console.error('putSingleVenueDetails - Error: ', error);
    throw error;
  }
}

export async function getInvoiceAddressesSingleVenue(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/invoice_addresses`,
    );

    return response.data;
  } catch (error) {
    console.error('getInvoiceAddressesSingleVenue - Error: ', error);
    throw error;
  }
}

export async function addNewVenue({ ...payload }: AddNewVenueRequestPayloadToBE): Promise<AddNewVenueSuccessPayload> {
  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/practices/me/locations`,
      {
        ...payload,
      },
    );

    return response.data;
  } catch (error) {
    console.error('addNewVenue - Error: ', error);
    throw error;
  }
}

export async function getVenueLocationAffiliations(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations/affiliations?onlyAssigned=true`,
    );

    return response.data;
  } catch (error) {
    console.error('getVenueLocationAffiliations - Error: ', error);
    throw error;
  }
}

export async function getVenueLocationTypes(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations/types`,
    );

    return response.data;
  } catch (error) {
    console.error('getVenueLocationTypes - Error: ', error);
    throw error;
  }
}

export async function getVenueAdditionalServicesTypes(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations/additional_services`,
    );

    return response.data;
  } catch (error) {
    console.error('getVenueAdditionalServicesTypes - Error: ', error);
    throw error;
  }
}

export async function submitPracticeVenue(): Promise<Response> {
  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/current_practice/submit`,
    );

    return response.data;
  } catch (error) {
    console.error('submitPracticeVenue - Error: ', error);
    throw error;
  }
}

export async function getCurrentVenuePraticeDetails(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/current_practice/status`,
    );

    return response.data;
  } catch (error) {
    console.error('getCurrentVenuePraticeDetails - Error: ', error);
    throw error;
  }
}

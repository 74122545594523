import React from 'react';
import PropTypes from 'prop-types';
import { HeroCover } from 'utils/icons';
import { Wrapper, BackgroundWrap, Content } from './styles';

function AnimatedBackground({ children, component, notfixed }) {
  return (
    <Wrapper>
      <Content>{children}</Content>
      <BackgroundWrap notfixed={notfixed}>
        <HeroCover />
        {component}
      </BackgroundWrap>
    </Wrapper>
  );
}

AnimatedBackground.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
  notfixed: PropTypes.bool,
};

AnimatedBackground.defaultProps = {
  children: null,
  component: null,
  notfixed: false,
};

export default AnimatedBackground;

import { APIClient, APIClientPU } from '@common/common/utils';
import { AxiosResponse } from 'axios';
import { GetSpecialtiesRequestPayload, GetAllSpecialtiesSuccessPayload } from './types';

export async function getAllLocationSpecialties({ locationId }: GetSpecialtiesRequestPayload): Promise<Response> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/locations/${locationId}/specialities`,
    );

    return response.data;
  } catch (error) {
    console.error('getAllLocationSpecialties - Error: ', error);
    throw error;
  }
}

export async function getAllSpecialties(): Promise<GetAllSpecialtiesSuccessPayload> {
  try {
    const response = await APIClient.post<any, AxiosResponse<GetAllSpecialtiesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/specialities/v2/filter?page=1&itemsPerPage=200`,
      {
        filter: [{ field: 'pu', operator: 'eq', value: true }],
        orderBy: [{ field: 'name', order: 'asc' }],
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllSpecialties - Error: ', error);
    throw error;
  }
}

export async function getPartnerSpecialties(): Promise<GetAllSpecialtiesSuccessPayload> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetAllSpecialtiesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/specialities/filter?page=1&itemsPerPage=200`,
      {
        orderBy: [{ field: 'name', order: 'asc' }],
      },
    );

    return response.data;
  } catch (error) {
    console.error('getPartnerSpecialties - Error: ', error);
    throw error;
  }
}

import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.gray300};
`;

Section.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  text-align: left;
`;

Section.Title = styled.span`
  font-size: 1.8rem;
  color: ${({ theme, disabled }) => (disabled ? theme.gray200 : theme.black)};
  font-weight: 600;
`;

Section.Subtitle = styled.div`
  font-size: 1.4rem;
  color: ${({ theme, disabled }) => (disabled ? theme.gray200 : theme.gray300)};
`;

Section.Content = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.gray600};
  display: ${({ checked }) => (checked ? 'block' : 'none')};
`;

import every from 'lodash/every';
import {
  /*IMatch,*/
  ILocation,
} from './types';

const regex = {
  digitsPattern: /\d/,
  lettersPattern: /[a-zA-Z]/,
  lowerCasePattern: /[a-z]/,
  upperCasePattern: /[A-Z]/,
  wordsPattern: /\w/,
  symbolsPattern: /\W/,
};
const pswMinLength = 8;
const pswMaxLength = 25;

type Rules = {
  symbols: boolean | undefined;
  words: boolean | undefined;
  digits: boolean | undefined;
  letters: boolean | undefined;
  lowerCase: boolean | undefined;
  upperCase: boolean | undefined;
};

export const isActivePage = (location: ILocation /*, _match?: IMatch*/) => {
  if (!location) return false;
  const { pathname } = location;
  return pathname === '/';
};

export const getPUAnalyticsUUID = async () => {
  const url = `analytics/users`;
  const data = {
    deviceType: 'web',
  };

  try {
    const response = await fetch(`${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      const { data } = await response.json();
      localStorage.setItem('analytics_uuid', data.uuid);
    }

    // yield put(analyticsUUIDResponse(payload));
  } catch (e) {
    console.log(e);
  }
};

export const isMatchingRule = (password: string, rule: string): boolean => {
  switch (rule) {
    case 'symbols':
      return regex.symbolsPattern.test(password);
    case 'words':
      return regex.wordsPattern.test(password);
    case 'digits':
      return regex.digitsPattern.test(password);
    case 'letters':
      return regex.lettersPattern.test(password);
    case 'lowerCase':
      return regex.lowerCasePattern.test(password);
    case 'upperCase':
      return regex.upperCasePattern.test(password);
    default:
      return true;
  }
};

export const isMatchingRules = (password: string, ruleNames: string, withErrorsList = false): Rules | boolean => {
  if (!ruleNames) return true;

  const rules = ruleNames
    .split('|')
    .map((rule) => rule.trim())
    .filter((rule) => !!rule);

  if (withErrorsList) {
    const errors = rules.reduce((acc, key) => {
      acc.set(key, isMatchingRule(password, key));
      return acc;
    }, new Map());

    return Object.fromEntries(errors);
  }

  return every(rules, (rule) => isMatchingRule(password, rule));
};

export const isTooSmall = (password: string): boolean => password?.trim().length < pswMinLength ?? false;

export const isTooLong = (password: string): boolean => password?.trim().length > pswMaxLength ?? false;

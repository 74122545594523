import styled from 'styled-components';
import GoodmeFooterLogo from 'images/goodme-logo-footer.png';
import AONFooterLogo from 'images/AONLogo.svg';
import { Link as _link } from '@components';

export const FooterWrapper = styled.div`
  min-width: 700px;
  min-height: 140px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.white};
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (min-width: 1050px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  font-family: ${({ theme }) => theme.fontFamilySansSerif};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CtaWrapper = styled.div`
  min-height: 210px;
  padding-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  div#linksWrapper {
    display: flex;
    .links {
      min-width: 180px;
    }
    @media only screen and (max-width: 1050px){
      margin-bottom: 30px;
    }
  }
  div#socialWrapper {
    max-width: 470px;
    min-width: 360px;
    display: flex;
    justify-content: space-between;
    margin-left:30px;
    .badgeAppStore {
      margin-bottom: 18px;
    }
    .logoFacebook {
      margin: 0 10px;
    }
    @media only screen and (max-width: 1050px){
      width: 100%;
      margin-left: 0px;
    }
  }
  a {
      font-size: 1.6rem;
      line-height: 1;
      transition: color 100ms linear;
      text-decoration: none;
      display: inline-block;
      & > span{
        line-height: 2.5rem;
      }
      & > img {
        width: 140px;
        margin-bottom: 14px;
      }
      &:visited{ color: ${({ theme }) => theme.black}}
      &:hover{ color: ${({ theme }) => theme.primaryHover}}
      &:active{ color: ${({ theme }) => theme.primary}}
    }
  h3 {
    font-weight: 700;
    margin-bottom: 25px;
    @media only screen and (max-width: 1050px){
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 1050px){
    flex-direction: column;
  }
`;

export const LegalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 45px;
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const GoodmeLogo = styled.img.attrs({
  src: GoodmeFooterLogo,
  alt: 'Goodme Logo',
  height: '12',
})`
  padding: 0 35px 0 30px;
  margin-top: 58px;
`;

export const BrandWrapper = styled.div`
  min-width: 600px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 2;
  padding-right: 25px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 57px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.gray500};
  font-weight: 600;
  ${({ alignRight }) => alignRight && `align-items: flex-end`};
  @media only screen and (max-width: 1050px) {
    height: 40px;
    align-items: flex-start;
  }
`;

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: #888; /* non esiste in theme il grigio dell'axure */
  margin-left: 2px;
  @media only screen and (max-width: 1050px) {
    margin: 0;
  }
`;

export const AonLogo = styled.img.attrs({
  src: AONFooterLogo,
  alt: 'AON Logo',
  height: '17',
  width: '36',
})`
  margin-left: 5px;
  margin-bottom: 1px;
`;

export const Legals = styled.div`
  min-width: 350px;

  @media only screen and (max-width: 1050px) {
    margin-top: 15px;
    min-width: 600px;
  }
`;

export const LinkWrapper = styled.div`
  margin-right: 8px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;

  a:last-child {
    margin-left: 18px;
    text-align: right;
  }
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.gray500};
  font-size: 1.2rem;
  font-weight: 600;
  text-align: right;
`;

export const Link = styled(_link)`
  color: ${({ theme }) => theme.gray500};
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-left: 0;
    height: auto;
  }
`;

export const MailLink = styled.a`
  color: ${({ theme }) => theme.gray500};
  font-size: 1.2rem;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const AonData = styled.div`
  color: ${({ theme }) => theme.gray500};
  span a {
    color: #3b96b2 !important;
  }
`;

export const AonDataHighlighted = styled.div`
  color: ${({ theme }) => theme.red};
`;

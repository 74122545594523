/* global zE */

import { compose, lifecycle, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import jquery from 'jquery';
import { selectFetchHeaders } from 'components/shared/selectors';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import { selectProfileData } from 'containers/EditProfile/selectors';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { zendeskOrgName, zendeskUserRoleFieldId } from 'utils/env';
import ZendeskChat from './ZendeskChat';
import { USER_ROLE } from './constants';

const mapStateToProps = createStructuredSelector({
  fetchHeaders: selectFetchHeaders,
  isAuthenticated: selectIsAuthenticated(),
  userProfileData: selectProfileData(),
});

let waitForZendesk;

export default compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidUpdate() {
      if (waitForZendesk) {
        return;
      }

      const { fetchHeaders, isAuthenticated, userProfileData, isImpersonate } = this.props;

      if (!isAuthenticated || !userProfileData || isImpersonate) {
        return;
      }

      window.zESettings = {
        webWidget: {
          // authenticate in the chat pane (https://developer.zendesk.com/embeddables/docs/widget/settings#authenticate)
          authenticate: {
            chat: {
              jwtFn(callback) {
                fetchWrapper(`zendesk/chat_token`, {
                  method: 'POST',
                  headers: fetchHeaders,
                }).then(({ data }) => {
                  const { token } = data;
                  callback(token);
                });
              },
            },
          },
        },
      };

      // Wait for zendesk things to load
      waitForZendesk = setInterval(() => {
        if (window.$zopim === undefined || window.$zopim.livechat === undefined || window.zE === undefined) {
          return;
        }

        if (typeof window.zE === 'function') {
          // Fill user info for the contact form (https://developer.zendesk.com/embeddables/docs/widget/api#ze.identify)
          zE.identify({
            name: userProfileData.username,
            email: userProfileData.email,
            organization: zendeskOrgName,
          });
        }

        // Hide "read-only" fields when widgets open
        zE('webWidget:on', 'open', () => {
          setTimeout(() => {
            const widget = jquery('#webWidget').contents();
            // Hide email. It cannot be made through Zendesk API
            const emailInput = widget.find('input[name="email"]');
            emailInput.hide();
            widget.find(`label[for="${emailInput[0].id}"]`).hide();
            // Prefill and hide user role. It cannot be prefilled through Zendesk API
            const userRoleField = widget.find(`[name="key:${zendeskUserRoleFieldId}"]`);
            userRoleField.find('input').val(`ticket-role-${USER_ROLE}`);
            userRoleField.parent().hide();
          }, 100);
        });

        clearInterval(waitForZendesk);
        waitForZendesk = null;
      }, 100);
    },
    componentWillUnmount() {
      if (waitForZendesk) {
        clearInterval(waitForZendesk);
      }
    },
  }),
  branch(
    ({ isAuthenticated, isImpersonate, isMobile }) => !isAuthenticated || isImpersonate || isMobile,
    renderNothing,
  ),
)(ZendeskChat);

import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import get from 'lodash/get';

import {
  loadedPrivacyPolicy,
  loadedTermsOfUse,
  setLegalDocuments,
} from '../actions';

export function* getUserLegalDocument({ data }) {
  const url = `legal_documents/users/${data}`;

  try {
    const { data: legalDocumentsData } = yield call(fetchWrapper, {
      method: 'GET',
      url,
    });
    yield put(setLegalDocuments(legalDocumentsData));
  } catch (e) {
    console.log(e);
  }
}

export function* getPrivacyPolicy() {
  const url = `legal_documents/privacy_partners`;

  try {
    const payload = yield call(fetchWrapper, { method: 'GET', url });
    // Add an index property to the acceptances in order to use it in the field name
    get(payload, 'data.content.acceptances', []).forEach((_a, index) => {
      payload.data.content.acceptances[index].index = index;
    });
    yield put(loadedPrivacyPolicy(payload));
  } catch (e) {
    yield put(loadedPrivacyPolicy({}));
  }
}

export function* getTos() {
  const url = `legal_documents/tos_partners`;

  try {
    const payload = yield call(fetchWrapper, { method: 'GET', url });
    // Add an index property to the acceptances in order to use it in the field name
    get(payload, 'data.content.acceptances', []).forEach((_a, index) => {
      payload.data.content.acceptances[index].index = index;
    });
    yield put(loadedTermsOfUse(payload));
  } catch (e) {
    yield put(loadedTermsOfUse({}));
  }
}

import React from 'react';

const SelectArrow = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 12" {...props}>
    <title>Icon/arrow-3</title>
    <path
      d="M1.53.97A.75.75 0 0 0 .47 2.03l9 9a.75.75 0 0 0 1.06 0l9-9A.75.75 0 0 0 18.47.97L10 9.44 1.53.97z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SelectArrow;

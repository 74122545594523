import { compose, withStateHandlers } from 'recompose';
import InputPassword from './InputPassword';

export default compose(
  withStateHandlers(
    {
      typeOfInput: 'password',
    },
    {
      showPassword: ({ typeOfInput }) => () => ({
        typeOfInput: typeOfInput === 'password' ? 'text' : 'password',
      }),
    },
  ),
)(InputPassword);

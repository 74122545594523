import React from 'react';
import PropTypes from 'prop-types';

const MyRequests = ({ colored, ...props }) => (
  <svg viewBox="0 0 172.3 172.3" width="1em" height="1em" {...props}>
    <title>MENU-Requests</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          strokeMiterlimit="10"
          strokeWidth="4px"
          fill="none"
          className="cls-1"
          d="M124.4,170.3a45.9,45.9,0,1,0-45.9-45.9A45.9,45.9,0,0,0,124.4,170.3Zm0,0H2V2H170.3V124.4M2,124.4H78.5M2,32.6H170.3M2,78.5H124.4m-7.65-45.9V78.5M55.55,32.6V170.3"
        />
        <path d="M151.62,106.43,119,146.52a1.88,1.88,0,0,1-2.68.24l-17.55-15,2.45-2.86,16.08,13.78,31.36-38.59Z" />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          strokeMiterlimit="10"
          strokeWidth=".75px"
          fill="none"
          d="M151.62,106.43,119,146.52a1.88,1.88,0,0,1-2.68.24l-17.55-15,2.45-2.86,16.08,13.78,31.36-38.59Z"
        />
      </g>
    </g>
  </svg>
);

MyRequests.propTypes = {
  colored: PropTypes.bool,
};

export default MyRequests;

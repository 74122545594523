import React from 'react';
import PropTypes from 'prop-types';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Wrapper, WrapperSlick, ArrowUp, ArrowDown, WrapperArrows } from './styles';

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  swipe: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '20px',
  speed: 500,
  vertical: true,
  verticalSwiping: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function TimePicker({
  minsMounted,
  hoursMounted,
  back,
  backHours,
  next,
  nextHours,
  mins,
  hours,
  goToSlide,
  sliding,
  setSliding,
  onSwipeMin,
  onSwipeHour,
  initialHoursIndex,
  initialMinsIndex,
}) {
  const doSlide = slide => {
    if (!sliding) {
      setSliding(true);
      slide();
    }
  };
  const doSlideHours = slide => {
    console.log("SLIDE")
    if (!sliding) {
      setSliding(true);
      slide();
    }
  };
  return (
    <Wrapper>
      <WrapperArrows>
        <ArrowUp onClick={() => doSlideHours(nextHours)} />
        <ArrowUp onClick={() => doSlide(next)} />
      </WrapperArrows>
      {/* <div style={{ margin: '23px 0' }} /> */}
      <WrapperSlick>
        <Slick
          ref={hoursMounted}
          {...settings}
          initialSlide={initialHoursIndex}
          onSwipe={onSwipeHour}
          afterChange={() => setSliding(false)}
        >
          {(hours || []).map(({ label }, index) => (
            // eslint-disable-next-line
            <div key={index} onClick={() => goToSlide(index, 'hour')}>
              {label}
            </div>
          ))}
        </Slick>
        <Slick
          ref={minsMounted}
          {...settings}
          initialSlide={initialMinsIndex}
          onSwipe={onSwipeMin}
          afterChange={() => setSliding(false)}
        >
          {(mins || []).map(({ label }, index) => (
            // eslint-disable-next-line
            <div key={index} onClick={() => goToSlide(index, 'min')}>
              {label}
            </div>
          ))}
        </Slick>
      </WrapperSlick>
      <WrapperArrows>
        <ArrowDown onClick={() => doSlideHours(backHours)} />
        <ArrowDown onClick={() => doSlide(back)} />
      </WrapperArrows>
      {/* <div style={{ margin: '23px 0' }} /> */}
    </Wrapper>
  );
}

TimePicker.propTypes = {
  minsMounted: PropTypes.func.isRequired,
  hoursMounted: PropTypes.func.isRequired,
  onSwipeMin: PropTypes.func.isRequired,
  onSwipeHour: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  backHours: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  nextHours: PropTypes.func.isRequired,
  setMin: PropTypes.func.isRequired,
  setHour: PropTypes.func.isRequired,
  goToSlide: PropTypes.func.isRequired,
  mins: PropTypes.array,
  hours: PropTypes.array,
  initialHoursIndex: PropTypes.number,
  initialMinsIndex: PropTypes.number,
  sliding: PropTypes.bool.isRequired,
  setSliding: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  initialHoursIndex: 0,
  initialMinsIndex: 0,
};

export default TimePicker;

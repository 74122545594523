import styled from 'styled-components';
import {
  DropdownMenu as _dropdownmenu,
  DropdownToggle as _dropdownToggle,
  NavLink as _navLink,
} from 'reactstrap';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  padding-right: 0;
`;

export const Img = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const RemoteFilePreviewWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

export const DropdownMenu = styled(_dropdownmenu)`
  border-radius: 0;
  width: auto;
  border: none;
  padding: 0 10px;
  min-width: 160px;

  margin-top: 0;
  border-top: 0;
  box-shadow: 0px 6px 10px 0px rgba(200, 200, 200, 0.8);
`;

export const DropdownToggle = styled(_dropdownToggle)`
  display: flex;
  align-items: center;
  text-align: left;
  height: 80px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const TextWrapper = styled.div`
  padding: 0 15px;
`;

export const Hello = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const Name = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
`;

export const NavLink = styled(_navLink)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.gray200};
  color: ${({ theme }) => theme.black};
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0.8rem 1rem;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;

  svg {
    height: 28px;
    margin-bottom: 3px;
    margin-right: 5px;
    width: 28px;
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const MenuWrapper = styled.div`
  margin-right: 38px;
  display: flex;
`;

import styled, { css } from 'styled-components';
import _label from '../Label';
import _input from '../Input';

export const Label = styled(_label)`
  /* margin-left: -10px;
  margin-top: 25px; */
  margin: 10px 20px;
  padding-top: 10px;
  font-size: 1.4rem;
`;

export const Input = styled(_input)`
  ${({ id }) => id === 'start' && 'border-radius: 25px 0 0 25px'};
  text-align: center;
  ${({ id }) =>
    id === 'end' &&
    css`
      border-radius: 0 25px 25px 0;
      border-left: none;
    `};

  ::-webkit-calendar-picker-indicator {
    background: none;
  }

  &.form-control:disabled {
    background: ${({ theme }) => theme.white} !important;
    color: ${({ theme }) => theme.black} !important;
  }
`;

import { compose, withStateHandlers, withProps } from 'recompose';
import get from 'lodash/get';
import { getTimeSlots } from 'utils/utils';
import SelectHours from './SelectHours';

export default compose(
  withProps(p => ({
    fromInput: { ...get(p, p.names[0]) },
    toInput: { ...get(p, p.names[1]) },
  })),
  withStateHandlers(
    {
      fromSlots: getTimeSlots(),
      toSlots: [...getTimeSlots(), { label: '00:00 pm', value: '00:00' }],
    },
    {
      updateToSlots: (_, { toInput }) => (fieldName, fieldValue) => {
        const newToSlots = getTimeSlots(fieldValue);
        if (!newToSlots.map(v => v.value).includes(toInput.input.value))
          toInput.input.onChange(newToSlots[0].value);
        return { toSlots: newToSlots };
      },
    },
  ),
)(SelectHours);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TimePicker from '../TimePicker';
import Row from '../Row';
import Col from '../Col';
import { Input, Label } from './styles';
// import Label from '../Label';
import messages from './messages';

function TimeRange({ timeStartDataForm, timeEndDataForm, manualInput }) {
  return (
    <Fragment>
      <Row textAlign="center">
        <Col
          sm="6"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!manualInput && (
            <Label label={<FormattedMessage {...messages.from} />} />
          )}
          <TimePicker
            input={timeStartDataForm.input}
            maxTime={timeEndDataForm.input}
          />
        </Col>
        <Col
          sm="6"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!manualInput && (
            <Label label={<FormattedMessage {...messages.to} />} />
          )}
          <TimePicker
            input={timeEndDataForm.input}
            minTime={timeStartDataForm.input}
          />
        </Col>
      </Row>
      {manualInput && (
        <Row>
          <Col paddingRight="0">
            <Input
              type="time"
              id="start"
              step="100"
              {...timeStartDataForm}
              size="lg"
            />
          </Col>
          <Col paddingLeft="0">
            <Input
              type="time"
              id="end"
              step="600"
              {...timeEndDataForm}
              size="lg"
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

TimeRange.propTypes = {
  timeStartDataForm: PropTypes.shape({}).isRequired,
  timeEndDataForm: PropTypes.shape({}).isRequired,
  manualInput: PropTypes.bool,
};

TimeRange.defaultProps = {
  manualInput: false,
};

export default TimeRange;

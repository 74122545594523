import { createAction } from 'redux-actions-helpers';

export const settingRequest = createAction('APP/SETTINGS/REQUEST');
export const settingResponse = createAction('APP/SETTINGS/RESPONSE', data => ({
  data,
}));

export const editSettingRequest = createAction(
  'APP/SETTINGS/EDIT',
  ({ data, formName }) => ({
    data,
    formName,
  }),
);

export const autoApproveRequest = createAction('APP/AUTO_APPROVE/REQUEST');
export const autoApproveResponse = createAction(
  'APP/AUTO_APPROVE/RESPONSE',
  autoApprove => ({
    autoApprove,
  }),
);

export const editAutoApproveRequest = createAction(
  'APP/AUTO_APPROVE/EDIT',
  ({ autoApprove }) => ({
    autoApprove,
  }),
);

export const editBidSLARequest = createAction(
  'APP/BID_SLA/EDIT',
  ({ bidSla }) => ({
    bidSla,
  }),
);

export const videoMeetingActivationRequest = createAction(
  'APP/VIDEOMEETING_ACTIVATION/REQUEST',
);
export const videoMeetingActivationResponse = createAction(
  'APP/VIDEOMEETING_ACTIVATION/RESPONSE',
  videomeeting => ({
    videomeeting,
  }),
);

export const editVideoMeetingActivationRequest = createAction(
  'APP/VIDEOMEETING_ACTIVATION/EDIT',
  ({ videomeeting }) => ({
    videomeeting,
  }),
);

import React from 'react';
import PropTypes from 'prop-types';

const CommunicationsNew = ({ hasUnread, ...props }) => (
  <svg viewBox="2 -2 30 30" width={18} height={24} {...props}>
    <title>CommunicationsNew Icon</title>
    <g fill="currentColor">
      <path d="M24.42 21.57a7.84 7.84 0 0 1-3-4.77 20.21 20.21 0 0 1-.61-5v-.71-.25A5.81 5.81 0 0 0 17 5.38a2 2 0 0 0-4 0 5.81 5.81 0 0 0-3.81 5.45v.96a20.21 20.21 0 0 1-.61 5 7.84 7.84 0 0 1-3 4.77.5.5 0 0 0 .13.9 34.35 34.35 0 0 0 5.81 1 3.52 3.52 0 0 0 7 0 34.35 34.35 0 0 0 5.81-1 .5.5 0 0 0 .13-.9zM15 4.5a1 1 0 0 1 .92.6 5.33 5.33 0 0 0-1.84 0 1 1 0 0 1 .92-.6zM9.58 17.06a21.28 21.28 0 0 0 .64-5.24v-1a4.81 4.81 0 0 1 9.62 0v1a21.28 21.28 0 0 0 .64 5.24A9.58 9.58 0 0 0 23 21.74a34.44 34.44 0 0 1-8 1 34.44 34.44 0 0 1-8-1 9.58 9.58 0 0 0 2.58-4.68zM15 25.5a2.52 2.52 0 0 1-2.44-1.89c.8.05 1.62.08 2.44.08s1.64 0 2.44-.08A2.52 2.52 0 0 1 15 25.5z" />
    </g>
    {hasUnread && <circle cx={24} cy={6} r={6} fill="red" />}
  </svg>
);

CommunicationsNew.propTypes = {
  hasUnread: PropTypes.bool,
};

CommunicationsNew.defaultProps = {
  hasUnread: false,
};

export default CommunicationsNew;

import React from 'react';

const Close = props => (
  <svg width="0.5em" height="0.5em" viewBox="0 0 36 36" {...props}>
    <title>close</title>
    <path
      d="M33.636.406a1.385 1.385 0 111.958 1.958L19.958 18l15.636 15.636a1.385 1.385 0 11-1.958 1.958L18 19.958 2.364 35.594a1.385 1.385 0 11-1.958-1.958L16.042 18 .406 2.364A1.385 1.385 0 112.364.406L18 16.042 33.636.406z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Close;

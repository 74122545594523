import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'components/shared/Label';
import { isString } from 'utils/validation';
import moment from 'moment';
import errorMessages from '../errorMessages';
import { FormGroup, DatePicker, ErrorMessage } from './styles';

function TimePicker({
  input,
  meta,
  titleLabel,
  label,
  className,
  hiddenField,
  placeholder,
  changeTime,
  minTime,
  maxTime,
  requiredfield,
}) {
  const { visited, error, warning } = meta;
  return (
    <FormGroup
      className={className}
      haslabel={`${!!titleLabel || !!label}`}
      hidden={hiddenField}
    >
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          name="time"
          requiredfield={!!(requiredfield || error)}
        />
      )}
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="HH:mm"
        timeCaption="time"
        placeholderText={placeholder || '-- : --'}
        {...input}
        onChange={changeTime}
        onBlur={() => null}
        error={visited && error}
        minTime={
          minTime &&
          moment()
            .hour(parseFloat(minTime.split(':')[0]))
            .minute(parseFloat(minTime.split(':')[1]))
            .toDate()
        }
        maxTime={
          maxTime &&
          moment()
            .hour(parseFloat(maxTime.split(':')[0]))
            .minute(parseFloat(maxTime.split(':')[1]))
            .toDate()
        }
      />
      {visited &&
        ((error && (
          <ErrorMessage>
            <FormattedMessage
              values={typeof error !== 'object' ? {} : error}
              {...errorMessages[isString(error)]}
            />
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}

TimePicker.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
  type: PropTypes.string,
  selectedTime: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.string,
  titleLabel: PropTypes.string,
  haslabel: PropTypes.bool,
  veryValue: PropTypes.any,
  hiddenField: PropTypes.bool,
  placeholder: PropTypes.string,
  changeTime: PropTypes.func.isRequired,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  requiredfield: PropTypes.bool,
};

TimePicker.defaultProps = {
  input: null,
  meta: null,
  type: null,
  className: null,
  selectedTime: null,
  label: null,
  titleLabel: null,
  haslabel: null,
  veryValue: undefined,
  hiddenField: null,
  placeholder: null,
  minTime: null,
  maxTime: null,
  requiredfield: false,
};

export default TimePicker;

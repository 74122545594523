import { compose, lifecycle } from 'recompose';
import { push } from 'connected-react-router/immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginResponse } from 'containers/Auth/actions';
import { createStructuredSelector } from 'reselect';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import { setCookiePolicyConsentAction } from 'containers/App/actions';
import VideomeetingMobile from './VideomeetingMobile';

const mapDispatchToProps = {
  goTo: push,
  setLoginResponse: loginResponse,
  setCookiePolicyConsent: setCookiePolicyConsentAction,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { match, setLoginResponse, setCookiePolicyConsent } = this.props;
      const { token: accessToken, refreshToken } = match.params;
      const storage = window.sessionStorage;
      storage.setItem('jwt_my_care', accessToken);
      storage.setItem('refresh_token_my_care', refreshToken);
      storage.setItem('partner_status', 'approved');
      setCookiePolicyConsent({ consent: 'true' });
      setLoginResponse({
        isAuthenticated: true,
        jwt: accessToken,
        refreshToken,
      });
    },
    componentDidUpdate({ isAuthenticated: prevIsAuthenticated }) {
      const { isAuthenticated, goTo, match } = this.props;
      const { id, bookingId } = match.params;
      if (isAuthenticated && isAuthenticated !== prevIsAuthenticated)
        goTo(`/patient/${id}/details/${bookingId}/videomeeting_mobile`);
    },
  }),
)(VideomeetingMobile);

import React from 'react';

const Specialities = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>Specialities</title>
    <g fill="currentColor">
      <path d="M27.33 22.51a.42.42 0 0 0 .43-.42v-1.72a.43.43 0 0 0-.43-.43h-24A1.29 1.29 0 0 0 2 21.23v5.15a1.29 1.29 0 0 0 1.29 1.29h24a.43.43 0 0 0 .43-.43v-1.72a.43.43 0 0 0-.43-.43 1.29 1.29 0 0 1 0-2.58zm-.42-.85H4.58v-.86h22.33zm-24 4.72v-5.15a.43.43 0 0 1 .43-.43h.43v6h-.48a.43.43 0 0 1-.43-.43zm24.05.43H4.58V26h22.33zM4.58 25.09v-2.58h21a2.18 2.18 0 0 0-.4.86H6.29v.86h18.94a2.12 2.12 0 0 0 .4.86zm0 0M3.59 7l2.7.83v9.92a.43.43 0 0 0 .43.43H23a.43.43 0 0 0 .43-.43V7.87l2.15-.66v2.7L24.46 11l.6.62.56-.55v.67h.86v-.65l.53.54.61-.61-1.14-1.1V6.63a.43.43 0 0 0-.29-.41L15 2.36a.34.34 0 0 0-.28 0L3.58 6.22a.43.43 0 0 0 0 .82zm19 10.32H7.15V8.17l7.59 2.72a.37.37 0 0 0 .29 0l7.58-2.72zm-7.71-14.1l9.79 3.38-9.79 3.4L5.1 6.6zm0 0" />
    </g>
  </svg>
);

export default Specialities;

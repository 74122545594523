import React from 'react';

const FullStar = props => (
  <svg height="24px" viewBox="0 0 1024 1024" {...props}>
    <title>star-full</title>
    <path
      fill="currentColor"
      d="M1024 397.05l-353.78-51.408L512 25.06 353.784 345.642 0 397.05l256 249.538-60.432 352.352L512 832.582 828.432 998.94l-60.434-352.352L1024 397.05z"
    />
  </svg>
);

export default FullStar;

import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import moment from 'moment';
import {
  getVideomeetingBookingRequest,
  getVideomeetingBookingResponse,
  resetVideomeetingData,
  getVideomeetingRequest,
  getVideomeetingRsponse,
} from './actions';

const initialState = fromJS({
  videomeetingLoading: false,
  bookingId: null,
  partnerName: null,
  patientName: null,
  patientFirstName: null,
  patientLastName: null,
  patientId: null,
  profilePicture: null,
  healthServiceName: null,
  title: null,
  notes: null,
  timing: {
    startedAt: null,
    endedAt: null,
    duration: null,
  },
});

export default handleActions(
  {
    [getVideomeetingBookingRequest]: state =>
      state.set('videomeetingLoading', true),
    [getVideomeetingBookingResponse]: (state, { data }) => {
      const {
        id: bookingId,
        partner: { name: partnerName },
        patient: { name: patientName, firstName, lastName, profilePicture },
        healthService: { name: healthServiceName },
        startsAt,
        patientId,
      } = data;
      return state
        .set('bookingId', bookingId)
        .set('partnerName', partnerName)
        .set('patientName', patientName)
        .set('patientFirstName', firstName)
        .set('patientLastName', lastName)
        .set('patientId', patientId)
        .set('profilePicture', profilePicture)
        .set('healthServiceName', healthServiceName)
        .set(
          'title',
          `${healthServiceName} ${moment(startsAt).format('DD/MM/YYYY')}`,
        )
        .set('videomeetingLoading', false);
    },
    [getVideomeetingRequest]: state => state.set('videomeetingLoading', true),
    [getVideomeetingRsponse]: (
      state,
      { notes, startedAt, endedAt, duration },
    ) => {
      const timing = fromJS({ startedAt, endedAt, duration });
      return state
        .set('notes', notes)
        .set('timing', timing)
        .set('videomeetingLoading', false);
    },
    [resetVideomeetingData]: () => initialState,
  },
  { initialState },
);

/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useStore } from 'react-redux';
import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) =>
  (WrappedComponent) => {
    return hoistNonReactStatics((props) => {
      //  si rompe con qualsiasi HOOK
      const store = useStore();
      const [isReady, setIsReady] = useState(false);

      useEffect(() => {
        const { injectReducer } = getInjectors(store);

        injectReducer(key, reducer);
        setIsReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return !isReady ? null : <WrappedComponent {...props} />;
    }, WrappedComponent);
  };

import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { loginResponse } from '../actions';

export function* getFacebookLoginData(facebookData) {
  const url = `auth/login`;
  const dataMap = {
    role: 'partner',
    provider: {
      name: 'facebook',
      token: facebookData.accessToken,
    },
  };
  try {
    yield put(showLoading());
    const { status, data } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: dataMap,
    });

    if (data.result === 'changepass') {
      data.isAuthenticated = false;
      yield put(pushError({ text: 'facebookFirstAccessError' }));
      yield put(loginResponse(data));
      return;
    }
    if (status === 'success') {
      data.isAuthenticated = true;
      yield put(push('/'));
      yield put(loginResponse(data));
    }
  } catch (e) {
    const { packet, response } = e; // eslint-disable-line no-unused-vars
    if (response.status === 403) {
      yield put(
        push({
          pathname: '/register',
          state: {
            isOpenCompleteRegister: true,
            initialValues: {
              username: facebookData.first_name,
              firstName: facebookData.first_name,
              lastName: facebookData.last_name,
              file: facebookData.picture.data.url,
              email: facebookData.email,
              gender: facebookData.gender,
              birthday: facebookData.birthday,
              address: facebookData.address,
              provider: {
                name: 'facebook',
                token: facebookData.accessToken,
              },
            },
          },
        }),
      );
    } else {
      yield put(pushError({ text: 'facebookGenericError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PracticeIcon from 'images/icons/practice';
import Avatar from './Avatar';
import { FileName, FilePreviewUrl, ImagePreview } from './styles';
import { isImage } from './utils';
import LoadingSpinner from './LoadingSpinner';

function RenderPreview({ alt, url, type, filename, firstName, lastName, size }) {
  if (firstName || lastName) {
    return <Avatar firstName={firstName} lastName={lastName} size={size} url={filename} type={type} />;
  }

  if (!filename && !url) {
    return <LoadingSpinner />;
  }

  if (isImage(filename)) {
    return <ImagePreview alt={alt} filename={url || filename} />;
  }

  return (
    <Fragment>
      <PracticeIcon width={40} height={40} color="red" />
      <FileName>{filename && filename.slice(filename.lastIndexOf('/') + 1)}</FileName>
    </Fragment>
  );
}

RenderPreview.propTypes = {
  filename: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
};

RenderPreview.defaultTypes = {
  filename: null,
  firstName: null,
  lastName: null,
  type: null,
};

function MyFilePreview({ alt, filename, firstName, lastName, size, url, type, onlyview, disableClick }) {
  if (!url) {
    return (
      <RenderPreview alt={alt} filename={filename} firstName={firstName} lastName={lastName} size={size} type={type} />
    );
  }

  return (
    <FilePreviewUrl onlyview={onlyview} disableClick={disableClick}>
      <a href={url} target="_blank" download rel="noreferrer">
        <RenderPreview alt={alt} url={url} filename={filename} />
      </a>
    </FilePreviewUrl>
  );
}

MyFilePreview.propTypes = {
  filename: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  alt: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  onlyview: PropTypes.bool,
  disableClick: PropTypes.bool,
};

MyFilePreview.defaultTypes = {
  filename: null,
  firstName: null,
  lastName: null,
  size: null,
  alt: '',
  url: '',
  onlyview: undefined,
  disableClick: undefined,
  type: null,
};

export default MyFilePreview;

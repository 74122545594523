import React from 'react';
import PropTypes from 'prop-types';
import { FamilyIcon, Wrapper, Initials } from './styles';

const Avatar = ({ initials, size, type, url, active }) => (
  <Wrapper backgroundImage={url} size={size} active={active}>
    {initials && !url && <Initials size={size - 10}>{initials}</Initials>}
    {type === 'family' && <FamilyIcon />}
  </Wrapper>
);

Avatar.propTypes = {
  initials: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
};

Avatar.defaultProps = {
  initials: '',
  size: 40,
  type: null,
};

export default Avatar;

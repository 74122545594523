import { compose, withHandlers } from 'recompose';
import GMap from './GMap';

export default compose(
  withHandlers({
    onMapCreated: () => map => {
      map.setOptions({
        disableDefaultUI: true,
      });
    },
  }),
)(GMap);

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form/immutable';
import Slider from '../Slider';
import { FormGroup, ErrorMessage } from '../FormStyles';
import Label from '../Label';
import Map from '../Maps';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import { InputGeosuggest, MapWrapper, SliderWrapper } from './styles';
import messages from './messages';

const marks = {
  0: <strong>{'< 2km'}</strong>,
  5: '5km',
  10: '10km',
  20: '20km',
  30: <strong>30km</strong>,
};

function MyInputGeosuggest2({
  intl,
  addressDataForm,
  rangeDataForm,
  label,
  titleLabel,
  className,
  onSuggestSelect,
  onMapCreated,
  requiredfield,
  marginBottom,
  selectedLocation,
  nearBy,
  onNearBy,
  onlyItaly,
  ...props
}) {
  const { name } = addressDataForm;
  const { google } = window;
  const { error, warning, visited } = addressDataForm.meta;

  // TODO: indagare perchè non scatena meta.touched per sostituire meta.visited
  const hasErrors = visited && !!error;
  const haslabel = !!titleLabel || !!label;
  const placeholder = intl.formatMessage(messages.placeholder);

  if (nearBy && !selectedLocation) onNearBy();

  return (
    <FormGroup
      className={className}
      haslabel={haslabel}
      marginBottom={marginBottom}
    >
      <MapWrapper>
        <Map
          {...selectedLocation}
          radius={rangeDataForm.input.value}
          zoom={11}
          height="495px"
          onNearBy={onNearBy}
        />
      </MapWrapper>
      <SliderWrapper>
        <Field
          component={Slider}
          {...rangeDataForm.input}
          min={0}
          max={30}
          marks={marks}
          step={null}
          defaultValue={
            !rangeDataForm.input.value ? 0 : rangeDataForm.input.value / 1000
          }
          format={value => value / 1000}
          normalize={value => value * 1000}
        />
      </SliderWrapper>
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          name={name}
          requiredfield={!!requiredfield}
        />
      )}
      <InputGeosuggest
        {...addressDataForm}
        initialValue={addressDataForm.input.value}
        id={name}
        {...props}
        onChange={() => null}
        location={new google.maps.LatLng(53.558572, 9.9278215)}
        radius="20"
        onSuggestSelect={onSuggestSelect}
        error={hasErrors}
        placeholder={placeholder}
        country={onlyItaly ? 'it' : null}
      />
      {hasErrors &&
        ((error && (
          <ErrorMessage>
            <FormattedMessage
              values={typeof error !== 'object' ? {} : error}
              {...errorMessages[isString(error)]}
            />
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}

MyInputGeosuggest2.propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  addressDataForm: PropTypes.shape({}).isRequired,
  rangeDataForm: PropTypes.shape({}).isRequired,
  selectedLocation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSuggestSelect: PropTypes.func.isRequired,
  onMapCreated: PropTypes.func.isRequired,
  onNearBy: PropTypes.func.isRequired,
  requiredfield: PropTypes.bool,
  placeholder: PropTypes.string,
  marginBottom: PropTypes.string,
  nearBy: PropTypes.bool,
  onlyItaly: PropTypes.string,
};

MyInputGeosuggest2.defaultProps = {
  className: null,
  label: null,
  titleLabel: null,
  requiredfield: false,
  placeholder: null,
  marginBottom: null,
  onlyItaly: null,
};

export default MyInputGeosuggest2;

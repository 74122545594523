// Import here your languages
import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';
import it from './locales/it.json';

// Set here you favourite default language
export const defaultLanguage = 'it_IT';

// Export here your language files import
export const languagesResources = {
  en_US: { translation: en },
  it_IT: { translation: it },
  de_DE: { translation: de },
  fr_FR: { translation: fr },
};

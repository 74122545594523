import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { getSettings } from 'containers/Settings/sagas/getSettings';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import identity from 'lodash/identity';
import { profileResponse, partnerProfileResponse } from '../actions';

const acceptProperty = [
  'id',
  'username',
  'email',
  'firstName',
  'lastName',
  'gender',
  'SSN',
  'birthDate',
  'birthplaceId',
  'birthplace',
  'pecAddress',
  'phone',
  'zipCode',
  'cityId',
  'city',
  'address',
  'profilePictureId',
];

export function* getProfileData() {
  const url = 'users/me/profile';
  const partnerUrl = 'partners/me/profile';
  yield put(showLoading());

  try {
    const { data } = yield call(fetchWrapper, { url });
    yield put(
      profileResponse({
        data: pickBy(pick(data.user, acceptProperty), identity),
      }),
    );
    const { data: partnerData } = yield call(fetchWrapper, { url: partnerUrl });
    yield call(getSettings);
    yield put(
      partnerProfileResponse({
        partnerData,
      }),
    );
  } catch ({ err, response }) {
    yield put(pushError({ text: 'getProfileDataError' }));
  } finally {
    yield put(hideLoading());
  }
}

import styled from 'styled-components';
import {
  DropdownItem as _dropdownItem,
  DropdownToggle as _dropdownToggle,
  DropdownMenu as _dropdownMenu,
} from 'reactstrap';

export const GrayContainer = styled.div`
  color: ${({ theme }) => theme.gray400};
`;

export const ArrowContainer = styled.div`
  heigth: 100%;
  padding-left: 2.5em;
`;

export const Img = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

export const DropdownItem = styled(_dropdownItem)`
  color: ${({ theme }) => theme.black};
  &:active {
    background: ${({ theme }) => theme.gray300};
  }
  &:hover {
    background: ${({ theme }) => theme.gray100};
    color: ${({ theme }) => theme.black};
  }
`;

export const DropdownToggle = styled(_dropdownToggle)`
  color: ${({ theme }) => theme.gray900};
`;

export const DropdownMenu = styled(_dropdownMenu)`
  border-radius: 2px;
`;

export const DropwDownBorder = styled.div`
  border: 1px solid ${({ theme }) => theme.gray200};
`;

import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { components } from 'react-select';
import find from 'lodash/find';
import { FormGroup, ErrorMessage, Select } from '../FormStyles';
import { isString } from '../../utils/validation';
import Label from '../Label';
import errorMessages from '../errorMessages';
import SelectArrow from './SelectArrow';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SelectArrow />
  </components.DropdownIndicator>
);

function SelectField({
  label,
  titleLabel,
  isSearchable,
  options,
  disabled,
  placeholder,
  input,
  withNullOption,
  callbackOnChange,
  requiredfield,
  meta,
  touched,
  size,
  warning,
  activateTouched,
  marginBottom,
  menuPlacement,
  customOption,
  singleValue,
  onReturn,
}) {
  const { onChange, name, value } = input;
  const { dirty, error } = meta;
  const findValueInsideOptions =
    find(options, ({ value: optionValue }) => optionValue == value) || ''; // check equality by value (e.g. "24" == 24)
  return (
    <FormGroup
      marginBottom={marginBottom}
      haslabel={!!titleLabel || !!label}
      onFocus={() => activateTouched()}
    >
      {(!!label || !!titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={!!requiredfield}
        />
      )}
      <Select
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          DropdownIndicator,
          ...(customOption && { Option: customOption }),
          ...(singleValue && { SingleValue: singleValue }),
        }}
        menuPlacement={menuPlacement || 'auto'}
        options={
          withNullOption ? [{ label: '', value: '' }, ...options] : options
        }
        isDisabled={disabled}
        name={name}
        dirty={dirty}
        error={touched && error}
        onChange={(obj) => {
          if (typeof obj.value === 'boolean') {
            onChange(`${obj.value}`);
          } else {
            onChange(obj.value);
          }
          if (callbackOnChange) callbackOnChange(input.name, obj.value);
        }}
        value={findValueInsideOptions}
        size={size}
        onBlur={(e) => {
          const self = this;
          if (e && self && e.target === self.$control_input[0])
            e.preventDefault();
        }}
        onKeyDown={(e) => {
          if (onReturn && e.keyCode === 13) {
            const self = this;
            if (e && self && e.target === self.$control_input[0])
              e.preventDefault();
            onReturn(e.target.value);
            e.target.blur();
          }
        }}
        className="select"
        classNamePrefix="select-component"
      />
      {touched &&
        ((error && (
          <ErrorMessage>
            {errorMessages[isString(error)] ? (
              <FormattedMessage
                values={typeof error !== 'object' ? {} : error}
                {...errorMessages[isString(error)]}
              />
            ) : (
              error
            )}
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}

SelectField.propTypes = {
  menuPlacement: PropTypes.string,
  marginBottom: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  }),
  withNullOption: PropTypes.bool,
  callbackOnChange: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  singleValue: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  customOption: PropTypes.func.isRequired,
  activateTouched: PropTypes.func.isRequired,
  size: PropTypes.string,
  warning: PropTypes.shape({}),
  selectedValue: PropTypes.string,
  requiredfield: PropTypes.bool,
};

SelectField.defaultProps = {
  isSearchable: false,
  disabled: false,
  placeholder: '',
  withNullOption: false,
  callbackOnChange: null,
  requiredfield: false,
  touched: false,
  meta: {},
};

export default SelectField;

import * as React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, BasicLabel, TitleLabel } from './styles';

function Label({ label, titleLabel, requiredfield, className }) {
  const asteriskForRequiredField = requiredfield ? '*' : '';
  return (
    <Wrapper className={className}>
      {titleLabel && <TitleLabel>{titleLabel}</TitleLabel>}
      {label && (
        <BasicLabel>
          {label}
          {asteriskForRequiredField}
        </BasicLabel>
      )}
    </Wrapper>
  );
}

Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  requiredfield: PropTypes.bool,
  className: PropTypes.string,
};

Label.defaultProps = {
  requiredfield: false,
};

export default Label;

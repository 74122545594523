import React from 'react';
import PropTypes from 'prop-types';

const AcceptedBid = ({ colored, ...props }) => (
  <svg viewBox="0 0 150.25 200.9" width="1em" height="1em" {...props}>
    <title>MENU-Accepted Bid</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M118.38,65.37l-12.58-13h-20V0h64.49V52.4H131Zm-28.62-17h17.73l10.89,11.22,10.9-11.22h17V4H89.76Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M117,200.9H0V180.07c0-18.51,5.71-33.66,16.52-43.83,10.19-9.6,24.71-14.67,42-14.67s31.78,5.07,42,14.67c10.8,10.17,16.52,25.32,16.52,43.83Zm-113-4H113V180.07c0-34.13-20.38-54.5-54.5-54.5S4,145.94,4,180.07Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M58.5,125.57A39.67,39.67,0,1,1,98.16,85.9,39.71,39.71,0,0,1,58.5,125.57Zm0-75.33A35.67,35.67,0,1,0,94.16,85.9,35.71,35.71,0,0,0,58.5,50.24Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M134.06,16l-19.4,23.87a1.11,1.11,0,0,1-1.59.15l-10.45-9,1.46-1.69,9.57,8.2,18.68-23Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M113.79,40.63a1.48,1.48,0,0,1-1-.36l-10.73-9.19,2-2.27L113.6,37l18.67-23,2.32,1.88L115,40.08a1.47,1.47,0,0,1-1,.55ZM103.15,31l10.16,8.71a.7.7,0,0,0,.55.18.76.76,0,0,0,.51-.27L133.53,16l-1.15-.93-18.68,23-9.58-8.22Z"
        />
      </g>
    </g>
  </svg>
);

AcceptedBid.propTypes = {
  colored: PropTypes.bool,
};

AcceptedBid.defaultProps = {
  colored: null,
};

export default AcceptedBid;

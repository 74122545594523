import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';
import { getAcceptanceFieldName } from 'containers/HomePage/LandingPage/FormRegister/TermsModal/utils';

export const handleAcceptancesFor = termsGroup => values => {
  const targetField = termsGroup.documentType;
  const newValues = {};

  // Set documentId
  set(newValues, [targetField, 'documentId'], termsGroup.id);

  // Set acceptances
  each(get(termsGroup, 'content.acceptances', []), (a, idx) => {
    const acceptanceFieldName = getAcceptanceFieldName(
      termsGroup.documentType,
      a,
    );
    const acceptanceStatus = get(values, acceptanceFieldName, false);

    set(newValues, [targetField, `acceptance_${idx}`], acceptanceStatus);
  });

  return newValues;
};

import styled from 'styled-components';

export const Button = styled.button`
  &.rdrDay.rdrDayToday span:after {
    bottom: 9px !important;
  }

  &.rdrDay .rdrEndEdge + .rdrDayNumber .sk-circle {
    color: white !important;
  }
`;

export const DayWrapper = styled.div`
  &.rdrDayNumber {
    display flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
  }
  .sk-circle {
    width: 14px;
    height: 14px;
    position: relative;
  }
`;

export const Availabilities = styled.span`
  font-size: 1.2rem !important;
`;

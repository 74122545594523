import { takeLatest, all, fork } from 'redux-saga/effects';

import { getLoginData, getLogout } from './login';
import { getRegister } from './register';
import { getFacebookLoginData } from './facebookLogin';
import { getGoogleLoginData } from './googleLogin';
import { getChangePassword } from './changePassword';
import { getForgotPassword } from './forgotPassword';
import { JWTokenRefreshListener, jwtRefreshToken } from './jwtRefreshToken';
import { setTokenLocalStorage } from './setTokenLocalStorage';
import { getTokenLocalStorage } from './getTokenLocalStorage';
import { getPrivacyPolicy, getTos, getUserLegalDocument } from './terms';
import { updateTerms } from './updateTerms';
import { removeTokenLocalStorage } from './removeTokenLocalStorage';
import { getPartnerStatus } from './getPartnerStatus';
import { resetPassword } from './resetPassword';
import { getForgotUsername } from './forgotUsername';
import { getGeolocationData } from './getGeolocation';

import {
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  LOGOUT_REQUEST,
  FACEBOOK_LOGIN_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  FORGOT_USERNAME_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  GOOGLE_LOGIN_REQUEST,
  // REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  SET_TOKEN_LOCAL_STORAGE,
  GET_TOKEN_LOCAL_STORAGE,
  PRIVACY_POLICY_LOADING,
  TOS_LOADING,
  GET_PARTNER_STATUS,
  UPDATE_TERMS_REQUEST,
  RESET_PASSWORD,
  REQUEST_LEGAL_DOCUMENTS,
  GET_GEO_LOCATION_REQUEST,
} from '../constants';

export default function* rootSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, getLoginData),
    takeLatest(REGISTER_REQUEST, getRegister),
    takeLatest(LOGOUT_REQUEST, getLogout),
    takeLatest(FACEBOOK_LOGIN_REQUEST, getFacebookLoginData),
    takeLatest(GOOGLE_LOGIN_REQUEST, getGoogleLoginData),
    takeLatest(CHANGE_PASSWORD_REQUEST, getChangePassword),
    takeLatest(FORGOT_USERNAME_REQUEST, getForgotUsername),
    takeLatest(FORGOT_PASSWORD_REQUEST, getForgotPassword),
    fork(JWTokenRefreshListener),
    // takeLatest(REFRESH_TOKEN_SUCCESS, setTokenLocalStorage),
    takeLatest(REFRESH_TOKEN_FAIL, removeTokenLocalStorage),
    takeLatest(SET_TOKEN_LOCAL_STORAGE, setTokenLocalStorage),
    takeLatest(GET_TOKEN_LOCAL_STORAGE, getTokenLocalStorage),
    takeLatest(PRIVACY_POLICY_LOADING, getPrivacyPolicy),
    takeLatest(TOS_LOADING, getTos),
    takeLatest(GET_PARTNER_STATUS, getPartnerStatus),
    takeLatest(UPDATE_TERMS_REQUEST, updateTerms),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(REQUEST_LEGAL_DOCUMENTS, getUserLegalDocument),
    takeLatest(GET_GEO_LOCATION_REQUEST, getGeolocationData),
  ]);
}

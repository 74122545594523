import styled from 'styled-components';
import { Form as FormStrap, ModalBody as ModalBodyStrap } from 'reactstrap';
import { Input as _input } from '@components';

export const ModalTitle = styled.h3`
  font-size: 1.8rem;
  text-align: left;
  line-height: 24px;
  color: #01463a;
`;

export const ModalBody = styled(ModalBodyStrap)`
  padding: 2rem;
`;

export const ModalScrollContent = styled.div`
  max-height: 450px;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow-y: scroll;
  color: ${({ theme }) => theme.gray600};
  font-size: 1.6rem;
`;

export const AcceptanceFieldWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  label {
    font-size: 1.6rem;
  }
`;

export const Form = styled(FormStrap)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled(_input)`
  width: 310px;

  & + input {
    margin-top: 20px;
  }
`;

export const AcceptanceWrap = styled.div`
  padding-top: 10px;
`;

export const AcceptanceTitle = styled.h3`
  color: #01463a;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-bottom: 0;
`;

export const AcceptanceContent = styled.span`
  font-weight: 400;
`;

export const ExtraText = styled.div`
  margin-bottom: ${({ theme }) => theme.fontSizeBase};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

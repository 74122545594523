import styled from 'styled-components';
import _spinkit from 'react-spinkit';

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: calc(100vh - 220px);
  overflow: scroll;
`;
export const Spinkit = styled(_spinkit).attrs({
  color: ({ theme }) => theme.primary,
})``;

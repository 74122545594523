import React from 'react';

const Plus = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
    <path
      d="M10 11.772H.75a.75.75 0 110-1.5H10v-9.25a.75.75 0 111.5 0v9.25h9.25a.75.75 0 110 1.5H11.5v9.25a.75.75 0 11-1.5 0v-9.25z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default Plus;

import { defineMessages } from 'react-intl';

export default defineMessages({
  selectFile: {
    id: 'web.shared.components.FileDropInput.selectFile',
    defaultMessage: 'Select file',
  },
  selectImage: {
    id: 'web.shared.components.FileDropInput.selectImage',
    defaultMessage: 'Select image',
  },
  fileSizeError: {
    id: 'web.shared.components.FileDropInput.fileSizeError',
    defaultMessage: 'The file is too big!',
  },
  fileTypeError: {
    id: 'web.shared.components.FileDropInput.fileTypeError',
    defaultMessage: 'File type not allowed!',
  },
  fileGenericError: {
    id: 'web.shared.components.FileDropInput.fileGenericError',
    defaultMessage: 'An error occurred during upload!',
  },
});

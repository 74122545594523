import i18n from '@apps/pu/i18n';
import { AppRoutes } from '@apps/pu/routes/routesList';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from '../messageHandler/actions';
import {
  createNewPricelistSandboxFailed,
  createNewPricelistSandboxRequest,
  createNewPricelistSandboxSuccess,
  getAllPricelistsFailed,
  getAllPricelistsRequest,
  getAllPricelistsSuccess,
  getAllPricestlistsSandboxFailed,
  getAllPricestlistsSandboxRequest,
  getAllPricestlistsSandboxSuccess,
  getNotApprovedVenuesListFailed,
  getNotApprovedVenuesListRequest,
  getNotApprovedVenuesListSuccess,
  getSinglePricelistFailed,
  getSinglePricelistRequest,
  getSinglePricelistSandboxFailed,
  getSinglePricelistSandboxRequest,
  getSinglePricelistSandboxSuccess,
  getSinglePricelistSpecialtyFailed,
  getSinglePricelistSpecialtyRequest,
  getSinglePricelistSpecialtySuccess,
  getSinglePricelistSuccess,
  saveDraftPricelistSandboxFailed,
  saveDraftPricelistSandboxRequest,
  saveDraftPricelistSandboxSuccess,
  updatePricelistSandboxFailed,
  updatePricelistSandboxRequest,
  updatePricelistSandboxSuccess,
  uploadPricelistSandboxFailed,
  uploadPricelistSandboxRequest,
  uploadPricelistSandboxSuccess,
} from './actions';
import * as PricelistsAPI from './apiCall';
import {
  CreateNewPricelistSandboxRequestPayload,
  GetAllPricelistsRequestPayload,
  GetAllPricelistsSandboxRequestPayload,
  GetNotApprovedVenuesListRequestPayload,
  GetSinglePricelistRequestPayload,
  GetSinglePricelistSpecialtyRequestPayload,
  UpdatePricelistSandboxRequestPayload,
  UploadTemplateFileRequestPayload,
} from './types';

function* getAllPricelistsSaga({ payload }: PayloadAction<GetAllPricelistsRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getAllPricelists, { ...payload });
    const { data } = response;

    yield put(getAllPricelistsSuccess(data));
  } catch (error: any) {
    yield put(getAllPricelistsFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getSinglePricelistSaga({ payload }: PayloadAction<GetSinglePricelistRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getSinglePricelist, { ...payload });
    // yield all([
    //   yield call(PricelistsAPI.getSinglePricelistSpecialty, { ...payload });
    // ])
    const { data } = response;

    yield put(getSinglePricelistSuccess(data));
  } catch (error: any) {
    yield put(getSinglePricelistFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getSinglePricelistSpecialtySaga({ payload }: PayloadAction<GetSinglePricelistSpecialtyRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getSinglePricelistSpecialty, { ...payload });
    const { data } = response;

    yield put(getSinglePricelistSpecialtySuccess(data));
  } catch (error: any) {
    yield put(getSinglePricelistSpecialtyFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* createNewPricelistSandboxSaga({ payload }: PayloadAction<CreateNewPricelistSandboxRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.createNewPricelistSandbox, { ...payload });
    const { data } = response;

    yield put(createNewPricelistSandboxSuccess(data));
  } catch (error: any) {
    yield put(createNewPricelistSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* updatePricelistSandboxSaga({ payload }: PayloadAction<UpdatePricelistSandboxRequestPayload>) {
  try {
    yield call(PricelistsAPI.updatePricelistSandbox, { ...payload });

    yield put(updatePricelistSandboxSuccess());

    if (payload?.path === 'edit') {
      yield put(push(AppRoutes.Home));
    } else if (payload?.path === 'add') {
      yield put(push(AppRoutes.ManagePricelists));
    } else {
      yield put(push(AppRoutes.Home));
    }
  } catch (error: any) {
    yield put(updatePricelistSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* saveDraftPricelistSandboxSaga({ payload }: PayloadAction<UpdatePricelistSandboxRequestPayload>) {
  try {
    yield call(PricelistsAPI.updatePricelistSandbox, { ...payload });

    yield put(saveDraftPricelistSandboxSuccess());
    yield put(messageHandlerSet({ message: i18n.t('web.pu.Pricelist.DraftSavedSuccessfully'), type: 'success' }));

    if (payload?.path === 'edit') {
      yield put(push(AppRoutes.Home));
    } else if (payload?.path === 'add') {
      yield put(push(AppRoutes.ManagePricelists));
    } else if (payload?.path === 'editApproved') {
      yield put(push(AppRoutes.ManagePricelists));
    } else {
      yield put(push(AppRoutes.Home));
    }
  } catch (error: any) {
    yield put(saveDraftPricelistSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* uploadPricelistSandboxSaga({ payload }: PayloadAction<UploadTemplateFileRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.uploadExcelTemplateFile, { ...payload });
    const { data } = response;

    yield put(uploadPricelistSandboxSuccess(data));
    yield put(
      messageHandlerSet({ message: i18n.t('web.pu.PricelistAdd.PricelistAddedSuccessfully'), type: 'success' }),
    );
  } catch (error: any) {
    yield put(uploadPricelistSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message ?? error?.message, type: 'error' }));
  }
}

function* getAllPricelistsSandboxSaga({ payload }: PayloadAction<GetAllPricelistsSandboxRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getAllPricelistsSandbox, { ...payload });
    const { data } = response;

    yield put(getAllPricestlistsSandboxSuccess(data));
  } catch (error: any) {
    yield put(getAllPricestlistsSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getSinglePricelistSandboxSaga({ payload }: PayloadAction<GetSinglePricelistRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getSinglePricelistSandbox, { ...payload });
    const { data } = response;

    yield put(getSinglePricelistSandboxSuccess(data));
  } catch (error: any) {
    yield put(getSinglePricelistSandboxFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getNotApprovedVenuesListSaga({ payload }: PayloadAction<GetNotApprovedVenuesListRequestPayload>) {
  try {
    const response = yield call(PricelistsAPI.getNotApprovedVenuesList, { ...payload });
    const { data } = response;

    yield put(getNotApprovedVenuesListSuccess(data));
  } catch (error: any) {
    yield put(getNotApprovedVenuesListFailed());
  }
}

function* pricelistsSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getAllPricelistsRequest.type, getAllPricelistsSaga);
  yield takeLatest(getSinglePricelistRequest.type, getSinglePricelistSaga);
  yield takeLatest(getSinglePricelistSpecialtyRequest.type, getSinglePricelistSpecialtySaga);
  yield takeLatest(createNewPricelistSandboxRequest.type, createNewPricelistSandboxSaga);
  yield takeLatest(updatePricelistSandboxRequest.type, updatePricelistSandboxSaga);
  yield takeLatest(uploadPricelistSandboxRequest.type, uploadPricelistSandboxSaga);
  yield takeLatest(getAllPricestlistsSandboxRequest.type, getAllPricelistsSandboxSaga);
  yield takeLatest(saveDraftPricelistSandboxRequest.type, saveDraftPricelistSandboxSaga);
  yield takeLatest(getSinglePricelistSandboxRequest.type, getSinglePricelistSandboxSaga);
  yield takeLatest(getNotApprovedVenuesListRequest.type, getNotApprovedVenuesListSaga);
}

export default pricelistsSaga;

import styled from 'styled-components';
import omit from 'utils/omitProps';
import { FormGroup as _formGroup } from 'reactstrap';
import _datePicker from 'react-datepicker';

export const DatePicker = styled(_datePicker)`
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: 1.4rem;
  height: ${({ theme }) => theme.inputHeight};
  border: 1px solid
    ${({ theme, error }) => (error ? theme.danger : theme.gray300)};
  width: 100%;
  padding: 0 10px;
  line-height: 1.5;
  outline: none;

  &:focus {
    color: ${({ theme }) => theme.gray400};
    background-color: ${({ theme }) => theme.gray100};
    border-color: ${({ theme }) => theme.gray100};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.gray200};
  }
`;

export const FormGroup = styled(omit(_formGroup, ['haslabel']))`
  width: 100%;
  margin: 0;
  margin-bottom: ${({ haslabel }) => (haslabel === 'true' ? '13px' : '0')};
  min-height: 50px;

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
  }
  .react-datepicker__time-container {
    min-width: 100px;
  }
  .react-datepicker__time-list-item {
    width: 100%;
    font-size: 1.4rem;
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
  font-size: 1rem;
  text-align: left;
`;

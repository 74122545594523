import { createAction } from 'redux-actions-helpers';

export const setActiveFormAction = createAction(
  'APP/LANDING_PAGE/SET_ACTIVE_FORM',
  ({ activeForm }) => ({ activeForm }),
);

export const setActiveFormParamsAction = createAction(
  'APP/LANDING_PAGE/SET_ACTIVE_FORM_PARAMS',
  ({ params }) => ({ params }),
);

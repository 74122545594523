import React from 'react';
import PropTypes from 'prop-types';

const CircleWithDots = ({ active, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 59 59"
    fill="currentColor"
    {...props}
  >
    <path d="M29.5 59A29.5 29.5 0 1159 29.5 29.54 29.54 0 0129.5 59zm0-57A27.5 27.5 0 1057 29.5 27.54 27.54 0 0029.5 2z" />
    <circle cx={16.16} cy={28.99} r={3.5} />
    <circle cx={29.5} cy={28.99} r={3.5} />
    <circle cx={42.98} cy={28.99} r={3.5} />
    {active && (
      <circle
        stroke="#FFF"
        strokeWidth={4}
        fill="#FF4040"
        cx={50}
        cy={50}
        r={10}
      />
    )}
  </svg>
);

CircleWithDots.propTypes = {
  active: PropTypes.bool,
};

export default CircleWithDots;

import { createSelector } from 'reselect';
import { DEFAULT_LOCALE } from '../../i18n';
import { fromJS, isImmutable } from 'immutable';

// export const selectLanguage = state => state.get('language', DEFAULT_LOCALE);

const defaultLanguageState = fromJS({ locale: DEFAULT_LOCALE });

export const selectLanguage = (state) =>
  isImmutable(state)
    ? state.get('language', defaultLanguageState)
    : fromJS(state).get('language', defaultLanguageState);

export const makeSelectLocale = () => createSelector(selectLanguage, (languageState) => languageState.get('locale'));

export const makeSelectLocales = () =>
  createSelector(selectLanguage, (languageState) => languageState.toJS().remoteLocales);

import React from 'react';

function Google(props) {
  return (
    <svg viewBox="0 0 50 50" width={40} height={40} {...props}>
      <g fill="currentColor">
        <path d="M26 2C13.309 2 3 12.309 3 25s10.309 23 23 23c9.918 0 15.973-4.563 19.125-10.219 3.152-5.656 3.55-12.3 2.594-16.843l-.188-.782h-.781L26 20.125h-1v10.406h11.438c-1.727 4-5.243 6.75-10.438 6.75-6.79 0-12.281-5.492-12.281-12.281 0-6.79 5.492-12.281 12.281-12.281 3.05 0 5.82 1.129 7.969 2.969l.718.593 6.844-6.843.719-.75L41.5 8C37.414 4.277 31.96 2 26 2zm0 2c5.074 0 9.652 1.855 13.281 4.844l-4.812 4.812c-2.38-1.777-5.27-2.937-8.469-2.937-7.871 0-14.281 6.41-14.281 14.281S18.129 39.281 26 39.281c6.55 0 11.262-4.015 12.938-9.468l.406-1.282H27v-6.406l18.844.031c.664 4.035.222 9.828-2.469 14.657C40.515 41.937 35.32 46 26 46 14.387 46 5 36.61 5 25S14.387 4 26 4z" />
      </g>
    </svg>
  );
}

export default Google;

import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'connected-react-router/immutable';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import jwtDecode from 'jwt-decode';
import uuidv4 from 'uuid/v4';
import * as Cookies from 'js-cookie';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { platformReady, basename } from 'utils/env';
import { pushError } from 'containers/Alerts/actions';
import { cleanProfileDataOnLogout, profileRequest, profileSummaryRequest } from 'containers/EditProfile/actions';
import { cleanPartenersDataOnLogout } from 'containers/PartnersRegistration/actions';
import { setActiveFormAction } from 'containers/HomePage/LandingPage/actions';
import { USER_ROLE } from '../constants';
import { loginResponse, logoutResponse, setTokenLocalStorage, setRedirectUrlPostLoginAction } from '../actions';
import { selectIsImpersonated, selectFromPathname, selectFromRedirectUrlPostLogin } from '../selectors';

function* redirectAfterLogin() {
  const externalPathname = yield select(selectFromPathname());
  const queryParams = yield select((state) => state.getIn(['router', 'location', 'search']));
  const hash = yield select((state) => state.getIn(['router', 'location', 'state', 'from', 'hash']));
  const externalSearchPathName = yield select(selectFromRedirectUrlPostLogin());
  const externalPath = externalPathname || externalSearchPathName;

  if (externalPath) {
    yield put(push(`${externalPath}${queryParams}${hash || ''}`));
    yield put(setRedirectUrlPostLoginAction({ url: null }));
  } else yield put(push(`/${queryParams}${hash || ''}`));
}

export function* getLoginData(formData) {
  const { rememberMe, username, password } = formData.data;
  const externalPathname = yield select(selectFromPathname());
  const queryParams = yield select((state) => state.getIn(['router', 'location', 'search']));
  const hash = yield select((state) => state.getIn(['router', 'location', 'state', 'from', 'hash']));

  const url = `auth/login`;
  const nonce = uuidv4();
  try {
    yield put(showLoading());
    const { status, data } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        username,
        password,
        role: USER_ROLE,
        nonce,
      },
    });

    const redirect =
      platformReady === 'false' && data.partnerStatus === 'approved'
        ? '/platform-offline'
        : `${externalPathname || '/'}${queryParams}${hash || ''}`;

    if (status === 'success') {
      if (data.result === 'changepass') {
        yield put(setActiveFormAction({ activeForm: 'changePassword' }));
        yield put(push('/'));
        yield put(loginResponse(data));
        return;
      }
      const decodeAccessToken = data.accessToken && jwtDecode(data.accessToken);
      if (decodeAccessToken.nonce === nonce) {
        data.changePasswordModal = false;
        data.isAuthenticated = true;
        data.jwt = data.accessToken;
        // eslint-disable-next-line no-self-assign
        data.refreshToken = data.refreshToken;
        data.id = decodeAccessToken.id;
        data.privacyNeedUpdate = get(data, ['userLegalFlags', 'privacy_partners']) === 'needs_update';
        data.tosNeedUpdate = get(data, ['userLegalFlags', 'tos_partners']) === 'needs_update';

        yield put(
          setTokenLocalStorage({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            partnerStatus: data.partnerStatus,
            rememberMe,
          }),
        );

        yield put(loginResponse(data));
        yield put(profileRequest());
        yield put(profileSummaryRequest());
        yield call(redirectAfterLogin);
        if (data.partnerStatus !== 'approved') {
          yield put(push('/partner-registration'));
        } else yield put(push(redirect));
        yield put(pushError({ text: '' }));
      }
    }
  } catch ({ packet, response }) {
    // eslint-disable-line no-unused-vars
    if (response.status === 403) {
      yield put(pushError({ text: get(packet, 'message', 'loginDataError') }));
    } else {
      yield put(pushError({ text: 'loginError' }));
    }
    // inject error nel form
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

export function* getLogout() {
  const isImpersonated = yield select(selectIsImpersonated());
  const url = `auth/logout`;
  try {
    if (isImpersonated) {
      yield call(fetchWrapper, {
        url,
        method: 'POST',
        params: {},
      });
    }
    localStorage.removeItem('jwt_my_care');
    localStorage.removeItem('refresh_token_my_care');
    localStorage.removeItem('partner_status');
    localStorage.removeItem('current-language');
    sessionStorage.removeItem('jwt_my_care');
    sessionStorage.removeItem('refresh_token_my_care');
    sessionStorage.removeItem('partner_status');

    if (isImpersonated) {
      const { hostname } = window.location;
      // const domain = hostname.match(/\.[.\w]+$/i)[0];
      const domain = hostname.split('.').slice(-2).join('.').split(':')[0];
      const path = basename;
      Cookies.remove('impersonate_token', { domain, path });
      Cookies.remove('impersonate_refresh_token', { domain, path });
      window.close();
    }

    yield put(logoutResponse());
    // @TODO: refactor. We will have to clean the entire Redux store from jsut one place.
    yield put(cleanProfileDataOnLogout());
    yield put(cleanPartenersDataOnLogout());
    const externalPathname = yield select(selectFromPathname());

    const queryParams = yield select((state) => state.getIn(['router', 'location', 'search']));
    const redirect = `${externalPathname || '/'}${queryParams}`;
    yield put(push(redirect));
  } catch (e) {
    console.log(e);
  }
}

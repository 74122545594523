import React from 'react';
import { compose, lifecycle, withStateHandlers, withHandlers, withProps, branch, renderComponent } from 'recompose';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import moment from 'moment';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import { reduxForm } from 'redux-form/immutable';
import { Spinner } from 'components/shared';
import { push } from 'connected-react-router/immutable';
import { selectAuthToken, selectRefreshToken } from 'containers/Auth/selectors';
import {
  getVideomeetingBookingRequest,
  resetVideomeetingData,
  saveVideomeetingNotesRequest,
  getVideomeetingRequest,
} from './actions';
import { selectVideomeetingLoading, selectVideomeetingData } from './selectors';
import saga from './sagas';
import reducer from './reducer';
import Videomeeting from './Videomeeting';
import { sendSocketMsg } from './utils';
import { PageWrapper } from './styles';
import { SOCKET_ENDPOINT, SOCKET_PATH, SOCKET_AUTHENTICATE, SOCKET_GET_URL } from './constants';

const mapStateToProps = createStructuredSelector({
  jwt: selectAuthToken(),
  refreshToken: selectRefreshToken(),
  isLoading: selectVideomeetingLoading(),
  videomeetingData: selectVideomeetingData(),
});

const mapDispatchToProps = {
  getBookingData: getVideomeetingBookingRequest,
  resetData: resetVideomeetingData,
  saveNotes: saveVideomeetingNotesRequest,
  getVideomeetingData: getVideomeetingRequest,
  goTo: push,
};

export default compose(
  withRouter,
  injectReducer({ key: 'Videomeeting', reducer }),
  injectSaga({ key: 'Videomeeting', saga }),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    {
      isHangupModalOpen: false,
      isSocketErrorModalOpen: false,
      isTimeExpiredModalOpen: false,
      videomeetingUrl: null,
      videomeetingStartTime: null,
      timer: null,
      showNotes: false,
      isMobile: false,
      openHelpModal: false,
    },
    {
      toggleHangupModal:
        ({ isHangupModalOpen }) =>
        () => ({
          isHangupModalOpen: !isHangupModalOpen,
        }),
      toggleSocketErrorModal:
        ({ isSocketErrorModalOpen }) =>
        () => ({
          isSocketErrorModalOpen: !isSocketErrorModalOpen,
        }),
      toggleTimeExpiredModal:
        ({ isTimeExpiredModalOpen }) =>
        () => ({
          isTimeExpiredModalOpen: !isTimeExpiredModalOpen,
        }),
      toggleShowNotes:
        ({ showNotes }) =>
        () => ({
          showNotes: !showNotes,
        }),
      toggleHelpModal:
        ({ openHelpModal, isMobile }) =>
        () => {
          if (isMobile) {
            window.ReactNativeWebView.postMessage(`OPEN_HELP_MODAL`);
            return null;
          }
          return {
            openHelpModal: !openHelpModal,
          };
        },
      setVideomeetingUrl: () => (url) => ({ videomeetingUrl: url }),
      setVideomeetingStartTime:
        (_, { videomeetingData }) =>
        () => {
          const startedAt = get(videomeetingData, 'timing.startedAt', null);
          return {
            videomeetingStartTime: startedAt ? moment(startedAt).toISOString() : moment().toISOString(),
          };
        },
      setTimer:
        ({ timer, videomeetingUrl }) =>
        () => {
          if (!isNull(videomeetingUrl)) {
            return {
              timer: timer ? moment(timer).add(1, 'seconds').toISOString() : moment().toISOString(),
            };
          }
          return null;
        },
      setMobile: () => () => ({ isMobile: true }),
    },
  ),
  withHandlers({
    goBack:
      ({ goTo, videomeetingData, setVideomeetingUrl, location, history, isMobile }) =>
      () => {
        const { patientId, id } = videomeetingData;
        const isAgenda = get(location, 'state.isAgenda', false);
        setVideomeetingUrl(null);
        if (isMobile) window.ReactNativeWebView.postMessage('BACK_TO_APP');
        else setTimeout(() => (isAgenda ? history.goBack() : goTo(`/patient/${patientId}/details/${id}`)), 0);
      },
  }),
  withHandlers({
    confirmHangup:
      ({ toggleHangupModal, goBack }) =>
      () => {
        toggleHangupModal();
        goBack();
      },
    cancelHangup:
      ({ toggleHangupModal }) =>
      () =>
        toggleHangupModal(),
    conenctSocket: () => () => io(SOCKET_ENDPOINT, { path: SOCKET_PATH }),
    getVideomeetingUrl:
      ({ videomeetingData: { id }, jwt, setVideomeetingUrl, setVideomeetingStartTime, toggleSocketErrorModal }) =>
      (socket) => {
        sendSocketMsg(socket, SOCKET_AUTHENTICATE, {
          bookingId: id,
          jwtToken: jwt,
        })
          .then(({ status: statusAuth, data }) => {
            if (statusAuth === 'success' && data === 'accepted')
              sendSocketMsg(socket, SOCKET_GET_URL, {})
                .then(({ status: statusUrl, data: { url } }) => {
                  if (statusUrl === 'success') {
                    setVideomeetingUrl(url);
                    setVideomeetingStartTime();
                  } else {
                    toggleSocketErrorModal();
                    console.log('Socket get url error:', url);
                  }
                })
                .catch((error) => {
                  toggleSocketErrorModal();
                  console.log('Socket get url error:', JSON.stringify(error));
                });
            else {
              toggleSocketErrorModal();
              console.log('Sochet authentication error:', JSON.stringify(data));
            }
          })
          .catch((error) => {
            toggleSocketErrorModal();
            console.log('Sochet authentication error:', JSON.stringify(error));
          });
      },
  }),
  withProps(({ videomeetingData, videomeetingStartTime, timer }) => ({
    initialValues: {
      videomeetingMemo: videomeetingData.notes,
    },
    showTimer: videomeetingStartTime && timer ? moment(timer).diff(videomeetingStartTime, 'minutes') : null,
  })),
  reduxForm({
    form: 'videomeeting',
    enableReinitialize: true,
    onSubmit: (values, _, { saveNotes, videomeetingData }) => {
      const { id } = videomeetingData;
      const { videomeetingMemo: notes } = values.toJS();
      saveNotes({ id, notes });
      if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('NOTES_SAVED');
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        getBookingData,
        match,
        setMobile,
        location: { pathname },
        getVideomeetingData,
      } = this.props;
      const { bookingId } = match.params;
      document.getElementById('main-content-wrapper').style.overflowX = 'hidden';
      getBookingData(bookingId);
      getVideomeetingData(bookingId);
      if (pathname.split('/').includes('videomeeting_mobile')) {
        setMobile();
      } else {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then()
          .catch((err) => {
            console.log(err);
          });
      }
    },
    componentDidUpdate(prevProps) {
      const {
        isLoading,
        isMobile,
        videomeetingData,
        conenctSocket,
        getVideomeetingUrl,
        videomeetingUrl,
        setTimer,
        goBack,
        jwt,
        refreshToken,
        toggleTimeExpiredModal,
      } = this.props;
      const { videomeetingUrl: prevVideomeetingUrl, jwt: prevJwt, refreshToken: prevRefreshToken } = prevProps;

      // Update mobile token
      if (isMobile && (jwt !== prevJwt || refreshToken !== prevRefreshToken)) {
        window.ReactNativeWebView.postMessage(`REFRESH_APP_TOKEN/${jwt}/${refreshToken}`);
      }

      if (videomeetingData && videomeetingData.id && !this.socket && !isLoading) {
        this.socket = conenctSocket();
        if (this.socket) {
          this.socket.on('ws:videomeeting:refresh', () => getVideomeetingUrl(this.socket));
          this.socket.on('ws:videomeeting:leave_room', (data) => {
            const { actor } = data;
            if (actor === 'partner') goBack();
          });
          this.socket.on('ws:videomeeting:expired', () => {
            toggleTimeExpiredModal();
          });
          this.socket.on('connect', () => {
            getVideomeetingUrl(this.socket);
          });
        }
      }
      if (this.socket && videomeetingUrl && !prevVideomeetingUrl)
        this.connectionTimer = setInterval(() => setTimer(), 1000);
    },
    componentWillUnmount() {
      const { resetData, setVideomeetingUrl } = this.props;
      document.getElementById('main-content-wrapper').style.overflowX = 'auto';
      if (this.socket) {
        setVideomeetingUrl(null);
        this.socket.close();
      }
      if (this.connectionTimer) clearInterval(this.connectionTimer);
      resetData();
    },
  }),
  branch(
    ({ isLoading, isMobile }) => isLoading && !isMobile,
    renderComponent(() => (
      <PageWrapper>
        <Spinner isLoading fadeIn="quarter" />
      </PageWrapper>
    )),
  ),
)(Videomeeting);

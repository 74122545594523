import React from 'react';
import PropTypes from 'prop-types';

const Stethoscope = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 36 38" {...props}>
    <title>Stethoscope</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M34 13a3 3 0 10-4 2.816V21a9 9 0 11-18 0v-1.05A10.997 10.997 0 0022 9V4a4 4 0 00-4-4h-4v2h4a2.002 2.002 0 012 2v5A9 9 0 112 9V4a2.002 2.002 0 012-2h4V0H4a4 4 0 00-4 4v5a10.997 10.997 0 0010 10.95V21c0 6.075 4.925 11 11 11s11-4.925 11-11v-5.184A2.991 2.991 0 0034 13z"
        fill="currentColor"
      />
      {active && (
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#FF4040"
          cx={28.923}
          cy={30.923}
          r={5.923}
        />
      )}
    </g>
  </svg>
);

Stethoscope.propTypes = {
  active: PropTypes.bool,
};

export default Stethoscope;

import { compose, withHandlers, branch, renderNothing } from 'recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import OneSignal from 'components/shared/OneSignal';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import { logoutRequest } from 'containers/Auth/actions';
import { goToStepAction } from 'containers/PartnersRegistration/actions';
import HeaderProfileLogin from './HeaderProfileLogin';

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
});

const mapDispatchToProps = {
  goTo: push,
  logout: logoutRequest,
  goToStep: goToStepAction,
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    goToLogin:
      ({ goTo }) =>
      () =>
        goTo('/'),
    onLogout:
      ({ logout }) =>
      () => {
        OneSignal.push(() => {
          OneSignal.setSubscription(false);
          OneSignal.deleteTag('user');
          OneSignal.logoutEmail();
        });
        logout();
        // @TODO: refactor ASAP
        window.location.reload();
      },
  }),
  branch(({ isAuthenticated }) => !isAuthenticated, renderNothing),
)(HeaderProfileLogin);

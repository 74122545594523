import React from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderComponent } from 'recompose';
import { PageWrapper, Spinkit } from './styles';

export const SpinnerComponent = ({ name, fadeIn }) => (
  <Spinkit
    name={name || 'ball-spin-fade-loader'}
    fadeIn={fadeIn || 'quarter'}
  />
);

SpinnerComponent.propTypes = {
  name: PropTypes.string,
  fadeIn: PropTypes.string,
};

function Spinner() {
  return (
    <PageWrapper>
      <SpinnerComponent />
    </PageWrapper>
  );
}

export default compose(
  branch(({ isLoading }) => isLoading, renderComponent(Spinner)),
);

import { init, captureMessage } from '@sentry/browser';
import withCallToActionLogic from 'components/shared/CallToActionAlert';
import Alert from 'containers/Alerts';
import Analytics from 'containers/Analytics';
import Auth from 'containers/Auth';
import { getLocalStorageToken, loginResponse, getGeoLocationRequest } from 'containers/Auth/actions';
import {
  selectPartnerStatus,
  selectIsAuthenticated,
  selectUserId,
  selectIsImpersonated,
} from 'containers/Auth/selectors';
import { profileRequest } from 'containers/EditProfile/actions';
import { selectProfileData } from 'containers/EditProfile/selectors';
import languageSaga from 'containers/LanguageProvider/sagas';
import OneSignalLogic from 'containers/OneSignal';
import { goToStepAction } from 'containers/PartnersRegistration/actions';
import partnersRegistrationReducer from 'containers/PartnersRegistration/reducers';
import { selectCurrentStep } from 'containers/PartnersRegistration/selectors';
import settingsReducer from 'containers/Settings/reducers';
import * as Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { hotjarId, sentryDns } from 'utils/env';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import App from './App';
import { generateCookiePolicyCookieAction, setCookiePolicyConsentAction } from './actions';
import appReducer from './reducer';
import sagas from './sagas';
import { makeSelectCookiePolicyConsent } from './selectors';

if (process.env.NODE_ENV === 'production') {
  // HOTJAR Analitics
  if (hotjarId) hotjar.initialize(hotjarId, 6);

  // Sentry
  init({
    dsn: sentryDns,
  });
}

const mapStateToProps = createStructuredSelector({
  partnerPracticeStatus: selectPartnerStatus(),
  partnerIsAuthenticated: selectIsAuthenticated(),
  partnerUserId: selectUserId(),
  partnerProfileData: selectProfileData(),
  isImpersonate: selectIsImpersonated(),
  currentStep: selectCurrentStep(),
  cookiePolicyConsent: makeSelectCookiePolicyConsent(),
});

const mapDispatchToProps = {
  getLocalStorageToken,
  loginResponse,
  profileRequest,
  goToStep: goToStepAction,
  generateCookiePolicyCookie: generateCookiePolicyCookieAction,
  setCookiePolicyConsent: setCookiePolicyConsentAction,
  getGeoLocation: getGeoLocationRequest,
};

export default compose(
  Auth,
  withRouter,
  Analytics,
  Alert,
  withCallToActionLogic,
  injectReducer({ key: 'settings', reducer: settingsReducer }),
  injectReducer({ key: 'App', reducer: appReducer }),
  injectReducer({ key: 'steps', reducer: partnersRegistrationReducer }),
  injectSaga({ key: 'App', saga: sagas }),
  injectSaga({ key: 'language', saga: languageSaga }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidUpdate() {
      const { partnerPracticeStatus, history, currentStep } = this.props;
      // TODO: attenzione al controllo su currentStep al variare degli step
      if (partnerPracticeStatus !== 'approved' && currentStep <= 6) history.goForward();
    },
    componentDidMount() {
      this.props.getLocalStorageToken();
      this.props.generateCookiePolicyCookie();
      this.props.getGeoLocation();
    },
    componentDidCatch(error, errorInfo) {
      captureMessage(error, { extra: errorInfo });
    },
  }),
  withHandlers({
    logPageView:
      ({ generateCookiePolicyCookie, cookiePolicyConsent, location: { pathname } }) =>
      () => {
        // this create the cookie if user delete it from Application in console
        const cookies = Cookies.get();
        if ((cookies || {}).cookiePolicyConsent === undefined) generateCookiePolicyCookie();
        if (cookiePolicyConsent === 'true') {
          ReactGA.set({ page: pathname });
          ReactGA.pageview(pathname);
        }
        return null;
      },
  }),
  withProps(({ location }) => {
    const aPath = location.pathname.split('/');
    return {
      fromPU: new URLSearchParams(location.search).get('from'),
      singlePage: new URLSearchParams(location.search).get('nocontours'),
      mobileVideomeeting: aPath.includes('videomeeting_mobile'),
    };
  }),
  OneSignalLogic,
)(App);

import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'web.partner.components.Carousel.Card.date',
    defaultMessage: 'DATE',
  },
  time: {
    id: 'web.partner.components.Carousel.Card.time',
    defaultMessage: 'TIME',
  },
  confirm: {
    id: 'web.partner.components.Carousel.Card.confirm',
    defaultMessage: 'Confirm',
  },
  reject: {
    id: 'web.partner.components.Carousel.Card.reject',
    defaultMessage: 'Reject',
  },
});

import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router/immutable';
import { editCurrentPracticeDataRequest, goToStepAction } from 'containers/PartnersRegistration/actions';
import { selectPraticeStatus } from 'containers/PartnersRegistration/selectors';
import { selectProfileData } from 'containers/EditProfile/selectors';
import PlatformOffline from './PlatformOffline';

const mapStateToProps = createStructuredSelector({
  practiceStatus: selectPraticeStatus(),
  userProfileData: selectProfileData(),
});

const mapDispatchToProps = {
  editCurrentPractice: editCurrentPracticeDataRequest,
  goToStep: goToStepAction,
  goTo: push,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(PlatformOffline);

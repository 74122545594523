import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import 'rc-slider/assets/index.css';
import { Wrapper, Slider, Range } from './styles';

function MySlider(props) {
  if (!has(props, ['min']) || !has(props, 'max') || !has(props, 'defaultValue'))
    return null;
  const {
    input,
    // min,
    max,
    marks,
    step,
    defaultValue,
    maxWidth,
    names,
    height,
  } = props;
  const Component = Array.isArray(defaultValue) ? Range : Slider;
  const multiOnChange = (selectedRange, [minName, maxName]) => {
    const [minRange, maxRange] = selectedRange;
    props[minName].input.onChange(minRange);
    props[maxName].input.onChange(maxRange);
  };

  let value = input && input.value;

  if (names) {
    const [minName, maxName] = names;

    value =
      props[minName] &&
      props[maxName] &&
      props[minName].input.value &&
      props[maxName].input.value
        ? [props[minName].input.value, props[maxName].input.value]
        : [];
  }

  return (
    <Wrapper maxWidth={maxWidth} height={height}>
      <Component
        onChange={(v) =>
          Array.isArray(v) ? multiOnChange(v, names) : input.onChange(v)
        }
        /*  min={min} */
        max={max}
        marks={marks}
        step={step}
        defaultValue={defaultValue}
        value={value}
      />
    </Wrapper>
  );
}

MySlider.propTypes = {
  height: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  maxWidth: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.shape({}),
  step: PropTypes.number,
  defaultValue: PropTypes.number,
};

export default MySlider;

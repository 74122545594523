import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'web.partner.components.Login.login',
    defaultMessage: 'Login',
  },
  logout: {
    id: 'web.partner.components.Login.logout',
    defaultMessage: 'Logout',
  },
  register: {
    id: 'web.partner.components.Login.register',
    defaultMessage: 'Register',
  },
  dontHaveAccount: {
    id: 'web.partner.components.Login.dontHaveAccount',
    defaultMessage: 'Don’t have an account?',
  },
  myProfile: {
    id: 'web.partner.components.Login.menu.myProfile',
    defaultMessage: 'My Profile',
  },
  settings: {
    id: 'web.partner.components.Login.menu.settings',
    defaultMessage: 'Settings',
  },
  invoices: {
    id: 'web.partner.components.Login.menu.invoices',
    defaultMessage: 'Invoices',
  },
  hello: {
    id: 'web.partner.components.Login.menu.hello',
    defaultMessage: 'Hello',
  },
  agenda: {
    id: 'web.partner.components.Login.menu.agenda',
    defaultMessage: 'Agenda',
  },
  requests: {
    id: 'web.partner.components.Login.menu.requests',
    defaultMessage: 'Requests',
  },
  patients: {
    id: 'web.partner.components.Login.menu.patients',
    defaultMessage: 'Patients',
  },
  partnerPractice: {
    id: 'web.partner.components.Login.menu.partnerPractice',
    defaultMessage: 'Practice',
  },
  partnerServices: {
    id: 'web.partner.components.Login.menu.partnerServices',
    defaultMessage: 'Services',
  },
  communications: {
    id: 'web.partner.components.Login.menu.communications',
    defaultMessage: 'Communications',
  },
});

import { useProvideAuthContext } from '@common/auth/hooks/useAuthContextValue';
import { authContext } from '@common/auth/utils/authContext';

// export interface AuthProviderProps {}

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuthContext();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

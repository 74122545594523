import React from 'react';
import PropTypes from 'prop-types';

const NewBid = ({ colored, ...props }) => (
  <svg viewBox="0 0 150.25 200.9" width="1em" height="1em" {...props}>
    <title>MENU-New Bid</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M118.38,65.37l-12.58-13h-20V0h64.49V52.4H131Zm-28.62-17h17.73l10.89,11.22,10.9-11.22h17V4H89.76Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M117,200.9H0V180.07c0-18.51,5.71-33.66,16.52-43.83,10.19-9.6,24.71-14.67,42-14.67s31.78,5.07,42,14.67c10.8,10.17,16.52,25.32,16.52,43.83Zm-113-4H113V180.07c0-34.13-20.38-54.5-54.5-54.5S4,145.94,4,180.07Z"
        />
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M58.5,125.57A39.67,39.67,0,1,1,98.16,85.9,39.71,39.71,0,0,1,58.5,125.57Zm0-75.33A35.67,35.67,0,1,0,94.16,85.9,35.71,35.71,0,0,0,58.5,50.24Z"
        />
        <rect x="103.42" y="25.9" width="29.07" height="4" />
        <rect x="115.95" y="13.37" width="4" height="29.07" />
      </g>
    </g>
  </svg>
);

NewBid.propTypes = {
  colored: PropTypes.bool,
};

NewBid.defaultProps = {
  colored: null,
};

export default NewBid;

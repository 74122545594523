import React from 'react';
import { Helmet } from 'react-helmet';
import { ZENDESK_CODE } from './constants';

const ZendeskChat = () => (
  <Helmet>
    <script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_CODE}`}
    />
  </Helmet>
);

ZendeskChat.propTypes = {};

ZendeskChat.defaultProps = {};

export default ZendeskChat;

import styled, { css } from 'styled-components';

export const BoxContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 20px;
  margin: 10px 10px 20px;
  border: 1px solid ${({ theme }) => theme.gray200};
  border-radius: 3px;

  width: 145px;
  flex-basis: auto; /* default value */
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: 120px;
  }
`;

export const StepNumberContainer = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 3.6rem;
  font-weight: 700;
`;

export const StepNameContainer = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export const CProgressStepsStep = styled.li`
  cursor: ${({ doPointer }) => (doPointer ? 'pointer' : 'not-allowed')};
  display: table-cell;
  text-align: center;
  padding: 0;
  padding-bottom: 4px;
  white-space: nowrap;
  position: relative;

  &:first-child div div:first-child {
    background: transparent;
  }

  &:last-child div div:last-child {
    background: transparent;
  }
`;

export const Round = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.gray200};
  ${({ theme, isCurrent }) =>
    isCurrent &&
    css`
      background: ${theme.gray400};
      border: none;
    `};
  ${({ theme, index, completedStep }) =>
    index <= completedStep &&
    css`
      background: ${theme.yellow};
      border: none;
    `};
  ${({ theme, index, completedStep }) =>
    index === completedStep &&
    css`
      background: ${theme.blue};
      border: none;
    `};
  ${({ theme, status }) =>
    (status === 'submitted' || status === 'working') &&
    css`
      background: ${theme.primary};
      border: none;
    `};
  ${({ theme, status }) =>
    status === 'approved' &&
    css`
      background: ${theme.primary};
      border: none;
    `};

  ${({ hasRejectedSections, theme, status }) =>
    hasRejectedSections &&
    status === 'edit' &&
    css`
      background: ${theme.success};
      border: none;
    `};

  ${({ theme, status }) =>
    status === 'rejected' &&
    css`
      background: ${theme.danger};
      border: none;
    `};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  height: 2px;
  background: ${({ status, theme }) =>
    status === 'submitted' || status === 'working'
      ? theme.primary
      : theme.gray200};
  width: calc(50% - 10px);
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.3rem;
  line-height: 24px;
  padding: 0px 15px;
  max-width: 70px;
  margin: ${({ margin }) => margin || 0};
`;

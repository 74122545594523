import styled, { css } from 'styled-components';
import { Button as _button } from '@components';

export const Wrapper = styled.div`
  height: 98px;
  margin: 20px 5px;
  border: 1px solid ${({ theme }) => theme.gray200};
  border-left: 5px solid ${({ theme, color }) => color || theme.gray200};
  border-radius: 2px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 13px 0;
  background-color: ${({ theme }) => theme.white};
`;

export const AvatarWrapper = styled.div`
  width: 85px;
  margin: 0 15px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const Button = styled(_button)`
  & > span {
    font-size: 2rem;
    font-weight: 700;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 224px;
  padding: 0 15px;
  border-left: 1px solid ${({ theme }) => theme.gray200};
  justify-content: space-between;
`;

export const GeneralInfoWrapper = styled.div`
  color: ${({ theme }) => theme.black};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > h1 {
    color: ${({ theme }) => theme.black};
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
  }
`;

export const DatetimeInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 20px;
    height: 0;
    border-top: 1px solid ${({ theme }) => theme.gray200};
  }
`;

export const Datetime = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
      margin-right: 15px;
    `};
`;

export const GrayLabel = styled.div`
  color: ${({ theme }) => theme.gray300};
  font-size: 1.2rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  text-align: ${({ align }) => align || 'left'};
`;

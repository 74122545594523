import styled from 'styled-components';
import { Alert as _alert } from 'reactstrap';

export const Alert = styled(_alert)`
  border-radius: 5px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 85px;
  transform: translateX(-50%);
  width: 60%;
  z-index: 10000;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.black};
  & > * {
    color: ${({ theme }) => theme.black};
  }
  & > button.close {
    outline: none;
  }
  & > span > a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 600;
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { intlShape, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Label from '../Label';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import { FormGroup, Select, ErrorMessage } from '../FormStyles';
import messages from './messages';
import SelectArrow from '../Select/SelectArrow';

export const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SelectArrow />
  </components.DropdownIndicator>
);

function MyInputAutosuggest({
  input,
  titleLabel,
  label,
  handleInputChange,
  suggestions,
  handleChange,
  isMulti,
  selectedOption,
  intl,
  meta,
  touched,
  activateTouched,
  size,
  value,
  disabled,
  placeholder,
  requiredfield,
  marginBottom,
  tabIndex,
  ...props
}) {
  let metaError;
  const showError =
    (touched || meta.touched || meta.submitFailed) && get(meta, 'error', false);
  if (showError)
    metaError = meta.error.size ? meta.error.toJS()[0] : meta.error;

  return (
    <FormGroup
      haslabel={!!titleLabel || !!label ? 1 : 0}
      onFocus={() => activateTouched && activateTouched()}
      marginBottom={marginBottom}
    >
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={!!requiredfield}
        />
      )}
      <Select
        tabIndex={tabIndex}
        name={input.name}
        value={selectedOption || value}
        components={{ DropdownIndicator }}
        onChange={handleChange}
        options={suggestions}
        onInputChange={handleInputChange}
        isMulti={isMulti}
        noOptionsMessage={() => intl.formatMessage(messages.noOption)}
        dirty={meta.dirty ? 1 : 0}
        error={showError}
        size={size}
        isDisabled={disabled}
        placeholder={
          placeholder || <FormattedMessage {...messages.placeholder} />
        }
        {...props}
      />
      {showError && (
        <ErrorMessage>
          {errorMessages[isString(metaError)] ? (
            <FormattedMessage
              values={typeof meta.error !== 'object' ? {} : metaError}
              {...errorMessages[isString(metaError)]}
            />
          ) : (
            metaError
          )}
        </ErrorMessage>
      )}
    </FormGroup>
  );
}

MyInputAutosuggest.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleInputChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  selectedOption: PropTypes.any,
  intl: intlShape.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  touched: PropTypes.bool,
  activateTouched: PropTypes.func,
  size: PropTypes.string,
  value: PropTypes.shape({}),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  requiredfield: PropTypes.bool,
  marginBottom: PropTypes.string,
  tabIndex: PropTypes.number,
};

MyInputAutosuggest.defaultProps = {
  titleLabel: null,
  label: null,
  suggestions: [],
  isMulti: false,
  selectedOption: null,
  touched: false,
  size: null,
  value: null,
  disabled: null,
  placeholder: null,
  marginBottom: null,
  tabIndex: -1,
};

export default MyInputAutosuggest;

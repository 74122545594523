import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
// import { messageHandlerSet } from '../messageHandler/actions';
import {
  getInvoicesFailed,
  getInvoicesRequest,
  getInvoicesSuccess,
  checkInvoicesRequest,
  checkInvoicesSuccess,
  checkInvoicesFailed,
} from './actions';
import * as InvoicesAPI from './apiCall';
import { GetInvoicesListRequestPayload, CheckInvoicesRequestPayload } from './types';

function* getInvoicesListSaga({ payload }: PayloadAction<GetInvoicesListRequestPayload>) {
  try {
    const response = yield call(InvoicesAPI.getInvoicesList, { ...payload });
    const { data } = response;

    yield put(getInvoicesSuccess(data));
  } catch (error: any) {
    yield put(getInvoicesFailed());
    // yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* checkInvoicesSaga({ payload }: PayloadAction<CheckInvoicesRequestPayload>) {
  try {
    const { data } = yield call(InvoicesAPI.checkInvoices, { ...payload });

    yield put(checkInvoicesSuccess(data));
  } catch (error: any) {
    yield put(checkInvoicesFailed());
    // yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* invoicesSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getInvoicesRequest.type, getInvoicesListSaga);
  yield takeLatest(checkInvoicesRequest.type, checkInvoicesSaga);
}

export default invoicesSaga;

import { compose, withStateHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { selectProfileData } from 'containers/EditProfile/selectors';
import { selectUnreadCommunicationsCount } from 'containers/App/selectors';
import { getUnreadCommunicationsCountAction } from 'containers/App/actions';
import { selectPartnerStatus } from 'containers/Auth/selectors';
import { getPartnerStatus } from 'containers/Auth/actions';
import { createStructuredSelector } from 'reselect';
import HeaderProfileLogin from './HeaderProfileLogin';

const mapStateToProps = createStructuredSelector({
  userProfileData: selectProfileData(),
  partnerPracticeStatus: selectPartnerStatus(),
  unreadCommunicationsCount: selectUnreadCommunicationsCount(),
});

const mapDispatchToProps = {
  getPartnerStatus,
  getUnreadCommunicationsCount: getUnreadCommunicationsCountAction,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStateHandlers(
    { isOpen: false },
    { toggle: ({ isOpen }) => () => ({ isOpen: !isOpen }) },
  ),
  lifecycle({
    componentDidMount() {
      this.props.getPartnerStatus();
      const { getUnreadCommunicationsCount } = this.props;
      getUnreadCommunicationsCount();
      this.interval = setInterval(() => getUnreadCommunicationsCount(), 15000);
    },
    componentWillUnmount() {
      clearInterval(this.interval);
    },
  }),
)(HeaderProfileLogin);

import styled, { css } from 'styled-components';
import {
  Nav,
  TabContent as _tabContent,
  Row as _row,
  NavItem as _navItem,
  Col as _col,
} from 'reactstrap';

export const NavWrapper = styled(Nav)`
  width: 100%;
  border-bottom: none !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    display: block;
  }
`;

export const NavItem = styled(_navItem)`
  flex: 1;
  max-width: 100%;
  > a {
    color: ${({ theme }) => theme.black};
    font-family: ${({ theme }) => theme.fontFamilySansSerif};
    font-size: 1.8rem;
    font-weight: 600;
    white-space: nowrap;
    > span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    > svg {
      min-height: 30px;
      min-width: 30px;
      height: 30px;
      width: 30px;
    }
  }
`;

export const TabContent = styled(_tabContent)`
  width: 100%;
`;

export const Col = styled(_col)`
  ${({ theme, bordered }) =>
    bordered &&
    css`
      border-left: 1px solid ${theme.gray200};
      &:last-child {
        border-right: 1px solid ${theme.gray200};
      }
      @media (max-width: ${theme.gridBreakpoints.md}) {
        border-right: 1px solid ${theme.gray200};
      }
    `};
  border-top: none;
  border-bottom: none;
`;

export const Row = styled(_row)`
  min-height: 60vh;
  margin-bottom: 30px;
`;

export const ContentCol = styled(_col)`
  border-left: 1px solid
    ${({ direction, theme }) =>
      direction === 'vertical' ? theme.gray200 : 'transparent'};
`;

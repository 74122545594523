import get from 'lodash/get';

const checkRequiredAcceptances = (content, selectorFn) =>
  !!get(content, 'content.acceptances', [])
    .filter(
      a =>
        a.required &&
        (a.title.trim().length > 0 || a.content.trim().length > 0),
    )
    .map(ra => getAcceptanceFieldName(get(content, 'documentType'), ra))
    .map(selectorFn)
    .reduce((v, a) => v && a, true);

const checkRadioButton = (content, selectorFn) =>
  !!get(content, 'content.acceptances', [])
    .filter(a => a.title.trim().length > 0 || a.content.trim().length > 0)
    .map(ra => getAcceptanceFieldName(get(content, 'documentType'), ra))
    .map(selectorFn)
    .reduce((v, a) => typeof v === 'boolean' && typeof a === 'boolean', true);

const getAcceptanceFieldName = (docType, acceptance, fieldType = 'radio') =>
  `${docType}-${acceptance.index}-${fieldType}`;

const getAcceptanceFieldNameFor = docType => acceptance =>
  getAcceptanceFieldName(docType, acceptance);

export {
  checkRequiredAcceptances,
  getAcceptanceFieldName,
  getAcceptanceFieldNameFor,
  checkRadioButton,
};

import { createReducer } from '@reduxjs/toolkit';
import { resetAuthUserData, saveAuthUserData, getPartnerWarningsSuccess, getPartnerWarningsFailure } from './actions';

export interface AuthDef {
  partnerFirstApprovalReviewed: null;
  impersonatedBy: null;
  isAuthenticated: boolean;
  userId: null;
  jwt: string | undefined;
  privacyNeedUpdate: boolean;
  privacy: null;
  isImpersonated: boolean;
  tosNeedUpdate: boolean;
  tos: null;
  changePassToken: null;
  partnerStatus: null;
  loading: boolean;
  refreshToken: string | undefined;
  warnings: {
    warningType: string;
    relatedId: number;
    relatedUUID: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

const initialState: AuthDef = {
  partnerFirstApprovalReviewed: null,
  impersonatedBy: null,
  isAuthenticated: false,
  userId: null,
  jwt: undefined,
  privacyNeedUpdate: false,
  privacy: null,
  isImpersonated: false,
  tosNeedUpdate: false,
  tos: null,
  changePassToken: null,
  partnerStatus: null,
  loading: false,
  refreshToken: undefined,
  warnings: [],
};

const authReducer = createReducer(initialState, {
  [saveAuthUserData.type]: (state, action) => {
    state.partnerFirstApprovalReviewed = action.payload.firstApprovalReviewed;
    state.isAuthenticated = true;
    state.userId = action.payload.userId;
    // state.jwt = ;
    state.privacyNeedUpdate = action.payload.userLegalFlags.privacy_partners;
    // state.privacy = ;
    state.tosNeedUpdate = action.payload.userLegalFlags.tos_partners;
    state.partnerStatus = action.payload.partnerStatus;
    // state.refreshToken = ;
  },
  [resetAuthUserData.type]: () => initialState,
  [getPartnerWarningsSuccess.type]: (state, action) => {
    state.warnings = action.payload;
  },
  [getPartnerWarningsFailure.type]: (state) => {
    state.warnings = [];
  },
});

export default authReducer;

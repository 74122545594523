import React from 'react';

const House = props => (
  <svg width="1em" height="1em" viewBox="0 0 39 37" {...props}>
    <title>House</title>
    <path
      d="M1 16.333l18.333-15 18.334 15m-13.334 20V23h0-10v13.333m-8.333 0V13m26.667 23.333V13"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default House;

import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import ModalConfirmation from './ModalConfirmation';
import reducer from './reducer';
import { selectModalConfirmationIsOpen } from './selectors';
import { hideModal } from './actions';

const mapStateToProps = createStructuredSelector({
  isOpen: selectModalConfirmationIsOpen(),
});

const mapDispatchToProps = { hideModal };

export default compose(
  injectIntl,
  injectReducer({ key: 'modalConfirmation', reducer }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ModalConfirmation);

import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectProfileDomain = () => state => state.get('profile');

export const selectProfileData = () =>
  createSelector(selectProfileDomain(), profile => profile.get('data'));

export const selectProfileDataLoading = () =>
  createSelector(selectProfileDomain(), profile => profile.get('loading'));

export const selectPartnerData = () =>
  createSelector(selectProfileDomain(), profile => profile.get('partnerData'));

export const selectIsStructurePartner = () =>
  createSelector(
    selectProfileDomain(),
    profile => get(profile.get('partnerData'), 'partnerType') === 'structure',
  );

export const selectIsEditingProfile = () =>
  createSelector(selectProfileDomain(), profile =>
    profile.get('isEditingProfile'),
  );

export const selectProfileSummaryData = () =>
  createSelector(selectProfileDomain(), profile =>
    profile.get('profileSummary'),
  );

export const selectPartnerId = () =>
  createSelector(selectPartnerData(), partnerData => partnerData.id);

export const selectPartnerType = () =>
  createSelector(selectPartnerData(), partnerData => partnerData.partnerType);

export const selectTmpIbanData = () =>
  createSelector(selectProfileDomain(), profile => profile.get('tmpIbanData'));

const envs = {
  hostname:
    process.env.STORYBOOK_API_BASE_PATH || window.MyCareEnvironment.hostname,
  facebookClientId: process.env.FACEBOOK_APP_ID_ENV,
  googleClientId: process.env.STORYBOOK_GOOGLE_APP_ID_ENV,
  googleAnalyticsId: process.env.ANALYTICS_ID_ENV,
  sentryDns: process.env.SENTRY_DNS,
  gmapsId: process.env.GMAPS_API,
  deployedVersion: process.env.DEPLOYED_VERSION,
  storibook: process.env.STORYBOOK_REPO,
  masterLanguage: process.env.STORYBOOK_MASTER_LANGUAGE,
  temporarilyDisabled: process.env.TEMPORARILY_DISABLED,
};

export const env = process.env.NODE_ENV;

export const {
  hostname,
  facebookClientId,
  googleClientId,
  googleAnalyticsId,
  sentryDns,
  gmapsId,
  storibook,
  masterLanguage,
  temporarilyDisabled,
} = envs;

export const keychainKey = `jwt_${env}`;

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import ReactTable from 'react-table/react-table';
import SpinnerTable from '../SpinnerTable';
import Prompt from '../Hocs/withPrompt/Prompt';
import Pagination from './Pagination';
import { ReactTablesWrapper } from './styles';

function Table({
  intl,
  manual,
  showAddCondition,
  data,
  columnsData,
  filtered,
  filterAll,
  formValues,
  loading,
  onChangeInput,
  onFilteredChange,
  currentPage,
  itemsPerPage,
  nextPage,
  prevPage,
  totalPages,
  totalCount,
  goToPage,
  getDataTable,
  urlCall,
  dataMapper,
  defaultExpandedRows,
  badgeList,
  updateExpandedRows,
  resetExpandedRows,
  filters,
  FooterActions,
  headerInitialValues,
  Form,
  onSubmit,
  dirty,
  formLeaveMessage,
  refetchData,
  typeOfCall,
  rowHeight,
  tableActions,
  setFilters,
  setItemsPerPage,
  setCurrentPage,
  resetFilters,
  removeFilter,
  onExpandedChangeCallback,
  ...rest
}) {
  const Wrapper = Form || React.Fragment;
  const wrapperProps = Form ? { onSubmit } : {};
  const expanded = defaultExpandedRows[currentPage] || {};

  return (
    <Wrapper {...wrapperProps}>
      <ReactTablesWrapper rowHeight={rowHeight}>
        <ReactTable
          manual={manual}
          columns={columnsData}
          data={dataMapper ? dataMapper(data || []) : data || []}
          defaultSorted={[{ desc: false }]}
          defaultPageSize={itemsPerPage}
          pageSize={data && data.length}
          resizable
          multiSort
          sortable
          loading={loading}
          LoadingComponent={() => <SpinnerTable loading={loading} />}
          onExpandedChange={(newExpanded, rowIndex) => {
            updateExpandedRows({ currentPage, rowIndex: rowIndex[0] });
            if (onExpandedChangeCallback) onExpandedChangeCallback();
          }}
          expanded={expanded}
          showPagination={!loading}
          PaginationComponent={props => (
            <Pagination
              {...props}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              nextPage={nextPage}
              prevPage={prevPage}
              totalPages={totalPages}
              totalCount={totalCount}
              getDataTable={getDataTable}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
              resetExpandedRows={resetExpandedRows}
            />
          )}
          className="-striped"
          filtered={filtered}
          onFilteredChange={onFilteredChange}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          {...rest}
        />
      </ReactTablesWrapper>

      {FooterActions}
      {Form && <Prompt when={dirty} />}
    </Wrapper>
  );
}

Table.propTypes = {
  showAddCondition: PropTypes.bool,
  formValues: PropTypes.shape({}),
  manual: PropTypes.bool.isRequired,
  columnsData: PropTypes.array.isRequired,
  data: PropTypes.array,
  filtered: PropTypes.array.isRequired,
  filterAll: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  onExpandedChangeCallback: PropTypes.func,
  intl: intlShape,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  nextPage: PropTypes.number,
  prevPage: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  goToPage: PropTypes.func,
  getDataTable: PropTypes.func.isRequired,
  urlCall: PropTypes.string,
  dataMapper: PropTypes.func,
  defaultExpandedRows: PropTypes.shape({}),
  badgeList: PropTypes.arrayOf(
    PropTypes.shape({
      reduxRefIndex: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  updateExpandedRows: PropTypes.func,
  resetExpandedRows: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  Form: PropTypes.any,
  formLeaveMessage: PropTypes.string,
  dirty: PropTypes.bool,
  onSubmit: PropTypes.func,
  FooterActions: PropTypes.node,
  headerInitialValues: PropTypes.shape({}),
  refetchData: PropTypes.func,
  typeOfCall: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({ ActionComponent: PropTypes.any }),
  ),
  setFilters: PropTypes.func.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  nextPage: null,
  prevPage: null,
  totalPages: null,
  totalCount: null,
  goToPage: null,
  currentPage: null,
  itemsPerPage: null,
  urlCall: '',
  badgeList: [],
  dataMapper: () => null,
  Form: null,
  dirty: null,
  formLeaveMessage: null,
  onSubmit: null,
  FooterActions: null,
  filters: null,
  headerInitialValues: null,
  defaultExpandedRows: [],
  typeOfCall: null,
};

export default Table;

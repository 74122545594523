import React from 'react';
import { Success, ThreeDots, Cancel } from '@shared/icons';

export const nextStep = (currentStep, maxStep) => {
  if (currentStep === 10) return 11;
  if (currentStep === 11) return 12;
  return maxStep >= currentStep / 2 + 1 ? currentStep + 2 : currentStep + 1;
};

function isEven(value) {
  if (value % 2 === 0) return true;
  return false;
}

// TODO: USED ONLY BACK BUTTON INTO STEP
export const prevStep = currentStep =>
  isEven(currentStep) ? currentStep - 2 : currentStep - 1;

export const editVisible = status => {
  switch (status) {
    case 'submitted':
      return false;
    case 'approved':
      return false;
    case 'working':
      return false;
    default:
      return true;
  }
};

export const isEdit = status => status === 'edit';
export const notApproved = status => !!status && status !== 'approved';
export const isApproved = status => !!status && status === 'approved';

export const statusVisibility = status => {
  switch (status) {
    case 'submitted':
      return true;
    case 'registered':
      return true;
    case 'edit':
      return true;
    default:
      return false;
  }
};

export const isInShowStatus = status => {
  switch (status) {
    case 'edit':
      return true;
    case 'working':
      return true;
    case 'submitted':
      return true;
    case 'rejected':
      return true;
    default:
      return false;
  }
};

export const statusCompleted = ({ completedStep, step, isStructure }) => {
  if (isStructure && step === 5) return 9;
  if (!isStructure && step === 6) return 11;
  if (!isStructure && step === 7) return 12;
  return completedStep < step ? step * 2 - 1 : step * 2;
};

export const step = {
  registered: {
    completed: 0,
    current: 0,
  },
  profile: {
    completed: 1,
    current: 2,
  },
  specialities: {
    completed: 2,
    current: 3,
  },
  career: {
    completed: 3,
    current: 4,
  },
  invoicing: {
    completed: 4,
    current: 5,
  },
  locations: {
    completed: 5,
    current: 6,
  },
  conditions: {
    completed: 7,
    current: 7,
  },
  contract: {
    completed: 7,
    current: 7,
  },
  submitted: {
    completed: 7,
    current: 7,
  },
  edit: {
    completed: 7,
    current: 1,
  },
  approved: {
    completed: 7,
    current: 7,
  },
  rejected: {
    completed: 7,
    current: 7,
  },
  // // TODOD: REMOVE???????
  unapproved: { completed: 7, current: 7 },
  working: { completed: 7, current: 7 },
};
export const stepStructure = {
  registered: {
    completed: 0,
    current: 0,
  },
  profile: {
    completed: 1,
    current: 2,
  },
  specialities: {
    completed: 2,
    current: 3,
  },
  invoicing: {
    completed: 3,
    current: 4,
  },
  locations: {
    completed: 4,
    current: 4,
  },
  conditions: {
    completed: 7,
    current: 7,
  },
  contract: {
    completed: 6,
    current: 7,
  },
  submitted: {
    completed: 7,
    current: 7,
  },
  edit: {
    completed: 7,
    current: 1,
  },
  approved: {
    completed: 7,
    current: 7,
  },
  rejected: {
    completed: 7,
    current: 7,
  },
  // // TODOD: REMOVE???????
  unapproved: { completed: 7, current: 7 },
  working: { completed: 7, current: 7 },
};

export const iconMapper = {
  approved: <Success color="white" width={18} height={18} />,
  rejected: <Cancel color="white" width={10} height={10} />,
  submitted: <ThreeDots color="white" width={12} height={12} />,
  edit: <Success color="white" width={12} height={12} />,
  working: <ThreeDots color="white" width={12} height={12} />,
  profile: <Success color="white" width={20} height={20} />,
  specialities: <Success color="white" width={20} height={20} />,
  career: <Success color="white" width={20} height={20} />,
  invoicing: <Success color="white" width={20} height={20} />,
  locations: <Success color="white" width={20} height={20} />,
  contract: <Success color="white" width={20} height={20} />,
};

export const iconMapperEditProfile = {
  approved: <Success color="white" width={20} height={20} />,
  rejected: <Cancel color="white" width={12} height={12} />,
  submitted: <ThreeDots color="white" width={12} height={12} />,
  edit: <Success color="white" width={12} height={12} />,
  working: <ThreeDots color="white" width={12} height={12} />,
  profile: <Success color="white" width={12} height={12} />,
  specialities: <Success color="white" width={12} height={12} />,
  career: <Success color="white" width={12} height={12} />,
  invoicing: <Success color="white" width={12} height={12} />,
  locations: <Success color="white" width={12} height={12} />,
  contract: <Success color="white" width={12} height={12} />,
};

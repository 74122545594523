import { createReducer } from '@reduxjs/toolkit';
import {
  createNewPricelistSandboxFailed,
  createNewPricelistSandboxRequest,
  createNewPricelistSandboxSuccess,
  getAllPricelistsFailed,
  getAllPricelistsRequest,
  getAllPricelistsSuccess,
  getAllPricestlistsSandboxFailed,
  getAllPricestlistsSandboxRequest,
  getAllPricestlistsSandboxSuccess,
  getNotApprovedVenuesListFailed,
  getNotApprovedVenuesListRequest,
  getNotApprovedVenuesListSuccess,
  getSinglePricelistFailed,
  getSinglePricelistRequest,
  getSinglePricelistSandboxFailed,
  getSinglePricelistSandboxRequest,
  getSinglePricelistSandboxSuccess,
  getSinglePricelistSpecialtyFailed,
  getSinglePricelistSpecialtyRequest,
  getSinglePricelistSpecialtySuccess,
  getSinglePricelistSuccess,
  resetAllPricestlistsSandbox,
  resetCreateNewPricelistSandbox,
  resetNotApprovedVenuesListRequest,
  resetSearchPricelistsFilters,
  resetSearchSinglePricelistsSpecialtyFilters,
  resetSinglePricelistDetails,
  resetSinglePricelistSandboxDetails,
  resetSinglePricelistSpecialty,
  resetUpdatePricelistSandbox,
  saveDraftPricelistSandboxFailed,
  saveDraftPricelistSandboxRequest,
  saveDraftPricelistSandboxSuccess,
  setPricelistInitialPage,
  setSearchPricelistsFilters,
  setSearchSinglePricelistsSpecialtyFilters,
  updatePricelistSandboxFailed,
  updatePricelistSandboxRequest,
  updatePricelistSandboxSuccess,
  uploadPricelistSandboxFailed,
  uploadPricelistSandboxRequest,
  uploadPricelistSandboxSuccess,
} from './actions';
import {
  GetAllPricelistsSuccessPayload,
  GetPricelistsSpecialtySuccessPayload,
  GetSinglePricelistPayloadSuccess,
  PricelistSandboxInitialState,
  SinglePricelistSandboxState,
} from './types';

const initialState: GetAllPricelistsSuccessPayload = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  pricelistsList: [],
  pricelistsFilter: {
    textFilter: '',
  },
};

const initialPricelistDetailsState: GetSinglePricelistPayloadSuccess = {
  loading: false,
  pricelistDetails: {
    locations: [],
  },
};

const initialPricelistDetailsSpecialtyState: GetPricelistsSpecialtySuccessPayload = {
  loading: false,
  pricelistDetailsSpecialty: [],
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  pricelistsSpecialtyFilter: {
    textFilter: '',
  },
};

const initialPricelistSandboxState: PricelistSandboxInitialState = {
  loading: false,
  updating: {
    loading: false,
  },
  pricelist: undefined,
  showModal: false,
  homepagePricelists: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    pricelists: [],
  },
  savingdraft: {
    loading: false,
  },
  venues: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    venuesList: [],
  },
};

const initialPricelistSandboxDetailsState: SinglePricelistSandboxState = {
  loading: false,
  pricelistSandboxDetails: undefined,
};

export const pricelistReducers = createReducer(initialState, {
  [getAllPricelistsRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllPricelistsSuccess.type]: (state, action) => {
    state.loading = false;
    state.pricelistsList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllPricelistsFailed.type]: (state) => {
    state.loading = false;
  },
  [setSearchPricelistsFilters.type]: (state, action) => {
    state.pricelistsFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchPricelistsFilters.type]: (state) => {
    state.pricelistsFilter.textFilter = '';
  },
  [setPricelistInitialPage.type]: (state) => {
    state.currentPage = 1;
  },
});

export const pricelistDetailsReducer = createReducer(initialPricelistDetailsState, {
  [getSinglePricelistRequest.type]: (state) => {
    state.loading = true;
  },
  [getSinglePricelistSuccess.type]: (state, action) => {
    state.loading = false;
    state.pricelistDetails = action.payload;
  },
  [getSinglePricelistFailed.type]: (state) => {
    state.loading = false;
  },
  [resetSinglePricelistDetails.type]: (state) => {
    state.loading = false;
    state.pricelistDetails = {
      locations: [],
    };
  },
});

export const pricelistDetailsSpecialtyListReducer = createReducer(initialPricelistDetailsSpecialtyState, {
  [getSinglePricelistSpecialtyRequest.type]: (state) => {
    state.loading = true;
  },
  [getSinglePricelistSpecialtySuccess.type]: (state, action) => {
    state.loading = false;
    state.pricelistDetailsSpecialty = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getSinglePricelistSpecialtyFailed.type]: (state) => {
    state.loading = false;
  },
  [resetSinglePricelistSpecialty.type]: (state) => {
    state.loading = false;
    state.pricelistDetailsSpecialty = [];
    state.totalCount = 0;
    state.currentPage = 1;
    state.prevPage = null;
    state.nextPage = null;
  },
  [setSearchSinglePricelistsSpecialtyFilters.type]: (state, action) => {
    state.pricelistsSpecialtyFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchSinglePricelistsSpecialtyFilters.type]: (state) => {
    state.pricelistsSpecialtyFilter.textFilter = '';
  },
});

export const pricelistSandboxReducer = createReducer(initialPricelistSandboxState, {
  [getAllPricestlistsSandboxRequest.type]: (state) => {
    state.homepagePricelists.loading = true;
    // state.loading = false;
    // state.updating.loading = false;
  },
  [getAllPricestlistsSandboxSuccess.type]: (state, action) => {
    state.homepagePricelists.loading = false;
    state.homepagePricelists.pricelists = action.payload.results;
    state.homepagePricelists.totalCount = action.payload.totalCount;
    state.homepagePricelists.currentPage = action.payload.currentPage;
    state.homepagePricelists.prevPage = action.payload.prevPage;
    state.homepagePricelists.nextPage = action.payload.nextPage;
  },
  [getAllPricestlistsSandboxFailed.type]: (state) => {
    state.homepagePricelists.loading = false;
    // state.updating.loading = false;
  },
  [resetAllPricestlistsSandbox.type]: (state) => {
    state.homepagePricelists.loading = false;
    // state.updating.loading = false;
    state.homepagePricelists.pricelists = [];
    state.homepagePricelists.totalCount = 0;
    state.homepagePricelists.currentPage = 1;
    state.homepagePricelists.prevPage = null;
    state.homepagePricelists.nextPage = null;
  },
  [createNewPricelistSandboxRequest.type]: (state) => {
    state.loading = true;
    state.updating.loading = false;
  },
  [createNewPricelistSandboxSuccess.type]: (state, action) => {
    state.loading = false;
    state.updating.loading = false;
    state.pricelist = action.payload;
  },
  [createNewPricelistSandboxFailed.type]: (state) => {
    state.loading = false;
    state.updating.loading = false;
  },
  [resetCreateNewPricelistSandbox.type]: (state) => {
    state.loading = false;
    state.updating.loading = false;
    state.pricelist = undefined;
    state.showModal = false;
  },
  [uploadPricelistSandboxRequest.type]: (state) => {
    state.loading = true;
  },
  [uploadPricelistSandboxSuccess.type]: (state, action) => {
    state.loading = false;
    state.pricelist = action.payload;
  },
  [uploadPricelistSandboxFailed.type]: (state) => {
    state.loading = false;
  },
  [updatePricelistSandboxRequest.type]: (state) => {
    state.updating.loading = true;
  },
  [updatePricelistSandboxSuccess.type]: (state) => {
    state.updating.loading = false;
    state.showModal = true;
  },
  [updatePricelistSandboxFailed.type]: (state) => {
    state.updating.loading = false;
  },
  [resetUpdatePricelistSandbox.type]: (state) => {
    state.updating.loading = false;
    state.showModal = false;
  },
  [saveDraftPricelistSandboxRequest.type]: (state) => {
    state.savingdraft.loading = true;
  },
  [saveDraftPricelistSandboxSuccess.type]: (state) => {
    state.savingdraft.loading = false;
  },
  [saveDraftPricelistSandboxFailed.type]: (state) => {
    state.savingdraft.loading = false;
  },
  [getNotApprovedVenuesListRequest.type]: (state) => {
    state.venues.loading = true;
  },
  [getNotApprovedVenuesListSuccess.type]: (state, action) => {
    state.venues.loading = false;
    state.venues.venuesList = action.payload.results;
    state.venues.totalCount = action.payload.totalCount;
    state.venues.currentPage = action.payload.currentPage;
    state.venues.prevPage = action.payload.prevPage;
    state.venues.nextPage = action.payload.nextPage;
  },
  [getNotApprovedVenuesListFailed.type]: (state) => {
    state.venues.loading = false;
  },
  [resetNotApprovedVenuesListRequest.type]: (state) => {
    state.venues.loading = false;
    state.venues.venuesList = [];
    state.venues.totalCount = 0;
    state.venues.currentPage = 1;
    state.venues.prevPage = null;
    state.venues.nextPage = null;
  },
});

export const pricelistSandboxDetailsReducer = createReducer(initialPricelistSandboxDetailsState, {
  [getSinglePricelistSandboxRequest.type]: (state) => {
    state.loading = true;
  },
  [getSinglePricelistSandboxSuccess.type]: (state, action) => {
    state.loading = false;
    state.pricelistSandboxDetails = action.payload;
  },
  [getSinglePricelistSandboxFailed.type]: (state) => {
    state.loading = false;
  },
  [resetSinglePricelistSandboxDetails.type]: (state) => {
    state.loading = false;
    state.pricelistSandboxDetails = undefined;
  },
});

import styled, { css } from 'styled-components';

export const AppWrapper = styled.div`
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '80')}px;
`;

export const HeaderWrapper = styled.div`
  ${({ isMobile }) => isMobile && `display: none;`};
  position: fixed;
  min-height: 80px;
  flex-basis: 80px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  background: ${({ theme }) => theme.white};
`;

export const ScrollContent = styled.div`
  position: relative;
  height: calc(100vh - ${({ isMobile }) => (isMobile ? '0' : '80')}px);
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  ${({ isImpersonate }) => isImpersonate && `margin-top: 120px;`};
  min-height: ${({ isImpersonate, isMobile }) => {
    if (isImpersonate) return `calc(100vh - 261px)`;
    if (isMobile) return `100vh`;
    return `calc(100vh - 221px)`;
  }};
  z-index: 0;
`;

export const FooterWrapper = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? `display: none;`
      : css`
          border-top: 1px solid ${({ theme }) => theme.gray200};
          display: flex;
          justify-content: center;
          padding-right: 50px;
        `};
`;

export const CookiePolicyBanner = styled.div`
  position: fixed;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(0, 0%, 0%, 0.82);
  color: ${({ theme }) => theme.white};
  font-size: 1.4rem;
  padding: 20px;
  text-align: center;
  letter-spacing: 1px;
  z-index: 2000;
  a,
  a:hover,
  a:visited,
  a:active,
  button {
    color: ${({ theme }) => theme.red};
    text-decoration: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
`;

import axios from 'axios';
import {
  LOGIN_API_URL,
  LOGOUT_API_URL,
  REFRESH_TOKEN_API_URL,
  CHANGE_PASSWORD_URL,
  CHECK_MAINTENANCE_URL,
} from './paths';

const APIClient = axios.create({
  baseURL: window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const APIPUClient = axios.create({
  baseURL: window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('i18nextLng') || 'it_IT';

export const APIClientNoAuth = axios.create({
  baseURL: window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type LoginOptions = {
  username: string;

  password: string;

  role: string;

  nonce: string;
};

type TokenResponse = {
  data?: {
    accessToken?: string;

    refreshToken?: string;

    partnerStatus?: string;

    userStatus?: string;

    token?: string;

    result?: string;
  };
};

type LogoutOptions = {
  accessToken: string;
};

type LogoutResponse = {
  status: number;
};

type ChangePasswordOptions = {
  username: string;

  oldPassword: string;

  newPassword: string;

  role: string;

  token: string;
};

type ChangePasswordResponse = {
  status: string;

  data: {
    id: number;
  };
};

type MaintenanceDetail = {
  id: number;
  startDate: string;
  endDate: string;
  titleToShow: string;
  contentToShow: string;
  enabled: boolean;
};

type MaintenanceResponse = {
  status: string;
  data: {
    enabledMaintenances: MaintenanceDetail[];
    currentMaintenance: MaintenanceDetail | null;
  };
};

export async function login({ username, password, role, nonce }: LoginOptions): Promise<TokenResponse> {
  const { data } = await APIClient.post<LoginOptions & TokenResponse>(
    LOGIN_API_URL,
    {
      username,
      password,
      role,
      nonce,
    },
    {
      headers: {
        'accept-language': 'it_IT',
      },
    },
  );

  // await navigator.geolocation.getCurrentPosition((position) => {
  //   console.log('Latitude is :', position.coords.latitude);
  //   console.log('Longitude is :', position.coords.longitude);
  //   // return position?.coords?.latitude ?? 0 | position?.coords?.longitude ?? 0;
  // });

  return data;
}

export async function logout({ accessToken }: LogoutOptions): Promise<LogoutResponse> {
  const response = await APIClient.post<LogoutOptions & LogoutResponse>(LOGOUT_API_URL, undefined, {
    headers: {
      'accept-language': 'it_IT',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response;
}

export async function changePassword({
  username,
  oldPassword,
  newPassword,
  token,
  role,
}: ChangePasswordOptions): Promise<ChangePasswordResponse> {
  const { data } = await APIClient.patch<ChangePasswordResponse>(
    CHANGE_PASSWORD_URL,
    {
      username,
      oldPassword,
      newPassword,
      token,
      role,
    },
    {
      headers: {
        'accept-language': 'it_IT',
      },
    },
  );

  return data;
}

type RefreshTokenOptions = {
  accessToken: string;
  refreshToken: string;
};

export async function refreshToken({ accessToken, refreshToken }: RefreshTokenOptions): Promise<TokenResponse> {
  try {
    const { data } = await APIClient.post<any, any>(
      REFRESH_TOKEN_API_URL,
      {
        refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'metrics-touchpoint': 'WEB_PARTNER',
          'metrics-geolocation': (0 | 0).toString(),
          // 'x-mycare-meta': {
          //   meta: { analytics: { uuid: 'f4176280-7857-496b-89a6-abce2c5cf22b', languageCode: 'it_IT' } },
          // },
          // 'metrics-geolocation': navigator.geolocation.getCurrentPosition((position) => {
          //   console.log('Latitude is :', position.coords.latitude);
          //   console.log('Longitude is :', position.coords.longitude);
          //   return position?.coords?.latitude ?? 0 | position?.coords?.longitude ?? 0;
          // }),
        },
        // @ts-ignore
        skipAuthRefresh: true,
      },
    );

    return data;
  } catch (err) {
    console.error('refreshToken - Error: ', err);
  }

  return {
    data: {
      accessToken: '',
      refreshToken: '',
    },
  };
}

export async function checkMaintenance(): Promise<MaintenanceResponse> {
  const { data } = await APIPUClient.get<MaintenanceResponse>(CHECK_MAINTENANCE_URL, {
    headers: {
      'accept-language': 'it_IT',
    },
  });

  return data;
}

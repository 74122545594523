import styled from 'styled-components';
import _button from '../Button';

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
export const Button = styled(_button)`
  position: absolute;
  top: 20px;
  left: 10px;
`;

import styled, { css } from 'styled-components';
import { addCSSProperty, arrayCSSProperty } from '../../utils/addCSSProperty';
import omit from '../../utils/omitProps';

export const Wrapper = styled(
  omit('div', [
    'hasChildren',
    'label',
    'titleLabel',
    'bigMargin',
    ...arrayCSSProperty,
  ]),
)`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: ${({ noInput }) => !noInput && '3rem'};

  span {
    color: ${({ theme }) => theme.black};
  }

  ${({ label, titleLabel, hasChildren }) =>
    !label &&
    hasChildren &&
    titleLabel &&
    css`
      label {
        margin-bottom: 1rem;
      }
    `};

  ${({ bigMargin }) =>
    bigMargin &&
    css`
      margin-bottom: 4rem;
    `};
  ${addCSSProperty};
`;

export const InputFake = styled.div`
  padding: 13px 10px;
`;

import { createReducer } from '@reduxjs/toolkit';
import {
  getAllDoctorsForSingleLocationFailed,
  getAllDoctorsForSingleLocationRequest,
  getAllDoctorsForSingleLocationSuccess,
  getDoctorDetailsFailed,
  getDoctorDetailsRequest,
  getDoctorDetailsSuccess,
  resetAllDoctorsListForSingleLocation,
  resetSearchAllDoctorsForSingleLocationFilters,
  setSearchAllDoctorsForSingleLocationFilters,
  getAllDoctorsFailed,
  getAllDoctorsRequest,
  getAllDoctorsSuccess,
  resetSearchAllDoctorsFilters,
  setSearchAllDoctorsFilters,
  resetDoctorDetails,
  createConventionalLocationSuccess,
  createConventionalLocationError,
  createConventionalLocationRequest,
  getDoctorsSkillsRequest,
  getDoctorsSkillsSuccess,
  getDoctorsSkillsFailed,
} from './actions';
import { GetDoctorDetailsSuccessPayload, GetDoctorsSkillsSuccessPayload } from './types';

export interface IDoctors {
  loading: boolean;
  totalCount: number;
  currentPage: number;
  prevPage: null;
  nextPage: null;
  doctorsList: [];
  doctorsFilter: {
    textFilter: string;
  };
  doctorDetails?: GetDoctorDetailsSuccessPayload;
  doctorsSkills: GetDoctorsSkillsSuccessPayload[];
  createConventionLocationLoading: boolean;
}

const initialState: IDoctors = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  doctorsList: [],
  doctorsFilter: {
    textFilter: '',
  },
  doctorDetails: {
    id: 0,
    firstName: '',
    lastname: '',
    doctorId: 0,
    fiscalCode: '',
    phone: null,
    email: null,
    doctorLocations: [],
    specialities: [],
    pricelistServices: [],
  },
  doctorsSkills: [],
  createConventionLocationLoading: false,
};

export const doctorsReducers = createReducer(initialState, {
  [getAllDoctorsForSingleLocationRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllDoctorsForSingleLocationSuccess.type]: (state, action) => {
    state.loading = false;
    state.doctorsList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllDoctorsForSingleLocationFailed.type]: (state) => {
    state.loading = false;
  },

  [getDoctorDetailsRequest.type]: (state) => {
    state.loading = true;
  },
  [getDoctorDetailsSuccess.type]: (state, action) => {
    state.loading = false;
    state.doctorDetails = action.payload;
  },
  [getDoctorDetailsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetDoctorDetails.type]: (state) => {
    state.doctorDetails = undefined;
  },

  [resetAllDoctorsListForSingleLocation.type]: (state) => {
    state.doctorsList = [];
    state.totalCount = 0;
    state.currentPage = 1;
    state.prevPage = null;
    state.nextPage = null;
  },
  [setSearchAllDoctorsForSingleLocationFilters.type]: (state, action) => {
    state.doctorsFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchAllDoctorsForSingleLocationFilters.type]: (state) => {
    state.doctorsFilter.textFilter = '';
  },

  [getAllDoctorsRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllDoctorsSuccess.type]: (state, action) => {
    state.loading = false;
    state.doctorsList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllDoctorsFailed.type]: (state) => {
    state.loading = false;
  },
  [setSearchAllDoctorsFilters.type]: (state, action) => {
    state.doctorsFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchAllDoctorsFilters.type]: (state) => {
    state.doctorsFilter.textFilter = '';
  },

  [createConventionalLocationRequest.type]: (state) => {
    state.createConventionLocationLoading = true;
  },
  [createConventionalLocationSuccess.type]: (state, action) => {
    state.createConventionLocationLoading = false;
    state.doctorDetails = action.payload.results;
  },
  [createConventionalLocationError.type]: (state) => {
    state.createConventionLocationLoading = false;
  },

  [getDoctorsSkillsRequest.type]: (state) => {
    state.loading = true;
  },
  [getDoctorsSkillsSuccess.type]: (state, action) => {
    state.loading = false;
    state.doctorsSkills = action.payload;
  },
  [getDoctorsSkillsFailed.type]: (state) => {
    state.loading = false;
  },
});

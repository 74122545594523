import React, { Fragment } from 'react';
import {
  PanelGroup,
  PanelCollapse,
  PanelTitle,
  PanelSubTitle,
  PanelHeader,
  PanelHeaderLeft,
  PanelHeaderRight,
  DropDownArrow,
  IconName,
  IconWrapper,
} from './styles';

export default class Collapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      title,
      subtitle,
      iconName,
      icon,
      children,
      borderBottom,
      noMarginLR,
    } = this.props;
    // capitalized first letter of all
    const capitalizeFirstLetter = elementToCapitalize => {
      if (elementToCapitalize) {
        return (
          elementToCapitalize.charAt(0).toUpperCase() +
          elementToCapitalize.slice(1)
        );
      }
    };

    return (
      <PanelGroup>
        <PanelHeader
         borderBottom={borderBottom}
          noMarginLR={noMarginLR}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <PanelHeaderLeft>
            <PanelTitle>{capitalizeFirstLetter(title)}</PanelTitle>
            <PanelSubTitle>{capitalizeFirstLetter(subtitle)}</PanelSubTitle>
          </PanelHeaderLeft>
          <PanelHeaderRight>
            {icon ? (
              <Fragment>
                <IconName>{capitalizeFirstLetter(iconName)}</IconName>
                <IconWrapper>{icon}</IconWrapper>{' '}
              </Fragment>
            ) : null}
            <DropDownArrow active={this.state.open} />
          </PanelHeaderRight>
        </PanelHeader>
        <PanelCollapse open={this.state.open}>{children}</PanelCollapse>
      </PanelGroup>
    );
  }
}

import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import packageJson from '../../../../../package.json';

export const CacheChecker = ({ children }) => {
  const location = useLocation();

  const clearCacheData = useCallback(() => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  useEffect(() => {
    const isImpersonated = localStorage.getItem('is_impersonated');
    const oldAppVersion = localStorage.getItem('app_version');
    const newAppVersion = packageJson.version;
    if (oldAppVersion !== newAppVersion && !isImpersonated) {
      clearCacheData();
      localStorage.setItem('app_version', newAppVersion);
      window.location.reload();
    }
  }, [location, clearCacheData]);

  return <>{children}</>;
};

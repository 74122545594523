import styled from 'styled-components';
import { Container as _container } from 'reactstrap';
import { PageWrapper as _pageWrapper } from 'components/shared';

export const PageWrapper = styled(_pageWrapper)`
  max-width: 930px;
  margin: 0 auto;
  padding: 20px 0 0;
`;

export const Container = styled(_container)`
  max-width: 930px;
  padding: 0;
`;

export const HeaderButtonContainer = styled.div`
  position: relative;
  width: 120px;
  & a:hover {
    text-decoration: none;
  }
`;

export const WizardTitle = styled.span`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.black};
`;

export const AnamnesisWrapper = styled.div`
  & a:hover {
    text-decoration: none;
  }
`;

export const Category = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`;

export const Question = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.black};
  margin-left: 5px;
`;

export const Answer = styled.div`
  width: 100%;
  min-height: 50px;
  background-color: ${({ theme }) => theme.gray300};
  border-radius: 25px;
  padding: 15px 20px;
  text-align: left;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.gray600};
  margin: 10px 0;
`;

export const DownloadButton = styled.div`
  height: 30px;
  font-size: 1.4rem;
  border-radius: 15px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { GoogleMap, Marker, Circle } from 'react-google-maps';
// import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import UserMarker from '../../images/map-pin/marker-user.png';
import { Located } from '../../icons';
import Pin from '../../images/map-pin/pin.png';
import PinBestMatch from '../../images/map-pin/pinBestMatch.png';
import { SpinnerComponent } from '../Spinner';
// import SearchCard from '../SearchCard';
import { mapStyle } from './mapStyles';
import { SpinnerWrapper, Button } from './styles';

function GoogleMapExample({
  markers,
  onMapMounted,
  userPosition,
  radius,
  toggleInfoWindow,
  infoWindowSelected,
  partnersData,
  zoom,
  selectedVisit,
  onNearBy,
  fitBounds,
  toggleBookingDetails,
  healthServiceData,
  searchListData,
}) {
  const results = get(searchListData, 'results', '');
  return (
    <Fragment>
      <Button
        icon={() => <Located width="2rem" height="2rem" />}
        onClick={onNearBy || fitBounds}
      />
      <GoogleMap
        center={userPosition}
        ref={onMapMounted}
        defaultZoom={zoom || 8}
        disableDefaultUI
        defaultOptions={{
          styles: mapStyle,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
        }}
        onClick={() => toggleInfoWindow()}
      >
        {userPosition && (
          <Fragment>
            <Marker
              position={userPosition}
              icon={{
                url: UserMarker,
                anchor: new window.google.maps.Point(6, 6),
              }}
            />
            <Circle
              center={userPosition}
              radius={radius}
              options={{
                fillColor: 'gray',
                strokeColor: 'white',
                strokeWeight: 5,
                strokeOpacity: 1,
              }}
            />
          </Fragment>
        )}
        {(markers || []).map((el, i) => (
          <Marker
            position={el}
            key={`${el.lat}-${el.lng}`}
            icon={{
              url: el.bestMatch ? PinBestMatch : Pin,
              scaledSize: new window.google.maps.Size(27, 35),
            }}
            /* onClick={() => toggleInfoWindow(`${el.locationId}-${el.partnerId}`)} */
            onClick={() =>
              toggleBookingDetails({
                selectedVisit: results[i],
                healthServiceData,
              })
            }
          >
            {/* {infoWindowSelected === `${el.locationId}-${el.partnerId}` && (
              <InfoBox
                key={`${el.partnerId}-${el.locationId}`}
                options={{
                  pane: 'overlayLayer',
                  pixelOffset: new window.google.maps.Size(-175, -45),
                  alignBottom: true,
                  boxStyle: {
                    zIndex: -1,
                    overflow: 'unset',
                    width: '350px',
                  },
                  closeBoxURL: '',
                  enableEventPropagation: true,
                }}
              >
                <SearchCard
                  key={`${el.partnerId}-${el.locationId}`}
                  locationId={el.locationId}
                  partner={partnersData[el.partnerId]}
                  distance={el.distanceInKm}
                  price={el.price}
                  availabilities={el.availabilities}
                  isInfoWindow
                  onClick={() =>
                    toggleBookingDetails({
                      selectedVisit: results[i],
                      healthServiceData,
                    })
                  }
                  formName="bookingDetails"
                  selectedVisit={selectedVisit}
                />
              </InfoBox>
            )} */}
          </Marker>
        ))}
        {!userPosition && (
          <SpinnerWrapper>
            <SpinnerComponent />
          </SpinnerWrapper>
        )}
      </GoogleMap>
    </Fragment>
  );
}
const GeoLocationShape = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

GoogleMapExample.propTypes = {
  onMapMounted: PropTypes.func.isRequired,
  radius: PropTypes.number.isRequired,
  markers: PropTypes.arrayOf(PropTypes.shape(GeoLocationShape)),
  userPosition: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
  }),
  toggleInfoWindow: PropTypes.func.isRequired,
  fitBounds: PropTypes.func.isRequired,
  onNearBy: PropTypes.func,
  infoWindowSelected: PropTypes.string,
  zoom: PropTypes.number,
  partnersData: PropTypes.shape({}),
  toggleBookingDetails: PropTypes.func.isRequired,
  healthServiceData: PropTypes.shape({}).isRequired,
  searchListData: PropTypes.shape({}).isRequired,
  selectedVisit: PropTypes.shape({}).isRequired,
};

GoogleMapExample.defaultProps = {
  infoWindowSelected: null,
  markers: null,
};

export default GoogleMapExample;

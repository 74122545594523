import React from 'react';

const AonIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 70 30" {...props}>
    <path
      d="M24.214 0l4.043 29.23h-6.403L19.008 8.817l-.068-.044-4.84 9.297h5.18l.804 5.42H11.3l-3.005 5.74H1.142l2.805-5.74H0l2.757-5.42h4.036L16.25 0h7.963zM70 6.778L66.121 29.23h-6.413l-4.84-11.282c-.74 3.74-1.322 7.542-1.975 11.282H46.44c.245-1.723.584-3.484.74-5.184-2.084 3.717-5.826 5.893-9.996 5.824-3.04-.038-6.755-1.96-8.449-4.467l-1.658-11.736c1.476-3.941 5.535-7.004 9.681-7.345 4.213-.248 7.826 1.565 10.266 5.103.874 1.292 1.342 2.741 1.656 4.26l1.585-8.907h6.284l4.955 11.351c.69-3.76 1.367-7.544 1.972-11.35H70zm-28.85 7.206c-1.255-1.586-3.273-2.085-5.108-1.474-1.547.432-2.757 2.132-3.271 3.535-.924 2.453.129 5.81 1.803 6.846 1.136.704 2.448 1.088 3.8.679 1.364-.453 2.711-1.562 3.314-2.947.873-1.972 1.033-4.939-.537-6.639z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default AonIcon;

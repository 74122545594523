import _Dropzone from 'react-dropzone';
import styled from 'styled-components';
import EditIcon from 'images/icons/edit';
import { CloseBordered } from 'utils/icons';
import _avatar from '../FilePreview/Avatar';

export const AvatarInputWrap = styled.div`
  position: relative;
  max-width: 110px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
`;

export const Dropzone = styled(_Dropzone)`
  width: auto;
  height: auto;
`;

export const RedPointEdit = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  bottom: 10px;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 10px;
  width: 30px;
`;

export const EditIconAvatar = styled(EditIcon)`
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const CloseButton = styled(CloseBordered)`
  background: #fff;
  position: absolute;
  top: 10px;
  right: 7px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
`;

export const Avatar = styled(_avatar)`
  border: 10px solid #fff;
  box-sizing: content-box;
`;

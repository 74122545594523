import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import {
  goToStepAction,
  saveCurrentPracticeDataResponse,
  cleanPartenersDataOnLogout,
  getCurrentPracticeRequest,
  disableLoadingStatus,
  setContractNeededDuringFill,
  saveInvoiceCitiesResponse,
  setDataLostErrorAction,
} from './actions';

const { localStorage, sessionStorage } = window;
const jwtToken = () =>
  localStorage.getItem('jwt_my_care') ||
  sessionStorage.getItem('jwt_my_care') ||
  null;

const initialState = fromJS({
  loading: false,
  currentStep: 0,
  completedStep: 0,
  wizardIsOpen: false,
  data: null,
  changedSections: null,
  reason: null,
  status: '',
  invoiceCities: [],
  contractNeededDuringFill:
    (jwtToken() && jwtDecode(jwtToken()).partnerSelfRegistered) || false,
  dataLostError: null,
});

export default handleActions(
  {
    [saveInvoiceCitiesResponse]: (state, { data }) =>
      state.set('invoiceCities', data),
    [disableLoadingStatus]: state => state.set('loading', false),
    [getCurrentPracticeRequest]: state => state.set('loading', true),
    [goToStepAction]: (state, { step, wizardIsOpen }) =>
      state
        .set('currentStep', step)
        .set(
          'wizardIsOpen',
          typeof wizardIsOpen === 'boolean'
            ? wizardIsOpen
            : state.toJS().wizardIsOpen,
        ),
    [saveCurrentPracticeDataResponse]: (
      state,
      {
        data: {
          completedStep,
          reason,
          status,
          currentStep,
          wizardIsOpen,
          changedSections,
          contractNeededDuringFill,
          ...data
        },
      },
    ) =>
      state
        .set('reason', reason || state.get('reason') || reason)
        .set('loading', false)
        .set('data', (!isEmpty(data) && data) || state.get('data') || null)
        .set('changedSections', fromJS(changedSections) || null)
        .set('currentStep', currentStep || state.get('currentStep'))
        .set('completedStep', completedStep || state.get('completedStep'))
        .set(
          'contractNeededDuringFill',
          contractNeededDuringFill || state.get('contractNeededDuringFill'),
        )
        .set(
          'wizardIsOpen',
          typeof wizardIsOpen !== 'boolean'
            ? state.toJS().wizardIsOpen
            : wizardIsOpen,
        )
        .set('status', status || state.get('status')),
    [cleanPartenersDataOnLogout]: state =>
      state
        .set('loading', false)
        .set('data', null)
        .set('currentStep', null)
        .set('completedStep', null)
        .set('status', null),
    [setContractNeededDuringFill]: state =>
      state.set(
        'contractNeededDuringFill',
        (jwtToken() && jwtDecode(jwtToken()).partnerSelfRegistered) || false,
      ),
    [setDataLostErrorAction]: (state, { error }) =>
      state.set('dataLostError', error),
  },
  { initialState },
);

import React from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import { EmptyStar, FullStar } from './assets';
import { RatingContainer } from './styles';

const convertRating = rating => rating && rating.split('/');

function StarRating({ rating, size, starColor, maxRating, ...props }) {
  const [ratingValue, maxRatingValue] = convertRating(rating);
  return (
    <RatingContainer starColor={starColor}>
      <Rating
        emptySymbol={<EmptyStar height={size} />}
        fullSymbol={<FullStar height={size} />}
        initialRating={parseInt(ratingValue, 10)}
        stop={parseInt(maxRatingValue, 10) || maxRating}
        {...props}
      />
    </RatingContainer>
  );
}

StarRating.propTypes = {
  rating: PropTypes.string,
  readonly: PropTypes.bool,
  size: PropTypes.number,
  starColor: PropTypes.string,
  maxRating: PropTypes.number,
};

StarRating.defaultProps = {
  rating: '0/5',
  readonly: true,
  size: 24,
  starColor: '#E7E71C',
  maxRating: 5,
};

export default StarRating;

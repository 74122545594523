import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'web.shared.FormErrors.required',
    defaultMessage: 'Required',
  },
  invalidEmailAddress: {
    id: 'web.shared.FormErrors.invalidEmailAddress',
    defaultMessage: 'Invalid email addres',
  },
  onlyNumber: {
    id: 'web.shared.FormErrors.onlyNumber',
    defaultMessage: 'In this field you can only enter numbers',
  },
  greaterThanZero: {
    id: 'web.shared.FormErrors.greaterThanZero',
    defaultMessage: 'Must be greater than zero.',
  },
  mustBeInteger: {
    id: 'web.shared.FormErrors.mustBeInteger',
    defaultMessage: 'Must be an integer.',
  },
  atMaxTwoDecimals: {
    id: 'web.shared.FormErrors.atMaxTwoDecimals',
    defaultMessage: 'Must have at max two decimals.',
  },
  minChars: {
    id: 'web.shared.FormErrors.minChars',
    defaultMessage: 'Minimum be {min} characters or more',
  },
  maxChars: {
    id: 'web.shared.FormErrors.maxChars',
    defaultMessage: 'Must be {length} characters or less',
  },
  numberInFieldError: {
    id: 'web.shared.FormErrors.numberInFieldError',
    defaultMessage: 'Field {name} can have letters only',
  },
  isInvalid: {
    id: 'web.shared.FormErrors.isInvalid',
    defaultMessage: 'is invalid',
  },
  amountGreaterThan: {
    id: 'web.shared.FormErrors.amountGreaterThan',
    defaultMessage: 'the amount must be greater than {amount}',
  },
  amountNotLessThan: {
    id: 'web.shared.FormErrors.amountNotLessThan',
    defaultMessage: 'the amount must be at least {amount}',
  },
  toAfterBeforeError: {
    id: 'web.shared.FormErrors.toAfterBeforeError',
    defaultMessage: '{label} must be after the {name}',
  },
  noEmpty: {
    id: 'web.shared.FormErrors.noEmpty',
    defaultMessage: '{name} must not be empty',
  },
  range: {
    id: 'web.shared.FormErrors.range',
    defaultMessage: 'The {name} must be between {min} and {max} characters',
  },
  numberRange: {
    id: 'web.shared.FormErrors.numberRange',
    defaultMessage: '{name} must be between {min} and {max}',
  },
  lengthError: {
    id: 'web.shared.FormErrors.lengthError',
    defaultMessage: 'Needs to be {length} characters long',
  },
  specificAddress: {
    id: 'web.shared.FormErrors.specificAddress',
    defaultMessage: 'Enter a more specific address',
  },
  invalidPecAddress: {
    id: 'web.shared.FormErrors.invalidPecAddress',
    defaultMessage: 'Invalid pec addres',
  },
  passwordMismatch: {
    id: 'web.shared.FormErrors.passwordMismatch',
    defaultMessage: 'The passwords are not the same',
  },
  notInRange: {
    id: 'web.shared.FormErrors.notInRange',
    defaultMessage: 'The value must be in the upper range',
  },
  notEqual: {
    id: 'web.shared.FormErrors.notEqual',
    defaultMessage: 'The value not equal to {fieldComparison}',
  },
  passwordNotMatchingCriteria: {
    id: 'web.shared.FormErrors.passwordNotMatchingCriteria',
    defaultMessage:
      'The password does not match the following criteria. It has to contain lowercase letters, capital letters, numbers and symbols.',
  },
  minimumYear: {
    id: 'web.shared.FormErrors.minimumYear',
    defaultMessage: 'The year cannot be less then {minimumYear}',
  },
  greaterThan: {
    id: 'web.shared.FormErrors.greaterThan',
    defaultMessage: 'The value must be greater then {greaterThanValue}',
  },
  multipleOf: {
    id: 'web.shared.FormErrors.multipleOf',
    defaultMessage: 'The value must be multiple of {multipleOfValue}',
  },
  alreadyUsed: {
    id: 'web.shared.FormErrors.alreadyUsed',
    defaultMessage: 'Value already used',
  },
});

import React from 'react';

const PracticeIcon = props => (
  <svg viewBox="0 0 18 24" width={18} height={24} {...props}>
    <title>Pratice Icon</title>
    <g data-name="Livello 2">
      <g fill="currentColor">
        <path d="M17.85 6.15L11.71 0H0v24h18V6.29zM12 1.71L16.29 6H12zM17 23H1V1h10v6h6z" />
        <path d="M3 14h12v1H3zM3 17h12v1H3zM3 20h12v1H3z" />
      </g>
    </g>
  </svg>
);

export default PracticeIcon;

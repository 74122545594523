import { defineMessages } from 'react-intl';

export default defineMessages({
  showingResults: {
    id: 'web.aon.components.Tables.showingResults',
    defaultMessage: 'Showing results',
  },
  rowPerPage: {
    id: 'web.aon.components.Tables.rowPerPage',
    defaultMessage: 'Row per page',
  },
  of: {
    id: 'web.aon.components.Tables.of',
    defaultMessage: 'of',
  },
  previousPage: {
    id: 'web.aon.components.Tables.previousPage',
    defaultMessage: 'Prev',
  },
  nextPage: {
    id: 'web.aon.components.Tables.nextPage',
    defaultMessage: 'Next',
  },
});

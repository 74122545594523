import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';
import { Text, ModalTitle, Button } from './styles';
import messages from './messages';

function DestructiveOperationConfirmModal({
  analytics,
  handleSubmit,
  isOpen,
  onClosed,
}) {
  return (
    <Modal isOpen={isOpen} toggle={onClosed} size="sm" centered>
      <ModalBody>
        <ModalTitle>
          <FormattedMessage
            {...messages.areYouSureYouWantToProceedWithTheCancellation}
          />
        </ModalTitle>

        <Text>
          <FormattedMessage {...messages.thisActionIsFinalAndCannotBeUndone} />
        </Text>

        <Button
          color="primary"
          size="lg"
          type="submit"
          onClick={handleSubmit}
          analytics={analytics.submit}
        >
          <FormattedMessage {...messages.confirmDestructiveOperation} />
        </Button>

        <Button
          size="lg"
          type="cancel"
          outline
          onClick={onClosed}
          analytics={analytics.cancel}
        >
          <FormattedMessage {...messages.noWait} />
        </Button>
      </ModalBody>
    </Modal>
  );
}

DestructiveOperationConfirmModal.propTypes = {
  analytics: PropTypes.shape({
    submit: PropTypes.shape({}).isRequired,
    cancel: PropTypes.shape({}).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func.isRequired,
};

DestructiveOperationConfirmModal.defaultProps = {
  isOpen: false,
};

export default DestructiveOperationConfirmModal;

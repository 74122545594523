import React from 'react';

const CommunicationsAll = props => (
  <svg viewBox="0 0 512.013 512.013" width={18} height={24} {...props}>
    <title>CommunicationsAll Icon</title>
    <g fill="currentColor">
      <path d="M372.653 244.726l22.56 22.56 112-112c6.204-6.241 6.204-16.319 0-22.56l-112-112-22.56 22.72 84.8 84.64H.013v32h457.44l-84.8 84.64zM512.013 352.086H54.573l84.8-84.64-22.72-22.72-112 112c-6.204 6.241-6.204 16.319 0 22.56l112 112 22.56-22.56-84.64-84.64h457.44v-32z" />
    </g>
  </svg>
);

export default CommunicationsAll;

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  compose,
  withStateHandlers,
  branch,
  lifecycle,
  withHandlers,
  renderComponent,
} from 'recompose';
import { createStructuredSelector } from 'reselect';
import { change } from 'redux-form';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { selectFetchHeaders } from 'components/shared/selectors';
import { loadRemoteAttachmentById } from 'utils/Api/attachments';
import AvatarInput from './AvatarInput';

const mapStateToProps = createStructuredSelector({
  fetchHeaders: selectFetchHeaders,
});

const RemoteAvatarInput = compose(
  connect(
    mapStateToProps,
    { change },
  ),
  withStateHandlers(
    {
      image: '',
      isLoading: true,
    },
    {
      handleDrop: () => dropped => ({ image: dropped[0] }),
      resetImage: () => () => ({ image: '' }),
      showLoader: () => (isLoading = true) => ({ isLoading }),
      setAttachment: () => ({ url }) => ({
        image: url,
      }),
      showDefault: () => () => ({
        image: '',
      }),
    },
  ),
  withHandlers({
    loadRemoteAttachment: ({
      avatarId,
      fetchHeaders,
      setAttachment,
      showDefault,
      showLoader,
    }) => () => {
      loadRemoteAttachmentById(avatarId, fetchHeaders)
        .then(setAttachment)
        .catch(showDefault)
        .then(() => showLoader(false));
    },
  }),
  lifecycle({
    componentDidMount() {
      const { avatarId, loadRemoteAttachment, showLoader } = this.props;
      if (avatarId) {
        loadRemoteAttachment();
      } else {
        showLoader(false);
      }
    },
    componentDidUpdate({ avatarId: oldAvatarId }) {
      const { avatarId, loadRemoteAttachment } = this.props;
      if (avatarId && oldAvatarId !== avatarId) {
        loadRemoteAttachment();
      }
    },
  }),
  branch(({ isLoading }) => isLoading, renderComponent(LoadingSpinner)),
)(AvatarInput);

RemoteAvatarInput.propTypes = {
  avatarId: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  input: PropTypes.shape({}).isRequired,
};

export default RemoteAvatarInput;

import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { appLocales } from '../../../i18n';
import { localesResponse } from '../actions';

export function* getLocales() {
  const url = `locales`;

  try {
    yield put(showLoading());
    const { data } = yield call(fetchWrapper, { url });

    const remoteLocales = Object.keys(data)
      .filter(remoteLocale =>
        appLocales.includes(remoteLocale.replace('_', '-')),
      )
      .map(locale => ({
        value: locale,
        label: data[locale],
      }));
    yield put(localesResponse({ remoteLocales }));
  } catch (e) {
    yield put(pushError({ text: 'getProfileError' }));
  } finally {
    yield put(hideLoading());
  }
}

import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { profileSummaryResponse } from '../actions';

export function* getProfileSummaryData() {
  const url = `partners/me/summary`;

  try {
    yield put(showLoading());
    const { data } = yield call(fetchWrapper, { url });
    yield put(profileSummaryResponse({ data }));
  } catch ({ err, response }) {
    yield put(pushError({ text: 'getProfileSummaryDataError' }));
  } finally {
    yield put(hideLoading());
  }
}

import { createAction } from 'redux-actions-helpers';

export const generateCookiePolicyCookieAction = createAction(
  'APP/GENERATE_COOKIE_POLICY_COOKIE',
);

export const setCookiePolicyConsentAction = createAction(
  'APP/SET_COOKIE_POLICY_CONSENT',
  ({ consent }) => ({ consent }),
);

export const setGoogleAnalyticsInitializedAction = createAction(
  'APP/SET_GA_INITIALIZED',
);

export const initializeGoogleAnalyticsAction = createAction(
  'APP/INITIALIZE_GA',
);

export const getUnreadCommunicationsCountAction = createAction(
  'APP/GET_UNREAD_COMMUNICATIONS',
);

export const setUnreadCommunicationsCountAction = createAction(
  'APP/SET_UNREAD_COMMUNICATIONS',
  ({ communicationsCount }) => ({ communicationsCount }),
);

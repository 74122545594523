import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { Form } from 'reactstrap';
import { Row, Button, Modal } from '@components';
import Avatar from 'components/shared/Avatar';
import {
  PageWrapper,
  Container,
  Wrapper,
  VideoConferenceWrapper,
  Col,
  ConnectionTime,
  BackButton,
  LeftWrapper,
  RightWrapper,
  LeftInnerWrapper,
  HealthService,
  NotesWrapper,
  NotesHeader,
  NotesBody,
  NotesFooter,
  CloseNotes,
  NotesButton,
  Input,
} from './styles';
import messages from './messages';
import HangupModal from './HangupModal';
import SocketErrorModal from './SocketErrorModal';
import TimeExpiredModal from './TimeExpiredModal';
import HelpVideomeetingContent from '../BookingAction/HelpVideomeeting';
import { CloseButton } from '../BookingAction/styles';

function Videomeeting({
  videomeetingData,
  isHangupModalOpen,
  toggleHangupModal,
  isSocketErrorModalOpen,
  isTimeExpiredModalOpen,
  toggleSocketErrorModal,
  toggleTimeExpiredModal,
  videomeetingUrl,
  toggleShowNotes,
  confirmHangup,
  cancelHangup,
  handleSubmit,
  showNotes,
  showTimer,
  isMobile,
  goBack,
  openHelpModal,
  toggleHelpModal,
}) {
  const {
    patientFirstName,
    patientLastName,
    profilePicture,
    healthServiceName,
  } =
    videomeetingData || {};

  return (
    <PageWrapper mobile={isMobile}>
      <Container fluid mobile={isMobile}>
        <Wrapper showNotes={showNotes} mobile={isMobile}>
          <Form onSubmit={handleSubmit}>
            <Row
              paddingBottom={16}
              paddingTop={16}
              style={{ backgroundColor: '#f2f2f2' }}
            >
              <Col md={12} className="wrapper">
                <LeftWrapper mobile={isMobile}>
                  <BackButton
                    onClick={() =>
                      videomeetingUrl ? toggleHangupModal() : goBack()
                    }
                    mobile={isMobile}
                  />
                  <Avatar
                    attachmentId={profilePicture}
                    firstName={patientFirstName}
                    lastName={patientLastName}
                    alt="photo profile"
                    size={40}
                  />
                  <LeftInnerWrapper mobile={isMobile}>
                    <HealthService mobile={isMobile}>
                      {healthServiceName}
                    </HealthService>
                    <FormattedMessage
                      {...messages.patientName}
                      values={{
                        patient: `${patientFirstName} ${patientLastName}`.trim(),
                      }}
                    />
                  </LeftInnerWrapper>
                </LeftWrapper>

                <RightWrapper>
                  {!isMobile ? (
                    <Fragment>
                      <ConnectionTime showNotes={showNotes}>
                        {showTimer > 0 && (
                          <FormattedMessage
                            {...messages[
                              showTimer === 1
                                ? 'videomeetingConnectionTimeOne'
                                : 'videomeetingConnectionTime'
                            ]}
                            values={{ count: showTimer }}
                          />
                        )}
                      </ConnectionTime>
                      <Button
                        outline
                        size="md"
                        width="auto"
                        onClick={toggleShowNotes}
                      >
                        <FormattedMessage {...messages.videomeetingNotes} />
                      </Button>
                    </Fragment>
                  ) : (
                    <NotesButton onClick={toggleShowNotes} />
                  )}
                </RightWrapper>
              </Col>
            </Row>
            {/* )} */}
            <Row>
              <Col
                md={12}
                paddingLeft={isMobile ? '0' : null}
                paddingRight={isMobile ? '0' : null}
              >
                <VideoConferenceWrapper showNotes={showNotes} mobile={isMobile}>
                  <iframe
                    title="Videomeeting"
                    className="vcFrame"
                    src={videomeetingUrl}
                    height="100%"
                    frameBorder="0"
                    allow="camera; microphone"
                    allowFullScreen
                  />
                </VideoConferenceWrapper>
              </Col>
            </Row>
            <NotesWrapper showNotes={showNotes} mobile={isMobile}>
              <NotesHeader>
                <FormattedMessage {...messages.videomeetingNotesTitle} />
                <CloseNotes onClick={toggleShowNotes} />
              </NotesHeader>
              <NotesBody mobile={isMobile}>
                <Field
                  type="textarea"
                  size="lg"
                  mobile={isMobile}
                  component={Input}
                  name="videomeetingMemo"
                  label=""
                />
              </NotesBody>
              <NotesFooter mobile={isMobile}>
                <Button color="primary" size="md" type="submit" width="153px">
                  <FormattedMessage {...messages.videomeetingButtonSave} />
                </Button>
              </NotesFooter>
            </NotesWrapper>
          </Form>
        </Wrapper>

        <Modal isOpen={openHelpModal} toggle={toggleHelpModal}>
          <CloseButton onClick={toggleHelpModal} />
          <HelpVideomeetingContent toggleModal={toggleHelpModal} />
        </Modal>
        <HangupModal
          isOpen={isHangupModalOpen}
          confirmHangup={confirmHangup}
          cancelHangup={cancelHangup}
        />
        <SocketErrorModal
          isOpen={isSocketErrorModalOpen}
          confirm={() => {
            toggleSocketErrorModal();
            goBack();
          }}
        />
        <TimeExpiredModal
          isOpen={isTimeExpiredModalOpen}
          confirm={toggleTimeExpiredModal}
        />
      </Container>
    </PageWrapper>
  );
}

Videomeeting.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  videomeetingData: PropTypes.shape({}).isRequired,
  isHangupModalOpen: PropTypes.bool.isRequired,
  confirmHangup: PropTypes.func.isRequired,
  cancelHangup: PropTypes.func.isRequired,
  toggleHangupModal: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  videomeetingUrl: PropTypes.string,
  showNotes: PropTypes.bool.isRequired,
  toggleShowNotes: PropTypes.func.isRequired,
  showTimer: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  isSocketErrorModalOpen: PropTypes.bool.isRequired,
  isTimeExpiredModalOpen: PropTypes.bool.isRequired,
  toggleSocketErrorModal: PropTypes.func.isRequired,
  toggleTimeExpiredModal: PropTypes.func.isRequired,
  openHelpModal: PropTypes.bool.isRequired,
  toggleHelpModal: PropTypes.func.isRequired,
};

Videomeeting.defaultValues = {
  videomeetingUrl: null,
  isMobile: false,
};

export default Videomeeting;

import styled from 'styled-components';
import { Tooltip as _tooltip } from 'reactstrap';

export const Tooltip = styled(_tooltip)`
  background: transparent;
  max-width: none;

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    position: relative;
    text-align: left;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
    border-radius: 0 0 25px 25px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.35);
    padding: 20px;
    max-width: none !important;
    z-index: 1;
  }

  .tooltip.show {
    opacity: 1;
  }

  .arrow:before {
    top: -2px;
    border: none;
    width: 1rem;
    height: 1rem;
    background: transparent;
    transform: rotate(45deg);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.35);
    z-index: -1;
  }

  .arrow:after {
    position: absolute;
    content: '';
    border: none;
    width: 1rem;
    height: 1rem;
    background: ${({ theme }) => theme.white};
    transform: rotate(45deg);
    top: -2px;
    z-index: 2;
  }
`;

export const SwitchWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  margin-left: 10px;
  font-weight: 600;
`;

export const TooltipWrapper = styled.div``;

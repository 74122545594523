import { fromJS } from 'immutable';
import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { handleActions } from 'redux-actions-helpers';
import {
  loginRequest,
  loginResponse,
  logoutRequest,
  logoutResponse,
  registerRequest,
  refreshTokenSuccess,
  refreshTokenFail,
  loadedPrivacyPolicy,
  loadedTermsOfUse,
  updateTermsResponse,
  setPartnerStatus,
  setLegalDocuments,
  setPartnerFirstApprovalReviewed,
  setRedirectUrlPostLoginAction,
  getGeoLocationRequest,
  getGeoLocationResponse,
} from './actions';

const { localStorage, sessionStorage } = window;
const jwtToken = localStorage.getItem('jwt_my_care') || sessionStorage.getItem('jwt_my_care') || null;

const refreshToken =
  localStorage.getItem('refresh_token_my_care') || sessionStorage.getItem('refresh_token_my_care') || null;

const isImpersonated = (!!Cookies.get('impersonate_token') && !!Cookies.get('impersonate_refresh_token')) || false;

const isAuthenticated = isImpersonated || (!!jwtToken && !!refreshToken) || false;

const initialState = fromJS({
  loading: false,
  isAuthenticated,
  isImpersonated,
  userId: null,
  changePassToken: null,
  jwt:
    Cookies.get('impersonate_token') ||
    sessionStorage.getItem('jwt_my_care') ||
    localStorage.getItem('jwt_my_care') ||
    null,
  refreshToken:
    Cookies.get('impersonate_refresh_token') ||
    sessionStorage.getItem('refresh_token_my_care') ||
    localStorage.getItem('refresh_token_my_care') ||
    null,
  partnerStatus: null,
  privacy: null,
  privacyNeedUpdate: false,
  tos: null,
  tosNeedUpdate: false,
  impersonatedBy: Cookies.get('impersonate_token') ? jwtDecode(Cookies.get('impersonate_token')).impersonatedBy : null,
  partnerFirstApprovalReviewed: null,
  geolocation: {
    latitude: null,
    longitude: null,
  },
});

export default handleActions(
  {
    [loginRequest]: (state) => state.set('loading', true),
    [logoutRequest]: (state) => state.set('loading', true),
    [registerRequest]: (state) => state.set('loading', true),
    [loginResponse]: (state, { data }) =>
      state
        .set('isAuthenticated', data.isAuthenticated)
        .set('userId', data.id)
        .set('changePasswordModal', data.changePasswordModal)
        .set('changePassToken', data.token)
        .set('sendPasswordEmailModal', false)
        .set('jwt', data.jwt)
        .set('refreshToken', data.refreshToken)
        .set('loading', false)
        .set('partnerStatus', data.partnerStatus)
        .set('privacyNeedUpdate', data.privacyNeedUpdate)
        .set('tosNeedUpdate', data.tosNeedUpdate),
    [setLegalDocuments]: (state, { data }) =>
      state
        .set('privacyNeedUpdate', data.privacy_partners === 'needs_update')
        .set('tosNeedUpdate', data.tos_partners === 'needs_update'),
    [refreshTokenSuccess]: (state, { data }) =>
      state.set('jwt', data.accessToken).set('refreshToken', data.refreshToken),
    [refreshTokenFail]: () => initialState,
    [setPartnerStatus]: (state, { partnerStatus, firstApprovalReviewed }) =>
      state.set('partnerStatus', partnerStatus).set('partnerFirstApprovalReviewed', firstApprovalReviewed),
    [setPartnerFirstApprovalReviewed]: (state) => state.set('partnerFirstApprovalReviewed', true),
    [logoutResponse]: (state) =>
      state.set('isAuthenticated', false).set('jwt', null).set('refreshToken', null).set('partnerStatus', null),
    [loadedPrivacyPolicy]: (state, { data }) => state.set('privacy', fromJS(data)),
    [loadedTermsOfUse]: (state, { data }) => state.set('tos', fromJS(data)),
    [updateTermsResponse]: (state, { dataType }) =>
      state.set(dataType === 'privacy' ? 'privacyNeedUpdate' : 'tosNeedUpdate', false),
    [getGeoLocationRequest]: (state) => state.set('loading', true),
    [getGeoLocationResponse]: (state, { latitude, longitude }) =>
      state.set('geolocation', fromJS({ latitude, longitude })),
    [setRedirectUrlPostLoginAction]: (state, { url }) => state.set('redirectUrlPostLogin', url),
  },
  { initialState },
);

import styled, { css } from 'styled-components';
import { Card as _card } from 'reactstrap';
import _slider from 'react-slick';
import omit from '../../utils/omitProps';
import _col from '../Col';
import { ArrowRight as _ar } from '../../icons';

export const Card = styled(omit(_card, ['isInfoWindow']))`
  border-radius: 0;
  cursor: pointer;
  text-align: left;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  padding-top: 15px;
  > .card-body {
    padding-top: 0;
  }

  ${({ isInfoWindow }) =>
    isInfoWindow &&
    css`
      margin: 0;
      max-width: 350px;
      box-shadow: none;
    `};
`;

export const ImgWrapper = styled.div`
  display: inline-block;
  margin-top: 1.25rem;
`;

export const RemoteFilePreviewWrapper = styled.div`
  display: inline-block;
  margin-top: 1.25rem;
  height: 50px;
  width: 50px;
  overflow: hidden;
`;

export const TitleWrapper = styled.div`
  display: inline-block;
  margin-top: 0.6rem;
  font-size: 1.4rem;
`;

export const Title = styled.div`
  
  color: #01463a;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
`;

export const Rating = styled.div`
  margin: 5px 0 10px;
`;

export const Distance = styled.div`
  display: inline-block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.gray600};

  b {
    color: ${({ theme }) => theme.gray1000};
    font-weight: 700;
  }
`;

export const Price = styled.div`
font-style: normal;
  text-align: right;
  font-size: 13px;
  font-weight: ${({ discount, bestMatch }) =>  discount || bestMatch ? "bold" : "100"};
  color: ${({ theme }) =>  theme.black};

  & span {
    font-style: normal;
    font-weight: ${({ discount, bestMatch }) =>  discount || bestMatch ? "bold" : "100"};
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.gray1000};
    margin-left: 3px;
  }
`;

export const Img = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Time = styled.div`

  font-size: 1.4rem;
  padding: 0 5px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.primary : theme.gray200};
  background-color: ${({ theme, selected }) => {
    if (selected) return theme.primary;
    return theme.white;
  }};
  ${({ theme, selected }) =>
    selected &&
    css`
      color: ${theme.white};
    `};
  height: 30px;
  line-height: 1;
  margin: 0 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Slider = styled(_slider)`
  svg {
    color: ${({ theme }) => theme.black} !important;
    font-size: 10px;
    
  }
  .slick-prev,
  .slick-next {
    width: 10px;
    height: 10px;
  }
  .slick-prev {
    transform: rotate(180deg);
    top: 10px;
    left: -15px;
  }
  .slick-next {
    top: 15px;
    right: -15px;
  }
`;

export const Col = styled(omit(_col, ['maxWidth', 'widthFlex']))`
  ${({ maxWidth }) =>
    !!maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
  ${({ widthFlex }) =>
    !!widthFlex &&
    css`
      flex: 0 0 ${widthFlex};
      max-width: ${widthFlex};
    `};
`;

export const ArrowRight = styled(omit(_ar, ['currentSlide', 'slideCount']))``;

export const BestMatchBadge = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  padding-top: 2px;
  text-align: center;
`;

export const Badge = styled.div`
 /*  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.3rem;
  line-height: 24px;
  padding: 0px 15px;
  margin: 10px 0 15px;
  max-width: 70px; */

  border: 1px solid;
  border-color: rgb(255,235,231);
  border-radius: 8px;
  background-color: rgb(255,235,231);
  color: rgb(255,119,97);
  padding: 1px 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.city{
    margin-left: 5px;
  }
`;

export const RowCard = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-right: 15px;
margin-left: 25px;
`;
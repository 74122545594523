import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import * as Cookies from 'js-cookie';
import {
  setCookiePolicyConsentAction,
  setGoogleAnalyticsInitializedAction,
  setUnreadCommunicationsCountAction,
} from './actions';

const initialState = fromJS({
  googleAnalyticsInitialized: false,
  cookiePolicyConsent: Cookies.get('cookiePolicyConsent') || null,
  unreadCommunicationsCount: 0,
});

export default handleActions(
  {
    [setCookiePolicyConsentAction]: (state, { consent }) =>
      state.set('cookiePolicyConsent', consent),
    [setGoogleAnalyticsInitializedAction]: state =>
      state.set('googleAnalyticsInitialized', true),
    [setUnreadCommunicationsCountAction]: (state, { communicationsCount }) =>
      state.set('unreadCommunicationsCount', communicationsCount),
  },
  { initialState },
);

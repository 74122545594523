import React from 'react';

const NoShow = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <title>CTA-NoShow</title>
    <g fill="currentColor">
      <path d="M13.89 15.73h.01-.01zM25.64 19.36a11.48 11.48 0 0 0-.22-9.22.5.5 0 1 0-.9.42 10.48 10.48 0 0 1 .34 8L20.27 14l7.65-7.65a.5.5 0 0 0 0-.7.5.5 0 0 0-.71 0l-7.64 7.64-7.65-7.64a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7l.19.19H11a4.11 4.11 0 1 0 4.11 4.11v-.4L18.86 14l-2.94 2.94a8 8 0 0 0-2-1.21A7.73 7.73 0 0 0 5 18.09 10.51 10.51 0 0 1 20 5.74a.51.51 0 0 0 .68-.2.5.5 0 0 0-.21-.68 11.5 11.5 0 1 0 4.76 15.47l2 2a.5.5 0 0 0 .71 0 .5.5 0 0 0 0-.7zM11 13.72a3.11 3.11 0 1 1 3.11-3.11A3.11 3.11 0 0 1 11 13.72zm11.42 8.7a10.48 10.48 0 0 1-14.84 0 10.31 10.31 0 0 1-2.22-3.26.49.49 0 0 0 .13-.16A6.69 6.69 0 0 1 11 16.17a6.62 6.62 0 0 1 2.52.49 6.47 6.47 0 0 1 1.67 1l-4 4a.5.5 0 0 0 0 .7.5.5 0 0 0 .71 0l7.65-7.64 4.87 4.87a10.41 10.41 0 0 1-2 2.83z" />
    </g>
  </svg>
);

export default NoShow;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Select } from '@components';
function SelectHours({
  fromSlots,
  updateToSlots,
  toSlots,
  labelFrom,
  labelTo,
  names,
  fromInput,
  toInput,
}) {
  const [fromName, toName] = names;
  return (
    <Fragment>
      <Col xs={12} sm={4}>
        <Select
          name={fromName}
          label={labelFrom}
          options={fromSlots}
          callbackOnChange={updateToSlots}
          input={fromInput.input}
          meta={fromInput.meta}
        />
      </Col>
      <Col xs={12} sm={4}>
        <Select
          name={toName}
          label={labelTo}
          options={toSlots}
          input={toInput.input}
          meta={fromInput.meta}
        />
      </Col>
    </Fragment>
  );
}

SelectHours.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  fromInput: PropTypes.shape({}),
  toInput: PropTypes.shape({}),
  labelFrom: PropTypes.string.isRequired,
  labelTo: PropTypes.string.isRequired,
  fromSlots: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  toSlots: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  updateToSlots: PropTypes.func.isRequired,
};

SelectHours.defaultProps = {};

export default SelectHours;

import React from 'react';
import {
  compose,
  withProps,
  withHandlers,
  lifecycle,
  withStateHandlers,
  branch,
  renderNothing,
} from 'recompose';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { withGoogleMap } from 'react-google-maps';
import { getUserLocation } from '../../utils/Api/geolocation';

import Maps from './Maps';

const refs = {
  map: undefined,
};

const MinMax = (markers, property) => {
  let min = markers[0][property];
  let max = markers[0][property];

  // eslint-disable-next-line no-plusplus
  for (let i = 1, len = markers.length; i < len; i++) {
    const value = markers[i][property];
    min = value < min ? value : min;
    max = value > max ? value : max;
  }

  return [min, max];
};

function getBoundsFromLatLng(lat, lng, radius) {
  const bounds = {
    topLeft: {
      lat: lat - 0.009 * radius,
      lng: lng - 0.009 * radius,
    },
    bottomRight: {
      lat: lat + 0.009 * radius,
      lng: lng + 0.009 * radius,
    },
  };
  return bounds;
}

export default compose(
  withStateHandlers(
    {
      infoWindowSelected: null,
    },
    {
      setUserPosition: () => userPosition => ({
        userPosition,
      }),
      toggleInfoWindow: () => value => ({
        infoWindowSelected: value || null,
      }),
    },
  ),
  withProps(({ lat, lng, userPosition, height }) => ({
    // Ho dovuto spreddare userPosition per far renderizzare meglio il componente :(
    userPosition: (lat && lng && { lat, lng }) || userPosition,
    containerElement: (
      <div
        style={{
          height: height || `${window.innerHeight - 80}px`,
          width: '100%',
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  })),
  withHandlers({
    fitBounds: ({ markers, userPosition, radius }) => () => {
      const getCircleLatLng = getBoundsFromLatLng(
        userPosition.lat,
        userPosition.lng,
        radius / 1000,
      );

      const markerWithBounds = [].concat(
        markers,
        getCircleLatLng.topLeft,
        getCircleLatLng.bottomRight,
      );

      if (markers) {
        const lats = MinMax(markerWithBounds, 'lat');
        const lngs = MinMax(markerWithBounds, 'lng');

        const bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(lats[0], lngs[0]),
          new window.google.maps.LatLng(lats[1], lngs[1]),
        );

        if (refs.map) refs.map.fitBounds(bounds);
      }
    },
  }),
  withHandlers({
    onMapMounted: () => ref => {
      refs.map = ref;
    },
    getLocation: ({ setUserPosition }) => async () => {
      const position = await getUserLocation();

      setUserPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { onNearBy, userPosition, getLocation } = this.props;
      if (!userPosition && !onNearBy) getLocation();
    },
    componentDidUpdate({ markers: prevMarkers, fitBounds }) {
      if (!isEqual(this.props.markers, prevMarkers)) fitBounds();
    },
  }),
  withGoogleMap,
  branch(({ userPosition }) => isNil(userPosition), renderNothing),
)(Maps);

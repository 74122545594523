import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { saveCurrentPracticeDataResponse } from 'containers/PartnersRegistration/actions';
import {
  profileRequest,
  profileResponse,
  cleanProfileDataOnLogout,
  editProfile,
  locationChange,
  profileSummaryRequest,
  profileSummaryResponse,
  partnerProfileResponse,
  getIbanDataRequest,
  getIbanDataResponse,
} from './actions';

const initialState = fromJS({
  data: null,
  isEditingProfile: false,
  loading: false,
  profileSummary: null,
  partnerData: null,
  tmpIbanData: null,
});

export default handleActions(
  {
    [profileRequest]: state => state.set('loading', true),
    [profileResponse]: (state, { data }) => state.set('data', data),
    [partnerProfileResponse]: (state, { partnerData }) =>
      state.set('loading', false).set('partnerData', partnerData),
    [cleanProfileDataOnLogout]: state =>
      state.set('loading', false).set('data', null),
    [editProfile]: (state, { data }) => state.set('isEditingProfile', data),
    [saveCurrentPracticeDataResponse]: state =>
      state.set('isEditingProfile', false),
    [locationChange]: state => state.set('isEditingProfile', false),
    [profileSummaryRequest]: state => state.set('loading', true),
    [profileSummaryResponse]: (state, { data: { data } }) =>
      state.set('loading', false).set('profileSummary', data),
    [getIbanDataRequest]: state => state.set('loading', true),
    [getIbanDataResponse]: state => state.set('loading', false),
  },
  { initialState },
);

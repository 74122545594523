import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Route } from 'react-router-dom';
import { ContentCol, NavWrapper, TabContent, NavItem } from './styles';
import NavLink from '../NavLink';

function Tabs({
  match: { url, path },
  tabs,
  styleOutput,
  direction,
  big,
  sidebarSize,
  contentSize,
}) {
  return (
    <Fragment>
      <Row>
        <Col md={direction === 'vertical' ? `${sidebarSize}` : '12'}>
          <NavWrapper
            vertical={direction === 'vertical'}
            tabs
            type={styleOutput}
          >
            {tabs.map(
              ({
                tabPath,
                tabName,
                tabLink,
                exact,
                componentProps,
                disabled,
                icon: Icon,
              }) => (
                <NavItem key={tabPath} type={styleOutput} direction={direction}>
                  <NavLink
                    type={styleOutput}
                    big={big}
                    direction={direction}
                    to={`${url !== '/' ? url : ''}/${tabLink || tabPath}`}
                    tabPath={tabPath || ''}
                    exact={exact}
                    isEdit={(componentProps || {}).isEdit || false}
                    activeLine={!big}
                    disabled={disabled}
                  >
                    {Icon && <Icon />}
                    {tabName}
                  </NavLink>
                </NavItem>
              ),
            )}
          </NavWrapper>
        </Col>
        <ContentCol
          direction={direction}
          md={direction === 'vertical' ? `${contentSize}` : '12'}
        >
          <TabContent>
            {tabs.map(
              ({
                tabPath,
                component: Component,
                componentProps,
                defaultRoute,
                edit,
                editComponent,
                exact = true,
              }) => (
                <Fragment key={tabPath}>
                  {defaultRoute && (
                    <Route
                      exact
                      path={path !== '/' ? path : ''}
                      render={props => (
                        <Component {...componentProps} {...props} />
                      )}
                    />
                  )}
                  <Route
                    exact={exact}
                    path={`${path !== '/' ? path : ''}/${tabPath}`}
                    render={props => (
                      <Component {...componentProps} {...props} />
                    )}
                  />
                  {edit && (
                    <Route
                      exact
                      path={`${path !== '/' ? path : ''}/${tabPath}/edit`}
                      component={editComponent}
                    />
                  )}
                </Fragment>
              ),
            )}
          </TabContent>
        </ContentCol>
      </Row>
    </Fragment>
  );
}

Tabs.propTypes = {
  match: PropTypes.shape({}).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  styleOutput: PropTypes.string,
  direction: PropTypes.string,
  big: PropTypes.bool,
  sidebarSize: PropTypes.number,
  contentSize: PropTypes.number,
};

Tabs.defaultProps = {
  direction: 'horizontal',
};

export default Tabs;

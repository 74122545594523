import React from 'react';
import PropTypes from 'prop-types';

const MyNoShowRequest = ({ colored, ...props }) => (
  <svg viewBox="0 0 172.3 172.3" width="1em" height="1em" {...props}>
    <title>MENU-NoShow Requests</title>
    <path
      stroke={colored ? '#e11c23' : 'currentColor'}
      strokeMiterlimit="10"
      strokeWidth="4px"
      fill="none"
      d="M117.94,54.73c-8.24,0-16.48,2-27.46-11-11,13-24.71,16.47-38.44,11M85,87.68A33,33,0,1,0,52,54.73,32.95,32.95,0,0,0,85,87.68Zm22-5.49c13,6.49,22,20,22,38.44v22H41.05v-22c0-18.42,9-32,22-38.44"
    />
    <circle
      stroke={colored ? '#e11c23' : 'currentColor'}
      strokeMiterlimit="10"
      strokeWidth="4px"
      fill="none"
      cx="84.76"
      cy="84.76"
      r="82.76"
    />
    <line
      stroke={colored ? '#e11c23' : 'currentColor'}
      strokeMiterlimit="10"
      strokeWidth="4px"
      fill="none"
      x1="22.68"
      y1="26.35"
      x2="140.62"
      y2="144.29"
    />
  </svg>
);

MyNoShowRequest.propTypes = {
  colored: PropTypes.bool,
};

MyNoShowRequest.defaultProps = {
  colored: null,
};

export default MyNoShowRequest;

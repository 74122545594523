import React from 'react';

const QRcode = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <title>CTA-Voucher</title>
    <g fill="currentColor">
      <path d="M20.5 21h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5zM10 20h10V10H10z" />
      <path d="M12 12h6v6h-6zM24.5 10a.5.5 0 0 1-.5-.5V6h-3.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM24.5 25h-4a.5.5 0 0 1 0-1H24v-3.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5zM9.5 25h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0V24h3.5a.5.5 0 0 1 0 1zM5.5 10a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6v3.5a.5.5 0 0 1-.5.5z" />
    </g>
  </svg>
);

export default QRcode;

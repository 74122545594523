import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ color, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 20 26" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      d="M18.9883 4.94245L14.1837 0.143245C14.0915 0.0515094 13.9669 0 13.8369 0H0.490566C0.219774 0 0 0.219774 0 0.490566V25.5094C0 25.7802 0.219774 26 0.490566 26H18.6415C18.9123 26 19.1321 25.7802 19.1321 25.5094V5.28977C19.1321 5.15928 19.0806 5.03468 18.9883 4.94245ZM17.4573 4.90566H14.2264V1.67479L17.4573 4.90566ZM0.981132 25.0189V0.981132H13.2453V5.39623C13.2453 5.66702 13.4651 5.88679 13.7358 5.88679H18.1509V25.0189H0.981132Z"
      fill="#494949"
    />
    <path
      d="M8.64645 22.3536C8.84171 22.5488 9.15829 22.5488 9.35355 22.3536L12.5355 19.1716C12.7308 18.9763 12.7308 18.6597 12.5355 18.4645C12.3403 18.2692 12.0237 18.2692 11.8284 18.4645L9 21.2929L6.17157 18.4645C5.97631 18.2692 5.65973 18.2692 5.46447 18.4645C5.2692 18.6597 5.2692 18.9763 5.46447 19.1716L8.64645 22.3536ZM8.5 8L8.5 22L9.5 22L9.5 8L8.5 8Z"
      fill="#494949"
    />
  </svg>
);

Download.propTypes = {
  color: PropTypes.string,
};

export default Download;

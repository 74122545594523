import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getGeoLocationResponse } from '../actions';

const getGeolocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error),
    );
  });

export function* getGeolocationData() {
  yield put(showLoading());
  try {
    const {
      coords: { latitude, longitude },
    } = yield call(getGeolocation);
    yield put(getGeoLocationResponse({ latitude, longitude }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Get geolocation error > ', e);
  } finally {
    yield put(hideLoading());
  }
}

import { APIClientPU } from '@common/common/utils';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import {
  CreateNewPricelistSandboxRequestPayload,
  CreateNewPricelistSandboxPayload,
  GetAllPricelistsRequestPayload,
  GetAllPricelistsSuccessPayload,
  GetSinglePricelistRequestPayload,
  GetSinglePricelistSpecialtyRequestPayload,
  UpdatePricelistSandboxRequestPayload,
  UpdatePricelistSandboxSuccessPayload,
  DownloadFileTemplatePayload,
  UploadTemplateFileRequestPayload,
  GetAllPricelistsSandboxRequestPayload,
  GetAllPricelistsSandboxSuccessPayload,
  DeletePricelistSandboxRequestPayload,
  DeletePricelistSandboxSuccessPayload,
  DownloadFileTemplateRequestPayload,
  GetNotApprovedVenuesListRequestPayload,
  GetNotApprovedVenuesListSuccessPayload,
  Filters,
} from './types';

export async function getAllPricelists({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetAllPricelistsRequestPayload): Promise<GetAllPricelistsSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'location',
          operator: 'contains', //in and pass array
          value: currentTextSearch,
        },
      ]
    : [];

  const domainFilter =
    popoverFilters?.domain !== undefined
      ? popoverFilters?.domain && {
          field: 'aliasDomain',
          operator: 'eq',
          value: popoverFilters?.domain,
        }
      : null;

  const statusFilter =
    popoverFilters?.status !== undefined
      ? popoverFilters?.status.length > 0 && {
          field: 'status',
          operator: 'in',
          value: popoverFilters?.status,
        }
      : null;

  const filtersBy = !isEmpty(popoverFilters) ? [domainFilter, statusFilter] : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(searchTextFilter) || !isEmpty(filtersBy)
      ? {
          filter: [...searchTextFilter, ...without(filtersBy, undefined, null, false)],
        }
      : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetAllPricelistsSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllPricelists - Error: ', error);
    throw error;
  }
}

export async function getNotApprovedVenuesList({
  currentTextSearch = '',
}: GetNotApprovedVenuesListRequestPayload): Promise<GetNotApprovedVenuesListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/availableLocations`,
      {
        ...allFilters,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getNotApprovedVenuesList - Error: ', error);
    throw error;
  }
}

export async function getSinglePricelist({ pricelistId }: GetSinglePricelistRequestPayload): Promise<Response> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/${pricelistId}`,
    );

    return response.data;
  } catch (error) {
    console.error('getSinglePricelist - Error: ', error);
    throw error;
  }
}

export async function getSinglePricelistSpecialty({
  pricelistId,
  page,
  itemsPerPage,
  currentTextSearch,
  filters,
}: GetSinglePricelistSpecialtyRequestPayload): Promise<GetAllPricelistsSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? {
        value: currentTextSearch,
      }
    : {};
  let filterArray: Filters[] = [{ operator: 'eq', field: 'dummyDoctor', value: true }];
  if (filters) {
    if (filters.specialityId && filters.specialityId.length > 0) {
      filterArray = [...filterArray, { operator: 'in', field: 'specialityId', value: filters.specialityId }];
    }
    if (filters.originalPrice) {
      if (filters.originalPrice.from) {
        filterArray = [
          ...filterArray,
          {
            operator: 'gteq',
            field: 'originalPrice',
            value:
              typeof filters.originalPrice.from === 'string'
                ? parseFloat(filters.originalPrice.from)
                : filters.originalPrice.from,
          },
        ];
      }
      if (filters.originalPrice.to) {
        filterArray = [
          ...filterArray,
          {
            operator: 'lteq',
            field: 'originalPrice',
            value:
              typeof filters.originalPrice.to === 'string'
                ? parseFloat(filters.originalPrice.to)
                : filters.originalPrice.to,
          },
        ];
      }
    }
    if (filters.affiliatedPrice) {
      if (filters.affiliatedPrice.from) {
        filterArray = [
          ...filterArray,
          {
            operator: 'gteq',
            field: 'affiliatedPrice',
            value:
              typeof filters.affiliatedPrice.from === 'string'
                ? parseFloat(filters.affiliatedPrice.from)
                : filters.affiliatedPrice.from,
          },
        ];
      }
      if (filters.affiliatedPrice.to) {
        filterArray = [
          ...filterArray,
          {
            operator: 'lteq',
            field: 'affiliatedPrice',
            value:
              typeof filters.affiliatedPrice.to === 'string'
                ? parseFloat(filters.affiliatedPrice.to)
                : filters.affiliatedPrice.to,
          },
        ];
      }
    }
  }
  const filterList = filterArray
    ? {
        filter: [...filterArray],
      }
    : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/${pricelistId}/services/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...searchTextFilter,
        ...filterList,
      },
    );

    return response.data as GetAllPricelistsSuccessPayload;
  } catch (error) {
    console.error('getSinglePricelistSpecialty - Error: ', error);
    throw error;
  }
}

// SANDBOX PRICELISTS

export async function createNewPricelistSandbox({
  locationUUID,
}: CreateNewPricelistSandboxRequestPayload): Promise<CreateNewPricelistSandboxPayload> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox`,
      {
        locationUUID,
      },
    );

    return response.data;
  } catch (error) {
    console.error('createNewPricelistSandbox - Error: ', error);
    throw error;
  }
}

export async function updatePricelistSandbox({
  pricelistId,
  status,
  services,
}: UpdatePricelistSandboxRequestPayload): Promise<UpdatePricelistSandboxSuccessPayload> {
  try {
    const response = await APIClientPU.put(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/${pricelistId}`,
      {
        status,
        services,
      },
    );

    return response.data;
  } catch (error) {
    console.error('updatePricelistSandbox - Error: ', error);
    throw error;
  }
}

export const loadDoctorForVenue = async (id: string) => {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/locations/${id}/doctors/filter`,
    );

    return response.data;
  } catch (error) {
    console.error('loadDoctorForVenue - Error: ', error);
    throw error;
  }
};

export async function downloadExcelTemplateFile({
  pricelistId,
}: DownloadFileTemplateRequestPayload): Promise<DownloadFileTemplatePayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/${pricelistId}/spreadsheet`,
    );

    return response.data;
  } catch (error) {
    console.error('downloadExcelTemplateFile - Error: ', error);
    throw error;
  }
}

export async function uploadExcelTemplateFile({
  pricelistId,
  mimeType,
  content,
}: UploadTemplateFileRequestPayload): Promise<Response> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/${pricelistId}/spreadsheet`,
      {
        mimeType,
        content,
      },
    );

    return response.data;
  } catch (error) {
    console.error('uploadExcelTemplateFile - Error: ', error);
    throw error;
  }
}

export async function getAllPricelistsSandbox({
  page = 1,
  itemsPerPage = 10,
  sortBy = [],
  popoverFilters = {},
}: GetAllPricelistsSandboxRequestPayload): Promise<GetAllPricelistsSandboxSuccessPayload> {
  const venueFilter =
    popoverFilters?.venues !== undefined
      ? popoverFilters?.venues && {
          field: 'locationUUID',
          operator: 'in',
          value: popoverFilters?.venues,
        }
      : null;

  const dateFromFilter =
    popoverFilters?.dateFrom !== undefined
      ? popoverFilters?.dateFrom && {
          field: 'updatedAt',
          operator: 'gteq',
          value: popoverFilters?.dateFrom,
        }
      : null;

  const statusFilter =
    popoverFilters?.status !== undefined
      ? popoverFilters?.status.length > 0 && {
          field: 'status',
          operator: 'in',
          value: popoverFilters?.status,
        }
      : null;

  const filtersBy = !isEmpty(popoverFilters) ? [venueFilter, dateFromFilter, statusFilter] : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters = !isEmpty(filtersBy)
    ? {
        filter: [...without(filtersBy, undefined, null, false)],
      }
    : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllPricelistsSandbox - Error: ', error);
    throw error;
  }
}

export async function getSinglePricelistSandbox({
  pricelistId,
  includeAllStates = false,
}: GetSinglePricelistRequestPayload): Promise<Response> {
  try {
    //console.log(includeAllStates)
    // implement ?includeAllStates=${includeAllStates}
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/${pricelistId}?includeAllStatuses=${includeAllStates}`,
    );

    return response.data;
  } catch (error) {
    console.error('getSinglePricelistSandbox - Error: ', error);
    throw error;
  }
}

export async function deletePricelistSandbox({
  pricelistId,
}: DeletePricelistSandboxRequestPayload): Promise<DeletePricelistSandboxSuccessPayload> {
  try {
    const response = await APIClientPU.delete(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/pricelists/sandbox/${pricelistId}`,
    );

    return response.data;
  } catch (error) {
    console.error('deletePricelistSandbox - Error: ', error);
    throw error;
  }
}

import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { loginResponse } from '../actions';

export function* getGoogleLoginData(googleData) {
  const url = `auth/login`;
  const dataMap = {
    role: 'partner',
    provider: {
      name: 'google',
      token: googleData.Zi.access_token || null,
    },
  };
  try {
    yield put(showLoading());
    const { status, data } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: dataMap,
    });

    if (data.result === 'changepass') {
      yield put(pushError({ text: 'facebookFirstAccessError' }));
      yield put(loginResponse(data));
      return;
    }
    if (status === 'success') {
      data.isAuthenticated = true;
      yield put(push('/'));
      yield put(loginResponse(data));
    }

    yield put(loginResponse({ ...data, googleData }));
  } catch (e) {
    const { packet, response } = e; // eslint-disable-line no-unused-vars
    if (response.status === 403) {
      yield put(
        push({
          pathname: '/register',
          state: {
            isOpenCompleteRegister: true,
            initialValues: {
              username: googleData.profileObj.givenName,
              firstName: googleData.profileObj.givenName,
              lastName: googleData.profileObj.familyName,
              file: googleData.profileObj.imageUrl,
              email: googleData.profileObj.email,
              // gender: googleData.gender,
              // birthday: googleData.birthday,
              // address: googleData.address,
              provider: {
                name: 'google',
                credentials: googleData,
              },
            },
          },
        }),
      );
    } else {
      yield put(pushError({ text: 'facebookGenericError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

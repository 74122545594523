import React from 'react';

const Documents = props => (
  <svg width="1em" height="1em" viewBox="0 0 27 22" fill="none" {...props}>
    <path
      d="M25.8705 7.12088H24.3V4.33127C24.3 3.66058 23.7483 3.11538 23.0706 3.11538H11.9317L9.68175 0H1.2294C0.5517 0 0 0.545637 0 1.21589V20.659H0.0027C0.00045 20.9096 0.0792 21.1548 0.2376 21.36C0.4581 21.6448 0.792 21.8077 1.15425 21.8077H21.2958C21.816 21.8077 22.2741 21.461 22.3983 21.0008L27 8.52236V8.44358C27 7.6772 26.5248 7.12088 25.8705 7.12088ZM0.9 1.21589C0.9 1.03609 1.04805 0.89011 1.2294 0.89011H9.21825L11.4683 4.00549H23.0701C23.2519 4.00549 23.3995 4.15147 23.3995 4.33127V7.12088H5.72895C5.66415 7.12088 5.5998 7.12622 5.53725 7.1369C5.0994 7.20944 4.73535 7.52543 4.6269 7.92732L0.9 17.9798V1.21589ZM21.541 20.732C21.5113 20.841 21.4101 20.9176 21.2958 20.9176H1.15425C1.0449 20.9176 0.981 20.8557 0.95265 20.8188C0.9243 20.7823 0.88065 20.7048 0.8973 20.6372L5.48325 8.19658C5.51295 8.08754 5.6142 8.01099 5.7285 8.01099H24.3H25.8705C26.0415 8.01099 26.0851 8.25399 26.0964 8.37549L21.541 20.732Z"
      fill="currentColor"
    />
  </svg>
);

export default Documents;

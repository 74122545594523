import styled from 'styled-components';
import Switch from 'react-switch';

// Function to convert hex format to a rgb color
function rgb2hex(rgb) {
  rgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
  );
  return rgb && rgb.length === 4
    ? `#${`0${parseInt(rgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
        rgb[2],
        10,
      ).toString(16)}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(
        16,
      )}`.slice(-2)}`
    : '';
}

export const StyledSwitch = styled(Switch).attrs({
  offHandleColor: ({ theme }) => rgb2hex(theme.gray300),
  onHandleColor: ({ theme }) => rgb2hex(theme.primary),
  offColor: ({ theme }) => rgb2hex(theme.white),
  onColor: ({ theme }) => rgb2hex(theme.white),
  activeBoxShadow: '0 0 0px 0px #000',
})`
  display: inline-block;
  & > .react-switch-bg {
    border: 1px solid
      ${({ theme, checked, disabled }) => checked ? rgb2hex(theme.primary) : rgb2hex(theme.gray300)}
  }
`;

import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, languagesResources } from './languageConfig';

const fallbackLng = 'it_IT';
const availableLanguages = ['it_IT'];
// const availableLanguages = ['en_US', 'it_IT', 'de_DE', 'fr_FR'];

const options = {
  // order and from where user language should be detected
  order: ['localStorage'],

  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/' },
};

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    detection: options,

    lng: defaultLanguage,
    fallbackLng,
    supportedLngs: availableLanguages,

    load: 'languageOnly',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: true,
    },

    backend: {
      backends: [HttpBackend, resourcesToBackend(languagesResources)],
      backendOptions: [
        {
          loadPath:
            process.env.NODE_ENV === 'development'
              ? '/locales/{{lng}}.json' //@TODO: This is just to check develop without UI broken. REMEMBER to send JSONs files to DevOPs
              : `${window?.MyCareEnvironment?.REACT_APP_BACKEND_API_BASE_URL}/translations/{{lng}}`,

          parse: (data) => {
            return data;
          },

          request: (_options, url, _payload, callback) => {
            axios
              .get(url)
              .then((res) => {
                callback(null, res.data);
              })
              .catch((err) => {
                callback(err, null);
              });
          },
        },
        {},
      ],
    },
  });

export default i18n;

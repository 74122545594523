import styled, { css } from 'styled-components';
import _icoFamily from '../../icons/family/family';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.white};
  height: ${({ size }) => size}px !important;
  text-align: center;
  width: ${({ size }) => size}px !important;
  border: 2px solid red;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &:after {
        content: '\u00A0'; /* necessary for right alignment */
      }
    `};
`;

export const Initials = styled.div`
  font-size: ${({ size }) => size / 2}px;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FamilyIcon = styled(_icoFamily)`
  font-size: 2rem;
  color: ${({ selected, theme }) => (selected ? theme.hfPrimary : theme.black)};
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { Alert } from './styles';
import messages from './messages';

function CallToActionAlert({ isOpen, descriptor, values, hideCallToAction }) {
  if (!messages[descriptor]) return null;
  return (
    <Alert color="warning" isOpen={isOpen} toggle={() => hideCallToAction()}>
      <FormattedHTMLMessage {...messages[descriptor]} values={values} />
    </Alert>
  );
}

CallToActionAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  descriptor: PropTypes.string,
  values: PropTypes.shape({}),
  hideCallToAction: PropTypes.func.isRequired,
};

CallToActionAlert.defaultProps = {
  descriptor: null,
  values: {},
};

export default CallToActionAlert;

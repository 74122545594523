import styled from 'styled-components';
import { ArrowRight } from '../../icons';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &:before {
    content: ':';
    color: ${({ theme }) => theme.black};
    font-size: 2.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -57%);
    line-height: 1;
  }
`;

export const WrapperSlick = styled.div`
  display: flex;
  font-size: 1.6rem;
  max-width: 160px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.gray300};
  border-radius: 35px;
  /* padding: 0 10px; */

  .slick-slider {
    width: 100%;

    text-align: center;
    color: ${({ theme }) => theme.gray300};
    .slick-list {
      padding: 0 0 0 10px !important;
    }

    .slick-slide {
      max-width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-current {
      /* background: rgba(255, 179, 1, 0.247058823529412); */
      color: ${({ theme }) => theme.black};
      font-size: 2.6rem;
    }
  }
`;

export const WrapperArrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;
export const ArrowUp = styled(ArrowRight)`
  font-size: 1.6rem;
  transform: rotate(-90deg);
  margin: 15px 0 20px;
`;

export const ArrowDown = styled(ArrowRight)`
  font-size: 1.6rem;
  transform: rotate(90deg);
  margin: 15px 0 20px;
`;

import { compose, withProps, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import MenuItem from './MenuItem';

export default compose(
  withRouter,
  withStateHandlers(
    { active: false },
    {
      toggleActive: ({ active }) => () => ({ active: !active }),
    },
  ),
  withProps(({ active, to, location: { pathname } }) => ({
    isActive: to === pathname || active,
  })),
)(MenuItem);

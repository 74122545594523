const fileTypesMapper = [
  {
    key: 'JPG',
    label: ' JPG',
    type: 'image/jpeg',
  },
  {
    key: 'PNG',
    label: ' PNG',
    type: 'image/png',
  },
  {
    key: 'GIF',
    label: ' GIF',
    type: 'image/gif',
  },
  {
    key: 'PDF',
    label: ' PDF',
    type: 'application/pdf',
  },
];

export default fileTypesMapper;

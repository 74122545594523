import React from 'react';
import PropTypes from 'prop-types';

const TrashIcon = ({ size = 20, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 50 50" {...props}>
    <title />
    <path
      style={{
        lineHeight: 'normal',
        textIndent: 0,
        textAlign: 'start',
        textDecorationLine: 'none',
        textDecorationStyle: 'solid',
        textDecorationColor: '#000',
        textTransform: 'none',
        blockProgression: 'tb',
        isolation: 'auto',
        mixBlendMode: 'normal',
      }}
      d="M21 2c-1.645 0-3 1.355-3 3v2h-7.846a1 1 0 0 0-.17-.014A1 1 0 0 0 9.84 7H8a1 1 0 1 0 0 2h1v36c0 1.645 1.355 3 3 3h26c1.645 0 3-1.355 3-3V9h1a1 1 0 1 0 0-2h-1.832a1 1 0 0 0-.326 0H32V5c0-1.645-1.355-3-3-3h-8zm0 2h8c.555 0 1 .445 1 1v2H20V5c0-.555.445-1 1-1zM11 9h7.832a1 1 0 0 0 .326 0h11.674a1 1 0 0 0 .326 0H39v36c0 .555-.445 1-1 1H12c-.555 0-1-.445-1-1V9zm7.984 4.986A1 1 0 0 0 18 15v25a1 1 0 1 0 2 0V15a1 1 0 0 0-1.016-1.014zm6 0A1 1 0 0 0 24 15v25a1 1 0 1 0 2 0V15a1 1 0 0 0-1.016-1.014zm6 0A1 1 0 0 0 30 15v25a1 1 0 1 0 2 0V15a1 1 0 0 0-1.016-1.014z"
      fontWeight={400}
      fontFamily="sans-serif"
      overflow="visible"
    />
  </svg>
);

TrashIcon.propTypes = {
  size: PropTypes.number,
};

export default TrashIcon;

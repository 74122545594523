import { call, put } from 'redux-saga/effects';
import ApiWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { setPartnerStatus } from '../actions';

export function* getPartnerStatus() {
  const url = 'auth/status';
  try {
    const { status, data } = yield call(ApiWrapper, { url });
    if (status === 'success') {
      const { partnerStatus, firstApprovalReviewed } = data;
      yield put(setPartnerStatus({ partnerStatus, firstApprovalReviewed }));
    }
  } catch (e) {
    yield put(pushError({ text: 'Error on get partner status.' }));
  }
}

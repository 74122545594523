import React from 'react';

const Price = props => (
  <svg width="1em" height="1em" viewBox="0 0 38 36" {...props}>
    <title>Price</title>
    <path
      d="M25.671 0C32.527 0 38 1.565 38 4.23v16.2c0 2.429-5.195 4.06-11.455 4.217v7.02c0 2.717-5.537 4.333-12.454 4.333-6.917 0-12.455-1.616-12.455-4.332V23.67c0-1.332 1.379-2.389 3.622-3.113C2.067 19.826 0 18.662 0 17.19v-8.1c0-2.61 5.809-4.131 13.343-4.225V4.23C13.343 1.565 18.816 0 25.67 0zm-1.126 26.123c-2.21 1.145-6.049 1.777-10.454 1.777-4.406 0-8.243-.632-10.455-1.777v5.545l.006.057c.18.971 4.826 2.295 10.449 2.295 5.735 0 10.454-1.377 10.454-2.352zM14.091 21.42c-5.723 0-10.455 1.338-10.455 2.25 0 .912 4.732 2.25 10.455 2.25s10.454-1.338 10.454-2.25c0-.912-4.731-2.25-10.454-2.25zM36 14.721c-2.225 1.159-6.07 1.839-10.329 1.839-4.176 0-8.017-.647-10.328-1.748v4.645l.377.013c5.265.194 9.452 1.336 10.546 3.202C31.48 22.555 36 21.208 36 20.43zM2 11.436v5.754l.006.02c.089.166 1.194.776 3.078 1.257 2.22.567 5.147.918 8.259.967v-6.119l-.501-.008c-4.652-.098-8.597-.75-10.842-1.87zm34-4.744C33.807 7.83 30.017 8.46 25.671 8.46c-4.345 0-8.136-.629-10.328-1.768v5.984c1.085.813 5.528 1.904 10.328 1.904 5.495 0 10.327-1.425 10.329-2.25zm-22.657.154C7.11 6.944 2 8.24 2 9.09c0 .849 5.11 2.146 11.343 2.244zM25.67 1.98c-5.657 0-10.328 1.336-10.328 2.25 0 .914 4.67 2.25 10.328 2.25C31.33 6.48 36 5.144 36 4.23c0-.914-4.671-2.25-10.329-2.25z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Price;

import {
  compose,
  withHandlers,
  withStateHandlers,
  withProps,
  lifecycle,
} from 'recompose';
import get from 'lodash/get';
import TimePicker from './TimePicker';

// TODO: da calcolare con il TimeSlot di Agenda
const mins = [
  { label: '00' },
  { label: '10' },
  { label: '20' },
  { label: '30' },
  { label: '40' },
  { label: '50' },
];
// ******************
const hours = [
  { label: '00' },
  { label: '01' },
  { label: '02' },
  { label: '03' },
  { label: '04' },
  { label: '05' },
  { label: '06' },
  { label: '07' },
  { label: '08' },
  { label: '09' },
  { label: '10' },
  { label: '11' },
  { label: '12' },
  { label: '13' },
  { label: '14' },
  { label: '15' },
  { label: '16' },
  { label: '17' },
  { label: '18' },
  { label: '19' },
  { label: '20' },
  { label: '21' },
  { label: '22' },
  { label: '23' },
];
const getCheckTime = (currentTime, minTime, maxTime, type, index, index2) => {
  if (!minTime && !maxTime) return true;
  const currentHour = Number(currentTime.split(':')[0]);
  const currentMin = Number(currentTime.split(':')[1]);
  const minHour = minTime ? Number(get(minTime, 'value').split(':')[0]) : null;
  const minMin = minTime ? Number(get(minTime, 'value').split(':')[1]) : null;
  const maxHour = maxTime ? Number(get(maxTime, 'value').split(':')[0]) : null;
  const maxMin = maxTime ? Number(get(maxTime, 'value').split(':')[1]) : null;
  if (type === 'hour') {
    const nextHour = Number(hours[index].label);
    if (maxTime)
      return (
        nextHour < Number(get(maxTime, 'value').split(':')[0]) ||
        (nextHour === Number(get(maxTime, 'value').split(':')[0]) &&
          currentMin <= maxMin)
      );
    return (
      nextHour > Number(get(minTime, 'value').split(':')[0]) ||
      (nextHour === Number(get(minTime, 'value').split(':')[0]) &&
        currentMin >= minMin)
    );
  } else if (type === 'min') {
    const nextMin = Number(mins[index].label);
    if (maxTime)
      return (
        nextMin <= Number(get(maxTime, 'value').split(':')[1]) ||
        (nextMin > Number(get(maxTime, 'value').split(':')[1]) &&
          currentHour < maxHour)
      );
    return (
      nextMin >= Number(get(minTime, 'value').split(':')[1]) ||
      (nextMin < Number(get(minTime, 'value').split(':')[1]) &&
        currentHour > minHour)
    );
  }
  const nextHour = Number(hours[index].label);
  const nextMin = Number(mins[index2].label);
  if (maxTime)
    return (
      nextHour < Number(get(maxTime, 'value').split(':')[0]) ||
      (nextHour === Number(get(maxTime, 'value').split(':')[0]) &&
        nextMin <= Number(get(maxTime, 'value').split(':')[1]))
    );
  return (
    nextHour > Number(get(minTime, 'value').split(':')[0]) ||
    (nextHour === Number(get(minTime, 'value').split(':')[0]) &&
      nextMin >= Number(get(minTime, 'value').split(':')[1]))
  );
};

export default compose(
  withProps(({ input: { value } }) => ({
    mins,
    hours,
    initialHoursIndex: Math.max(
      0,
      hours.findIndex(({ label }) => value.split(':')[0] === label),
    ),
    initialMinsIndex: Math.max(
      0,
      mins.findIndex(
        ({ label }) =>
          value.split(':')[1] === '59' ? '50' : value.split(':')[1] === label,
      ),
    ),
  })),
  withStateHandlers(
    ({ initialHoursIndex, initialMinsIndex }) => ({
      hour: initialHoursIndex,
      min: initialMinsIndex,
      refHour: undefined,
      refMin: undefined,
      sliding: false,
    }),
    {
      setMin: ({ hour }, { input }) => min => {
        input.onChange(`${hours[hour].label}:${mins[min].label}`);
        return { min };
      },
      setHour: ({ min }, { input }) => hour => {
        input.onChange(`${hours[hour].label}:${mins[min].label}`);
        return { hour };
      },
      minsMounted: () => ref => ({
        refMin: ref,
      }),
      hoursMounted: () => ref => ({
        refHour: ref,
      }),
      setSliding: () => slidingState => ({
        sliding: slidingState,
      }),
    },
  ),
  withHandlers({
    back: ({
      min,
      hour,
      setMin,
      setHour,
      refHour,
      refMin,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => () => {
      let backMin = min;
      let backHour = hour;
      if (min) {
        backMin -= 1;
      } else {
        backMin = mins.length - 1;
        if (hour) backHour -= 1;
        else backHour = 23;
      }
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        'both',
        backHour,
        backMin,
      );
      if (!min && checkTime) {
        refHour.slickGoTo(backHour);
        setHour(backHour);
      }
      if (checkTime) {
        refMin.slickGoTo(backMin);
        setMin(backMin);
      }
    },
    /*  backHours: ({
      min,
      hour,
      setMin,
      setHour,
      refHour,
      refMin,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => () => {
      let backMin = min;
      let backHour = hour;
      if (hour) {
        backHour -= 1;
      } 
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        'both',
        backHour,
        backMin,
      );
      if (checkTime) {
        refHour.slickGoTo(backHour);
        setHour(backHour);
      }
      console.log("BACKHOUR", {
        backMin,
        backHour,
        checkTime,
        min,
        hour,
        setMin,
        setHour,
        refHour,
        refMin,
        input: { value: currentTime },
        maxTime,
        minTime,
      })
    }, */
    backHours: ({
      hour,
      setHour,
      refHour,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => () => {
      const backHour = hour - 1;
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        'hour',
        backHour,
      );
      if (checkTime) {
        refHour.slickGoTo(backHour);
        setHour(backHour);
      }
    },
    next: ({
      min,
      hour,
      setMin,
      setHour,
      refHour,
      refMin,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => () => {
      let nextMin = min;
      let nextHour = hour;
      if (min < mins.length - 1) {
        nextMin += 1;
      } else {
        nextMin = 0;
        if (hour < 23) nextHour += 1;
        else nextHour = 0;
      }
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        'both',
        nextHour,
        nextMin,
      );
      if (min >= mins.length - 1 && checkTime) {
        refHour.slickGoTo(nextHour);
        setHour(nextHour);
      }
      if (checkTime) {
        refMin.slickGoTo(nextMin);
        setMin(nextMin);
      }
    },
     nextHours: ({
      hour,
      setHour,
      refHour,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => () => {
      const nextHour = hour + 1;
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        'hour',
        nextHour,
      );
      if (checkTime) {
        refHour.slickGoTo(nextHour);
        setHour(nextHour);
      }
    },
    goToSlide: ({
      setMin,
      setHour,
      refHour,
      refMin,
      input: { value: currentTime },
      maxTime,
      minTime,
    }) => (index, type) => {
      const checkTime = getCheckTime(
        currentTime,
        minTime,
        maxTime,
        type,
        index,
      );
      if (type === 'min' && checkTime) {
        refMin.slickGoTo(index);
        setMin(index);
      } else if (checkTime) {
        refHour.slickGoTo(index);
        setHour(index);
      }
    },
    resetSliders: ({ setMin, setHour, refHour, refMin }) => newValue => {
      refHour.slickGoTo(
        hours.findIndex(({ label }) => label === newValue.split(':')[0]),
        true,
      );
      setHour(hours.findIndex(({ label }) => label === newValue.split(':')[0]));
      refMin.slickGoTo(
        mins.findIndex(({ label }) => label === newValue.split(':')[1]),
        true,
      );
      setMin(mins.findIndex(({ label }) => label === newValue.split(':')[1]));
    },
  }),
  lifecycle({
    componentDidUpdate({ input: { value: prevValue } }) {
      const {
        input,
        setMin,
        setHour,
        refHour,
        refMin,
        minTime,
        maxTime,
        resetSliders,
      } = this.props;
      const { value } = input;

      const resetMinTime = minTime ? get(minTime, 'value') : null;
      const resetMaxTime = maxTime ? get(maxTime, 'value') : null;

      const checkTime = getCheckTime(
        prevValue,
        minTime,
        maxTime,
        'both',
        Number(value.split(':')[0]),
        Math.max(
          0,
          mins.findIndex(({ label }) => label === value.split(':')[1]),
        ),
      );

      if (value && value !== prevValue) {
        if (!this.resetTime) {
          const hourData = hours.find(
            ({ label }) => value.split(':')[0] === label,
          );
          const prevHourData = hours.find(
            ({ label }) => prevValue.split(':')[0] === label,
          );

          if (hourData !== prevHourData) {
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
            }
            this.timer = setTimeout(() => {
              if (checkTime) {
                refHour.slickGoTo(hours.indexOf(hourData), true);
                setHour(hours.indexOf(hourData));
              } else {
                this.resetTime = true;
                input.onChange(resetMinTime || resetMaxTime || prevValue);
                resetSliders(resetMinTime || resetMaxTime || prevValue);
              }
            }, 500);
          } else {
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
            }
            this.timer = setTimeout(() => {
              const minData = mins.find(({ label }) => {
                let m = Math.round(Number(value.split(':')[1]) / 10) * 10 + 100;
                if (m > 150) m = 150;
                return m.toString().substr(1) === label;
              });
              if (checkTime) {
                refMin.slickGoTo(mins.indexOf(minData), true);
                setMin(mins.indexOf(minData));
              } else {
                this.resetTime = true;
                input.onChange(resetMinTime || resetMaxTime || prevValue);
                resetSliders(resetMinTime || resetMaxTime || prevValue);
              }
              this.timer = null;
            }, 300);
          }
        } else this.resetTime = false;
      }
    },
  }),
)(TimePicker);

import { createAction } from '@reduxjs/toolkit';
import { GetPartnerWarningsResponse } from './types';

export const saveAuthUserData = createAction<any>('ACTION/SAVE_AUTH_USER_DATA');
export const resetAuthUserData = createAction('ACTION/RESET_AUTH_USER_DATA');
export const getPartnerWarningsRequest = createAction('ACTION/GET_PARTNER_WARNINGS');
export const getPartnerWarningsSuccess = createAction<GetPartnerWarningsResponse[]>(
  'ACTION/GET_PARTNER_WARNINGS_SUCCESS',
);
export const getPartnerWarningsFailure = createAction('ACTION/GET_PARTNER_WARNINGS_FAILURE');

import PUApp from '@apps/pu';
import PUFooter from '@apps/pu/components/PUFooter';
import { PrivateRoute } from '@apps/pu/routes/privateRoute';
import LoginPage from '@common/pages/LoginPage';
import MaintenancePage from '@common/pages/LoginPage/Maintenance';
import { createBrowserHistory } from 'history';
import { Redirect, Router, Switch, Route } from 'react-router-dom';

const history = createBrowserHistory();

const ExtApp = () => {
  return (
    <>
      <Router history={history}>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route exact path="/maintenance" component={MaintenancePage} />
          <Route
            exact
            path="/generic-error"
            render={() => {
              window.location.href = process.env.PUBLIC_URL + '/generic-error.html';
            }}
          />
          <PrivateRoute path="/pu" component={PUApp} />
          {/* <Route path="/goodme" component={goodMeApp} /> */}
          <Route exact path="/">
            <Redirect to="/pu" />
          </Route>
        </Switch>
      </Router>
      <PUFooter />
    </>
  );
};

export default ExtApp;

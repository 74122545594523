import React from 'react';

const agenda = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M6.473 5.096h11.054V1.774c0-.317.267-.574.596-.574.329 0 .595.257.595.574v3.322h3.486c.33 0 .596.257.596.574v16.556a.585.585 0 01-.596.574H1.796a.585.585 0 01-.596-.574V5.67c0-.317.267-.574.596-.574h3.486V1.774c0-.317.266-.574.595-.574.33 0 .596.257.596.574v3.322zM21.61 6.244H2.39v3.721H21.61V6.244zM2.39 11.114H21.61v10.538H2.39V11.113z"
      fillRule="evenodd"
    />
  </svg>
);

export default agenda;

import { useCallback, useEffect } from 'react';

const useAttachScriptToHTML = (url) => {
  const scriptExists = useCallback(() => {
    return document.querySelectorAll(`script[src="${url}"]`).length > 0;
  }, [url]);

  useEffect(() => {
    if (scriptExists()) return;

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [scriptExists, url]);
};

export default useAttachScriptToHTML;

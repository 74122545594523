import React from 'react';
import PropTypes from 'prop-types';
import {
  statusCompleted,
  iconMapper,
} from 'containers/PartnersRegistration/shared';
import {
  BoxContainer,
  StepNumberContainer,
  StepNameContainer,
  CProgressStepsStep,
  Wrapper,
  Round,
  Line,
} from './style';

function MyStepBox({
  stepNumber: step,
  stepName,
  goToStep,
  completedStep,
  currentStep,
  status,
  index,
  isStructure,
  isRejected,
  hasRejectedSections,
}) {
  const statusRound = status === 'edit' && isRejected ? 'rejected' : status;
  return (
    <CProgressStepsStep
      key={Number(index)}
      doPointer={completedStep >= index}
      status={status}
      onClick={() =>
        goToStep &&
        goToStep({
          step: statusCompleted({ completedStep, step, isStructure }),
          wizardIsOpen: true,
        })
      }
    >
      <BoxContainer
        onClick={() =>
          goToStep &&
          goToStep({
            step: statusCompleted({ completedStep, step }),
            wizardIsOpen: true,
          })
        }
      >
        <StepNumberContainer>{step}</StepNumberContainer>
        <StepNameContainer>{stepName}</StepNameContainer>
      </BoxContainer>
      <Wrapper>
        <Line isFirst={index === 0} />
        <Round
          isCurrent={Math.ceil(currentStep / 2) === step.key}
          completedStep={completedStep}
          index={index}
          status={statusRound}
          isRejected={isRejected}
          hasRejectedSections={hasRejectedSections}
        >
          {iconMapper[status]}
        </Round>
        <Line isLast={index === 0} />
      </Wrapper>
    </CProgressStepsStep>
  );
}

MyStepBox.propTypes = {
  index: PropTypes.number.isRequired,
  stepNumber: PropTypes.number.isRequired,
  stepName: PropTypes.node.isRequired,
  goToStep: PropTypes.any.isRequired,
  completedStep: PropTypes.number.isRequired,
  status: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  isStructure: PropTypes.bool,
  isRejected: PropTypes.bool,
  hasRejectedSections: PropTypes.bool,
};

MyStepBox.defaultProps = {
  status: undefined,
};

export default MyStepBox;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownToggle } from 'reactstrap';
import SelectArrow from '../../../Select/SelectArrow';
import messages from '../messages';
import {
  DropdownItem,
  DropdownMenu,
  ArrowContainer,
  GrayContainer,
} from './styles';

function DropDown({
  isOpen,
  toggle,
  rowShow,
  values,
  changeRowShow,
  totalCount,
}) {
  return (
    <Fragment>
      <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
        <DropdownToggle
          color="link"
          style={{
            display: 'flex',
            alignContent: 'flex-start',
            width: '6em',
            color: '#000',
            border: '1px solid #cccccc',
            paddingLeft: 5,
            marginLeft: 3,
            marginRight: 3,
            borderRadius: '50px',
          }}
        >
          {rowShow}
          <ArrowContainer>
            <SelectArrow />
          </ArrowContainer>
        </DropdownToggle>
        <DropdownMenu right>
          {values.map(el => (
            <DropdownItem key={el} onClick={() => changeRowShow(el)}>
              {el}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <GrayContainer>
        <FormattedMessage {...messages.of} />
        &nbsp;
        {totalCount}
      </GrayContainer>
    </Fragment>
  );
}

DropDown.propTypes = {
  totalCount: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  changeRowShow: PropTypes.func.isRequired,
  rowShow: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

DropDown.defaultProps = {
  isOpen: false,
};

export default DropDown;

import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from '../messageHandler/actions';
import {
  getLocationSpecialtiesFailed,
  getLocationSpecialtiesRequest,
  getLocationSpecialtiesSuccess,
  getSpecialtiesRequest,
  getSpecialtiesSuccess,
  getSpecialtiesFailed,
  getPartnerSpecialtiesRequest,
  getPartnerSpecialtiesSuccess,
  getPartnerSpecialtiesFailed,
} from './actions';
import * as SpecialtiesAPI from './apiCall';
import { GetSpecialtiesRequestPayload } from './types';

function* getAllLocationSpecialtiesSaga({ payload }: PayloadAction<GetSpecialtiesRequestPayload>) {
  try {
    const response = yield call(SpecialtiesAPI.getAllLocationSpecialties, { ...payload });
    const { data } = response;

    yield put(getLocationSpecialtiesSuccess(data));
  } catch (error: any) {
    yield put(getLocationSpecialtiesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getAllSpecialtiesSaga() {
  try {
    const response = yield call(SpecialtiesAPI.getAllSpecialties);
    const { data } = response;
    yield put(getSpecialtiesSuccess(data));
  } catch (error: any) {
    yield put(getSpecialtiesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getPartnerSpecialtiesSaga() {
  try {
    const response = yield call(SpecialtiesAPI.getPartnerSpecialties);
    const { data } = response;

    yield put(getPartnerSpecialtiesSuccess(data));
  } catch (error: any) {
    yield put(getPartnerSpecialtiesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* specialtiesSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getLocationSpecialtiesRequest.type, getAllLocationSpecialtiesSaga);
  yield takeLatest(getSpecialtiesRequest.type, getAllSpecialtiesSaga);
  yield takeLatest(getPartnerSpecialtiesRequest.type, getPartnerSpecialtiesSaga);
}

export default specialtiesSaga;

import React from 'react';

const FamilyIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
    <title>Untitled 2</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="none" cx={10} cy={10} r={9.625} />
      <g fill="currentColor">
        <path d="M7 9.8a3.15 3.15 0 1 0-3.14-3.15A3.16 3.16 0 0 0 7 9.8zm0-5.63a2.48 2.48 0 1 1 0 4.96 2.48 2.48 0 0 1 0-4.96zM14.64 11.81a2.66 2.66 0 1 0 .04-5.32 2.66 2.66 0 0 0-.04 5.32zm0-4.64a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0zm0 .67a9.34 9.34 0 0 1 8.2 13.79 4.91 4.91 0 0 0-3.2-1.84 5 5 0 0 0-3.35.8 5.83 5.83 0 0 0-4-2.65 6 6 0 0 0-6 2.91s0 .08-.07.12A9.33 9.33 0 0 1 10 .67zM1.86 14.55a5.36 5.36 0 0 1 .26-.55 5.29 5.29 0 0 1 5.37-2.58A5.41 5.41 0 0 1 12 16.84v2.27a9.3 9.3 0 0 1-10.14-4.56zm10.81 4.39v-2.1A6.31 6.31 0 0 0 12 14a4.31 4.31 0 0 1 2.93-.72 4.22 4.22 0 0 1 2.91 1.81 9.35 9.35 0 0 1-5.17 3.85z" />
      </g>
    </g>
  </svg>
);

export default FamilyIcon;

import { call, put, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { pushError } from 'containers/Alerts/actions';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { selectProfileData } from '../selectors';

export function* amendAccountData({ callback, intl, userNotes }) {
  const url = `zendesk/tickets`;

  yield put(showLoading());
  try {
    const { username } = yield select(selectProfileData());
    const message = intl.formatMessage(
      {
        id:
          'web.partner.MyProfile.GeneralDataProtection.accountReactificationZendeskMessage',
      },
      { username },
    );

    const { status } = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        subject: message,
        body: userNotes || message,
      },
    });

    if (status === 'success') {
      yield put(
        pushError({
          text: 'zendeskTicketCreatedSuccessfully',
          typeError: 'success',
        }),
      );

      if (callback) callback();
    }
  } catch ({ packet }) {
    yield put(pushError({ text: (packet || {}).message }));
  } finally {
    yield put(hideLoading());
  }
}

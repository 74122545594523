// TODO: RIFARE TUTTA LA LOGICA
import qs from 'qs';
import {
  compose,
  withHandlers,
  withStateHandlers,
  lifecycle,
  withProps,
  branch,
} from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
// TODO: importate da partners
import { makeSelectLocale } from '../../../../app/containers/LanguageProvider/selectors';
import { selectFetchHeaders } from '../../../../app/components/shared/selectors';
import { fetchWrapper } from '../../../../app/utils/Api/fetchWrapper';
// TODO: *********************
import InputAutosuggest from './InputAutosuggest';

const getSuggestionsFetcher = (
  url,
  headers,
  queryParams,
  method,
  callbackAfterFetch,
) => (filter, filterBy, filterType) => {
  if (method === 'POST') {
    return fetchWrapper(url, {
      method,
      body: JSON.stringify({ filter }),
      headers,
    }).then(res => {
      if (callbackAfterFetch) return callbackAfterFetch(res);
      return res;
    });
  }
  const paramString = qs.stringify({ filter, ...queryParams });
  if (filterType && filterBy) {
    return fetchWrapper(
      filterType === 'querystring'
        ? `${url}?${filterBy}=${filter[0].value}`
        : `${url}/${filter[0].value}`,
      {
        method: 'GET',
        headers,
      },
    ).then(
      ({ data }) => (filterType === 'querystring' ? data.results : data) || [],
    );
  }
  return fetchWrapper(`public/${url}?${decodeURI(paramString)}`, {
    method: 'GET',
    headers,
  }).then(({ data }) => data.results || []);
};

const mapStateToProps = createStructuredSelector({
  fetchHeaders: selectFetchHeaders,
  languageActive: makeSelectLocale(),
});

const mapDispatchToProps = {
  changeFieldValue: change,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(
    ({
      fetchHeaders,
      url,
      queryParams,
      method = 'GET',
      callbackAfterFetch,
    }) => ({
      fetchSuggestions: getSuggestionsFetcher(
        url,
        fetchHeaders,
        queryParams,
        method,
        callbackAfterFetch,
      ),
    }),
  ),
  branch(
    ({ filterBy }) => !!filterBy,
    compose(
      withStateHandlers(
        {
          suggestions: [],
          selectedOption: null,
          multiValue: [],
          value: null,
        },
        {
          setSuggestions: () => suggestions => ({
            suggestions: (suggestions || []).map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          }),
          onSuggestionsClearRequested: () => () => ({
            suggestions: [],
          }),
          setValue: () => ({ id, name }) => ({
            value: {
              value: id,
              label: name,
            },
            selectedOption: null,
          }),
          handleChange: (_, { input, isMulti }) => selectedOption => {
            if (isMulti) {
              const multiValue = selectedOption.map(({ value }) => value);
              input.onChange(JSON.stringify(multiValue));
              return { selectedOption, multiValue };
            }

            input.onChange(`${selectedOption.value || ''}`);
            return { selectedOption };
          },
          resetSelectedOption: () => () => ({
            selectedOption: null,
            value: null,
          }),
        },
      ),
      withHandlers({
        onSuggestionsFetchRequested: ({
          fetchSuggestions,
          setSuggestions,
          setValue,
          defaultFilters = [],
          input,
          method,
        }) => async (value, filterBy, filterType) => {
          if (!value) {
            setSuggestions([]);
            return;
          }
          const filterToUse = [
            {
              field: filterBy,
              operator: 'contains',
              value,
            },
          ];
          try {
            const results = await fetchSuggestions(
              filterToUse.concat(defaultFilters),
              filterBy,
              filterType,
            );
            const res = filterType === 'querystring' ? results[0] : results;
            if (res) {
              setValue(res);
              input.onChange(res.id);
            }
            if (filterType === 'querystring') setSuggestions(results);
            if (method === 'POST') setSuggestions(results);
          } catch (error) {
            setSuggestions([]);
          }
        },
      }),
      withHandlers({
        handleInputChange: ({
          onSuggestionsFetchRequested,
          filterBy,
          filterType,
        }) => value => {
          onSuggestionsFetchRequested(value, filterBy, filterType);
        },
        fetchById: ({
          onSuggestionsFetchRequested,
          setSuggestions,
          input,
        }) => id => {
          input.onChange(null);
          onSuggestionsFetchRequested(id, 'id', 'url');
          setSuggestions([]);
        },
      }),
      lifecycle({
        componentDidMount() {
          const { input, onSuggestionsFetchRequested } = this.props;
          if (input.value) {
            onSuggestionsFetchRequested(input.value, 'id', 'url');
          }
        },
        componentDidUpdate({ input: { value: oldValue }, lastId: oldLastId }) {
          const {
            input: { value },
            resetSelectedOption,
            fetchById,
            lastId,
            onSuggestionsFetchRequested,
            suggestions,
          } = this.props;
          if (lastId && lastId !== oldLastId) fetchById(lastId);

          if (
            value &&
            oldValue &&
            value !== oldValue &&
            suggestions.length === 0
          )
            onSuggestionsFetchRequested(value, 'id', 'url');

          if (oldValue && !value) {
            resetSelectedOption();
          }
        },
      }),
    ),
    compose(
      withStateHandlers(
        {
          suggestions: [],
          selectedOption: null,
          multiValue: [],
          value: null,
          touched: false,
        },
        {
          activateTouched: () => () => ({
            touched: true,
          }),
          setSuggestions: (_, { languageActive }) => suggestions => ({
            suggestions: (suggestions || []).map(({ id, translations }) => ({
              value: id,
              label: translations[languageActive.replace('-', '_')].name,
            })),
          }),
          onSuggestionsClearRequested: () => () => ({
            suggestions: [],
          }),
          setValue: (_, { languageActive }) => ({ id, translations }) => ({
            value: {
              value: id,
              label: translations[languageActive.replace('-', '_')].name,
            },
          }),
          handleChange: (_, { input, isMulti }) => selectedOption => {
            if (isMulti) {
              const multiValue = selectedOption.map(({ value }) => value);
              input.onChange(JSON.stringify(multiValue));
              return { selectedOption, multiValue };
            }

            input.onChange(`${selectedOption.value || ''}`);
            return { selectedOption };
          },
          resetSelectedOption: () => () => ({ selectedOption: null }),
        },
      ),
      withHandlers({
        onSuggestionsFetchRequested: ({
          fetchSuggestions,
          setSuggestions,
          setValue,
          defaultFilters = [],
        }) => async (value, filter) => {
          if (!value) {
            setSuggestions([]);
            return;
          }

          const filterToUse = [
            filter || {
              field: 'name',
              operator: 'contains',
              value,
            },
          ];
          try {
            const results = await fetchSuggestions(
              filterToUse.concat(defaultFilters),
            );

            if (filter) {
              if (results[0]) {
                setValue(results[0]);
              }
            } else {
              setSuggestions(results);
            }
          } catch (error) {
            setSuggestions([]);
          }
        },
      }),
      withHandlers({
        handleInputChange: ({ onSuggestionsFetchRequested }) => value => {
          onSuggestionsFetchRequested(value);
        },
      }),
      lifecycle({
        componentDidMount() {
          const { input, onSuggestionsFetchRequested } = this.props;
          if (input.value) {
            onSuggestionsFetchRequested(input.value, {
              operator: 'eq',
              field: 'id',
              value: input.value,
            });
          }
        },
        componentDidUpdate({ input: { value: oldValue } }) {
          const {
            input: { value },
            resetSelectedOption,
          } = this.props;

          if (oldValue && !value) resetSelectedOption();
        },
      }),
    ),
  ),
)(InputAutosuggest);

import { createSelector, createStructuredSelector } from 'reselect';

import { getAccessToken } from '@common/auth/utils/token';

export const selectAuth = () => (state) => state ? state.get('auth') : {};

export const selectJWTToken = () => createSelector(() => getAccessToken());

const selectAnalytics = (state) => state.get('analytics');

export const selectUUID = () => createSelector(selectAnalytics, (languageState) => () => languageState.get('UUID'));

const selectLanguage = (state) => state.get('language');

export const selectLocale = () => createSelector(selectLanguage, (languageState) => languageState.get('locale'));

export const selectFetchHeaders = createStructuredSelector({
  jwtToken: () => getAccessToken(),
  languageCode: selectLocale() ?? (() => 'it_IT'),
  uuid: (() => localStorage.getItem('analytics_uuid')) ?? selectUUID(),
});

export const selectPathname = () => (state) => state.getIn(['router', 'location', 'pathname']);

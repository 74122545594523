import { compose, lifecycle } from 'recompose';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import HeaderComponent from './Header';

export default compose(
  lifecycle({
    componentDidMount() {
      const {
        focusedDate,
        service,
        getAvailabilities,
        availabilities,
      } = this.props;
      if (isEmpty(availabilities) && service)
        getAvailabilities({ month: moment(focusedDate).format('YYYY-MM') });
    },
    componentDidUpdate({ focusedDate: oldFocusedDate, service: oldService }) {
      const {
        focusedDate,
        service,
        getAvailabilities,
        resetAvailabilities,
      } = this.props;

      if (service !== oldService) resetAvailabilities();

      if (
        (service !== oldService ||
          focusedDate.getMonth() !== oldFocusedDate.getMonth() ||
          focusedDate.getYear() !== oldFocusedDate.getYear()) &&
        !!getAvailabilities
      )
        getAvailabilities({ month: moment(focusedDate).format('YYYY-MM') });
    },
  }),
)(HeaderComponent);

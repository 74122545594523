import staticImages from '@apps/pu/theme/images';
import { palette } from '@apps/pu/theme/theme';
import { Flex, Box, Image, Text, Link, SimpleGrid } from '@chakra-ui/react';
import { useAuth } from '@common/auth/hooks/useAuth';
import { useInterval } from '@hooks/useInterval';
import { getPUAnalyticsUUID } from '@utils';
import parse from 'html-react-parser';
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const MaintenancePage: FC = () => {
  const { checkMaintenance, activeMaintenance } = useAuth();
  useEffect(() => {
    getPUAnalyticsUUID();
  }, []);

  useInterval(async () => {
    await checkMaintenance();
  }, Number(window.MyCareEnvironment.MAINTENANCE_CHECK_INTERVAL ?? '5') * 1000);

  if (!activeMaintenance) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <Flex height="100%" width="100%">
      <Flex bg={palette.GREY4} p={0} w="100%" justifyContent="center" alignItems="center">
        <Box
          width={{ xs: '100%', sm: '90%', xxl: '60%' }}
          maxWidth="1000px"
          p="65px"
          bg="white"
          borderRadius="20px"
          shadow="base"
        >
          <SimpleGrid columns={1} spacing={10}>
            <Box position="relative" paddingRight={{ xs: '0', sm: '25px' }}>
              <Image width="250px" objectFit="contain" src={staticImages.AONLogo} alt="AON Logo" />

              <Box mt="40px">
                <Text textStyle="h2">{activeMaintenance.titleToShow}</Text>
              </Box>

              <Box mt="10px" mb="50px">
                {parse(activeMaintenance.contentToShow)}
              </Box>

              <Box mt="10px" mb="50px">
                <Link textStyle="link" fontSize="xs" href="/login">
                  Vai alla pagina di login
                </Link>
              </Box>

              <Flex position="absolute" bottom="0" width="100%" pr="25px" justifyContent="space-between">
                <Link
                  isExternal
                  textStyle="link"
                  fontSize="xs"
                  href="https://www.privacy.aon.it/Home/DisclaimerItem/048084d0-8b9c-459c-8cbb-9752d59144a8?m=1&v=1"
                >
                  Informativa sulla privacy
                </Link>
                <Link
                  isExternal
                  textStyle="link"
                  fontSize="xs"
                  href="https://www.privacy.aon.it/Home/DisclaimerItem/f0aa646f-96f6-4f40-aae7-806e68176afe?m=1&v=1"
                >
                  Termini e Condizioni
                </Link>
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MaintenancePage;

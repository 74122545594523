import React from 'react';

const ArrowOrder = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 38" {...props}>
    <title>ArrowOrder</title>
    <path
      d="M6.5 13.5C7.05228 13.5 7.5 13.0523 7.5 12.5L7.5 4.33L10.3845 7.20647C10.7743 7.59518 11.4053 7.59474 11.7945 7.20549C12.1841 6.81586 12.1841 6.18414 11.7945 5.79451L6.95161 0.951612C6.70219 0.702193 6.29781 0.702194 6.04839 0.951612L1.20549 5.79451C0.81586 6.18414 0.815859 6.81586 1.20549 7.20549C1.59474 7.59474 2.2257 7.59518 2.61549 7.20647L5.5 4.33L5.5 12.5C5.5 13.0523 5.94771 13.5 6.5 13.5Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowOrder;

import React from 'react';
import PropTypes from 'prop-types';

const Question = ({ colored, ...props }) => (
  <svg viewBox="0 0 164.75 171.9" width="1em" height="1em" {...props}>
    <title>MENU-Question</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          stroke={colored ? '#e11c23' : 'currentColor'}
          d="M118.3,150.09H100.84a18.75,18.75,0,1,0,.2,4.48H118.3a25.8,25.8,0,0,0,25.74-25,24.62,24.62,0,0,0,20.71-24.29v-11a24.61,24.61,0,0,0-17.91-23.68V65.43C146.84,30,118.66.47,83.23,0A64.46,64.46,0,0,0,17.91,64.47v6.18A24.61,24.61,0,0,0,0,94.33v11a24.62,24.62,0,0,0,24.62,24.62H36.85V69.71H24.58a15.42,15.42,0,0,0-2.24.11V65.36c0-33,26.23-60.45,59.19-60.88a60,60,0,0,1,60.79,60v5.35c-.74,0-1.48-.11-2.24-.11H127.91v60.23h11.64A21.34,21.34,0,0,1,118.3,150.09Zm-35.95,17.3a14.28,14.28,0,1,1,13.58-9.72,14.11,14.11,0,0,1-13.55,9.72Zm-50-93.21v51.29H24.58A20.15,20.15,0,0,1,4.43,105.32v-11A20.15,20.15,0,0,1,24.58,74.18Zm100.08,0h7.75A20.15,20.15,0,0,1,160.3,94.33v11a20.15,20.15,0,0,1-20.15,20.15h-7.77Z"
        />
      </g>
    </g>
  </svg>
);

Question.propTypes = {
  colored: PropTypes.bool,
};

Question.defaultProps = {
  colored: null,
};

export default Question;

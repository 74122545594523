import React from 'react';

const Search = props => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
    <title>Search</title>
    <path
      d="M12.36 23C18.238 23 23 18.237 23 12.36c0-5.875-4.763-10.638-10.64-10.638-5.875 0-10.638 4.763-10.638 10.639C1.722 18.237 6.485 23 12.36 23zm19.776 7.919c.367.367.337.88 0 1.217-.337.337-.85.367-1.217 0L20.47 21.689a12.312 12.312 0 01-8.11 3.032C5.534 24.721 0 19.187 0 12.361 0 5.534 5.534 0 12.36 0c6.827 0 12.361 5.534 12.361 12.36 0 3.025-1.09 5.88-3.032 8.111L32.136 30.92z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default Search;

import React from 'react';
import PropTypes from 'prop-types';
import { HeroCover } from 'utils/icons';
import { Wrapper, BackgroundWrap, Content } from './styles';

function AnimatedBackgroundBottom({ children }) {
  return (
    <Wrapper>
      <Content>{children}</Content>
      <BackgroundWrap>
        <HeroCover />
      </BackgroundWrap>
    </Wrapper>
  );
}

AnimatedBackgroundBottom.propTypes = {
  children: PropTypes.node.isRequired,
};

AnimatedBackgroundBottom.defaultProps = {};

export default AnimatedBackgroundBottom;

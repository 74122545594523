import { createAction } from '@reduxjs/toolkit';
import { GetSpecialtiesRequestPayload, GetSpecialtiesSuccessPayload, GetAllSpecialtiesSuccessPayload } from './types';

export const getLocationSpecialtiesRequest = createAction<GetSpecialtiesRequestPayload>(
  'ACTION/GET_LOCATION_SPECIALTIES_REQUEST',
);
export const getLocationSpecialtiesSuccess = createAction<GetSpecialtiesSuccessPayload>(
  'ACTION/GET_LOCATION_SPECIALTIES_SUCCESS',
);
export const getLocationSpecialtiesFailed = createAction('ACTION/GET_LOCATION_SPECIALTIES_FAILED');
export const resetLocationSpecialties = createAction('ACTION/RESET_LOCATION_SPECIALTIES');

export const getSpecialtiesRequest = createAction('ACTION/GET_SPECIALTIES_REQUEST');
export const getSpecialtiesSuccess = createAction<GetAllSpecialtiesSuccessPayload>('ACTION/GET_SPECIALTIES_SUCCESS');
export const getSpecialtiesFailed = createAction('ACTION/GET_SPECIALTIES_FAILED');
export const resetSpecialties = createAction('ACTION/RESET_SPECIALTIES');

export const getPartnerSpecialtiesRequest = createAction('ACTION/GET_PARTNER_SPECIALTIES_REQUEST');
export const getPartnerSpecialtiesSuccess = createAction<GetAllSpecialtiesSuccessPayload>(
  'ACTION/GET_PARTNER_SPECIALTIES_SUCCESS',
);
export const getPartnerSpecialtiesFailed = createAction('ACTION/GET_PARTNER_SPECIALTIES_FAILED');

import { createReducer } from '@reduxjs/toolkit';
import {
  getManageStructureSuccess,
  getManageStructureFailed,
  getManageStructureRequest,
  updateManageStructureSuccess,
  updateManageStructureFailed,
  updateManageStructureRequest,
} from './actions';
import { ManageStructure } from './types';

export interface ManageStructureState {
  loading: boolean;
  manageStructure: ManageStructure;
}

const initialState: ManageStructureState = {
  loading: false,
  manageStructure: {
    name: '',
    staffContactName: '',
    email: '',
    ceo: '',
    medicalDirector: '',
    address: '',
    zipCode: '',
    cityId: '',
    phone: '',
    fax: '',
    website: '',
    mobile: '',
    tipo_studio: '',
    municipality: '',
    headquarter: {
      id: '',
      invoiceAddressId: '',
    },
  },
};

// Single Venue details

export const manageStructureReducer = createReducer(initialState, {
  [getManageStructureRequest.type]: (state) => {
    state.loading = true;
  },
  [getManageStructureSuccess.type]: (state, action) => {
    state.loading = false;
    state.manageStructure = action.payload;
  },
  [getManageStructureFailed.type]: (state) => {
    state.loading = false;
  },
  [updateManageStructureRequest.type]: (state) => {
    state.loading = true;
  },
  [updateManageStructureSuccess.type]: (state) => {
    state.loading = false;
  },
  [updateManageStructureFailed.type]: (state) => {
    state.loading = false;
  },
});

import { put, call, all } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { change } from 'redux-form';
import get from 'lodash/get';
import { getIbanDataResponse } from '../actions';

function* getCityInfo(city) {
  const cityUrl = `administrative_areas/cities?name=${city}`;
  try {
    const result = yield call(fetchWrapper, {
      url: cityUrl,
    });
    const results = get(result, 'data.results', []);
    if (result.status === 'success') return results[0];
    return null;
  } catch ({ packet, response }) {
    return null;
  }
}

export function* getIbanData({ iban, formData }) {
  const url = `bank_data?iban=${iban}`;
  const { form, fieldSwift, fieldBank, fieldAddress, fieldCity } = formData;
  try {
    const { status, data } = yield call(fetchWrapper, { url });
    if (status === 'success') {
      const cityData = yield getCityInfo(data.city);
      yield all([
        put(change(form, fieldSwift, data.bic)),
        put(change(form, fieldBank, data.bank)),
        put(change(form, fieldAddress, data.address)),
        put(change(form, fieldCity, cityData || null)),
      ]);
    }
    yield put(getIbanDataResponse());
  } catch (e) {
    yield put(pushError({ text: 'getIbanDataError' }));
  }
}

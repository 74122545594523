import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import { change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  getUserLocation,
  findAddresFromLatLng,
} from '../../utils/Api/geolocation';

import InputGeosuggest2 from './InputGeosuggest2';

const mapStateToProps = null;

const mapDispatchToProps = {
  onChangeValue: change,
};

const findZipCode = addressComponents =>
  addressComponents.find(_a => {
    const { types } = _a;
    return types.some(rowType => rowType === 'postal_code');
  });

const findCity = addressComponents =>
  addressComponents.find(_a => {
    const { types } = _a;
    return types.some(rowType => rowType === 'locality');
  });

const findAdministrativeArea = addressComponents =>
  addressComponents.find(_a => {
    const { types } = _a;
    return types.some(rowType => rowType === 'administrative_area_level_1');
  });

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(({ names, ...props }) => {
    const [geo, address] = names[0].split('.');
    const [geoPosition, position] = names[1].split('.');
    const range = names[2];
    const addressDataForm = props[geo][address];
    const positionDataForm = props[geoPosition][position];
    const rangeDataForm = props[range];

    return {
      addressDataForm,
      rangeDataForm,
      positionDataForm,
    };
  }),
  withHandlers({
    changeValues: ({ onChangeValue, addressDataForm: { input, meta } }) => (
      location,
      zipCode,
      city,
      administrativeArea,
      description,
    ) => {
      input.onChange(description);

      onChangeValue(
        meta.form,
        `${input.name.split('.')[0]}.administrativeArea`,
        administrativeArea && administrativeArea.long_name,
      );

      onChangeValue(
        meta.form,
        `${input.name.split('.')[0]}.position`,
        location,
      );

      onChangeValue(
        meta.form,
        `${input.name.split('.')[0]}.zipCode`,
        zipCode && zipCode.long_name,
      );

      onChangeValue(
        meta.form,
        `${input.name.split('.')[0]}.city`,
        city && city.long_name,
      );
    },
  }),
  withStateHandlers(
    ({ positionDataForm }) => ({
      selectedLocation:
        positionDataForm.input.value && positionDataForm.input.value.toJS
          ? positionDataForm.input.value.toJS()
          : positionDataForm.input.value,
    }),
    {
      setLocation: () => selectedLocation => ({
        selectedLocation,
      }),
      // eslint-disable-next-line
      onSuggestSelect: (_, { addressDataForm: { input }, changeValues }) => locationData => {
        if (!isEmpty(locationData)) {
          const {
            location,
            description,
            gmaps: { address_components: addressComponents },
          } = locationData;
          input.onChange(description);

          const zipCode = findZipCode(addressComponents);
          const city = findCity(addressComponents);
          const administrativeArea = findAdministrativeArea(addressComponents);

          changeValues(location, zipCode, city, administrativeArea);

          return {
            selectedLocation: location,
          };
        }
      },
    },
  ),
  withHandlers({
    onMapCreated: () => map => {
      map.setOptions({
        disableDefaultUI: true,
      });
    },
    getPosition: ({
      setLocation,
      changeValues,
      addressDataForm: { input },
    }) => async () => {
      try {
        const selectedLocation = await getUserLocation();
        const location = {
          lat: selectedLocation.coords.latitude,
          lng: selectedLocation.coords.longitude,
        };
        const {
          address_components: addressComponents,
          formatted_address: formattedAddress,
        } = await findAddresFromLatLng(location.lat, location.lng);

        input.onChange(formattedAddress);

        const zipCode = findZipCode(addressComponents);
        const city = findCity(addressComponents);

        changeValues(location, zipCode, city);

        setLocation(location);
      } catch (e) {
        return null;
      }
      return null;
    },
  }),
  withHandlers({
    onMapCreated: () => map => {
      map.setOptions({
        disableDefaultUI: true,
      });
    },
    onNearBy: ({ getPosition }) => () => {
      getPosition();
    },
  }),
)(InputGeosuggest2);

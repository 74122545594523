import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Modal } from 'components/shared/modalStyles';
import {
  Button,
  ContentWrapper,
  SubMessage,
  FooterContentWrapper,
  StatusDot,
} from './styles';
import messages from './messages';

function ModalConfirmation({
  isOpen,
  message,
  subMessage,
  hideModal,
  onConfirm,
  onCancel,
  intl,
}) {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <ContentWrapper>
          <StatusDot size="25" color="green" />
          {message}
          {subMessage && <SubMessage>{subMessage}</SubMessage>}
        </ContentWrapper>
      </ModalBody>
      <ModalFooter>
        <FooterContentWrapper>
          <Button color="primary" onClick={onConfirm || hideModal} autoFocus>
            {intl.formatMessage(messages.yes)}
          </Button>
          <Button color="secondary" onClick={onCancel || hideModal} autoFocus>
            {intl.formatMessage(messages.no)}
          </Button>
        </FooterContentWrapper>
      </ModalFooter>
    </Modal>
  );
}

ModalConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  intl: intlShape.isRequired,
};

ModalConfirmation.defaultProps = {};

export default ModalConfirmation;

import { compose, withProps, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import DatePicker from './DatePicker';
import { selectLocale } from '../selectors';

const calendarDate = value =>
  value && moment(value, 'DD/MM/YYYY', true).format('YYYY-MM-DD');

const mapStateToProps = createStructuredSelector({
  locale: selectLocale(),
});

export default compose(
  injectIntl,
  connect(mapStateToProps),
  withProps(({ locale }) => ({
    locale: (locale || ['it-IT']).split('-')[0],
  })),
  withStateHandlers(
    {
      selectedDate: null,
    },
    {
      changeDate: (_, { input }) => value => {
        input.onChange(calendarDate(value));

        return {
          selectedDate: value,
        };
      },
    },
  ),
)(DatePicker);

import styled from 'styled-components';
import originalStyles from 'react-table/react-table.css';

const defaultRowHeight = 39;

export const ReactTablesWrapper = styled.div`
  ${originalStyles};
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 40px;
  .ReactTable {
    /* RESETS */
    border: none;
    [role='rowgroup'] {
      border-bottom: 1px solid ${({ theme }) => theme.gray200};
    }
    [role='grid'] {
      overflow: visible !important;
    }
    & .showOverflow,
    & .rt-tbody {
      overflow: unset !important;
      min-height: 200px;
    }
    /* ALTEZZA RIGHE */
    .rt-thead.-header {
      height: ${({ rowHeight }) => rowHeight || defaultRowHeight}px;
      border-bottom: 1px solid ${({ theme }) => theme.gray200};
      box-shadow: none !important;
    }

    [role='row'] {
      height: ${({ rowHeight }) =>
        rowHeight !== 'auto' ? `${rowHeight || defaultRowHeight}px` : 'auto'};
      display: flex;
      align-items: center;
    }

    /* STILE HEADERS DI TABELLA */
    [role='columnheader'] {
      height: 39px;
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      color: ${({ theme }) => theme.gray300};
      border: none !important;
      font-size: 1.4rem !important;
      display: flex;
      align-items: center;
      text-align: inherit !important;
      padding-right: 15px !important;
      & * {
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        overflow: inherit;
        white-space: inherit;
        text-overflow: inherit;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        right: 8px;
        box-shadow: none;
        border-color: transparent;
      }
      &:before {
        border-width: 0 3px 6px 3px;
        border-bottom-color: ${({ theme }) => theme.gray500};
        top: 14px;
      }
      &.-sort-asc:before {
        border-bottom-color: ${({ theme }) => theme.red};
      }
      &:after {
        border-width: 6px 3px 0 3px;
        border-top-color: ${({ theme }) => theme.gray500};
        top: 22px;
      }
      &.-sort-desc:after {
        border-top-color: ${({ theme }) => theme.red};
      }
      &.disableSort {
        &:before,
        &:after {
          display: none;
        }
      }
      &.-sort-asc,
      &.-sort-desc {
        box-shadow: unset !important;
        border-top-color: ${({ theme }) => theme.gray300};
      }
      &:not(.-cursor-pointer) {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    /* STILE CONTENTUTI DI TABELLA */
    [role='gridcell'] {
      min-width: 0 !important;
      width: auto !important;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      color: ${({ theme }) => theme.black};
      border: none !important;
      font-size: 1.6rem !important;
      & a,
      & > span {
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        overflow: inherit;
        white-space: inherit;
        text-overflow: inherit;
      }
      /* ICONA PIVOT */
      & .rt-expander {
        display: block;
        position: relative;
        margin: 0 !important;
        color: transparent !important;
        user-select: none;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 50%;
          left: 45%;
          transform: translate(-50%, -50%) rotate(-45deg);
          transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          cursor: pointer;
          border: solid black;
          border-width: 0 0 2px 2px;
          display: inline-block;
          padding: 5px;
          user-select: none;
        }
        &.-open:after {
          transform: translate(-50%, -50%) rotate(135deg);
          left: 50%;
          user-select: none;
        }
      }
    }

    /* RESIZER COLONNE */
    & .rt-resizer {
      width: 6px;
      right: 0;
    }
  }
`;

import React from 'react';

const ArrowLeft = props => (
  <svg width="1em" height="1em" viewBox="0 0 12 22" {...props}>
    <title>arrow-right</title>
    <path
      d="M1.81 10.75l9.47 9.47a.75.75 0 01-1.06 1.06l-10-10a.75.75 0 010-1.06l10-10a.75.75 0 011.06 1.06l-9.47 9.47z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowLeft;

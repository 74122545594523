import { compose, withHandlers } from 'recompose';
import { event } from 'react-ga';
import withGoogleAnalyticsInfo from '../Hocs/withGoogleAnalyticsInfo';
import NavLink from './NavLink';

export default compose(
  withGoogleAnalyticsInfo,
  withHandlers({
    handleClick: ({
      analytics,
      googleAnalyticsInitialized,
      cookiePolicyConsent,
      onClick,
      disabled,
    }) => e => {
      if (disabled) e.preventDefault();
      if (
        analytics &&
        googleAnalyticsInitialized &&
        cookiePolicyConsent === 'true'
      )
        event(analytics);
      if (onClick) onClick();
    },
  }),
)(NavLink);

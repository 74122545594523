import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { profileRequest } from '../actions';

export function* setNewEmail({ data: { email, confirmEmail } }) {
  const url = `auth/email`;
  const params = { email, confirmEmail };

  try {
    yield put(showLoading());
    yield call(fetchWrapper, { url, method: 'PATCH', params });

    yield put(pushError({ text: 'changeEmailSuccess', typeError: 'success' }));

    yield put(profileRequest());
  } catch (e) {
    yield put(pushError({ text: 'changeEmailError' }));
  } finally {
    yield put(hideLoading());
  }
}

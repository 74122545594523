import i18n from '@apps/pu/i18n';
import { AppRoutes } from '@apps/pu/routes/routesList';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from '../messageHandler/actions';
import {
  createOpDataDetailsAccessesFailed,
  createOpDataDetailsAccessesRequest,
  createOpDataDetailsAccessesSuccess,
  deleteOpAccessesFailed,
  deleteOpAccessesRequest,
  deleteOpAccessesSuccess,
  editOpDataDetailsAccessesFailed,
  editOpDataDetailsAccessesRequest,
  editOpDataDetailsAccessesSuccess,
  getAllOpsAccessesFailed,
  getAllOpsAccessesRequest,
  getAllOpsAccessesSuccess,
  getOpDetailsAccessesFailed,
  getOpDetailsAccessesRequest,
  getOpDetailsAccessesSuccess,
  getOpVenuesListFailed,
  getOpVenuesListRequest,
  getOpVenuesListSuccess,
} from './actions';
import * as AccessesAPI from './apiCall';
import {
  CreateOpAccessRequestPayload,
  DeleteOpRequestPayload,
  EditOpDataAffiliationRequestPayload,
  GetAllOpsAccessesRequestPayload,
  GetOpDetailsRequestPayload,
  GetVenuesListRequestPayload,
} from './types';

function* getAllOpsAccessesSaga({ payload }: PayloadAction<GetAllOpsAccessesRequestPayload>) {
  try {
    const response = yield call(AccessesAPI.getUserAccesses, { ...payload });
    const { data } = response;

    yield put(getAllOpsAccessesSuccess(data));
  } catch (error: any) {
    yield put(getAllOpsAccessesFailed());
  }
}

function* getOpDetailsAccessesSaga({ payload }: PayloadAction<GetOpDetailsRequestPayload>) {
  try {
    const response = yield call(AccessesAPI.getOpDetailsAccesses, { ...payload });
    const { data } = response;

    yield put(getOpDetailsAccessesSuccess(data));
  } catch (error: any) {
    yield put(getOpDetailsAccessesFailed());
  }
}

function* deleteOpAccessesSaga({ payload }: PayloadAction<DeleteOpRequestPayload>) {
  try {
    const response = yield call(AccessesAPI.deleteOpAccesses, { ...payload });
    const { data } = response;

    yield put(deleteOpAccessesSuccess(data));
    yield put(
      messageHandlerSet({ message: i18n.t('web.pu.ManageAccesses.Details.OpSuccessfullyDeleted'), type: 'success' }),
    );
    yield put(push(AppRoutes.ManageAccesses));
  } catch (error: any) {
    yield put(deleteOpAccessesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* editOpDataAccessesSaga({ payload }: PayloadAction<EditOpDataAffiliationRequestPayload>) {
  try {
    const response = yield call(AccessesAPI.editOpDataAccesses, { ...payload });
    const { data } = response;

    yield put(editOpDataDetailsAccessesSuccess());
    yield put(
      getOpDetailsAccessesSuccess({
        user: data,
      }),
    );
    yield put(push(`${AppRoutes.ManageAccesses}/${payload?.operatorId}`));
    yield put(
      messageHandlerSet({ message: i18n.t('web.pu.ManageAccesses.Details.OpSuccessfullyEdited'), type: 'success' }),
    );
    yield put(push(AppRoutes.ManageAccesses));
  } catch (error: any) {
    yield put(editOpDataDetailsAccessesFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* getVenuesListSaga({ payload }: PayloadAction<GetVenuesListRequestPayload>) {
  try {
    const response = yield call(AccessesAPI.getVenuesList, { ...payload });
    const { data } = response;

    yield put(getOpVenuesListSuccess(data));
  } catch (error: any) {
    yield put(getOpVenuesListFailed());
  }
}

function* createOpAccessesSaga({ payload }: PayloadAction<CreateOpAccessRequestPayload>) {
  try {
    yield call(AccessesAPI.createOpDataAccesses, { ...payload });

    yield put(createOpDataDetailsAccessesSuccess({ userReactivation: payload.id ? true : false }));
  } catch (error: any) {
    const { message } = error?.message ?? {};
    if (message.includes('errors.partner.email_already_exists_unassigned')) {
      yield put(createOpDataDetailsAccessesFailed({ error: 'errors.partner.email_already_exists_unassigned' }));
    } else {
      yield put(createOpDataDetailsAccessesFailed({ error }));
      yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
    }
  }
}

function* accessesSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getAllOpsAccessesRequest.type, getAllOpsAccessesSaga);
  yield takeLatest(getOpDetailsAccessesRequest.type, getOpDetailsAccessesSaga);
  yield takeLatest(deleteOpAccessesRequest.type, deleteOpAccessesSaga);
  yield takeLatest(editOpDataDetailsAccessesRequest.type, editOpDataAccessesSaga);
  yield takeLatest(getOpVenuesListRequest.type, getVenuesListSaga);
  yield takeLatest(createOpDataDetailsAccessesRequest.type, createOpAccessesSaga);
}

export default accessesSaga;

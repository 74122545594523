export const isString = error => (typeof error === 'string' ? error : error.id);

export const required = value => (value ? undefined : 'required');

export const isEmail = value =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'invalidEmailAddress'
    : undefined;

export const compare = (value1, value2) =>
  value1 && value2 && value1 !== value2;

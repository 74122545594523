import { defineMessages } from 'react-intl';

export default defineMessages({
  yes: {
    id: 'web.shared.components.modalConfirmation.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'web.shared.components.modalConfirmation.no',
    defaultMessage: 'No',
  },
});

import React from 'react';

const LogoLinkedin = props => (
  <svg width="1em" height="1em" viewBox="0 0 28 25" {...props}>
    <path d="M27.53 24.87h-6.085v-8.743c0-2.288-.952-3.85-3.045-3.85-1.6 0-2.49 1.054-2.905 2.07-.156.364-.131.872-.131 1.38v9.143H9.336s.078-15.487 0-16.895h6.028v2.652c.356-1.16 2.282-2.814 5.356-2.814 3.813 0 6.81 2.43 6.81 7.663v9.394z" />
    <path
      d="M3.202 5.862h.038c2.017 0 3.273-1.296 3.273-2.933C6.475 1.26 5.257 0 3.278 0 1.297 0 0 1.264 0 2.933 0 4.57 1.26 5.862 3.202 5.862zM6.06 7.975H.694V24.87H6.06V7.975z"
      fillRule="evenodd"
    />
  </svg>
);

export default LogoLinkedin;

import React from 'react';

const Specialities = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M22.8 12c0 5.965-4.835 10.8-10.8 10.8-5.965 0-10.8-4.835-10.8-10.8C1.2 6.035 6.035 1.2 12 1.2c5.965 0 10.8 4.835 10.8 10.8zm-1.137 0A9.663 9.663 0 0012 2.337 9.663 9.663 0 002.337 12 9.663 9.663 0 0012 21.663 9.663 9.663 0 0021.663 12zM9.3 7.2a2.1 2.1 0 10.002 4.202A2.1 2.1 0 009.3 7.2zm-1.543 9.043a.596.596 0 00.845.003l7.644-7.644a.602.602 0 00-.003-.845.596.596 0 00-.845-.003l-7.644 7.644a.602.602 0 00.003.845zM14.7 12.6a2.1 2.1 0 10.002 4.202A2.1 2.1 0 0014.7 12.6z"
      fillRule="evenodd"
    />
  </svg>
);

export default Specialities;

import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { getPartnerWarningsRequest, getPartnerWarningsSuccess, getPartnerWarningsFailure } from './actions';
import * as DoctorsAPI from './apiCall';

function* getPartnerWarningsSaga() {
  try {
    const response = yield call(DoctorsAPI.getPartnerWarnings);
    //const { data } = response;

    yield put(getPartnerWarningsSuccess(response));
  } catch (error: any) {
    yield put(getPartnerWarningsFailure());
  }
}

function* authSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getPartnerWarningsRequest.type, getPartnerWarningsSaga);
}

export default authSaga;

import Spinkit from 'react-spinkit';
import styled from 'styled-components';

export const Spinner = styled(Spinkit).attrs({
  name: 'ball-spin-fade-loader',
  color: `${({ theme }) => theme.yellow}`,
})`
  transform: scale(0.5);
`;

export const SpinnerWrapper = styled.div`
  flex: 1;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

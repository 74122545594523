import { all, call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { settingResponse } from '../actions';

export function* getSettings() {
  const url = `notifications/me/settings`;
  const slaUrl = 'partners/me/settings/sla';

  try {
    yield put(showLoading());

    const [
      { data: settingsResponse },
      { data: slaSettingsResponse },
    ] = yield all([
      call(fetchWrapper, { url }),
      call(fetchWrapper, { url: slaUrl }),
    ]);

    yield put(
      settingResponse({
        ...(settingsResponse.settings || settingsResponse),
        bid: slaSettingsResponse,
      }),
    );
  } catch (e) {
    yield put(pushError({ text: 'getProfileError' }));
  } finally {
    yield put(hideLoading());
  }
}

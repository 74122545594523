import styled from 'styled-components';

export const HelpVideomeetingMsgTitleWrapper = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fontFamilySerif};
  font-size: 3.2rem;
  line-height: 35px;
  width: 50%;
  margin: 0 auto;
`;

export const HelpVideomeetingMsgMessageWrapper = styled.div`
  color: ${({ theme }) => theme.gray300};
  font-size: 1.6rem;
  width: 80%;
  margin: 0 auto 30px;
`;
export const HelpVideomeetingImgWrapper = styled.div`
  text-align: left;
`;

import styled from 'styled-components';
import { Button as _button } from '@components';
import { StatusDot as _statusDot } from 'components/shared';

export const Button = styled(_button)`
  width: auto;
  min-width: 160px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const SubMessage = styled.div`
  text-align: center;
  font-weight: 300;
  color: ${({ theme }) => theme.gray300};
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const StatusDot = styled(_statusDot)`
  margin-bottom: 25px;
`;

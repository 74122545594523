export const getUserLocation = async () =>
  new Promise((resolve, reject) => {
    const reason = {
      message: 'la geolocalizzazione NON È disponibile',
    };
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        err => {
          reason.err = err;
          reject(reason);
        },
      );
    } else {
      /* la geolocalizzazione NON È disponibile */
      reject(reason);
    }
  });

export const findAddresFromLatLng = async (lat, long) => {
  const { google } = window;
  const geocoder = await new google.maps.Geocoder();
  const latlng = await new google.maps.LatLng(lat, long);

  return new Promise((resolve, reject) =>
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0])
        return resolve(results[0]);
      return reject(status);
    }),
  );
};

import { createReducer } from '@reduxjs/toolkit';
import { IDoctorLocations } from '../doctors/types';
import {
  getAllVenuesFailed,
  getAllVenuesRequest,
  getAllVenuesSuccess,
  getInvoiceAddressesSingleVenueFailed,
  getInvoiceAddressesSingleVenueRequest,
  getInvoiceAddressesSingleVenueSuccess,
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getVenueDetailsFailed,
  getApprovedVenueDetailsRequest,
  getApprovedVenueDetailsSuccess,
  getApprovedVenueDetailsFailed,
  resetInvoiceAddressesList,
  resetSearchVenuesFilters,
  resetVenueDetailsFilters,
  setSearchVenuesFilters,
  getVenueDetailsInvoiceAddressesRequest,
  getVenueDetailsInvoiceAddressesSuccess,
  getVenueDetailsInvoiceAddressesFailed,
  putVenueDetailsRequest,
  putVenueDetailsSuccess,
  putVenueDetailsFailed,
  showReturnToVenueModal,
  getVenueAffiliationsRequest,
  getVenueAffiliationsSuccess,
  getVenueAffiliationsFailed,
  resetVenueAffiliations,
  getVenueTypesRequest,
  getVenueTypesSuccess,
  getVenueTypesFailed,
  resetVenueTypes,
  getVenueAdditionalServicesRequest,
  getVenueAdditionalServicesSuccess,
  getVenueAdditionalServicesFailed,
  resetVenueAdditionalServices,
  addNewVenueRequest,
  addNewVenueSuccess,
  addNewVenueFailed,
  resetAddNewVenueModalStatus,
  getCurrentVenuePraticeDetailsRequest,
  getCurrentVenuePraticeDetailsSuccess,
  getCurrentVenuePraticeDetailsFailed,
  resetCurrentVenuePraticeDetails,
  getAllApprovedVenuesFailed,
  getAllApprovedVenuesRequest,
  getAllApprovedVenuesSuccess,
  getAllVenuePartnersRequest,
  getAllVenuePartnersSuccess,
  getAllVenuePartnersFailed,
} from './actions';

export interface VenuesState {
  loading: boolean;
  venues: {
    name: string;
    address: string;
    agencyTypes: string;
    createdAt: string;
    id: number;
    locationId: string;
    locationName: string;
    partnerId: number;
    partnerStatus: string;
    updatedAt: null;
    additionalData?: {
      accountid?: number;
    };
  }[];
  totalCount: number;
  currentPage: number;
  venuesFilter: {
    textFilter: string;
  };
}

const initialState: VenuesState = {
  loading: false,
  venues: [],
  totalCount: 0,
  currentPage: 1,
  venuesFilter: {
    textFilter: '',
  },
};

// Single Venue details
export interface VenueDetailsState {
  loading: boolean;
  saving: boolean;
  savingStatus: string | null;
  showReturnToVenueModal: boolean;
  venueDetails:
    | {
        id: string;
        address: string;
        locationName: string;
        convenzioni: string[];
        tipo_azienda: string[];
        tipo_servizio: string[];
        responsabile_convenzionamento: string;
        descrizione_azienda: string;
        city: string;
        cityId: string;
        province: string;
        provinceId: string;
        regionId: string;
        administrativeArea: string;
        position?: {
          lat: string;
          lng: string;
        };
        email: string; // PEC???
        descrizione_long_azienda: string;
        descrizione_short_azienda: string;
        nota: string;
        vtigerTechnicalData?: {
          headquarter: number;
        };
        network: boolean;
        status: string;
        zipCode: string;
        tipo_studio: string;
        invoiceAddress?: {
          address: string;
          organizationName: string;
          vat: string;
          city: string;
          cityId: string;
          province: string;
          provinceId: string;
          np_first_name: string;
          np_last_name: string;
          fiscalCode: string;
          depotAccountName: string;
          depotIban: string;
          depotSwift: string;
          bill_street: string;
          zipCode: string;
          np_birth_place: string;
          bill_state: string;
          bill_region: string;
          regionId: string;
          amministratore: string;
          direttore_sanitario: string;
          operativePhoneNumber: string;
          bankAddress: string;
          bankCity: string;
          bankName: string;
          bill_country: string;
          id: string;
          np_birth_date: string;
          np_birth_province: string;
          np_gender: string;
          pecAddress: string;
          recipientCode: string;
          withholding_tax_application: number;
        };
        phoneNumber: string;
        phone: string;
        otherphone: string;
        operativeEmail: string;
        email1: string;
        email2: string;
        website: string;
        fax: string;
        lingue?: {
          code: string;
          value: number;
        }[];
        pagamenti?: {
          code: string;
          value: number;
        }[];
        servizi_generici?: {
          code: string;
          value: number | string;
        }[];
        medical?: {
          contatti?: {
            code: string;
            value: string;
          }[];
        };
        dental?: {
          prestazioni?: {
            code: string;
            value: number;
          }[];
        };
        fisio?: {
          prestazioni?: {
            code: string;
            value: number;
          }[];
        };
        time_table:
          | {
              time: string;
              timeFrom: string;
              timeTo: string;
              timeClosed: boolean;
            }[]
          | [];
        reason?: {
          message: string;
          sections: string[];
        };
      }
    | undefined;
  approvedVenueDetails:
    | {
        id: string;
        address: string;
        name: string;
        convenzioni: string[];
        tipo_azienda: string[];
        tipo_servizio: string[];
        responsabile_convenzionamento: string;
        descrizione_azienda: string;
        city: string;
        cityId: string;
        province: string;
        provinceId: string;
        regionId: string;
        administrativeArea: string;
        position?: {
          lat: string;
          lng: string;
        };
        email: string; // PEC???
        vtigerTechnicalData?: {
          headquarter: number;
        };
        status: string;
        zipCode: string;
        tipo_studio: string;
        invoiceAddress?: {
          additionalData?: {
            amministratore: string;
            bill_country: string;
            bill_region: string;
            bill_state: string;
            bill_street: string;
            direttore_sanitario: string;
            np_birth_date: string;
            np_birth_place: string;
            np_birth_province: string;
            np_first_name: string;
            np_gender: string;
            np_last_name: string;
            partnerId: number;
            province: string;
            provinceId: string;
            provinceShortName: string;
            regionId: string;
            uuid: string;
            withholding_tax_application: boolean;
          };
          address: string;
          organizationName: string;
          vat: string;
          city: string;
          cityId: string;
          province: string;
          provinceId: string;
          np_first_name: string;
          np_last_name: string;
          fiscalCode: string;
          depotAccountName: string;
          depotIban: string;
          depotSwift: string;
          bill_street: string;
          zipCode: string;
          np_birth_place: string;
          bill_state: string;
          bill_region: string;
          regionId: string;
          amministratore: string;
          direttore_sanitario: string;
          operativePhoneNumber: string;
          bankAddress: string;
          bankCity: string;
          bankName: string;
          bill_country: string;
          id: string;
          np_birth_date: string;
          np_birth_province: string;
          np_gender: string;
          pecAddress: string;
          recipientCode: string;
          withholding_tax_application: number;
        };
        phoneNumber: string;
        phone: string;
        otherphone: string;
        operativeEmail: string;
        email1: string;
        email2: string;
        website: string;
        fax: string;
        lingue?: {
          code: string;
          value: number;
        }[];
        pagamenti?: {
          code: string;
          value: number;
        }[];
        servizi_generici?: {
          code: string;
          value: number | string;
        }[];
        medical?: {
          contatti?: {
            code: string;
            value: string;
          }[];
        };
        dental?: {
          prestazioni?: {
            code: string;
            value: number;
          }[];
        };
        fisio?: {
          prestazioni?: {
            code: string;
            value: number;
          }[];
        };
        time_table:
          | {
              time: string;
              timeFrom: string;
              timeTo: string;
              timeClosed: boolean;
            }[]
          | [];
      }
    | undefined;
  specialtiesLoading: boolean;
  specialtiesList: {
    id: number;
    name: string;
    translated: boolean;
  }[];
  invoiceAddressesList: {
    address: string;
    amministratore: string;
    bankAddress: string;
    bankCity: string;
    bankName: string;
    bill_country: string;
    bill_region: string;
    bill_state: string;
    bill_street: string;
    city: string;
    cityId: string;
    depotAccountName: string;
    depotIban: string;
    depotSwift: string;
    direttore_sanitario: string;
    fiscalCode: string;
    id: string;
    np_birth_date: string;
    np_birth_place: string;
    np_birth_province: string;
    np_first_name: string;
    np_gender: string;
    np_last_name: string;
    organizationName: string;
    pecAddress: string;
    recipientCode: string;
    vat: string;
    withholding_tax_application: number;
    sameBillingAddress?: boolean;
    zipCode: string;
    province?: string;
    regiondId?: string;
    provinceId?: string;
  }[];
}

const initialStateVenueDetails: VenueDetailsState = {
  loading: false,
  saving: false,
  savingStatus: null,
  showReturnToVenueModal: false,
  venueDetails: undefined,
  approvedVenueDetails: undefined,
  specialtiesLoading: false,
  specialtiesList: [],
  invoiceAddressesList: [],
};

export interface InvoiceAddressesState {
  loading: boolean;
  invoiceAddresses: {
    address: string;
    amministratore: string;
    bankAddress: string;
    bankCity: string;
    bankName: string;
    bill_country: string;
    bill_region: string;
    bill_state: string;
    bill_street: string;
    city: string;
    cityId: string;
    depotAccountName: string;
    depotIban: string;
    depotSwift: string;
    direttore_sanitario: string;
    fiscalCode: string;
    id: string;
    np_birth_date: null;
    np_birth_place: string;
    np_birth_province: string;
    np_first_name: string;
    np_gender: string;
    np_last_name: string;
    organizationName: string;
    pecAddress: string;
    recipientCode: string;
    vat: string;
    withholding_tax_application: 0 | 1;
    zipCode: string;
  }[];
}

export interface AffiliationsState {
  loading: boolean;
  affiliations: {
    id: number;
    name: string;
    description: string;
    aonServices: [
      {
        id: number;
        name: string;
        description: string;
        createdAt: string;
        updatedAt: string;
      },
    ];
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface TypesState {
  loading: boolean;
  types: {
    id: number;
    name: string;
    aonServiceId: number;
    aonService: {
      id: number;
      name: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface AdditionalServicesState {
  loading: boolean;
  services: {
    id: number;
    name: string;
    description: string;
    additionalServices: [
      {
        id: number;
        code: string;
        enabled: boolean;
        aonServiceId: number;
        serviceTypeId: number;
        createdAt: string;
        updatedAt: string;
        aonServices: {
          id: number;
          name: string;
          description: string;
          createdAt: string;
          updatedAt: string;
        }[];
      },
    ];
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface AddNewVenueState {
  loading: boolean;
  showModal: boolean;
}

export interface CurrentPracticeStatus {
  loading: boolean;
  currentPracticeStatus: string | undefined;
  rejectionReason?: {
    message: string;
    sections: string[];
  };
}

export interface DoctorLocationConvention {
  loading: boolean;
  location?: IDoctorLocations[];
}

const initialStateInvoice: InvoiceAddressesState = {
  loading: false,
  invoiceAddresses: [],
};

const initialStateAffiliations: AffiliationsState = {
  loading: false,
  affiliations: [],
};

const initialStateTypes: TypesState = {
  loading: false,
  types: [],
};

const initialStateServices: AdditionalServicesState = {
  loading: false,
  services: [],
};

const initialStateAddNewVenue: AddNewVenueState = {
  loading: false,
  showModal: false,
};

const initialStateCurrentPractice: CurrentPracticeStatus = {
  loading: false,
  currentPracticeStatus: undefined,
  rejectionReason: undefined,
};

const initialStatePartnerLocation: DoctorLocationConvention = {
  loading: false,
  location: [],
};

export const allVenuesReducer = createReducer(initialState, {
  [getAllVenuesRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllVenuesSuccess.type]: (state, action) => {
    state.loading = false;
    state.venues = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
  },
  [getAllVenuesFailed.type]: (state) => {
    state.loading = false;
  },
  [getAllApprovedVenuesRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllApprovedVenuesSuccess.type]: (state, action) => {
    state.loading = false;
    state.venues = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
  },
  [getAllApprovedVenuesFailed.type]: (state) => {
    state.loading = false;
  },
  [setSearchVenuesFilters.type]: (state, action) => {
    state.venuesFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchVenuesFilters.type]: (state) => {
    state.venuesFilter.textFilter = '';
  },
});

export const venueDetailsReducer = createReducer(initialStateVenueDetails, {
  [getVenueDetailsRequest.type]: (state) => {
    state.loading = true;
  },
  [getVenueDetailsSuccess.type]: (state, action) => {
    state.loading = false;
    state.venueDetails = action.payload;
  },
  [getVenueDetailsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetVenueDetailsFilters.type]: (state) => {
    state.loading = false;
  },
  [getApprovedVenueDetailsRequest.type]: (state) => {
    state.loading = true;
  },
  [getApprovedVenueDetailsSuccess.type]: (state, action) => {
    state.loading = false;
    state.approvedVenueDetails = action.payload;
  },
  [getApprovedVenueDetailsFailed.type]: (state) => {
    state.loading = false;
  },
  [getVenueDetailsInvoiceAddressesRequest.type]: (state) => {
    state.specialtiesLoading = true;
  },
  [getVenueDetailsInvoiceAddressesSuccess.type]: (state, action) => {
    state.specialtiesLoading = false;
    state.invoiceAddressesList = action.payload;
  },
  [getVenueDetailsInvoiceAddressesFailed.type]: (state) => {
    state.specialtiesLoading = false;
  },
  [putVenueDetailsRequest.type]: (state) => {
    state.savingStatus = null;
    state.saving = true;
    state.showReturnToVenueModal = false;
  },
  [putVenueDetailsSuccess.type]: (state, action) => {
    state.saving = false;
    state.savingStatus = action.payload.status;
    state.showReturnToVenueModal = true;
    state.venueDetails = action.payload.data;
  },
  [putVenueDetailsFailed.type]: (state, action) => {
    state.savingStatus = action.payload.status;
    state.showReturnToVenueModal = false;
    state.saving = false;
  },
  [showReturnToVenueModal.type]: (state, action) => {
    state.showReturnToVenueModal = action.payload.show;
  },
});

export const addNewVenueReducer = createReducer(initialStateAddNewVenue, {
  [addNewVenueRequest.type]: (state) => {
    state.loading = true;
  },
  [addNewVenueSuccess.type]: (state) => {
    state.loading = false;
    state.showModal = true;
  },
  [addNewVenueFailed.type]: (state) => {
    state.loading = false;
  },
  [resetAddNewVenueModalStatus.type]: (state) => {
    state.loading = false;
    state.showModal = false;
  },
});

export const invoiceAddressesSingleVenueReducer = createReducer(initialStateInvoice, {
  [getInvoiceAddressesSingleVenueRequest.type]: (state) => {
    state.loading = true;
  },
  [getInvoiceAddressesSingleVenueSuccess.type]: (state, action) => {
    state.loading = false;
    state.invoiceAddresses = action.payload;
  },
  [getInvoiceAddressesSingleVenueFailed.type]: (state) => {
    state.loading = false;
  },
  [resetInvoiceAddressesList.type]: (state) => {
    state.loading = false;
    state.invoiceAddresses = [];
  },
});

export const venueAffiliationsReducer = createReducer(initialStateAffiliations, {
  [getVenueAffiliationsRequest.type]: (state) => {
    state.loading = true;
  },
  [getVenueAffiliationsSuccess.type]: (state, action) => {
    state.loading = false;
    state.affiliations = action.payload.results;
  },
  [getVenueAffiliationsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetVenueAffiliations.type]: (state) => {
    state.loading = false;
    state.affiliations = [];
  },
});

export const venueTypesReducer = createReducer(initialStateTypes, {
  [getVenueTypesRequest.type]: (state) => {
    state.loading = true;
  },
  [getVenueTypesSuccess.type]: (state, action) => {
    state.loading = false;
    state.types = action.payload.results;
  },
  [getVenueTypesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetVenueTypes.type]: (state) => {
    state.loading = false;
    state.types = [];
  },
});

export const venueAdditionalServicesReducer = createReducer(initialStateServices, {
  [getVenueAdditionalServicesRequest.type]: (state) => {
    state.loading = true;
  },
  [getVenueAdditionalServicesSuccess.type]: (state, action) => {
    state.loading = false;
    state.services = action.payload.results;
  },
  [getVenueAdditionalServicesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetVenueAdditionalServices.type]: (state) => {
    state.loading = false;
    state.services = [];
  },
});

export const venueCurrentPracticeReducer = createReducer(initialStateCurrentPractice, {
  [getCurrentVenuePraticeDetailsRequest.type]: (state) => {
    state.loading = true;
  },
  [getCurrentVenuePraticeDetailsSuccess.type]: (state, action) => {
    state.loading = false;
    state.currentPracticeStatus = action.payload.status;
    state.rejectionReason = action.payload.reason;
  },
  [getCurrentVenuePraticeDetailsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetCurrentVenuePraticeDetails.type]: (state) => {
    state.loading = false;
    state.currentPracticeStatus = undefined;
    state.rejectionReason = undefined;
  },
});

export const venuePartnerLocationReducer = createReducer(initialStatePartnerLocation, {
  [getAllVenuePartnersRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllVenuePartnersSuccess.type]: (state, action) => {
    state.loading = false;
    state.location = action.payload;
  },
  [getAllVenuePartnersFailed.type]: (state) => {
    state.loading = false;
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from './styles';

function MyLink({ children, to, small, handleClick, ...props }) {
  return (
    <Link {...props} onClick={handleClick} to={to} small={small}>
      {children}
    </Link>
  );
}

MyLink.propTypes = {
  small: PropTypes.bool,
  children: PropTypes.node,
  to: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

MyLink.defaultProps = {
  small: false,
  children: null,
  to: null,
};

export default MyLink;

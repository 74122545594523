import { defineMessages } from 'react-intl';

export default defineMessages({
  updateRequired: {
    id: 'web.shared.components.UpdateTermsModal.updateRequired',
    defaultMessage:
      'We updated our {subject}. Please review and accept the new version of our {subject}.',
  },
  privacy: {
    id: 'web.shared.components.UpdateTermsModal.privacy',
    defaultMessage: 'Privacy Policy',
  },
  tos: {
    id: 'web.shared.components.UpdateTermsModal.tos',
    defaultMessage: 'Terms of Service',
  },
});

import { createSelector } from 'reselect';

const selectRemoteValidationDomain = () => state =>
  state.get('remoteValidation');

export const makeSelectActiveFormName = () =>
  createSelector(selectRemoteValidationDomain(), remoteValidation => {
    if (remoteValidation) {
      const activeFormName = remoteValidation.get('activeFormName');
      return activeFormName || null;
    }
    return null;
  });

import React from 'react';

const Profile = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <title>PROFILE-MyProfile</title>
    <g fill="currentColor">
      <path d="M15 6.5a4.11 4.11 0 1 0 4.11 4.11A4.11 4.11 0 0 0 15 6.5zm0 7.22a3.11 3.11 0 1 1 3.11-3.11A3.11 3.11 0 0 1 15 13.72z" />
      <path d="M15 4a11 11 0 0 0-7.72 18.86 11 11 0 0 0 15.38 0A11 11 0 0 0 15 4zM8.3 22.44a6.71 6.71 0 0 1 13.4 0 10 10 0 0 1-13.4 0zm14.3-.91a7.72 7.72 0 0 0-15.2 0 10 10 0 1 1 15.2 0z" />
    </g>
  </svg>
);

export default Profile;

import { all, call, spawn } from 'redux-saga/effects';
import accessesRootSaga from './accesses/indexSaga';
import authRootSaga from './auth/indexSaga';
import doctorsRootSaga from './doctors/indexSaga';
import documentsRootSaga from './documents/indexSaga';
import invoicesRootSaga from './invoices/indexSaga';
import practicesRootSaga from './practices/indexSaga';
import pricelistsRootSaga from './pricelists/indexSaga';
import specialtiesRootSaga from './specialties/indexSaga';
import manageStructureSaga from './structure/sagas';
import supportRootSaga from './support/indexSaga';
import venuesRootSaga from './venues/indexSaga';

function* rootSaga() {
  const sagas = [
    doctorsRootSaga,
    documentsRootSaga,
    invoicesRootSaga,
    manageStructureSaga,
    practicesRootSaga,
    pricelistsRootSaga,
    specialtiesRootSaga,
    supportRootSaga,
    venuesRootSaga,
    accessesRootSaga,
    authRootSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      }),
    ),
  );
}

export default rootSaga;

import React from 'react';
import PropTypes from 'prop-types';
import RemoteFilePreview from 'components/shared/FilePreview/RemoteFilePreview';
import { Wrapper, FamilyIcon, AvatarSelection } from './styles';

function Avatar({
  firstName,
  lastName,
  type,
  attachmentId,
  onChange,
  selected,
  size,
  className,
}) {
  return (
    <Wrapper className={className}>
      <RemoteFilePreview
        attachmentId={attachmentId}
        firstName={firstName}
        lastName={lastName}
        size={size}
      />
      {onChange && (
        <AvatarSelection size={size} selected={selected} onClick={onChange} />
      )}
      {type === 'family' && <FamilyIcon selected={selected} />}
    </Wrapper>
  );
}

Avatar.propTypes = {
  selected: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  attachmentId: PropTypes.number,
  type: PropTypes.oneOf(['me', 'family', 'doctor']),
  onChange: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Avatar.defaultProps = {
  selected: false,
  type: null,
  onChange: null,
  size: 40,
};

export default Avatar;

import React from 'react';
import PropTypes from 'prop-types';

const Map = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 42 36" {...props}>
    <title />
    <g fill="none" fillRule="evenodd">
      <path
        d="M20.259 27.445c-.43.418-.829.79-1.188 1.11-.219.195-.377.331-.465.404a.947.947 0 01-1.212 0c-.088-.073-.246-.209-.465-.404a43.497 43.497 0 01-4.321-4.492h-5.92L2.385 34.105h31.23l-4.303-10.042h-5.92a43.497 43.497 0 01-3.133 3.382zm9.677-5.277c.38 0 .722.226.87.575l5.116 11.936a.947.947 0 01-.87 1.321H.948a.947.947 0 01-.87-1.32l5.115-11.937a.947.947 0 01.87-.575h5.067c-2.708-3.71-4.308-7.423-4.308-10.99C6.822 4.889 11.768 0 18 0c6.232 0 11.178 4.887 11.178 11.179 0 3.566-1.6 7.28-4.308 10.99h5.066zm-11.178-10.99h1.894a2.652 2.652 0 11-5.304.002 2.652 2.652 0 015.304-.001h-1.894a.757.757 0 10-1.515-.001.757.757 0 001.515 0zm.18 14.909a41.204 41.204 0 003.438-3.776c3.075-3.845 4.908-7.663 4.908-11.132 0-5.24-4.093-9.284-9.284-9.284-5.191 0-9.284 4.044-9.284 9.284 0 3.47 1.833 7.287 4.908 11.132A41.204 41.204 0 0018 26.97c.29-.263.604-.558.938-.883z"
        fill="currentColor"
      />
      {active && (
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#FF4040"
          cx={31.923}
          cy={6.923}
          r={9.923}
        />
      )}
    </g>
  </svg>
);

Map.propTypes = {
  active: PropTypes.bool,
};

export default Map;

import { defineMessages } from 'react-intl';

export default defineMessages({
  videomeetingConnectionTimeOne: {
    id: 'web.partner.videomeeting.header.connectionTimeOne',
    defaultMessage: 'Connected from {count} minute',
  },
  videomeetingConnectionTime: {
    id: 'web.partner.videomeeting.header.connectionTime',
    defaultMessage: 'Connected from {count} minutes',
  },
  videomeetingHelp: {
    id: 'web.partner.videomeeting.header.help',
    defaultMessage: 'Help',
  },
  videomeetingNotes: {
    id: 'web.partner.videomeeting.header.hotes',
    defaultMessage: 'Notes',
  },
  videomeetingBack: {
    id: 'web.partner.videomeeting.header.back',
    defaultMessage: 'Back',
  },
  videomeetingNotesTitle: {
    id: 'web.partner.videomeeting.notes.title',
    defaultMessage: 'Note for',
  },
  videomeetingButtonSave: {
    id: 'web.partner.videomeeting.button.save',
    defaultMessage: 'Note for',
  },
  patientName: {
    id: 'web.partner.videomeeting.header.patientName',
    defaultMessage: 'with Dr. {doctor}',
  },
});

import { createSelector, createStructuredSelector } from 'reselect';

export const selectAuth = () => (state) => state ? state.get('auth') : {};

export const selectJWTToken = () =>
  createSelector(selectAuth(), (selectStep) => selectStep.get('jwt'));

const selectAnalytics = (state) => state.get('analytics');

export const selectUUID = () =>
  createSelector(
    selectAnalytics,
    (languageState) =>
      localStorage.getItem('analytics_uuid') || languageState.get('UUID'),
  );

const selectLanguage = (state) => state.get('language');

export const selectLocale = () =>
  createSelector(selectLanguage, (languageState) =>
    languageState.get('locale'),
  );

export const selectFetchHeaders = createStructuredSelector({
  jwtToken: selectJWTToken(),
  languageCode: selectLocale(),
  uuid: selectUUID(),
});

export const selectPathname = () => (state) =>
  state.getIn(['router', 'location', 'pathname']);

import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import { setActiveFormAction } from 'containers/HomePage/LandingPage/actions';
import { changePasswordResponse } from '../actions';

export function* getChangePassword({ data }) {
  const url = `auth/pass`;
  try {
    yield put(showLoading());
    const payload = yield call(fetchWrapper, {
      url,
      method: 'PATCH',
      params: {
        ...data,
        role: 'partner',
      },
    });
    yield put(changePasswordResponse(payload));
    yield put(setActiveFormAction({ activeForm: 'login' }));
    yield put(
      pushError({ text: 'changePasswordSuccess', typeError: 'success' }),
    );
  } catch ({ packet, response }) {
    if (response.status === 422) {
      if (packet.message && packet.message !== '')
        yield put(pushError({ text: packet.message }));
      else yield put(pushError({ text: 'changePasswordDataError' }));
    } else {
      yield put(pushError({ text: 'changePasswordError' }));
    }
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

import { compose, withStateHandlers, withProps, lifecycle } from 'recompose';
import get from 'lodash/get';
import moment from 'moment/min/moment-with-locales';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLocale } from '../selectors';
import DateRange from './DateRange';

const mapStateToProps = createStructuredSelector({
  availabilities: (state, { availabilitiesAccessor }) =>
    state.getIn((availabilitiesAccessor || '').split('.')) &&
    state.getIn((availabilitiesAccessor || '').split('.')).toJS(),
  availabilitiesLoading: (state, { availabilitiesLoadingAccessor }) =>
    state.getIn((availabilitiesLoadingAccessor || '').split('.')),
  locale: selectLocale(),
});

export default compose(
  connect(mapStateToProps),
  withProps(({ locale, names, ...props }) => {
    const startName = names[0];
    const endName = names[1];
    const startDateDataForm = get(props, startName);
    const endDateDataForm = get(props, endName);
    moment.locale(locale.split('_')[0]);
    return {
      locale: (locale || 'it_IT').split('_')[0],
      startDateDataForm,
      endDateDataForm,
    };
  }),
  withStateHandlers(
    ({ startDateDataForm, endDateDataForm }) => ({
      selectionRange: {
        startDate: startDateDataForm.input.value
          ? moment(startDateDataForm.input.value).toDate()
          : new Date(),
        endDate: endDateDataForm.input.value
          ? moment(endDateDataForm.input.value).toDate()
          : new Date(),
        selection: 'selection',
        isOpen: false,
      },
    }),
    {
      toggle: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
      onChange: (_, { startDateDataForm, endDateDataForm }) => ({
        selection,
      }) => {
        const { startDate, endDate } = selection;

        // update REDUX FORM
        if (startDate)
          startDateDataForm.input.onChange(
            moment(startDate).format('YYYY/MM/DD'),
          );
        if (endDate)
          endDateDataForm.input.onChange(moment(endDate).format('YYYY/MM/DD'));
        return {
          selectionRange: selection,
        };
      },
    },
  ),
  lifecycle({
    componentDidMount() {
      const {
        selectionRange: { startDate, endDate },
        startDateDataForm,
        endDateDataForm,
      } = this.props;

      if (startDate)
        startDateDataForm.input.onChange(
          moment(startDate).format('YYYY/MM/DD'),
        );
      if (endDate)
        endDateDataForm.input.onChange(moment(endDate).format('YYYY/MM/DD'));
    },
  }),
)(DateRange);

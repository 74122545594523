import { all, takeLatest } from 'redux-saga/effects';
import { changeLocale, localesRequest } from '../actions';
import { getLocales } from './getLocales';
import { storeLocale } from './storeLocale';

export default function* rootSaga() {
  yield all([
    takeLatest(localesRequest, getLocales),
    takeLatest(changeLocale, storeLocale),
  ]);
}

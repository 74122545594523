import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BottomButtons from '../BottomButtons';
import { Popover, PopoverBody } from 'reactstrap';
// import { Tooltip } from '../styles';
import { Button } from './styles';

function Filter({
  children,
  icon: Icon,
  filterName,
  index,
  selectField,
  selectedField,
  toggle,
  filters,
  inputs,
  removeSingleFilter,
  removeAllFilters,
  applyFilters,
  valid,
  disabled,
  doApplyFilter,
}) {
  const filtersApplied = !!applyFilters.size;
  const isApplied = !!applyFilters
    .toJS()
    .filter(({ index: applyIndex }) => applyIndex === inputs[0].index).length;

  return (
    <Fragment>
      <Button
        onClick={selectField}
        id={`tooltip-${index}`}
        icon={() => <Icon active={isApplied} />}
        isOpen={selectedField && selectedField.key === filters[index].key}
        disabled={disabled}
      >
        {filterName}
      </Button>
      {selectedField && (
        <Popover
          placement="bottom"
          isOpen={selectedField.key === filters[index].key}
          target={`tooltip-${index}`}
          toggle={toggle}
          className="tooltip-inner"
          popperClassName="popper-class"
        >
          <PopoverBody>
            {children}

            <BottomButtons
              removeFilter={(e) => {
                selectField(e);
                removeAllFilters();
              }}
              removeSingleFilter={(e) => {
                selectField(e);
                inputs.map(({ index: indexField }) =>
                  removeSingleFilter({ indexField }),
                );
              }}
              apply={(e) => {
                selectField(e);
                doApplyFilter();
              }}
              valid={valid}
              isApplied={isApplied}
              filtersApplied={filtersApplied}
            />
          </PopoverBody>
        </Popover>
      )}
    </Fragment>
  );
}

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  selectedField: PropTypes.shape({}),
  selectField: PropTypes.func.isRequired,
  icon: PropTypes.node,
  filterName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
  doApplyFilter: PropTypes.func.isRequired,
  inputs: PropTypes.shape({}),
  removeSingleFilter: PropTypes.func.isRequired,
  removeAllFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.shape({}),
  valid: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Filter;

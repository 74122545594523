import { createSelector } from 'reselect';

export const selectVideomeetingDomain = () => state =>
  state.get('Videomeeting');

export const selectVideomeetingLoading = () =>
  createSelector(selectVideomeetingDomain(), state =>
    state.get('videomeetingLoading'),
  );

export const selectVideomeetingData = () =>
  createSelector(selectVideomeetingDomain(), state => ({
    id: state.get('bookingId'),
    partnerName: state.get('partnerName'),
    patientName: state.get('patientName'),
    patientFirstName: state.get('patientFirstName'),
    patientLastName: state.get('patientLastName'),
    patientId: state.get('patientId'),
    profilePicture: state.get('profilePicture'),
    healthServiceName: state.get('healthServiceName'),
    title: state.get('title'),
    notes: state.get('notes'),
    timing: state.get('timing').toJS(),
  }));

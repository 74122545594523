import styled from 'styled-components';
import GoodmeHeaderLogo from 'images/goodme-logo-black.png';
import { Link as _link } from '@components';

export const Wrapper = styled.div`
  height: 80px;
  background: ${p => p.theme.white};
  border-bottom: 1px solid ${p => p.theme.gray200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  position: relative;
  z-index: 2;
`;

export const Img = styled.img`
  height: 42px;
`;

export const Logo = styled.img.attrs({
  src: GoodmeHeaderLogo,
  width: '120',
})``;

export const Link = styled(_link).attrs({ small: true })`
  &.helpers {
    margin-left: 30px;
  }
`;

import styled from 'styled-components';
import { Alert as _alert } from 'reactstrap';

export const Alert = styled(_alert)`
  border-radius: 5px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: ${({ ctaopen }) => (ctaopen ? '130px' : '85px')};
  transform: translate(-50%);
  width: 60%;
  z-index: 10000;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.black};
  & > * {
    color: ${({ theme }) => theme.black};
  }
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  from: {
    id: 'web.shared.timeRange.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'web.shared.timeRange.to',
    defaultMessage: 'To',
  },
});

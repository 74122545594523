import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import InputPhone from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isNil from 'lodash/isNil';
import { FormGroup, ErrorMessage } from '../FormStyles';
import Label from '../Label';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import { InputPhoneContainer } from './styles';
import messages from './messages';

function MyInputPhone({
  intl,
  input,
  label,
  titleLabel,
  meta,
  tabIndex,
  requiredfield,
  placeholder,
  marginBottom,
}) {
  const { error, warning, touched, dirty, active } = meta;

  return (
    <FormGroup haslabel={!!titleLabel || !!label} marginBottom={marginBottom}>
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={!!requiredfield}
        />
      )}
      <InputPhoneContainer tabIndex={tabIndex} dirty={dirty} active={active}>
        <InputPhone
          {...input}
          regions="europe"
          defaultCountry="it"
          value={input.value}
          autoFormat={false}
          placeholder={intl.formatMessage(
            placeholder ? messages[placeholder] : messages.placeholder,
          )}
          inputStyle={{
            borderColor: meta && meta.touched && !isNil(meta.error) && 'red',
          }}
        />
      </InputPhoneContainer>
      {touched &&
        ((error && (
          <ErrorMessage>
            {errorMessages[isString(error)] ? (
              <FormattedMessage
                values={typeof error !== 'object' ? {} : error}
                {...errorMessages[isString(error)]}
              />
            ) : (
              error
            )}
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}
MyInputPhone.propTypes = {
  intl: intlShape.isRequired,
  input: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.any,
  tabIndex: PropTypes.number,
  requiredfield: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  marginBottom: PropTypes.string,
};
MyInputPhone.defaultProps = {
  input: null,
  label: '',
  titleLabel: '',
  meta: null,
  tabIndex: '',
  requiredfield: false,
  placeholder: null,
  marginBottom: null,
};
export default injectIntl(MyInputPhone);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from '@components';
import { Wrapper } from './styles';
import messages from './messages';

function ImpersonateBar({ profileData, onLogout }) {
  const { firstName, lastName } = profileData || {};
  return (
    <Wrapper>
      <FormattedMessage
        {...messages.impersonateMessage}
        values={{
          firstName: firstName || '',
          lastName: lastName || '',
        }}
      />{' '}
      <Link to="/" onClick={onLogout}>
        <FormattedMessage {...messages.stopImpersonate} />
      </Link>
    </Wrapper>
  );
}

ImpersonateBar.propTypes = {
  profileData: PropTypes.shape({}).isRequired,
  onLogout: PropTypes.func.isRequired,
};

ImpersonateBar.defaultProps = {};

export default ImpersonateBar;

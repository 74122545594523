import React from 'react';

const Gdpr = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>GDPR</title>
    <g fill="currentColor">
      <path d="M15 23.5a.47.47 0 0 1-.3-.1l-2.49-1.9H5a.5.5 0 0 1-.5-.5V7a.5.5 0 0 1 .5-.5h20a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5h-7.44l-2.24 1.88a.49.49 0 0 1-.32.12zm-9.5-3h6.88a.57.57 0 0 1 .3.1L15 22.36l2.07-1.74a.5.5 0 0 1 .33-.12h7.1v-13h-19z" />
      <path d="M15 19a5 5 0 1 1 5-5 5 5 0 0 1-5 5zm-.21-1a3.2 3.2 0 0 0 .62 0 6.57 6.57 0 0 0 1.32-2h-3.27a6.47 6.47 0 0 0 1.33 2zm-3.25-2a4.07 4.07 0 0 0 1.61 1.55 6.52 6.52 0 0 1-.76-1.55zm6.25 0a6.52 6.52 0 0 1-.64 1.38A4 4 0 0 0 18.46 16zm.21-1h.84a3.91 3.91 0 0 0 0-2H18a6.31 6.31 0 0 1 0 2zm-4.86 0H17a5.12 5.12 0 0 0 0-2h-3.77a5.12 5.12 0 0 0-.06 2zm-2 0h1a5.78 5.78 0 0 1 .07-2h-1.1a3.91 3.91 0 0 0 0 2zm6.55-3h.78A3.92 3.92 0 0 0 17 10.52a9.29 9.29 0 0 1 .68 1.48zm-4.12 0h3.07a7.52 7.52 0 0 0-1.23-2 4 4 0 0 0-.6 0 7.51 7.51 0 0 0-1.25 2zm-2 0h1a7.89 7.89 0 0 1 .79-1.62A4.06 4.06 0 0 0 11.54 12z" />
    </g>
  </svg>
);

export default Gdpr;

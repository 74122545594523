import { compose, withStateHandlers } from 'recompose';
import { injectIntl } from 'react-intl';
import Modal from './Modal';

export default compose(
  injectIntl,
  withStateHandlers(({ isOpen }) => ({ isOpen }), {
    close: () => () => ({
      isOpen: false,
    }),
  }),
)(Modal);

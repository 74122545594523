import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalHeader, ModalBody, Button, Row, Col } from '@components';
import messages from './messages';
import { Modal } from './styles';

function TimeExpiredModal({ isOpen, confirm }) {
  return (
    <Modal isOpen={isOpen} size="md" centered>
      <ModalHeader>
        <FormattedMessage {...messages.timeExpiredModalTitle} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.timeExpiredModalMessage} />
        <Row>
          <Col md={{ offset: 2, size: 8 }}>
            <Button
              color="primary"
              size="lg"
              margin="30px 0 0"
              onClick={confirm}
            >
              <FormattedMessage {...messages.ok} />
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

TimeExpiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default TimeExpiredModal;

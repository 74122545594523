import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyPolicy: {
    id: 'web.partner.components.footer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  termOfUse: {
    id: 'web.partner.components.footer.termOfUse',
    defaultMessage: 'Terms of use',
  },
  copyright: {
    id: 'web.partner.components.footer.copyright',
    defaultMessage: 'Copyright © 2019 - All rights reserved',
  },
  contact: {
    id: 'web.partner.components.footer.contact',
    defaultMessage: 'Contact us',
  },
  vat: {
    id: 'web.partner.components.footer.vat',
    defaultMessage: 'P.IVA: 000000000000',
  },
  address: {
    id: 'web.partner.components.footer.address',
    defaultMessage:
      'Sede legale e operativa: via Tal Dei Tali 42, 20126 Milano (MI)',
  },
  goodMe: {
    id: 'web.partner.components.footer.goodMe',
    defaultMessage: 'GoodMe',
  },
  community: {
    id: 'web.partner.components.footer.community',
    defaultMessage: 'Community',
  },
  needHelp: {
    id: 'web.partner.components.footer.needHelp',
    defaultMessage: 'Do you need help?',
  },
  downloadApp: {
    id: 'web.partner.components.footer.downloadApp',
    defaultMessage: 'Download the Free App',
  },
  followUs: {
    id: 'web.partner.components.footer.followUs',
    defaultMessage: 'Follow Us on:',
  },
  aboutUs: {
    id: 'web.partner.components.footer.links.aboutUs',
    defaultMessage: 'About Us',
  },
  workWithUs: {
    id: 'web.partner.components.footer.links.workWithUs',
    defaultMessage: 'Work with Us',
  },
  mediaRoom: {
    id: 'web.partner.components.footer.links.mediaRoom',
    defaultMessage: 'Media Room',
  },
  news: {
    id: 'web.partner.components.footer.links.news',
    defaultMessage: 'News',
  },
  doctors: {
    id: 'web.partner.components.footer.links.doctors',
    defaultMessage: 'Doctors',
  },
  structures: {
    id: 'web.partner.components.footer.links.structures',
    defaultMessage: 'Structures',
  },
  partners: {
    id: 'web.partner.components.footer.links.partners',
    defaultMessage: 'Partners',
  },
  faq: {
    id: 'web.partner.components.footer.links.faq',
    defaultMessage: 'FAQ',
  },
  tutorial: {
    id: 'web.partner.components.footer.links.tutorial',
    defaultMessage: 'Tutorial',
  },
  contactUs: {
    id: 'web.partner.components.footer.links.contactUs',
    defaultMessage: 'Contact Us',
  },
  row1: {
    id: 'web.partner.components.footer.row1',
    defaultMessage: 'Aon Hewitt Risk & Consulting S.r.l. con socio unico',
  },
  row2: {
    id: 'web.partner.components.footer.row2',
    defaultMessage:
      'Società soggetta a direzione e coordinamento di Aon Italia S.r.l.',
  },
  row3: {
    id: 'web.partner.components.footer.row3',
    defaultMessage:
      'Via Ernesto Calindri 6 | 20143 Milano | t: +39 02 45434.1 | f: +39 02 45434.810 | PEC: <a href="mailto:hewitt@pec.aon.it">hewitt@pec.aon.it</a>',
  },
  row4: {
    id: 'web.partner.components.footer.row4',
    defaultMessage:
      'Sede Legale: Via Ernesto Calindri 6 | 20143 Milano | Capitale Sociale € 26.500,00 i.v.',
  },
  row5: {
    id: 'web.partner.components.footer.row5',
    defaultMessage:
      'Iscrizione RUI E000193664 | Codice Fiscale e Numero Iscrizione Registro Imprese Milano 04270931001 | P. IVA 04270931001',
  },
});

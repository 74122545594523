import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectIsAuthenticated,
  selectPartnerStatus,
  selectIsImpersonated,
  selectPartnerFirstApprovalReviewed,
} from '../selectors';
import PrivateRoute from './PrivateRoute';

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  isImpersonated: selectIsImpersonated(),
  partnerStatus: selectPartnerStatus(),
  partnerFirstApprovalReviewed: selectPartnerFirstApprovalReviewed(),
});

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PrivateRoute);

import React from 'react';

const ArrowRight = props => (
  <svg width="1em" height="1em" viewBox="0 0 8 14" {...props}>
    <title>arrow - right</title>
    <path
      d="M.479 12.929a.525.525 0 0 0 .742.742l6.3-6.3a.525.525 0 0 0 0-.742l-6.3-6.3a.525.525 0 0 0-.742.742L6.408 7l-5.93 5.929z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowRight;

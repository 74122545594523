import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import messages from './messages';
import { Button, Link, Subtitle, Title, Wrapper, TextWrapper } from './styles';

const BackHeader = ({
  backPath,
  history,
  subtitle,
  title,
  RightComponent,
  titleFontSize,
  onClick,
  hideBackPath,
}) => (
  <Wrapper>
    {!hideBackPath &&
      !!backPath && (
        <FormattedMessage {...messages.back}>
          {text => <Link to={backPath}>{text}</Link>}
        </FormattedMessage>
      )}
    {!hideBackPath &&
      !backPath && (
        <FormattedMessage {...messages.back}>
          {text => (
            <Button onClick={onClick || (() => history.goBack())}>
              {text}
            </Button>
          )}
        </FormattedMessage>
      )}
    <TextWrapper>
      <Wrapper>
        <Title hasBackPath={!!backPath} titleFontSize={titleFontSize}>
          <FormattedHTMLMessage {...title} />
        </Title>
        {!!subtitle && (
          <FormattedMessage {...subtitle}>
            {text => <Subtitle>{text}</Subtitle>}
          </FormattedMessage>
        )}
      </Wrapper>
      {RightComponent && <RightComponent />}
    </TextWrapper>
  </Wrapper>
);

const FormattedMessageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
});

BackHeader.propTypes = {
  backPath: PropTypes.string,
  titleFontSize: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  title: FormattedMessageShape.isRequired,
  subtitle: FormattedMessageShape,
  RightComponent: PropTypes.node,
  onClick: PropTypes.func,
  hideBackPath: PropTypes.bool,
};

BackHeader.defaultProps = {
  backPath: '',
  RightComponent: null,
};

export default BackHeader;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { platformReady } from 'utils/env';
import {
  notApproved,
  isApproved,
} from 'containers/PartnersRegistration/shared';

const getRedirectPath = (
  partnerStatus,
  firstApprovalReviewed,
  isImpersonated,
  pathname,
) => {
  if (!partnerStatus) return false;
  const enterSiteCheck =
    isApproved(partnerStatus) &&
    firstApprovalReviewed === false &&
    !isImpersonated;
  const excludePathCheck =
    pathname !== '/term-of-use' &&
    pathname !== '/privacy-policy' &&
    pathname !== '/copyright';
  if (enterSiteCheck && platformReady === 'true')
    return '/partner-registration';
  if (enterSiteCheck && platformReady === 'false') return '/platform-offline';
  return notApproved(partnerStatus) && excludePathCheck
    ? '/partner-registration'
    : false;
};

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  partnerStatus,
  partnerFirstApprovalReviewed,
  isImpersonated,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ||
      !getRedirectPath(
        partnerStatus,
        partnerFirstApprovalReviewed,
        isImpersonated,
        props.location.pathname,
      ) ? (
        <Component {...props} />
      ) : (
        <Redirect
          push
          to={getRedirectPath(
            partnerStatus,
            partnerFirstApprovalReviewed,
            isImpersonated,
          )}
        />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isImpersonated: PropTypes.bool.isRequired,
  partnerStatus: PropTypes.string,
  partnerFirstApprovalReviewed: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

PublicRoute.defaultProps = {
  partnerStatus: null,
  partnerFirstApprovalReviewed: false,
};

export default PublicRoute;

import React from 'react';
import PropTypes from 'prop-types';

const MedicalFolder = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 68.77 95" {...props}>
    <path d="M44.87 54.3H40v-4.86a2.49 2.49 0 00-2.5-2.5h-5.64a2.49 2.49 0 00-2.5 2.5v4.86H24.5a2.49 2.49 0 00-2.5 2.5v5.64a2.5 2.5 0 002.5 2.5h4.87v4.84a2.5 2.5 0 002.5 2.5h5.64a2.5 2.5 0 002.5-2.5v-4.83h4.87a2.5 2.5 0 002.5-2.5v-5.67a2.49 2.49 0 00-2.5-2.5zm-.5 7.64h-4.86a2.49 2.49 0 00-2.5 2.5v4.87h-4.65v-4.86a2.49 2.49 0 00-2.5-2.5H25V57.3h4.87a2.5 2.5 0 002.5-2.5v-4.86H37v4.84a2.5 2.5 0 002.5 2.5h4.87zM47.78 13.1H21.59a1.5 1.5 0 000 3h26.2a1.5 1.5 0 000-3zM47.78 23.5H21.59a1.5 1.5 0 100 3h26.2a1.5 1.5 0 000-3zM47.78 33.84H21.59a1.5 1.5 0 100 3h26.2a1.5 1.5 0 000-3z" />
    <path d="M8.08 80.5h47.71a5.52 5.52 0 005.5-5.5v-7.6h3.79a3.7 3.7 0 003.68-3.68v-9.83a3.65 3.65 0 00-.76-2.18 3.7 3.7 0 00.77-2.21v-9.8a3.65 3.65 0 00-.77-2.2 3.66 3.66 0 00.73-2.18V25.5a3.59 3.59 0 00-.73-2.17 3.66 3.66 0 00.73-2.18v-9.83a3.69 3.69 0 00-3.68-3.68h-3.76V5.5a5.5 5.5 0 00-5.5-5.5H5.5A5.51 5.51 0 000 5.5V75a5.52 5.52 0 005.5 5.5zm53.21-69.86h3.79a.67.67 0 01.68.68v9.82a.68.68 0 01-.68.68h-3.79zm0 14.19h3.79a.67.67 0 01.68.68v9.82a.68.68 0 01-.68.68h-3.79zm0 14.19h3.79a.67.67 0 01.68.68v9.8a.68.68 0 01-.68.68h-3.79zm0 14.19h3.79a.67.67 0 01.68.68v9.82a.68.68 0 01-.68.68h-3.79zM11.08 3h44.71a2.49 2.49 0 012.5 2.5V75a2.5 2.5 0 01-2.5 2.5H11.08zM3 75V5.5A2.49 2.49 0 015.5 3h2.42v74.5H5.5A2.5 2.5 0 013 75z" />
    {active && (
      <circle
        stroke="#FFF"
        strokeWidth={4}
        fill="#FF4040"
        cx={60}
        cy={80}
        r={14}
      />
    )}
  </svg>
);

MedicalFolder.propTypes = {
  active: PropTypes.bool,
};

export default MedicalFolder;

import { createReducer } from '@reduxjs/toolkit';
import {
  getPracticesRequest,
  getPracticesSuccess,
  getPracticesFailed,
  getPracticesAppointmentsSuccess,
  getPracticesAppointmentsFailed,
  getPracticesToManageSuccess,
  getPracticesToManageFailed,
  resetSearchPracticesListFilters,
  setPracticesListInitialPage,
  setSearchPracticesListFilters,
  getPracticeDetailsRequest,
  getPracticeDetailsSuccess,
  getPracticeDetailsFailed,
  resetPracticeDetails,
  getPracticesCategoriesRequest,
  getPracticesCategoriesSuccess,
  getPracticesCategoriesFailed,
  getPracticesStatusesRequest,
  getPracticesStatusesSuccess,
  getPracticesStatusesFailed,
  getPracticeMedicalServicesRequest,
  getPracticeMedicalServicesSuccess,
  getPracticeMedicalServicesFailed,
  getPracticeMedicalServicesDentalRequest,
  getPracticeMedicalServicesDentalSuccess,
  getPracticeMedicalServicesDentalFailed,
  setMedicalServiceRequest,
  resetMedicalServiceRequest,
  resetMedicalServiceDentalRequest,
  resetAvailableMedicalServiceRequest,
  getPracticeDoctorsRequest,
  getPracticeDoctorsSuccess,
  getPracticeDoctorsFailed,
  resetPracticeDoctorsRequest,
  putPracticesRequest,
  putPracticesSuccess,
  putPracticesFailed,
  putProntoCarePracticesRequest,
  putProntoCarePracticesSuccess,
  putProntoCarePracticesFailed,
  resetPutPracticeModalStatus,
  deletePracticeServicesRequest,
  deletePracticeServicesSuccess,
  deletePracticeServicesFailed,
  payPracticesCouponRequest,
  payPracticesCouponSuccess,
  payPracticesCouponFailed,
  closePracticeRequest,
  closePracticeSuccess,
  closePracticeFailed,
  addInvoiceRequest,
  addInvoiceSuccess,
  addInvoiceErrors,
  addInvoiceFailed,
  resetUploadedInvoicesIdsRequest,
  resetUploadedInvoicesErrorsRequest,
  getFamilyListRequest,
  getFamilyListSuccess,
  getFamilyListFailed,
  resetFamilyListRequest,
  getVenuesListRequest,
  getVenuesListSuccess,
  getVenuesListFailed,
  resetVenuesListRequest,
  createPracticeCouponRequest,
  createPracticeCouponSuccess,
  createPracticeCouponFailed,
  resetCreatePracticeCoupon,
} from './actions';
import {
  ResultsEntity,
  DoctorsEntity,
  PracticesCategories,
  PracticesStatuses,
  MedicalServiceEntity,
  MedicalServicesDentalEntity,
  DataEntity,
  FamilyData,
  VenueType,
} from './types';

export type PracticesListIS = {
  loading: boolean;
  saving: boolean;
  showSavedModal: boolean;
  savingErrors: boolean;
  practicesList: ResultsEntity[] | [];
  totalCount: number;
  currentPage: number;
  prevPage?: null;
  nextPage?: null;
  practicesListFilter: {
    textFilter: string;
  };
  practiceMedicalServices: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage?: null;
    nextPage?: null;
    available: MedicalServiceEntity[] | [] | null;
    selected: MedicalServiceEntity[] | [];
  };
  practiceMedicalServicesDental: {
    loading: boolean;
    medicalServices: MedicalServicesDentalEntity[] | [] | null;
  };
  practiceDoctors: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage?: null;
    nextPage?: null;
    doctorsList: DoctorsEntity[] | [];
  };
  practicesAppointments: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage?: null;
    nextPage?: null;
    practicesList: ResultsEntity[] | [];
  };
  practicesToManage: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage?: null;
    nextPage?: null;
    practicesList: ResultsEntity[] | [];
  };

  practiceDetails: DataEntity | null;
  categories: PracticesCategories[] | [];
  statuses: PracticesStatuses[] | [];
  uploadedInvoicesIds: {
    documentId: string;
  }[];
  uploadedInvoicesErrors: InvoicesErrors | null;
};

export type OpenPracticeIS = {
  familyPatients: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage: number | null;
    nextPage: number | null;
    familyList: FamilyData[] | [];
  };
  venues: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage: number | null;
    nextPage: number | null;
    venuesList: VenueType[] | [];
  };
};

export type CreatePracticeIS = {
  loading: boolean;
  practiceCreated: boolean | undefined;
  errors: boolean | undefined;
};

export type InvoicesErrors = {
  errors: InvoicesErrorsEntity[];
  warnings: InvoicesErrorsEntity[];
};

export type InvoicesErrorsEntity = {
  invoiceNumber: string;
  type: string;
  error: string;
};

const initialState: PracticesListIS = {
  loading: false,
  saving: false,
  showSavedModal: false,
  savingErrors: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  practicesList: [],
  practicesListFilter: {
    textFilter: '',
  },
  practicesAppointments: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    practicesList: [],
  },
  practicesToManage: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    practicesList: [],
  },
  practiceDetails: null,
  practiceMedicalServices: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    available: null,
    selected: [],
  },
  practiceMedicalServicesDental: {
    loading: false,
    medicalServices: null,
  },
  practiceDoctors: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    doctorsList: [],
  },

  categories: [],
  statuses: [],
  uploadedInvoicesIds: [],
  uploadedInvoicesErrors: null,
};

const initialStateOpenPractice: OpenPracticeIS = {
  familyPatients: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    familyList: [],
  },
  venues: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    venuesList: [],
  },
};

const initialStateCreatePractice: CreatePracticeIS = {
  loading: false,
  practiceCreated: undefined,
  errors: undefined,
};

export const practicesReducers = createReducer(initialState, {
  [getPracticesRequest.type]: (state) => {
    state.loading = true;
  },
  [getPracticesSuccess.type]: (state, action) => {
    state.loading = false;
    state.practicesList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getPracticesFailed.type]: (state) => {
    state.loading = false;
  },
  [getPracticesAppointmentsSuccess.type]: (state, action) => {
    state.practicesAppointments.loading = false;
    state.practicesAppointments.practicesList = action.payload.results;
    state.practicesAppointments.totalCount = action.payload.totalCount;
    state.practicesAppointments.currentPage = action.payload.currentPage;
    state.practicesAppointments.prevPage = action.payload.prevPage;
    state.practicesAppointments.nextPage = action.payload.nextPage;
  },
  [getPracticesAppointmentsFailed.type]: (state) => {
    state.practicesAppointments.loading = false;
  },
  [getPracticesToManageSuccess.type]: (state, action) => {
    state.practicesToManage.loading = false;
    state.practicesToManage.practicesList = action.payload.results;
    state.practicesToManage.totalCount = action.payload.totalCount;
    state.practicesToManage.currentPage = action.payload.currentPage;
    state.practicesToManage.prevPage = action.payload.prevPage;
    state.practicesToManage.nextPage = action.payload.nextPage;
  },
  [getPracticesToManageFailed.type]: (state) => {
    state.practicesToManage.loading = false;
  },
  [setSearchPracticesListFilters.type]: (state, action) => {
    state.practicesListFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchPracticesListFilters.type]: (state) => {
    state.practicesListFilter.textFilter = '';
  },
  [setPracticesListInitialPage.type]: (state) => {
    state.currentPage = 1;
  },
  [getPracticeDetailsRequest.type]: (state) => {
    state.loading = true;
  },
  [getPracticeDetailsSuccess.type]: (state, action) => {
    state.loading = false;
    state.practiceDetails = action.payload;
  },
  [getPracticeDetailsFailed.type]: (state) => {
    state.loading = false;
  },
  [resetPracticeDetails.type]: (state) => {
    state.loading = false;
    state.savingErrors = false;
    state.practiceDetails = null;
  },
  [getPracticesCategoriesRequest.type]: (state) => {
    state.loading = true;
  },
  [getPracticesCategoriesSuccess.type]: (state, action) => {
    state.loading = false;
    state.categories = action.payload;
  },
  [getPracticesCategoriesFailed.type]: (state) => {
    state.loading = false;
  },
  [getPracticesStatusesRequest.type]: (state) => {
    state.loading = true;
  },
  [getPracticesStatusesSuccess.type]: (state, action) => {
    state.loading = false;
    state.statuses = action.payload;
  },
  [getPracticesStatusesFailed.type]: (state) => {
    state.loading = false;
  },
  [getPracticeMedicalServicesRequest.type]: (state) => {
    state.practiceMedicalServices.loading = true;
  },
  [getPracticeMedicalServicesSuccess.type]: (state, action) => {
    state.practiceMedicalServices.loading = false;
    state.practiceMedicalServices.currentPage = action.payload.currentPage;
    state.practiceMedicalServices.nextPage = action.payload.nextPage;
    state.practiceMedicalServices.prevPage = action.payload.prevPage;
    state.practiceMedicalServices.totalCount = action.payload.totalCount;
    state.practiceMedicalServices.available = action.payload.results;
  },
  [getPracticeMedicalServicesFailed.type]: (state) => {
    state.practiceMedicalServices.loading = false;
  },
  [setMedicalServiceRequest.type]: (state, action) => {
    state.practiceMedicalServices.selected = action.payload;
  },
  [resetMedicalServiceRequest.type]: (state) => {
    state.practiceMedicalServices.available = null;
    state.practiceMedicalServices.selected = [];
  },
  [resetMedicalServiceDentalRequest.type]: (state) => {
    state.practiceMedicalServicesDental.medicalServices = null;
  },
  [resetAvailableMedicalServiceRequest.type]: (state) => {
    state.practiceMedicalServices.available = null;
  },
  [getPracticeMedicalServicesDentalRequest.type]: (state) => {
    state.practiceMedicalServicesDental.loading = true;
  },
  [getPracticeMedicalServicesDentalSuccess.type]: (state, action) => {
    state.practiceMedicalServicesDental.loading = false;
    state.practiceMedicalServicesDental.medicalServices = action.payload;
  },
  [getPracticeMedicalServicesDentalFailed.type]: (state) => {
    state.practiceMedicalServicesDental.loading = false;
  },
  [getPracticeDoctorsRequest.type]: (state) => {
    state.practiceDoctors.loading = true;
  },
  [getPracticeDoctorsSuccess.type]: (state, action) => {
    state.practiceDoctors.loading = false;
    state.practiceDoctors.doctorsList = action.payload.results;
    state.practiceDoctors.totalCount = action.payload.totalCount;
    state.practiceDoctors.currentPage = action.payload.currentPage;
    state.practiceDoctors.prevPage = action.payload.prevPage;
    state.practiceDoctors.nextPage = action.payload.nextPage;
  },
  [getPracticeDoctorsFailed.type]: (state) => {
    state.practiceDoctors.loading = false;
  },
  [resetPracticeDoctorsRequest.type]: (state) => {
    state.practiceDoctors = initialState.practiceDoctors;
  },
  [putPracticesRequest.type]: (state) => {
    state.saving = true;
    state.savingErrors = false;
  },
  [putPracticesSuccess.type]: (state) => {
    state.saving = false;
    state.showSavedModal = true;
  },
  [putPracticesFailed.type]: (state) => {
    state.savingErrors = true;
    state.saving = false;
  },
  [putProntoCarePracticesRequest.type]: (state) => {
    state.saving = true;
    state.savingErrors = false;
  },
  [putProntoCarePracticesSuccess.type]: (state) => {
    state.saving = false;
    state.showSavedModal = true;
  },
  [putProntoCarePracticesFailed.type]: (state) => {
    state.savingErrors = true;
    state.saving = false;
  },
  [resetPutPracticeModalStatus.type]: (state) => {
    state.showSavedModal = false;
  },
  [deletePracticeServicesRequest.type]: (state) => {
    state.saving = true;
    state.savingErrors = false;
  },
  [deletePracticeServicesSuccess.type]: (state) => {
    state.saving = false;
    state.showSavedModal = true;
  },
  [deletePracticeServicesFailed.type]: (state) => {
    state.savingErrors = true;
    state.saving = false;
  },
  [payPracticesCouponRequest.type]: (state) => {
    state.saving = true;
  },
  [payPracticesCouponSuccess.type]: (state, action) => {
    state.practiceDetails = action.payload;
    state.saving = false;
  },
  [payPracticesCouponFailed.type]: (state) => {
    state.saving = false;
  },
  [closePracticeRequest.type]: (state) => {
    state.saving = true;
    state.savingErrors = false;
  },
  [closePracticeSuccess.type]: (state, action) => {
    state.practiceDetails = action.payload;
    state.saving = false;
  },
  [closePracticeFailed.type]: (state) => {
    state.savingErrors = true;
    state.saving = false;
  },
  [addInvoiceRequest.type]: (state) => {
    state.saving = true;
    state.savingErrors = false;
  },
  [addInvoiceSuccess.type]: (state, action) => {
    state.uploadedInvoicesIds = action.payload;
    state.saving = false;
  },
  [addInvoiceErrors.type]: (state, action) => {
    state.uploadedInvoicesErrors = action.payload;
    state.saving = false;
  },
  [addInvoiceFailed.type]: (state) => {
    state.savingErrors = true;
    state.saving = false;
  },
  [resetUploadedInvoicesIdsRequest.type]: (state) => {
    state.uploadedInvoicesIds = [];
  },
  [resetUploadedInvoicesErrorsRequest.type]: (state) => {
    state.uploadedInvoicesErrors = null;
  },
});

export const openPraticeReducers = createReducer(initialStateOpenPractice, {
  [getFamilyListRequest.type]: (state) => {
    state.familyPatients.loading = true;
  },
  [getFamilyListSuccess.type]: (state, action) => {
    state.familyPatients.loading = false;
    state.familyPatients.familyList = action.payload.results;
    state.familyPatients.totalCount = action.payload.totalCount;
    state.familyPatients.currentPage = action.payload.currentPage;
    state.familyPatients.prevPage = action.payload.prevPage;
    state.familyPatients.nextPage = action.payload.nextPage;
  },
  [getFamilyListFailed.type]: (state) => {
    state.familyPatients.loading = false;
  },
  [resetFamilyListRequest.type]: (state) => {
    state.familyPatients.loading = false;
    state.familyPatients.familyList = [];
    state.familyPatients.totalCount = 0;
    state.familyPatients.currentPage = 1;
    state.familyPatients.prevPage = null;
    state.familyPatients.nextPage = null;
  },
  [getVenuesListRequest.type]: (state) => {
    state.venues.loading = true;
  },
  [getVenuesListSuccess.type]: (state, action) => {
    state.venues.loading = false;
    state.venues.venuesList = action.payload.results;
    state.venues.totalCount = action.payload.totalCount;
    state.venues.currentPage = action.payload.currentPage;
    state.venues.prevPage = action.payload.prevPage;
    state.venues.nextPage = action.payload.nextPage;
  },
  [getVenuesListFailed.type]: (state) => {
    state.venues.loading = false;
  },
  [resetVenuesListRequest.type]: (state) => {
    state.venues.loading = false;
    state.venues.venuesList = [];
    state.venues.totalCount = 0;
    state.venues.currentPage = 1;
    state.venues.prevPage = null;
    state.venues.nextPage = null;
  },
});

export const createPraticeReducers = createReducer(initialStateCreatePractice, {
  [createPracticeCouponRequest.type]: (state) => {
    state.loading = true;
  },
  [createPracticeCouponSuccess.type]: (state) => {
    state.loading = false;
    state.practiceCreated = true;
    state.errors = false;
  },
  [createPracticeCouponFailed.type]: (state) => {
    state.loading = false;
    state.practiceCreated = undefined;
    state.errors = true;
  },
  [resetCreatePracticeCoupon.type]: (state) => {
    state.loading = false;
    state.practiceCreated = undefined;
    state.errors = undefined;
  },
});

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NavLink as _navLink } from 'reactstrap';
import omit from '../../utils/omitProps';

export const ActiveLine = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: ${({ theme, disabled }) =>
    disabled ? theme.gray200 : theme.primary};
`;

const disabledView = ({ disabled, theme, big }) =>
  disabled &&
  css`
    color: ${theme.white} !important;
    padding: 11px 10px;
    cursor: default;
    justify-content: center;
    min-width: 100px;
    ${big &&
      css`
        background: ${theme.gray300} !important;
        justify-content: flex-start;
        padding: 10px 20px;
      `};
  `;

const bigView = ({ big, theme, direction }) =>
  big &&
  css`
    /* height: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-size: 1.4rem;
    padding: 10px 20px;
    margin: 0 5px;
    :hover,
    &.active {
      color: ${theme.white} !important;
      background: ${theme.primary};
    }

    ${direction === 'vertical' &&
      css`
        margin: 0 0 5px;
        justify-content: flex-start;
      `};
  `;

const verticalView = ({ direction }) =>
  direction === 'vertical' &&
  css`
    min-width: auto;
    padding: 0 10px;
    margin: 0 0 5px;
    border-right: none;
    justify-content: flex-start;
  `;

const style = css`
  position: relative;
  display: inline-block;
  font-size: ${({ type }) => (type === 'outside' ? '1.8rem' : '1.6rem')};
  padding: 11px 10px;
  color: ${({ theme }) => theme.black} !important;
  min-width: 100px;
  width: 100%;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:not(.active) {
    color: ${({ theme }) => theme.gray400};
    ${ActiveLine} {
      display: none;
    }
  }

  &:active {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
  }

  svg {
    margin-right: 10px;
    font-size: 1.6rem;
  }

  ${verticalView};
  ${bigView};
  ${disabledView};
`;

export const NavLinkRoute = styled(
  omit(NavLink, [
    'big',
    'tabPath',
    'isOpen',
    'isEdit',
    'cookiePolicyConsent',
    'googleAnalyticsInitialized',
  ]),
)`
  ${style};
`;

export const NavLinkStrap = styled(
  omit(_navLink, [
    'isOpen',
    'cookiePolicyConsent',
    'googleAnalyticsInitialized',
    'dispatch',
  ]),
)`
  ${style};

  &.active {
    color: black !important;
  }
  &:not(.active) {
    color: ${({ theme }) => theme.gray300}  !important;
  }
`;

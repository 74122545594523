import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Label from '../Label';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import {
  LabelRadio,
  RadioWrap,
  FormGroup,
  ErrorMessage,
  OnlyLabel,
  RadioIcon,
  RadioIconDisabled,
  RadioContainer,
} from './styles';

// eslint-disable-next-line no-unused-vars
function Radio({
  input,
  meta,
  label,
  radioButtons,
  className,
  titleLabel,
  transparent,
  disabled,
  variant,
  requiredfield,
  size,
  paddingLeft,
  vertical,
  labelRadioPadding,
  typeButton,
  radioContainerPadding,
  labelRadioMargin,
  tabIndex,
  ...props
}) {
  const { error, warning, touched } = meta;
  const viewMode = transparent && disabled;
  return (
    <FormGroup variant={variant}>
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={!!(requiredfield || error)}
        />
      )}
      <RadioWrap vertical={vertical}>
        {radioButtons.map(el => {
          if (viewMode && input.value === el.value) {
            return (
              <OnlyLabel
                paddingLeft={paddingLeft}
                key={`only-label-${el.value}`}
              >
                {el.label}
              </OnlyLabel>
            );
          }
          return viewMode ? null : (
            <RadioContainer
              radioContainerPadding={radioContainerPadding}
              typeButton={typeButton}
              checked={input.value === el.value}
              key={`radio-container-${el.value}`}
            >
              <LabelRadio
                margin={labelRadioMargin}
                key={el.value}
                htmlFor={`${input.name}-${el.value}`}
                vertical={vertical}
                checked={input.value === el.value}
                padding={labelRadioPadding}
              >
                <Input
                  name={input.name}
                  id={`${input.name}-${el.value}`}
                  type="radio"
                  value={el.value}
                  onChange={() => input.onChange(el.value)}
                  style={{ display: 'none' }}
                  disabled={el.disabled}
                  bsSize={size}
                  {...props}
                />
                {/*  <RadioIcon
                  checked={input.value === el.value}
                  disabled={el.disabled}
                  typeButton={typeButton}
                  tabIndex={tabIndex}
                /> */}
                {input.value === el.value ? (
                  <RadioIcon
                    checked={input.value === el.value}
                    disabled={el.disabled}
                    typeButton={typeButton}
                    tabIndex={tabIndex}
                  />
                ) : (
                  <RadioIconDisabled
                    checked={input.value === el.value}
                    disabled={el.disabled}
                    typeButton={typeButton}
                    tabIndex={tabIndex}
                  />
                )}
                <span>{el.label}</span>
              </LabelRadio>
            </RadioContainer>
          );
        })}
      </RadioWrap>
      {touched &&
        ((error && (
          <ErrorMessage>
            {errorMessages[isString(error)] ? (
              <FormattedMessage
                values={typeof error !== 'object' ? {} : error}
                {...errorMessages[isString(error)]}
              />
            ) : (
              error
            )}
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}

Radio.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  radioButtons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string.isRequired,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['small']),
  requiredfield: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingLeft: PropTypes.string,
  vertical: PropTypes.bool,
  labelRadioPadding: PropTypes.string,
  typeButton: PropTypes.string,
  labelRadioMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radioContainerPadding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tabIndex: PropTypes.number,
};

Radio.defaultProps = {
  label: '',
  titleLabel: '',
  meta: {},
  transparent: null,
  disabled: false,
  requiredfield: false,
  vertical: false,
  typeButton: '',
  tabIndex: -1,
};

export default Radio;

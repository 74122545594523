import styled, { css } from 'styled-components';
import _datePicker from 'react-datepicker';

const getHeight = (size, theme) => {
  if (size === 'sm') return theme.inputHeightSm;
  if (size === 'lg') return theme.inputHeightLg;
  return theme.inputHeight;
};
const getFontSize = size => {
  if (size === 'sm') return '1rem';
  if (size === 'lg') return '1.8rem';
  return '1.6rem';
};

const getBorderRadius = (size, theme) => {
  if (size === 'sm') return theme.inputBorderRadiusSm;
  if (size === 'lg') return theme.inputBorderRadiusLg;
  return theme.inputBorderRadius;
};

const getPaddingOption = size => {
  if (size === 'sm') return '10px 10px';
  if (size === 'lg') return '10px 25px';
  return '10px 20px';
};

export const DatePicker = styled(_datePicker)`
  border-radius: ${({ theme, size }) => getBorderRadius(size, theme)};
  font-size: ${({ theme, size }) => getFontSize(size, theme)};
  height: ${({ theme, size }) => getHeight(size, theme)};
  border: 1px solid ${({ theme }) => theme.gray400};
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  width: 100%;
  padding: ${({ theme, size }) => getPaddingOption(size, theme)};
  line-height: 1.5;
  outline: none;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.gray100};
      color: ${({ theme }) => theme.gray300};
      border-color: ${({ theme }) => theme.gray200};
    `} &:focus {
    background-color: ${({ theme }) => theme.gray100};
    border-color: ${({ theme }) => theme.gray100};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.gray200};
  }
  ${({ theme, touched, error }) =>
    touched &&
    error &&
    css`
      border-color: ${theme.danger};
    `};
  ${({ theme, dirty, active }) =>
    !active &&
    dirty &&
    css`
      border-color: ${theme.warning};
    `};
  ${({ transparent, theme }) =>
    transparent &&
    css`
      background-color: ${theme.white} !important;
      border-color: ${theme.white} !important;
      color: ${theme.black} !important;
      font-weight: 600;
      font-size: 1.6rem;
      ${({ paddingLeft }) =>
        paddingLeft &&
        css`
          padding-left: ${paddingLeft}px;
        `};
    `};
`;

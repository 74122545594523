export const TabsStyles = {
  // The parts of the component
  parts: ['tab', 'tabpanel'],
  // Styles for the base style
  baseStyle: {
    tab: {
      borderTopRadius: '10px',
      color: 'grey2',
      fontWeight: 400,
      height: '60px',
      // _selected: {
      //   bg: 'grey4',
      //   borderBottom: '6px solid',
      //   borderColor: '#FCB731',
      //   // borderBottomWidth: '6px',
      //   color: '#000',
      // },
    },
    tabpanel: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};

import { createAction } from '@reduxjs/toolkit';
import {
  GetFAQListRequestPayload,
  GetFAQListSuccessPayload,
  GetTicketsListRequestPayload,
  GetTicketsListSuccessPayload,
  SetSearchFAQFilters,
  SetSearchTicketsFilters,
  GetPracticeTicketsListRequestPayload,
  GetPracticeTicketsListSuccessPayload,
} from './types';

export const getFAQListRequest = createAction<GetFAQListRequestPayload>('ACTION/GET_FAQ_LIST_REQUEST');
export const getFAQListSuccess = createAction<GetFAQListSuccessPayload>('ACTION/GET_FAQ_LIST_SUCCESS');
export const getFAQListFailed = createAction('ACTION/GET_FAQ_LIST_FAILED');
export const resetFAQList = createAction('ACTION/GET_FAQ_LIST_FAILED');

export const setSearchFAQListFilters = createAction<SetSearchFAQFilters>('ACTION/SET_SEARCH_FAQ_FILTERS');
export const resetSearchFAQListFilters = createAction('ACTION/RESET_SEARCH_FAQ_LIST_FILTERS');

export const getTicketsListRequest = createAction<GetTicketsListRequestPayload>('ACTION/GET_TICKETS_LIST_REQUEST');
export const getTicketsListSuccess = createAction<GetTicketsListSuccessPayload>('ACTION/GET_TICKETS_LIST_SUCCESS');
export const getTicketsListFailed = createAction('ACTION/GET_TICKETS_LIST_FAILED');
export const resetTicketsList = createAction('ACTION/GET_TICKETS_LIST_FAILED');

export const setSearchTicketsListFilters = createAction<SetSearchTicketsFilters>('ACTION/SET_SEARCH_TICKETS_FILTERS');
export const resetSearchTicketsListFilters = createAction('ACTION/RESET_SEARCH_TICKETS_LIST_FILTERS');

export const getAllPracticeTicketsRequest = createAction<GetPracticeTicketsListRequestPayload>(
  'ACTION/GET_PRACTICE_TICKETS_REQUEST',
);
export const getAllPracticeTicketsSuccess = createAction<GetPracticeTicketsListSuccessPayload>(
  'ACTION/GET_PRACTICE_TICKETS_SUCCESS',
);
export const getAllPracticeTicketsFailed = createAction('ACTION/GET_PRACTICE_TICKETS_FAILED');

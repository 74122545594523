import React from 'react';

const ChangeEmailPassword = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>change-email-password</title>
    <g fill="currentColor">
      <path d="M11.62 21.35h6.56a2.51 2.51 0 0 0 2.5-2.5v-3.6a2.51 2.51 0 0 0-2.5-2.5H18v-1.4a2.19 2.19 0 0 0-1-1.75 3.75 3.75 0 0 0-2.1-.6 3.71 3.71 0 0 0-2.16.65 2.19 2.19 0 0 0-1 1.75v1.4h-.14a2.5 2.5 0 0 0-2.5 2.5v3.6a2.5 2.5 0 0 0 2.52 2.45zm1.14-10a1.19 1.19 0 0 1 .56-.94A2.79 2.79 0 0 1 14.9 10a2.76 2.76 0 0 1 1.57.46 1.18 1.18 0 0 1 .57.94v1.4h-4.28zm-2.64 3.9a1.51 1.51 0 0 1 1.5-1.5h6.56a1.52 1.52 0 0 1 1.5 1.5v3.6a1.52 1.52 0 0 1-1.5 1.5h-6.56a1.51 1.51 0 0 1-1.5-1.5z" />
      <path d="M26.52 6.79a.5.5 0 0 0-.5-.5h-3.77a.5.5 0 0 0-.35.14.51.51 0 0 0-.15.36v3.8a.5.5 0 1 0 1 0V8.05a10.56 10.56 0 0 1-.46 14.37 10.37 10.37 0 0 1-14.75 0 10.57 10.57 0 0 1 0-14.88.5.5 0 0 0 0-.71.49.49 0 0 0-.7 0 11.56 11.56 0 0 0 0 16.28 11.36 11.36 0 0 0 16.17 0 11.55 11.55 0 0 0 .41-15.83H26a.5.5 0 0 0 .52-.49z" />
    </g>
  </svg>
);

export default ChangeEmailPassword;

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirmAction: () => void;
  title?: string;
  description?: string;
  children?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  type?: string;
}

const ContainerModal: FC<Props> = ({
  isOpen = false,
  onClose,
  title = '',
  description = '',
  onConfirmAction,
  children,
  disabled = false,
  isLoading = false,
  type = '',
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="lg">
      <ModalOverlay />
      <ModalContent px="56px" py="40px">
        <ModalHeader p="0px" mb="10px">
          <Text textStyle="h3" textAlign="center">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton h="30px" size="24px" w="30px" mt="16px" mr="16px" />
        <ModalBody p={0}>
          <Text textStyle="normalGrey2" fontSize="md" textAlign="center">
            {description}
          </Text>
          <Flex mb="56px" mt="40px">
            {children}
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center" p={0}>
          <Button
            variant={type === 'reset_psw_username' ? 'primaryOutlineModal' : 'primaryOutline'}
            size="primaryOutlineBig"
            onClick={onClose}
            mr="24px"
          >
            {t('web.pu.Modal.Cancel')}
          </Button>
          <Button
            variant={type === 'reset_psw_username' ? 'primaryModal' : 'primary'}
            size="primaryBig"
            onClick={onConfirmAction}
            isDisabled={disabled}
          >
            {isLoading ? <Spinner color="white" /> : t('web.pu.Modal.SendRequest')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContainerModal;

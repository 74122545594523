import { call, put, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import OneSignal from 'components/shared/OneSignal';
import { pushError } from 'containers/Alerts/actions';
import { logoutRequest } from 'containers/Auth/actions';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { selectProfileData } from '../selectors';

export function* deleteUserAccount({ intl }) {
  const url = `zendesk/tickets`;

  yield put(showLoading());

  try {
    const { username } = yield select(selectProfileData());
    const message = intl.formatMessage(
      {
        id:
          'web.partner.MyProfile.GeneralDataProtection.accountDeletionZendeskMessage',
      },
      { username },
    );

    const { status } = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        subject: message,
        body: message, // 'body' cannot be empty
      },
    });

    if (status === 'success') {
      OneSignal.push(() => {
        OneSignal.setSubscription(false);
        OneSignal.deleteTag('user');
        OneSignal.logoutEmail();
      });

      yield put(logoutRequest());
    }
  } catch ({ packet, response }) {
    yield put(pushError({ text: (packet || {}).message }));
  } finally {
    yield put(hideLoading());
  }
}

import styled from 'styled-components';
import { Modal as _modal } from '@components';

export const Modal = styled(_modal)`
  & .modal-title {
    font-size: 2rem;
    font-weight: 700;
  }
  & .modal-body {
    font-size: 2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.gray300};
    & span {
      padding: 0 20px;
    }
  }
`;

import { createReducer } from '@reduxjs/toolkit';
import {
  createOpDataDetailsAccessesFailed,
  createOpDataDetailsAccessesRequest,
  createOpDataDetailsAccessesSuccess,
  editOpDataDetailsAccessesFailed,
  editOpDataDetailsAccessesRequest,
  editOpDataDetailsAccessesSuccess,
  getAllOpsAccessesFailed,
  getAllOpsAccessesRequest,
  getAllOpsAccessesSuccess,
  getOpDetailsAccessesFailed,
  getOpDetailsAccessesRequest,
  getOpDetailsAccessesSuccess,
  getOpVenuesListFailed,
  getOpVenuesListRequest,
  getOpVenuesListSuccess,
  resetAllOpsAccessesList,
  resetCreateOpDataDetailsAccesses,
  resetCreateOpExists,
  resetOpDetailsAccessesList,
  resetOpVenuesListRequest,
  resetSearchAllOpsAccessesFilters,
  setSearchAllOpsAccessesFilters,
} from './actions';
import { LocationsEntity, Settings, User, Venues } from './types';

export interface OperatorsProps {
  loading: boolean;
  totalCount: number;
  currentPage: number;
  prevPage: null;
  nextPage: null;
  opsList: [];
  opsFilter: {
    textFilter: string;
  };
}

export interface OperatorsDetails {
  loading: boolean;
  editLoading: boolean;
  user: User | undefined;
  partnerId: number | undefined;
  operatorId: number | undefined;
  settings: Settings | undefined;
  locations?: LocationsEntity[] | [];
  venues: {
    loading: boolean;
    totalCount: number;
    currentPage: number;
    prevPage: number | null;
    nextPage: number | null;
    venuesList: Venues[] | [];
  };
  createOp: {
    loading: boolean;
    showModal: boolean;
    exists: boolean;
    showModalOldUser: boolean;
  };
}

const initialStateOpsList: OperatorsProps = {
  loading: false,
  totalCount: 0,
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  opsList: [],
  opsFilter: {
    textFilter: '',
  },
};

const initialStateOpDetails: OperatorsDetails = {
  loading: false,
  editLoading: false,
  user: undefined,
  partnerId: undefined,
  operatorId: undefined,
  settings: undefined,
  locations: [],
  venues: {
    loading: false,
    totalCount: 0,
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    venuesList: [],
  },
  createOp: {
    loading: false,
    showModal: false,
    exists: false,
    showModalOldUser: false,
  },
};

export const opsAccessesReducers = createReducer(initialStateOpsList, {
  [getAllOpsAccessesRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllOpsAccessesSuccess.type]: (state, action) => {
    state.loading = false;
    state.opsList = action.payload.results;
    state.totalCount = action.payload.totalCount;
    state.currentPage = action.payload.currentPage;
    state.prevPage = action.payload.prevPage;
    state.nextPage = action.payload.nextPage;
  },
  [getAllOpsAccessesFailed.type]: (state) => {
    state.loading = false;
  },
  [setSearchAllOpsAccessesFilters.type]: (state, action) => {
    state.opsFilter.textFilter = action.payload.textFilter;
  },
  [resetSearchAllOpsAccessesFilters.type]: (state) => {
    state.opsList = [];
    state.totalCount = 0;
    state.currentPage = 1;
    state.prevPage = null;
    state.nextPage = null;
  },
  [resetAllOpsAccessesList.type]: (state) => {
    state.opsFilter.textFilter = '';
  },
});

export const opDetailsAccessesReducers = createReducer(initialStateOpDetails, {
  [getOpDetailsAccessesRequest.type]: (state) => {
    state.loading = true;
  },
  [getOpDetailsAccessesSuccess.type]: (state, action) => {
    state.loading = false;
    state.editLoading = false;
    state.user = action.payload.user;
    state.partnerId = action.payload.partnerId;
    state.operatorId = action.payload.operatorId;
    state.settings = action.payload.settings;
    state.locations = action.payload.locations;
  },
  [getOpDetailsAccessesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetOpDetailsAccessesList.type]: (state) => {
    state.loading = false;
    state.user = undefined;
    state.partnerId = undefined;
    state.operatorId = undefined;
    state.settings = undefined;
    state.locations = [];
  },
  [editOpDataDetailsAccessesRequest.type]: (state) => {
    state.editLoading = true;
  },
  [editOpDataDetailsAccessesSuccess.type]: (state) => {
    state.editLoading = false;
  },
  [editOpDataDetailsAccessesFailed.type]: (state) => {
    state.editLoading = false;
  },
  [getOpVenuesListRequest.type]: (state) => {
    state.venues.loading = true;
  },
  [getOpVenuesListSuccess.type]: (state, action) => {
    state.venues.loading = false;
    state.venues.venuesList = action.payload.results;
    state.venues.totalCount = action.payload.totalCount;
    state.venues.currentPage = action.payload.currentPage;
    state.venues.prevPage = action.payload.prevPage;
    state.venues.nextPage = action.payload.nextPage;
  },
  [getOpVenuesListFailed.type]: (state) => {
    state.venues.loading = false;
  },
  [resetOpVenuesListRequest.type]: (state) => {
    state.venues.loading = false;
    state.venues.venuesList = [];
    state.venues.totalCount = 0;
    state.venues.currentPage = 1;
    state.venues.prevPage = null;
    state.venues.nextPage = null;
  },
  [createOpDataDetailsAccessesRequest.type]: (state) => {
    state.createOp.loading = true;
  },
  [createOpDataDetailsAccessesSuccess.type]: (state, action) => {
    state.createOp.loading = false;
    if (action.payload.userReactivation) {
      state.createOp.showModalOldUser = true;
    } else {
      state.createOp.showModal = true;
    }
  },
  [createOpDataDetailsAccessesFailed.type]: (state, action) => {
    if (action.payload.error === 'errors.partner.email_already_exists_unassigned') {
      state.createOp.exists = true;
    }
    state.createOp.loading = false;
    state.createOp.showModal = false;
  },
  [resetCreateOpDataDetailsAccesses.type]: (state) => {
    state.createOp.loading = false;
    state.createOp.showModal = false;
  },
  [resetCreateOpExists.type]: (state) => {
    state.createOp.exists = false;
    state.createOp.loading = false;
    state.createOp.showModal = false;
    state.createOp.showModalOldUser = false;
  },
});

import styled from 'styled-components';
import { Family as _icoFamily } from 'utils/icons';

export const Wrapper = styled.div`
  position: relative;
`;

export const Initials = styled.div`
  font-size: ${({ size }) => size / 2}px;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FamilyIcon = styled(_icoFamily)`
  font-size: 2rem;
  color: ${({ selected, theme }) => (selected ? theme.hfPrimary : theme.black)};
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

export const AvatarSelection = styled.div`
  width: ${({ size }) => size || '50'}px;
  height: ${({ size }) => size || '50'}px;
  border: 3px solid;
  border-color: ${({ selected, theme }) =>
    selected ? theme.hfPrimary : 'transparent'};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

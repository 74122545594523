import { createAction } from '@reduxjs/toolkit';
import {
  GetInvoicesListRequestPayload,
  GetInvoicesListSuccessPayload,
  SetSearchInvoicesListFilters,
  CheckInvoicesRequestPayload,
  CheckInvoicesSuccessPayload,
} from './types';

export const getInvoicesRequest = createAction<GetInvoicesListRequestPayload>('ACTION/GET_INVOICES_LIST_REQUEST');
export const getInvoicesSuccess = createAction<GetInvoicesListSuccessPayload>('ACTION/GET_INVOICES_LIST_SUCCESS');
export const getInvoicesFailed = createAction('ACTION/GET_INVOICES_LIST_FAILED');

export const checkInvoicesRequest = createAction<CheckInvoicesRequestPayload>('ACTION/CHECK_INVOICES_REQUEST');
export const checkInvoicesSuccess = createAction<CheckInvoicesSuccessPayload>('ACTION/CHECK_INVOICES_SUCCESS');
export const checkInvoicesFailed = createAction('ACTION/CHECK_INVOICES_FAILED');

export const setSearchInvoicesListFilters = createAction<SetSearchInvoicesListFilters>(
  'ACTION/SET_SEARCH_INVOICES_LIST_FILTERS',
);
export const resetSearchInvoicesListFilters = createAction('ACTION/RESET_SEARCH_INVOICES_LIST_FILTERS');
export const setInvoicesListInitialPage = createAction('ACTION/SET_INVOICES_INITIAL_PAGE');

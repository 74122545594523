import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { change as reduxFormChange } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { selectFetchHeaders } from '../selectors';
import { hostname } from '../../utils/env';
// TODO: importate da partners
import { getFetchHeaders } from '../../../../app/utils/Api/utils';
// TODO: *********************
import FileDropInput from './FileDropInput';
import typesMapper from './fileTypesMapper';

const mapStateToProps = createStructuredSelector({
  fetchHeaders: selectFetchHeaders,
});

const mapDispatchToProps = {
  changeField: reduxFormChange,
};

const handleDropAccepted = ({
  meta,
  input,
  setValue,
  setSpinner,
  changeField,
  fetchHeaders,
  onDroppedFile,
  onFileUploaded,
  setUploadSizeError,
  setUploadTypeError,
  setUploadGenericError,
  setFileCustomError,
  customHandleDropAccepted,
}) => async dropped => {
  setSpinner(true);
  setUploadSizeError(false);
  setUploadTypeError(false);
  setUploadGenericError(false);
  if (customHandleDropAccepted) {
    customHandleDropAccepted({
      dropped,
      meta,
      input,
      setValue,
      setSpinner,
      changeField,
      fetchHeaders,
      onFileUploaded,
      setUploadSizeError,
      setUploadTypeError,
      setUploadGenericError,
      setFileCustomError,
      customHandleDropAccepted,
    });
    return null;
  }

  if (onDroppedFile) {
    onDroppedFile(dropped);
    setSpinner(false);
    return null;
  }

  const imageData = new FormData();
  const url = `attachments`;
  const options = {
    method: 'POST',
    headers: getFetchHeaders(url, fetchHeaders),
    body: JSON.stringify({ filename: dropped[0].name }),
  };
  changeField(meta.form, input.name, {});

  try {
    const {
      response: { status, data },
    } = await fetch(`${hostname}/${url}`, options)
      .then(response => response.json())
      .then(response => Promise.resolve({ response }));

    if (status !== 'success') {
      setSpinner(false);
      return;
    }

    const {
      postPolicy: { formData, postURL: postUrl },
      postPolicy,
      attachment: { id: attachmentId },
    } = data;

    Object.keys(formData).forEach(key => {
      imageData.append(key, formData[key]);
    });
    imageData.append('file', dropped[0]);

    // Upload image
    await fetch(postUrl, {
      method: 'POST',
      body: imageData,
    });

    // Update field with attachmentId
    if (meta !== undefined) {
      changeField(meta.form, input.name, { id: attachmentId });
    }

    setValue(dropped[0].name, postPolicy);
    if (onFileUploaded) onFileUploaded();
  } catch (e) {
    setUploadGenericError(true);
  }
  setSpinner(false);
};

const handleDropRejected = ({
  setSpinner,
  fileTypesMap,
  maxFileSizeBytes,
  setUploadSizeError,
  setUploadTypeError,
  setUploadGenericError,
}) => async dropped => {
  setSpinner(false);
  if (fileTypesMap.map(({ type }) => type).indexOf(dropped[0].type) < 0) {
    setUploadTypeError(true);
  } else if (dropped[0].size > maxFileSizeBytes) {
    setUploadSizeError(true);
  } else {
    setUploadGenericError(true);
  }
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(({ maxFileSizeMB, fileTypes, fileName, onDroppedFile, input }) => {
    let fileTypesMap = [];
    let typeLabel = '';
    let typeFilter = null;

    if (fileTypes !== '*') {
      fileTypesMap = typesMapper.filter(
        ({ key }) => (fileTypes || 'PDF,JPG,PNG,GIF').indexOf(key) >= 0,
      );
      typeLabel = fileTypesMap.map(({ label }) => label).toString();
      typeFilter = fileTypesMap.map(({ type }) => type).toString();
    }

    return {
      file: (onDroppedFile ? (input || {}).value : fileName) || '',
      typeLabel,
      typeFilter,
      maxFileSizeMB: maxFileSizeMB || 1,
      maxFileSizeBytes: (maxFileSizeMB || 1) * 1024 * 1024,
      fileTypesMap,
    };
  }),
  withStateHandlers(
    {
      getData: '',
      loading: false,
      fileSizeError: false,
      fileTypeError: false,
      fileGenericError: false,
      fileCustomError: '',
    },
    {
      setValue: () => (value, formData) => ({
        file: value,
        getData: formData,
      }),
      setSpinner: () => value => ({
        loading: value,
      }),
      setUploadSizeError: () => value => ({
        fileSizeError: value,
      }),
      setUploadTypeError: () => value => ({
        fileTypeError: value,
      }),
      setUploadGenericError: () => value => ({
        fileGenericError: value,
      }),
      setFileCustomError: () => value => ({
        fileGenericError: value,
      }),
    },
  ),
  withHandlers({
    handleDropAccepted,
    handleDropRejected,
    clearInput: ({
      setValue,
      changeField,
      input,
      meta,
      deleteCallBack,
    }) => e => {
      e.stopPropagation();
      if (meta !== undefined) {
        changeField(meta.form, input.name, null);
      }
      setValue('', '');
      if (deleteCallBack) deleteCallBack();
    },
  }),
)(FileDropInput);

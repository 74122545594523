import { createAction } from '@reduxjs/toolkit';
import {
  CreateOpAccessErrorPayload,
  CreateOpAccessRequestPayload,
  CreateOpAccessSuccessPayload,
  EditOpDataAffiliationRequestPayload,
  GetAllOpsAccessesRequestPayload,
  GetAllOpsAccessesSuccessPayload,
  GetOpDetailsRequestPayload,
  GetOpDetailsSuccessPayload,
  GetVenuesListRequestPayload,
  GetVenuesListSuccessPayload,
  SetSearchOpsAccessesFilters,
} from './types';

export const getAllOpsAccessesRequest = createAction<GetAllOpsAccessesRequestPayload>(
  'ACTION/GET_ALL_OPS_ACCESSES_REQUEST',
);
export const getAllOpsAccessesSuccess = createAction<GetAllOpsAccessesSuccessPayload>(
  'ACTION/GET_ALL_OPS_ACCESSES_SUCCESS',
);
export const getAllOpsAccessesFailed = createAction('ACTION/GET_ALL_OPS_ACCESSES_FAILED');
export const setSearchAllOpsAccessesFilters = createAction<SetSearchOpsAccessesFilters>(
  'ACTION/SET_SEARCH_OPS_ACCESSES_FILTERS',
);
export const resetSearchAllOpsAccessesFilters = createAction('ACTION/RESET_SEARCH_OPS_ACCESSES_FILTERS');
export const resetAllOpsAccessesList = createAction('ACTION/RESET_ALL_OPS_ACCESSES_LIST');

export const getOpDetailsAccessesRequest = createAction<GetOpDetailsRequestPayload>(
  'ACTION/GET_OP_DETAILS_ACCESSES_REQUEST',
);
export const getOpDetailsAccessesSuccess = createAction<GetOpDetailsSuccessPayload>(
  'ACTION/GET_OP_DETAILS_ACCESSES_SUCCESS',
);
export const getOpDetailsAccessesFailed = createAction('ACTION/GET_OP_DETAILS_ACCESSES_FAILED');
export const resetOpDetailsAccessesList = createAction('ACTION/RESET_OP_DETAILS_ACCESSES_LIST');
export const deleteOpAccessesRequest = createAction<GetOpDetailsRequestPayload>('ACTION/DELETE_OP_ACCESSES_REQUEST');
export const deleteOpAccessesSuccess = createAction<GetOpDetailsSuccessPayload>('ACTION/DELETE_OP_ACCESSES_SUCCESS');
export const deleteOpAccessesFailed = createAction('ACTION/DELETE_OP_ACCESSES_FAILED');

export const editOpDataDetailsAccessesRequest = createAction<EditOpDataAffiliationRequestPayload>(
  'ACTION/EDIT_OP_DATA_DETAILS_ACCESSES_REQUEST',
);
export const editOpDataDetailsAccessesSuccess = createAction('ACTION/EDIT_OP_DATA_DETAILS_ACCESSES_SUCCESS');
export const editOpDataDetailsAccessesFailed = createAction('ACTION/EDIT_OP_DATA_DETAILS_ACCESSES_FAILED');

export const getOpVenuesListRequest = createAction<GetVenuesListRequestPayload>('ACTION/GET_OP_VENUES_LIST_REQUEST');
export const getOpVenuesListSuccess = createAction<GetVenuesListSuccessPayload>('ACTION/GET_OP_VENUES_LIST_SUCCESS');
export const getOpVenuesListFailed = createAction('ACTION/GET_OP_VENUES_LIST_FAILED');
export const resetOpVenuesListRequest = createAction('ACTION/RESET_OP_VENUES_LIST');

export const createOpDataDetailsAccessesRequest = createAction<CreateOpAccessRequestPayload>(
  'ACTION/CREATE_OP_ACCESSES_REQUEST',
);
export const createOpDataDetailsAccessesSuccess = createAction<CreateOpAccessSuccessPayload>(
  'ACTION/CREATE_OP_ACCESSES_SUCCESS',
);
export const createOpDataDetailsAccessesFailed = createAction<CreateOpAccessErrorPayload>(
  'ACTION/CREATE_OP_ACCESSES_FAILED',
);
export const resetCreateOpDataDetailsAccesses = createAction('ACTION/RESET_OP_ACCESSES_DATA');
export const resetCreateOpExists = createAction('ACTION/RESET_OP_EXISTS');

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import ButtonTooltip from './ButtonTooltip';
import { SwitchWrapper, Label } from './styles';

function Filters({
  filters,
  formValues,
  removeFilter,
  selectField,
  selectedField,
  valid,
  ignoredFilters,
  applyFilter,
  removeSingleFilter,
  disabledKeys,
}) {
  return (
    <Fragment>
      {filters.map(({ onlySwitch, ...filter }, index) =>
        onlySwitch ? (
          <Fragment>
            {filter &&
              Object.keys(filter.inputs).map((name) => {
                const input = filter.inputs[name];
                const { label } = filter.inputs[name];
                const { value } =
                  formValues?.filter?.find(
                    ({ index: indexValue }) => indexValue === input.index,
                  ) ?? {};
                return (
                  <SwitchWrapper>
                    <Field
                      {...input}
                      key={input.index}
                      name={`filter[${input.index}].value`}
                      component={input.Component}
                      checked={value === 'toBeUploaded'}
                      onChange={() => {
                        if (!value) {
                          // eslint-disable-next-line no-param-reassign
                          formValues.filter[input.index].value = 'toBeUploaded';
                          applyFilter({
                            filter: !value && formValues.filter[input.index],
                          });
                        } else removeSingleFilter({ indexField: input.index });
                      }}
                    />
                    <Label>{label}</Label>
                  </SwitchWrapper>
                );
              })}
          </Fragment>
        ) : (
          <ButtonTooltip
            key={filter.key}
            icon={filter.icon}
            filterName={filter.filterName}
            index={index}
            filters={filters}
            width="auto"
            selectField={(e) => {
              e.stopPropagation();
              selectField(
                (selectedField || {}).key !== filter.key ? index : null,
              );
            }}
            selectedField={selectedField}
            removeFilter={removeFilter}
            formValues={formValues}
            inputs={filter.inputs}
            valid={valid}
            ignoredFilters={ignoredFilters}
            disabled={(disabledKeys || []).includes(filter.key)}
          >
            <Fragment>
              {filter &&
                Object.keys(filter.inputs).map((name) => {
                  const input = filter.inputs[name];
                  return (
                    <Field
                      {...input}
                      key={input.index}
                      name={`filter[${input.index}].value`}
                      component={input.Component}
                      marginBottom="20px"
                    />
                  );
                })}
            </Fragment>
          </ButtonTooltip>
        ),
      )}
    </Fragment>
  );
}

Filters.propTypes = {
  formValues: PropTypes.shape({}),
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeFilter: PropTypes.func.isRequired,
  selectField: PropTypes.func.isRequired,
  selectedField: PropTypes.shape({}),
  valid: PropTypes.bool.isRequired,
  ignoredFilters: PropTypes.arrayOf(PropTypes.string),
  applyFilter: PropTypes.func.isRequired,
  removeSingleFilter: PropTypes.func.isRequired,
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
};

Filters.defaultProps = {};

export default Filters;

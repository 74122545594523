import { all, select, put, takeLatest, call } from 'redux-saga/effects';
import ReactGA from 'react-ga';
/* import { pushError } from 'containers/Alerts/actions'; */
import fetchWrapper from 'utils/Api/fetchWrapper';
import { env, googleAnalyticsId } from 'utils/env';
import * as Cookies from 'js-cookie';
import {
  initializeGoogleAnalyticsAction,
  generateCookiePolicyCookieAction,
  setCookiePolicyConsentAction,
  setGoogleAnalyticsInitializedAction,
  getUnreadCommunicationsCountAction,
  setUnreadCommunicationsCountAction,
} from './actions';
import { makeSelectGoogleAnalyticsInitialized } from './selectors';

export function* initializeGoogleAnalytics() {
  const isInitialized = yield select(makeSelectGoogleAnalyticsInitialized());
  if (!isInitialized) {
    ReactGA.initialize(googleAnalyticsId, {
      cookieDomain: 'auto',
      debug: env === 'development' && true,
    });
    yield put(setGoogleAnalyticsInitializedAction());
  }
}

export function* generateCookiePolicyCookie() {
  const cookies = Cookies.get();
  if ((cookies || {}).cookiePolicyConsent === undefined)
    yield put(setCookiePolicyConsentAction({ consent: 'false' }));
  else if (cookies.cookiePolicyConsent === 'true')
    yield put(initializeGoogleAnalyticsAction());
}

export function* setCookiePolicyConsent({ consent }) {
  Cookies.set('cookiePolicyConsent', consent, { expires: 365 });
  if (consent === 'true') yield put(initializeGoogleAnalyticsAction());
}

export function* getUnreadCommunications() {
  const url = `communications/persist/me/unread`;
  try {
    const { status, data } = yield call(fetchWrapper, { url });
    if (status === 'success') {
      yield put(
        setUnreadCommunicationsCountAction({ communicationsCount: data.count }),
      );
    }
  } catch ({ err, response }) {
    /* yield put(pushError({ text: 'getAreUnreadCommunicationsError' })); */
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(initializeGoogleAnalyticsAction, initializeGoogleAnalytics),
    takeLatest(generateCookiePolicyCookieAction, generateCookiePolicyCookie),
    takeLatest(setCookiePolicyConsentAction, setCookiePolicyConsent),
    takeLatest(getUnreadCommunicationsCountAction, getUnreadCommunications),
  ]);
}

import { APIClientPU } from '@common/common/utils';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import {
  GetInvoicesListRequestPayload,
  GetInvoicesListSuccessPayload,
  CheckInvoicesRequestPayload,
  CheckInvoicesSuccessPayload,
} from './types';

export async function getInvoicesList({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetInvoicesListRequestPayload): Promise<GetInvoicesListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'eq',
          value: currentTextSearch,
        },
      ]
    : [];

  const domainFilter =
    popoverFilters?.domain !== undefined
      ? popoverFilters?.domain && {
          field: 'aliasDomain',
          operator: 'eq',
          value: popoverFilters?.domain,
        }
      : null;

  const dateFromFilter =
    popoverFilters?.dateFrom !== undefined
      ? popoverFilters?.dateFrom && {
          field: 'dateFrom',
          operator: 'eq',
          value: popoverFilters?.dateFrom,
        }
      : null;

  const dateToFilter =
    popoverFilters?.dateTo !== undefined
      ? popoverFilters?.dateTo && {
          field: 'dateTo',
          operator: 'eq',
          value: popoverFilters?.dateTo,
        }
      : null;

  const venuesFilter = !isEmpty(popoverFilters?.venues)
    ? {
        field: 'providerId',
        operator: 'in',
        value: popoverFilters?.venues,
      }
    : null;

  const categoryFilter =
    popoverFilters?.category !== undefined
      ? popoverFilters?.category && {
          field: 'category',
          operator: 'eq',
          value: popoverFilters?.category,
        }
      : null;

  const statusFilter = !isEmpty(popoverFilters?.status)
    ? {
        field: 'aliasStatus',
        operator: 'in',
        value: popoverFilters?.status,
      }
    : null;

  const filtersBy = !isEmpty(popoverFilters)
    ? [domainFilter, dateFromFilter, dateToFilter, venuesFilter, categoryFilter, statusFilter]
    : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(searchTextFilter) || !isEmpty(filtersBy)
      ? {
          filter: [...searchTextFilter, ...without(filtersBy, undefined, null)],
        }
      : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetInvoicesListSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/invoices/me/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getInvoicesList - Error: ', error);
    throw error;
  }
}

export async function checkInvoices({
  ticketNumber,
  invoiceType,
  checks,
  invoices,
}: CheckInvoicesRequestPayload): Promise<CheckInvoicesSuccessPayload> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<CheckInvoicesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${ticketNumber}/invoices/checks`,
      {
        invoiceType,
        checks,
        invoices,
      },
    );
    return response.data;
  } catch (error) {
    console.error('checkInvoices - Error: ', error);
    throw error;
  }
}

import { call, put, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import ReactGA, { event } from 'react-ga';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { saveCurrentPracticeDataRequest } from 'containers/PartnersRegistration/actions';
import { uploadAttachment } from 'utils/Api/attachments';
import { pushError } from 'containers/Alerts/actions';
import pick from 'lodash/pick';
import { selectPartnerType } from '../selectors';
import { profileResponse, editPartnerProfileRequest } from '../actions';

const acceptProperty = [
  'id',
  'username',
  'email',
  'firstName',
  'lastName',
  'gender',
  'SSN',
  'birthDate',
  'birthplaceId',
  'pecAddress',
  'phone',
  'zipCode',
  'cityId',
  'address',
  'profilePictureId',
  'confirm',
];

export function* setProfileData({
  data: dataMap,
  partnerRegistration,
  confirm,
  analytics,
}) {
  const url = `users/me/profile`;
  const params = pick(dataMap, acceptProperty);
  const partnerType = yield select(selectPartnerType());

  try {
    yield put(showLoading());

    let profilePictureId = dataMap.profilePictureId || null;
    if (dataMap.file && dataMap.file[0]) {
      const { id } = yield call(uploadAttachment, dataMap.file[0]);
      profilePictureId = id;
    }

    const { data } = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: {
        ...params,
        profilePictureId,
      },
    });

    const profile = {
      data: {},
      section: 'profile',
      confirm,
    };

    yield put(saveCurrentPracticeDataRequest(profile));
    yield put(profileResponse({ data }));

    if (partnerType !== 'structure')
      yield put(
        editPartnerProfileRequest({
          data: dataMap,
          partnerRegistration,
          confirm,
        }),
      );
    else
      yield put(
        pushError({ text: 'setProfileDataSuccess', typeError: 'success' }),
      );

    if (analytics) {
      if (analytics.metrics) ReactGA.set(analytics.metrics);
      if (analytics.inStep) event(analytics.inStep);
    }
  } catch (e) {
    if (e.status === 422) {
      yield put(pushError({ text: 'getProfileDataError' }));
    } else {
      yield put(pushError({ text: 'getProfileError' }));
    }
  } finally {
    yield put(hideLoading());
  }
}

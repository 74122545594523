import { defineMessages } from 'react-intl';

export default defineMessages({
  community: {
    id: 'web.partner.headers.links.community',
    defaultMessage: 'Community',
  },
  needHelp: {
    id: 'web.partner.headers.links.needHelp',
    defaultMessage: 'Do you need help?',
  },
});

import { createAction } from '@reduxjs/toolkit';
import {
  GetAllDoctorsForSingleLocationRequestPayload,
  GetAllDoctorsForSingleLocationSuccessPayload,
  GetDoctorDetailsRequestPayload,
  GetDoctorDetailsSuccessPayload,
  SetSearchDoctorsLocationFilters,
  GetAllDoctorsRequestPayload,
  GetAllDoctorsSuccessPayload,
  SetSearchDoctorsFilters,
  CreateDoctorConventionalLocationPayload,
  GetDoctorsSkillsSuccessPayload,
} from './types';

export const getAllDoctorsForSingleLocationRequest = createAction<GetAllDoctorsForSingleLocationRequestPayload>(
  'ACTION/GET_ALL_DOCTORS_SINGLE_LOCATION_REQUEST',
);
export const getAllDoctorsForSingleLocationSuccess = createAction<GetAllDoctorsForSingleLocationSuccessPayload>(
  'ACTION/GET_ALL_DOCTORS_SINGLE_LOCATION_SUCCESS',
);
export const getAllDoctorsForSingleLocationFailed = createAction('ACTION/GET_ALL_DOCTORS_SINGLE_LOCATION_FAILED');

export const getDoctorDetailsRequest = createAction<GetDoctorDetailsRequestPayload>(
  'ACTION/GET_DOCTOR_DETAILS_REQUEST',
);
export const getDoctorDetailsSuccess = createAction<GetDoctorDetailsSuccessPayload>(
  'ACTION/GET_DOCTOR_DETAILS_SUCCESS',
);
export const getDoctorDetailsFailed = createAction('ACTION/GET_DOCTOR_DETAILS_FAILED');

export const setSearchAllDoctorsForSingleLocationFilters = createAction<SetSearchDoctorsLocationFilters>(
  'ACTION/SET_SEARCH_DOCTORS_SINGLE_LOCATION_FILTERS',
);
export const resetSearchAllDoctorsForSingleLocationFilters = createAction(
  'ACTION/RESET_SEARCH_DOCTORS_SINGLE_LOCATION_FILTERS',
);
export const resetAllDoctorsListForSingleLocation = createAction('ACTION/RESET_ALL_DOCTORS_LIST_FOR_SINGLE_LOCATION');

export const getAllDoctorsRequest = createAction<GetAllDoctorsRequestPayload>('ACTION/GET_ALL_DOCTORS_REQUEST');
export const getAllDoctorsSuccess = createAction<GetAllDoctorsSuccessPayload>('ACTION/GET_ALL_DOCTORS_SUCCESS');
export const getAllDoctorsFailed = createAction('ACTION/GET_ALL_DOCTORS_FAILED');
export const setSearchAllDoctorsFilters = createAction<SetSearchDoctorsFilters>('ACTION/SET_SEARCH_DOCTORS_FILTERS');
export const resetSearchAllDoctorsFilters = createAction('ACTION/RESET_SEARCH_DOCTORS_FILTERS');
export const resetAllDoctorsList = createAction('ACTION/RESET_ALL_DOCTORS_LIST');

export const resetDoctorDetails = createAction('ACTION/RESET_DOCTOR_DETAILS');

export const createConventionalLocationRequest = createAction<CreateDoctorConventionalLocationPayload>(
  'ACTION/CREATE_DOCTOR_CONVENTIONAL_LOCATION_REQUEST',
);
export const createConventionalLocationError = createAction('ACTION/CREATE_DOCTOR_CONVENTIONAL_LOCATION_FAILED');
export const createConventionalLocationSuccess = createAction<CreateDoctorConventionalLocationPayload>(
  'ACTION/CREATE_DOCTOR_CONVENTIONAL_LOCATION_SUCCESS',
);

export const getDoctorsSkillsRequest = createAction('ACTION/GET_DOCTORS_SKILLS_REQUEST');
export const getDoctorsSkillsSuccess = createAction<GetDoctorsSkillsSuccessPayload>(
  'ACTION/GET_DOCTORS_SKILLS_SUCCESS',
);
export const getDoctorsSkillsFailed = createAction('ACTION/GET_DOCTORS_SKILLS_FAILED');

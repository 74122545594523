import { defineMessages } from 'react-intl';

export default defineMessages({
  nextStepSuggestSpecialties: {
    id: 'web.shared.cta.nextStepSuggest.specialities',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Profile</b> area, continue your registration by clicking <b>Specialties</b>',
  },
  nextStepSuggestCareer: {
    id: 'web.shared.cta.nextStepSuggest.career',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Specialties</b> area, continue your registration by clicking <b>Career</b>',
  },
  nextStepSuggestInvoiceInfo: {
    id: 'web.shared.cta.nextStepSuggest.invoiceInfo',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Career</b> area, continue your registration by clicking <b>Invoice info</b>',
  },
  nextStructureStepSuggestInvoiceInfo: {
    id: 'web.shared.cta.nextStepSuggest.structureInvoiceInfo',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Specialties</b> area, continue your registration by clicking <b>Invoice info</b>',
  },
  nextStepSuggestLocations: {
    id: 'web.shared.cta.nextStepSuggest.locations',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Invoice info</b> area, continue your registration by clicking <b>Locations</b>',
  },
  nextStepSuggestConditions: {
    id: 'web.shared.cta.nextStepSuggest.conditions',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Locations</b> area, continue your registration by clicking <b>Conditions</b>',
  },
  nextStepSuggestContract: {
    id: 'web.shared.cta.nextStepSuggest.contract',
    defaultMessage:
      'Welcome back, you have correctly filled the <b>Conditions</b> area, continue your registration by clicking <b>Contract</b>',
  },
  yourRequestIsBeingApproved: {
    id: 'web.shared.cta.yourRequestIsBeingApproved',
    defaultMessage:
      'Hi, your request is being approved. In the meantime you can continue your activities.',
  },
  yourPriceListsAreEmpty: {
    id: 'web.shared.cta.yourPriceListsAreEmpty',
    defaultMessage:
      'Welcome back, your practice has been approved. Click <a href="/partner-services">Services</a> to indicate what services you want to offer.',
  },
  yourAvailabilitiesAreEmpty: {
    id: 'web.shared.cta.yourAvailabilitiesAreEmpty',
    defaultMessage: `Welcome back, you've entered services. To let your customers find you, define your availability on the agenda by clicking <a href="/agenda">Agenda</a>.`,
  },
  youHaveBookingsToManage: {
    id: 'web.shared.cta.youHaveBookingsToManage',
    defaultMessage:
      'Hi, you have booking requests to manage. Click <a href="/requests">here</a> to see the list.',
  },
});

import { APIClient } from '@common/common/utils';
import { UpdateManageStructureRequestPayload } from './types';

export async function getManageStructure(): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/me/profile`,
    );
    return response.data;
  } catch (error) {
    console.error('getAllVenues - Error: ', error);
    throw error;
  }
}

export async function updateManageStructure({ structure }: UpdateManageStructureRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.put(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/me/profile`,
      { ...structure },
    );

    // @TODO: Will be fixed when used!
    return response.data;
  } catch (error) {
    console.error('getSingleVenueDetails - Error: ', error);
    throw error;
  }
}

import { call, put, takeLatest } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { analyticsUUIDResponse } from './actions';

import { ANALYTICS_UUID_REQUEST } from './constants';

export function* getAnalyticsUUID() {
  const url = `analytics/users`;
  const data = {
    deviceType: 'web',
  };

  try {
    const payload = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: data,
    });
    yield put(analyticsUUIDResponse(payload));
  } catch (e) {
    console.log(e);
  }
}

export default function* rootSaga() {
  yield takeLatest(ANALYTICS_UUID_REQUEST, getAnalyticsUUID);
}

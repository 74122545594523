/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your web.partner.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
//eslint-disable-line

const { addLocaleData } = require('react-intl');
const deLocaleData = require('react-intl/locale-data/de');
const enLocaleData = require('react-intl/locale-data/en');
const frLocaleData = require('react-intl/locale-data/fr');
const itLocaleData = require('react-intl/locale-data/it');

const deDETranslationMessages = require('./translations/de-DE.json');
const enUSTranslationMessages = require('./translations/en-US.json');
const frFRTranslationMessages = require('./translations/fr-FR.json');
const itTranslationMessages = require('./translations/it-IT.json');

addLocaleData(deLocaleData);
addLocaleData(enLocaleData);
addLocaleData(frLocaleData);
addLocaleData(itLocaleData);

// prettier-ignore
export const appLocales = [
  'it-IT',
  'de-DE',
  'en-US',
  'fr-FR',
];

export const DEFAULT_LOCALE = appLocales[0];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, itTranslationMessages) : {};

  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  'de-DE': formatTranslationMessages('de-DE', deDETranslationMessages),
  'en-US': formatTranslationMessages('en-US', enUSTranslationMessages),
  'fr-FR': formatTranslationMessages('fr-FR', frFRTranslationMessages),
  'it-IT': formatTranslationMessages('it-IT', itTranslationMessages),
};

import styled from 'styled-components';
import { CloseBordered } from 'utils/icons';
import { Link as _link } from '@components';

export const Wrapper = styled.div`
  padding: 20px 0 40px;
  margin-bottom: 20px;
  .row + .row {
    margin-top: 10px;
  }
`;

export const CloseButton = styled(CloseBordered).attrs({
  width: 40,
  height: 40,
})`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const Round = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 30px;
  background-color: ${({ status, theme }) =>
    status === 'success' && theme.success};
  background-color: ${({ status, theme: { danger } }) =>
    status === 'error' && danger};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
`;

export const Link = styled(_link)`
  display: block;
  text-align: center;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.black};
`;

export const VideoConferenceWrapper = styled.div`
  width: 640px;
  height: 480px;
  margin-top: 30px;
  background-color: ${({ theme }) => theme.gray100};
`;

export const VideoConference = styled.iframe`
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

export const VideomeetingMsgTitle = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fontFamilySerif};
  font-size: 3.2rem;
  text-align: center;
`;

export const VideomeetingMsgText = styled.div`
  color: ${({ theme }) => theme.gray300};
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.6rem;
  text-align: center;
`;

export const VideomeetingMsgWrapper = styled.div`
  margin-bottom: 10px;
`;

import ArrowRight from './arrow-right';
import Info from './info';
import Loading from './loading';
import Plus from './plus';
import Search from './search';
import Clock from './clock';
import Basket from './basket';
import Edit from './edit';
import House from './house';
import Checked from './checked';
import Notes from './notes';

import Stethoscope from './filters/stethoscope';
import Map from './filters/map';
import Data from './filters/data';
import Agenda from './filters/agenda';
import MedicalFolder from './filters/medicalFolder';
import CircleWithDots from './filters/circleWithDots';
import Located from './located';
import Expanded from './expanded';
import Close from './close';
import Eye from './eye';
import EyeOutline from './eye-outline';
import EyeOpened from './eye-opened';
import EyeSlashed from './eye-slashed';

export { default as MenuIconsPatients } from './menu/patients';
export { default as MenuIconsAgenda } from './menu/agenda';
export { default as MenuIconsRequests } from './menu/requests';
export { default as MenuIconsSpecialties } from './menu/specialities';
export { default as MenuIconsCommunications } from './menu/communications';
export { default as MenuIconsSearch } from './menu/search';
export { default as MenuIconsDocuments } from './menu/documents';
export { default as SettingsIcon } from './menu/settings';

export { default as ArrowRight } from './arrow-right';
export { default as ArrowLeft } from './arrow-left';
export { default as CFArrowLeft } from './cf-arrow-left';
export { default as ArrowUp } from './arrow-up';
export { default as ArrowDown } from './arrow-down';
export { default as Info } from './info';
export { default as Loading } from './loading';
export { default as Plus } from './plus';
export { default as Search } from './search';
export { default as Clock } from './clock';
export { default as Basket } from './basket';
export { default as Edit } from './edit';
export { default as Hyperlink } from './hyperlink';
export { default as Download } from './download';
export { default as Permissions } from './permissions';
export { default as PermissionsOn } from './permissions-on';
export { default as NewDocument } from './new-document';
export { default as House } from './house';
export { default as BadgeAppStore } from './socials/badge-app-store';
export { default as BadgeGooglePlay } from './socials/badge-google-play';
export { default as LogoLinkedin } from './socials/logo-linkedin';
export { default as LogoFacebook } from './socials/logo-facebook';
export { default as LogoInstagram } from './socials/logo-instagram';
export { default as LogoGoogle } from './socials/logo-google';
export { default as LogoGooglePlus } from './socials/logo-google-plus';
export { default as Trash } from './trash';
export { default as Located } from './located';
export { default as Expanded } from './expanded';
export { default as Close } from './close';
export { default as Checked } from './checked';
export { default as Notes } from './notes';
export { default as Eye } from './eye';
export { default as EyeOutline } from './eye-outline';
export { default as EyeOpened } from './eye-opened';
export { default as EyeSlashed } from './eye-slashed';

export { default as Stethoscope } from './filters/stethoscope';
export { default as Map } from './filters/map';
export { default as Price } from './filters/price';
export { default as Star } from './filters/star';
export { default as ArrowOrder } from './filters/arrowOrder';
export { default as Data } from './filters/data';
export { default as Agenda } from './filters/agenda';
export { default as ClockFilter } from './filters/clock';
export { default as MedicalFolder } from './filters/medicalFolder';
export { default as CircleWithDots } from './filters/circleWithDots';
export { default as CancellationPolicy } from './filters/cancellationPolicy';

export { default as Date } from './Homepage/agenda';
export { default as ThreeDots } from './status/dots';
export { default as Success } from './status/success';
export { default as Cancel } from './status/cancel';
export { default as FamilyIcon } from './family/family';

export { default as DocARC } from './documents/arc';
export { default as DocAUD } from './documents/aud';
export { default as DocDOC } from './documents/doc';
export { default as DocIMG } from './documents/img';
export { default as DocVID } from './documents/vid';
export { default as DocPDF } from './documents/pdf';
export { default as DocGENERIC } from './documents/generic';

export const iconsMap = {
  arrowRight: ArrowRight,
  info: Info,
  loading: Loading,
  plus: Plus,
  search: Search,
  clock: Clock,
  basket: Basket,
  edit: Edit,
  house: House,
  stethoscope: Stethoscope,
  map: Map,
  data: Data,
  agenda: Agenda,
  medicalFolder: MedicalFolder,
  circleWithDots: CircleWithDots,
  located: Located,
  expanded: Expanded,
  close: Close,
  checked: Checked,
  eye: Eye,
  eyeOutline: EyeOutline,
  eyeOpened: EyeOpened,
  eyeSlashed: EyeSlashed,
  notes: Notes,
};

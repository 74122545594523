import { takeLatest, all, call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { pushError } from 'containers/Alerts/actions';
import {
  resetVideomeetingData,
  getVideomeetingBookingRequest,
  getVideomeetingBookingResponse,
  saveVideomeetingNotesRequest,
  getVideomeetingRequest,
  getVideomeetingRsponse,
} from './actions';

export function* getVideomeetingBooking({ id }) {
  const url = `partners/me/bookings/${id}`;

  yield put(showLoading());
  try {
    const { status, data } = yield call(fetchWrapper, { url });
    if (status === 'success') {
      yield put(getVideomeetingBookingResponse(data));
    }
  } catch ({ packet, response }) {
    yield put(hideLoading());
    yield put(resetVideomeetingData());
  } finally {
    yield put(hideLoading());
  }
}

export function* getVideomeetingData({ id }) {
  const url = `videomeeting/${id}`;

  yield put(showLoading());
  try {
    const { status, data } = yield call(fetchWrapper, { url });
    if (status === 'success') {
      yield put(getVideomeetingRsponse(data));
    }
  } catch ({ packet, response }) {
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

export function* saveVideomeetingNotes({ id, notes }) {
  const url = `videomeeting/${id}/notes`;
  yield put(showLoading());
  try {
    const { status, data } = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: { notes },
    });
    if (status === 'success') {
      yield put(getVideomeetingRsponse(data));
      yield put(
        pushError({
          text: 'saveVideomeetingNotesSuccess',
          typeError: 'success',
        }),
      );
    }
  } catch ({ packet: { status }, response }) {
    yield put(hideLoading());
    yield put(pushError({ text: 'saveVideomeetingNotesError' }));
  } finally {
    yield put(hideLoading());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getVideomeetingBookingRequest, getVideomeetingBooking),
    takeLatest(saveVideomeetingNotesRequest, saveVideomeetingNotes),
    takeLatest(getVideomeetingRequest, getVideomeetingData),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ContentWrapper,
  IconWrapper,
  IconAndTextWrapper,
} from './styles';
import { iconsMap } from '../../icons';

const ArrowRight = iconsMap.arrowRight;
const Loading = iconsMap.loading;

function MyButton({
  children,
  link,
  handleClick,
  width,
  margin,
  withArrow,
  size,
  icon,
  loading,
  color,
  iconColor,
  type,
  textLinkButton,
  outline,
  ...props
}) {
  const arrowHeight = size === 'sm' ? '0.7em' : '1em';
  let IconComponent = null;
  if (icon)
    IconComponent =
      typeof icon === 'string' &&
      Object.prototype.hasOwnProperty.call(iconsMap, icon)
        ? iconsMap[icon]
        : icon;

  const isIconButton = !children && !!IconComponent;
  const content = !IconComponent ? (
    children
  ) : (
    <IconAndTextWrapper>
      <IconWrapper isIconButton={isIconButton}>
        <IconComponent />
      </IconWrapper>
      {children}
    </IconAndTextWrapper>
  );
  return (
    <Button
      color={isIconButton ? 'secondary' : color}
      iconColor={iconColor}
      size={size}
      onClick={handleClick}
      href={link}
      width={width}
      margin={margin}
      withArrow={withArrow}
      isIconButton={isIconButton}
      icon={icon}
      type={type}
      textLinkButton={textLinkButton}
      outline={outline}
      {...props}
    >
      {loading ? (
        <Loading />
      ) : (
        <ContentWrapper withArrow={withArrow} withIcon={IconComponent}>
          {content}
          {withArrow && <ArrowRight height={arrowHeight} />}
        </ContentWrapper>
      )}
    </Button>
  );
}

MyButton.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  width: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  margin: PropTypes.string,
  link: PropTypes.string,
  withArrow: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  iconColor: PropTypes.oneOf(['light', 'secondary']),
  type: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func.isRequired,
  textLinkButton: PropTypes.bool,
  outline: PropTypes.bool,
};

MyButton.defaultProps = {
  children: null,
  link: null,
  type: 'button',
  textLinkButton: false,
  outline: false,
};

export default MyButton;

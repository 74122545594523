import { APIClient } from '@common/common/utils';
import { isEmpty } from 'lodash';
import {
  CreateOpAccessRequestPayload,
  DeleteOpRequestPayload,
  EditOpDataAffiliationRequestPayload,
  GetAllOpsAccessesRequestPayload,
  GetOpDetailsRequestPayload,
  GetVenuesListRequestPayload,
  GetVenuesListSuccessPayload,
} from './types';

export async function getUserAccesses({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
}: GetAllOpsAccessesRequestPayload): Promise<Response> {
  const searchTextFilter = currentTextSearch
    ? {
        filter: [
          {
            field: {
              $or: ['fullName', 'email', 'username'],
            },
            operator: 'contains',
            value: currentTextSearch,
          },
        ],
      }
    : {};

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/operators/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...searchTextFilter,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getUserAccesses - Error: ', error);
    throw error;
  }
}

export async function getOpDetailsAccesses({ operatorId }: GetOpDetailsRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/operators/${operatorId}`,
    );

    return response.data;
  } catch (error) {
    console.error('getOpDetailsAccesses - Error: ', error);
    throw error;
  }
}

export async function deleteOpAccesses({ operatorId }: DeleteOpRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.delete(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/operators/${operatorId}`,
    );

    return response.data;
  } catch (error) {
    console.error('deleteOpAccesses - Error: ', error);
    throw error;
  }
}

export async function editOpDataAccesses({
  firstName,
  lastName,
  operatorId,
  locations = [],
}: EditOpDataAffiliationRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.put(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/operators/${operatorId}`,
      {
        user: {
          firstName,
          lastName,
        },
        locations,
      },
    );

    return response.data;
  } catch (error) {
    console.error('editOpDataAccesses - Error: ', error);
    throw error;
  }
}

export async function getVenuesList({
  currentTextSearch = '',
}: GetVenuesListRequestPayload): Promise<GetVenuesListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations?page=1&itemsPerPage=999`,
      {
        ...allFilters,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getVenuesList - Error: ', error);
    throw error;
  }
}

export async function createOpDataAccesses({
  firstName,
  lastName,
  username,
  email,
  locations = [],
  id,
}: CreateOpAccessRequestPayload): Promise<Response> {
  try {
    const response = await APIClient.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/operators/`,
      {
        user: id
          ? { id }
          : {
              firstName,
              lastName,
              username,
              email,
            },
        locations,
      },
    );

    return response.data;
  } catch (error: any) {
    const { message } = error?.message ?? {};
    if (!message.includes('errors.partner.email_already_exists_unassigned')) {
      console.error('createOpDataAccesses - Error: ', error);
    }
    throw error;
  }
}

import { call, put, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { uploadAttachment } from 'utils/Api/attachments';
import { pushError } from 'containers/Alerts/actions';
import {
  saveCurrentPracticeDataResponse,
  saveCurrentPracticeDataRequest,
} from 'containers/PartnersRegistration/actions';
import { selectCompletedStep } from 'containers/PartnersRegistration/selectors';
import pick from 'lodash/pick';
import { partnerProfileResponse } from '../actions';

const acceptProperty = [
  // 'file',
  'name',
  'staffContactName',
  'ceo',
  'medicalDirector',
  'address',
  'zipCode',
  'cityId',
  'phone',
  'fax',
  'mobile',
  'website',
  'email',
  'zipCode',
  'profilePictureId',
];

export function* setPartnerProfileData({
  data: dataMap,
  partnerRegistration,
  confirm,
}) {
  const url = `partners/me/profile`;
  const params = pick(dataMap, acceptProperty);

  try {
    yield put(showLoading());

    let profilePictureId = null;
    if (dataMap.file && dataMap.file[0]) {
      const { id } = yield call(uploadAttachment, dataMap.file[0]);
      profilePictureId = id;
    }

    const { data } = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: {
        ...params,
        profilePictureId,
      },
    });

    const profile = {
      data: {},
      section: 'profile',
      confirm,
    };

    yield put(saveCurrentPracticeDataRequest(profile));
    yield put(partnerProfileResponse({ partnerData: data }));
    if (partnerRegistration) {
      const completedStep = yield select(selectCompletedStep());

      if (confirm && completedStep >= 2) {
        yield put(
          saveCurrentPracticeDataResponse({
            currentStep: 4,
          }),
        );
      } else {
        yield put(
          saveCurrentPracticeDataResponse({
            currentStep: confirm ? 3 : 2,
            completedStep: completedStep === 0 && 1,
            status: completedStep === 0 && 'profile',
          }),
        );
      }
    }

    yield put(
      pushError({ text: 'setProfileDataSuccess', typeError: 'success' }),
    );
  } catch (e) {
    if (e.status === 422) {
      yield put(pushError({ text: 'getProfileDataError' }));
    } else {
      yield put(pushError({ text: 'getProfileError' }));
    }
  } finally {
    yield put(hideLoading());
  }
}

import { compose, withStateHandlers } from 'recompose';
import { injectIntl } from 'react-intl';
import DropDown from './DropDown';

export default compose(
  injectIntl,
  withStateHandlers(
    ({ defaultValue }) => ({ isOpen: false, rowShow: defaultValue }),
    {
      toggle: ({ isOpen }) => () => ({ isOpen: !isOpen }),
      changeRowShow: (
        _,
        { resetExpandedRows, setItemsPerPage },
      ) => itemsPerPage => {
        setItemsPerPage({ itemsPerPage });
        resetExpandedRows();
        return { rowShow: itemsPerPage };
      },
    },
  ),
)(DropDown);

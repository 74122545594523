import { compose, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import { selectDataLostError } from 'containers/PartnersRegistration/selectors';
import reducer from './reducers';
import { selectAlertText, selectAlertType } from './selectors';

const withReducer = injectReducer({ key: 'alert', reducer });

const mapStateToProps = createStructuredSelector({
  textError: selectAlertText(),
  typeError: selectAlertType(),
  dataLostErrorText: selectDataLostError(),
});

const mapDispatchToProps = {};

export default compose(
  withReducer,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(() => ({
    dataLostErrorType: 'danger',
  })),
);

import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { getCookiebyName } from 'utils/utils';
import set from 'lodash/set';

const setMeta = ({ uuid, languageCode, lat, long }) => ({
  meta: {
    analytics: {
      uuid,
      languageCode: (languageCode || '').replace('-', '_'),
      lat,
      long,
    },
  },
});

export const getFetchHeaders = (url, baseHeaders) => {
  const { languageCode, jwtToken, uuid, metricsHeaders } = baseHeaders || {};

  // META
  const baseMeta = { uuid, languageCode };
  const meta = url !== 'analytics/users' && uuid && setMeta(baseMeta);

  // TOKEN
  const token =
    url !== 'analytics/users' && jwtToken ? `Bearer ${jwtToken}` : null;

  return pickBy(
    {
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': (languageCode || '').replace('-', '_'),
      'x-basic-user': getCookiebyName('x-basic-user', 'mycare'),
      'x-mycare-meta': meta ? JSON.stringify(meta) : null,
      ...metricsHeaders,
    },
    identity,
  );
};

// this change a path error like "data/locations/2/address/0/city"
// to a more readable for lodash "locations[2].address[0].city"
const strErrorForLodash = errorPath =>
  errorPath
    .replace('data/', '')
    .replace(/(\/([0-9]+))/g, '[$2]')
    .replace(/[/]/g, '.');

// this return an object to inject in @@redux-form/SubmitErrors
export const remodelServerErrors = errors =>
  Object.entries(errors).reduce((acc, [key, value]) => {
    set(acc, strErrorForLodash(key), value.join(', '));
    return acc;
  }, {});

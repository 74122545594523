import Moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { fromJS } from 'immutable';
import { agendaMinutesTimeslot } from 'utils/env';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const getCookiebyName = (name, defaultValue = null) => {
  const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
  return pair ? pair[1] : defaultValue;
};

export const mergeArrays = (newArray, oldArray) => {
  if (!oldArray) {
    return newArray;
  }
  const merge = [].concat(oldArray, newArray);
  return uniqBy(merge, 'id');
};

export const mergeResults = (newResults, oldResults) => {
  if (!oldResults) {
    return newResults;
  }
  return {
    ...oldResults,
    ...newResults,
    results: mergeArrays(newResults.results, oldResults.results),
  };
};

export const infiniteScrollReducerFor = fieldName => (state, data) => {
  const newResults = data[fieldName] || [];
  const oldResults = state.get(fieldName) && state.get(fieldName).toJS();

  return state
    .set(fieldName, fromJS(mergeResults(newResults, oldResults)))
    .set(`${fieldName}Loading`, false);
};

export const formatPrice = (priceCent = 0, toFixed) =>
  Number(priceCent / 100.0).toFixed(typeof toFixed === 'number' ? toFixed : 2);

export const unformatPrice = (priceCent = 0) =>
  Number(priceCent * 100.0).toFixed(0);

export const getTimeSlots = (fromHours = null) => {
  let dayStart;
  const midnight = { label: '00:00', value: '00:00' };
  const lastSlotTime = moment()
    .endOf('day')
    .endOf('hour')
    .subtract(agendaMinutesTimeslot, 'minutes')
    .add(1, 'minutes')
    .format('HH:mm');

  if (fromHours) {
    if (fromHours === lastSlotTime) return [midnight];
    const { hour, minute, second } = makeHoursObjFromString(fromHours);
    const roundMinute =
      Math.ceil(minute / agendaMinutesTimeslot) * agendaMinutesTimeslot;
    dayStart = moment
      .utc()
      .hour(hour)
      .minute(roundMinute)
      .second(second)
      .add(agendaMinutesTimeslot, 'minutes');
  } else dayStart = moment.utc().startOf('day');
  const dayEnd = moment.utc().endOf('day');
  const range = moment.range(dayStart, dayEnd);
  const hours = Array.from(
    range.by('minutes', { step: agendaMinutesTimeslot }),
  );
  const timeSlots = hours.map(i => ({
    label: moment(i).format('HH:mm'),
    value: moment(i).format('HH:mm'),
  }));
  // return fromHours ? [...timeSlots, midnight] : timeSlots;
  return timeSlots;
};

export const joinDateHours = (date, hours) => {
  const { hour, minute, second } = makeHoursObjFromString(hours);
  return moment(date)
    .hours(hour)
    .minutes(minute || '0')
    .seconds(second || '0')
    .toISOString();
};

// return an object from a string like "08:30"
export const makeHoursObjFromString = str => {
  const [hour, minute, second] = str.split(':');
  return {
    hour: hour || 0,
    minute: minute || 0,
    second: second || 0,
  };
};

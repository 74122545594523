import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { change as reduxFormChange } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import FileDropInput from './FileDropInput';
import typesMapper from '../FileDrop/fileTypesMapper';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  changeField: reduxFormChange,
};

const handleDropRejected = ({
  fileTypesMap,
  maxFileSizeBytes,
  setUploadSizeError,
  setUploadTypeError,
}) => async dropped => {
  if (fileTypesMap.map(({ type }) => type).indexOf(dropped[0].type) < 0) {
    setUploadTypeError(true);
  } else if (dropped[0].size > maxFileSizeBytes) {
    setUploadSizeError(true);
  }
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(({ maxFileSizeMB, fileTypes, fileName }) => {
    const fileTypesMap = typesMapper.filter(
      ({ key }) => (fileTypes || 'PDF,JPG,PNG,GIF').indexOf(key) >= 0,
    );

    const typeLabel = fileTypesMap.map(({ label }) => label).toString();
    const typeFilter = fileTypesMap.map(({ type }) => type).toString();

    return {
      file: fileName || null,
      typeLabel,
      typeFilter,
      maxFileSizeMB: maxFileSizeMB || 1,
      maxFileSizeBytes: (maxFileSizeMB || 1) * 1024 * 1024,
      fileTypesMap,
    };
  }),
  withStateHandlers(
    {
      fileSizeError: false,
      fileTypeError: false,
    },
    {
      setUploadSizeError: () => value => ({
        fileSizeError: value,
      }),
      setUploadTypeError: () => value => ({
        fileTypeError: value,
      }),
    },
  ),
  withHandlers({
    handleDropRejected,
    clearInput: ({
      changeField,
      input,
      meta,
      fileDropClear,
    }) => e => {
      e.stopPropagation();
      changeField(meta.form, input.name, null);
      if (fileDropClear) fileDropClear();
    },
  }),
)(FileDropInput);

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: rgba(251, 202, 202, 1);
  padding: 10px;
  color: ${({ theme }) => theme.danger};
  font-size: 1.6rem;
  font-weight: 700;

  a {
    color: ${({ theme }) => theme.danger};
    padding-left: 5px;
  }
`;

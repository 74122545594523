import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

function MenuItem({
  Icon,
  Label,
  Tooltip,
  to,
  toggleActive,
  isActive,
  onClick,
  history,
}) {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const goToPage = page => {
    if (!isIE11) history.push(page);
    else window.location = `${window.location.origin}${to}`;
  };
  return (
    <Wrapper
      to={to}
      title={Tooltip}
      className={isActive ? 'active' : null}
      data-type="itemMenu"
      onClick={e => {
        e.preventDefault();
        if (!isActive) {
          const menuItems = document.querySelectorAll('[data-type=itemMenu]');
          for (let i = 0; i < menuItems.length; i += 1)
            menuItems[i].classList.remove('active');
          // activate temporaly for start the animation
          toggleActive();
          setTimeout(() => {
            // restore default state (now is active by location path)
            toggleActive();
            goToPage(to);
          }, 600);
          if (onClick) onClick();
        }
      }}
    >
      <div className="content">
        <Icon />
        <Label />
      </div>
    </Wrapper>
  );
}

MenuItem.propTypes = {
  toggleActive: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  Icon: PropTypes.func.isRequired,
  Label: PropTypes.func.isRequired,
  Tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

MenuItem.defaultProps = {
  onClick: () => null,
};

export default MenuItem;

import React from 'react';

const Notes = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <title>arrow-down</title>
    <path
      d="M15.0933 10.1641C15.0933 9.75415 14.761 9.42188 14.3511 9.42188H6.70654C6.29663 9.42188 5.96436 9.75415 5.96436 10.1641C5.96436 10.574 6.29663 10.9062 6.70654 10.9062H14.3511C14.761 10.9062 15.0933 10.574 15.0933 10.1641Z"
      fill="black"
    />
    <path
      d="M6.70654 12.3906C6.29663 12.3906 5.96436 12.7229 5.96436 13.1328C5.96436 13.5427 6.29663 13.875 6.70654 13.875H11.3493C11.7592 13.875 12.0915 13.5427 12.0915 13.1328C12.0915 12.7229 11.7592 12.3906 11.3493 12.3906H6.70654Z"
      fill="black"
    />
    <path
      d="M8.45096 19.5156H5.96875C5.15027 19.5156 4.48438 18.8497 4.48438 18.0312V4.96875C4.48438 4.15027 5.15027 3.48438 5.96875 3.48438H15.0935C15.912 3.48438 16.5779 4.15027 16.5779 4.96875V9.5332C16.5779 9.94311 16.9102 10.2754 17.3201 10.2754C17.73 10.2754 18.0623 9.94311 18.0623 9.5332V4.96875C18.0623 3.33178 16.7305 2 15.0935 2H5.96875C4.33178 2 3 3.33178 3 4.96875V18.0312C3 19.6682 4.33178 21 5.96875 21H8.45096C8.86087 21 9.19315 20.6677 9.19315 20.2578C9.19315 19.8479 8.86087 19.5156 8.45096 19.5156Z"
      fill="black"
    />
    <path
      d="M19.3767 12.7459C18.5085 11.8778 17.096 11.8777 16.2284 12.7453L12.1537 16.811C12.0672 16.8974 12.0033 17.0037 11.9678 17.1207L11.0804 20.0421C11.0017 20.3012 11.0703 20.5824 11.2593 20.7762C11.4009 20.9213 11.5933 21.0001 11.7906 21.0001C11.8566 21.0001 11.9233 20.9913 11.9887 20.9731L14.9843 20.1433C15.1076 20.1092 15.22 20.0437 15.3105 19.9533L19.3767 15.8948C20.2449 15.0267 20.2449 13.6141 19.3767 12.7459ZM14.4 18.7649L12.8929 19.1824L13.334 17.7303L16.0834 14.987L17.1332 16.0369L14.4 18.7649ZM18.3276 14.8447L18.1838 14.9883L17.1342 13.9386L17.2775 13.7956C17.5669 13.5062 18.0377 13.5062 18.3271 13.7956C18.6165 14.085 18.6165 14.5558 18.3276 14.8447Z"
      fill="black"
    />
    <path
      d="M14.3511 6.45312H6.70654C6.29663 6.45312 5.96436 6.7854 5.96436 7.19531C5.96436 7.60522 6.29663 7.9375 6.70654 7.9375H14.3511C14.761 7.9375 15.0933 7.60522 15.0933 7.19531C15.0933 6.7854 14.761 6.45312 14.3511 6.45312Z"
      fill="black"
    />
  </svg>
);

export default Notes;

import styled from 'styled-components';
import { Label as _label } from 'reactstrap';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleLabel = styled(_label)`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 8px;
  padding-left: 10px;
`;

export const BasicLabel = styled(_label)`
  color: ${({ theme }) => theme.gray300};
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 8px;
  padding-left: 10px;
`;

export const isImage = filename =>
  !!filename &&
  (filename.toLowerCase().match(/.(jpg|jpeg|png|gif)$/i) ||
    base64MimeType(filename));

function base64MimeType(encoded) {
  if (typeof encoded !== 'string') return null;
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (mime && mime.length) return mime[1];
  return null;
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'web.shared.components.PhoneInput.placeholder',
    defaultMessage: 'Enter phone number',
  },
  faxPlaceholder: {
    id: 'web.shared.components.PhoneInput.faxPlaceholder',
    defaultMessage: 'Enter the fax number',
  },
  cellularPlaceholder: {
    id: 'web.shared.components.PhoneInput.cellularPlaceholder',
    defaultMessage: 'Enter the cellular number',
  },
});

import React from 'react';

const SvgComponent = props => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <g data-name="Group">
      <path
        data-name="Compound Path"
        d="M96.4 63.2a19.1 19.1 0 00-11.7-17.6 12.5 12.5 0 10-14.9 0 19 19 0 00-10.1 10.3l-2.3-1.2a12.5 12.5 0 10-14.9 0l-2.3 1.2a19 19 0 00-10.1-10.2 12.5 12.5 0 10-14.9 0A19.1 19.1 0 003.6 63.2v4.5h4v-4.5A15.1 15.1 0 0137 58.4a19 19 0 00-6 13.9v4.5h4v-4.5a15.1 15.1 0 1130.1 0v4.5h4v-4.5a19 19 0 00-6-13.9 15.1 15.1 0 0129.3 4.8v4.5h4zM14.2 35.7a8.5 8.5 0 118.5 8.5 8.5 8.5 0 01-8.5-8.5zm27.3 9.1a8.5 8.5 0 118.5 8.4 8.5 8.5 0 01-8.5-8.4zm27.3-9.1a8.5 8.5 0 118.5 8.5 8.5 8.5 0 01-8.5-8.5z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { createAction } from '@reduxjs/toolkit';
import {
  GetAllPricelistsRequestPayload,
  GetAllPricelistsSuccessPayload,
  GetSinglePricelistRequestPayload,
  GetSinglePricelistPayloadSuccess,
  SetSearchPricelistsFilters,
  GetPricelistsSpecialtySuccessPayload,
  GetSinglePricelistSpecialtyRequestPayload,
  CreateNewPricelistSandboxRequestPayload,
  CreateNewPricelistSandboxPayload,
  UploadTemplateFileRequestPayload,
  UpdatePricelistSandboxRequestPayload,
  GetAllPricelistsSandboxRequestPayload,
  GetAllPricelistsSandboxSuccessPayload,
  UpdatePricelistSandboxSuccessPayload,
  GetSinglePricelistSandboxSuccessPayload,
  GetNotApprovedVenuesListRequestPayload,
  GetNotApprovedVenuesListSuccessPayload,
} from './types';

export const getAllPricelistsRequest = createAction<GetAllPricelistsRequestPayload>(
  'ACTION/GET_ALL_PRICELISTS_REQUEST',
);
export const getAllPricelistsSuccess = createAction<GetAllPricelistsSuccessPayload>(
  'ACTION/GET_ALL_PRICELISTS_SUCCESS',
);
export const getAllPricelistsFailed = createAction('ACTION/GET_ALL_PRICELISTS_FAILED');

export const setSearchPricelistsFilters = createAction<SetSearchPricelistsFilters>(
  'ACTION/SET_SEARCH_PRICELISTS_FILTERS',
);
export const resetSearchPricelistsFilters = createAction('ACTION/RESET_SEARCH_PRICELISTS_FILTERS');

export const getSinglePricelistRequest = createAction<GetSinglePricelistRequestPayload>(
  'ACTION/GET_SINGLE_PRICELIST_REQUEST',
);
export const getSinglePricelistSuccess = createAction<GetSinglePricelistPayloadSuccess>(
  'ACTION/GET_SINGLE_PRICELIST_SUCCESS',
);
export const getSinglePricelistFailed = createAction('ACTION/GET_SINGLE_PRICELIST_FAILED');
export const resetSinglePricelistDetails = createAction('ACTION/RESET_SINGLE_PRICELIST_DETAILS');

export const getSinglePricelistSpecialtyRequest = createAction<GetSinglePricelistSpecialtyRequestPayload>(
  'ACTION/GET_SINGLE_PRICELIST_SPECIALTY_REQUEST',
);
export const getSinglePricelistSpecialtySuccess = createAction<GetPricelistsSpecialtySuccessPayload>(
  'ACTION/GET_SINGLE_PRICELIST_SPECIALTY_SUCCESS',
);
export const getSinglePricelistSpecialtyFailed = createAction('ACTION/GET_SINGLE_PRICELIST_SPECIALTY_FAILED');
export const resetSinglePricelistSpecialty = createAction('ACTION/RESET_SINGLE_PRICELIST_SPECIALTY');

export const setSearchSinglePricelistsSpecialtyFilters = createAction<SetSearchPricelistsFilters>(
  'ACTION/SET_SEARCH_PRICELISTS_SPECIALTY_FILTERS',
);
export const resetSearchSinglePricelistsSpecialtyFilters = createAction(
  'ACTION/RESET_SEARCH_PRICELISTS_SPECIALTY_FILTERS',
);

export const setPricelistInitialPage = createAction('ACTION/SET_PRICELIST_INITIAL_PAGE');

export const createNewPricelistSandboxRequest = createAction<CreateNewPricelistSandboxRequestPayload>(
  'ACTION/CREATE_NEW_PRICELIST_SANDBOX_REQUEST',
);
export const createNewPricelistSandboxSuccess = createAction<CreateNewPricelistSandboxPayload>(
  'ACTION/CREATE_NEW_PRICELIST_SANDBOX_SUCCESS',
);
export const createNewPricelistSandboxFailed = createAction('ACTION/CREATE_NEW_PRICELIST_SANDBOX_FAILED');
export const resetCreateNewPricelistSandbox = createAction('ACTION/RESET_PRICELIST_SANDBOX');

export const updatePricelistSandboxRequest = createAction<UpdatePricelistSandboxRequestPayload>(
  'ACTION/UPDATE_PRICELIST_SANDBOX_REQUEST',
);
export const updatePricelistSandboxSuccess = createAction('ACTION/UPDATE_PRICELIST_SANDBOX_SUCCESS');
export const updatePricelistSandboxFailed = createAction('ACTION/UPDATE_PRICELIST_SANDBOX_FAILED');
export const resetUpdatePricelistSandbox = createAction('ACTION/RESET_UPDATE_PRICELIST_SANDBOX');

export const saveDraftPricelistSandboxRequest = createAction<UpdatePricelistSandboxRequestPayload>(
  'ACTION/SAVE_DRAFT_PRICELIST_SANDBOX_REQUEST',
);
export const saveDraftPricelistSandboxSuccess = createAction('ACTION/SAVE_DRAFT_PRICELIST_SANDBOX_SUCCESS');
export const saveDraftPricelistSandboxFailed = createAction('ACTION/SAVE_DRAFT_PRICELIST_SANDBOX_FAILED');

export const uploadPricelistSandboxRequest = createAction<UploadTemplateFileRequestPayload>(
  'ACTION/UPLOAD_PRICELIST_SANDBOX_REQUEST',
);
export const uploadPricelistSandboxSuccess = createAction<UpdatePricelistSandboxSuccessPayload>(
  'ACTION/UPLOAD_PRICELIST_SANDBOX_SUCCESS',
);
export const uploadPricelistSandboxFailed = createAction('ACTION/UPLOAD_PRICELIST_SANDBOX_FAILED');

export const getAllPricestlistsSandboxRequest = createAction<GetAllPricelistsSandboxRequestPayload>(
  'ACTION/GET_ALL_PRICELISTS_SANDBOX_REQUEST',
);
export const getAllPricestlistsSandboxSuccess = createAction<GetAllPricelistsSandboxSuccessPayload>(
  'ACTION/GET_ALL_PRICELISTS_SANDBOX_SUCCESS',
);
export const getAllPricestlistsSandboxFailed = createAction('ACTION/GET_ALL_PRICELISTS_SANDBOX_FAILED');
export const resetAllPricestlistsSandbox = createAction('ACTION/RESET_ALL_PRICELISTS_SANDBOX');

export const getSinglePricelistSandboxRequest = createAction<GetSinglePricelistRequestPayload>(
  'ACTION/GET_SINGLE_PRICELIST_SANDBOX_REQUEST',
);
export const getSinglePricelistSandboxSuccess = createAction<GetSinglePricelistSandboxSuccessPayload>(
  'ACTION/GET_SINGLE_PRICELIST_SANDBOX_SUCCESS',
);
export const getSinglePricelistSandboxFailed = createAction('ACTION/GET_SINGLE_PRICELIST_SANDBOX_FAILED');
export const resetSinglePricelistSandboxDetails = createAction('ACTION/RESET_SINGLE_PRICELIST_SANDBOX_DETAILS');

export const getNotApprovedVenuesListRequest = createAction<GetNotApprovedVenuesListRequestPayload>(
  'ACTION/GET_NOT_APPROVED_VENUES_LIST_REQUEST',
);
export const getNotApprovedVenuesListSuccess = createAction<GetNotApprovedVenuesListSuccessPayload>(
  'ACTION/GET_NOT_APPROVED_VENUES_LIST_SUCCESS',
);
export const getNotApprovedVenuesListFailed = createAction('ACTION/GET_NOT_APPROVED_VENUES_LIST_FAILED');
export const resetNotApprovedVenuesListRequest = createAction('ACTION/RESET_NOT_APPROVED_VENUES_LIST');

import styled, { css } from 'styled-components';
import { DateRange as _dateRange } from 'react-date-range';
import _input from '../Input';

export const DateRange = styled(_dateRange)`
  &.rdrCalendarWrapper {
    width: 100%;
  }

  .rdrStartEdge {
    background: ${({ theme }) => theme.white};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.gray300} !important;
  }
  .rdrEndEdge {
    border: 1px solid ${({ theme }) => theme.gray300} !important;
    border-radius: 5px;
    background: ${({ theme }) => theme.cfSecondary};
    color: ${({ theme }) => theme.white};
  }
  .rdrInRange {
    background: ${({ theme }) => theme.cfSecondaryLight};
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .rdrDay {
    height: 6em;

    &.rdrDayPassive {
      opacity: 0;
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      border-color: ${({ theme }) => theme.primary};
    }

    &.rdrDayEndOfMonth {
      .rdrInRange {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    &.rdrDayStartOfWeek {
      .rdrDayInPreview {
        border-radius: 5px;
      }
      .rdrInRange {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      .rdrStartEdge,
      .rdrEndEdge {
        border-radius: 5px;
      }
    }

    &.rdrDayEndOfWeek {
      .rdrDayInPreview {
        border-radius: 5px;
      }
      .rdrInRange {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      .rdrStartEdge,
      .rdrEndEdge {
        border-radius: 5px;
      }
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview {
      border-radius: 5px;
    }

    &.rdrDayPassive {
      span {
        color: ${({ theme }) => theme.white} !important;
      }
    }
  }

  .rdrMonth {
    width: 100%;
    .rdrWeekDay {
      color: ${({ theme }) => theme.black} !important;
      font-weight: 600;
      font-size: 1.4rem;
      text-align: left;
      text-transform: capitalize;
    }
  }

  .rdrDayNumber span {
    color: ${({ theme }) => theme.gray300};
    font-size: 1.4rem;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: ${({ theme }) => theme.black};
  }

  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber.rdrEndEdge span {
    color: ${({ theme }) => theme.white};
  }

  .rdrDayToday .rdrDayNumber span:after {
    bottom: 20px;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
      margin: 0 auto;
    `};
`;

export const Input = styled(_input)`
  ${({ type }) => type === 'start' && 'border-radius: 25px 0 0 25px'};
  ${({ type }) =>
    type === 'end' &&
    css`
      border-radius: 0 25px 25px 0;
      border-left: none;
    `};

  &.form-control:disabled {
    background: ${({ theme }) => theme.white} !important;
    color: ${({ theme }) => theme.black} !important;
  }
`;

import { useAuth } from '@common/auth/hooks/useAuth';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from './analytics';

const useGoogleAnalytics = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const currentPath = location.pathname + location.search;
      analytics.sendPageview(currentPath);
    }
  }, [isAuthenticated, location]);
};

export default useGoogleAnalytics;

import { compose, withProps } from 'recompose';
import Avatar from './Avatar';

function getInitials(...strings) {
  return strings
    .filter(v => !!v)
    .map(part => part.substring(0, 1).toUpperCase())
    .join('');
}

export default compose(
  withProps(({ firstName, lastName }) => ({
    initials: getInitials(firstName, lastName),
  })),
)(Avatar);

import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { RESTART_ON_REMOUNT } from 'utils/constants';
import { analyticsUUIDRequest } from './actions';
import reducer from './reducers';
import saga from './sagas';

const mapStateToProps = null;

const mapDispatchToProps = {
  callAnalyticsUUID: analyticsUUIDRequest,
};

const withSaga = injectSaga({
  key: 'analytics',
  saga,
  mode: RESTART_ON_REMOUNT,
});

const withReducer = injectReducer({ key: 'analytics', reducer });

export default compose(
  withReducer,
  withSaga,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.callAnalyticsUUID();
    },
  }),
);

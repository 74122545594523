import { injectGlobal } from 'styled-components';
import EksellMedium from '../static/fonts/ekselldisplayweb-medium-webfont.ttf';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body  {
    height: 100%;
    width: 100%;
    color: #333;
    overflow-x: hidden;
  }

  /* this fix a strange z-index problem on Invoices page if modal is showed */
  .bookingTable div.rt-tbody {
    overflow: hidden;
  }

  #app {
    background-color: #FFF;
    min-width: 100%;
  }

  p {
    color: #888888;
    font-size: 1.4rem;
  }

  /* For Firefox */
  input[type=number] {
    appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  @font-face {
    font-family: 'Eksell Medium';
    src: url(${EksellMedium}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  .popper-class {
    position: relative;
    text-align: left;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 0px 0px 25px 25px;
    box-shadow: rgb(0 0 0 / 35%) 0px 2px 10px;
    padding: 20px;
    z-index: 20;
    max-width: none !important;
  }
`;

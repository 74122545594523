import { APIClientPU } from '@common/common/utils';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import {
  GetAllDoctorsForSingleLocationRequestPayload,
  GetAllDoctorsForSingleLocationSuccessPayload,
  GetDoctorDetailsRequestPayload,
  GetAllDoctorsRequestPayload,
  GetAllDoctorsSuccessPayload,
  CreateDoctorRequestPayload,
  CreateDoctorSuccessPayload,
  CreateDoctorConventionalLocationPayload,
  GetDoctorsSkillsSuccessPayload,
} from './types';

export async function getAllDoctorsForSingleLocation({
  id,
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetAllDoctorsForSingleLocationRequestPayload): Promise<GetAllDoctorsForSingleLocationSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? {
        value: currentTextSearch,
      }
    : {};

  const specilatiesFilter =
    popoverFilters?.specialityId !== undefined
      ? popoverFilters?.specialityId.length > 0 && {
          field: 'specialityId',
          operator: 'in',
          value: popoverFilters?.specialityId,
        }
      : null;

  const rolesFilter =
    popoverFilters?.skillId !== undefined
      ? popoverFilters?.skillId.length > 0 && {
          field: 'skillId',
          operator: 'in',
          value: popoverFilters?.skillId,
        }
      : null;

  const filtersBy = !isEmpty(popoverFilters) ? [specilatiesFilter, rolesFilter] : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(filtersBy) || !isEmpty()
      ? {
          filter: [...without(filtersBy, undefined, null, false)],
        }
      : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/locations/${id}/doctors/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...searchTextFilter,
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllDoctorsForSingleLocation - Error: ', error);
    throw error;
  }
}

export async function getDoctorDetails({ doctorId }: GetDoctorDetailsRequestPayload): Promise<Response> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/doctors/${doctorId}`,
    );
    return response.data;
  } catch (error) {
    console.error('getAllVenues - Error: ', error);
    throw error;
  }
}

export async function getDoctorsSkills(): Promise<GetDoctorsSkillsSuccessPayload[]> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/doctors/skills`,
    );
    return response.data;
  } catch (error) {
    console.error('getDoctorsSkills - Error: ', error);
    throw error;
  }
}

export async function getAllDoctors({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
}: GetAllDoctorsRequestPayload): Promise<GetAllDoctorsSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? {
        value: currentTextSearch,
      }
    : {};

  const specilatiesFilter =
    popoverFilters?.specialityId !== undefined
      ? popoverFilters?.specialityId.length > 0 && {
          field: 'specialityId',
          operator: 'in',
          value: popoverFilters?.specialityId,
        }
      : null;

  const rolesFilter =
    popoverFilters?.skillId !== undefined
      ? popoverFilters?.skillId.length > 0 && {
          field: 'skillId',
          operator: 'in',
          value: popoverFilters?.skillId,
        }
      : null;

  const filtersBy = !isEmpty(popoverFilters) ? [specilatiesFilter, rolesFilter] : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(filtersBy) || !isEmpty()
      ? {
          filter: [...without(filtersBy, undefined, null, false)],
        }
      : {};

  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/doctors/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...searchTextFilter,
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllDoctors - Error: ', error);
    throw error;
  }
}

export async function createDoctor({ data }: CreateDoctorRequestPayload): Promise<CreateDoctorSuccessPayload> {
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/doctors`,
      {
        ...data,
      },
    );

    return response.data;
  } catch (error) {
    console.error('createDoctor - Error: ', error);
    throw error;
  }
}

export async function createDoctorConventionalLocation(data: CreateDoctorConventionalLocationPayload): Promise<any> {
  const { skillId, agreementStartAt, isAgreement, surgical, specialist, outpatient, specialities } = data;
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/doctors/${data.id}/locations/${data.location}`,
      {
        skillId,
        agreementStartAt,
        isAgreement,
        surgical,
        specialist,
        outpatient,
        specialities,
      },
    );

    return response.data;
  } catch (error) {
    console.error('createDoctor - Error: ', error);
    throw error;
  }
}

/* TODO:  */
export async function updateDoctorConventionalLocation(data: CreateDoctorConventionalLocationPayload): Promise<any> {
  const { skillId, agreementStartAt, isAgreement, surgical, specialist, outpatient, specialities } = data;
  try {
    const response = await APIClientPU.post(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/partners/pu/me/doctors/${data.id}/locations/${data.location}`,
      {
        skillId,
        agreementStartAt,
        isAgreement,
        surgical,
        specialist,
        outpatient,
        specialities,
      },
    );

    return response.data;
  } catch (error) {
    console.error('createDoctor - Error: ', error);
    throw error;
  }
}

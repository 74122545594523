import styled, { css } from 'styled-components';
import { PageWrapper as _pageWrapper } from 'components/shared';
import { Container as _container } from 'reactstrap';
import { CFArrowLeft } from '@shared/icons';
import CaffeinaClose from 'images/icons/caffeina-icons/close';
import Notes from 'images/icons/caffeina-icons/notes';
import { Col as _col, Input as _input } from '@components';

export const PageWrapper = styled(_pageWrapper)`
  margin: 0 auto;
  padding: 0 !important;
`;

export const Container = styled(_container)`
  padding: 0;
`;

export const Col = styled(_col)`
  &.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const BackButton = styled(CFArrowLeft)`
  ${({ mobile }) =>
    mobile
      ? css`
          margin-top: 10px;
          margin-right: 10px;
          width: 25px;
          height: 25px;
        `
      : css`
          margin-top: 15px;
          margin-right: 32px;
          cursor: pointer;
          width: 20px;
          height: 20px;
        `};
`;

export const CloseNotes = styled(CaffeinaClose)`
  ${({ mobile }) =>
    mobile
      ? css`
          margin-top: 8px;
          margin-right: 10px;
          width: 25px;
          height: 25px;
        `
      : css`
          position: absolute;
          top: 35px;
          right: 25px;
          cursor: pointer;
          width: 10px;
          height: 10px;
        `};
`;

export const NotesButton = styled(Notes)`
  margin-right: 15px;
  width: 25px;
  height: 25px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${({ mobile }) => (mobile ? '10' : '32')}px;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & button {
    border: 2px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    position: fixed;
    right: 32px;
  }
`;

export const LeftInnerWrapper = styled.div`
  ${({ mobile }) =>
    mobile
      ? css`
          display: flex;
          flex-direction: column;
          padding-left: 6px;
          margin-left: 3px;
          margin-top: 6px;
          justify-content: flex-start;
          & span:last-child {
            font-family: ${({ theme }) => theme.fontFamilyBase};
            font-size: 1.4rem;
            color: ${({ theme }) => theme.black};
            text-align: left;
          }
        `
      : css`
          padding-left: 16px;
          margin-top: 5px;
          & span:last-child {
            margin-top: 10px;
            margin-left: 15px;
            font-family: ${({ theme }) => theme.fontFamilyBase};
            font-size: 1.4rem;
            color: ${({ theme }) => theme.black};
          }
        `};
`;

export const HealthService = styled.span`
  font-family: 'Eksell medium', 'Times new roman', 'Arial', 'Noto Sans', serif;
  font-size: ${({ mobile }) => (mobile ? '2.0' : '2.4')}rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: ${({ mobile }) => (mobile ? '15' : '30')}px;
  color: ${({ theme }) => theme.black};
`;

export const Wrapper = styled.div`
  width: ${({ showNotes, mobile }) => (showNotes && !mobile ? '60%' : '100%')};
  transition: width 0.5s;
`;

export const VideoConferenceWrapper = styled.div`
  width: 100%;
  height: ${({ mobile }) =>
    mobile ? 'calc(100vh - 80px)' : 'calc(100vh - 200px)'};
  background-color: ${({ theme }) => theme.gray100};
  & .vcFrame {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
  }
`;

export const NotesWrapper = styled.div`
  width: ${({ mobile }) => (mobile ? '100%' : '40%')};
  height: 100%;
  background-color: #fafafa;
  position: absolute;
  ${({ showNotes, mobile }) =>
    mobile
      ? css`
          top: ${showNotes ? '0' : '100%'};
          left: 0;
          transition: top 0.5s;
        `
      : css`
          top: 0;
          left: ${showNotes ? '60%' : '100%'};
          transition: left 0.5s;
        `};
`;

export const NotesHeader = styled.div`
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Eksell medium', 'Times new roman', 'Arial', 'Noto Sans', serif;
  font-size: 2.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.black};
  height: 85px;
`;

export const NotesBody = styled.div`
  ${({ mobile }) =>
    !mobile &&
    css`
      height: calc(100% - 185px);
    `};
`;

export const NotesFooter = styled.div`
  height: 100px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  & button {
    width: ${({ mobile }) => (mobile ? '100%' : '153px')}!important;
    height: 54px !important;
    border-radius: 27px !important;
  }
`;

export const ConnectionTime = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: 'Eksell medium', 'Times new roman', 'Arial', 'Noto Sans', serif;
  font-size: 1.8rem;
  margin-right: ${({ showNotes, mobile }) =>
    showNotes && !mobile ? '32' : '120'}px;
  transition: margin-right 0.5s;
  margin-top: 5px;
  background-color: #f2f2f2;
`;

export const Input = styled(_input)`
  background-color: #fafafa;
  :focus {
    background-color: #fafafa;
  }
  margin: 0;
  border-radius: 0;
  border: none;
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.black};
  padding: 0 !important;
  height: calc(100vh - 300px) !important;
  caret-color: ${({ theme }) => theme.black};
  resize: none;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Field } from 'redux-form/immutable';
import get from 'lodash/get';
import { Modal, Radio, Button } from '@components';
import analytics from './analytics';
import messages from './messages';
import {
  ModalTitle,
  ModalBody,
  ModalScrollContent,
  AcceptanceFieldWrap,
  AcceptanceTitle,
  AcceptanceContent,
  AcceptanceWrap,
  ExtraText,
} from './styles';
import { getAcceptanceFieldName } from './utils';

const TermsModal = props => {
  const {
    intl,
    content,
    hasAllRequiredAcceptances,
    submitTermsAcceptance,
    isOpen,
    title,
    docType,
    cancel,
    extraText,
    checkAllRadioButton,
  } = props;
  return (
    <Modal isOpen={isOpen} toggle={cancel} size="md" centered>
      <ModalBody>
        <ModalTitle>{title}</ModalTitle>

        <ModalScrollContent>
          {!!extraText && <ExtraText>{extraText}</ExtraText>}

          <p
            dangerouslySetInnerHTML={{
              __html: get(content, 'content.general', '').replace(
                /\n/g,
                '<br/>',
              ),
            }}
          />

          {get(content, 'content.acceptances', []).map(
            a =>
              (a.title.trim().length > 0 || a.content.trim().length > 0) && (
                <AcceptanceWrap key={a.title}>
                  <AcceptanceTitle>
                    {a.title}
                    {a.required ? '*' : ''}
                  </AcceptanceTitle>

                  <AcceptanceContent>{a.content}</AcceptanceContent>

                  <AcceptanceFieldWrap>
                    <Field
                      labelRadioMargin="0"
                      radioContainerPadding="10px 0px"
                      labelRadioPadding="0px"
                      name={getAcceptanceFieldName(docType, a)}
                      component={Radio}
                      className="acceptanceRadio"
                      variant="small"
                      radioButtons={[
                        {
                          label: a.acceptButton,
                          value: true,
                        },
                        {
                          label: a.denyButton,
                          value: false,
                        },
                      ]}
                    />
                  </AcceptanceFieldWrap>
                </AcceptanceWrap>
              ),
          )}
        </ModalScrollContent>

        <Button
          color="primary"
          onClick={submitTermsAcceptance}
          disabled={!hasAllRequiredAcceptances || !checkAllRadioButton}
          analytics={analytics.acceptTerms}
          margin="20px calc((100% - 310px) / 2) 0"
          width="310px"
        >
          {intl.formatMessage(messages.acceptTermsButtonLabel)}
        </Button>
      </ModalBody>
    </Modal>
  );
};

TermsModal.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  submitTermsAcceptance: PropTypes.func.isRequired,
  docType: PropTypes.string,
  isOpen: PropTypes.bool,
  hasAllRequiredAcceptances: PropTypes.bool,
  checkAllRadioButton: PropTypes.bool,
  extraText: PropTypes.string,
};

TermsModal.defaultProps = {
  isOpen: null,
};

export default TermsModal;

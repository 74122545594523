import React from 'react';
import PropTypes from 'prop-types';
import { Success, ThreeDots, Cancel } from 'utils/icons';
import { BigCircusStatus, StatusIcon } from './styles';

const iconMapper = (status, size) => {
  switch (status) {
    case 'approved':
    case 'completed':
    case 'confirmed':
      return <Success color="white" width={size * 2} height={size * 2} />;
    case 'noshow':
    case 'expired':
    case 'cancelled':
    case 'rejected':
      return <Cancel color="white" width={size} height={size} />;
    default:
      return <ThreeDots color="white" width={size} height={size} />;
  }
};

function CircleStatus({ noMargin, inline, size, status, margin }) {
  return (
    <BigCircusStatus
      noMargin={noMargin}
      margin={margin}
      inline={inline}
      size={size}
      status={status}
    >
      <StatusIcon>{iconMapper(status, size * 0.625)}</StatusIcon>
    </BigCircusStatus>
  );
}

CircleStatus.propTypes = {
  status: PropTypes.string,
  inline: PropTypes.bool,
  size: PropTypes.number,
  noMargin: PropTypes.bool,
  margin: PropTypes.string,
};

CircleStatus.defaultProps = {
  status: undefined,
  inline: false,
  size: 80,
  noMargin: false,
};

export default CircleStatus;

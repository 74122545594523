import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import en from 'date-fns/locale/en-US';
import { registerLocale } from 'react-datepicker';
import Label from '../Label';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import './react-datepicker.css';
import { FormGroup, ErrorMessage } from '../FormStyles';
import { DatePicker } from './styles';
import messages from './messages';

registerLocale('it', it);
registerLocale('en', en);

function MyDatePicker({
  intl,
  input,
  meta,
  label,
  titleLabel,
  hiddenField,
  className,
  minDate,
  maxDate,
  selectedDate: selectedDateStr,
  changeDate,
  requiredfield,
  index,
  disabled,
  size,
  locale,
  position,
  marginBottom,
  margin,
  transparent,
  paddingLeft,
}) {
  const { name, value, onBlur, onFocus, ...inputProps } = input;
  const { error, warning, touched, dirty, active } = meta;
  const localeLanguage = locale.substring(0, 2);
  const selectedDate =
    selectedDateStr || value ? moment(selectedDateStr || value).toDate() : null;
  return (
    <FormGroup
      className={className}
      haslabel={!!titleLabel || !!label}
      hidden={hiddenField}
      marginBottom={marginBottom}
      margin={margin}
    >
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          name={name}
          requiredfield={requiredfield}
        />
      )}
      <DatePicker
        {...inputProps}
        id={name}
        name={name}
        type="date"
        selected={selectedDate}
        onChange={changeDate}
        active={active}
        dirty={dirty}
        dateFormat="dd/MM/yyyy"
        locale={localeLanguage || 'it'}
        popperPlacement={position || 'top-start'}
        placeholderText={intl.formatMessage(messages.placeholder)}
        minDate={minDate}
        maxDate={maxDate}
        style={{
          borderColor: meta && touched && error && 'red',
        }}
        touched={touched}
        error={error}
        onBlur={() => null}
        onFocus={() => {
          onFocus();
          onBlur();
        }}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
        tabIndex={index}
        disabled={disabled}
        size={size}
        transparent={transparent}
        paddingLeft={paddingLeft}
      />
      {touched &&
        ((error && (
          <ErrorMessage>
            {errorMessages[isString(error)] ? (
              <FormattedMessage
                values={typeof error !== 'object' ? {} : error}
                {...errorMessages[isString(error)]}
              />
            ) : (
              error
            )}
          </ErrorMessage>
        )) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
}

MyDatePicker.propTypes = {
  intl: intlShape.isRequired,
  transparent: PropTypes.bool,
  paddingLeft: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  haslabel: PropTypes.bool,
  veryValue: PropTypes.any,
  hiddenField: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  selectedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  changeDate: PropTypes.func.isRequired,
  index: PropTypes.number,
  requiredfield: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  size: PropTypes.string,
  position: PropTypes.oneOf([
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
  ]),
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
};

MyDatePicker.defaultProps = {
  disabled: false,
  type: null,
  className: null,
  input: null,
  meta: null,
  label: null,
  titleLabel: null,
  haslabel: null,
  veryValue: undefined,
  hiddenField: null,
  minDate: null,
  maxDate: null,
  selectedDate: null,
  index: 0,
  requiredfield: false,
  size: null,
  marginBottom: null,
};

export default MyDatePicker;

import React from 'react';

const CaffeinaMarker = props => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99992 0.833333C3.84933 0.833333 2.91659 1.76607 2.91659 2.91667C2.91659 4.06726 3.84933 5 4.99992 5C6.15051 5 7.08325 4.06726 7.08325 2.91667C7.08325 1.76607 6.15051 0.833333 4.99992 0.833333ZM2.08325 2.91667C2.08325 1.30584 3.38909 0 4.99992 0C6.61075 0 7.91659 1.30584 7.91659 2.91667C7.91659 4.5275 6.61075 5.83333 4.99992 5.83333C3.38909 5.83333 2.08325 4.5275 2.08325 2.91667Z"
      fill="#FE744F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 9.58341C1.25 9.3533 1.43655 9.16675 1.66667 9.16675H8.33333C8.56345 9.16675 8.75 9.3533 8.75 9.58341C8.75 9.81353 8.56345 10.0001 8.33333 10.0001H1.66667C1.43655 10.0001 1.25 9.81353 1.25 9.58341Z"
      fill="#FE744F"
      {...props}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99992 5C5.23004 5 5.41659 5.18655 5.41659 5.41667V7.91667C5.41659 8.14678 5.23004 8.33333 4.99992 8.33333C4.7698 8.33333 4.58325 8.14678 4.58325 7.91667V5.41667C4.58325 5.18655 4.7698 5 4.99992 5Z"
      fill="#FE744F"
    />
  </svg>
);

export default CaffeinaMarker;

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Button from '../../../Button';
import { ModalContainer, ModalHeader, ModalFooter } from './styles';
import messages from './messages';

const Modal = ({ isOpen, message, intl, onCancel, close, onConfirm }) => (
  <ModalContainer isOpen={isOpen} modalClassName="form-dirty-modal">
    <ModalHeader>
      {message || intl.formatMessage(messages.leaveMessage)}
    </ModalHeader>
    <ModalFooter>
      <Button color="secondary" outline onClick={onCancel || close}>
        {intl.formatMessage(messages.no)}
      </Button>
      <Button color="primary" onClick={onConfirm}>
        {intl.formatMessage(messages.yes)}
      </Button>
    </ModalFooter>
  </ModalContainer>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  intl: intlShape.isRequired,
  onCancel: PropTypes.func,
  close: PropTypes.func,
  onConfirm: PropTypes.func,
};

Modal.defaultProps = {
  isOpen: false,
  message: null,
};

export default Modal;

import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import _button from '../Button';
import { RoundedDelete as _roundedDelete } from '../../utils/icons';
import omit from '../../utils/omitProps';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CustomDropzone = styled(Dropzone)`
  background: ${({ theme }) => theme.white};
  border-radius: 10px;
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;

  button {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    div {
      flex-direction: column;
      flex: 1;
      span {
        color: ${({ theme }) => theme.gray600};
        font-size: 2rem;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 5px;
        white-space: normal !important;
        max-width: 140px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.gray400} !important;
      text-decoration: none !important;
    }
  }

  input {
    overflow: hidden;
    width: 100%;
  }
`;

export const CustomIcon = styled.p`
  color: ${({ theme }) => theme.gray300};
  display: block;
  font-size: 8rem;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 7rem;
`;

export const FileFormats = styled.p`
  bottom: 0;
  color: ${({ theme }) => theme.gray600} !important;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: normal;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
`;

export const Button = styled(omit(_button, ['deleteIcon']))`
  ${({ deleteIcon }) =>
    deleteIcon &&
    `
      height: 40px !important;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px !important;
  `};
`;

export const RoundedDelete = styled(_roundedDelete)`
  height: 40px;
  width: 40px;
  fill: ${({ theme }) => theme.error};
`;

export const FileDropInputWrapper = styled.div`
  width: 100%;
  border: 1px
    ${({ theme, isEmpty }) =>
      isEmpty ? `dashed ${theme.gray200}` : `solid ${theme.gray200}`};
  border-radius: 25px;
  overflow: hidden;
`;

import { createAction } from 'redux-actions-helpers';
import {
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_REQUEST,
  LOGOUT_RESPONSE,
  REGISTER_REQUEST,
  FACEBOOK_LOGIN_REQUEST,
  GOOGLE_LOGIN_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_RESPONSE,
  FORGOT_USERNAME_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  SET_TOKEN_LOCAL_STORAGE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  GET_TOKEN_LOCAL_STORAGE,
  PRIVACY_POLICY_LOADING,
  PRIVACY_POLICY_LOADED,
  TOS_LOADING,
  TOS_LOADED,
  GET_PARTNER_STATUS,
  SET_PARTNER_STATUS,
  UPDATE_TERMS_REQUEST,
  UPDATE_TERMS_RESPONSE,
  RESET_PASSWORD,
  SET_LEGAL_DOCUMENTS,
  REQUEST_LEGAL_DOCUMENTS,
  SET_FIRST_APPROVAL_REVIEWED,
  SET_REDIRECT_URL_POST_LOGIN,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_RESPONSE,
} from './constants';

export const requestLegalDocuments = createAction(
  REQUEST_LEGAL_DOCUMENTS,
  data => ({
    data,
  }),
);

export const setLegalDocuments = createAction(SET_LEGAL_DOCUMENTS, data => ({
  data,
}));

export const loginRequest = createAction(LOGIN_REQUEST, data => ({ data }));

export const loginResponse = createAction(LOGIN_RESPONSE, data => ({ data }));

export const logoutRequest = createAction(LOGOUT_REQUEST);
export const logoutResponse = createAction(LOGOUT_RESPONSE);

export const registerRequest = createAction(REGISTER_REQUEST, data => ({
  data,
}));

export const facebookLoginRequest = createAction(
  FACEBOOK_LOGIN_REQUEST,
  data => data,
);

export const googleLoginRequest = createAction(
  GOOGLE_LOGIN_REQUEST,
  data => data,
);

export const changePasswordRequest = createAction(
  CHANGE_PASSWORD_REQUEST,
  data => ({ data }),
);

export const changePasswordResponse = createAction(
  CHANGE_PASSWORD_RESPONSE,
  data => ({ data }),
);

export const forgotUsernameRequest = createAction(
  FORGOT_USERNAME_REQUEST,
  data => ({ data }),
);

export const forgotPasswordRequest = createAction(
  FORGOT_PASSWORD_REQUEST,
  data => ({ data }),
);

export const setTokenLocalStorage = createAction(
  SET_TOKEN_LOCAL_STORAGE,
  data => data,
);
export const getLocalStorageToken = createAction(GET_TOKEN_LOCAL_STORAGE);

export const refreshTokenRequest = createAction(REFRESH_TOKEN_REQUEST);
export const refreshTokenSuccess = createAction(
  REFRESH_TOKEN_SUCCESS,
  data => data,
);
export const refreshTokenFail = createAction(REFRESH_TOKEN_FAIL);

export const loadPrivacyPolicy = createAction(PRIVACY_POLICY_LOADING);
export const loadedPrivacyPolicy = createAction(
  PRIVACY_POLICY_LOADED,
  data => data,
);

export const loadTermsOfUse = createAction(TOS_LOADING);
export const loadedTermsOfUse = createAction(TOS_LOADED, data => data);

export const updateTermsRequest = createAction(
  UPDATE_TERMS_REQUEST,
  (type, data) => ({
    dataType: type,
    data,
  }),
);

export const updateTermsResponse = createAction(
  UPDATE_TERMS_RESPONSE,
  dataType => ({ dataType }),
);

export const getPartnerStatus = createAction(GET_PARTNER_STATUS);
export const setPartnerStatus = createAction(
  SET_PARTNER_STATUS,
  ({ partnerStatus, firstApprovalReviewed }) => ({
    partnerStatus,
    firstApprovalReviewed,
  }),
);
export const setPartnerFirstApprovalReviewed = createAction(
  SET_FIRST_APPROVAL_REVIEWED,
);

export const resetPassword = createAction(RESET_PASSWORD, ({ email }) => ({
  email,
}));

export const setRedirectUrlPostLoginAction = createAction(
  SET_REDIRECT_URL_POST_LOGIN,
  ({ url }) => ({ url }),
);

export const getGeoLocationRequest = createAction(GET_GEO_LOCATION_REQUEST);
export const getGeoLocationResponse = createAction(
  GET_GEO_LOCATION_RESPONSE,
  ({ latitude, longitude }) => ({
    latitude,
    longitude,
  }),
);

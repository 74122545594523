import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../Switch';
import { Section } from './styles';

function CollapsableRow({
  title,
  subtitle,
  children,
  enabled,
  checked,
  toggle,
  onChange,
  disabled,
}) {
  return (
    <Section>
      <Section.Header>
        <div>
          <Section.Title disabled={disabled}>{title}</Section.Title>
          <Section.Subtitle disabled={disabled}>{subtitle}</Section.Subtitle>
        </div>
        {!disabled &&
          enabled && (
            <Switch
              checked={checked}
              onChange={() => {
                if (onChange) onChange(!checked);
                toggle();
              }}
            />
          )}
      </Section.Header>
      {enabled && (
        <Section.Content checked={checked}>{children}</Section.Content>
      )}
    </Section>
  );
}

CollapsableRow.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

CollapsableRow.defaultProps = {
  enabled: true,
  disabled: false,
};

export default CollapsableRow;

import ReactGA from 'react-ga4';

const TRACKING_ID = window.MyCareEnvironment.REACT_APP_ANALYTICS;

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging';

  if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID, {
      testMode: isDev,
    });
  }
};

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.send({ hitType: 'pageview', page: path }); // GA4 by default get page_view information, this is a fallback!
};

const analytics = {
  init,
  sendEvent,
  sendPageview,
};

export default analytics;

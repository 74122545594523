import React from 'react';
import PropTypes from 'prop-types';

const Notes = ({ size = 25, color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.509 5.792l.131-.132L28 4.02 23.927 0l-1.525 1.423L20.826 3l-.268.267-9.21.768L4.175 2.84l-.558-.08-.098.544-3.43 19.434-.089.546.558.118 5.13.855.11 2.041.043.577.528-.036.063-.003 8.837-.982 5.867.977.567.07.089-.535.223-1.262 4.995-.556.561-.058-.054-.542L26.51 5.792zm-5.278-1.58l2.62 2.62-8.033 8.034-1.295-1.362-1.326-1.258 8.034-8.033zm-6.376 11.341l-3.128.783.783-3.127 1.155 1.019 1.19 1.325zM19.307 4.52l-.722.721-2.648-.44 3.37-.281zm-12.4 21.114l-.064-1.184 4.286.714-4.222.47zm13.858-.024l-19.45-3.24 3.23-18.31 13.047 2.175-5.912 5.911c-.007.007-.008.019-.014.026a.535.535 0 00-.114.203c-.004.014-.017.023-.02.037l-1.144 4.572-.26.889.814-.179.138-.018 4.573-1.143c.014-.003.023-.016.035-.02a.573.573 0 00.194-.11c.01-.008.026-.01.036-.02l7.863-7.863-3.016 17.09zm1.457-1.678L25.19 7.109l.234-.234.921 16.597-4.123.459zm3.61-19.08L24.66 6.026l-2.62-2.62 1.173-1.175.93-.807 2.599 2.382-.909 1.047z"
      fill="#fff"
    />
  </svg>
);

Notes.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Notes;

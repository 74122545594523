import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SpinnerComponent } from '../../Spinner';
import { DayWrapper, Button, Availabilities } from './styles';

function Day({
  handleMouseEvent,
  handleKeyEvent,
  className,
  renderSelectionPlaceholders,
  renderPreviewPlaceholder,
  day,
  styles,
  disabled,
  isPassive,
  color,
  availabilities,
  availabilitiesLoading,
  availabilitiesAccessor,
  availabilitiesLoadingAccessor,
}) {
  const availabilityDay = moment(day.toString()).format('YYYY-MM-DD');

  return (
    <Button
      type="button"
      onMouseEnter={handleMouseEvent}
      onMouseLeave={handleMouseEvent}
      onFocus={handleMouseEvent}
      onMouseDown={handleMouseEvent}
      onMouseUp={handleMouseEvent}
      onBlur={handleMouseEvent}
      onPauseCapture={handleMouseEvent}
      onKeyDown={handleKeyEvent}
      onKeyUp={handleKeyEvent}
      className={className}
      {...(disabled || isPassive ? { tabIndex: -1 } : {})}
      style={{ color }}
    >
      {renderSelectionPlaceholders()}
      {renderPreviewPlaceholder()}
      <DayWrapper className={styles.dayNumber}>
        <span>{moment(day.toString()).format('D')}</span>
        {availabilitiesAccessor &&
          availabilitiesLoadingAccessor && (
            <Fragment>
              {availabilitiesLoading ? (
                <SpinnerComponent name="circle" />
              ) : (
                <Availabilities>
                  ({availabilities &&
                  typeof availabilities[availabilityDay] === 'number'
                    ? availabilities[availabilityDay]
                    : '0'})
                </Availabilities>
              )}
            </Fragment>
          )}
      </DayWrapper>
    </Button>
  );
}

Day.propTypes = {
  handleMouseEvent: PropTypes.func.isRequired,
  handleKeyEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderSelectionPlaceholders: PropTypes.func.isRequired,
  renderPreviewPlaceholder: PropTypes.func.isRequired,
  day: PropTypes.shape({}),
  availabilities: PropTypes.shape({}),
  styles: PropTypes.string,
  disabled: PropTypes.bool,
  isPassive: PropTypes.bool,
  availabilitiesLoading: PropTypes.bool,
  color: PropTypes.string,
  availabilitiesAccessor: PropTypes.string,
  availabilitiesLoadingAccessor: PropTypes.string,
};

Day.defaultProps = {};

export default Day;

import { defineMessages } from 'react-intl';

export default defineMessages({
  tosModalTitle: {
    id: 'web.partner.components.Modal.tosModalTitle',
    defaultMessage: 'Termini of service',
  },
  privacyModalTitle: {
    id: 'web.partner.components.Modal.privacyModalTitle',
    defaultMessage: 'Privacy Policy',
  },
  changePasswordButton: {
    id: 'web.partner.components.Modal.changePasswordButton',
    defaultMessage: 'Update password',
  },
  acceptTermsButtonLabel: {
    id: 'web.partner.components.Modal.tosModalButtonLabel',
    defaultMessage: 'Accept',
  },
});

import styled from 'styled-components';
import { Label as _label } from 'reactstrap';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleLabel = styled(_label)`
  color: ${({ theme }) => theme.balck};
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 15px;
`;

export const BasicLabel = styled(_label)`
  color: ${({ theme }) => theme.black};
  font-weight: 400 !important;
  font-size: 1.4rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 8px;
  padding-left: 10px;
`;

import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { change } from 'redux-form';

import InputLocalAutosuggest from './InputLocalAutosuggest';

const mapStateToProps = null;

const mapDispatchToProps = {
  changeFieldValue: change,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStateHandlers(
    {
      selectedOption: null,
      multiValue: [],
    },
    {
      setValue: () => values => ({
        value: values.map(s => ({
          value: s.id,
          label: s.name,
        })),
      }),
      handleChange: (_o, { input, isMulti }) => selectedOption => {
        if (isMulti) {
          const multiValue = selectedOption.map(({ value }) => value);
          input.onChange(multiValue);
          return { selectedOption, multiValue };
        }

        input.onChange(`${selectedOption.value || ''}`);
        return { selectedOption };
      },
    },
  ),
  lifecycle({
    componentDidMount() {
      const { props } = this;
      const { value } = props.input;
      const valueFromImmutable = value.toJS ? value.toJS() : value;
      if (!isEmpty(valueFromImmutable)) {
        const valueList = flatten([valueFromImmutable]);
        this.props.setValue(
          valueList.map(v => find(this.props.suggestions, o => o.id === v)),
        );
      }
    },
  }),
)(InputLocalAutosuggest);

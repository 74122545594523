import React from 'react';

const ArrowDown = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 12" {...props}>
    <title>arrow-down</title>
    <path
      d="M10.75 9.69L1.28.22A.75.75 0 00.22 1.28l10 10a.75.75 0 001.06 0l10-10A.75.75 0 0020.22.22l-9.47 9.47z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowDown;

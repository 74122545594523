import styled from 'styled-components';

export const ItemCount = styled.div`
  font-size: 14px;
`;

export const CustomPagination = styled.div`
  display: flex;
  padding: 20px 10px;
  align-items: center;
  justify-content: space-between;
`;

export const CustomPaginationButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const TableVisiblePagesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PageButtonComponentDisabled = styled.div`
  color: ${p => p.disabled && 'gray'};
  font-size: 13px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${p => (p.isActivePage ? 'bold' : 'normal')};
  padding: 0 5px;
`;

export const PageButtonComponent = styled.div`
  color: ${p => p.isActivePage && '#C8102E'};
  font-size: 13px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${p => (p.isActivePage ? 'bold' : 'normal')};
  padding: 0 5px;
`;

export const PageButtonComponentNext = styled.div`
  color: ${p => p.disabled && 'gray'};
  font-size: 13px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${p => (p.isActivePage ? 'bold' : 'normal')};
  padding: 0 5px;
`;

export const CustomNavigation = styled.div`
  display: inline-block;
  .-pageJump {
    display: inline-block;

    input {
      width: 30px;
      text-align: right;
    }
  }

  .-totalPages {
    padding: 0 10px;
  }
`;

export const Dots = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
`;

import React from 'react';
import PropTypes from 'prop-types';
import Spinkit from 'react-spinkit';
import { Wrapper } from './styles';

const SpinnerTable = ({ loading = false }) =>
  loading ? (
    <Wrapper>
      <Spinkit name="ball-spin-fade-loader" color="#fcb731" fadeIn="quarter" />
    </Wrapper>
  ) : null;

SpinnerTable.propTypes = {
  loading: PropTypes.bool,
};

export default SpinnerTable;

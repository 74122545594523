import React from 'react';

const SvgComponent = props => (
  <svg viewBox="0 0 50 50" width={50} height={50} {...props}>
    <path
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M38 15L22 33l-10-8"
    />
  </svg>
);

export default SvgComponent;

import { defineMessages } from 'react-intl';

export default defineMessages({
  searchByIdOrName: {
    id: 'web.aon.components.Filters.searchByIdOrName',
    defaultMessage: 'Search by Id or Name',
  },
  filterBy: {
    id: 'web.aon.components.Filters.filterBy',
    defaultMessage: 'Filter by',
  },
});

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  change,
  registerField as register,
  focus,
  getFormValues,
} from 'redux-form/immutable';
import SearchCard from './SearchCard';

const mapStateToProps = createStructuredSelector({
  formValues: (state, { formName }) => getFormValues(formName)(state),
});

const mapDispatchToProps = {
  changeField: change,
  registerField: register,
  focusField: focus,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    onChange: ({
      changeField,
      registerField,
      focusField,
      formName,
      onClick,
    }) => value => {
      changeField(formName, 'timeSlot', null);
      registerField(formName, 'timeSlot', 'Field');
      changeField(formName, 'timeSlot', value);
      focusField(formName, 'timeSlot');
      onClick();
    },
  }),
)(SearchCard);

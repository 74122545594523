import { createSelector } from 'reselect';

const selectPatientDomain = () => (state) => state.get('patient');

export const selectPatientLoading = () => createSelector(selectPatientDomain(), (patient) => patient.get('isLoading'));

export const selectPatientDetails = () =>
  createSelector(
    selectPatientDomain(),
    (patient) => patient.get('patientDetails') && patient.get('patientDetails').toJS(),
  );

export const selectBookings = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('bookings') && patient.get('bookings', []).toJS());

export const selectBids = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('bids') && patient.get('bids', []).toJS());

export const selectPatientUserId = () => createSelector(selectPatientDetails(), (details) => details.userId);

export const selectPatientId = () => createSelector(selectPatientDetails(), (details) => details.id);

export const selectPatientBookingUpcoming = () =>
  createSelector(
    selectPatientDomain(),
    (patientBookings) => patientBookings.get('upcoming') && patientBookings.get('upcoming').toJS(),
  );

export const selectPatientBookingUpcomingLoading = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('upcomingLoading'));

export const selectPatientBookingHistory = () =>
  createSelector(
    selectPatientDomain(),
    (patientBookings) => patientBookings.get('history') && patientBookings.get('history').toJS(),
  );

export const selectPatientBookingHistoryLoading = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('historyLoading'));

export const selectPatientBooking = () =>
  createSelector(
    selectPatientDomain(),
    (patientBookings) => patientBookings.get('selectedBooking') && patientBookings.get('selectedBooking').toJS(),
  );

export const selectPatientBookingLoading = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('bookingLoading'));

export const selectModalLoading = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('modalLoading'));

export const selectModalStatus = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('modalStatus'));

export const selectMessagesLoading = () =>
  createSelector(selectPatientDomain(), (patientBookings) => patientBookings.get('messageLoading'));

export const selectMessages = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('messages') && patient.get('messages').toJS());

export const selectHealthServicesPricelists = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('healthServicePricelists').toJS());

export const selectServerTime = () => createSelector(selectPatientDomain(), (patient) => patient.get('serverTime'));

export const selectAnamnesisPreview = () =>
  createSelector(
    selectPatientDomain(),
    (patient) => patient.get('anamnesisPreview') && patient.get('anamnesisPreview').toJS(),
  );

export const selectAnamnesisFile = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('anamnesisFile'));

export const selectHasDocuments = () => createSelector(selectPatientDomain(), (patient) => patient.get('hasDocuments'));

export const selectHasBookingDocuments = () =>
  createSelector(selectPatientDomain(), (patient) => patient.get('hasBookingDocuments'));

import { put, select, call } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import * as Cookies from 'js-cookie';
import { push } from 'connected-react-router/immutable';
import { getProfileSummaryData } from 'containers/EditProfile/sagas/getProfileSummaryData';
import { getProfileData } from 'containers/EditProfile/sagas/getProfileData';
import { loginResponse, requestLegalDocuments } from '../actions';
import { selectIsImpersonated } from '../selectors';

export function* getTokenLocalStorage() {
  const { localStorage, sessionStorage } = window;
  const isImpersonated = yield select(selectIsImpersonated());
  const checkStorageJWT = localStorage.getItem('jwt_my_care') || sessionStorage.getItem('jwt_my_care');
  const checkStorageRefreshToken =
    localStorage.getItem('refresh_token_my_care') || sessionStorage.getItem('refresh_token_my_care');

  const accessToken = isImpersonated ? Cookies.get('impersonate_token') : checkStorageJWT;
  const refreshToken = isImpersonated ? Cookies.get('impersonate_refresh_token') : checkStorageRefreshToken;
  const partnerStatus = localStorage.getItem('partner_status');
  const data = {
    accessToken,
    refreshToken,
    partnerStatus,
  };

  if (accessToken && refreshToken) {
    const decodeAccessToken = jwtDecode(accessToken);
    data.changePasswordModal = false;
    data.isAuthenticated = true;
    data.jwt = data.accessToken;
    data.id = decodeAccessToken.id;
    yield put(requestLegalDocuments(decodeAccessToken.id));
    yield put(loginResponse(data));
    yield call(getProfileData);
    yield call(getProfileSummaryData);
    if (!isImpersonated && partnerStatus && partnerStatus !== 'approved') yield put(push('/partner-registration'));
  }
}

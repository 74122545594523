import { compose, withProps } from 'recompose';
import isNil from 'lodash/isNil';
import Avatar from './Avatar';

const onlyFirstName = (firstName = '', lastName = '') => {
  const name = `${firstName.trim()}${lastName.trim()}`;
  return name === firstName.trim();
};

function getInitials(firstName, lastName) {
  if (isNil(firstName) && isNil(lastName)) return null;
  if (onlyFirstName(firstName, lastName))
    return firstName.substr(0, 1).toUpperCase();
  const name = `${firstName} ${lastName}`.trim();
  return name
    .split(/\s/)
    .map(part => part.substring(0, 1).toUpperCase())
    .filter(v => !!v)
    .join('');
}

export default compose(
  withProps(({ firstName, lastName }) => ({
    initials: getInitials(firstName, lastName),
  })),
)(Avatar);

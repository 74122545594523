import { Box, Button, FormControl, FormLabel, GridItem, Input, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { APIClientNoAuth } from '@common/auth/api';
import ContainerModal from '@common/components/ContainerModal';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ResetPwdRequest = {
  status: string;
};

const ResetPassword: FC = () => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const currentLanguage = i18n.language;

  const onHandleOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const onEmailChange = useCallback((val: string) => {
    setEmail(val);
  }, []);

  const onCloseModal = useCallback(() => {
    setEmail('');
    onClose();
  }, [onClose]);

  const onSendResetPasswordRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ResetPwdRequest> = await APIClientNoAuth.post(
        'auth/pass/reset',
        {
          email,
        },
        {
          headers: {
            'accept-language': currentLanguage,
            'metrics-geolocation': ' 0 | 0',
            'metrics-touchpoint': 'WEB_PARTNER',
          },
        },
      );

      if (response.data.status === 'success') {
        toast({
          description: t('web.pu.Login.Welcome.Modal.ResetPasswordDoneSuccessfully'),
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 10000,
        });
      }
    } catch (err: any) {
      toast({
        description: err?.message as string,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000,
      });
    }

    onCloseModal();
    setIsLoading(false);
  }, [currentLanguage, email, onCloseModal, t, toast]);

  return (
    <>
      <Button variant="buttonAsLink" color="black" textStyle="link" fontSize="sm" onClick={onHandleOpen}>
        {t('web.pu.Login.Welcome.LostPassword')}
      </Button>

      <ContainerModal
        type="reset_psw_username"
        isOpen={isOpen}
        onClose={onCloseModal}
        onConfirmAction={onSendResetPasswordRequest}
        title={t('web.pu.Login.Welcome.Modal.ResetPasswordTitle')}
        description={t('web.pu.Login.Welcome.Modal.ResetPasswordDescription')}
        disabled={!email}
        isLoading={isLoading}
        children={
          <Box width="100%">
            <FormControl as={GridItem} colSpan={[12, 12]}>
              <FormLabel htmlFor="email" textStyle="cardDetailsInputLabel">
                {t('web.pu.Login.Welcome.Modal.EmailLabel')}
                <Text as="span" textStyle="asterisk">
                  *
                </Text>
              </FormLabel>
              <Input
                onChange={(e) => onEmailChange(e.target.value)}
                type="text"
                name="email"
                id="email"
                focusBorderColor="gray.300"
                w="full"
                fontSize="md"
                value={email}
              />
            </FormControl>
          </Box>
        }
      />
    </>
  );
};

export default ResetPassword;

import React from 'react';

const Locations = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>PROFILE-Locations</title>
    <g data-name="Livello 72">
      <g fill="currentColor" data-name="Livello 73">
        <path
          d="M14.5 3.94a8.5 8.5 0 0 0-8.5 8.5V13a9.11 9.11 0 0 0 1.31 4.23 36.93 36.93 0 0 0 6.86 8.15l.33.28.33-.28a44.62 44.62 0 0 0 4-4.18 25.86 25.86 0 0 0 2.81-4A9.11 9.11 0 0 0 23 13v-.56a8.5 8.5 0 0 0-8.5-8.5zm6.31 12.8a28.85 28.85 0 0 1-4.14 5.44c-.7.74-1.32 1.35-1.78 1.78l-.39.37a41.7 41.7 0 0 1-4.34-4.67 20.2 20.2 0 0 1-2.22-3.4A7.51 7.51 0 0 1 7 13v-.56a7.5 7.5 0 1 1 15 0V13a8.12 8.12 0 0 1-1.19 3.74z"
          fillRule="evenodd"
        />
        <circle cx={14.56} cy={12.25} r={4.5} />
      </g>
    </g>
  </svg>
);

export default Locations;

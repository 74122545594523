import { select, put, race, take } from 'redux-saga/effects';
import { selectFetchHeaders } from 'components/shared/selectors';
import { selectAuthToken } from 'containers/Auth/selectors';
import { refreshTokenRequest, refreshTokenSuccess, refreshTokenFail, logoutRequest } from 'containers/Auth/actions';
import jwtDecode from 'jwt-decode';

export function* getHeaders(noCheckJWT) {
  const headers = yield select(selectFetchHeaders);

  const jwtToken = yield select(selectAuthToken());

  if (!noCheckJWT && jwtToken) {
    const oldToken = jwtDecode(jwtToken);
    const expMillis = oldToken.exp * 1000;

    if (expMillis < Date.now()) {
      yield put(refreshTokenRequest());

      const { success, fail } = yield race({
        success: take(refreshTokenSuccess),
        fail: take(refreshTokenFail),
      });

      // Success call ApiWrapper
      if (success) return { ...headers, jwtToken: success.data.data.accessToken };
      else if (fail) yield put(logoutRequest()); // @TODO: window.location home page portale unico
    }
  }

  return headers;
}

import { messageHandlerSet } from '@apps/pu/redux/messageHandler/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import {
  getManageStructureRequest,
  getManageStructureSuccess,
  getManageStructureFailed,
  updateManageStructureRequest,
  updateManageStructureSuccess,
  updateManageStructureFailed,
} from './actions';
import * as ManageStructureAPI from './apiCall';
import { UpdateManageStructureRequestPayload } from './types';

function* getManageStructureSaga() {
  try {
    const response = yield call(ManageStructureAPI.getManageStructure);

    const { data } = response;

    yield put(getManageStructureSuccess(data));
  } catch (error: any) {
    yield put(getManageStructureFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* updateManageStructureSaga({ payload }: PayloadAction<UpdateManageStructureRequestPayload>) {
  try {
    const response = yield call(ManageStructureAPI.updateManageStructure, { ...payload });

    if (response.status === 'success') {
      yield put(getManageStructureRequest());
    }

    yield put(updateManageStructureSuccess());
  } catch (error: any) {
    yield put(updateManageStructureFailed());
    yield put(messageHandlerSet({ message: error?.message?.message, type: 'error' }));
  }
}

function* manageStructureSaga(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getManageStructureRequest.type, getManageStructureSaga);
  yield takeLatest(updateManageStructureRequest.type, updateManageStructureSaga);
}

export default manageStructureSaga;

import React from 'react';

const DocPDF = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(226, 77, 77)"
    />
    <path
      fill-rule="evenodd"
      d="M 4.042 13.29 L 4.042 12.47 L 4.292 12.47 L 4.292 10.22 L 4.042 10.22 L 4.042 9.4 L 6.232 9.4 C 6.665 9.4 6.995 9.493 7.222 9.68 C 7.449 9.867 7.562 10.167 7.562 10.58 C 7.562 10.987 7.449 11.283 7.222 11.47 C 6.995 11.657 6.665 11.75 6.232 11.75 L 5.692 11.75 L 5.692 12.47 L 6.172 12.47 L 6.172 13.29 L 4.042 13.29 Z M 5.692 11.04 L 5.862 11.04 C 6.055 11.04 6.152 10.933 6.152 10.72 L 6.152 10.54 C 6.152 10.427 6.129 10.347 6.082 10.3 C 6.029 10.247 5.955 10.22 5.862 10.22 L 5.692 10.22 L 5.692 11.04 ZM 7.732 13.29 L 7.732 12.47 L 7.982 12.47 L 7.982 10.23 L 7.732 10.23 L 7.732 9.4 L 9.762 9.4 C 10.315 9.4 10.759 9.553 11.092 9.86 C 11.419 10.16 11.582 10.653 11.582 11.34 C 11.582 12.027 11.419 12.523 11.092 12.83 C 10.759 13.137 10.315 13.29 9.762 13.29 L 7.732 13.29 Z M 9.382 12.47 L 9.672 12.47 C 9.785 12.47 9.869 12.44 9.922 12.38 C 9.982 12.32 10.012 12.227 10.012 12.1 L 10.012 10.59 C 10.012 10.343 9.899 10.22 9.672 10.22 L 9.382 10.22 L 9.382 12.47 ZM 14.062 13.29 L 11.752 13.29 L 11.752 12.47 L 12.002 12.47 L 12.002 10.22 L 11.752 10.22 L 11.752 9.4 L 14.742 9.4 L 14.742 10.69 L 13.862 10.69 L 13.862 10.22 L 13.402 10.22 L 13.402 11.05 L 14.152 11.05 L 14.152 11.62 L 13.402 11.62 L 13.402 12.47 L 14.062 12.47 L 14.062 13.29 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocPDF;

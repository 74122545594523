import { gmapsId } from 'utils/env';
import { defineMessages } from 'react-intl';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const GoogleParams = {
  v: '3.exp',
  key: gmapsId,
};

export const initialDefaultTime = {
  from: '08:00',
  to: '20:00',
};

// if change this you also update translations for 'web.shared.FormErrors.minimumYear'
export const minimumYearAllowed = 1950;

// if change this you also update translations for 'web.aon.invoices.modal.fileRules'
export const maxInvoiceFileSize = 1048576; // 1mb

export const serviceDurationMultiple = 10; // (min)

export const locationPlusesMessages = defineMessages({
  hospitalization: {
    id: 'web.partner.locations.pluses.hospitalization',
    defaultMessage: 'Hospitalization',
  },
  dayHospital: {
    id: 'web.partner.locations.pluses.dayHospital',
    defaultMessage: 'Day hospital',
  },
  analysisLab: {
    id: 'web.partner.locations.pluses.analysisLab',
    defaultMessage: 'Analysis lab',
  },
  samplesPoint: {
    id: 'web.partner.locations.pluses.samplesPoint',
    defaultMessage: 'Samples point',
  },
  doctorChoice: {
    id: 'web.partner.locations.pluses.doctorChoice',
    defaultMessage: 'Doctors choice',
  },
  checkUp: {
    id: 'web.partner.locations.pluses.checkUp',
    defaultMessage: 'Check up',
  },
  onlineReportsSend: {
    id: 'web.partner.locations.pluses.onlineReportsSend',
    defaultMessage: 'Online reports send',
  },
  onlineConsult: {
    id: 'web.partner.locations.pluses.onlineConsult',
    defaultMessage: 'Online consult',
  },
  homeVisits: {
    id: 'web.partner.locations.pluses.homeVisits',
    defaultMessage: 'Home visits',
  },
  urgencyService: {
    id: 'web.partner.locations.pluses.urgencyService',
    defaultMessage: 'Urgency service',
  },
  augutstOpening: {
    id: 'web.partner.locations.pluses.augustOpening',
    defaultMessage: 'August opening',
  },
  disabledEntry: {
    id: 'web.partner.locations.pluses.disabledEntry',
    defaultMessage: 'Disabled access',
  },
  reservedParking: {
    id: 'web.partner.locations.pluses.reservedParking',
    defaultMessage: 'Reserved parking',
  },
  parkingNearby: {
    id: 'web.partner.locations.pluses.parkingNearby',
    defaultMessage: 'Parking nearby',
  },
  childrenArea: {
    id: 'web.partner.locations.pluses.childrenArea',
    defaultMessage: "Children's area",
  },
  activitiesAndServices: {
    id: 'web.partner.locations.pluses.activitiesAndServices',
    defaultMessage: 'Activities and services',
  },
  additionalServices: {
    id: 'web.partner.locations.pluses.additionalServices',
    defaultMessage: 'Additional services',
  },
  plusOfLocation: {
    id: 'web.partner.locations.pluses.plusOfLocation',
    defaultMessage: 'Location pluses',
  },
});

// For partnerType structure locations
export const activitiesAndServices = [
  'hospitalization',
  'dayHospital',
  'analysisLab',
  'samplesPoint',
  'doctorChoice',
  'checkUp',
  'onlineReportsSend',
  'onlineConsult',
  'homeVisits',
];

export const additionalServices = [
  'urgencyService',
  'augutstOpening',
  'disabledEntry',
  'reservedParking',
];

// For partnerType professional locations
export const locationPluses = [
  'parkingNearby',
  'homeVisits',
  'disabledEntry',
  'childrenArea',
];

export const REFRESH_TABLES_TIME = 15; // Expressed in minutes

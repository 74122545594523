import { compose, withHandlers, withState } from 'recompose';
import Pagination from './Pagination';

export default compose(
  withHandlers({
    getVisiblePages: () => (page, total) => {
      if (total < 7) {
        return [1, 2, 3, 4, 5, 6].filter(number => number <= total);
        // return filterPages([1, 2, 3, 4, 5, 6], total);
      }
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      }
      return [1, 2, 3, 4, 5, total];
    },
  }),
  withState('visiblePages', 'setVisiblePages', props =>
    props.getVisiblePages(props.currentPage, props.totalPages),
  ),
)(Pagination);

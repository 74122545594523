import React from 'react';

const Checked = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 19" {...props}>
    <title>Checked</title>
    <path
      d="M1.238 11.12a.75.75 0 10-.976 1.138l7 6a.75.75 0 001.07-.096l13-16a.75.75 0 00-1.164-.946L7.653 16.618 1.238 11.12z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default Checked;

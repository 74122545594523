import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import * as AuthAPI from '../api';

const SESSION_STORAGE_AUTH_TOKEN = 'jwt_my_care';
const SESSION_STORAGE_REFRESH_TOKEN = 'refresh_token_my_care';

const IMPERSONATE_AUTH_TOKEN = 'impersonate_pu_token';
const IMPERSONATE_REFRESH_TOKEN = 'impersonate_pu_refresh_token';

export interface Impersonated {
  impersonatedBy: {
    id: number;
    email: string;
    name: string;
  };
}

/**
 * Store the passed authTokens to SessionStorage for later use.
 *
 * @param accessToken the JWT access token to store
 * @param refreshToken the refresh token to store
 */
export function storeAuthTokens(accessToken: string, refreshToken = '', rememberMe = true) {
  try {
    if (rememberMe) {
      localStorage.setItem(SESSION_STORAGE_AUTH_TOKEN, accessToken);
      localStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, refreshToken);
      localStorage.setItem('rememberMe', rememberMe.toString());
    } else {
      sessionStorage.setItem(SESSION_STORAGE_AUTH_TOKEN, accessToken);
      sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, refreshToken);
    }
  } catch (err) {
    console.error('Failed to store AuthTokens', err);
  }

  return {
    accessToken,
    refreshToken,
    rememberMe,
  };
}

/**
 * Clear currently saved authToken from SessionStorage.
 */
export function clearAuthTokens(redirect = true): void {
  try {
    localStorage.removeItem(SESSION_STORAGE_AUTH_TOKEN);
    localStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);
    localStorage.clear();

    sessionStorage.removeItem(SESSION_STORAGE_AUTH_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);

    if (redirect) {
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }
    console.log('clearAuthTokens');
  } catch (err) {
    console.error('Failed to clear AuthTokens', err);
  }
}

/**
 * Retrieve the stored accessToken (if any)
 */
export function getAccessToken(): string {
  try {
    const isImpersonated = localStorage.getItem('is_impersonated')
      ? Boolean(localStorage.getItem('is_impersonated'))
      : false;
    const impersonateToken = Cookies.get(IMPERSONATE_AUTH_TOKEN);
    const impersonateTokenRefresh = Cookies.get(IMPERSONATE_REFRESH_TOKEN);
    if (impersonateToken && impersonateTokenRefresh && !isImpersonated) {
      const decodedImpersonateToken: Impersonated = jwtDecode(impersonateToken);
      storeAuthTokens(impersonateToken, impersonateTokenRefresh, true);
      localStorage.setItem('is_impersonated', 'true');
      localStorage.setItem('impersonated_by', JSON.stringify(decodedImpersonateToken.impersonatedBy));
      return impersonateToken;
    }
    return localStorage.getItem(SESSION_STORAGE_AUTH_TOKEN) ?? sessionStorage.getItem(SESSION_STORAGE_AUTH_TOKEN) ?? '';
  } catch (err) {
    console.error('Failed to retrieve AccessToken', err);
  }

  return '';
}

/**
 * Retrieve the stored refreshToken (if any)
 */
export function getRefreshToken(): string {
  try {
    return (
      localStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN) ?? sessionStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN) ?? ''
    );
  } catch (err) {
    console.error('Failed to retrieve RefreshToken', err);
  }

  return '';
}

/**
 * Call the refreshToken API to refresh the current tokens and store the new ones.
 */
export async function refreshAuthTokens(): Promise<
  | {
      data?: { accessToken?: string; refreshToken?: string };
    }
  | undefined
> {
  const oldAccessToken = getAccessToken();
  const oldRefreshToken = getRefreshToken();

  try {
    const data = await AuthAPI.refreshToken({
      accessToken: oldAccessToken,
      refreshToken: oldRefreshToken,
    });

    if (data?.data?.accessToken && data?.data?.refreshToken) {
      const rememberMeLS = localStorage.getItem('rememberMe');
      const rememberMe: boolean = !!rememberMeLS ? JSON.parse(rememberMeLS) === true : false;

      storeAuthTokens(data?.data?.accessToken, data?.data?.refreshToken, rememberMe);

      return data;
    } else {
      clearAuthTokens();
    }
  } catch (err) {
    console.error('Failed to refresh AuthTokens', err);

    clearAuthTokens();

    throw err;
  }
}

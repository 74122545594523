import { createAction } from 'redux-actions-helpers';

export const setDataTableLoading = createAction(
  'APP/TABLE/SET_DATA_TABLE_LOADING',
  loading => ({ loading }),
);

export const getDataTableRequest = createAction(
  'APP/TABLE/GET_DATA_TABLE_REQUEST',
  additionalDataAccessor => ({ additionalDataAccessor }),
);

export const getDataTableResponse = createAction(
  'APP/TABLE/GET_DATA_TABLE_RESPONSE',
  ({ response, filter, orderBy }) => ({
    response,
    filter,
    orderBy,
  }),
);

export const updateExpandedRows = createAction(
  'APP/TABLE/UPDATE_EXPANDED_ROWS',
  ({ currentPage, rowIndex }) => ({ currentPage, rowIndex }),
);

export const updateSelectedItemsAutosuggestAction = createAction(
  'APP/TABLE/UPDATE_SELECTED_AUTOSUGGEST',
  ({ inputName, selectedOption }) => ({ inputName, selectedOption }),
);

export const setFiltersAction = createAction(
  'APP/TABLE/SET_FILTERS',
  ({ filters }) => ({ filters }),
);

export const applyFilterAction = createAction(
  'APP/TABLE/APPLY_FILTERS',
  ({ filter }) => ({ filter }),
);

export const setOrderByAction = createAction(
  'APP/TABLE/SET_ORDER_BY',
  ({ orderBy }) => ({ orderBy }),
);

export const setDataUrlAction = createAction(
  'APP/TABLE/SET_DATA_URL',
  ({ dataUrl }) => ({ dataUrl }),
);

export const setTypeOfCallAction = createAction(
  'APP/TABLE/SET_TYPE_OF_CALL',
  ({ typeOfCall }) => ({ typeOfCall }),
);

export const setItemsPerPageAction = createAction(
  'APP/TABLE/SET_ITEMS_PER_PAGE',
  ({ itemsPerPage }) => ({ itemsPerPage }),
);

export const setCurrentPageAction = createAction(
  'APP/TABLE/SET_CURRENT_PAGE',
  ({ page }) => ({ page }),
);

export const removeFilterAction = createAction(
  'APP/TABLE/REMOVE_FILTER',
  ({ indexField }) => ({ indexField }),
);

export const removeFilterWithoutRefetchAction = createAction(
  'APP/TABLE/REMOVE_FILTER_WITHOUT_REFETCH',
  ({ indexField }) => ({ indexField }),
);

export const setV2ParamsAction = createAction(
  'APP/TABLE/SET_V2_PARAMS',
  ({ v2 }) => ({ v2 }),
);

export const hidePaginationParamsAction = createAction(
  'APP/TABLE/HIDE_PAGINATION_PARAMS',
);

export const setInitialTableConfigAction = createAction(
  'APP/TABLE/SET_INITIAL_TABLE_CONFIG',
  config => ({ config }),
);

export const resetExpandedRows = createAction('APP/TABLE/RESET_EXPANDED_ROWS');
export const resetOrders = createAction('APP/TABLE/RESET_ORDERS');
export const resetPage = createAction('APP/TABLE/RESET_PAGE');
export const resetResults = createAction('APP/TABLE/RESET_RESULTS');
export const resetOnUnmountAction = createAction('APP/TABLE/RESET_ON_UNMOUNT');
export const resetFiltersAction = createAction(
  'APP/TABLE/RESET_FILTERS',
  ({ ignoredFilters }) => ({ ignoredFilters }),
);

export const setAdditionalDataAccessorAction = createAction(
  'APP/TABLE/SET_ADDITIONAL_DATA_ACCESSOR_ACTION',
  ({ additionalDataAccessor }) => ({ additionalDataAccessor }),
);

export const setAdditionalDataAction = createAction(
  'APP/TABLE/SET_ADDITIONAL_DATA_ACTION',
  additionaData => ({ additionaData }),
);

export const setDefaultParamsAction = createAction(
  'APP/TABLE/SET_DEFAULT_PARAMS',
  ({ defaultParams }) => ({ defaultParams }),
);

/*
 *
 * LanguageProvider reducer
 *
 */

import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { masterLanguage } from 'utils/env';
import { changeLocale, localesResponse } from './actions';

const initialState = fromJS({
  locale: localStorage.getItem('i18nextLng') || masterLanguage,
  remoteLocales: null,
});

export default handleActions(
  {
    [changeLocale]: (state, { languageLocale }) => state.set('locale', languageLocale),
    [localesResponse]: (state, { remoteLocales }) => state.set('remoteLocales', remoteLocales),
  },
  { initialState },
);

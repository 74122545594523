// https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts

export const RadioStyles = {
  // The parts of the component
  parts: ['container', 'control', 'label'],
  // The styles all button have in common
  baseStyle: {
    control: {
      borderColor: 'secondary',
      _checked: {
        borderColor: 'secondary',
        bg: 'white',
        color: 'secondary',
        _hover: {
          borderColor: 'secondary',
          bg: 'white',
          color: 'secondary',
        },
      },
      _focus: {
        borderColor: 'secondary',
        bg: 'white',
        color: 'secondary',
      },
      _hover: {
        borderColor: 'secondary',
        bg: 'white',
        color: 'secondary',
      },
    },
    label: {
      color: 'black',
      fontSize: 'sm',
      fontWeight: 600,
    },
  },
  sizes: {},
  // Two variants: primary and outline
  variants: {},
  // The default size and variant values
  defaultProps: {},
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  beforeStarting: {
    id: 'web.partner.PatientBookingDetails.helpModal.beforeStarting',
    defaultMessage: 'Before the online visit begins',
  },
  continue: {
    id: 'web.partner.PatientBookingDetails.helpModal.continue',
    defaultMessage: 'Continue',
  },
  skip: {
    id: 'web.partner.PatientBookingDetails.helpModal.skip',
    defaultMessage: 'Skip',
  },
  ok: {
    id: 'web.partner.PatientBookingDetails.helpModal.ok',
    defaultMessage: 'Ok',
  },
  msgPage1: {
    id: 'web.partner.PatientBookingDetails.helpModal.msgPage1',
    defaultMessage: 'Find an enclosed, quiet place for a clear conversation',
  },
  msgPage2: {
    id: 'web.partner.PatientBookingDetails.helpModal.msgPage2',
    defaultMessage: 'Find an enclosed, quiet place for a clear conversation',
  },
  msgPage3: {
    id: 'web.partner.PatientBookingDetails.helpModal.msgPage3',
    defaultMessage: 'Find an enclosed, quiet place for a clear conversation',
  },
});

import { takeLatest, all } from 'redux-saga/effects';
import {
  profileRequest,
  editProfileRequest,
  setNewPasswordResponse,
  setNewEmailResponse,
  profileSummaryRequest,
  editPartnerProfileRequest,
  amendAccountData as amendAccountDataRequest,
  deleteAccount as deleteAccountRequest,
  portAccountData as portAccountDataRequest,
  getIbanDataRequest,
} from '../actions';
import { getProfileData } from './getProfileData';
import { setProfileData } from './setProfileData';
import { setNewEmail } from './setNewEmail';
import { setNewPassword } from './setNewPassword';
import { getProfileSummaryData } from './getProfileSummaryData';
import { setPartnerProfileData } from './setPartnerProfileData';
import { amendAccountData } from './amendAccountData';
import { deleteUserAccount } from './deleteAccount';
import { portAccountData } from './portAccountData';
import { getIbanData } from './getIbanData';

export default function* rootSaga() {
  yield all([
    takeLatest(profileRequest, getProfileData),
    takeLatest(editProfileRequest, setProfileData),
    takeLatest(editPartnerProfileRequest, setPartnerProfileData),
    takeLatest(setNewPasswordResponse, setNewPassword),
    takeLatest(setNewEmailResponse, setNewEmail),
    takeLatest(profileSummaryRequest, getProfileSummaryData),
    takeLatest(amendAccountDataRequest, amendAccountData),
    takeLatest(deleteAccountRequest, deleteUserAccount),
    takeLatest(portAccountDataRequest, portAccountData),
    takeLatest(getIbanDataRequest, getIbanData),
  ]);
}

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose, withHandlers, withProps } from 'recompose';
import { formValueSelector, clearFields } from 'redux-form/immutable';
import { connect } from 'react-redux';
import messages from './messages';
import TermsModal from './TermsModal';
import {
  checkRequiredAcceptances,
  checkRadioButton,
  getAcceptanceFieldNameFor,
} from './utils';

const mapStateToProps = (state, { content, form }) => ({
  hasAllRequiredAcceptances: checkRequiredAcceptances(content, fieldName =>
    formValueSelector(form)(state, fieldName),
  ),
  checkAllRadioButton: checkRadioButton(content, fieldName =>
    formValueSelector(form)(state, fieldName),
  ),
});

const TermsModalContainer = compose(
  injectIntl,
  withProps(({ content, intl }) => {
    const docType = get(content, 'documentType');
    return {
      docType,
      title: intl.formatMessage(
        docType === 'privacy_partners'
          ? messages.privacyModalTitle
          : messages.tosModalTitle,
      ),
    };
  }),
  connect(mapStateToProps),
  withHandlers({
    submitTermsAcceptance: ({
      onAccepted,
      hasAllRequiredAcceptances,
      checkAllRadioButton,
    }) => () => {
      if (hasAllRequiredAcceptances && checkAllRadioButton) {
        onAccepted();
      }
    },
    cancel: ({ content, dispatch, form, onAccepted }) => () => {
      // Reset selections
      const fieldsToReset = get(content, 'content.acceptances', []).map(
        getAcceptanceFieldNameFor(content.documentType),
      );
      dispatch(clearFields(form, false, false, ...fieldsToReset));

      // Close dialog
      onAccepted();
    },
  }),
)(TermsModal);

TermsModalContainer.propTypes = {
  form: PropTypes.string.isRequired,
};

export default TermsModalContainer;

import React from 'react';

const Requests = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M20.624 4.2H3.376c-.59 0-.906.063-1.235.248a1.758 1.758 0 00-.707.747c-.175.346-.234.677-.234 1.299v14.012c0 .622.059.953.234 1.3.162.32.404.574.707.746.33.186.644.248 1.235.248h17.248c.59 0 .906-.063 1.235-.248.303-.172.545-.427.707-.747.175-.346.234-.677.234-1.299V6.494c0-.622-.059-.953-.234-1.3a1.758 1.758 0 00-.707-.746c-.33-.185-.644-.248-1.235-.248zm1.028 2.625v-.331c0-.429-.023-.583-.1-.734a.581.581 0 00-.238-.251c-.141-.08-.286-.104-.69-.104H3.376c-.404 0-.549.024-.69.104a.58.58 0 00-.238.251c-.077.151-.1.305-.1.734v1.45l9.63 8.586 9.674-9.705zm-9.63 11.326L2.349 9.524v10.982c0 .429.023.583.1.734a.58.58 0 00.237.251c.142.08.287.104.691.104h17.248c.404 0 .549-.024.69-.104a.581.581 0 00.238-.251c.077-.151.1-.305.1-.734V8.491l-9.63 9.66z"
      fillRule="evenodd"
    />
  </svg>
);

export default Requests;

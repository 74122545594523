import { compose, withStateHandlers } from 'recompose';
import moment from 'moment';
import TimePicker from './TimePicker';

const calendarTime = value =>
  value && moment(value, 'HH:mm', true).format('HH:mm');

export default compose(
  withStateHandlers(
    {
      selectedTime: null,
    },
    {
      changeTime: (_, { input }) => value => {
        input.onChange(calendarTime(value));

        return {
          selectedTime: value,
        };
      },
    },
  ),
)(TimePicker);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderComponent } from 'recompose';
import Spinkit from 'react-spinkit';
import { PageWrapper } from './styles';

export const SpinnerComponent = ({ isLoading, ...props }) => (
  <Spinkit name="ball-spin-fade-loader" color="#fcb731" {...props} />
);

SpinnerComponent.propTypes = {
  isLoading: PropTypes.bool,
};

function Spinner() {
  return (
    <PageWrapper>
      <SpinnerComponent />
    </PageWrapper>
  );
}

export default compose(
  branch(({ isLoading }) => isLoading, renderComponent(Spinner)),
);

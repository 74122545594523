import styled from 'styled-components';
import Geosuggest from 'react-geosuggest';
import {
  getBorderRadius,
  getHeight,
  getFontSize,
  getTopMenuSpacer,
  getMenuBorderRadius,
  getPaddingOption,
} from '../FormStyles';

export const InputGeosuggest = styled(Geosuggest).attrs({
  style: ({ theme, size }) => ({
    input: {
      position: 'relative',
      outline: 0,
      display: 'block',
      width: '100%',
      height: getHeight(size, theme),
      padding: getPaddingOption(size),
      lineHeight: 1.5,
      fontSize: getFontSize(size, theme),
      color: theme.black,
      backgroundColor: 'transparent',
      borderRadius: getBorderRadius(size, theme),
      border: 'none',
      zIndex: 102,
    },
    suggests: {
      zIndex: 101,
      backgroundColor: theme.white,
      position: 'absolute',
      border: `1px solid ${theme.gray400}`,
      borderTop: 'none',
      marginTop: getTopMenuSpacer(size),
      paddingTop: '30px',
      left: '-1px',
      right: '-1px',
      paddingLeft: 0,
      borderRadius: getMenuBorderRadius(size),
      overflow: 'scroll',
      maxHeight: '250px',
    },
    suggestItem: {
      listStyle: 'none',
      fontSize: '1.6rem',
      padding: getPaddingOption(size),
    },
  }),
})`
  border: 1px solid ${({ theme }) => theme.gray400};
  border-color: ${({ theme, error }) => (error ? theme.danger : '')};
  border-radius: ${({ theme, size }) => getBorderRadius(size, theme)};
  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out';
  z-index: 100;
  position: relative;

  .geosuggest__input {
    background-color: transparent;
  }

  .geosuggest__suggests--hidden {
    max-height: 0 !important;
    overflow: hidden !important;
    border-width: 0 !important;
    padding: 0 !important;
  }
`;

export const MapWrapper = styled.div`
  overflow: hidden;
  border-radius: 25px;
`;
export const SliderWrapper = styled.div`
  padding: 40px 120px;
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  socketErrorModalTitle: {
    id: 'web.partner.videomeeting.modal.socketErrorModalTitle',
    defaultMessage: 'Connection error',
  },
  socketErrorModalMessage: {
    id: 'web.partner.videomeeting.modal.socketErrorModalMessage',
    defaultMessage: 'There was a connection error, please try again later',
  },
  ok: {
    id: 'web.partner.videomeeting.modal.ok',
    defaultMessage: 'Ok',
  },
});

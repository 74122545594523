import styled, { css } from 'styled-components';
import { Link as _link } from 'react-router-dom';
import omit from '../../utils/omitProps';

export const Link = styled(omit(_link, ['small']))`
  font-size: ${({ small }) => (small ? '1.2rem' : '1.6rem')};
  /* TODO: aggiungere nelle variabili */
  color: #535353;

  span {
    font-size: inherit;
    color: currentColor;
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
    span {
      color: ${({ theme }) => theme.primary};
    }
  }

  svg {
    margin-left: 10px;
    height: 14px;
    color: currentColor;
  }

  ${({ textLink }) =>
    textLink &&
    css`
      display: inline-flex;
      align-items: center;
      height: 35px;
      padding: 0 15px;
      transition: all 0.4s;
      overflow: visible;
      border-radius: 18px;

      &:hover {
        color: ${({ theme }) => theme.white};
        span {
          color: currentColor;
        }
        background: ${({ theme }) => theme.primary};
      }
    `};

  &:active {
    text-decoration: underline;
  }
`;

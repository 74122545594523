import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import {
  settingRequest,
  settingResponse,
  autoApproveResponse,
  autoApproveRequest,
  videoMeetingActivationRequest,
  videoMeetingActivationResponse,
} from './actions';

const initialState = fromJS({
  loading: false,
  data: null,
  videoMeetingActivation: null,
});

export default handleActions(
  {
    [settingRequest]: state => state.set('loading', true),
    [settingResponse]: (state, { data }) =>
      state.set('data', data).set('loading', false),
    [autoApproveRequest]: state => state.set('loading', true),
    [autoApproveResponse]: (state, { autoApprove }) =>
      state.set('autoApprove', autoApprove).set('loading', false),
    [videoMeetingActivationRequest]: state => state.set('loading', true),
    [videoMeetingActivationResponse]: (
      state,
      { videomeeting: { videomeeting } },
    ) =>
      state.set('videoMeetingActivation', videomeeting).set('loading', false),
  },
  { initialState },
);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCookiePolicyConsent,
  makeSelectGoogleAnalyticsInitialized,
} from './selectors';

const mapStateToProps = createStructuredSelector({
  cookiePolicyConsent: makeSelectCookiePolicyConsent(),
  googleAnalyticsInitialized: makeSelectGoogleAnalyticsInitialized(),
});

export default compose(connect(mapStateToProps));

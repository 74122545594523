import styled from 'styled-components';
import omit from '../../utils/omitProps';
import CaffeinaArrowDown from 'images/icons/caffeina-icons/arrow-down';

export const PanelGroup = styled.div`
  margin-top: 30px;
  width: 100%;
  
`;
export const PanelCollapse = styled.div`
  transition: max-height 0.5s ease;
  max-height: ${({ open }) => (open ? 'auto' : '0')};
  overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
  cursor: pointer;
`;

export const PanelHeader = styled.div`
border-bottom: ${({ theme, borderBottom }) =>
borderBottom ? `1px solid ${theme.gray200}` : ''};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({  noMarginLR }) => 
  noMarginLR ? `20px 0px` : '20px'};
`;
export const PanelHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PanelHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PanelTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-right: 20px;
`;

export const PanelSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  display: flex;
  align-items: flex-end;
  color: ${({ theme }) => theme.gray400};
`;
export const PanelClose = styled.div`
  max-height: 0;
`;

export const IconName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
`;
export const IconWrapper = styled.div`
  margin-right: 20px;
`;

export const DropDownArrow = styled(omit(CaffeinaArrowDown, ['active']))`
  font-size: 2.4rem;
  height: 20px;
  transition: max-height 0.5s ease;
  transform: ${({ active }) => (active ? 'rotate(180deg)' : '')};
`;

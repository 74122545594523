import { APIClient, APIClientPU } from '@common/common/utils';
import { AxiosResponse } from 'axios';
import { without } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {
  GetPracticesListRequestPayload,
  GetPraticesListSuccessPayload,
  GetPracticeDetailsRequestPayload,
  GetPracticeDetailsSuccessPayload,
  GetPracticesCategoriesSuccessPayload,
  GetPracticesStatusesSuccessPayload,
  GetPracticeMedicalServicesRequestPayload,
  GetPraticeMedicalServicesSuccessPayload,
  GetPraticeMedicalServicesDentalSuccessPayload,
  GetPracticeDoctorsRequestPayload,
  GetPraticeDoctorsSuccessPayload,
  PutPracticeRequestPayload,
  PutPracticeSuccessPayload,
  PutProntoCarePracticeRequestPayload,
  DeletePracticeServicesRequestPayload,
  DeletePracticeServicesSuccessPayload,
  PayPracticeCouponRequestPayload,
  PayPracticeCouponSuccessPayload,
  ClosePracticeRequestPayload,
  ClosePracticeSuccessPayload,
  AddInvoiceRequestPayload,
  AddInvoiceSuccessPayload,
  AddInvoiceErrorsPayload,
  GetFamilyListRequestPayload,
  GetFamilyListSuccessPayload,
  GetVenuesListRequestPayload,
  GetVenuesListSuccessPayload,
  CreateCouponPracticeRequestPayload,
} from './types';

export async function getPracticesList({
  page = 1,
  itemsPerPage = 10,
  currentTextSearch = '',
  sortBy = [],
  popoverFilters = {},
  homeTab = undefined,
}: GetPracticesListRequestPayload): Promise<GetPraticesListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'eq',
          value: currentTextSearch,
        },
      ]
    : [];

  const domainFilter =
    popoverFilters?.domain !== undefined
      ? popoverFilters?.domain && {
          field: 'aliasDomain',
          operator: 'eq',
          value: popoverFilters?.domain,
        }
      : null;

  const dateFromFilter =
    popoverFilters?.dateFrom !== undefined
      ? popoverFilters?.dateFrom && {
          field: 'dateFrom',
          operator: 'eq',
          value: popoverFilters?.dateFrom,
        }
      : null;

  const dateToFilter =
    popoverFilters?.dateTo !== undefined
      ? popoverFilters?.dateTo && {
          field: 'dateTo',
          operator: 'eq',
          value: popoverFilters?.dateTo,
        }
      : null;

  const venuesFilter = !isEmpty(popoverFilters?.venues)
    ? {
        field: 'providerId',
        operator: 'in',
        value: popoverFilters?.venues,
      }
    : null;

  const categoryFilter =
    popoverFilters?.category !== undefined
      ? popoverFilters?.category && {
          field: 'category',
          operator: 'eq',
          value: popoverFilters?.category,
        }
      : null;

  const statusFilter = !isEmpty(popoverFilters?.status)
    ? {
        field: 'aliasStatus',
        operator: 'in',
        value: popoverFilters?.status,
      }
    : null;

  const filtersBy = !isEmpty(popoverFilters)
    ? [domainFilter, dateFromFilter, dateToFilter, venuesFilter, categoryFilter, statusFilter]
    : null;

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters =
    !isEmpty(searchTextFilter) || !isEmpty(filtersBy)
      ? {
          filter: [...searchTextFilter, ...without(filtersBy, undefined, null)],
        }
      : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetPraticesListSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return { ...response.data, homeTab };
  } catch (error) {
    console.error('getPracticesList - Error: ', error);
    throw error;
  }
}

export async function getPracticeDetails({
  practiceId,
}: GetPracticeDetailsRequestPayload): Promise<GetPracticeDetailsSuccessPayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${practiceId}`,
    );

    return response.data;
  } catch (error) {
    console.error('getPracticeDetails - Error: ', error);
    throw error;
  }
}

export async function getPracticesCategories(): Promise<GetPracticesCategoriesSuccessPayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/categories`,
    );

    return response.data;
  } catch (error) {
    console.error('getPracticesCategories - Error: ', error);
    throw error;
  }
}

export async function getPracticesStatuses(): Promise<GetPracticesStatusesSuccessPayload> {
  try {
    const response = await APIClientPU.get(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/statuses`,
    );

    return response.data;
  } catch (error) {
    console.error('getPracticesStatuses - Error: ', error);
    throw error;
  }
}

export async function getPracticeMedicalServices({
  page,
  itemsPerPage,
  currentTextSearch = '',
  aliasDomain,
  assId,
  providerId,
  ticketType,
  onlyPublished = false,
}: GetPracticeMedicalServicesRequestPayload): Promise<GetPraticeMedicalServicesSuccessPayload> {
  const filter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'eq',
          value: currentTextSearch,
        },
        {
          field: 'aliasDomain',
          operator: 'eq',
          value: aliasDomain,
        },
        {
          field: 'providerId',
          operator: 'eq',
          value: providerId,
        },
        {
          field: 'onlyPublished',
          operator: 'eq',
          value: onlyPublished,
        },
      ]
    : [
        {
          field: 'aliasDomain',
          operator: 'eq',
          value: aliasDomain,
        },
        {
          field: 'providerId',
          operator: 'eq',
          value: providerId,
        },
        {
          field: 'onlyPublished',
          operator: 'eq',
          value: onlyPublished,
        },
      ];

  if (currentTextSearch && !isUndefined(assId)) {
    filter.push({
      field: 'assId',
      operator: 'eq',
      value: assId,
    });
  }

  if (!isEmpty(filter) && ticketType) {
    filter.push({
      field: 'ticketType',
      operator: 'eq',
      value: ticketType,
    });
  }

  const filterBy = !isEmpty(filter) ? { filter } : null;

  const orderBy = {
    orderBy: [
      {
        field: 'name',
        order: 'asc',
      },
    ],
  };

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetPraticeMedicalServicesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/medicalservices/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...filterBy,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getPracticeMedicalServices - Error: ', error);
    throw error;
  }
}

export async function getPracticeMedicalServicesDental(): Promise<GetPraticeMedicalServicesDentalSuccessPayload> {
  try {
    const response = await APIClientPU.get<any, AxiosResponse<GetPraticeMedicalServicesDentalSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/medicalservicesdental`,
    );

    return response.data;
  } catch (error) {
    console.error('getPracticeMedicalServicesDental - Error: ', error);
    throw error;
  }
}

export async function getPracticeDoctors({
  providerId,
  medicalserviceId,
}: GetPracticeDoctorsRequestPayload): Promise<GetPraticeDoctorsSuccessPayload> {
  const filter = [
    {
      field: 'providerId',
      operator: 'eq',
      value: providerId,
    },
    {
      field: 'medicalserviceId',
      operator: 'eq',
      value: medicalserviceId,
    },
  ];

  const filterBy = { filter };

  const orderBy = {
    orderBy: [
      {
        field: 'name',
        order: 'asc',
      },
    ],
  };

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetPraticeDoctorsSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/doctors/filter?page=1&itemsPerPage=1000`,
      {
        ...filterBy,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getPracticeDoctors - Error: ', error);
    throw error;
  }
}

export async function putPractice({
  id,
  operation,
  data,
  hospitalAdmissionDate,
  hospitalDischargeDate,
  bookingDate,
  editNote,
  editNoteType,
  type,
}: PutPracticeRequestPayload): Promise<PutPracticeSuccessPayload> {
  try {
    const response = await APIClientPU.put<any, AxiosResponse<PutPracticeSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}`,
      {
        operation,
        editNote,
        editNoteType,
        medicalServicesSummary: data,
        hospitalAdmissionDate,
        hospitalDischargeDate,
        ...(bookingDate &&
          type && {
            bookingDate,
            type,
          }),
      },
    );

    return response.data;
  } catch (error) {
    console.error('getPracticesStatuses - Error: ', error);
    throw error;
  }
}

export async function putProntoCarePractice({
  id,
  operation,
  bookingDate,
  type,
}: PutProntoCarePracticeRequestPayload): Promise<PutPracticeSuccessPayload> {
  try {
    const response = await APIClientPU.put<any, AxiosResponse<PutPracticeSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}`,
      { operation, bookingDate, type },
    );

    return response.data;
  } catch (error) {
    console.error('getPracticesStatuses - Error: ', error);
    throw error;
  }
}

export async function deletePracticeServices({
  id,
  services,
}: DeletePracticeServicesRequestPayload): Promise<DeletePracticeServicesSuccessPayload> {
  try {
    const response = await APIClientPU.delete<any, AxiosResponse<DeletePracticeServicesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}`,
      { data: { services } },
    );

    return response.data;
  } catch (error) {
    console.error('deletePracticeServices - Error: ', error);
    throw error;
  }
}

export async function payPracticesCoupon({
  practiceId,
  ticketNumber,
}: PayPracticeCouponRequestPayload): Promise<PayPracticeCouponSuccessPayload> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<PayPracticeCouponSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${practiceId}/pay`,
      {
        ticketNumber,
      },
    );

    return { ...response.data };
  } catch (error) {
    console.error('payPracticesCoupon - Error: ', error);
    throw error;
  }
}

export async function closePractice({
  practiceId,
  editNote,
  editNoteType,
}: ClosePracticeRequestPayload): Promise<ClosePracticeSuccessPayload> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<PayPracticeCouponSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${practiceId}/close`,
      {
        editNote,
        editNoteType,
      },
    );

    return response.data;
  } catch (error) {
    console.error('closePractice - Error: ', error);
    throw error;
  }
}

export async function addInvoice({
  practiceId,
  data,
  skipWarnings,
}: AddInvoiceRequestPayload): Promise<AddInvoiceSuccessPayload | AddInvoiceErrorsPayload> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<AddInvoiceSuccessPayload | AddInvoiceErrorsPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${practiceId}/invoices`,
      {
        ...data,
        skipWarnings,
      },
    );

    return { ...response.data, status: response.status };
  } catch (error) {
    console.error('addInvoice - Error: ', error);
    throw error;
  }
}

export async function getFamilyList({
  currentTextSearch = '',
  sortBy = [],
}: GetFamilyListRequestPayload): Promise<GetFamilyListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'searchText',
          operator: 'contains',
          value: currentTextSearch,
        },
      ]
    : [];

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetFamilyListSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/family/filter`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getFamilyList - Error: ', error);
    throw error;
  }
}

export async function getVenuesList({
  currentTextSearch = '',
  sortBy = [],
}: GetVenuesListRequestPayload): Promise<GetVenuesListSuccessPayload> {
  const searchTextFilter = currentTextSearch
    ? [
        {
          field: 'name',
          operator: 'contains',
          value: currentTextSearch,
        },
        {
          field: 'convenzioni.name',
          operator: 'eq',
          value: 'Coupon',
        },
      ]
    : [];

  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  const allFilters = !isEmpty(searchTextFilter)
    ? {
        filter: [...searchTextFilter],
      }
    : {};

  try {
    const response = await APIClient.post<any, AxiosResponse<GetVenuesListSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_BASE_URL}/partners/pu/me/locations?page=1&itemsPerPage=999`,
      {
        ...allFilters,
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getVenuesList - Error: ', error);
    throw error;
  }
}

export async function createPracticesCoupon({ data }: CreateCouponPracticeRequestPayload): Promise<any> {
  try {
    const response = await APIClientPU.post<any, AxiosResponse<any>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/coupon`,
      {
        ...data,
      },
    );

    return { ...response.data };
  } catch (error) {
    console.error('createPracticesCoupon - Error: ', error);
    throw error;
  }
}

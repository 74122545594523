import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { analyticsUUIDResponse } from './actions';

const initialState = fromJS({
  UUID: null,
});

export default handleActions(
  {
    [analyticsUUIDResponse]: (state, { data }) => state.set('UUID', data.uuid),
  },
  { initialState },
);

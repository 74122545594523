import { defineMessages } from 'react-intl';

export default defineMessages({
  noOption: {
    id: 'web.shared.components.InputLocalAutosuggest.noOption',
    defaultMessage: 'No results found',
  },
  placeholder: {
    id: 'web.shared.components.InputLocalAutosuggest.placeholder',
    defaultMessage: 'Select...',
  },
});

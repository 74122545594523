import React from 'react';

const RadioDisabled = props => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E2E2E2" />
  </svg>
);

export default RadioDisabled;

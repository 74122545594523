import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Spinkit from 'react-spinkit';
import { FileFormats, CustomIcon, Button, RoundedDelete } from './styles';
import messages from './messages';
import FileDecoratedPreview from './FileDecoratedPreview';

function MyDummyPreview({
  input,
  file,
  clearInput,
  fileUrlExists,
  getData,
  loading,
  hideDelete,
  customMessage,
  typeLabel,
  maxFileSizeMB,
  contract,
  onDroppedFile,
  forcePracticeIcon,
}) {
  if (!input || (input.value && input.value.size === 0)) {
    if (!file)
      return (
        <Button size="lg" color="link">
          <CustomIcon>+</CustomIcon>
          <FormattedMessage {...messages[customMessage || 'selectFile']} />
          <FileFormats>{`${typeLabel} - ${maxFileSizeMB}MB`}</FileFormats>
        </Button>
      );

    return (
      <Fragment>
        {loading ? (
          <Fragment>
            <Spinkit name="ball-spin-fade-loader" color="#fcb731" />
          </Fragment>
        ) : (
          <Fragment>
            {!hideDelete && (
              <Button color="link" deleteIcon="true" onClick={clearInput}>
                <RoundedDelete />
              </Button>
            )}
            <FileDecoratedPreview
              input={input}
              file={file}
              disableClick={fileUrlExists}
              getData={getData}
              contract={contract}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {loading ? (
        <Fragment>
          <Spinkit name="ball-spin-fade-loader" color="#fcb731" />
        </Fragment>
      ) : (
        <Fragment>
          {!hideDelete && (
            <Button color="link" deleteIcon="true" onClick={clearInput}>
              <RoundedDelete />
            </Button>
          )}
          <FileDecoratedPreview
            input={input}
            file={file}
            disableClick={!fileUrlExists}
            getData={getData}
            contract={contract}
            onDroppedFile={onDroppedFile}
            forcePracticeIcon={forcePracticeIcon}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

MyDummyPreview.propTypes = {
  input: PropTypes.shape({}),
  file: PropTypes.any,
  clearInput: PropTypes.func.isRequired,
  fileUrlExists: PropTypes.bool,
  getData: PropTypes.string,
  loading: PropTypes.bool,
  hideDelete: PropTypes.bool,
  customMessage: PropTypes.string,
  typeLabel: PropTypes.string,
  maxFileSizeMB: PropTypes.number,
  contract: PropTypes.bool,
  onDroppedFile: PropTypes.func,
  forcePracticeIcon: PropTypes.bool,
};

MyDummyPreview.defaultProps = {
  input: null,
  file: null,
  fileUrlExists: null,
  loading: false,
  hideDelete: false,
  typeLabel: null,
  customMessage: null,
  contract: null,
  getData: null,
  onDroppedFile: null,
  forcePracticeIcon: false,
};

export default MyDummyPreview;

/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  loginError: {
    id: 'web.partner.components.Errors.loginError',
    defaultMessage: 'Login fail',
  },
  loginDataError: {
    id: 'web.partner.components.Errors.loginDataError',
    defaultMessage: 'Login data fail',
  },
  registerError: {
    id: 'web.partner.components.Errors.registerError',
    defaultMessage: 'Register fail',
  },
  registerDataError: {
    id: 'web.partner.components.Errors.registerDataError',
    defaultMessage: 'A user with this email or username already exists',
  },
  registerSuccess: {
    id: 'web.partner.components.Errors.registerSuccess',
    defaultMessage: 'Register success',
  },
  facebookGenericError: {
    id: 'web.partner.components.Errors.facebookGenericError',
    defaultMessage: 'Login with Facebook failed',
  },
  facebookFirstAccessError: {
    id: 'web.partner.components.Errors.facebookFirstAccessError',
    defaultMessage: 'At the first login you must manually enter the username and password sent by email',
  },
  changePasswordError: {
    id: 'web.partner.components.Errors.changePasswordError',
    defaultMessage: 'Change Password fail',
  },
  changePasswordDataError: {
    id: 'web.partner.components.Errors.changePasswordDataError',
    defaultMessage: 'Change Password fields are not valid',
  },
  changePasswordSuccess: {
    id: 'web.partner.components.Errors.changePasswordSuccess',
    defaultMessage: 'Password changed successfully',
  },
  changeEmailError: {
    id: 'web.partner.components.Errors.changeEmailError',
    defaultMessage: 'Change Email fail',
  },
  changeEmailSuccess: {
    id: 'web.partner.components.Errors.changeEmailSuccess',
    defaultMessage: 'Email changed successfully',
  },
  forgotPasswordError: {
    id: 'web.partner.components.Errors.forgotPasswordError',
    defaultMessage: 'Forgot Password fail',
  },
  forgotPasswordDataError: {
    id: 'web.partner.components.Errors.forgotPasswordDataError',
    defaultMessage: 'Forgot Password fail',
  },
  forgotPasswordSuccess: {
    id: 'web.partner.components.Errors.forgotPasswordSuccess',
    defaultMessage: 'Reset Password success',
  },
  forgotUsernameError: {
    id: 'web.partner.components.Errors.forgotUsernameError',
    defaultMessage: 'Remind Username fail',
  },
  forgotUsernameDataError: {
    id: 'web.partner.components.Errors.forgotUsernameDataError',
    defaultMessage: 'Remind Username fail',
  },
  forgotUsernameSuccess: {
    id: 'web.partner.components.Errors.forgotUsernameSuccess',
    defaultMessage: 'You will receive a mail with your username soon.',
  },
  getProfileDataError: {
    id: 'web.partner.components.Errors.getProfileDataError',
    defaultMessage: 'Profile call fail',
  },
  getProfileError: {
    id: 'web.partner.components.Errors.getProfileError',
    defaultMessage: 'Profile error',
  },
  setProfileDataSuccess: {
    id: 'web.partner.components.Errors.setProfileDataSuccess',
    defaultMessage: 'Profile success',
  },
  serverError: {
    id: 'web.partner.components.Errors.serverError',
    defaultMessage: 'Data sends to server are not valid.',
  },
  unableToUpdateBookingStatus: {
    id: 'web.partner.components.Errors.unableToUpdateBookingStatus',
    defaultMessage: 'Unable to update booking status.',
  },
  unableToRejectRequest: {
    id: 'web.partner.components.Errors.unableToRejectRequest',
    defaultMessage: 'Unable to reject request.',
  },
  confirmRejectRequest: {
    id: 'web.partner.components.Errors.confirmRejectRequest',
    defaultMessage: 'Request rejected.',
  },
  unableToGetCalendar: {
    id: 'web.partner.components.Errors.unableToGetCalendar',
    defaultMessage: 'Unable to get calendar data.',
  },
  patientNotFound: {
    id: 'web.partner.components.Errors.patientNotFound',
    defaultMessage: 'Patient not found.',
  },
  confirmUpdateAvailabilities: {
    id: 'web.partner.components.Errors.confirmUpdateAvailabilities',
    defaultMessage: 'Availabilities agenda has been updated.',
  },
  confirmUpdateAvailabilitiesSpecialOffer: {
    id: 'web.partner.components.Errors.confirmUpdateAvailabilitiesSpecialOffer',
    defaultMessage:
      'Availabilities agenda has been updated. Services that go below the minimum price will be adjusted to the AON minimum price limit',
  },
  confirmUpdateCommitments: {
    id: 'web.partner.components.Errors.confirmUpdateCommitments',
    defaultMessage: 'Commitments has been updated.',
  },
  confirmUpdateNotes: {
    id: 'web.partner.components.Errors.confirmUpdateNotes',
    defaultMessage: 'Notes has been updated.',
  },
  getCommunicationListError: {
    id: 'web.partner.components.Errors.getCommunicationListError',
    defaultMessage: 'Communication List data error.',
  },
  getAreUnreadCommunicationsError: {
    id: 'web.partner.components.Errors.getAreUnreadCommunicationsError',
    defaultMessage: 'Unread Communications data error.',
  },
  getCommunicationError: {
    id: 'web.partner.components.Errors.getCommunicationError',
    defaultMessage: 'Get message communication data error.',
  },
  setCommunicationStatusError: {
    id: 'web.partner.components.Errors.setCommunicationStatusError',
    defaultMessage: 'Error to set communication to read.',
  },
  newReplyError: {
    id: 'web.partner.components.Errors.newReplyError',
    defaultMessage: 'Error to reply to a message.',
  },
  changeAppointmentError: {
    id: 'web.partner.components.Errors.changeAppointmentError',
    defaultMessage: 'the appointment change was not successful',
  },
  changeAppointmentSuccess: {
    id: 'web.partner.components.Errors.changeAppointmentSuccess',
    defaultMessage: 'the appointment change was successful',
  },
  cancelAppointmentSuccess: {
    id: 'web.partner.components.Errors.cancelAppointmentSuccess',
    defaultMessage: 'The cancellation of the appointment was successful',
  },
  cancelAppointmentError: {
    id: 'web.partner.components.Errors.cancelAppointmentError',
    defaultMessage: 'the cancellation of the appointment was not successful',
  },
  acceptAppointmentSuccess: {
    id: 'web.partner.components.Errors.acceptAppointmentSuccess',
    defaultMessage: 'The acceptance of the appointment was successful',
  },
  acceptAppointmentError: {
    id: 'web.partner.components.Errors.acceptAppointmentError',
    defaultMessage: 'Acceptance of the appointment was not successful',
  },
  errorGeneric: {
    id: 'web.partner.components.Errors.errorGeneric',
    defaultMessage: 'The call was not successful',
  },
  createPriceList: {
    id: 'web.partner.components.Errors.createPriceList',
    defaultMessage: 'The creation of the price list was successful',
  },
  savePriceList: {
    id: 'web.partner.components.Errors.savePriceList',
    defaultMessage: 'The price list was saved successfully',
  },
  deletePriceList: {
    id: 'web.partner.components.Errors.deletePriceList',
    defaultMessage: 'The cancellation of the price list was successful',
  },
  uploadInvoiceError: {
    id: 'web.partner.components.Errors.uploadInvoiceError',
    defaultMessage: 'Error loading invoice. Try again later.',
  },
  uploadInvoiceSuccess: {
    id: 'web.partner.components.Errors.uploadInvoiceSuccess',
    defaultMessage: 'The invoice was successful uploaded.',
  },
  getProfileSummaryDataError: {
    id: 'web.partner.components.Errors.getProfileSummaryDataError',
    defaultMessage: 'Profile Summary call fail',
  },
  getProfileSummaryError: {
    id: 'web.partner.components.Errors.getProfileSummaryError',
    defaultMessage: 'Profile Summary error',
  },
  getPendingBookingsError: {
    id: 'web.partner.components.Errors.getPendingBookingsError',
    defaultMessage: 'Get pending bookings fail',
  },
  getAutoApprovedError: {
    id: 'web.partner.components.Errors.getAutoApprovedError',
    defaultMessage: 'Get setting auto approve fail',
  },
  setAutoApprovedSuccess: {
    id: 'web.partner.components.Errors.setAutoApprovedSuccess',
    defaultMessage: 'Set setting auto approve was successful',
  },
  setAutoApprovedError: {
    id: 'web.partner.components.Errors.setAutoApprovedError',
    defaultMessage: 'Set setting auto approve fail',
  },
  getBidSLAError: {
    id: 'web.partner.components.Errors.getBidSLAError',
    defaultMessage: 'Get setting bid SLA fail',
  },
  setBidSLASuccess: {
    id: 'web.partner.components.Errors.setBidSLASuccess',
    defaultMessage: 'Set setting bid SLA was successful',
  },
  setBidSLAError: {
    id: 'web.partner.components.Errors.setBidSLAError',
    defaultMessage: 'Set setting bid SLA fail',
  },
  unableToGetSyncedCalendar: {
    id: 'web.partner.components.Errors.unableToGetSyncedCalendar',
    defaultMessage: 'Unable to get synced calendar data.',
  },
  unableToAddCalendar: {
    id: 'web.partner.components.Errors.unableToAddCalendar',
    defaultMessage: 'Unable to add new calendar.',
  },
  notHavePermission: {
    id: 'web.partner.components.Errors.notHavePermission',
    defaultMessage: 'You do not have permission to perform this action',
  },
  unableToRemoveCalendar: {
    id: 'web.partner.components.Errors.unableToRemoveCalendar',
    defaultMessage: 'Unable to remove calendar, please try again later.',
  },
  sendMessageSuccessProgress: {
    id: 'web.partner.components.Success.sendMessageSuccessProgress',
    defaultMessage: 'Sending in progress...',
  },
  sendMessageError: {
    id: 'web.partner.components.Errors.sendMessage',
    defaultMessage: 'message not sent',
  },
  invalidSectionError: {
    id: 'web.partner.components.Errors.invalidSectionError',
    defaultMessage: 'Check the fields in red',
  },
  sendPraticeSectionError: {
    id: 'web.partner.components.Errors.sendPraticeSectionError',
    defaultMessage: 'The submission of the practice section was not successful',
  },
  sendPraticeError: {
    id: 'web.partner.components.Errors.sendPraticeError',
    defaultMessage: 'Sending the practice was not successful',
  },
  editPraticeError: {
    id: 'web.partner.components.Errors.editPraticeError',
    defaultMessage: 'It was not possible to pass the practice in edit mode',
  },
  zendeskTicketCreatedSuccessfully: {
    id: 'web.partner.components.Errors.zendeskTicketCreatedSuccessfully',
    defaultMessage: 'Your request has been sent successfully!',
  },
  updateBookingStatusCompletedSuccess: {
    id: 'web.partner.components.Errors.updateBookingStatusCompletedSuccess',
    defaultMessage: 'You have completed the visit correctly',
  },
  updateBookingStatusNoshowSuccess: {
    id: 'web.partner.components.Errors.updateBookingStatusNoshowSuccess',
    defaultMessage: 'The appointment was successfully updated',
  },
  updateBookingStatusChangeRequestedSuccess: {
    id: 'web.partner.components.Errors.updateBookingStatusChangeRequestedSuccess',
    defaultMessage: 'The appointment was successfully changed',
  },
  updateBookingStatusConfirmedSuccess: {
    id: 'web.partner.components.Errors.updateBookingStatusConfirmedSuccess',
    defaultMessage: 'Booking confirmation was successful',
  },
  registrationWizardDataNotSaved: {
    id: 'web.partner.components.Errors.registrationWizardDataNotSaved',
    defaultMessage: 'The data entered are incomplete and have not been saved',
  },
  getIbanDataError: {
    id: 'web.partner.components.Errors.getIbanDataError',
    defaultMessage: 'Iban call fail',
  },
  saveVideomeetingNotesSuccess: {
    id: 'web.partner.components.Errors.saveVideomeetingNotesSuccess',
    defaultMessage: 'Video meeting notes saved succesfully',
  },
  saveVideomeetingNotesError: {
    id: 'web.partner.components.Errors.saveVideomeetingNotesError',
    defaultMessage: 'Error saving video meeting notes',
  },
  getVideoMeetingActivationError: {
    id: 'web.partner.components.Errors.getVideoMeetingActivationError',
    defaultMessage: 'Get setting Video meeting activation fail',
  },
  setVideoMeetingActivationSuccess: {
    id: 'web.partner.components.Errors.setVideoMeetingActivationSuccess',
    defaultMessage: 'Set setting Video meeting activation was successful',
  },
  setVideoMeetingActivationError: {
    id: 'web.partner.components.Errors.setVideoMeetingActivationError',
    defaultMessage: 'Set setting Video meeting activation fail',
  },
  readDocumentsError: {
    id: 'web.partner.components.Errors.readDocumentsError',
    defaultMessage: 'Error during documents reading',
  },
  downloadDocumentError: {
    id: 'web.partner.components.Errors.downloadDocumentError',
    defaultMessage: 'Error during document download',
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import {
  AvatarInputWrap,
  Wrapper,
  Dropzone,
  EditIconAvatar,
  RedPointEdit,
  CloseButton,
  Avatar,
} from './styles';

function AvatarInput({
  handleDrop,
  image,
  input,
  meta,
  resetImage,
  firstName,
  lastName,
  change,
  accept,
}) {
  const img = (input.value && input.value[0]) || image;
  return (
    <AvatarInputWrap>
      {img && (
        <CloseButton
          onClick={e => {
            e.preventDefault();
            input.onChange(null);
            resetImage();
            change(meta.form, 'profilePictureId', null);
          }}
        />
      )}
      <Dropzone {...input} onDrop={handleDrop} accept={accept}>
        <Wrapper>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            size={100}
            url={img.preview || img}
          />
          <RedPointEdit>
            <EditIconAvatar size={12} color="#fff" />
          </RedPointEdit>
        </Wrapper>
      </Dropzone>
    </AvatarInputWrap>
  );
}

AvatarInput.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  resetImage: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
  change: PropTypes.func.isRequired,
  accept: PropTypes.string,
};

AvatarInput.defaultProps = {
  image: null,
  accept: null,
};

export default AvatarInput;

import React from 'react';
import PropTypes from 'prop-types';
import LocaleCode from 'locale-code';
import FlagIconFactory from 'react-flag-icon-css';
import { FlagWrapper } from './styles';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const FlagGenerator = ({ language, width, height }) => {
  if (LocaleCode.validate(language.replace('_', '-'))) {
    const countryCode = LocaleCode.getCountryCode(
      language.replace('_', '-'),
    ).toLowerCase();
    return (
      <FlagWrapper width={width} height={height}>
        <FlagIcon code={countryCode} size="2x" Component="div" />
      </FlagWrapper>
    );
  }
  return <span>noFlag</span>;
};

FlagGenerator.propTypes = {
  language: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FlagGenerator;

import React from 'react';

const Expanded = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 19" {...props}>
    <title>Expanded</title>
    <g fill="#000" fillRule="nonzero">
      <path d="M8.26 1.52a.513.513 0 01-.489-.488.436.436 0 01.457-.457l8.908.3c.261.01.48.228.489.489l.3 8.908a.436.436 0 01-.457.457.513.513 0 01-.488-.488l-.284-8.437-8.437-.284zM9.74 17.48c.261.009.48.227.489.488a.436.436 0 01-.457.457l-8.908-.3a.513.513 0 01-.489-.489l-.3-8.908a.436.436 0 01.457-.457c.26.009.48.228.488.488l.284 8.437 8.437.284z" />
    </g>
  </svg>
);

export default Expanded;

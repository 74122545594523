import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${props => props.theme.gridBreakpoints.md}) {
    height: auto;
  }
`;

export const BackgroundWrap = styled.div`
  margin-bottom: 0px;
`;

export const Content = styled.div`
  z-index: 1;
  padding: 20px;

  @media (max-width: ${props => props.theme.gridBreakpoints.md}) {
    margin-bottom: 0;
  }
`;

export const Img = styled.img`
  width: 100%;
`;

import { compose, withStateHandlers } from 'recompose';
import HelpVideomeetingContent from './HelpVideomeetingContent';

export default compose(
  withStateHandlers(
    {
      currentPage: 1,
      totalPages: 3,
    },
    {
      changePage: ({ currentPage }) => () => ({
        currentPage: currentPage + 1,
      }),
      skip: ({ totalPages }) => () => ({
        currentPage: totalPages,
      }),
      exit: (_, { toggleModal }) => () => {
        toggleModal();
        return { currentPage: 1 };
      },
    },
  ),
)(HelpVideomeetingContent);

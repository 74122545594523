import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Avatar from 'components/shared/Avatar';
import { dateFormatter, hoursFormatter } from 'utils/formatters';
import {
  Wrapper,
  AvatarWrapper,
  InfoWrapper,
  GeneralInfoWrapper,
  GrayLabel,
  DatetimeInfoWrapper,
  Datetime,
  Button,
  ButtonsWrapper,
} from './styles';
import messages from './messages';

function Card({
  id,
  patient,
  code,
  healthService,
  startsAt,
  analyticsConfirm,
  analyticsReject,
  action,
}) {
  const { name } = healthService || {};
  const { firstName, lastName, profilePicture } = patient || {};
  return (
    <Wrapper key={id} color="blue">
      <AvatarWrapper>
        <Avatar
          attachmentId={profilePicture || null}
          firstName={firstName}
          lastName={lastName}
          size={50}
        />
      </AvatarWrapper>
      <InfoWrapper>
        <GeneralInfoWrapper>
          <h1>{name}</h1>
          <GrayLabel>{`${firstName || ''} ${lastName || ''}`.trim()}</GrayLabel>
        </GeneralInfoWrapper>
        <DatetimeInfoWrapper>
          <Datetime>
            <GrayLabel>
              <FormattedMessage {...messages.date} />
            </GrayLabel>
            {dateFormatter(startsAt)}
          </Datetime>
          <Datetime alignRight>
            <GrayLabel align="right">
              <FormattedMessage {...messages.time} />
            </GrayLabel>
            {hoursFormatter(startsAt)}
          </Datetime>
        </DatetimeInfoWrapper>
      </InfoWrapper>
      <ButtonsWrapper>
        <Button
          color="primary"
          analytics={analyticsConfirm}
          onClick={() => action({ bookingId: id, code, status: 'confirmed' })}
        >
          <FormattedMessage {...messages.confirm} />
        </Button>
        <Button
          color="secondary"
          outline
          analytics={analyticsReject}
          onClick={() => action({ bookingId: id, code, status: 'rejected' })}
        >
          <FormattedMessage {...messages.reject} />
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
}

Card.propTypes = {
  id: PropTypes.number,
  code: PropTypes.string.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePictureId: PropTypes.number,
  }),
  patient: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePictureId: PropTypes.number,
  }),
  healthService: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    specialityName: PropTypes.string,
  }),
  startsAt: PropTypes.string,
  analyticsConfirm: PropTypes.func,
  analyticsReject: PropTypes.func,
  action: PropTypes.func,
};

Card.defaultProps = {
  analyticsConfirm: null,
  analyticsReject: null,
  action: null,
};

export default Card;

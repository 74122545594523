import { handleActions } from 'redux-actions-helpers';
import { fromJS } from 'immutable';
import { showModal, hideModal } from './actions';

const initialState = fromJS({
  isOpen: false,
});

export default handleActions(
  {
    [showModal]: state => state.set('isOpen', true),
    [hideModal]: state => state.set('isOpen', false),
  },
  { initialState },
);

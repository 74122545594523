import React from 'react';
import PropTypes from 'prop-types';
import { StyledSwitch } from './styles';

function Switch({ checked, onChange, input, disabled }) {
  return (
    <StyledSwitch
      checked={checked}
      onChange={onChange}
      uncheckedIcon={false}
      checkedIcon={false}
      height={30}
      width={60}
      handleDiameter={20}
      disabled={disabled}
      {...input}
    />
  );
}

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  }),
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => null,
};

export default Switch;

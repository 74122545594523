import { defineMessages } from 'react-intl';

export default defineMessages({
  hangupModalTitle: {
    id: 'web.partner.videomeeting.modal.hangupModalTitle',
    defaultMessage: 'Exit from videomeeting',
  },
  hangupModalMessage: {
    id: 'web.partner.videomeeting.modal.hangupModalMessage',
    defaultMessage: 'Do you confirm that you want to exit this videomeeting?',
  },
  yes: {
    id: 'web.partner.videomeeting.modal.yes',
    defaultMessage: 'Confirm',
  },
  no: {
    id: 'web.partner.videomeeting.modal.no',
    defaultMessage: 'Cancel',
  },
});

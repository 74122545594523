import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelThis: {
    id: 'web.aon.components.Filters.cancelThis',
    defaultMessage: 'Cancel this',
  },
  cancelAll: {
    id: 'web.aon.components.Filters.cancelAll',
    defaultMessage: 'Cancel all',
  },
  apply: {
    id: 'web.aon.components.Filters.apply',
    defaultMessage: 'Apply',
  },
});

import styled from 'styled-components';
import { Button as _button } from '@components';

export const ModalTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizeBase};
  font-weight: bold;
  text-align: center;
`;

export const Button = styled(_button)`
  width: 310px;
  margin-top: 20px;
`;

export const ModalMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizeBase};
  text-align: center;
  margin-top: 20px;
`;

import styled from 'styled-components';
import { ThreeDots as _threeDots } from 'utils/icons';

const colorMapper = {
  green: '#41B005',
  yellow: '#FFCC00',
  red: '#E11C23',
  gray: '#dddddd',
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div`
  display: flex;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: 2px solid #fff;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${({ color }) => colorMapper[color]};
  margin-right: ${({ hasLabel }) => (hasLabel ? '8px' : '0')};
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  &,
  & > span {
    font-size: 1.4rem !important;
    color: ${({ theme }) => theme.gray300} !important;
  }
`;

export const ThreeDots = styled(_threeDots)`
  color: #fff;
  width: 65%;
`;

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  compose,
  withStateHandlers,
  branch,
  lifecycle,
  withHandlers,
  renderComponent,
} from 'recompose';
import { createStructuredSelector } from 'reselect';
import { selectFetchHeaders } from '../selectors';
// TODO: importate da partners
import { loadRemoteAttachmentById } from '../../../../app/utils/Api/attachments';
// TODO: *********************
import LoadingSpinner from './LoadingSpinner';
import MyFilePreview from './MyFilePreview';

const mapStateToProps = createStructuredSelector({
  fetchHeaders: selectFetchHeaders,
});

const RemoteFilePreview = compose(
  connect(mapStateToProps),
  withStateHandlers(
    {
      isLoading: false,
    },
    {
      showLoader:
        () =>
        (isLoading = true) => ({ isLoading }),
      setAttachment:
        () =>
        ({ filename, url }) => ({
          filename,
          url,
        }),
      showDefault:
        ({ defaultImg }) =>
        () => ({
          filename: defaultImg,
        }),
    },
  ),
  withHandlers({
    loadRemoteAttachment:
      ({
        attachmentId,
        fetchHeaders,
        setAttachment,
        showDefault,
        showLoader,
      }) =>
      () => {
        showLoader();
        if (attachmentId)
          loadRemoteAttachmentById(attachmentId, fetchHeaders)
            .then(setAttachment)
            .catch(showDefault)
            .then(() => showLoader(false));
        else {
          showLoader(false);
          showDefault();
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      const { attachmentId, loadRemoteAttachment } = this.props;
      if (attachmentId) {
        loadRemoteAttachment();
      }
    },
    componentDidUpdate({ attachmentId: oldAttachmentId }) {
      const { attachmentId, loadRemoteAttachment } = this.props;
      if (oldAttachmentId !== attachmentId) {
        loadRemoteAttachment();
      }
    },
  }),
  branch(({ isLoading }) => isLoading, renderComponent(LoadingSpinner)),
)(MyFilePreview);

RemoteFilePreview.propTypes = {
  attachmentId: PropTypes.number,
  defaultImg: PropTypes.string,
  filename: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default RemoteFilePreview;

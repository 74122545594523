import React from 'react';

const Contract = props => (
  <svg viewBox="0 0 30 30" {...props} width="1em" height="1em">
    <title>PROFILE-Contract</title>
    <g fill="currentColor">
      <path d="M23.85 9.15L17.71 3H6v24h18V9.29zM18 4.71L22.29 9H18zM23 26H7V4h10v6h6z" />
      <path d="M9 8h6v1H9zM9 11h12v1H9zM9 14h12v1H9z" />
      <circle cx={19} cy={21.5} r={2.5} />
      <path d="M9 20h5v1H9zM9 22h3v1H9z" />
    </g>
  </svg>
);

export default Contract;

import React from 'react';
import PropTypes from 'prop-types';
import { FamilyIcon } from 'components/shared/Avatar/styles';
import { Wrapper, Initials, ProfileIcon } from './styles';

function Avatar({ size, url, type, className, initials }) {
  const imgProps = url ? { backgroundImage: url } : {};
  const initialVisibility = initials && !url;
  const defaultImageVisibility = !initials && !url;
  return (
    <Wrapper size={size} {...imgProps} className={className}>
      {initialVisibility && <Initials size={size}>{initials}</Initials>}
      {defaultImageVisibility && (
        <ProfileIcon width={`${size + 25}px`} height={`${size + 25}px`} />
      )}
      {type === 'family' && <FamilyIcon />}
    </Wrapper>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  initials: PropTypes.string,
};

Avatar.defaultProps = {
  type: null,
  size: 40,
};

export default Avatar;

import { refreshAuthTokens } from '@common/auth/utils/token';
import { push } from 'connected-react-router/immutable';
import { makeSelectLocation } from 'containers/App/selectors';
import * as Cookies from 'js-cookie';
import { call, select, put, take, fork, cancel } from 'redux-saga/effects';
import { refreshTokenSuccess, refreshTokenFail, setTokenLocalStorage, logoutRequest } from '../actions';
import { REFRESH_TOKEN_REQUEST, LOGIN_RESPONSE, LOGOUT_RESPONSE } from '../constants';
import { selectRefreshToken, selectIsImpersonated } from '../selectors';

export function* jwtRefreshToken() {
  while (true) {
    yield take(REFRESH_TOKEN_REQUEST);

    // const refreshToken = yield select(selectRefreshToken());
    // const partnerStatus = yield select(selectPartnerStatus());
    const isImpersonated = yield select(selectIsImpersonated());
    const makeSelectedLocation = yield select(makeSelectLocation());

    try {
      const data = yield call(refreshAuthTokens);

      yield put(refreshTokenSuccess({ data }));
      // console.log('jwtRefreshToken>>>>', data);

      yield put(
        setTokenLocalStorage({
          accessToken: data.data.accessToken,
          refreshToken: data.data.refreshToken,
          partnerStatus: 'WEB_PARTNER',
          rememberMe: typeof window.localStorage.refresh_token_my_care === 'string',
        }),
      );

      if (data.status !== 'success') {
        yield put(refreshTokenFail());
        yield call(logoutRequest);
        yield put(push('/'));
      }

      if (isImpersonated) {
        const { hostname: domain } = window.location;
        Cookies.set('impersonate_refresh_token', data.data.refreshToken, { domain });
        Cookies.set('impersonate_token', data.data.accessToken, { domain });
      }
    } catch (e) {
      console.error(e);
      yield put(refreshTokenFail());
      yield put(
        push({
          pathname: `/`,
          state: makeSelectedLocation.state || null,
          search: makeSelectedLocation.search,
        }),
      );
    }
  }
}

export function* JWTokenRefreshListener() {
  while (true) {
    const refreshToken = yield select(selectRefreshToken());

    if (!refreshToken) {
      yield take(LOGIN_RESPONSE); // LOGIN_SUCCESS
    }

    const task = yield fork(jwtRefreshToken);
    yield take(LOGOUT_RESPONSE); // LOGOUT_SUCCESS

    yield cancel(task);
  }
}

import uniqBy from 'lodash/uniqBy';
import { fromJS } from 'immutable';

export const getCookiebyName = (name, defaultValue = null) => {
  const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
  return pair ? pair[1] : defaultValue;
};

export const mergeArrays = (newArray, oldArray) => {
  if (!oldArray) {
    return newArray;
  }
  const merge = [].concat(oldArray, newArray);
  return uniqBy(merge, 'id');
};

export const mergeResults = (newResults, oldResults) => {
  if (!oldResults) {
    return newResults;
  }
  return {
    ...oldResults,
    ...newResults,
    results: mergeArrays(newResults.results, oldResults.results),
  };
};

export const infiniteScrollReducerFor = fieldName => (state, data) => {
  const newResults = data[fieldName] || [];
  const oldResults = state.get(fieldName) && state.get(fieldName).toJS();

  return state
    .set(fieldName, fromJS(mergeResults(newResults, oldResults)))
    .set(`${fieldName}Loading`, false);
};

export const formatPrice = (priceCent = 0, toFixed) =>
  Number(priceCent / 100.0).toFixed(typeof toFixed === 'number' ? toFixed : 2);

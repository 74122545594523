import React from 'react';

const Basket = props => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
    <title>{props.title}</title>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M4.65 7.148c0-.523.425-.947.948-.947h24.804c.523 0 .947.424.947.947v27.905a.947.947 0 01-.947.947H5.598a.947.947 0 01-.947-.947V7.148zm1.895.948v26.01h22.91V8.095H6.545z" />
      <path d="M.947 8.096h34.106a.947.947 0 000-1.895H.947a.947.947 0 000 1.895z" />
      <path d="M12.402.947c0-.523.424-.947.947-.947h9.302c.523 0 .947.424.947.947v6.201a.947.947 0 01-.947.948h-9.302a.947.947 0 01-.947-.948v-6.2zm1.895.948V6.2h7.406V1.895h-7.406z" />
      <path d="M12.402.947c0-.523.424-.947.947-.947h9.302c.523 0 .947.424.947.947v6.201a.947.947 0 01-.947.948h-9.302a.947.947 0 01-.947-.948v-6.2zm1.895.948V6.2h7.406V1.895h-7.406zM21.703 13.35v15.502a.947.947 0 101.895 0V13.349a.947.947 0 00-1.895 0zM12.402 13.35v15.502a.947.947 0 101.895 0V13.349a.947.947 0 00-1.895 0z" />
    </g>
  </svg>
);

export default Basket;

import React from 'react';

const InvoiceIcon = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <g fill="currentColor">
      <path d="M21.6 27H6.4V7h3.2V3h11l5 5v15.8h-4zm-14.4-.8h13.6V12.6H16V7.8H7.2zM21.6 23h3.2V8.6H20V3.8h-9.6V7h6.17l5 5zm-4.8-11.2h3.43L16.8 8.37zm4-4h3.43L20.8 4.37z" />
      <path d="M12.8 15h-2.4v-1.6H8.8V11h1.6V9.4h2.4V11h1.6v2.4h-1.6zm-1.6-.8h.8v-1.6h1.6v-.8H12v-1.6h-.8v1.6H9.6v.8h1.6zM8.8 17.4h10.4v.8H8.8zM8.8 20.6h10.4v.8H8.8zM13.6 23.8h.8v.8h-.8zM16 23.8h.8v.8H16zM18.4 23.8h.8v.8h-.8z" />
    </g>
  </svg>
);

export default InvoiceIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Dot, Label, ThreeDots } from './styles';

const StatusDot = ({ color, label, size, withThreeDots, ...rest }) => (
  <Wrapper>
    <Dot color={color} size={size} hasLabel={!!label} {...rest}>
      {withThreeDots && <ThreeDots />}
    </Dot>
    {label && <Label>{label}</Label>}
  </Wrapper>
);

StatusDot.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.oneOf(['green', 'yellow', 'red', 'gray']).isRequired,
  size: PropTypes.string,
  withThreeDots: PropTypes.bool,
};

StatusDot.defaultProps = {
  size: '12',
  withThreeDots: false,
};

export default StatusDot;

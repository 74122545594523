import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  CustomPagination,
  CustomPaginationButtons,
  PageButtonComponentDisabled,
  PageButtonComponentNext,
  PageButtonComponent,
  TableVisiblePagesWrapper,
  Dots,
} from './styles';
import messages from './messages';
import Dropdown from './DropDown';

function Pagination({
  currentPage,
  itemsPerPage,
  nextPage,
  prevPage,
  totalCount,
  visiblePages,
  resetExpandedRows,
  setItemsPerPage,
  setCurrentPage,
}) {
  // const minShowData = currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage;
  // const itemsAtCurrentPage = currentPage * itemsPerPage;
  // const maxShowData =
  //   totalCount > itemsAtCurrentPage ? itemsAtCurrentPage : totalCount;
  const showElement = [5, 10, 20, 25, 50];

  return (
    <CustomPagination>
      <CustomPaginationButtons>
        <FormattedMessage {...messages.rowPerPage} />
        <Dropdown
          totalCount={totalCount}
          defaultValue={itemsPerPage}
          values={showElement}
          resetExpandedRows={resetExpandedRows}
          setItemsPerPage={setItemsPerPage}
        />
      </CustomPaginationButtons>

      {!!totalCount && (
        <CustomPaginationButtons>
          <PageButtonComponentDisabled>
            <PageButtonComponentDisabled
              onClick={() => prevPage && setCurrentPage({ page: prevPage })}
              disabled={!prevPage}
            >
              <FormattedMessage {...messages.previousPage} />
            </PageButtonComponentDisabled>
          </PageButtonComponentDisabled>
          <TableVisiblePagesWrapper>
            {visiblePages.map((newPageCounter, index, array) => (
              <Fragment key={newPageCounter}>
                {array[index - 1] + 2 <= newPageCounter && <Dots>...</Dots>}
                <PageButtonComponent
                  key={newPageCounter}
                  isActivePage={currentPage === newPageCounter}
                  onClick={() => setCurrentPage({ page: newPageCounter })}
                >
                  {newPageCounter}
                </PageButtonComponent>
              </Fragment>
            ))}
          </TableVisiblePagesWrapper>
          <PageButtonComponentNext>
            <PageButtonComponentNext
              onClick={() => nextPage && setCurrentPage({ page: nextPage })}
              disabled={!nextPage}
            >
              <FormattedMessage {...messages.nextPage} />
            </PageButtonComponentNext>
          </PageButtonComponentNext>
        </CustomPaginationButtons>
      )}
    </CustomPagination>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  nextPage: PropTypes.number,
  prevPage: PropTypes.number,
  totalCount: PropTypes.number,
  visiblePages: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetExpandedRows: PropTypes.func.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  itemsPerPage: null,
  totalCount: null,
  nextPage: null,
  prevPage: null,
};

export default Pagination;

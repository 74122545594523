import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Wrapper, ArrowLeft, ArrowRight } from './styles';
import Card from './Card';

function MyCarousel({ items, slidesToShow, slidesToScroll, action }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <Slider {...settings}>
        {items &&
          items.map((item, index) => (
            <Card key={Number(index)} {...item} action={action} />
          ))}
      </Slider>
    </Wrapper>
  );
}

MyCarousel.propTypes = {
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  items: PropTypes.array,
  action: PropTypes.func,
};

MyCarousel.defaultProps = {
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default MyCarousel;

import styled from 'styled-components';

export const ColoredSelect = styled.button`
  color: ${({ color }) => color};
  font-size: 1.6rem;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  &:hover {
    background: ${({ theme }) => theme.gray100};
  }
`;

export const SelectedValue = styled.div`
  width: 50px;
  color: ${({ color }) => color};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

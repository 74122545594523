import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import Label from '../Label';
import { isString } from '../../utils/validation';
import errorMessages from '../errorMessages';
import { FormGroup, ErrorMessage } from '../FormStyles';
import {
  Input,
  Currency,
  InputWrapper,
  CleanButton,
  CleanIcon,
} from './styles';

function MyInput({
  input,
  meta,
  error: overrideError,
  label,
  titleLabel,
  hideErrorString,
  requiredfield,
  overrideValue,
  validate,
  hiddenField,
  marginBottom,
  margin,
  maxWidth,
  size,
  currency,
  paddingLeft,
  inputRadioField,
  cleanButton,
  onClean,
  onKeyPress,
  ...props
}) {
  let metaError;
  const { error, visited, submitFailed, touched, dirty } = meta;
  if (error && (touched || visited || submitFailed))
    metaError = error.size ? error.toJS()[0] : error;
  const checkError = overrideError || metaError;
  const showErrorBorder = touched && checkError;
  const showErrorMessage = showErrorBorder && !hideErrorString;

  if (
    !input.value &&
    input.value !== overrideValue &&
    props.type === 'hidden' &&
    overrideValue
  ) {
    input.onChange(overrideValue);
  }

  return (
    <FormGroup
      haslabel={!!titleLabel || !!label}
      hidden={hiddenField}
      marginBottom={marginBottom}
      margin={margin}
      maxWidth={maxWidth}
    >
      {(label || titleLabel) && (
        <Label
          label={label}
          titleLabel={titleLabel}
          requiredfield={!!requiredfield}
        />
      )}
      <InputWrapper>
        <Input
          {...input}
          {...props}
          id={input.name}
          errorBorder={!!showErrorBorder}
          value={input.value}
          dirty={dirty}
          error={checkError}
          success={!checkError && touched && dirty}
          validate={validate}
          bsSize={size}
          maxWidth={maxWidth}
          paddingLeft={paddingLeft}
          inputRadioField={inputRadioField}
          onKeyPress={event => {
            if(onKeyPress) return onKeyPress(event)
            if (props.type === 'number') {
              const keyCode = event.keyCode || event.which;
              const keyValue = String.fromCharCode(keyCode);
              if (!/^\d+$/.test(keyValue)) event.preventDefault();
            }
          }}
        />
        {currency && <Currency>€</Currency>}
        {cleanButton && (
          <CleanButton>
            <Button color="link" type="button" onClick={onClean && onClean}>
              <CleanIcon />
            </Button>
          </CleanButton>
        )}
      </InputWrapper>
      {showErrorMessage && (
        <ErrorMessage>
          {errorMessages[isString(metaError)] ? (
            <FormattedMessage
              values={typeof error !== 'object' ? {} : metaError}
              {...errorMessages[isString(metaError)]}
            />
          ) : (
            metaError
          )}
        </ErrorMessage>
      )}
    </FormGroup>
  );
}

MyInput.propTypes = {
  margin: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideErrorString: PropTypes.bool,
  requiredfield: PropTypes.bool,
  overrideValue: PropTypes.any,
  validate: PropTypes.any,
  hiddenField: PropTypes.bool,
  size: PropTypes.string,
  currency: PropTypes.bool,
  paddingLeft: PropTypes.string,
  inputRadioField: PropTypes.bool,
  cleanButton: PropTypes.bool,
  onClean: PropTypes.func,
  onKeyPress: PropTypes.func,
};

MyInput.defaultProps = {
  type: null,
  error: null,
  className: null,
  input: null,
  meta: {},
  label: null,
  titleLabel: null,
  hideErrorString: false,
  requiredfield: false,
  overrideValue: undefined,
  inputRadioField: false,
  cleanButton: false,
  onClean: undefined,
};

export default MyInput;

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts

export const ButtonStyles = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    fontFamily: 'Nunito Sans',
    borderRadius: 100, // <-- border radius is same for all variants and sizes
    boxShadow: 'none !important',
    lineHeight: 0,
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  sizes: {
    primaryBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primarySmall: {
      fontSize: 'lg',
      h: '40px',
    },
    primaryOutlineBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primaryOutlineSmall: {
      fontSize: 'lg',
      h: '40px',
      // minWidth: '140px',
    },
  },
  // Two variants: primary and outline
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      borderWidth: '2px',
      borderColor: 'primary',
      _hover: {
        bg: '#fed37e',
        borderWidth: '2px',
        borderColor: '#fed37e',
        _disabled: {
          bg: 'primary',
          borderWidth: '2px',
          borderColor: 'primary',
          color: 'white',
        },
      },
    },
    secondary: {
      bg: 'grey4',
      color: 'black',
      borderWidth: '2px',
      borderColor: 'grey4',
    },
    primaryModal: {
      bg: 'primary',
      color: 'white',
      borderWidth: '2px',
      borderColor: 'primary',
      minW: '150px',
      maxW: '350px',
      _hover: {
        bg: '#fed37e',
        borderWidth: '2px',
        borderColor: '#fed37e',
        _disabled: {
          bg: 'primary',
          borderWidth: '2px',
          borderColor: 'primary',
          color: 'white',
        },
      },
    },
    primaryOutline: {
      bg: 'white',
      color: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
      // h: '40px',
      _hover: {
        bg: '#fff5db',
      },
    },
    primaryOutlineModal: {
      bg: 'white',
      color: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
      minW: '150px',
      maxW: '350px',
      // h: '40px',
      _hover: {
        bg: '#fff5db',
      },
    },
    noStyle: {
      width: 'auto',
      height: 'auto',
      minWidth: 'auto',
      paddingInline: '5px',
    },
    underlined: {
      width: 'auto',
      height: 'auto',
      minWidth: 'auto',
      paddingInline: '5px',
      textDecoration: 'underline',
    },
    noHoverBG: {
      padding: 0,
      _hover: {
        backgroundColor: 'transparent',
      },
    },
    buttonAsLink: {
      width: 'auto',
      height: 'auto',
      minWidth: 'auto',
      paddingInline: '5px',
      _hover: {
        color: 'yellow.600',
      },
    },
    languageSelected: {
      fontWeight: 700,
      fontSize: 'sm',
    },
    languageUnselected: {
      fontWeight: 400,
      fontSize: 'sm',
    },
    asLink: {
      color: 'black',
      fontSize: 'sm',
      fontWeight: 'semibold',
      textDecoration: 'underline',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { platformReady } from 'utils/env';
import {
  notApproved,
  isApproved,
} from 'containers/PartnersRegistration/shared';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isImpersonated,
  partnerStatus,
  partnerFirstApprovalReviewed,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const { search } = props.location;
      const redirect = search ? `/?${search}` : '/';
      if (!isAuthenticated)
        return (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        );
      if (!partnerStatus) return null;
      const { pathname } = props.location;
      const excludePathCheck =
        pathname !== '/partner-registration' &&
        pathname !== '/communications' &&
        pathname !== '/settings' &&
        pathname !== '/platform-offline' &&
        !pathname.includes('edit-profile');

      if (
        pathname === '/partner-registration' &&
        isApproved(partnerStatus) &&
        (partnerFirstApprovalReviewed || isImpersonated)
      ) {
        return <Redirect to="/" />;
      } else if (notApproved(partnerStatus) && excludePathCheck) {
        return <Redirect to="/partner-registration" />;
      } else if (excludePathCheck && platformReady === 'false') {
        return <Redirect to="/platform-offline" />;
      }
      return <Component {...props} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isImpersonated: PropTypes.bool.isRequired,
  partnerStatus: PropTypes.string,
  partnerFirstApprovalReviewed: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
};

PrivateRoute.defaultProps = {
  partnerStatus: null,
  partnerFirstApprovalReviewed: false,
};

export default PrivateRoute;

// @ts-ignore
import { theme } from '@apps/goodme/app/app.js';
import LanguageProvider from '@apps/goodme/app/containers/LanguageProvider/index.js';
import { ChakraProvider } from '@chakra-ui/react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { history } from '../../utils/history';
import App from './App';
import { CacheChecker } from './components/CacheChecker';
import PULoader from './components/PULoader';
import i18n from './i18n';
import { store, persistor } from './redux/store';
import './styles.css';
import customTheme from './theme/theme';

const PUApp = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<PULoader />}>
            <ConnectedRouter history={history}>
              <LanguageProvider messages={{}}>
                <ThemeProvider theme={theme}>
                  <I18nextProvider i18n={i18n}>
                    <CacheChecker>
                      <App />
                    </CacheChecker>
                  </I18nextProvider>
                </ThemeProvider>
              </LanguageProvider>
            </ConnectedRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
};

export default PUApp;

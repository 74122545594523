import React from 'react';

const SvgComponent = props => (
  <svg viewBox="0 0 30 30" {...props}>
    <title>PROFILE-Logout</title>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22.65 22.78a11 11 0 1 1 0-15.56M21.83 11.62L26.91 15l-5.08 3.38M26.91 15H13.37" />
    </g>
    <g fill="currentColor">
      <path d="M22.3 22.42A10.5 10.5 0 1 1 7.45 7.58a10.49 10.49 0 0 1 14.85 0 .5.5 0 0 0 .7-.71 11.5 11.5 0 1 0 0 16.26.5.5 0 1 0-.71-.71z" />
      <path d="M27.32 15.27a.43.43 0 0 0 0-.44.63.63 0 0 0-.11-.12l-5.1-3.51a.51.51 0 0 0-.7.14.5.5 0 0 0 .14.69l3.7 2.47H13.37a.5.5 0 1 0 0 1h11.88l-3.7 2.5a.5.5 0 0 0-.14.69.51.51 0 0 0 .7.14l5.07-3.38a.63.63 0 0 0 .14-.18z" />
    </g>
  </svg>
);

export default SvgComponent;

import styled from 'styled-components';
import _button from '../../../Button';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const CancelWrap = styled.div`
  display: flex;
  color: ${({ theme }) => theme.gray200};
  font-size: 1.4rem;
  align-items: center;
  padding-right: 50px;
  min-width: 202px;
`;

export const CancelButton = styled(_button)`
  padding: 0 5px;
  width: auto;
`;

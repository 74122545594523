import { APIClientPU } from '@common/common/utils';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import {
  GetAllPracticesAttachmentsRequestPayload,
  GetAllPracticesAttachmentsSuccessPayload,
  GetAllPracticesInvoicesRequestPayload,
  GetAllPracticesInvoicesSuccessPayload,
} from './types';

export async function getAllPracticesAttachments({
  id,
  page = 1,
  itemsPerPage = 10,
  sortBy = [],
}: GetAllPracticesAttachmentsRequestPayload): Promise<GetAllPracticesAttachmentsSuccessPayload> {
  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetAllPracticesAttachmentsSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}/attachments/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllPracticesAttachments - Error: ', error);
    throw error;
  }
}

export async function getAllPracticesInvoices({
  id,
  page = 1,
  itemsPerPage = 10,
  sortBy = [],
}: GetAllPracticesInvoicesRequestPayload): Promise<GetAllPracticesInvoicesSuccessPayload> {
  const orderBy = !isEmpty(sortBy)
    ? {
        orderBy: sortBy?.map((el: { id: string; desc: boolean }) => {
          return {
            field: el?.id,
            order: el?.desc === true ? 'desc' : 'asc',
          };
        }),
      }
    : {};

  try {
    const response = await APIClientPU.post<any, AxiosResponse<GetAllPracticesInvoicesSuccessPayload>>(
      `${window.MyCareEnvironment.REACT_APP_BACKEND_API_PU_BASE_URL}/portaleunico/tickets/me/${id}/invoices/filter?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        ...orderBy,
      },
    );

    return response.data;
  } catch (error) {
    console.error('getAllPracticesInvoices - Error: ', error);
    throw error;
  }
}

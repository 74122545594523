import React from 'react';

const DocVID = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" {...props}>
    <title>{props.title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 3.521 7.912 L 12.527 7.912 L 12.527 5.183 L 9.658 5.183 C 9.357 5.183 8.938 5.021 8.739 4.822 C 8.541 4.624 8.42 4.265 8.42 3.966 L 8.42 1.06 L 1.13 1.06 C 1.105 1.06 1.085 1.073 1.073 1.085 C 1.052 1.099 1.047 1.117 1.047 1.144 L 1.047 15.92 C 1.047 15.939 1.059 15.964 1.072 15.976 C 1.084 15.996 1.11 16.001 1.128 16.001 C 4.29 16.001 9.192 16.001 12.442 16.001 C 12.467 16.001 12.466 15.989 12.48 15.976 C 12.499 15.964 12.526 15.938 12.526 15.92 L 12.526 14.37 L 3.521 14.37 C 2.947 14.37 2.472 13.899 2.472 13.321 L 2.472 8.96 C 2.472 8.384 2.944 7.912 3.521 7.912 Z M 13.572 7.912 L 14.951 7.912 C 15.528 7.912 16 8.385 16 8.961 L 16 13.323 C 16 13.899 15.527 14.372 14.951 14.372 L 13.572 14.372 L 13.572 16.253 C 13.572 16.476 13.482 16.675 13.336 16.822 C 13.189 16.969 12.991 17.058 12.767 17.058 C 8.681 17.058 4.912 17.058 0.805 17.058 C 0.582 17.058 0.383 16.969 0.236 16.822 C 0.089 16.675 0 16.476 0 16.253 L 0 0.812 C 0 0.589 0.09 0.39 0.236 0.243 C 0.383 0.096 0.587 0.007 0.805 0.007 L 8.956 0.007 C 8.974 0 8.993 0 9.013 0 C 9.101 0 9.192 0.039 9.255 0.096 L 9.268 0.096 C 9.28 0.103 9.287 0.108 9.3 0.121 L 13.463 4.335 C 13.533 4.406 13.585 4.501 13.585 4.61 C 13.585 4.642 13.578 4.667 13.572 4.7 L 13.572 7.912 Z M 9.371 3.882 L 9.371 1.241 L 12.345 4.253 L 9.741 4.253 C 9.639 4.253 9.55 4.209 9.479 4.145 C 9.415 4.081 9.371 3.985 9.371 3.882 Z"
      fill="rgb(8, 88, 137)"
    />
    <path
      fill-rule="evenodd"
      d="M 8.202 9.24 L 8.202 10.06 L 7.952 10.06 L 7.952 12.31 L 8.202 12.31 L 8.202 13.13 L 6.312 13.13 L 6.312 12.31 L 6.552 12.31 L 6.552 10.56 L 5.702 13.13 L 4.962 13.13 L 4.082 10.59 L 4.082 12.31 L 4.322 12.31 L 4.322 13.13 L 2.682 13.13 L 2.682 12.31 L 2.932 12.31 L 2.932 10.06 L 2.682 10.06 L 2.682 9.24 L 4.972 9.24 L 5.492 10.8 L 5.992 9.24 L 8.202 9.24 ZM 10.271 13.2 C 9.658 13.2 9.188 13.04 8.861 12.72 C 8.528 12.4 8.361 11.887 8.361 11.18 C 8.361 10.48 8.528 9.97 8.861 9.65 C 9.188 9.33 9.658 9.17 10.271 9.17 C 10.884 9.17 11.354 9.33 11.681 9.65 C 12.014 9.97 12.181 10.48 12.181 11.18 C 12.181 11.887 12.014 12.4 11.681 12.72 C 11.354 13.04 10.884 13.2 10.271 13.2 Z M 10.271 12.31 C 10.384 12.31 10.468 12.28 10.521 12.22 C 10.581 12.16 10.611 12.067 10.611 11.94 L 10.611 10.43 C 10.611 10.303 10.581 10.21 10.521 10.15 C 10.468 10.09 10.384 10.06 10.271 10.06 C 10.158 10.06 10.074 10.09 10.021 10.15 C 9.961 10.21 9.931 10.303 9.931 10.43 L 9.931 11.94 C 9.931 12.073 9.961 12.167 10.021 12.22 C 10.074 12.28 10.158 12.31 10.271 12.31 ZM 14.347 10.06 L 14.347 9.24 L 16.017 9.24 L 16.017 10.06 L 15.817 10.06 L 14.917 13.13 L 13.307 13.13 L 12.407 10.06 L 12.207 10.06 L 12.207 9.24 L 14.127 9.24 L 14.127 10.06 L 13.867 10.06 L 14.207 11.73 L 14.267 11.73 L 14.607 10.06 L 14.347 10.06 Z"
      transform="matrix(0.8579750061035156, 0, 0, 0.9071421027183533, 1.1659729480743408, 1.1355129480361938)"
      fill="rgb(255, 255, 255)"
    />
  </svg>
);

export default DocVID;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';
import { intlShape, FormattedMessage } from 'react-intl';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Container } from 'reactstrap';
import { Search } from '../../icons';
import Input from '../Input';
import {
  FilterWrapper,
  SearchWrapper,
  ButtonWrapper,
  SearchButton,
  Form,
  Overlay,
  FilterBy,
} from './styles';
import messages from './messages';
import Filters from './Filters';

function HeaderTable({
  showAddCondition,
  filters,
  formValues,
  intl,
  placeholder,
  hideSearchInput,
  handleSubmit,
  removeFilter,
  selectedField,
  selectField,
  valid,
  ignoredFilters,
  onDismiss,
  applyFilter,
  removeSingleFilter,
  disabledKeys,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid>
        <FilterWrapper>
          <ButtonWrapper>
            <FilterBy>
              <FormattedMessage {...messages.filterBy} />
            </FilterBy>
            {filters && (
              <FieldArray
                name="filter"
                component={Filters}
                filters={filters}
                formValues={formValues}
                removeFilter={removeFilter}
                showAddCondition={showAddCondition}
                selectField={selectField}
                selectedField={selectedField}
                valid={valid}
                ignoredFilters={ignoredFilters}
                applyFilter={applyFilter}
                removeSingleFilter={removeSingleFilter}
                disabledKeys={disabledKeys}
              />
            )}
          </ButtonWrapper>
          {!hideSearchInput && (
            <SearchWrapper md="5">
              <Field
                name="search.value"
                style={{ fontSize: '1.4rem' }}
                placeholder={
                  placeholder || intl.formatMessage(messages.searchByIdOrName)
                }
                component={Input}
                type="text"
                size="lg"
                removeBorderRight
              />
              <SearchButton color="primary" type="submit" disabled={!valid}>
                <Search />
              </SearchButton>
            </SearchWrapper>
          )}
        </FilterWrapper>
      </Container>
      {!!selectedField && <Overlay onClick={onDismiss} />}
    </Form>
  );
}

HeaderTable.propTypes = {
  showAddCondition: PropTypes.bool,
  formValues: PropTypes.shape({}),
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  intl: intlShape.isRequired,
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  removeFilter: PropTypes.func,
  valid: PropTypes.bool.isRequired,
  hideSearchInput: PropTypes.bool,
  selectField: PropTypes.func.isRequired,
  selectedField: PropTypes.shape({}),
  ignoredFilters: PropTypes.arrayOf(PropTypes.string),
  onDismiss: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  removeSingleFilter: PropTypes.func.isRequired,
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
};

HeaderTable.defaultProps = {
  filters: null,
  placeholder: null,
  hideSearchInput: false,
};

export default HeaderTable;

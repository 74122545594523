import styled, { css } from 'styled-components';
import { Label as _label, FormGroup as _formGroup } from 'reactstrap';
import omit from '../../utils/omitProps';
import RadioActive from 'images/icons/caffeina-icons/radio-active';
import RadioDisabled from 'images/icons/caffeina-icons/radio-disabled';

export const LabelRadio = styled(omit(_label, ['vertical', 'labelPadding']))`
  cursor: pointer;
  margin: ${({ labelRadioMargin }) => labelRadioMargin || '3px 0 !important'};
  display: flex;
  align-items: center;
  padding: ${({ labelRadioPadding }) =>
    labelRadioPadding ? `${labelRadioPadding} !important` : '3px 0 !important'};
  font-size: 1.4rem;
  color: ${({ theme, checked }) => (checked ? theme.black : theme.gray300)};
  user-select: none;
  ${({ vertical }) =>
    !vertical &&
    css`
      & + label {
        margin-left: 20px;
      }
    `};
`;

export const RadioIcon = styled(omit(RadioActive, ['active']))`
  font-size: 2.4rem;
  height: 20px;
  color: ${({ active, theme }) => (active ? theme.primary : theme.black)};
  
`;
export const RadioIconDisabled = styled(omit(RadioDisabled, ['active']))`
  font-size: 2.4rem;
  height: 20px;
  color: ${({ active, theme }) => (active ? theme.primary : theme.black)};
  
`;
export const RadioIcon2 = styled.div`
  display: inline-box;
  margin-right: 10px;
  position: relative;
  height: 26px;
  width: 26px;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    ${({ theme, disabled }) =>
      disabled &&
      css`
        border: none;
        border: 8px solid ${theme.gray200} !important;
        border-radius: 50%;
      `};
    border: ${({ checked, theme }) =>
      checked
        ? `7px solid ${theme.primaryHover}`
        : `1px solid ${theme.gray600}`}};
  }
  display: ${({ typeButton }) => typeButton === 'Button' && 'none'} !important;
`;

export const RadioWrap = styled.div`
  display: flex;
  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
    `};
`;

export const FormGroup = styled(_formGroup)`
  width: 100%;
  margin: 0;
  ${({ variant }) =>
    variant !== 'small' &&
    css`
      margin-bottom: 13px;
      min-height: 50px;
    `};
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.red} !important;
  padding-left: 15px;
  font-weight: 600;
  display: block;
`;

export const OnlyLabel = styled.span`
  padding-top: 0.7em;
  padding-left: 0.6em;
  font-size: 1.4rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.black}
    ${({ paddingLeft }) =>
      paddingLeft &&
      css`
        padding-left: ${paddingLeft}px !important;
      `};
`;

export const RadioContainer = styled.div`
  background-color: ${({ checked, typeButton, theme }) =>
    checked && typeButton === 'Button' && theme.primaryHover} !important;
  border-radius: ${({ typeButton }) =>
    typeButton === 'Button' && '50px'} !important;
  border: ${({ typeButton, theme }) =>
    typeButton === 'Button' && `1px solid ${theme.primaryHover}`} !important;
  padding: ${({ radioContainerPadding }) => radioContainerPadding || '10px'};
  width: 100%;
`;

import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { history } from '../../../utils/history';
import persistedRootReducer from './reducers';
import rootSaga from './rootsaga';

// Middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

// Store
const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middleware),
  devTools:
    process.env.NODE_ENV === 'development' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__({ name: 'PU' }),
});

const persistor = persistStore(store);

/**
 * Create a permanent store
 * for future reducers, currently
 * only used to attach global app
 * state to local redux state.
 */
// @ts-ignore
store.asyncReducers = {};

// @ts-ignore
store.runSaga = sagaMiddleware.run;
// @ts-ignore
store.injectedReducers = {}; // Reducer registry
// @ts-ignore
store.injectedSagas = {}; // Saga registry

sagaMiddleware.run(rootSaga);

export { store, persistor };

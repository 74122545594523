import { createAction } from 'redux-actions-helpers';

export const getInvoiceCitiesRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP4/GET_INVOICE_CITIES',
  citiesCode => ({
    citiesCode,
  }),
);

export const saveInvoiceCitiesResponse = createAction(
  'APP/PARTNER_REGISTRATION/SAVE_INVOICE_CITIES',
  data => ({
    data,
  }),
);

export const goToStepAction = createAction(
  'APP/PARTNER_REGISTRATION/STEP/GO_TO_STEP',
  ({ step, wizardIsOpen, changeCurrentStep }) => ({
    step,
    wizardIsOpen,
    changeCurrentStep,
  }),
);

export const saveFirstApprovalReviewed = createAction(
  'APP/PARTNER_REGISTRATION/STEP/SAVE_FIRST_APPROVAL_REVIEWED',
  data => ({
    data,
  }),
);

export const getCurrentPracticeRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP/GET_CURRENT_PRACTICE_DATA_REQUEST',
);

export const saveCurrentPracticeDataRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP/SAVE_CURRENT_PRACTICE_DATA_REQUEST',
  data => ({
    data,
  }),
);

export const saveCurrentPracticeDataResponse = createAction(
  'APP/PARTNER_REGISTRATION/STEP/SAVE_CURRENT_PRACTICE_DATA_RESPONSE',
  data => ({
    data,
  }),
);

export const saveCurrentEditPracticeDataRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP/SAVE_CURRENT_EDIT_PRACTICE_DATA_RESPONSE',
  data => ({
    data,
  }),
);

export const editCurrentPracticeDataRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP/EDIT_CURRENT_PRACTICE_DATA_REQUEST',
);

export const editCurrentPracticeDataResponse = createAction(
  'APP/PARTNER_REGISTRATION/STEP/EDIT_CURRENT_PRACTICE_DATA_RESPONSE',
  data => ({
    data,
  }),
);

export const submittedCurrentPracticeDataRequest = createAction(
  'APP/PARTNER_REGISTRATION/STEP/SUBMITTED_CURRENT_PRACTICE_DATA_REQUEST',
  data => ({
    data,
  }),
);

export const cleanPartenersDataOnLogout = createAction(
  'APP/PARTNER_REGISTRATION/STEP/LOGOUT',
);

export const disableLoadingStatus = createAction(
  'APP/PARTNER_REGISTRATION/DISABLE_LOADING_STATUS',
);

export const setContractNeededDuringFill = createAction(
  'APP/PARTNER_REGISTRATION/SET_CONTRACT_NEEDEDDURING_FILL',
);

export const setDataLostErrorAction = createAction(
  'APP/PARTNER_REGISTRATION/SET_DATA_LOST_ERROR',
  error => ({
    error,
  }),
);

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { call, put } from 'redux-saga/effects';
import { pushError } from 'containers/Alerts/actions';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { updateTermsResponse } from '../actions';

export function* updateTerms({ dataType, data: updatedTerms }) {
  try {
    yield put(showLoading());

    const url = `legal_flags/me`;
    yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: updatedTerms,
    });

    // Mark dataType terms as updated
    yield put(updateTermsResponse(dataType));
  } catch (e) {
    const { response } = e;
    if (response.status === 403) {
      yield put(pushError({ text: 'updateTermsDataError' }));
    } else {
      yield put(pushError({ text: 'updateTermsError' }));
    }
  } finally {
    yield put(hideLoading());
  }
}

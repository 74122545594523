import React from 'react';

const Located = props => (
  <svg viewBox="0 0 187.484 187.484" width="1em" height="1em" {...props}>
    <circle cx={93.742} cy={93.742} r={35.898} />
    <path d="M182.484 88.742h-5.61c-2.498-41.953-36.179-75.635-78.132-78.132V5a5 5 0 10-10 0v5.61C46.789 13.108 13.107 46.789 10.61 88.742H5a5 5 0 100 10h5.61c2.498 41.953 36.179 75.635 78.132 78.132v5.61a5 5 0 1010 0v-5.61c41.953-2.498 75.635-36.179 78.132-78.132h5.61a5 5 0 100-10zm-83.742 73.093v-8.621a5 5 0 10-10 0v8.621C55.065 159.384 28.1 132.42 25.649 98.742h8.622a5 5 0 100-10h-8.622C28.1 55.065 55.065 28.1 88.742 25.649v8.621a5 5 0 1010 0v-8.621c33.677 2.451 60.642 29.416 63.093 63.093h-8.622a5 5 0 100 10h8.622c-2.451 33.678-29.416 60.642-63.093 63.093z" />
  </svg>
);

export default Located;

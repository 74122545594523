import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Row, CardBody } from 'reactstrap';
import moment from 'moment';
import { showTimeShort } from '../../utils/DateTimeFormat';
import { formatPrice } from '../../utils/utils';
import StarRating from '../StarRating';
// TODO: importato da enduser da sistemare
import RemoteFilePreview from '../../../../app/components/shared/FilePreview/RemoteFilePreview';
// TODO: importato da enduser da sistemare ^
import {
  Col,
  Card,
  TimeWrapper,
  Time,
  Title,
  Rating,
  Distance,
  Price,
  Slider,
  ArrowRight,
  // BestMatchBadge,
  Badge,
  RowCard,
} from './styles';
import messages from './messages';
import CaffeinaMarker from '../../../../app/images/icons/caffeina-icons/marker';

function SearchCard({
  partner,
  distance,
  price,
  specialOffer,
  availabilities,
  onClick,
  isInfoWindow,
  onChange,
  formValues,
  selectedVisit,
  locationId,
  partnerId,
  fromRequestDetails,
  bestMatch,
  city,
}) {
  const { partnerType } = partner || {};
  const slidesInfowindowToShow = isInfoWindow ? 3 : 7;
  const slidesToShow =
    ((availabilities || []).length <= slidesInfowindowToShow &&
      (availabilities || []).length) ||
    slidesInfowindowToShow;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <ArrowRight width="10px" height="10px" />,
    prevArrow: <ArrowRight width="10px" height="10px" />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { maxDiscount, minPrice } = specialOffer || {};
  return (
    partner && (
      <Card isInfoWindow={isInfoWindow}>
        <CardBody onClick={onClick}>
          <Row>
            <Col widthFlex="70px">
              <RemoteFilePreview
                attachmentId={partner && partner.profilePictureId}
                firstName={
                  partner &&
                  (partnerType === 'structure'
                    ? partner.name
                    : partner.firstName)
                }
                lastName={
                  partner &&
                  (partnerType === 'structure' ? '' : partner.lastName)
                }
                size={60}
                alt="photo profile"
              />
            </Col>
            <Col widthFlex="calc(100% - 70px)">
              <Row>
                <Col md="8" sm="8">
                  <Title>
                    {partnerType === 'structure'
                      ? partner.name
                      : `${partner.firstName} ${partner.lastName}`.trim()}
                  </Title>
                </Col>
                <Col right="true" md="4" sm="4" textAlign="right">
                  {bestMatch && (
                    <Badge>
                      <FormattedMessage {...messages.bestMatch} />
                    </Badge>
                  )}
                </Col>
              </Row>
              <Rating>
                <StarRating rating="0/5" size={14} />
              </Rating>
              <RowCard>
                <Badge>
                  <CaffeinaMarker /> {city}
                </Badge>
                <Col distance="true" top="5" md="8" sm="8">
                  <Distance>
                    <FormattedMessage {...messages.distance} />{' '}
                    <b>{distance.toFixed(2)} km</b>
                  </Distance>
                </Col>
                <Col
                  distance="true"
                  strong="true"
                  right="true"
                  nopaddingleft="true"
                  md="4"
                  sm="4"
                >
                  <Price discount={maxDiscount} bestMatch={bestMatch}>
                    &euro;
                    <span>{formatPrice(minPrice || price)}</span>
                  </Price>
                </Col>
              </RowCard>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <Row>
            <Col
              nopadding="true"
              md="12"
              /*  padding={
                isInfoWindow
                  ? '0 30px'
                  : `0 30px 0 ${fromRequestDetails ? '20%' : '40%'}`
              } */
            >
              <Slider {...settings}>
                {(availabilities || []).map(({ startTime, discount }) => (
                  <TimeWrapper key={`${partner.id}-${startTime}`}>
                    <Time
                      onClick={() => onChange(startTime)}
                      selected={
                        formValues &&
                        formValues.toJS().timeSlot === startTime &&
                        (selectedVisit || {}).partnerId === partnerId &&
                        (selectedVisit || {}).locationId === locationId
                      }
                      discount={discount || 0}
                    >
                      {fromRequestDetails
                        ? moment(startTime).format('DD/MM/YYYY - HH:mm')
                        : showTimeShort(startTime)}
                    </Time>
                  </TimeWrapper>
                ))}
              </Slider>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  );
}

SearchCard.propTypes = {
  intl: intlShape.isRequired,
  partner: PropTypes.shape({
    partnerId: PropTypes.number,
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  distance: PropTypes.number,
  price: PropTypes.number,
  specialOffer: PropTypes.shape({
    maxDiscount: PropTypes.number,
    minPrice: PropTypes.number,
  }),
  availabilities: PropTypes.array,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isInfoWindow: PropTypes.bool,
  selectedVisit: PropTypes.shape({}),
  partnerId: PropTypes.number,
  locationId: PropTypes.number,
  fromRequestDetails: PropTypes.bool,
  bestMatch: PropTypes.number,
  city: PropTypes.string,
};

SearchCard.defaultProps = {
  onClick: null,
  isInfoWindow: null,
};

export default injectIntl(SearchCard);

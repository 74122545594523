import { defineMessages } from 'react-intl';

export default defineMessages({
  dr: {
    id: 'web.partner.platformOffline.dr',
    defaultMessage: 'Dr {name}',
  },
  message: {
    id: 'web.partner.platformOffline.message',
    defaultMessage:
      'Thank you for signing up. </br>We will notify you as soon as the platform is active',
  },
  modifyPractice: {
    id: 'web.partner.platformOffline.modifyPractice',
    defaultMessage: 'Modify Practice',
  },
});

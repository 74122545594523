import { createReducer } from '@reduxjs/toolkit';
import {
  getLocationSpecialtiesFailed,
  getLocationSpecialtiesRequest,
  getLocationSpecialtiesSuccess,
  resetLocationSpecialties,
  getSpecialtiesFailed,
  getSpecialtiesRequest,
  getSpecialtiesSuccess,
  getPartnerSpecialtiesFailed,
  getPartnerSpecialtiesRequest,
  getPartnerSpecialtiesSuccess,
  resetSpecialties,
} from './actions';

export interface LocationSpecialtiesState {
  loading: boolean;
  specialtiesList:
    | {
        id: number;
        name: string;
        status: string;
      }[]
    | [];
}

const initialState: LocationSpecialtiesState = {
  loading: false,
  specialtiesList: [],
};

export const allLocationSpecialties = createReducer(initialState, {
  [getLocationSpecialtiesRequest.type]: (state) => {
    state.loading = true;
  },
  [getLocationSpecialtiesSuccess.type]: (state, action) => {
    state.loading = false;
    state.specialtiesList = action.payload;
  },
  [getLocationSpecialtiesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetLocationSpecialties.type]: (state) => {
    state.specialtiesList = [];
  },
  [getSpecialtiesRequest.type]: (state) => {
    state.loading = true;
  },
  [getSpecialtiesSuccess.type]: (state, action) => {
    state.loading = false;
    state.specialtiesList = action.payload.results;
  },
  [getSpecialtiesFailed.type]: (state) => {
    state.loading = false;
  },
  [resetSpecialties.type]: (state) => {
    state.specialtiesList = [];
  },
  [getPartnerSpecialtiesRequest.type]: (state) => {
    state.loading = true;
  },
  [getPartnerSpecialtiesSuccess.type]: (state, action) => {
    state.loading = false;
    state.specialtiesList = action.payload.results;
  },
  [getPartnerSpecialtiesFailed.type]: (state) => {
    state.loading = false;
  },
});

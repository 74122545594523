import React from 'react';
import PropTypes from 'prop-types';

const Data = ({ active, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 38 44" {...props}>
    <title>Data</title>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <g stroke="currentColor">
        <path
          d="M11.75 15.75H23m-11.25 4.5h6.75m-6.75 4.5H23M8 15h1.5v1.5H8V15zm0 4.5h1.5V21H8v-1.5zM8 24h1.5v1.5H8V24zm0 4.5h1.5V30H8v-1.5zm3.75.75h6.75"
          strokeWidth={0.882}
        />
        <path
          d="M21.667 3.333H30v33.334H0V3.333h8.333m0-3.333h13.334v8.333H8.333V0z"
          strokeWidth={2}
        />
      </g>
      {active && (
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#FF4040"
          cx={29.923}
          cy={35.923}
          r={5.923}
        />
      )}
    </g>
  </svg>
);

Data.propTypes = {
  active: PropTypes.bool,
};

export default Data;

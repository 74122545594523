import React from 'react';
import PropTypes from 'prop-types';

const AonMessage = ({ colored, ...props }) => (
  <svg width="174" height="202" viewBox="0 0 174 202" {...props}>
    <title>MENU-Aon Message</title>
    <path
      d="M173.55 201.3H8.59229e-06V76.3701C-0.00105973 75.9731 0.0975109 75.5822 0.286141 75.2329C0.474772 74.8836 0.747548 74.587 1.08009 74.3701L17.4902 60.1301L20.1104 63.1301L5.11036 76.1301L86.73 135.31L168.29 76.1501L152.7 62.9502L155.28 59.8901L172.11 74.1501C172.536 74.3232 172.9 74.6193 173.157 75.0005C173.413 75.3817 173.55 75.8308 173.55 76.2903V201.3ZM8.16993 197.3H165.38L86.75 140.3L8.16993 197.3ZM90.1699 137.82L169.56 195.38V80.2302L90.1699 137.82ZM4.01026 80.3003V195.37L83.3604 137.82L4.01026 80.3003ZM43.8101 42.6101L41.1899 39.6101L85.6401 0.950195L130.28 38.7502L127.7 41.8103L85.7002 6.22021L43.8101 42.6101Z"
      fill={colored ? '#e11c23' : 'currentColor'}
    />
    <path
      d="M21.1401 89.5195H17.1401V38.8496H155.63V89.0195H151.63V42.8496H21.1401V89.5195Z"
      fill={colored ? '#e11c23' : 'currentColor'}
    />
    <path
      d="M70.1802 50.5703L76.0903 92.6802H66.7202L62.5503 63.2703L62.46 63.2002L55.3804 76.6001H62.9502L64.1304 84.4102H51.27L46.8804 92.6802H36.4199L40.5103 84.4102H34.7402L38.7402 76.6001H44.6499L58.48 50.6001L70.1802 50.5703ZM137.18 60.3401L131.51 92.6802H122.12L115.04 76.4302C113.96 81.8202 113.11 87.2902 112.16 92.6802H102.71C103.07 90.2002 103.56 87.6802 103.79 85.2102C102.345 87.8072 100.219 89.9615 97.6411 91.4414C95.0635 92.9214 92.1318 93.671 89.1602 93.6101C86.7585 93.4862 84.4115 92.847 82.2788 91.7358C80.1461 90.6246 78.2779 89.0675 76.8003 87.1702L74.3701 70.2502C75.5616 67.3758 77.5069 64.8763 80.001 63.0159C82.4951 61.1554 85.4453 60.003 88.54 59.6802C91.4675 59.465 94.3997 60.0324 97.0356 61.324C99.6715 62.6156 101.916 64.585 103.54 67.0303C104.761 68.8858 105.588 70.9721 105.97 73.1602L108.29 60.3401H117.49L124.72 76.6902C125.72 71.2702 126.72 65.8201 127.62 60.3401H137.18ZM94.9702 70.7102C94.0941 69.6288 92.9049 68.8446 91.5659 68.4651C90.2269 68.0856 88.8035 68.1292 87.4902 68.5901C85.2302 69.2101 83.49 71.6702 82.71 73.6902C81.36 77.2202 82.9001 82.0603 85.3501 83.5503C86.1487 84.1067 87.0582 84.4839 88.0161 84.6565C88.974 84.8291 89.9574 84.793 90.8999 84.5503C91.953 84.2056 92.9263 83.6525 93.7612 82.9241C94.5962 82.1957 95.276 81.3069 95.7603 80.3103C97.0303 77.4403 97.25 73.1602 94.96 70.7102H94.9702Z"
      fill={colored ? '#e11c23' : 'currentColor'}
    />
  </svg>
);

AonMessage.propTypes = {
  colored: PropTypes.bool,
};

AonMessage.defaultProps = {
  colored: null,
};

export default AonMessage;
